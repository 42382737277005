import React, { Component } from "react";

import LoadingIcon from "../Components/LoadingIcon";
import SweetAlert from "react-bootstrap-sweetalert";
import TableAPI from "../Tables/TableAPI";
import { Trans } from "react-i18next";
import { connect } from 'react-redux';
// import { getDashboardData } from '../../helpers/apiHelper'
import { getUserGroups } from "../../helpers/apiHelper";

var moment = require('moment-timezone');
 
let $ = window.$;

class Analytics extends Component {

  onClickDetail = (group) => {
    window.open(`#/analytics/dashboard-report/?group=${group}`);
}
  static displayName = Analytics.name;
  constructor(props) {
    super(props);
   
    
    this.state = {
      alert: null,
      dataDidLoad: false
    }

  }

  async componentDidMount() {

    if (!this.props.auth) {
      this.props.history.push('/');
    }
    // const data = await getDashboardData();
    // await this.setState({ data });
    this.setState({ dataDidLoad: true });
   
  }
    
  endpointErrorHandlerAlert = (error) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={<Trans>ERROR_ALERT</Trans>}
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          <p><Trans>TABLE_API_ERROR_1</Trans><br /><Trans>TABLE_API_ERROR_2</Trans></p>
        </SweetAlert >
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  render() {
    if (this.state.dashboardError) {
      return <div>
        {this.state.alert}
      </div>
    }

    if (!this.state.dataDidLoad) {
      return <LoadingIcon />;
    }
    return (
      
      <div className="main-content">
<TableAPI
                                    fetchData={getUserGroups}
                                    columns={[
                                        
                                        
                                        {
                                           
                                            canFilter: false,
                                            id: 'name',
                                            width: 220,
                                            accessor: (el) => {return el}
                                        }

                ]}
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                        return {
                            onClick: (e) => {
                                this.onClickDetail(rowInfo.original)
                            },
                            style: {
                                cursor: "pointer"
                            }
                        }
                    } else {
                        return {}
                    }
                }}
              
                className="-highlight"

            />
      

      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.userData.isAuthenticated,
    pubKey: state.userData.publicKey,
    privKey: state.userData.privateKey,
    community: state.userData.communityAccount,
    cityName: state.userData.cityName,
    dashboardData: state.dashboard.data,
    
  };
};

export default connect(mapStateToProps)(Analytics);
// export default Analytics;
