import "./CommentBoxStyles.css";

import React, { Component } from "react";

import PropTypes from "prop-types";
import i18n from "i18next";

class CommentBox extends Component {
    state = {
        showEditComment: false,
        showDeleteConfirmation: false,
        comment: "",
    };

    componentDidMount() {
        this.setState({ comment: this.props.comment });
    }

    submitEdit = () => {
        this.props.editFunction(this.props.id, this.state.comment);
        this.setState({ showEditComment: false });
    };

    submitDelete = () => {
        this.props.deleteFunction(this.props.id);
    };

    render() {
        const editCommentPopup = (
            <div className="comment-box-edit-blur">
                <div className="comment-box-edit-container">
                    <h5 className="comment-box-edit-title">
                        {i18n.t("EDIT_COMMENT")}
                    </h5>
                    <hr />
                    <textarea
                        className="comment-box-edit-text-area"
                        value={this.state.comment}
                        onChange={(e) =>
                            this.setState({ comment: e.target.value })
                        }
                    />
                    <button
                        className="comment-box-edit-submit-btn"
                        onClick={() => this.submitEdit()}
                    >
                        {i18n.t("SUBMIT")}
                    </button>
                    <button
                        className="comment-box-edit-cancel-btn"
                        onClick={() =>
                            this.setState({
                                showEditComment: false,
                                comment: this.props.comment,
                            })
                        }
                    >
                        {i18n.t("CANCEL")}
                    </button>
                </div>
            </div>
        );

        return (
            <div className="comment-box-wrapper">
                {this.state.showEditComment && editCommentPopup}
                <div className="comment-box">
                    <div className="comment-box-header">
                        <span className="comment-author-span">
                            {this.props.author}
                        </span>
                        <span className="comment-edit-span">
                            {this.props.canEdit ? (
                                <span>
                                    <span
                                        className="comment-edit-btn"
                                        onClick={() =>
                                            this.setState({
                                                showEditComment: true,
                                            })
                                        }
                                    >
                                        {i18n.t("EDIT")}
                                    </span>
                                    {" | "}
                                    <span
                                        className="comment-delete-btn"
                                        onClick={() => this.submitDelete()}
                                    >
                                        {i18n.t("DELETE")}
                                    </span>
                                </span>
                            ) : null}
                        </span>
                    </div>
                    <div className="comment-box-body">{this.state.comment}</div>
                </div>
                <div className="comment-box-ts">
                    <i>{this.props.timestamp}</i>
                </div>
            </div>
        );
    }
}

CommentBox.propTypes = {
    id: PropTypes.number.isRequired,
    comment: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,
    canEdit: PropTypes.bool.isRequired,
    editFunction: PropTypes.func.isRequired,
    deleteFunction: PropTypes.func.isRequired,
};

export default CommentBox;

