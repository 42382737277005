import React, { Component } from "react";
import { Grid,
    Row,
    Col
} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import ReactTable from "react-table";
import { connect } from 'react-redux';
import { Trans } from "react-i18next";

class ChainTransferTokens extends Component {
    state = {
        tokenTransfers: []
    }

    async componentWillMount(){
    };

    render() {
        let defaultView = (
            <div>
                <br />
                
                <Grid fluid> 
                    <Row>
                    <Col md={12}>
                    
                        <Card
                            plain
                            ctTableFullWidth
                            ctTableResponsive
                            content={
                                <ReactTable
                                    data={this.props.tokenTransfersList}
                                    filterable
                                    columns={[
                                        {
                                            Header: <Trans>TIME_SENT</Trans>,
                                            id: 'blcok_time',
                                            accessor: (el) => {return el.block_time},
                                        },
                                        {
                                            Header: <Trans>FROM_ACCOUNT</Trans>,
                                            id: 'account_name',
                                            accessor: (el) => {return el.from_account},
                                        },
                                        {
                                            Header: <Trans>TO_ACCOUNT</Trans>,
                                            id: 'num_rides',
                                            accessor: (el) => {return el.to_account},
                                        },
                                        {
                                            Header: <Trans>AMOUNT</Trans>,
                                            id: 'phone',
                                            accessor: (el) => {return el.quantity},
                                        },
                                        {
                                            Header: <Trans>MEMO</Trans>,
                                            id: 'memo',
                                            accessor: (el) => {return el.memo},
                                        }
                                    ]}
                                    defaultPageSize={1000}
                                    sorted={[
                                            {
                                             id: 'block_time',
                                              desc: true
                                            }
                                            ]}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-highlight"
                                />
                            }
                        />
                    </Col>
                    </Row>
                </Grid>
                <p></p>
                <br></br>
                <br></br>
            </div>
        )

        return (
            <div className="content">
                {defaultView}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
      tokenTransfersList: state.tokenTransfer.tokenTransfersList
    };
  };
export default connect(mapStateToProps)(ChainTransferTokens);
