import { Col, Grid, Row } from "react-bootstrap";
import React, { Component } from "react";

import Card from "../../components/Card/Card.jsx";
import RideOpportunitySuccess from "../../const/RideOpportunitySuccess";
import TableAPI from "../Tables/TableAPI";
import { Trans } from "react-i18next";
import {
  getRideOpportunities,
} from "../../helpers/apiHelper";
import { utcStringToLocalString } from "../../helpers/dateHelper";
import { Link } from 'react-router-dom';

export default class rideOpportunityList extends Component {
  constructFormFromApi = async (data) => {
    const rideOpportunities = await getRideOpportunities(data);
    if (data.get_count) {
      return rideOpportunities;
    }
    let formRows = {};
    if (rideOpportunities instanceof Error) return rideOpportunities;
    for (var index in rideOpportunities) {
      const row = await this.getFormRowFromRideOpportunity(
        rideOpportunities[index]
      );
      formRows[index] = row;
    }
    return formRows;
  };

  getFormRowFromRideOpportunity = async (rideOpportunity) => {
    const rider = rideOpportunity.rider;
    const riderFullName = rider.first_name + " " + rider.last_name;
    const riderAccount = rideOpportunity.rider_account;
    const rideRequest = rideOpportunity.ride_request;
    const success = rideOpportunity.success;

    let row = {
      block_time: utcStringToLocalString(rideOpportunity.block_time),
      rider_account: riderAccount,
      rider_name: riderFullName,
      ride_request: rideRequest,
      success: success,
    };
    return row;
  };

  getStyleFromRow = (row) => {
    if (row) {
      const success = row.original.success;
      if (success) {
        return {
          style: { color: "green" },
        };
      } else {
        return {
          style: { color: "red" },
        };
      }
    } else {
      return {
        style: { color: "red" },
      };
    }
  };

  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <br />
            <Col md={12}>
              <Card
                plain
                ctTableFullWidth
                ctTableResponsive
                content={
                  <TableAPI
                    fetchData={this.constructFormFromApi}
                    columns={[
                      {
                        Header: <Trans>DATE</Trans>,
                        canFilter: false,
                        canSort: true,
                        defaultSort: true,
                        filterMode: "date",
                        id: "block_time",
                        width: 300,
                        accessor: "block_time",
                      },
                      {
                        Header: <Trans>RIDER</Trans>,
                        canFilter: true,
                        canSort: true,
                        id: "rider_name",
                        accessor: "rider_name",
                        width: 300,
                        Cell: (e) => (
                          <Link
                            to={`/rider?name=${e.original.rider_account.account}`}
                            target="_blank"
                            style={{ color: "#70CEEC", cursor: "pointer" }}
                            rel="noopener noreferrer"
                          >
                            {" "}
                            {e.value}{" "}
                          </Link>
                        ),
                      },
                      {
                        Header: <Trans>SUCCESS</Trans>,
                        canFilter: true,
                        filterMode: "select",
                        width: 300,
                        id: "success",
                        options: [
                          { value: true, label: <Trans>SUCCESSFUL</Trans> },
                          { value: false, label: <Trans>FAILED</Trans> },
                        ],
                        accessor: "success",
                        Cell: (row) => {
                          if (typeof row.original.ride_request.ride_request_id !== "undefined") {
                            return (
                              <Link
                                to={`/ride-request-summary?reqId=1de66757-2b9c-47a9-b53b-23c02dfc23cc`}
                                target="_blank"
                                style={{ color: "#70CEEC", cursor: "pointer" }}
                                rel="noopener noreferrer"
                              >
                                <Trans>
                                  {RideOpportunitySuccess[row.value.toString()]}
                                </Trans>
                              </Link>
                            );
                          } else {
                            return (
                              <span>
                                <Trans>
                                  {RideOpportunitySuccess[row.value.toString()]}
                                </Trans>
                              </span>
                            );
                          }
                        },
                        getProps: (state, row, column) => {
                          return this.getStyleFromRow(row);
                        },
                      },
                    ]}
                    className="-highlight"
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
        <p></p>
        <br></br>
      </div>
    );
  }
}

