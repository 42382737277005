import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { store, persistor } from "./store";
import AppComponent from "./AppComponent";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/light-bootstrap-dashboard.css?v=1.1.1";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import './GlobalStyleOverrides.css';
require("dotenv").config();

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppComponent />
      </PersistGate>
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
);
