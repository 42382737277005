// language ressources 
export const resources = {
    en: {
      translation: {
        // Business schedule
        "SUNDAY": "Sunday",
        "MONDAY": "Monday",
        "TUESDAY": "Tuesday",
        "WEDNESDAY": "Wednesday",
        "THURSDAY": "Thursday",
        "FRIDAY": "Friday",
        "SATURDAY": "Saturday",
        "CLOSED": "Closed",
        "OPEN_24_HOUR": "Open 24 hours",
        "MODIFY": "Modify",
        "SCHEDULE_EDITOR": "Schedule Editor",
        "SAVE_CHANGES": "Save changes",
        "WORKING_HOURS": "Working Hours (UTC)",

        // Restaurant
        "RESTAURANT_MENU": "Restaurants",
        "RESTAURANT_NEW": "Add",
        "RESTAURANT_LIST": "List",
        "RESTAURANT_NAME": "Name",
        "RESTAURANT_DESCRIPTION": "Description",
        "ENTER_RESTAURANT_DESCRIPTION": "Enter restaurant description",
        "RESTAURANT_LINK": "Link",
        "RESTAURANT_NAME": "Restaurant Name",
        "ADDRESS": "Address",
        "INVALID_ADDRESS": "Invalid email",
        "ENTER_RESTAURANT_NAME": "Enter restaurant name",
        "TRANSACTIONAL_LINK": "Transactional Link",
        "ENTER_TRANSACTIONAL_LINK": "Enter transactional link",
        "RESTAURANT_NEW_SUBTITLE": "New Restaurant",
        "RESTAURANT_EDIT_SUBTITLE": "Edit Restaurant",
        "CHANGE_RESTAURANT_IMAGE": "Change restaurant image",
        "RESTAURANT_IMAGE": "Restaurant image",
        "ENTER_RESTAURANT_ADDRESS": "Enter restaurant address",
        "RESTAURANT_CATEGORIES": "Food types",
        "ADD_NEW_FOOD_TYPE": "Add a new food type: ",
        "ENTER_FOOD_TYPES": "Enter restaurant food types",
        "SENDING_DATA": "Sending data",
        "ERROR_OCCURED": "An ERROR has occured : {{0}}",
        "UPDATED": "updated",
        "CREATED": "created",
        "SUCCESS_MESSAGE": "The restaurant has been successfully {{0}}.",
        "ENTER_ADDRESS": "Enter an address",
        "PERMANENT_RESTAURANT_DELETION": "This restaurant will we permanently deleted",
        "YES_DELETE": "Yes, delete it!",
        "DELETED": "deleted",


        //Business
        "BUSINESS_MENU": "Businesses",
        "BUSINESS_NEW": "Add",
        "BUSINESS_NEW_SUBTITLE": "New Business",
        "BUSINESS_EDIT_SUBTITLE": "Edit Business",
        "COMPANY_NAME": "Company Name",
        "COMPANY_ADDRESS": "Company Address",
        "COMPANY_APARTMENT_NUMBER": "Company Apartment Number",
        "COMPANY_PHONE": "Company Phone",
        "REGISTERED_ON": "Registration date",
        "BUSINESS_LIST": "List",
        "CONTACT_FIRST_NAME": "Contact First Name",
        "CONTACT_LAST_NAME": "Contact Last Name",
        "CONTACT_PHONE": "Contact Phone",
        "CONTACT_EMAIL": "Contact Email",
        "BILLING_EMAIL": "Billing Email(s) (comma separated if multiple)",
        "CONTACT_NAME": "Contact Name",
        "PREFERRED_LANGUAGE": "Preferred Language",
        "ADD_QUOTE_STRATEGY_TITLE": "Dynamic Quoting",
        "EDIT_QUOTE_STRATEGY_TITLE": "Edit Dynamic Quoting",
        "QUOTE_TYPE": "Quote Type",
        "TOTAL_REDUCTION": "Price Reduction",
        "FIXED_PRICE": "Fixed Price",
        "AMOUNT_CENT": "Amount in cent",
        "SAVE": "Save",
        "DELETE_QUOTE_STRATEGY": "Delete Dynamic Quoting",
        "CUSTOM_PRICING": "Éditer tarifs personnalisé pour : ",
        "USING_NORMAL_PRICING": "L'entreprise utilise présentement les tarifs normaux pour ce type de service.",
        "COMMUNITY_RATE_X100": "Taux (%) par la franchise ",
        "GLOBAL_RATE_X100": "Taux (%) par Eva Globale ",
        "MINUTE_RATE_CENT": "Frais à la minute (cent)",
        "KM_RATE_CENT": "Frais au kilomètre (cent)",
        "MIN_CHARGE": "Tarif mininum (cent)",
        "CANCEL_FEE_CENT": "Tarif d'annulation (cent)",
        "BASE_CHARGE": "Frais de base (cent)",
        "SUPPLEMENT_COMM_CHARGE": "Frais additionnel franchise (cent)",
        "SUPPLEMENT_COMM_CHARGE_DESCRIPTION": "Frais additionnel franchise description",
        "SUPPLEMENT_WORLD_CHARGE": "Frais additionnel Eva Global",
        "SUPPLEMENT_WORLD_CHARGE_DESCRIPTION": "Frais additionnel Eva Global Description",
        "RIDER_ACCOUNT":"Rider Account",
        "SEND_SMS_TRACKING":"Send Tracking Link by SMS",
        "SEND_EMAIL_TRACKING": "Send Tracking Link by Email",
        "PARENT_RIDER_ACCOUNT": "Parent Business Rider Account",
        "PARENT_CORPORATE_DONT_EXIST": "Parent Business doesn't exist",

        //LoginPage
        "LOGIN_EMAIL_LABEL": "Email",
        "Password": "Password",
        "LOGIN_BUTTON_LABEL": "Log In",
        "LOGIN_ERROR": "Error while logging in",

        //Footer
        "Home": "Home",
        
        //SideBar
        "Dashboard": "Dashboard",
        "Analytics": "Analytics",
        "Analytics Reports": "Reports",
        "Analytics Management": "Management",
        "Rides": "Rides",
        "Drivers": "Drivers",
        "Payments": "Payments",
        "Active rides": "Live",
        "Pending rides": "Current Rides Request",    
        "Pending payments": "Pending payments",
        "Driver Cancellation": "Driver Cancellation",
        "Rider Cancellation": "Rider Cancellation",
        "Historical payments": "Payments History",
        "Invoices": "Invoices",
        "Config": "Configuration",
        "Community config": "Financial information",
        "DRIVERS_MENU_COMMUNICATION": "Communication",

        //ViewTitles
        "Active Rides in": "Active Rides in ",
        "Pending Rides Requests in": "Pending Rides Requests in ",  
        "Drivers in": "Drivers in",
        

        //ActiveRidesList
        "route ID": "Route ID",
        "Rider ACCOUNT": "Rider Account",
        "status": "status",
        "Driver account": "Driver account",
        "Driver full Name": "Driver full Name",
        "Created date": "Created date",
        "Modified date": "Modified date",
        "is_driver_onsite": "On Site?",
        "is_driver_late": "Is Punctual?",
        "DRIVER_ONSITE_ARRIVED": "At pickup",
        "DRIVER_NOT_ONSITE": "Not there yet",
        "estimated_pickup_time": "Estimated Pickup Time",
        "elapsed_pickup_time": "Real Pickup Time",
        "STARTED":"Started?",
        "DRIVER_FOUND": "Driver",
        "FLAG_STARTED":"Started",
        "FLAG_NOT_STARTED":"Not Started",
        "MERGED_EST_MIN": "Estimated Time (minutes)",
        "MERGED_EST_DIST_KM": "Estimated Distance (km)",
        "CURRENT_STEP_EST_TIME": "Step Estimated time",
        "CURRENT_STEP_REAL_TIME": "Step Elapsed time",
        "NUMBER_OF_ORDERS":"Number of Orders",

        //RequestedRides
        "~Minutes": "~Minutes",
        "~KM": "~KM",
        "Amount": "Amount",
        "actions": "Actions",
        "action": "Action",

        //Buttons
        "Default": "Default",
        "Tweet": "Tweeter",
        "Share": "Share",
        "Share on Google+": "Share on Google+",
        "Connect with Linkedin": "Connect with Linkedin",
        "Pint it": "Pint it",
        "View on Youtube": "View on Youtube",
        "Repost": "Repost",
        "Connect with Github": "Connect with Github",
        "Follow us": "Follow us",
        "Find us on Dribbble": "Find us on Dribbble",
        "View on StumbleUpon": "View on StumbleUpon",

        //historicalRides
        "Filter on": "Filter on",
        "Download CSV": "Download Table in CSV",
        "Date Range": "Date Range",
        "End date": "End date",
        "End Time": "End Time",
        "rideID": "Route Id",
        "Driver Name": "Driver Name",
        "Driver profit": "Driver",
        "Community profit": "Community",
        "Global profit": "Global",
        "Ride cost": "Ride cost",
        "Commity Sup Fee": "Commity Sup Fee",
        "Global Sup Fee": "Global Sup Fee",
        "Total Cost": "Total Cost",
        "TPS": "TPS Fee",
        "TVQ": "TVQ Fee",
        "Total Taxes": "Total Taxes",
        "Total with Taxes": "Total with Taxes",
        "Credit Card Fee": "Credit Card Fee",
        "Total with Credit": "Total with Credit",
        "Tip Amount": "Tip Amount",
        "Tip Fee": "Tip Fee",
        "RIDER_NAME": "Rider's Name",
        "RIDER_CONNECTION_NUMBER": "Number of App Access",

        //RideOpportunitiesTable
        "RIDE_OPPORTUNITIES": "Opportunities",
        "SUCCESSFUL": "Successful",
        "FAILED": "Failed",

        // DriverCanceledRides
        "Number of cancelled ride by driver in": "Number of cancelled ride by driver in",
        "Number of cancellation": "Number of cancellation",
        "Number of completed rides": "Number of completed rides",
        "Score": "Score",

        //ExtendedTables
        "View Profile": "View Profile",
        "Edit Profile": "Edit Profile",
        "Remove": "Remove",
        "View Post": "View Post",
        "Edit Post": "Edit Post",
        "Remove Post": "Remove Post",
        "Name": "Name",
        "Job Position": "Job Position",
        "Salary": "Salary",

        //ReactTables
        "Position": "Position",
        "Office": "Office",
        "Age": "Age",

        //DriverInfoForm
        "Community (disabled)": "Community (disabled)",
        "Phone Number": "Phone Number",
        "Eos Account": "Eos Account",
        "Email address": "Email address",
        "First Name": "First Name",
        "Last Name": "Last Name",
        "tps": "TPS",
        "tvq": "TVQ",
        "Google Form":"Field from google sheet",
        "Vehicle model": "Vehicle model",
        "Vehicle year": "Vehicle year",
        "Vehicle color": "Vehicle color",
                        ////Color
                        "Silver": "Silver",
                        "White": "White",
                        "Blue": "Blue",
                        "Gray": "Gray",
                        "Red": "Red", 
                        "Green": "Green",
                        "Brown": "Brown",
                        "Yellow": "Yellow",
                        "Orange": "Orange",
                        "Pink": "Pink",
                        "Purple": "Purple",
                        "Dark Blue": "Dark Blue",
                        "Dark Gray": "Dark Gray",
                        "Dark Red": "Dark Red",
                        "Dark Green": "Dark Green",
                        "Dark Brown": "Dark Brown",
                        "Bright Red": "Light Red",
                        "Bright Blue": "Light Blue",
                        "Bright Green": "Light Green",
                        "Bright Gray": "Light Gray",
                        "Bright Brown": "Light Brown",
       "Vehicle mileage": "Vehicle mileage",
       "Allowed multi ride": "Multi ride permission",
       "Allowed Cash Payment": "Cash Payment Permission",
       "Vehicle identification number": "Vehicle identification number",
       "Vehicle registration certificate file number": "Vehicle registration certificate file number",
       "VEHICLES": "Vehicles",
       "VEHICLE_TYPE": "Vehicle Type",
       "VEHICLE": "Vehicle",
       "Registration plate number": "Registration plate number",
       "NEW_VEHICLE": "Add another vehicle",
       "Driver licence number": "Driver licence number",
       "Financial institute name": "Financial institute name",
       "Financial institute number": "Financial institute number",
       "Transit number": "Transit Number",
       "Account number": "Account Number",
       "Go Back": "Go Back",


       //DriverHistoricalCashout
       "Community": "Community",
        "Payment Date": "Payment Date",
       "EVA Tokens": "Eva Tokens",
       "cad": "CAD",
       "From": "From ",
       "To": " to ",

       //PendingCashout
       "Full Name": "Full Name",
       "Requested Date": "Requested Date",
       "All rides": "All rides",
       "Completed rides": "Completed rides",
       "Canceled Rides": "Canceled Rides",
       "EOS Account": "EOS Account",
       "Institution": "Institution",
       "Transit": "Transit Number",
        "Select All": "Select All", 
        "Issue All Selected": "Issue All Selected", 
        "Requested Time": "Requested Time",

        "Sequence ID": "Sequence ID",
        "Force_Sequence": "Force Sequence:",
        "select": "Select",
        "Error_Force_Sequence": "The sequence number must be between 0 - 999.",
        "Download": "Download",
        "Error_More_Than_One_Payment": "Please select at least one payment.",
        "Error_No_Sequence_Payment": "One or more payments do not have a sequence number. Please generate one before proceeding.",
        "Error_Different_Sequence_Ids": "Some of the selected payments have conflicting sequence numbers. Please select only payments with a same sequence number.",
        "Issuing_Payments_Confirmation": "Issuing Payments Confirmation",
        "Issuing_Payments_Confirmation_Yes": "Yes, issue the payments!",
        "Issuing_Payments_Confirmation_Warning": "You will be issuing payments for the following cashouts:",
        "Generate_Deposit_Confirmation": "Confirm Generate Deposit",
        "Generate_Deposit_Confirmation_Yes": "Generate deposit file",
        "Generate_Deposit_Confirmation_Warning": "Generate a deposit file for sequence <b>#{{sequence_number}}</b> with the following cashouts?",
        "Generate_Deposit_Error": "Something went wrong while generating the deposit file.",
        "Generate_Deposit_Success": "Successfully generated file.",
        "Generate_Deposit": "Generate Deposit File",
        "Payments_Have_Sequence_Warning": "Some of the selected payments already have a sequence number. Do you still want to continue?",
        "Generate_Sequence": "Generate sequence",
        "Generate_Sequence_Confirmation_Warning": "You will generate the sequence <b>#{{sequence_id}}</b> with the following cashouts:",
        "Generate_Sequence_Success": "Successfully generated a sequence.",
        "Generate_Sequence_Error": "An error has occurred while generating a sequence.",
        "Payment_Request_Success": "The payment request has been successfully processed and validated.",
        "Payment_Confirmation": "Issue payment confirmation",
        "Payment_Confirmation_Yes": "Yes, confirm the payment",
        "Payment_Confirmation_Warning": "You are about to issue the payment of <b>{{amount}}</b> requested by the following driver: <b>{{driver}}</b>. Do you want to proceed?",

        //Invoices
        "Invoice Number": "Invoice Number",
        "Payment Type": "Payment Type",
        "Mark paid": "Mark paid",
        "Mark unpaid": "Mark unpaid",
        "Files": "Files",
        "Paid": "Paid",
        "Total": "Total",
        "CREDIT_CARD": "Credit card",
        "MANUAL": "Manual",
        "PAID_DATETIME": "Date of payment",
        
        "From_invoice": "Start of period",
        "To_invoice": "End of period",

        //DriverList
        "Add driver": "Add Driver",
        "Edit driver": "Edit Driver",
        "ACTIONS_DRIVER": "Actions",
        "DEACTIVATE_TITLE": "Deactivate",
        "DEACTIVATION_CONFIRMATION": "Do you wish to deactivate driver",
        "DEACTIVATION_SUCCESSFUL": "has been deactivated successfully from the community",
        "Add new driver": "Add New Driver",
        "phone": "Phone",
        "DRIVERS_MENU_ACTIVE" :"Active",
        "DRIVERS_MENU_INACTIVE" :"Inactive",
        "Updated now" : "Updated now",
        "Online": "Online",
        "Activate driver": "Activate Driver",
        "Allowed":"Allowed",
        "Denied":"Denied",
        "City": "City",

        //RideSubTable
        "STARTED_DATETIME":"Started Time",
        "DONE_DATETIME": "Completed Time",

        // New Submission (add driver)
        "DRIVERS_MENU_NEW_SUBMISSION": "New Submission",
        "NEW_SUBMISSION_PHONE_LABEL_TEXT": "Please enter the phone number of the rider member who wishes to become a driver member.",
        "NEW_SUBMISSION_PHONE_FORMAT_ERROR": "The number entered is invalid.",
        "NEW_SUBMISSION_CREATE_BUTTON": "Open",
        "NEW_SUBMISSION_CREATE_NEW_PROMPT": "You are about to open a submission for {{fullName}}. Would you like to continue?",
        "NEW_SUBMISSION_NO_RIDER_FOUND_PROMPT": "There is no rider account associated with this phone number. The account may be synchronizing or may be already a driver.",
        "NEW_SUBMISSION_PHONE_REJECTED_BY_SERVER_PROMPT": "Phone number rejected by the server.",
        "NEW_SUBMISSION_HEADER_TITLE": "New Applicant",
        "NEW_SUBMISSION_DRIVER_TAB": "Driver",
        "NEW_SUBMISSION_VEHICLE_TAB": "Vehicle",
        "NEW_SUBMISSION_SEND_REMINDER_BUTTON": "Send Reminder",
        "NEW_SUBMISSION_SEND_REMINDER_CONFIRMATION": "You are about to send a reminder by email. Would you like to continue?",
        "NEW_SUBMISSION_ARCHIVE_BUTTON": "Archive",
        "NEW_SUBMISSION_OPEN_BUTTON": "Open",
        "NEW_SUBMISSION_TITLE_BASIC_INFO": "Basic Information",
        "NEW_SUBMISSION_TITLE_CUSTOM_INFO": "Additional Information",
        "NEW_SUBMISSION_SELECT_OPTION": "Select an option",
        "NEW_SUBMISSION_REMOVE_VEHICLE": "Remove",
        "NEW_SUBMISSION_CHOOSE_FILE": "No file selected",
        "NEW_SUBMISSION_UPLOADED_FILE_ERROR": "❌ Error while uploading. Please try again.",
        "NEW_SUBMISSION_CANNOT_OPEN_IMAGE": "Unable to open the requested file at this time. Contact the development team if the problem persists.",
        "NEW_SUBMISSION_MAX_FILE_SIZE": "The file size can't exceed 25 MB.",
        "NEW_SUBMISSION_ACCEPTED_FILE_FORMAT": "Accepted file types are jpeg, gif, png and pdf.",
        "NEW_SUBMISSION_VALIDATION_ERROR": "Validation error",
        "NEW_SUBMISSION_SAVE_BUTTON": "Save Submission",
        "NEW_SUBMISSION_VALIDATE_ALL_BUTTON": "Validate All",
        "NEW_SUBMISSION_EDIT_BUTTON": "Edit Submission",
        "NEW_SUBMISSION_COMPLETE_BUTTON": "Complete driver's submission",
        "NEW_SUBMISSION_CONFIRMATION_ALERT_TEXT": "The request will be sent to the server and it will not be possible to edit this submission anymore.",
        "NEW_SUBMISSION_ERROR_VERIFY_FIELDS": "Verify the following fields: ",
        "NEW_SUBMISSION_ERROR_CONTACT_DEV_TEAM": "Contact the development team if the problem persists.",
        "NEW_SUBMISSION_ERROR_UPDATE_PROMPT": "Unable to update the submission.",
        "NEW_SUBMISSION_ERROR_MISSING_FIELDS_PROMPT": "Can't proceed to validation because some fields are invalid.",
        "NEW_SUBMISSION_ERROR_VALIDATE_ALL_PROMPT": "Unable to validate the submission.",
        "NEW_SUBMISSION_ERROR_STATUS_PROMPT": "Unable to update the status of the submission. Contact the development team if the problem persists.",
        "NEW_SUBMISSION_ERROR_VALIDATE_STATUS_PROMPT": "Unable to update the status. Contact the development team if the problem persists.",
        "NEW_SUBMISSION_SUCCESS_ALERT_TEXT": "The submission has been successfully processed. Welcome to our driver member!",
        "NEW_SUBMISSION_ERROR_ALERT_TEXT": "Unable to create a driver account at this time. Please try again later or contact the development team.",
        // Driver Fields
        "NEW_SUBMISSION_FIRST_NAME": "First Name",
        "NEW_SUBMISSION_LAST_NAME": "Last Name",
        "NEW_SUBMISSION_CHAIN_ACCOUNT_NUMBER": "Blockchain number",
        "NEW_SUBMISSION_PREFERRED_LANGUAGE": "Preferred Language",
        "NEW_SUBMISSION_PREFERRED_LANGUAGE_OPTIONS": {
                "FR": "French",
                "EN": "English"
        },
        "NEW_SUBMISSION_PHONE": "Phone Number",
        "NEW_SUBMISSION_EMAIL": "Email Address",
        "NEW_SUBMISSION_CITY": "City",
        "NEW_SUBMISSION_DATE_OF_BIRTH": "Date Of Birth",
        "NEW_SUBMISSION_FINANCIAL_INSTITUTION_NUMBER": "Financial Institution Number",
        "NEW_SUBMISSION_FINANCIAL_INSTITUTION_ACCOUNT_NUMBER": "Bank Account Number",
        "NEW_SUBMISSION_FINANCIAL_INSTITUTION_TRANSIT_NUMBER": "Bank Account Transit Number",
        "NEW_SUBMISSION_CRIMINAL_BACKGROUND_CHECK": "Criminal Backgroud Check",
        "NEW_SUBMISSION_CRIMINAL_BACKGROUND_CHECK_DATE": "Criminal Background Check Issued Date",
        "NEW_SUBMISSION_DRIVER_TRAINING_PHOTO": "Driver Training Certificate",
        "NEW_SUBMISSION_DRIVER_TRAINING_DATE": "Date Of Completion Of Driver Training",
        "NEW_SUBMISSION_DRIVER_LICENSE_NUMBER": "Driver License Number",
        "NEW_SUBMISSION_DRIVER_LICENSE_EXPIRATION_DATE": "Driver's License Expiration Date",
        "NEW_SUBMISSION_DRIVER_LICENSE_PHOTO": "Photo Of Driver's License",
        "NEW_SUBMISSION_TPS_NUMBER": "TPS Number", 
        "NEW_SUBMISSION_TVQ_NUMBER": "TVQ Number",
        "NEW_SUBMISSION_REVENU_QUEBEC_AUTHENTIFICATION_NUMBER": "Revenu Québec Authorization Code",
        "NEW_SUBMISSION_REVENU_QUEBEC_IDENTIFICATION_NUMBER": "Revenu Québec Identification Number",
        "NEW_SUBMISSION_REVENU_QUEBEC_BILLING_NUMBER": "Revenu Québec Billing Number",
        // Vehicle Fields
        "NEW_SUBMISSION_VEHICLE_TYPE_ID": "Category",
        "NEW_SUBMISSION_ALLOWED_RIDE_SERVICES": "Authorized Service Types",
        "NEW_SUBMISSION_COLOR": "Color",
        "NEW_SUBMISSION_COLOR_OPTIONS": {
                "GREEN" : "Green",
                "RED" : "Red",
                "BLUE" : "Blue",
                "GREY" : "Grey",
                "BLACK" : "Black",
                "INDIGO" : "Indigo",
                "ORANGE" : "Orange",
                "YELLOW" : "Yellow",
                "PURPLE" : "Purple",
                "WHITE" : "White", 
                "BROWN" : "Brown",
                "PINK" : "Pink",
        },
        "NEW_SUBMISSION_BRAND": "Manifacturer",
        "NEW_SUBMISSION_MODEL": "Model",
        "NEW_SUBMISSION_YEAR": "Year",
        "NEW_SUBMISSION_MILEAGE": "Mileage",
        "NEW_SUBMISSION_VEHICLE_IDENTIFICATION_NUMBER": "Vehicle Identification Number (VIN)",
        "NEW_SUBMISSION_VEHICLE_REGISTRATION_CERTIFICATE_FILE_NUMBER": "File Number Issued By The SAAQ",
        "NEW_SUBMISSION_VEHICLE_REGISTRATION_PLATE_NUMBER": "Plate Number",
        "NEW_SUBMISSION_VEHICLE_MECHANICAL_INSPECTION": "Mechanical Inspection",
        "NEW_SUBMISSION_VEHICLE_MECHANICAL_INSPECTION_DATE": "Mechanical Inspection Date",
        "NEW_SUBMISSION_INSURANCE_INSTITUTION_NAME": "Name Of The Insurer",
        "NEW_SUBMISSION_INSURANCE_INSTITUTION_NAME_OPTIONS": {
                "ALLSTATE_ASSURANCE": "Allstate Insurance",
                "AVIVA_CANADA": "Aviva Canada",
                "BANQUE_NATIONALE_ASSURANCES": "National Bank Insurance",
                "BC_ASSUR": "BC Assur",
                "BELAIRDIRECT": "belairdirect",
                "CO_OPERATORS": "Co-operators",
                "COSECO_COMPAGNIE_DASSURANCE": "COSECO Insurance Company",
                "DESJARDINS_ASSURANCES": "Desjardins Insurance",
                "ECONOMICAL_ASSURANCE": "Economical Insurance",
                "INDUSTRIELLE_ALLIANCE": "Industrial Alliance",
                "INTACT_ASSURANCES": "Intact Insurance",
                "LA_CAPITALE_ASSURANCE": "La Capitale Insurance / Beneva",
                "ROYAL_SUN_ALLIANCE_DU_CANADA": "RSA Assurance",
                "SSQ_ASSURANCE": "SSQ Insurance",
                "WAWANESA_ASSURANCE": "Wawanesa Insurance",
                "AUTRE": "Other"
        },
        "NEW_SUBMISSION_INSURANCE_POLICY_NUMBER": "Insurance Policy Number",
        "NEW_SUBMISSION_INSURANCE_POLICY_PHOTO": "Photo Of The Insurance Policy",

        "NEW_SUBMISSION_VEHICLE_GRAY_CARD_NUMBER": "Gray Card Number",
        "NEW_SUBMISSION_VEHICLE_GRAY_CARD_EXPIRY": "Gray Card Expiration Date",
        "NEW_SUBMISSION_VEHICLE_GRAY_CARD_PHOTO": "Photo of Gray Card",
        "NEW_SUBMISSION_TECHNICAL_VISIT_CERTIFICATE_PHOTO": "Photo of Technical Visit Certificate",
        "NEW_SUBMISSION_TECHNICAL_VISIT_CERTIFICATE_EXPIRY": "Technical Visit Certificate Expiration Date",
        "NEW_SUBMISSION_INSURANCE_EXPIRATION_DATE": "Insurance Expiration Date",
        "NEW_SUBMISSION_FINANCIAL_INSTITUTION_RIB_KEY": "RIB key",
        "NEW_SUBMISSION_FINANCIAL_INSTITUTION_NAME": "Bank Name",
        "NEW_SUBMISSION_VEHICLE_OWNER": "Vehicle Owner",
        "NEW_SUBMISSION_VEHICLE_OWNER_RUT": "Vehicle Owner RUT Number",

        // Driver Submissions List
        "DRIVERS_MENU_SUBMISSIONS_LIST": "Submissions List",
        "DRIVERS_MENU_OLD_SUBMISSIONS_LIST": "Submissions List",
        "DRIVER_SUBMISSION_DETAIL": "Submission Detail",
        "DRIVER_FINANCIAL_INFO": "Financial info",
        "DRIVER_VEHICLE_INFO": "Vehicle info",
        "DRIVER_LICENSE_INFO": "Driver license info",
        "DRIVER_OTHER_INFO": "Other info",
        "Open File": "Open File",
        "SEND_REMINDER_ALERT_TEXT": "You are about to send an email reminder to {{name}} ({{email}}). Do you want to continue?",
        "SEND_REMINDER_ALERT_SUCCESS_TEXT": "The reminder was successfully sent.",
        "SEND_REMINDER_ALERT_ERROR": "An error has occured while sending the email.",

        // Driver submission field translations
        "License_expiration": "Expiry Date",
        "License_number": "License Number",
        "Registration_plate": "Registration Plate",
        "language": "Language",
        "insurance_number": "Insurance Number",
        "insurance_institution": "Insurance Institution",
        "insurance_photo": "Insurance Photo",
        "driver_licence_photo": "Driver Licence Photo",
        "criminal_background_check": "Criminal Background Check",
        "mechanical_inspection": "Mechanical Inspection",
        "address": "Address",
        "tlc_driver_license_number": "TLC Driver License Number",
        "tlc_driver_license_expiration": "TLC Driver License Expiration",
        "fh1_insurance_policy_number": "FH1 Insurance Policy Number",
        "fh1_insurance_policy_effective_date": "FH1 Insurance Policy Effective Date",
        "fh1_insurance_policy_expiration_date": "FH1 Insurance Policy Expiration Date",
        "vehicle_identification_number": "Vehicle Identification Number",
        "vehicle_identification_expiration": "Vehicle Identification Expiration",
        "tlc_inspection_number": "TLC Inspection Number",
        "tlc_inspection_expiration": "TLC Inspection Expiration",
        "certificate_of_liability": "Certificate of Liability",
        "vehicle_inspection": "Vehicle Inspection",
        "commercial_insurance": "Commercial Insurance",
        "vehicle_registration": "Vehicle Registration",
        "class_155_registration": "Class 1-55 Registration",
        "three_year_driver_abstract": "Three-year driver abstract",
        "elvis_inspection": "E.L.V.I.S. Inspection",
        "tvdl": "Transportation Network Driver's Licence",
        "file_upload": "Files",
        "creation_date": "Creation date",
        "progress": "Progress",
        "status": "Status",
        "REGISTERED": "Registered",
        "ACTIVE": "Active",
        "CANCELLED": "Cancelled",
        "SUSPENDED": "Suspended",
        "APPLIED": "Applied",
        "COMPLETED": "Completed",
        "VALIDATED": "Validated",
        "ALL": "All",
        "OPEN": "Open",
        "ARCHIVED": "Archived",
        "reminder": "Reminder",
        "send": "Send",
        "last_reminder_sent": "Last reminder",
        "LAST_ACTIVITY": "Last activity",

        // Driver submissions category names
        "personal_data": "Personal data",
        "tlc": "TLC",
        "fh1": "FH1",
        "vehicle": "Vehicle",
        "SEE_BALANCE_TRANSACTIONS": "See Balance & Transactions",

        "RIDERS_MENU": "Riders",
        "ACTIVE_RIDERS": "Active Riders",
        "BLACKLISTED_RIDERS": "Blacklisted Riders",
        "RIDER_DETAIL": "Rider Detail",
        "CREATED_AT": "Creation Time",
        "CHAIN_ACCOUNT": "Blockchain Account",
        "HOURLY_RATE": "Hourly wage",
        "RIDER_NUMBER_OF_RIDES": "Number of rides",
        "RIDER_TRIES_LENGTH": "Number of requested rides",
        "RIDER_SUCCESS_RATE": "Success Rate",
        "RIDERSLIST_TITLE": "Rides in",
        "FROM_ADDRESS": "From Address",
        "TO_ADDRESS": "To Address",
        "LOGS": "LOGS",
        "TOKEN_TRANSFER": "Token Transfer",
        "FROM_ACCOUNT": "From",
        "TO_ACCOUNT": "To",
        "RIDE_REQUEST": "Request",
        "AMOUNT": "AMOUNT TRANSFERRED",
        "TIME_SENT": "TIME",
        "MEMO": "MEMO",
        "ALL_REQUESTS": "All Requests",
        "RIDES_COMPLETED": "Billed",
        "SCHEDULED_RIDES": "Scheduled",
        "CUSTOMER_ORDER": "Customer Orders",
        "DRIVER_ROUTES":"Driver Routes",
        "ARCHIVED_CUSTOMER_ORDERS":"Archived Customer Orders",
        "SCHEDULED_RIDES_ARCHIVED": "Archived Scheduled",
        "RIDES_COMPLETED_TITLE": "Rides Billed",
        "REQUEST_STATUS": "Status",
        "SUCCESS": "Success",
        "NO_DRIVER": "No Driver",
        "CANCELLED_BY_COMMUNITY": "Cancelled by coop",
        "CANCELLED_BY_RIDER": "Cancelled by rider",
        "CANCELLED_BY_DRIVER": "Cancelled by driver",
        "NOT_YET_ACCEPTED": "Looking for driver",
        "LIVE": "In process",
        "MORE_DETAILS": "Details",
        "RIDER_BALANCE": "Balance",
        "RIDER_ACTION_SEND_SHRDISCOUNT": "Send a shared discount",
        "RIDER_ACTION_SEND_MONEY": "Send Eva Tokens", 
        "RIDER_ACTION_CREATE_DEBT": "Withdraw Eva Tokens",
        "RIDER_ACTION_BLACKLIST": "Blacklist Rider",
        "RIDER_ACTION_WHITELIST": "Whitelist Rider",
        "RIDER_ACTION_DELETE": "Delete Rider",
        "RIDER_ACTION_SEND_SHRDISCOUNT_BUTTON": "Send",
        "RIDE_AMOUNT_LABEL": "Token Amount",
        "RIDE_AMOUNT_FIAT_LABEL": "Currency amount",
        "CURRENCY_ESTIMATE_DISCLAIMER":"This is an estimate and can vary based on exchange rates",
        "RIDER_ACTION_TITLE": "List of actions",
        "RIDE_ACTION_TITLE": "Actions on the ride",
        "RIDE_ACTION_SEND_INVOICE": "Send the invoice",
        "RIDE_INVOICE_SEND_TO_EMAIL": "Email",
        "RIDER_EMAIL": "Email",
        "RIDER_LAST_CONNECTION": 'Last Connection',
        "RIDER_NUMBER_CONNECTION": "Connections",
        "DEACTIVATE_DRIVER": "Deactivate driver",
        "SEND_SHARED_DISCOUNT_CONFIRM": "Send a shared discount to this rider member?",
        "SEND_BLACKLIST_CONFIRM": "Block the rider member",
        "SEND_WHITELIST_CONFIRM": "Allow the rider member",
        "DELETE_RIDER_CONFIRM": "Delete the rider account",
        "BLACKLIST_RIDER_SUCCESSFULL": "Rider successfully blocked",
        "WHITELIST_RIDER_SUCCESSFULL": "Rider successfully unblocked",
        "DELETE_RIDER_SUCCESSFULL": "Rider successfully deleted",
        "SEND_TOKEN_CONFIRM": "Send this token amount to the user?",
        "NEW_DEBT_TOKEN_CONFIRM": "Withdraw this number of token to the user?",
        "SHARED_DISCOUNT_SUCCESSFULLY_SENT": "Shared discount sent successfully.",
        "ARE_YOU_SURE": "Are you sure?",
        "YES_DO_IT": "Yes, do it.",
        "YES_ACTIVATE_DRIVER": "Yes, activate driver!",
        "CANCEL": "Cancel",
        "CANNOT_SEND": "Can't send tokens",
        "OK": "Ok",
        "MISSING_INFO_TOKEN": "You are missing info to send tokens",
        "SENDING": "Sending",
        "SENDING_REQUEST": "Sending request...",
        "SENDING_TOKEN_USER": "Sending this token amount to the user",
        "SENDING_DEACTIVATING_DRIVER": "Sending action to deactivate driver",
        "ERROR_ALERT": "An error has occured",
        "ERROR": "Error",
        "DRIVER_DISPATCHED_NUMBER": "Driver dispatched number",
        "DRIVER_RATING": "Rating",
        "DRIVER_SUCCESS_RATIO": "Success",
        "DRIVER_ACCEPTANCE_RATIO": "Acceptance",
        "NO_RIDES": "N/A",
        "RIDE_DETAILS": "Ride Details",
        "REQUEST_SUMMARY": "Request Summary",
        "TOKENS_SUCCESSFULLY_SENT": "Tokens sent succesfully.",
        "DRIVER_SUCCESSFULLY_DEACTIVATED": "Driver successfully deactivated",
        "DEBT_SUCCESFULLY_SENT": "Tokens successfully withdrawn",
        "DRIVER_NAME": "Driver's Name",
        "TRANSACTION_HISTORY": "Transaction history",
        "RIDER_BLACKLISTED": "This rider is blacklisted!",
        "DELETE_DRIVER_CONFIRMATION": "Do you want to deactivate this driver account: ",
        "ACTIVATE_DRIVER_CONFIRMATION": "Do you want to activate this driver account: ",
        "ACTIVATE_DRIVER_SUCCESS": "This driver account has been activated successfully:",
        "ACTIVATED": "Activated",
        "ACTIVATE_PERMISSIONS_CONFIRM": "Remember to re-enable the permissions of the driver.",
        "RIDER_NOT_FOUND": "Rider not found",
        "RIDER_NOT_FOUND_ERROR_MESSAGE": "The rider with the following account name does not exist: ",
        "WARNING": "Warning",
        "CONTINUE": "Continue",

        //DASHBOARD MINIS
        //Rides
        "DASHBOARD_ACTIVE_RIDE_MINI":"L",
        "DASHBOARD_CUSTOMER_ORDERS_MINI":"CO",
        "DASHBOARD_DRIVER_ROUTES_MINI":"DR",
        "DASHBOARD_BILLED_MINI":"B",
        "DASHBOARD_ARCHIVED_CUSTOMER_ORDERS_MINI":"ACO",
        "DASHBOARD_ALL_REQUESTS_MINI":"AR",
        "DASHBOARD_RIDE_OPPORTUNITY_MINI":"RO",
        "DASHBOARD_CANCELLED_MINI":"C",
        //Riders
        "DASHBOARD_ACTIVE_RIDERS_MINI":"AR",
        "DASHBOARD_BLACKLISTED_RIDERS_MINI":"BR",
        //DRIVERS
        "DASHBOARD_ACTIVE_DRIVER_MINI":"AR",
        "DASHBOARD_INACTIVE_DRIVER_MINI":"AR",
        "DASHBOARD_JTF_DRIVER_MINI":"JTF",
        "DASHBOARD_ONLINE_DRIVER_MAP_MINI":"OM",
        "DASHBOARD_NEW_DRIVER_SUBMISSIONS_MINI":"NS",
        "DASHBOARD_DRIVER_SUBMISSIONS_LIST_MINI":"SL",
        "DASHBOARD_DRIVER_COMMUNICATION_MINI":"COM",
        //BUSINESS
        "DASHBOARD_BUSINESS_LIST_MINI":"L",
        "DASHBOARD_NEW_BUSINESS_MINI":"N",
        //RESTAURANTS
        "DASHBOARD_RESTAURANTS_LIST_MINI":"L",
        "DASHBOARD_NEW_RESTAURANTS_MINI":"N",
        //PAYMENTS
        "DASHBOARD_PENDING_PAYMENTS_MINI":"PP",
        "DASHBOARD_PAYMENT_HISTORY_MINI":"PH",
        "DASHBOARD_PAYMENT_HISTORY_MINI":"PH",
        "DASHBOARD_PAYMENT_CASH_COLLECTION_MINI": "CC",
        "DASHBOARD_INVOICES_MINI":"I",
        //ANALYTICS
        "DASHBOARD_ANALYTICS_MANAGEMENT_MINI":"M",
        //LOGS
        "DASHBOARD_LOGS_TOKEN_TRANSFER_MINI":"TT",

        //Dashboard Graphs
        "DASHBOARD_SUCC_RIDES_TITLE": "Cumulative Number of Successful Rides",
	"DASHBOARD_CURRENT_MONTH": "Current Month",
        "DASHBOARD_PREV_MONTH": "Previous Month",
	"DASHBOARD_NEW_USERS_TITLE": "Cumulative Number of New Users",
        "DASHBOARD_TWO_MONTHS_AGO": "Two Months Ago",
        "DASHBOARD_THREE_MONTHS_AGO": "Three Months Ago",
        "DASHBOARD_FOUR_MONTHS_AGO": "Four Months Ago",
        "DASHBOARD_CURRENT_RIDES": "Current Rides",
        "NEW_DRIVERS": "New Drivers",
        "MONTHLY_GROWTH": "Monthly Growth",
        "DASHBOARD_RIGHT_NOW": "Right Now",
        "DASHBOARD_NUM_RIDES": "Number of Rides",
        "DASHBOARD_SINCE_BEG": "Since beginning",
        "DASHBOARD_LAST_WEEK": "Last week",
        "DASHBOARD_RIDES_TODAY": "Rides Today",
        "DASHBOARD_RIDES_TODAY_SUCCESSFUL": "Successful Rides Today",
        "DASHBOARD_RIDES_TODAY_FAILED": "Failed Rides Today",
        "DASHBOARD_UPDATED_NOW": "Updated now",
        "DASHBOARD_TODAY": "Today",
        "DASHBOARD_REVENUE_TODAY": "Revenue Today",
        "DASHBOARD_CURRENT_STATUS": "Current Status",
        "DASHBOARD_SUCCESS": "Successful",
        "DASHBOARD_CANCELLED_COMM": "Cancelled By Community",
        "DASHBOARD_CANCELLED_DRIVER": "Cancelled By Driver",
        "DASHBOARD_CANCELLED_RIDER": "Cancelled By Rider",
        "UNKNOWN_CANCELLATION": "Cancelled",
        "DASHBOARD_NEVER_ACC": "Never Accepted",

        //Analytics
        "ANALYTICS_SALES": "Sales",
        "ANALYTICS_REPORTS": "Reports",
        "ANALYTICS_EXECUTIVE": "Executive",
        "ANALYTICS_REPORT_TYPE": "Report Groups",
        "ANALYTICS_NO_GROUP_SELECTED": "Please select at least one group",
        "ANALYTICS_OPERATIONS": "Operations",
        
        //Driver Edit
	"DRIVER_IS_RIDER_LINK": "Click Here for the Driver's Rider Page",
        "DRIVER_EDIT_MORE_INFO": "More Info",
        "DRIVER_EDIT_CLOSE": "Close",
        "DRIVER_EDIT_ONLINE_SESSIONS": "Online Sessions",
        "DRIVER_EDIT_START": "Start of Session",
        "DRIVER_EDIT_END": "End of Session",
        "DRIVER_EDIT_DURATION": "Duration",
        "DRIVER_EDIT_DATE": "Date",

        //Ride and Ride Req Details 
        "MAP_STAT_CARD_TITLE": "MAP",
        "INFO_STAT_CARD_TITLE": "INFORMATION",
        "ACTIONS_STAT_CARD_TITLE": "ACTIONS",
        "MAP_STAT_CARD_HINT": "Show Map",
        "INFO_STAT_CARD_HINT": "Show Info",
        "ACTIONS_STAT_CARD_HINT": "Show Actions",
        "HIDE_STAT_CARD_HINT": "Hide",
        "MAP_LABEL_PICKUP": "Driver's Route to Pickup",
        "MAP_LABEL_DEST": "Driver's Route to Destination",
        "MAP_LABEL_OPTIMAL": "Optimal Route",
        "MAP_MARKER_RIDE_ACC": "Ride Accepted",
        "MAP_MARKER_PICKUP": "Pick Up",
        "MAP_MARKER_DEST": "Destination",
        "MAP_MARKER_ADDRESS": "Address: ",
        "ALL_REQUESTS_DETAILS_TAB": "Details",
        "INFO_TABLE_TITLE": "General",
        "INFO_TABLE_START": "Start",
        "INFO_TABLE_END": "End",
        "INFO_TABLE_DISTANCE": "Estimated distance between pickup and destination",
        "INFO_TABLE_FROM": "From",
        "INFO_TABLE_TO": "To",
        "INFO_TABLE_SUCC": "Successful",
        "INFO_TABLE_DRIVER": "Driver",
        "INFO_TABLE_RIDER": "Rider",
        "INFO_TABLE_RIDE_REQUEST": "Associated ride request",
        "INFO_TABLE_PROOF": "Photo delivery proof",
        "INFO_TABLE_ONSITE_DELTA": "Minutes waited at pickup",
        "REQUEST_DETAILS_GENERAL_TITLE": "General",
        "REQUEST_DETAILS_GENERAL_ID": "Route ID",
        "REQUEST_DETAILS_GENERAL_RIDER": "Rider Member",
        "REQUEST_DETAILS_GENERAL_DRIVER": "Driver Member",
        "REQUEST_DETAILS_GENERAL_SERVICE_TYPE": "Service Type",
        "REQUEST_DETAILS_GENERAL_FROM": "From",
        "REQUEST_DETAILS_GENERAL_TO": "To",
        "REQUEST_DETAILS_GENERAL_DISTANCE": "Estimated distance between pickup and destination",
        "REQUEST_DETAILS_GENERAL_TIME": "Estimated time from pickup to destination",
        "TIMELINE_LIST_TITLE": "Timeline",
        "TIMELINE_LIST_REQUEST_START": "Request started",
        "TIMELINE_LIST_FIRST_ACCEPTED": "First acceptance",
        "TIMELINE_LIST_LAST_ACCEPTED": "Last acceptance",
        "TIMELINE_LIST_CANCELLED": "Last cancellation",
        "TIMELINE_LIST_REQUEST_END": "Request ended",
        "TIMELINE_LIST_DELTA": "Average drivers' response delay",
        "ALL_REQUESTS_HISTORY_TAB": "History",
        "HISTORY_TABLE_LEGEND_PASSENGER": "Rider",
        "HISTORY_TABLE_LEGEND_COMMUNITY": "Community",
        "HISTORY_TABLE_LEGEND_SYSTEM": "System",
        "HISTORY_TABLE_TITLE": "Requests Sent",
        "HISTORY_TABLE_DRIVER": "Driver",
        "HISTORY_TABLE_REQUEST_DATETIME": "Request datetime",
        "HISTORY_TABLE_ACCEPTANCE_DATETIME": "Acceptance",
        "HISTORY_TABLE_CANCELLATION_DATETIME": "Cancellation",
        "HISTORY_TABLE_REFUSAL_DATETIME": "Refusal",
        "HISTORY_TABLE_ONSITE_DATETIME": "Arrived on site",
        "HISTORY_TABLE_START_DATETIME": "Start",
        "HISTORY_TABLE_FINISH_DATETIME": "Finish",
        "HISTORY_LOG_TITLE": "Log",
        "HISTORY_LOG_REQUEST_SENT": "A request has been sent to",
        "HISTORY_LOG_REQUEST_ACCEPTED": "accepted the ride and is approximately {{estimatedPickupDistance}} km from the pickup location. The estimated time to get there is {{estimatedPickupTime}}.",
        "HISTORY_LOG_REQUEST_DECLINED": "declined the request.",
        "HISTORY_LOG_REQUEST_DECLINED_BY_SYSTEM": "Time limit exceeded. The system has declined the request for",
        "HISTORY_LOG_REQUEST_ONSITE": "arrived at the pickup location.",
        "HISTORY_LOG_REQUEST_CANCELLED": "has cancelled the ride.",
        "HISTORY_LOG_REQUEST_CANCELLED_BY_COMMUNITY": "The community has cancelled the current ride.",
        "HISTORY_LOG_REQUEST_CANCELLED_BY_RIDER": "The rider member has cancelled the current ride.",
        "HISTORY_LOG_REQUEST_CANCELLED_BY_SYSTEM": "The system has cancelled the current ride.",
        "HISTORY_LOG_REQUEST_STARTED": "started the ride.",
        "HISTORY_LOG_REQUEST_STARTED_BY_COMMUNITY": "The community manually started the ride for ",
        "HISTORY_LOG_REQUEST_FINISHED": "finished the ride.",
        "HISTORY_LOG_REQUEST_FINISHED_BY_COMMUNITY": "The community manually finished the ride for ",
        "HISTORY_LOG_REQUEST_FINISHED_BY_SYSTEM": "The system manually finished the ride for ",
        "REQ_STATUS": "Request Status",
        "CHARGE_TABLE_TITLE": "CHARGES",
        "CHARGE_TABLE_TIP": "Tip:",
        "CHARGE_TABLE_MIN": "Minimum Fee:",
        "CHARGE_TABLE_PROMO": "Promotion Code:",
        "CHARGE_TABLE_COMM": "Comm:",
        "CHARGE_TABLE_COMM_SUPPL": "Supplementary Comm:",
        "CHARGE_TABLE_BASE": "Base Fee:",
        "CHARGE_TABLE_CREDIT": "Credit Card Fee:",
        "CHARGE_TABLE_DIST": "Distance Fee:",
        "CHARGE_TABLE_TIME": "Time Fee:",
        "CHARGE_TABLE_DRIVER": "Driver Fee:",
        "CHARGE_TABLE_RIDER_ALGO": "Estimated Ride Fee:",
        "CHARGE_TABLE_RIDE": "Actual Ride Fee:",
        "CHARGE_TABLE_FROM_ZONE": "From Zone Fee:",
        "CHARGE_TABLE_TO_ZONE": "To Zone Fee:",
        "CHARGE_TABLE_WORLD": "World Fee:",
        "CHARGE_TABLE_WORLD_SUPPL": "Supplementary World Fee:",
        "CHARGE_TABLE_TOTAL": "Total:",
        "CHARGE_TABLE_TAX": "Tax:",
        "CHARGE_TABLE_TOTAL_TAX": "Total (with tax):",
        "NO_MAP_INFO": "No Information Available",
        "MAP_LOADING": "Loading...",
        "DEBT_MEMO_LABEL": "Debt memo",
        "SEND_TRANSFER_MEMO_LABEL": "Transfer memo",
        "RIDE_REQ_STAT_CARD_TITLE": "LAST 20 RIDE REQUESTS",
        "RIDE_REQ_STAT_CARD_HINT": "Show",
        "STATUS": "STATUS",
        "DATE": "Date",
        "SCHEDULED_DATE": "Scheduled Date",
        "TRACKING_ID": "Tracking id",
        "ONSITE_DATE": "Onsite Date",
        "ARCHIVED_DATE": "Archived Date",
        "CUSTOMER_PHONE": "Customer phone",
        "CUSTOMER_PHONE_EXTENSION": "Extension",
        "CUSTOMER_FIST_NAME": "Customer first and last name",
        "ORDER_NUMBER": "Order Number",
        "DRIVER": "Driver",
        "LOAD_MORE": "LOAD MORE",
        "NO_DATA": "NO AVAILABLE DATA",
        "ADD_MONTH": "Add Month",
        "REMOVE_MONTH": "Remove Month",

        //ACTIVE RIDES PAGE
        "START_RIDE": "Start Ride",
        "END_RIDE": "End Ride",
        "TRACK_RIDE": "Track",
        "TRACKING_LINK": "Tracking Link",
        "CANCEL_RIDE": "Cancel Ride",
        "ACTION_SUCCESS": "The action was successful!",
        "ACTION_ERROR": "An Error has occurred:",
        "CANCEL_ACTIVE_RIDE_ALERT_TITLE": "Cancel Ride",
        "CANCEL_ACTIVE_RIDE_ALERT_CONFIRM": "Yes, Cancel Ride!",
        "CANCEL_ACTIVE_RIDE_ALERT_BODY": "You are about to cancel an active ride, route Id: ",
        "CANCEL_ACTIVE_CORP_RIDE_ALERT_BODY": "You are about to cancel this ride.",
        "START_ACTIVE_RIDE_ALERT_TITLE": "Start Active Ride",
        "INCREMENT_RIDE_ALERT_TITLE": "Next Step",
        "START_ACTIVE_RIDE_ALERT_CONFIRM": "Yes, Start Ride!",
        "INCREMENT_RIDE_ALERT_CONFIRM": "Yes, Next Step!",
        "START_ACTIVE_RIDE_ALERT_BODY": "You are about to start an active ride, route Id: ",
        "INCREMENT_STEP": "Skip Step",
        "INCREMENT_RIDE_ALERT_BODY": "You are about to make the ride go to the next step. All proofs or documents required to go to next steps are not joined as they are not provided by the driver.",
        "END_ACTIVE_RIDE_ALERT_TITLE": "End Active Ride",
        "END_ACTIVE_RIDE_ALERT_CONFIRM": "Yes, End Ride!",
        "END_ACTIVE_RIDE_ALERT_BODY": "You are about to end an active ride, route Id: ",
        "WANT_TO_PROCEED": "Do you want to proceed?",
        "RIDER_IS_DRIVER_MAIN": "THIS RIDER IS ALSO A DRIVER",
        "RIDER_IS_DRIVER_LINK": " Click here for the driver page",
        "RIDER_IS_CORPORATE_USER": "This is a <b>corporate</b> account",
        "SEND_INVOICE_ALERT_TITLE": "Send Invoice",
	    "SEND_INVOICE_ALERT_CONFIRM": "Yes, Send Invoice!",
	    "SEND_INVOICE_ALERT_BODY": "You are about to send an invoice to ",
	    "INVALID_EMAIL_OR_ROUTE": "Email or route invalid",
	    "ERROR_SENDING_INVOICE": "There was an error whille generating invoice and sending it.",
	    "ALERT_LOADING": "Loading...",
        "WELCOME_SIDEBAR": "Welcome, ",
        "YES": "Yes",
        "NO": "No",
        "NEXT": "NEXT",
        "PREVIOUS": "PREVIOUS",
        "LOADING": "LOADING",
        "PAGE": "PAGE",
        "ROW_PER_PAGE":"ROWS PER PAGE",
        "JOURNEY": "JOURNEY",
        "MAP": "MAP",
        "IS_DRIVER_ONLINE": "Online",
        "MEMO_REFUND_RIDER": "Refund Rider",
        "MEMO_TRANSACTION_ERROR": "Transaction Error",
        "MEMO_PROMO_WITHDRAWAL": "Promo Withdrawal",
        "MEMO_DRIVER_REQUEST": "Driver Request",
        "MEMO_OTHER": "Other",
        "MEMO_WAVE": "WAVE Deposit",
        "MEMO_DETAILS": "Details",
        "TYPE": "Type",
        "REASON": "Reason",
        "CLOSE": "Close",

        //Edit rides

        "EDIT_RIDE": "Edit",
        "EDIT_RIDE_ALERT_TITLE":"Edit Ride",
        "EDIT_RIDE_ALERT_BODY": "You are about to edit this ride.",
        "EDIT_RIDE_ALERT_CONFIRM":"Yes, edit Ride",
        "GET_PRICE_ESTIMATE": "Get price estimate",
        "RIDESHARING": "Ridesharing",
        "DELIVERY": "Delivery",
        "ELECTRIC_PLACEHOLDER" : "Electric",
        "CANNABIS": "Cannabis",
        "RIDE_SERVICE_TYPE":"Service Type",
        "TIP_AMOUNT": "Update tip in token", 
        "NEW_PRICE": "New Price",
        "EDIT_RIDE_ERROR": "This edit ride request is invalid. Please validate addresses before submitting.",
        "RIDE_NOT_FOUND" : "Ride not found.",
        "EDIT_CONFIRMATION" : "Confirmation",
        "EDIT_ERROR" : "Error",
        "MANUAL_DISPATCH": "Manual Dispatch",
        "MANUAL_DISPATCH_CONFIRM": 'Yes, dispatch the ride to driver',
        "DRIVER_PHONE": "Driver's Phone",
        "RIDER_PHONE": "Rider's Phone",
        "NONE": "None",
        "COMPLETED_DATE": "Completed Date",
        "PROOF_OF_CUSTOMER": "Proof of customer",
        "GO_TO_DESTINATION": "Go to destination",
        "CASH_PAYMENT_COLLECTION": "Cash Payment Collection",
        "TIME_TAKEN":"Time Taken",
        "PROOF_OF_ADDRESS": "Proof of address",
        "GO_BACK_TO_STORE": "Going back to store",
        "OWT_RIDER": "On the way toward rider",
        "DESTINATION": "Toward Destination",
        "PICKUP": "Pickup",
        "RIDER": "Rider",

        "PROMOTION": "Promotion",
        "SENT_IN_PROMOTION": "Sent in promo",
        "REMEDIAL": "Remedial",

        "PERMISSIONS": "Permissions",
        "INFORMATION": "Information",
        "RIDE_SHARING_SERVICE": "Ride Sharing",
        "DELIVERY_SERVICE": "Delivery",
        "BULK": "Bulk delivery",
        "DELIVERY_SERVICE_BIKE": "Bike delivery",
        "DELIVERY_SERVICE_MOTORCYCLE": "Moto delivery",
        "DELIVERY_SERVICE_AMBULANCE": "Ambulance",
        "RIDE_SHARING_SERVICE_LUX": "Lux Ride Sharing",
        "RIDE_SHARING_MOTO": "Moto Ridesharing",
        "DELIVERY_TRICYCLE": "Moto Tricycle Delivery",
        "RIDE_SHARING_TRICYCLE": "Moto Tricycle Ridesharing",
        "SHUTTLE": "Shuttle",

        "RIDE_SERVICE_NAME":"Ride service type",

        "TABLE_API_ERROR_1": "There was a problem getting the data.",
        "TABLE_API_ERROR_2": "Please try again later.",

        // Reports section
        "REPORTS": "Reports",
        "GENERATE_REPORTS_TITLE": "Generate a Report",
        "REPORT_TYPE_CHOOSE": "Select Report Type",
        "GET_RIDER_RIDES_FREQUENCY": "Number of riders per ride number completed",
        "GENERATE_BUTTON_TITLE": "Generate",

        //JTF section
        "DRIVERS_MENU_JTF": "Hourly Paid",
        "ADD_NEW_JTF_DRIVER": "Add New JTF Driver",
        "ADD SHIFT": "Add Shift",
        "JTF_DELETE_SHIFT": "Delete Shift",
        "SHOW_SHIFT": "Show Shift",
        "REMOVE_FROM_JTF": "Remove From JTF",
        "JTF_SHIFT": "Shift JTF",
        "JTF_SHIFT_SCHEDULED": "Scheduled JTF Shift",
        "JTF_SAVE": "Save Changes",
        "JTF_TO": "To",
        "JTF_CONFIRM": "Confirm",
        "JTF_DESACTIVATE_DRIVER": "Desactivating JTF driver",
        "JTF_ADDING_DRIVER": "Adding driver to JTF",
        "JTF_ADDING_SHIFT": "Adding shift",
        "JTF_SALARY_UPDATE": "The driver salary have been updated",
        "JTF_SHIFT_REMOVED": "The shift have been successfully removed",
        "JTF_SHIFT_ADDED_SUCCESS": "Shift added successfully",
        "JTF_DRIVER_ADDED_SUCCESS": "Driver added successfully",
        "JTF_DRIVER_REMOVE_SUCCESS": "The account has been added successfully removed from JTF",
        "JTF_HOURLY_RATE": "Hourly Rate",
        "JTF_START_TIME": "Start Time",
        "JTF_END_TIME": "End Time",
        "JTF_DELETE_CONFIRM": "Are you sure you want to delete driver from JTF?",
        "NEW_SHIFT": "New Shift",

        //Driver communication section
        "COMMUNICATION_TITLE": "Communication Operation",
        "ACTION_SEND_SMS": "Send an SMS",
        "ACTION_SEND_NOTIFICATIONS": "Send a notification",
        "ACTION_SEND_EMAIL": "Send an email",
        "ACTION_SEND_TEMPLATED_EMAIL": "Send a templated email",
        "COVID_CERT": "Covid Certificate",
        "DRIVER_SMS_MESSAGE": "Text Message",
        "SELECT_ALL": "Select All",
        "SEND_SMS": "Send",
        "JTF_SHIFT_REMOVED": "Shift removed.",
        "JTF_SHIFT_ADDED_SUCCESS": "Shift Added!",
        "JTF_DRIVER_ADDED_SUCCESS": "Driver Added to JTF.",
        "JTF_DRIVER_REMOVE_SUCCESS": "Driver Removed from JTF",
        "JTF_HOURLY_RATE": "Hourly Salary",
        "JTF_START_TIME": "Start Time",
        "JTF_END_TIME": "End Time",
        "JTF_DELETE_CONFIRM": "Are you sure you want to remove the driver from the JTF?",
        "NEW_SHIFT": "New shift",
        "SEND": "Send",

        //Online Drivers
        "DRIVERS_MENU_ONLINE": "Online Map",
        "IN_RIDE": "Live Ride",
        "FIND_DRIVER": "Find Driver",
        "NO_PERMISSIONS": "No Permissions Set",
        "TIP": "Tip",

        "IN_RIDE_STATUS": "In Ride",
        "SCHEDULED_STATUS": "Scheduled",
        "DISPATCHING_STATUS": "Dispatching",
        "TRANSIT_PICKUP": "In Transit Pickup",
        "ARRIVED_PICKUP": "Arrived Pickup",
        "TRANSIT_DESTINATION": "In Transit Destination",
        "ARRIVED_DESTINATION": "Arrived Destination",
    
        "SCHEDULED_RIDE_HEALTHCHECK": "Monitored Status",
        "HEALTHCHECK": "Monitored Status",
        "SCHEDULED_RIDE_PROBLEM": "Problem",
        "SCHEDULED_RIDE_CLEAR": "Clear",
        "ONSITE_DATE": "Onsite Date",
        "REDISPATCH_RIDE": "Dispatch Again",
        "REDISPATCH_RIDE_ALERT_TITLE": "Redispatch the ride to drivers.",
        "REDISPATCH_RIDE_ALERT_YES": "Yes!",
        "REDISPATCH_RIDE_ALERT_BODY": "You are sending this delivery in dispatch. Please verify it doesn't already exist in the active rides.",

        //Units
        "KILOMETER": "km",
        "MINUTE": "min",
        "SECOND": "sec",

        //404
        "404_TITLE": "404 - Page Not Found",
        "404_CLICK": "Click ",
        "404_HERE": "here",
        "404_RETURN": " to return to the home page.",

        "REFILL_AMOUNT_CORPORATE_BUSINESS": "Refill corporate balance",

        "ADDITIONAL_INFORMATION": "Additional Information",
        "AUTHORIZATION_CODE": "Authorization Code Revenu QC",
        "BILLING_NUMBER": "Billing Number",
        "IDENTIFICATION_CODE": "Identification Code Revenu QC",
        "RIDES_MENU_CANCELLED": "Cancelled",
        "ACCEPTED_BLOCK_TIME": "Acceptance time",
        "CANCELED_BLOCK_TIME": "Cancellation time",
        "DELTA_TIME_CANCELLED": "Minutes with ride",
        "ESTIMATED_PICKUP_TIME": "Time to pickup",
        "ENABLED": "Enabled",
        "DISABLED": "Disabled",
        "PIN_CODE_TITLE": "Debug: PIN code",
        "PIN_CODE_SUBTITLE": "Enable to show PIN code on the Eva app. Please do not change without permission.",
        "ARCHIVE_SUBMISSION": "Archive Submission",
        "ATTENTION": "Attention!",
        "ARE_YOU_SURE_ARCHIVE": "Are you sure you want to archive this submission?",
        "CONFIRM": "Confirm",
        "SUCCESS": "Success",
        "ARCHIVE_SUCCESS": "Successfully archived the submission.",
        "RESTORE_SUCCESS": "Successfully restored the submission.",
        "ARCHIVE_ERROR": "There was a problem archiving the submission.",
        "RESTORE_ERROR": "There was a problem restoring the submission.",
        "SUBMISSION_IS_ARCHIVED": "The submission is archived",
        "RESTORE_SUBMISSION": "Restore Submission",
        "ARE_YOU_SURE_RESTORE": "Are you sure you want to restore the current submission?",
        "DRIVER_SUBMISSIONS_ARCHIVE": "Submissions Archive",
        "CANNABIS": "Cannabis",
        "FLAG_DRIVER": "Flag driver",
        "UNFLAG_DRIVER": "Unflag driver",
        "FLAG_CONFIRMATION": "Do you want to flag as high risk the driver ",
        "UNFLAG_CONFIRMATION": "Do you want to remove the risk flag for the driver ",
        "FLAG_TITLE": "Driver flagged",
        "UNFLAG_TITLE": "Driver unflagged",
        "FLAG_SUCCESSFUL": "was successfully flagged as high risk",
        "UNFLAG_SUCCESSFUL": "was successfully unflagged as high risk",
        "SENDING_FLAG_DRIVER": "Flagging driver...",
        "SENDING_UNFLAG_DRIVER": "Unflagging driver...",
        "DRIVER_FLAGGED": "Driver is flagged as high risk",
        "FLAGGED_DRIVER_DESC": "This driver is flagged as high risk. Watch this ride closely! This can be changed in the driver edit page.",
        "FLAGGED_RIDER_DESC": "This rider is flagged a high risk because the account is less than 24 hours old. Watch this ride closely!",
        "NOTES_DRIVER": "Notes",
        "EDIT": "Edit",
        "EDIT_COMMENT": "Edit Comment",
        "DELETE": "Delete",
        "SUBMIT": "Submit",
        "NO_NOTES_YET": "No notes to display.",
        "BUSINESS_NAME": "BUSINESS NAME",
        "BUSINESS": "BUSINESS",
        "IS_FLAGGED": "Flagged drivers",
        "EXTRA_FILTERS": "Extra Filters",
        "LATE": "The driver arrived late at the destination.",
        "ORDER_DAMAGED": "The driver damaged an order.",
        "WRONG_DESTINATION": "The driver delivered the order to the wrong address.",
        "ORDER_NOT_DELIVERED": "The driver did not deliver an order.",
        "LATE_PICKUP": "The driver was late to the pickup.",
        "DANGEROUS_DRIVING": "The driver was driving dangerously.",
        "LOW_SUCCESS_RATE": "The driver has a low success rate.",
        "LOW_SUCCESS_RATE_SELECT" : "Low success rate",
        "LOW_CANCELLATION_RATE": "The driver has a low cancellation rate.",
        "MISBEHAVIOUR": "The driver was reported for misbehaviour.",
        "LATE_SELECT": "Late order",
        "LOW_ACCEPTATION_RATE" : "The driver has a low acceptation rate",
        "LOW_ACCEPTATION_RATE_SELECT": "Low acceptation rate" ,
        "LOW_CANCELLATION_RATE_SELECT": "Low cancellation rate.", 
        "HIGH_ACCEPTATION_RATE_SELECT": "High acceptation rate.",
        "HIGH_ACCEPTATION_RATE" : "The driver has a high acceptation rate. " , 
        "ORDER_DAMAGED_SELECT": "Damaged order",
        "HIGH_CANCELLATION_RATE_SELECT": "High cancellation rate",
        "HIGH_CANCELLATION_RATE": "The driver has a a high cancellation rate. ", 
        "WRONG_DESTINATION_SELECT": "Wrong destination",
        "ORDER_NOT_DELIVERED_SELECT": "Order not delivered",
        "LATE_PICKUP_SELECT": "Late to pickup",
        "DANGEROUS_DRIVING_SELECT": "Dangerous driving",

        
        "MISBEHAVIOUR_SELECT": "Misbehaviour",
        "CUSTOM": "Custom...",
        "RIDE_REQUESTS_RIDER_GRAPH_TITLE": "Rides Requested Per Week",
        "RIDER_VERIFIED": "Verified",
        "RIDER_VERIFIED_TEXT": "This rider has been verified to be a real person!",
        "RIDER_NOT_VERIFIED": "Not Yet Verified",
        "RIDER_NOT_VERIFIED_TEXT": "This rider has not yet been verified to be a real person. You can manually verify the driver in the list of actions below, or wait until this rider has completed 5 rides, at which point the system will verify them automatically.",
        "RIDER_ACTION_VERIFY": "Verify Rider",
        "SEND_VERIFY_CONFIRM": "The rider will automatically be verified after 5 successful rides. Do you want to verify that this rider is not fraudulent",
        "VERIFY_RIDER_SUCCESSFULL": "Rider successfully verified!",
        "NOT_VERIFIED_RIDER_DESC": "This rider has not yet been verified.",
        "RESET_DRIVER_STATS": "Reset driver stats",
        "RESET_DRIVER_STATS_CONFIRMATION": "Do you wish to reset driver stats?",
        "RESET_DRIVER_STATS_SENDING": "Sending action to reset driver stats",
        "RESET_DRIVER_STATS_SUCCESSFUL": "Driver stats successfully reset",
        "RESET_DRIVER_STATS_TITLE": "Reset driver stats",

        //Helpers
        "never": "Never",
        "DEVELOPERS": "Developers",
        "STANDARD_CAR": "Standard Car",
        "LUXURY_CAR": "Luxury Car",
        "BICYCLE": "Standard Bicycle",
        "VAN": "Van",
        "MOTO": "Motorcycle",
        "AMBULANCE": "Ambulance",
        "VEHICLE_RIDE_SERVICES_TITLE": "Service for vehicle",
        "VEHICLE_RIDE_SERVICES_SUBTITLE": "Select all ride services you want to allow for each vehicle.",
        "RIDE_SERVICES_VISIBILITY_TITLE": "Ride service visibility",
        "RIDE_SERVICES_VISIBILITY_SUBTITLE": "Select where each service should be accessible.",
        "MIA_EXTERNAL": "Eva Business (Mia)",
        "ANA_EXTERNAL": "Eva Application",
        "COMMUNITY_SETTINGS": "Community Settings",
        "PROMO_TITLE": "Promotions",
        "PROMO_SUBTITLE": "Select all the options you want to activate",
        "AUTOMATIC_SMS": "Automatic sending of SMS when a promotion is created",
        "TIP_AMOUNT": "Tip",

        "NOTE": "Note",
        "TOTAL_ORDER_AMOUNT": "Total Order Amount",

        "ERROR_EDIT_SCHEDULED_RIDE": "Invalid inputs. Please check all fields and try again.",
        "PRICE": "Price",
        "INVALID_INPUT": "Invalid input.",
        "CUSTOMER_EMAIL": "Email",
        "CUSTOMER_NAME": "Customer Name",
        "CUSTOMER_NOTE": "Note",
        "ASSIGNED_TO": "Assigned To",
        "MANUAL_DISPATCH_RESET_CONFIRM": "Do you want to remove the assigned driver for this ride?",
        "MANUAL_DISPATCH_RESET_OK": "Removed assigned driver.",
        "RESET_MANUAL_DISPATCH": "Unassign",
        "FRENCH": "French",
        "ENGLISH": "English",
        "REDISPATCH_ACTIVE_RIDE_ALERT_TITLE": "Redispatch ride",
        "REDISPATCH_ACTIVE_RIDE_ALERT_BODY": "This will cancel the current live ride for the driver and re-send it to someone else.",
        "DISPATCH_TO_ANOTHER_DRIVER": "Dispatch to another driver",
        "TECHNOLOGY_FEE": "Technology Fee:",
        "DRIVER_CASHOUT_TOKEN_CONFIRM": "Are you sure you want to cash out tokens for this driver?",
        "DRIVER_ACTION_CASHOUT": "Cashout Eva Tokens",
        "RESET": "Reset",
        "MERGE": "Merge",
        "NO_ROUTES_IN_SYSTEM": "No routes in system.",
        "NO_ORDERS_IN_SYSTEM": "No orders in system.",
        "INVALID_ROUTE_IDS": "The given route IDs are invalid.",
        "FULLY_BOOKED": "This route is fully booked.",
        "INCOMPATIBLE_SERVICE": "Unable to merge ride sharing rides.",
        "INCOMPATIBLE_TIME": "The rides are scheduled at incompatible times.",
        "NO_AUTH": "Rider does not allow merges.",
        "ROUTE_ALREADY_STARTED": "Can't merge rides that aleady started.",
        "TOO_MANY_RIDES": "The limit of rides has been reached",
        "MANUAL_MERGE_RESET_TITLE": "Merge rides",
        "MANUAL_MERGE_RESET_BODY": "Are you SURE you want to merge the rides? \n\nThis action can't be undone!",
        "MANUAL_MERGE_CONFIRMATION": "Rides merged successfully.",
        "LIVE_ORDERS": "Live Orders",
        "LIVE_ROUTES": "Live Routes",
        "MANUAL_MERGE_LIVE_ROUTES_DESCRIPTION": "Drag available live orders to the left to remove from route.",
        "MANUAL_MERGE_LIVE_ORDERS_DESCRIPTION": "Drag any live order to the right to create a route, or to merge in an existing route.",
        "RIDE_MERGER": "Ride Merger",
        "DASHBOARD_RIDE_MERGER_MINI": "RM",
        "TRACKING_LINK_MESSAGE_SETTING_TITLE": "Tracking link message",
        "TRACKING_LINK_MESSAGE_SETTING_SUBTITLE": "This is an optional custom message to show on all tracking links in the community.",
        "MESSAGE": "Message",
        "SEVERITY": "Severity",
        "INFO": "Information",
        "WARN": "Warning",
        "DANGER": "Danger",
        "RELEASE_CASH_PAYMENT_ENTRY_CONFIRM": "Confirm Cash Received",
        "RELEASE_CASH_PAYMENT_ENTRY_BODY": "You are about to declare that the following commission due in cash payment has been received in cash from the driver : ",
        "RELEASE_CASH_COLLECTION_ENTRY_TITLE": "Declare Cash As Collected",
        "RELEASE_CASH_PAYMENT_ENTRY_CONFIRM": "Yes, we have the money",
        "RELEASE_DRIVER_CASH_PAYMENT": "Declare Cash Received",
        "VIEW_REQUESTS": "View Requests",
        "PROCESSED_DATE": "Processed date",
        "NOT_STARTED": "Not started",
        "ASSIGN_TO": "Assign to driver",
        "INVALID_DRIVER": "The selected driver does not exist.",
        "DISPATCHER_SETTING_TITLE": "Dispatcher settings",
        "DISPATCHER_SETTING_SUBTITLE": "Change the number of orders per dispatch cycle, and the dispatch radius of different services.",
        "RADIUSES": "Radiuses",
        "GENERAL": "General",
        "CASH_PAYMENT_SETTING_TITLE": "Cash payments",
        "CASH_PAYMENT_SETTING_SUBTITLE": "Decide whether cash payment is enabled in the community.",
        "ALLOW_CASH_PAYMENT": "Allow cash payment",
        "ERROR_FETCHING_SERVICES": "There was an error while loading the data",
        "DEFAULT_PRICING": "Default Market Pricing",

        //Rates mapping
        "minute_rate": "Minute rate",
        "km_rate": "Kilometer rate",
        "base_charge": "Base charge",
        "supplement_comm_charge": "Additional franchise charge",
        "supplement_comm_charge_desc": "Additional franchise charge description",
        "supplement_world_charge": "Additional global charge",
        "supplement_world_charge_desc": "Additional global charge description",
        "min_charge": "Minimal charge",
        "cancel_fee": "Cancellation charge",

        //SQDC Reports
        "SQDC_REPORT_TITLE": "SQDC",
        "SQDC_REPORT_SUBTITLE_1": "This week",
        "SQDC_REPORT_SUBTITLE_2": "All time",
        "SQDC_REPORT_SUBTITLE_3": "Late Orders Trend",
        "SQDC_REPORT_SUBTITLE_4": "Status Trend",
        "SQDC_REPORT_CHART_TITLE_1": "Status",
        "SQDC_REPORT_CHART_TITLE_2": "Late Orders",
        "SQDC_REPORT_CHART_TITLE_3": "Ride Breakdown",
        "SQDC_REPORT_CHART_TITLE_4": "Returns",
        "SQDC_LEGEND_SUCCESS": "Success",
        "SQDC_LEGEND_CANCELED": "Cancelled",
        "SQDC_LEGEND_RETURN": "Return",
        "SQDC_LEGEND_ON_TIME": "On time",
        "SQDC_LEGEND_LATE_1": "Late (0-15 mins)",
        "SQDC_LEGEND_LATE_2": "Late (15-30 mins)",
        "SQDC_LEGEND_LATE_3": "Late (30-45 mins)",
        "SQDC_LEGEND_LATE_4": "Late (45+ mins)",
        "SQDC_LEGEND_BREAKDOWN_1": "Minutes taken to find driver",
        "SQDC_LEGEND_BREAKDOWN_2": "Minutes taken to drive to pickup",
        "SQDC_LEGEND_BREAKDOWN_3": "Minutes waited at pickup",
        "SQDC_LEGEND_BREAKDOWN_4": "Minutes taken to driver to destination",
        "SQDC_LEGEND_RETURN_1": "Proof of address",
        "SQDC_LEGEND_RETURN_2": "Proof of customer",
},
    },
    fr: {
      translation: {
        // Business schedule
        "SUNDAY": "Dimanche",
        "MONDAY": "Lundi",
        "TUESDAY": "Mardi",
        "WEDNESDAY": "Mercredi",
        "THURSDAY": "Jeudi",
        "FRIDAY": "Vendredi",
        "SATURDAY": "Samedi",
        "CLOSED": "Fermé",
        "OPEN_24_HOUR": "Ouvert 24 heures",
        "MODIFY": "Modifier",
        "SCHEDULE_EDITOR": "Éditeur d'horaires",
        "SAVE_CHANGES": "Sauvegarder",
        "WORKING_HOURS": "Heures de travail (UTC)",

        // Restaurant
        "RESTAURANT_MENU": "Restaurants",
        "RESTAURANT_NEW": "Ajouter",
        "RESTAURANT_LIST": "Liste",
        "RESTAURANT_NAME": "Nom",
        "RESTAURANT_DESCRIPTION": "Description",
        "ENTER_RESTAURANT_DESCRIPTION": "Entrez la description du restaurant",
        "RESTAURANT_LINK": "Lien",
        "RESTAURANT_NAME": "Nom dur restaurant",
        "ADDRESS": "Adresse",
        "INVALID_ADDRESS": "Courriel non valide",
        "ENTER_RESTAURANT_NAME": "Entrez le nom du restaurant",
        "TRANSACTIONAL_LINK": "Lien Transactionnel",
        "ENTER_TRANSACTIONAL_LINK": "Entrez le lien transactionel",
        "RESTAURANT_NEW_SUBTITLE": "Nouveau Restaurant",
        "RESTAURANT_EDIT_SUBTITLE": "Modifier Restaurant",
        "CHANGE_RESTAURANT_IMAGE": "Changez l'image du restaurant",
        "RESTAURANT_IMAGE": "Image du restaurant",
        "ENTER_RESTAURANT_ADDRESS": "Entrez l'adresse du restaurant",
        "RESTAURANT_CATEGORIES": "Types de nourriture",
        "ADD_NEW_FOOD_TYPE": "Ajoutez un type de nourriture: ",
        "ENTER_FOOD_TYPES": "Entrez les types de nourriture du restaurant",
        "SENDING_DATA": "Envoi de données",
        "ERROR_OCCURED": "Une ERREUR s'est produite : {{0}}",
        "UPDATED": "mis à jour",
        "CREATED": "créé",
        "SUCCESS_MESSAGE": "Le restaurant a été {{0}}.",
        "ENTER_ADDRESS": "Entrez une adresse",
        "PERMANENT_RESTAURANT_DELETION": "Ce restaurant sera définitivement supprimé",
        "YES_DELETE": "Oui, supprimez-le !",
        "DELETED": "supprimé",

         //Business
         "BUSINESS_MENU": "Entreprises",
         "BUSINESS_NEW": "Ajouter",
         "BUSINESS_NEW_SUBTITLE": "Inscrire une entreprise",
         "BUSINESS_EDIT_SUBTITLE": "Éditer une entreprise",
         "COMPANY_NAME": "Nom de la société",
         "COMPANY_ADDRESS": "Adresse de la société",
         "COMPANY_APARTMENT_NUMBER": "Numéro d'appartement de la société",
         "COMPANY_PHONE": "Numéro de téléphone de la société",
         "REGISTERED_ON": "Date inscription",
         "BUSINESS_LIST": "Liste",
         "CONTACT_FIRST_NAME": "Prénom du contact",
         "CONTACT_LAST_NAME": "Nom du contact",
         "CONTACT_PHONE": "Téléphone # du contact",
         "CONTACT_EMAIL": "Courriel du contact",
         "BILLING_EMAIL": "Courriel pour factures (séparer par virgules si plusieurs)",
         "CONTACT_NAME": "Nom du contact",
         "PREFERRED_LANGUAGE": "Langue Préférée",
         "ADD_QUOTE_STRATEGY_TITLE": "Soumission Dynamique",
         "EDIT_QUOTE_STRATEGY_TITLE": "Editer Soumission Dynamique",
         "QUOTE_TYPE": "Type de Soumission",
         "TOTAL_REDUCTION": "Réduction du prix",
         "FIXED_PRICE": "Prix fixe",
         "AMOUNT_CENT": "Montant en cent",
         "SAVE": "Enregistrer",
         "DELETE_QUOTE_STRATEGY": "Supprimmer soumission dynamique",
         "CUSTOM_PRICING": "Custom Business Pricing for Service : ",
         "USING_NORMAL_PRICING": "This business is currently using normal prices for this service.",
        "COMMUNITY_RATE_X100": "Rate (%) taken by franchise",
        "GLOBAL_RATE_X100": "Rate (%) taken by Eva Global.",
        "MINUTE_RATE_CENT": "Fee per Minute (cent)",
        "KM_RATE_CENT": "Fee per Kilometer (cent)",
        "MIN_CHARGE": "Minimum Charge (cent)",
        "CANCEL_FEE_CENT": "Cancellation Charge (cent)",
        "BASE_CHARGE": "Base Fee (cent)",
        "SUPPLEMENT_COMM_CHARGE": "Franchise additionnal fee (cent)",
        "SUPPLEMENT_COMM_CHARGE_DESCRIPTION": "Description of franchise additionnal fee",
        "SUPPLEMENT_WORLD_CHARGE": "Eva Global additionnal fee (cent)",
        "SUPPLEMENT_WORLD_CHARGE_DESCRIPTION": "Eva Global additionnal fee description",
        "RIDER_ACCOUNT":"Compte passager",
        "SEND_SMS_TRACKING": "Envoyer lien de suivi par SMS",
        "SEND_EMAIL_TRACKING": "Envoyer lien de suivi par courriel",
        "PARENT_RIDER_ACCOUNT":"Compte Passager du commerce parent",
        "PARENT_CORPORATE_DONT_EXIST":"Compte parent n'existe pas",
         //LoginPage
         "LOGIN_EMAIL_LABEL": "Courriel",
         "Password": "Mot de passe",
         "LOGIN_BUTTON_LABEL": "Se connecter",
         "LOGIN_ERROR": "Erreur lors de la connexion",

         //Footer
         "Home": "Accueil",
         

        //DASHBOARD MINIS
        //Rides
        "DASHBOARD_ACTIVE_RIDE_MINI":"EC",
        "DASHBOARD_CUSTOMER_ORDERS_MINI":"CC",
        "DASHBOARD_DRIVER_ROUTES_MINI":"RC",
        "DASHBOARD_BILLED_MINI":"F",
        "DASHBOARD_ARCHIVED_CUSTOMER_ORDERS_MINI":"CCA",
        "DASHBOARD_ALL_REQUESTS_MINI":"TLR",
        "DASHBOARD_RIDE_OPPORTUNITY_MINI":"O",
        "DASHBOARD_CANCELLED_MINI":"A",
        //Riders
        "DASHBOARD_ACTIVE_RIDERS_MINI":"P",
        "DASHBOARD_BLACKLISTED_RIDERS_MINI":"PB",
        //DRIVERS
        "DASHBOARD_ACTIVE_DRIVER_MINI":"A",
        "DASHBOARD_INACTIVE_DRIVER_MINI":"I",
        "DASHBOARD_JTF_DRIVER_MINI":"JTF",
        "DASHBOARD_ONLINE_DRIVER_MAP_MINI":"CL",
        "DASHBOARD_NEW_DRIVER_SUBMISSIONS_MINI":"NS",
        "DASHBOARD_DRIVER_SUBMISSIONS_LIST_MINI":"LS",
        "DASHBOARD_DRIVER_COMMUNICATION_MINI":"COM",
        //BUSINESS
        "DASHBOARD_BUSINESS_LIST_MINI":"L",
        "DASHBOARD_NEW_BUSINESS_MINI":"A",
        //RESTAURANTS
        "DASHBOARD_RESTAURANTS_LIST_MINI":"L",
        "DASHBOARD_NEW_RESTAURANTS_MINI":"A",
        //PAYMENTS
        "DASHBOARD_PENDING_PAYMENTS_MINI":"PA",
        "DASHBOARD_PAYMENT_HISTORY_MINI":"HP",
        "DASHBOARD_PAYMENT_CASH_COLLECTION_MINI": "CM",
        //ANALYTICS
        "DASHBOARD_ANALYTICS_REPORT_MINI":"R",
        "DASHBOARD_ANALYTICS_MANAGEMENT_MINI":"G",
        //LOGS
        "DASHBOARD_LOGS_TOKEN_TRANSFER_MINI":"TJ",


         //SideBare
         "Dashboard": "Tableau de bord",
         "Rides": "Courses",
         "Drivers": "Conducteurs",
         "Payments": "Paiements",
         "Active rides": "En cours",
         "Pending rides": "Requêtes en cours",
         "Analytics": "Analytique",
         "Driver Cancellation": "Annulation de conducteur",
         "Rider Cancellation": "Annulation de passager",
         "Analytics Reports": "Rapports",
         "Analytics Management": "Gestion", 
         "Pending payments": "Paiements en attente",
         "Historical payments": "Historique des paiements",
         "Invoices": "Factures",
         "Config": "Configuration",
         "Community config": "Information financière",
         "DRIVERS_MENU_COMMUNICATION": "Communication",
        //ViewTitles 
        "Active Rides in": "Courses en cours à ",
        "Pending Rides Requests in": "Demandes de courses en attente à ",
        "Drivers in": "Conducteurs dans ",
    
        "STARTED":"Commencéé?",
        "DRIVER_FOUND":"Conducteur",
        "FLAG_STARTED":"Commencée",
        "FLAG_NOT_STARTED":"Pas commencée",
        "MERGED_EST_MIN": "Temps estimé (min)",
        "MERGED_EST_DIST_KM": "Distance estimée (km)",
        "CURRENT_STEP_EST_TIME": "Temps estimé",
        "CURRENT_STEP_REAL_TIME": "Temps passé",
        "NUMBER_OF_ORDERS":"Nombre de commandes",
        "route ID": "Route ID",
        "Rider ACCOUNT": "Comptes Passagers",
        "status": "Statut",
        "Driver account": "Comptes Conducteur",
        "Driver full Name": "Nom et prénom conducteur",   ////  Faire valider le nom prénom / Prénom nom //////
        "Created date":  "Date de Création",
        "Modified date": "Date de Modification",
        "is_driver_onsite": "Sur site?",
        "is_driver_late": "Est Ponctuel?",
        "DRIVER_ONSITE_ARRIVED": "Sur site",
        "DRIVER_NOT_ONSITE": "Pas encore",
        "estimated_pickup_time": "Temps ramassage est.",
        "elapsed_pickup_time": "Temps ramassage réel",
        "actions": "Actions",
        "action": "Action",

        //RequestedRides
        "~Minutes": "~Minutes",
        "~KM": "~KM",
        "Amount": "Montant",

        //Buttons
        "Default": "Par Défaut",
        "Tweet": "Tweeter",
        "Share": "Partager",
        "Share on Google+": "Partager sur Google+",
        "Connect with Linkedin": "Rejoignez nous avec Linkedin",
        "Pint it": "épingler",
        "View on Youtube": "Vue sur Youtube",
        "Connect with Github": "Rejoignez nous avec Github",
        "Follow us": "Suivez-nous",
        "Find us on Dribbble": "Trouvez nous sur Dribbble",
        "Repost": "Réafficher",
        "View on StumbleUpon": "Vue sur StumbleUpon",

        //historicalRides
        "Filter on": "Filtrer",
        "Download CSV":"Télécharger le tableau en CSV",
        "Date Range": "Plage de Dates",


        "End date": "Date de Fin",
        "End Time": "Heure de Fin",
        "rideID": "Route ID",
        "Driver Name": "Nom du Conducteur",
        "Driver profit": "Conducteur",
        "Community profit": "Communauté",
        "Global profit": "Global",
        "Ride cost": "Coût du trajet",
        "Commity Sup Fee": "Frais Sup communautaire",
        "Global Sup Fee": "Frais Sup global",
        "Total Cost": "Coût total",
        "tps": "TPS",
        "TPS": "TPS",
        "TVQ": "TVQ",
        "Total Taxes": "Total des taxes",
        "Total with Taxes": "Total avec taxes",
        "Credit Card Fee": "Frais de carte bancaire",
        "Total with Credit": "total avec crédit",
        "Tip Amount": "Montant du pourboire",
        "Tip Fee": "Frais de pourboire",
        "RIDER_NAME": "Nom du passager",
        "RIDER_CONNECTION_NUMBER": "Nombre de connexion",

        //RideOpportunitiesList
        "RIDE_OPPORTUNITIES": "Opportunités",
        "SUCCESSFUL": "Réussie",
        "FAILED": "Échoué",

        // DriverCanceledRides
        "Number of cancelled ride by driver in": "Nombre de courses annulés par conducteurs",
        "Last ride canceled in ": "Dernières courses annulés par les conducteurs dans",
        "Number of cancellation": "Nombre de cancellation",
        "Number of completed rides": "Nombre de courses complétés",
        "Score": "Pointage",


        //ExtendedTables
        "View Profile": "Vue du Profil",
        "Edit Profile": "Modifier le Profil",
        "Remove": "Supprimer",
        "View Post": "Voir les Publications",
        "Edit Post": "Modifier une Publication",
        "Remove Post": "Supprimer la Publication",
        "Job Position": "Votre Poste",
        "Salary": "Salaire",

        //ReactTables
        "Name": "Nom",
        "Position": "Lieu",
        "Office": "Office",
        "Age": "Âge",

        //DriverInfoForm
        "Community (disabled)": "Communauté (désactivé)",
        "Phone Number": "Numéro de Téléphone",
        "Eos Account": "Compte EOS",
        "Email address": "Adresse Mail",
        "First Name": "Prénom",
        "Last Name": "Nom",
        "Tps": "TPS",
        "tvq": "TVQ",
        "Google Form":"Champs de Google sheet",
        "Vehicle brand": "Marque du véhicule",
        "Vehicle model": "Model du véhicule",
        "Vehicle year": "Année du véhicule",
        "Vehicle color": "Couleur du véhicule",
                        //Color   ///////// ne marche pas ( problème identique aux titres ) /////
                        "Silver": "Argent",
                        "White": "Blanc",
                        "Blue": "Bleu",
                        "Gray": "Gris",
                        "Red": "Rouge", 
                        "Green": "Vert",
                        "Brown": "Marron",
                        "Yellow": "Jaune",
                        "Orange": "Orange",
                        "Pink": "Rose",
                        "Purple": "Violet",
                        "Dark Blue": "Bleu foncé",
                        "Dark Gray": "Gris foncé",
                        "Dark Red": "Rouge foncé",
                        "Dark Green": "Vert foncé",
                        "Dark Brown": "marron foncé",
                        "Bright Red": "Rouge clair",
                        "Bright Blue": "Bleu clair",
                        "Bright Green": "Vert clair",
                        "Bright Gray": "Gris clair",
                        "Bright Brown": "Marron clair",

        "Vehicle mileage": "Kilométrage",
        "Allowed multi ride": "Permission courses multiples",
        "Allowed Cash Payment": "Permissions paiement en espèces",
        "Vehicle identification number": "Numéro d'identification du véhicule",
        "Vehicle registration certificate file number": "Numéro de dossier du certificat d’immatriculation",
        "VEHICLES": "Véhicules",
        "VEHICLE_TYPE": "Type de véhicule",
        "VEHICLE": "Véhicule",
        "NEW_VEHICLE": "Ajouter un autre véhicule",
        "Registration plate number" : "Plaque d'Immatriculation",
        "Driver licence number": "Numéro de Licence du Conducteur",
        "Financial institute name": "Nom d'Institut Financier",
        "Financial institute number": "Numéro d'Institut Financier",
        "Transit number": "Numéro de Transit",
        "Account number": "Numéro de Compte",
        "Go Back": "Retour",

        //DriverHistoricalCashout
        "Community": "Communauté",
        "Requested Date": "Date demandée",
        "Payment Date": "Date de paiement",
        "EVA Tokens": "Jetons EVA",
        "cad": "CAD",
        "From": "De ",
        "To": " à ",

        //PendingCashout
        "Request date": "Date demandée",
        "All rides": "Toutes les courses",
        "Completed rides": "Courses terminées",
        "Canceled Rides": "Courses annulées",
        "Sequence ID": "Numéro de séquence",
        "Requested Time": "Date de requête",
        "select": "Sélectionner",
        "EOS Account": "Compte EOS",
        "Full Name": "Nom complet",
        "Institution": "Institution",
        "Transit": "Numéro de transit",
        "Select All": "Tout sélectionner", 
        "Issue All Selected": "Émettre les paiements sélectionnés", 

        "Force_Sequence": "Forcer le numéro de séquence:",
        "Error_Force_Sequence": "Le numéro de séquence doit être entre 0 et 999",
        "Download": "Télécharger",
        "Error_More_Than_One_Payment": "Veuillez choisir au moins un paiement.",
        "Error_No_Sequence_Payment": "Un ou plusieurs paiements n'ont pas de numéro de séquence. Veuillez générer un avant de procéder.",
        "Error_Different_Sequence_Ids": "Quelques paiements ont des numéros de séquence différents. Veuillez sélectionner ceux qui ont le même numéro de séquence.",
        "Issuing_Payments_Confirmation": "Confirmation pour émettre les paiements",
        "Issuing_Payments_Confirmation_Yes": "Oui, émetter les paiements!",
        "Issuing_Payments_Confirmation_Warning": "Vous allez émettre les paiements pour ces retraits en attente:",
        "Generate_Deposit_Confirmation": "Confirmation pour générer le dépôt",
        "Generate_Deposit_Confirmation_Yes": "Oui, générer le dépôt!",
        "Generate_Deposit_Confirmation_Warning": "Générer un fichier de dépôt pour le numéro de séquence <b>#{{sequence_number}}</b> avec ces retraits?",
        "Generate_Deposit_Error": "Une erreur s'est produite en essayant de générer le ficher de paiement.",
        "Generate_Deposit_Success": "Génération du ficher de paiement a été un succès.",
        "Generate_Deposit": "Générer le ficher de paiement",
        "Payments_Have_Sequence_Warning": "Quelques paiements sélectionnés ont déjà un numéro de séquence. Voulez-vous vraiment continuer?",
        "Generate_Sequence": "Générer une séquence",
        "Generate_Sequence_Success": "La génération de la séquence a été crée avec succès.",
        "Generate_Sequence_Error": "Une erreur s'est produite lors de la génération de la séquence.",
        "Payment_Request_Success": " La requête de paiement a été traitée et validée avec succès.",
        "Payment_Confirmation": "Confirmation de paiement",
        "Payment_Confirmation_Yes": "Oui, confirmer le paiement",
        "Payment_Confirmation_Warning": "Vous allez confirmer le paiement de <b>{{amount}}</b>, demandé par ce conducteur: <b>{{driver}}</b>. Voulez-vous continuez?",

        //Invoices
        "Invoice Number": "Numéro de facture",
        "Paid": "Payé",
        "Mark paid": "Marquer payé",
        "Mark unpaid": "Marquer non-payé",
        "Payment Type": "Mode de paiement",
        "Files": "Fichiers",
        "Total": "Total",
        "From_invoice": "Début de période",
        "To_invoice": "Fin de période",
        "CREDIT_CARD": "Carte de crédit",
        "MANUAL": "Manuel",
        "PAID_DATETIME": "Date de paiement",
        
        //DriverList
        "Add driver": "Ajouter Conducteur",
        "Edit driver": "Modifier Conducteur",
        "ACTIONS_DRIVER": "Actions",
        "DEACTIVATE_TITLE": "Désactiver",
        "DEACTIVATION_CONFIRMATION": "Souhaitez-vous vraiment désactiver le conducteur",
        "DEACTIVATION_SUCCESSFUL": "a été désactivé de le communité",
        "Add new driver":"Ajouter un nouveau conducteur",
        "phone": "Téléphone",
        "DRIVERS_MENU_ACTIVE":"Actifs",
        "DRIVERS_MENU_INACTIVE" :"Inactifs",
        "Online": "En ligne",
        "Activate driver": "Activer Conducteur",
        "Allowed":"Permis",
        "Denied":"Refusé",
        "City": "Ville",

        // New Submission (add driver)
        "DRIVERS_MENU_NEW_SUBMISSION": "Nouvelle soumission",
        "NEW_SUBMISSION_PHONE_LABEL_TEXT": "Entrer le numéro de téléphone du membre passager qui souhaite devenir membre conducteur.",
        "NEW_SUBMISSION_PHONE_FORMAT_ERROR": "Le numéro entré est invalide.",
        "NEW_SUBMISSION_CREATE_BUTTON": "Ouvrir",
        "NEW_SUBMISSION_CREATE_NEW_PROMPT": "Vous êtes sur le point d'ouvrir une soumission pour {{fullName}}. Voulez-vous continuer?",
        "NEW_SUBMISSION_NO_RIDER_FOUND_PROMPT": "Il n'y a pas de compte passager associé à ce numéro de téléphone. Le compte est peut-être en cours de synchronisation ou est déjà devenu conducteur.",
        "NEW_SUBMISSION_PHONE_REJECTED_BY_SERVER_PROMPT": "Le numéro de téléphone a été rejeté par le serveur.",
        "NEW_SUBMISSION_HEADER_TITLE": "Nouvel applicant",
        "NEW_SUBMISSION_DRIVER_TAB": "Conducteur",
        "NEW_SUBMISSION_VEHICLE_TAB": "Véhicule",
        "NEW_SUBMISSION_SEND_REMINDER_BUTTON": "Envoyer un rappel",
        "NEW_SUBMISSION_SEND_REMINDER_CONFIRMATION": "Vous êtes sur le point d'envoyer un rappel par courriel. Voulez-vous continuer?",
        "NEW_SUBMISSION_ARCHIVE_BUTTON": "Archiver",
        "NEW_SUBMISSION_OPEN_BUTTON": "Ouvrir",
        "NEW_SUBMISSION_TITLE_BASIC_INFO": "Information de base",
        "NEW_SUBMISSION_TITLE_CUSTOM_INFO": "Information supplémentaire",
        "NEW_SUBMISSION_SELECT_OPTION": "Sélectionner une option",
        "NEW_SUBMISSION_REMOVE_VEHICLE": "Retirer",
        "NEW_SUBMISSION_CHOOSE_FILE": "Aucun fichier sélectionné",
        "NEW_SUBMISSION_UPLOADED_FILE_ERROR": "❌ Erreur lors du téléversement. Réessayez.",
        "NEW_SUBMISSION_CANNOT_OPEN_IMAGE": "Impossible d'ouvrir le fichier demandé pour le moment. Contacter l'équipe de développement si le problème persiste.",
        "NEW_SUBMISSION_MAX_FILE_SIZE": "La taille du fichier ne doit pas dépasser 25 Mo.",
        "NEW_SUBMISSION_ACCEPTED_FILE_FORMAT": "Les types de fichiers acceptés sont jpeg, gif, png et pdf.",
        "NEW_SUBMISSION_VALIDATION_ERROR": "Erreur de validation",
        "NEW_SUBMISSION_SAVE_BUTTON": "Sauvegarder la soumission",
        "NEW_SUBMISSION_VALIDATE_ALL_BUTTON": "Tout valider",
        "NEW_SUBMISSION_EDIT_BUTTON": "Modifier la soumission",
        "NEW_SUBMISSION_COMPLETE_BUTTON": "Compléter la soumission",
        "NEW_SUBMISSION_CONFIRMATION_ALERT_TEXT": "La requête sera envoyée au serveur et il ne sera plus possible de modifier cette soumission",
        "NEW_SUBMISSION_ERROR_VERIFY_FIELDS": "Vérifer les champs suivant: ",
        "NEW_SUBMISSION_ERROR_CONTACT_DEV_TEAM": "Contacter l'équipe de développement si le problème persiste.",
        "NEW_SUBMISSION_ERROR_UPDATE_PROMPT": "Impossible de mettre la soumission à jour.",
        "NEW_SUBMISSION_ERROR_MISSING_FIELDS_PROMPT": "Impossible de procéder à la validation car certains champs sont invalides.",
        "NEW_SUBMISSION_ERROR_VALIDATE_ALL_PROMPT": "Impossible de valider la soumission.",
        "NEW_SUBMISSION_ERROR_STATUS_PROMPT": "Impossible de mettre à jour le statut de la soumission. Contacter l'équipe de développement si le problème persiste.",
        "NEW_SUBMISSION_SUCCESS_ALERT_TEXT": "La soumission a été traitée avec succès. Bienvenue au membre conducteur!",
        "NEW_SUBMISSION_ERROR_ALERT_TEXT": "Impossible de procéder à la création du compte conducteur pour le moment. Réessayez plus tard ou contacter l'équipe de développement.",
        // Driver Fields
        "NEW_SUBMISSION_FIRST_NAME": "Prénom",
        "NEW_SUBMISSION_LAST_NAME": "Nom de famille",
        "NEW_SUBMISSION_CHAIN_ACCOUNT_NUMBER": "Numéro de chaîne de bloc",
        "NEW_SUBMISSION_PREFERRED_LANGUAGE": "Langue de communication",
        "NEW_SUBMISSION_PREFERRED_LANGUAGE_OPTIONS": {
                "FR": "Français",
                "EN": "Anglais"
        },
        "NEW_SUBMISSION_PHONE": "Numéro de téléphone",
        "NEW_SUBMISSION_EMAIL": "Adresse courriel",
        "NEW_SUBMISSION_CITY": "Ville",
        "NEW_SUBMISSION_DATE_OF_BIRTH": "Date de naissance",
        "NEW_SUBMISSION_FINANCIAL_INSTITUTION_NUMBER": "Numéro de l'institution financière",
        "NEW_SUBMISSION_FINANCIAL_INSTITUTION_ACCOUNT_NUMBER": "Numéro de compte bancaire",
        "NEW_SUBMISSION_FINANCIAL_INSTITUTION_TRANSIT_NUMBER": "Numéro de transit du compte bancaire",
        "NEW_SUBMISSION_CRIMINAL_BACKGROUND_CHECK": "Vérification des antécédents judiciaires",
        "NEW_SUBMISSION_CRIMINAL_BACKGROUND_CHECK_DATE": "Date de vérification des antécédents judiciaires",
        "NEW_SUBMISSION_DRIVER_TRAINING_PHOTO": "Certificat de formation de conducteur",
        "NEW_SUBMISSION_DRIVER_TRAINING_DATE": "Date de réussite de la formation de conducteur",
        "NEW_SUBMISSION_DRIVER_LICENSE_NUMBER": "Numéro de permis de conduire",
        "NEW_SUBMISSION_DRIVER_LICENSE_EXPIRATION_DATE": "Date d'expiration du permis de conduire",
        "NEW_SUBMISSION_DRIVER_LICENSE_PHOTO": "Photo du permis de conduire",
        "NEW_SUBMISSION_TPS_NUMBER": "Numéro de TPS", 
        "NEW_SUBMISSION_TVQ_NUMBER": "Numéro de TVQ",
        "NEW_SUBMISSION_REVENU_QUEBEC_AUTHENTIFICATION_NUMBER": "Code d'autorisation de Revenu Québec",
        "NEW_SUBMISSION_REVENU_QUEBEC_IDENTIFICATION_NUMBER": "Numéro d'identification de Revenu Québec",
        "NEW_SUBMISSION_REVENU_QUEBEC_BILLING_NUMBER": "Numéro de facturation de Revenu Québec",
        // Vehicle Fields
        "NEW_SUBMISSION_VEHICLE_TYPE_ID": "Catégorie",
        "NEW_SUBMISSION_ALLOWED_RIDE_SERVICES": "Types de service autorisés",
        "NEW_SUBMISSION_COLOR": "Couleur",
        "NEW_SUBMISSION_COLOR_OPTIONS": {
                "GREEN" : "Vert",
                "RED" : "Rouge",
                "BLUE" : "Bleu",
                "GREY" : "Gris",
                "BLACK" : "Noir",
                "INDIGO" : "Indigo",
                "ORANGE" : "Orange",
                "YELLOW" : "Jaune",
                "PURPLE" : "Mauve",
                "WHITE" : "Blanc", 
                "BROWN" : "Brun",
                "PINK" : "Rose",
        },
        "NEW_SUBMISSION_BRAND": "Constructeur",
        "NEW_SUBMISSION_MODEL": "Modèle",
        "NEW_SUBMISSION_YEAR": "Année",
        "NEW_SUBMISSION_MILEAGE": "Kilométrage",
        "NEW_SUBMISSION_VEHICLE_IDENTIFICATION_NUMBER": "Numéro d'identification du véhicule (NIV)",
        "NEW_SUBMISSION_VEHICLE_REGISTRATION_CERTIFICATE_FILE_NUMBER": "Numéro de dossier émis par la SAAQ",
        "NEW_SUBMISSION_VEHICLE_REGISTRATION_PLATE_NUMBER": "Numéro de plaque",
        "NEW_SUBMISSION_VEHICLE_MECHANICAL_INSPECTION": "Inspection mécanique",
        "NEW_SUBMISSION_VEHICLE_MECHANICAL_INSPECTION_DATE": "Date de l'inspection mécanique",
        "NEW_SUBMISSION_INSURANCE_INSTITUTION_NAME": "Nom de l'assureur",
        "SEE_BALANCE_TRANSACTIONS": "Voir Balance & Transactions",
        "NEW_SUBMISSION_INSURANCE_INSTITUTION_NAME_OPTIONS": {
                "ALLSTATE_ASSURANCE": "Allstate Assurance",
                "AVIVA_CANADA": "Aviva Canada",
                "BANQUE_NATIONALE_ASSURANCES": "Banque Nationale Assurances",
                "BC_ASSUR": "BC Assur",
                "BELAIRDIRECT": "belairdirect",
                "CO_OPERATORS": "Co-operators",
                "COSECO_COMPAGNIE_DASSURANCE": "Compagnie d'assurance COSECO",
                "DESJARDINS_ASSURANCES": "Desjardins Assurances",
                "ECONOMICAL_ASSURANCE": "Economical Assurance",
                "INDUSTRIELLE_ALLIANCE": "Industrielle Alliance",
                "INTACT_ASSURANCES": "Intact Assurances",
                "LA_CAPITALE_ASSURANCE": "La Capitale Assurance / Beneva",
                "ROYAL_SUN_ALLIANCE_DU_CANADA": "RSA Canada",
                "SSQ_ASSURANCE": "SSQ Assurance",
                "WAWANESA_ASSURANCE": "Wawanesa Assurance",
                "AUTRE": "Autre"
        },
        "NEW_SUBMISSION_INSURANCE_POLICY_NUMBER": "Numéro de la police d'assurance",
        "NEW_SUBMISSION_INSURANCE_POLICY_PHOTO": "Photo de la police d'assurance",

        "NEW_SUBMISSION_VEHICLE_GRAY_CARD_NUMBER": "Numéro de la Carte Grise",
        "NEW_SUBMISSION_VEHICLE_GRAY_CARD_EXPIRY": "Date d'expiration de la Carte Grise",
        "NEW_SUBMISSION_VEHICLE_GRAY_CARD_PHOTO": "Photo de la Carte Grise",
        "NEW_SUBMISSION_TECHNICAL_VISIT_CERTIFICATE_PHOTO": "Photo du certificat de visite technique",
        "NEW_SUBMISSION_TECHNICAL_VISIT_CERTIFICATE_EXPIRY": "Date d'expiration du certificat de visite technique",
        "NEW_SUBMISSION_INSURANCE_EXPIRATION_DATE": "Date d'expiration d'attestation d'insurance",
        "NEW_SUBMISSION_FINANCIAL_INSTITUTION_RIB_KEY": "Clé RIB",

        "NEW_SUBMISSION_FINANCIAL_INSTITUTION_NAME": "Nom de la banque",
        "NEW_SUBMISSION_VEHICLE_OWNER": "Propriétaire du véhicule",
        "NEW_SUBMISSION_VEHICLE_OWNER_RUT": "Numéro de RUT du propriétaire du véhicule",
        

        // Driver All Submissions
        "DRIVERS_MENU_SUBMISSIONS_LIST": "Liste des soumissions",
        "DRIVERS_MENU_OLD_SUBMISSIONS_LIST": "Liste des soumissions",
        "DRIVER_SUBMISSION_DETAIL": "Détails de la soumission",
        "DRIVER_FINANCIAL_INFO": "Informations financières",
        "DRIVER_VEHICLE_INFO": "Informations sur le véhicule",
        "DRIVER_LICENSE_INFO": "Informations sue le permis de conduire",
        "DRIVER_OTHER_INFO": "Autres informations",
        "Open File": "Ouvrir le fichier",
        "SEND_REMINDER_ALERT_TEXT": "Vous êtes sur le point d'envoyer un rappel par courriel à {{name}} ({{email}}). Voulez-vous continuer?",
        "SEND_REMINDER_ALERT_SUCCESS_TEXT": "Le rappel a bien été envoyé.",
        "SEND_REMINDER_ALERT_ERROR": "Il y a eu une erreur lors de l'envoi.",

        // Driver submission field translations
        "License_expiration": "Date d'expiration",
        "License_number": "Numéro du permis de conduire",
        "language": "Langue",
        "insurance_number": "Numéro d'assurance",
        "insurance_institution": "Institution d'assurance",
        "insurance_photo": "Photo d'assurance",
        "driver_licence_photo": "Photo du permis de conduire",
        "criminal_background_check": "Vérification des antécédents criminels",
        "mechanical_inspection": "Inspection mécanique",
        "address": "Addresse",
        "tlc_driver_license_number": "Numéro du permis de conduire TLC",
        "tlc_driver_license_expiration": "Date d'expiration du permis de conduire TLC",
        "fh1_insurance_policy_number": "Numéro de la Police d'assurance FH1",
        "fh1_insurance_policy_effective_date": "Date d'entrée en vigueur de la Police d'assurance FH1",
        "fh1_insurance_policy_expiration_date": "Date d'expiration de la Police d'assurance FH1",
        "vehicle_identification_number": "Numéro de l'identification du véhicule",
        "vehicle_identification_expiration": "Date d'expiration de l'identification du véhicule",
        "tlc_inspection_number": "Numéro d'inspection TLC",
        "tlc_inspection_expiration": "Date d'expiration de l'inspection TLC",
        "certificate_of_liability": "Certificate of Liability",
        "vehicle_inspection": "Inspection du véhicule",
        "commercial_insurance": "Assurance commerciale",
        "vehicle_registration": "Immatriculation",
        "class_155_registration": "Immatriculation Classe 1-55",
        "three_year_driver_abstract": "Dossier du conducteur de trois ans",
        "elvis_inspection": "Inspection d'E.L.V.I.S.",
        "tvdl": "Transportation Network Driver's Licence",
        "file_upload": "Fichiers",
        "creation_date": "Date de création",
        "progress": "Progression",
        "status": "Status",
        "REGISTERED": "Inscrit",
        "ACTIVE": "Actif",
        "CANCELLED": "Annulé",
        "SUSPENDED": "Suspendu",
        "APPLIED": "Appliqué",
        "COMPLETED": "Complété",
        "VALIDATED": "Validé",
        "ALL": "Tous",
        "OPEN": "Ouvert",
        "ARCHIVED": "Archivé",
        "reminder": "Rappel",
        "send": "Envoyer",
        "last_reminder_sent": "Dernier rappel",
        "LAST_ACTIVITY": "Dernière activité",

        // Driver submissions category names
        "personal_data": "Personal data",
        "tlc": "TLC",
        "fh1": "FH1",
        "vehicle": "Vehicle",

        "RIDERS_MENU": "Passagers",
        "ACTIVE_RIDERS": "Passagers",
        "BLACKLISTED_RIDERS": "Passagers bloqués",
        "RIDER_DETAIL": "Détails du passager",
        "CREATED_AT": "Date de création",
        "CHAIN_ACCOUNT": "Compte Blockchain",
        "HOURLY_RATE": "Salaire heure",
        "RIDER_NUMBER_OF_RIDES": 'Nombre de courses',
        "RIDER_TRIES_LENGTH": "Nombre de tentative",
        "RIDER_SUCCESS_RATE": "Taux de succès",
        "RIDERSLIST_TITLE": "Passagers dans",
        "DEACTIVATE_DRIVER": "Désactiver conducteur",
        "FROM_ADDRESS": "Adresse de départ",
        "TO_ADDRESS": "Adresse de destination",
        "LOGS": "JOURNAUX",
        "TOKEN_TRANSFER": "Transfer de jetons",
        "FROM_ACCOUNT": "De",
        "TO_ACCOUNT": "À",
        "RIDE_REQUEST": "Requête",
        "AMOUNT": "Quantité",
        "TIME_SENT": "Temps",
        "MEMO": "Mémo",
        "ALL_REQUESTS": "Toutes les requêtes",
        "RIDES_COMPLETED": "Facturés",
        "SCHEDULED_RIDES": "Cédulés",
        "CUSTOMER_ORDER": "Commande Client",
        "DRIVER_ROUTES": "Routes conducteur",
        "SCHEDULED_RIDES_ARCHIVED": "Cédulés archivés",
        "ARCHIVED_CUSTOMER_ORDERS":"Commandes client archivées",
        "RIDES_COMPLETED_TITLE": "Courses Facturés",
        "REQUEST_STATUS": "Statut",
        "SUCCESS": "Succès",
        "NO_DRIVER": "Aucun conducteur",
        "CANCELLED_BY_COMMUNITY": "Annulé par la coopérative",
        "CANCELLED_BY_RIDER": "Annulé par passager",
        "CANCELLED_BY_DRIVER": "Annulé par conducteur",
        "NOT_YET_ACCEPTED": "Recherche de conducteur",
        "UNKNOWN_CANCELLATION": 'Annulation',
        "LIVE": "En cours",
        "MORE_DETAILS": "Détails",
        "CURRENCY_ESTIMATE_DISCLAIMER":"Ceci est un estimé et peut varié selon les taux de change.",
        "RIDER_BALANCE": "Balance",
        "RIDER_ACTION_SEND_SHRDISCOUNT": "Envoyer un rabais partagé",
        "RIDER_ACTION_SEND_MONEY": "Envoyer des jetons EVA",
        "RIDER_ACTION_CREATE_DEBT": "Retirer des jetons EVA",
        "RIDER_ACTION_BLACKLIST": "Bannir le passager",
        "RIDER_ACTION_WHITELIST": "Autoriser le passager",
        "RIDER_ACTION_DELETE": "Supprimmer le passager",
        "RIDER_ACTION_SEND_SHRDISCOUNT_BUTTON": "Exécuter",
        "RIDE_AMOUNT_LABEL": "Montant en jetons",
        "DEBT_MEMO_LABEL": "Mémo de la dette",
        "SEND_TRANSFER_MEMO_LABEL": "Mémo du transfert",
        "RIDE_AMOUNT_FIAT_LABEL": "Montant en argent",
        "RIDER_ACTION_TITLE": "Liste des actions",
        "RIDE_ACTION_TITLE": "Actions sur la course",
        "RIDE_ACTION_SEND_INVOICE": "Envoyer le reçu",
        "RIDE_INVOICE_SEND_TO_EMAIL": "Adresse courriel",
        "RIDER_EMAIL": "Courriel",
        "RIDER_LAST_CONNECTION": "Dernière connexion",
        "RIDER_NUMBER_CONNECTION": "Connexions",
        "SEND_SHARED_DISCOUNT_CONFIRM": "Envoyer un rabais partagé à ce membre passager?",
        "SEND_BLACKLIST_CONFIRM": "Bloquer le membre passager",
        "SEND_WHITELIST_CONFIRM": "Autoriser le membre passager ",
        "DELETE_RIDER_CONFIRM": "Supprimmer le membre passager ",
        "BLACKLIST_RIDER_SUCCESSFULL": "Membre passager bloqué",
        "WHITELIST_RIDER_SUCCESSFULL": "Membre passager autorisé",
        "DELETE_RIDER_SUCCESSFULL": "Membre passager supprimé. Le numéro est libérée",
        "SEND_TOKEN_CONFIRM": "Envoyer ce nombre de jeton à l'utilisateur? ",
        "NEW_DEBT_TOKEN_CONFIRM": "Retirer ce nombre de jetons à l'utilisateur?",
        "ARE_YOU_SURE": "Êtes vous certain?",
        "YES_DO_IT": "Oui.",
        "YES_ACTIVATE_DRIVER": "Oui, activer conducteur!",
        "CANCEL": "Annuler",
        "CANNOT_SEND": "Ne peut pas envoyer les jetons",
        "OK": "Ok",
        "MISSING_INFO_TOKEN": "Tu manques des infos pour envoyer des jetons",
        "SENDING": "Envoi en cours",
        "SENDING_REQUEST": "Envoi en cours...",
        "SENDING_TOKEN_USER": "Envoi ce montant de jetons à l'utilisateur",
        "SENDING_DEACTIVATING_DRIVER": "Envoi d'action pour désactiver conducteur",
        "ERROR_ALERT": "Une erreur s'est produite",
        "ERROR": "Erreur",
        "DRIVER_DISPATCHED_NUMBER": "Nombre de conducteurs envoyés",
        "DRIVER_RATING": "Éval.",
        "DRIVER_SUCCESS_RATIO": "Taux succès",
        "DRIVER_ACCEPTANCE_RATIO": "Taux acceptation",
        "NO_RIDES": "N/A",
        "RIDE_DETAILS": "Détails de la course",
        "REQUEST_SUMMARY": "Résumé de la requête",
        "SHARED_DISCOUNT_SUCCESSFULLY_SENT": "Rabais partagé envoyé avec succès.",
        "TOKENS_SUCCESSFULLY_SENT": "Jeton envoyés avec succès.",
        "DRIVER_SUCCESSFULLY_DEACTIVATED": "Le conducteur a été désactivé avec succès.",
        "DEBT_SUCCESFULLY_SENT": "Jetons retirés avec succès.",
        "DRIVER_NAME": "Nom conducteur",
        "TRANSACTION_HISTORY": "Historique de transactions",
        "RIDER_BLACKLISTED": "Ce passager est blacklisté!",
        "DELETE_DRIVER_CONFIRMATION": "Voulez-vous déactiver le compte de ce conducteur: ",
        "ACTIVATE_DRIVER_CONFIRMATION": "Voulez-vous activer le compte de ce conducteur: ",
        "ACTIVATE_DRIVER_SUCCESS": "Le compte de ce conducteur est activé avec succès: ",
        "ACTIVATED": "Activé",
        "ACTIVATE_PERMISSIONS_CONFIRM": "N'oubliez pas de réactiver les permissions du conducteur.",
        "RIDER_NOT_FOUND": "Passager non trouvé",
        "RIDER_NOT_FOUND_ERROR_MESSAGE": "Le passager sous le compte suivant n'existe pas: ",
        "WARNING": "Attention",
        "CONTINUE": "Continuer",

        //Dashboard Graphs
        "DASHBOARD_SUCC_RIDES_TITLE": "Nombre cumulatif de courses réussies",     
        "DASHBOARD_CURRENT_MONTH": "Mois en cours",
        "DASHBOARD_PREV_MONTH": "Mois précédent",
	"DASHBOARD_NEW_USERS_TITLE": "Nombre cumulatif des nouvels utilisateurs",
        "DASHBOARD_TWO_MONTHS_AGO": "Il y a deux mois",
        "DASHBOARD_THREE_MONTHS_AGO": "Il y a trois mois",
        "DASHBOARD_FOUR_MONTHS_AGO": "Il y a quatre mois",
        "DASHBOARD_CURRENT_RIDES": "Courses actuelles",
        "NEW_DRIVERS": "Nouveau conducteurs",
        "MONTHLY_GROWTH": "Croissance mensuelle",
        "DASHBOARD_RIGHT_NOW": "Maintenant",
        "DASHBOARD_NUM_RIDES": "Nombre de Courses",
        "DASHBOARD_SINCE_BEG": "Depuis le début",
        "DASHBOARD_LAST_WEEK": "Cette semaine",
        "DASHBOARD_RIDES_TODAY": "Course aujourd'hui",
        "DASHBOARD_RIDES_TODAY_SUCCESSFUL": "Courses Complétées",
        "DASHBOARD_RIDES_TODAY_FAILED": "Courses Échouées",
        "DASHBOARD_UPDATED_NOW": "Mis à jour maintenant",
        "DASHBOARD_TODAY": "Aujourd'hui",
        "DASHBOARD_REVENUE_TODAY": "Revenu aujourd'hui",
        "DASHBOARD_CURRENT_STATUS": "Statut actuel",
        "DASHBOARD_SUCCESS": "Réussi",
        "DASHBOARD_CANCELLED_COMM": "Annulé par la communauté",
        "DASHBOARD_CANCELLED_DRIVER": "Annulé par le conducteur",
        "DASHBOARD_CANCELLED_RIDER": "Annulé par le passager",
        "DASHBOARD_NEVER_ACC": "Jamais accepté",

        //Driver Edit
	"DRIVER_IS_RIDER_LINK": "Cliquez ici pour la page du passager",
        "DRIVER_EDIT_MORE_INFO": "Plus d'informations",
        "DRIVER_EDIT_CLOSE": "Fermer",
        "DRIVER_EDIT_ONLINE_SESSIONS": "Sessions en ligne",
        "DRIVER_EDIT_START": "Début de session",
        "DRIVER_EDIT_END": "Fin de session",
        "DRIVER_EDIT_DURATION": "Durée",
        "DRIVER_EDIT_DATE": "Date",

        //Ride and Ride request Details 
        "MAP_STAT_CARD_TITLE": "CARTE",
        "INFO_STAT_CARD_TITLE": "INFORMATION",
        "ACTIONS_STAT_CARD_TITLE": "ACTIONS",
        "MAP_STAT_CARD_HINT": "Afficher la carte",
        "INFO_STAT_CARD_HINT": "Afficher l'information",
        "ACTIONS_STAT_CARD_HINT": "Afficher les actions",
        "HIDE_STAT_CARD_HINT": "Cacher",
        "MAP_LABEL_PICKUP": "Itinéraire jusqu'au point de rendez-vous",
        "MAP_LABEL_DEST": "Itinéraire jusqu'au point de destination",
        "MAP_LABEL_OPTIMAL": "Itinéraire optimal",
        "MAP_MARKER_RIDE_ACC": "Course Accepte'",
        "MAP_MARKER_PICKUP": "Point d'embarquement",
        "MAP_MARKER_DEST": "Destination",
        "MAP_MARKER_ADDRESS": "Adresse: ",
        "INFO_TABLE_TITLE": "Général",
        "INFO_TABLE_START": "Début",
        "INFO_TABLE_END": "Fin",
        "INFO_TABLE_DISTANCE": "Distance estimée entre le ramassage et la destination",
        "INFO_TABLE_FROM": "À partir de",
        "INFO_TABLE_TO": "Jusqu'à",
        "INFO_TABLE_SUCC": "Réussi",
        "INFO_TABLE_DRIVER": "Membre Conducteur",
        "INFO_TABLE_RIDER": "Passager",
        "INFO_TABLE_RIDE_REQUEST": "Requête associée à la course",
        "INFO_TABLE_PROOF": "Preuve de livraison",
        "INFO_TABLE_ONSITE_DELTA": "Minutes d'attente au ramassage",
        "ALL_REQUESTS_DETAILS_TAB": "Détails",
        "REQUEST_DETAILS_GENERAL_TITLE": "Général",
        "REQUEST_DETAILS_GENERAL_ID": "Route ID",
        "REQUEST_DETAILS_GENERAL_RIDER": "Membre passager",
        "REQUEST_DETAILS_GENERAL_DRIVER": "Membre conducteur",
        "REQUEST_DETAILS_GENERAL_SERVICE_TYPE": "Type de service",
        "REQUEST_DETAILS_GENERAL_FROM": "À partir de",
        "REQUEST_DETAILS_GENERAL_TO": "Jusqu'à",
        "REQUEST_DETAILS_GENERAL_DISTANCE": "Distance estimée entre le ramassage et la destination",
        "REQUEST_DETAILS_GENERAL_TIME": "Temps estimé entre le ramassage et la destination",
        "TIMELINE_LIST_TITLE": "Ligne de temps",
        "TIMELINE_LIST_REQUEST_START": "Début de la requête",
        "TIMELINE_LIST_FIRST_ACCEPTED": "Première acceptation",
        "TIMELINE_LIST_LAST_ACCEPTED": "Dernière acceptation",
        "TIMELINE_LIST_CANCELLED": "Dernière annulation",
        "TIMELINE_LIST_REQUEST_END": "Fin de la requête",
        "TIMELINE_LIST_DELTA": "Délai moyen de réponse des conducteurs",
        "ALL_REQUESTS_HISTORY_TAB": "Historique",
        "HISTORY_TABLE_LEGEND_PASSENGER": "Passager",
        "HISTORY_TABLE_LEGEND_COMMUNITY": "Communauté",
        "HISTORY_TABLE_LEGEND_SYSTEM": "Système",
        "HISTORY_TABLE_TITLE": "Requêtes envoyées",
        "HISTORY_TABLE_DRIVER": "Conducteur",
        "HISTORY_TABLE_REQUEST_DATETIME": "Moment d'envoi",
        "HISTORY_TABLE_ACCEPTANCE_DATETIME": "Acceptation",
        "HISTORY_TABLE_CANCELLATION_DATETIME": "Annulation",
        "HISTORY_TABLE_REFUSAL_DATETIME": "Refus",
        "HISTORY_TABLE_ONSITE_DATETIME": "Arrivée sur le site",
        "HISTORY_TABLE_START_DATETIME": "Commencement",
        "HISTORY_TABLE_FINISH_DATETIME": "Fin",
        "HISTORY_LOG_TITLE": "Journal",
        "HISTORY_LOG_REQUEST_SENT": "Une requête a été envoyée à",
        "HISTORY_LOG_REQUEST_ACCEPTED": "a accepté la course et est à environ {{estimatedPickupDistance}} km du point de ramassage. Le temps estimé pour s'y rendre est de {{estimatedPickupTime}}.",
        "HISTORY_LOG_REQUEST_DECLINED": "a décliné la requête.",
        "HISTORY_LOG_REQUEST_DECLINED_BY_SYSTEM": "Délai dépassé. Le système a décliné la requête pour",
        "HISTORY_LOG_REQUEST_ONSITE": "est arrivé au lieu de ramassage.",
        "HISTORY_LOG_REQUEST_CANCELLED": "a annulé la course en cours.",
        "HISTORY_LOG_REQUEST_CANCELLED_BY_COMMUNITY": "La communauté a annulé la course en cours.",
        "HISTORY_LOG_REQUEST_CANCELLED_BY_RIDER": "Le membre passager a annulé la course en cours.",
        "HISTORY_LOG_REQUEST_CANCELLED_BY_SYSTEM": "Le système a annulé la course en cours.",
        "HISTORY_LOG_REQUEST_STARTED": "a commencé la course.",
        "HISTORY_LOG_REQUEST_STARTED_BY_COMMUNITY": "La communauté a manuellement commencé la course pour ",
        "HISTORY_LOG_REQUEST_FINISHED": "a terminé la course.",
        "HISTORY_LOG_REQUEST_FINISHED_BY_COMMUNITY": "La communauté a manuellement terminé la course pour ",
        "HISTORY_LOG_REQUEST_FINISHED_BY_SYSTEM": "Le système a terminé la course pour ",
        "REQ_STATUS": "État de la requête",
        "CHARGE_TABLE_TITLE": "FRAIS",
        "CHARGE_TABLE_TIP": "Pourboire:",
        "CHARGE_TABLE_MIN": "Minimum:",
        "CHARGE_TABLE_PROMO": "Code promotionnel:",
        "CHARGE_TABLE_COMM": "Comm:",
        "CHARGE_TABLE_COMM_SUPPL": "Comm Supplémentaire:",
        "CHARGE_TABLE_BASE": "Prix de Base:",
        "CHARGE_TABLE_CREDIT": "Prix Carte de Credit:",
        "CHARGE_TABLE_DIST": "Prix Distance:",
        "CHARGE_TABLE_TIME": "Prix Temps:",
        "CHARGE_TABLE_DRIVER": "Prix Membre Conducteur:",
        "CHARGE_TABLE_RIDER_ALGO": "Frais de trajet estimés:",
        "CHARGE_TABLE_RIDE": "Prix Course:",
        "CHARGE_TABLE_FROM_ZONE": "De La Zone:",
        "CHARGE_TABLE_TO_ZONE": "A La Zone:",
        "CHARGE_TABLE_WORLD": "Monde:",
        "CHARGE_TABLE_WORLD_SUPPL": "Monde Supplémentaire:",
        "CHARGE_TABLE_TOTAL": "Total:",
        "CHARGE_TABLE_TAX": "Taxe:",
        "CHARGE_TABLE_TOTAL_TAX": "Totale (avec taxe):",
        "NO_MAP_INFO": "Pas d'information disponible",
        "MAP_LOADING": "Chargement...",
        "RIDE_REQ_STAT_CARD_TITLE": "DERNIÈRES 20 REQUETES",
        "RIDE_REQ_STAT_CARD_HINT": "Montrer",
        "STATUS": "STATUT",
        "DATE": "Date",
        "CUSTOMER_NAME": "Prénom et nom du client",
        "CUSTOMER_PHONE": "Téléphone du client",
        "CUSTOMER_PHONE_EXTENSION": "Extension",
        "ORDER_NUMBER": "# Commande",
        "DRIVER": "Membre conducteur",
        "LOAD_MORE": "PLUS DE RÉSULTATS",
        "NO_DATA": "AUCUNE DONNÉE DISPONIBLE",
        "ADD_MONTH": "Ajouter un mois",
        "REMOVE_MONTH": "Supprimer un mois",

        //ACTIVE RIDES PAGE
        "START_RIDE": "Démarrer la course",
        "END_RIDE": "Terminer la course",
        "TRACK_RIDE": "Suivre",
        "TRACKING_LINK": "Suivi",
        "CANCEL_RIDE": "Annuler la course",
        "ACTION_SUCCESS": "L'action a réussi!",
        "ACTION_ERROR": "Une erreur est survenue:",
        "CANCEL_ACTIVE_RIDE_ALERT_TITLE": "Annuler la course",
        "CANCEL_ACTIVE_RIDE_ALERT_CONFIRM": "Oui, annuler!",
        "CANCEL_ACTIVE_RIDE_ALERT_BODY": "Vous êtes sur le point d'annuler une course. ID: ",
        "CANCEL_ACTIVE_CORP_RIDE_ALERT_BODY": "Vous êtes sur le point d'annuler cette course.",
        "START_ACTIVE_RIDE_ALERT_TITLE": "Démarrer la course",
        "INCREMENT_RIDE_ALERT_TITLE": "Prochaine étape",
        "START_ACTIVE_RIDE_ALERT_CONFIRM": "Oui, démarrer!",
        "INCREMENT_RIDE_ALERT_CONFIRM": "Oui! Prochaine étape!",
        "START_ACTIVE_RIDE_ALERT_BODY": "Vous êtes sur le point de démarrer une course. ID: ",
        "INCREMENT_STEP": "Passez l'étape",
        "INCREMENT_RIDE_ALERT_BODY": "Vous êtes sur le point de de faire passer la course à la prochaine étape. Toute preuve ou document nécessaire au succès n'est donc pas fournis par le conducteur.",
        "END_ACTIVE_RIDE_ALERT_TITLE": "Terminer la course",
        "END_ACTIVE_RIDE_ALERT_CONFIRM": "Oui, terminer!",
        "END_ACTIVE_RIDE_ALERT_BODY": "Vous êtes sur le point de terminer une course. ID: ",
        "WANT_TO_PROCEED": "Voulez-vous poursuivre?",
        "RIDER_IS_DRIVER_MAIN": "CE MEMBRE EST UN CONDUCTEUR",
        "RIDER_IS_DRIVER_LINK": " Cliquez ici pour la page du conducteur",
        "RIDER_IS_CORPORATE_USER": "Ceci est un compte <b>corporatif</b>",
        "SEND_INVOICE_ALERT_TITLE": "Send Invoice",
        "SEND_INVOICE_ALERT_CONFIRM": "Yes, Send Invoice!",
        "SEND_INVOICE_ALERT_BODY": "You are about to send an invoice to ",
        "INVALID_EMAIL_OR_ROUTE": "Email or route invalid",
        "ERROR_SENDING_INVOICE": "There was an error whille generating invoice and sending it.",
        "ALERT_LOADING": "Loading...",
        "WELCOME_SIDEBAR": "Bienvenue, ",
        "YES": "Oui",
        "NO": "Non",
        "NEXT": "SUIVANT",
        "PREVIOUS": "PRÉCÉDENT",
        "LOADING": "CHARGEMENT",
        "PAGE": "PAGE",
        "ROW_PER_PAGE":"LIGNES PAR PAGE",
        "MAP": "CARTE",
        "JOURNEY": "TRAJET",
        "IS_DRIVER_ONLINE": "En ligne",
        "ONSITE_DATE": "Date sur site",

        //Edit Rides
        "EDIT_RIDE": "Modifier",
        "EDIT_RIDE_ALERT_TITLE":"Modifier Ride",
        "EDIT_RIDE_ALERT_BODY": "Vous êtes sur le point de modifier cette course.",
        "EDIT_RIDE_ALERT_CONFIRM":"Oui, modifier la course",
        "GET_PRICE_ESTIMATE": "Prix estimé",
        "RIDESHARING": "Co-voiturage",
        "DELIVERY": "Livraison",
        "ELECTRIC_PLACEHOLDER" : "Electrique",
        "RIDE_SERVICE_TYPE":"Type de Service",
        "TIP_AMOUNT": "Pourboire en token",
        "NEW_PRICE": "Nouveau Prix",
        "EDIT_RIDE_ERROR": "Cette requête de modification est invalide. Veuillez valider les addresses avant d'envoyer la requête.",
        "RIDE_NOT_FOUND" : "Course non trouvée",
        "EDIT_CONFIRMATION" : "Confirmation",
        "EDIT_ERROR" : "Erreur",
        "MANUAL_DISPATCH": "Dispatch Manuel",
        "MANUAL_DISPATCH_CONFIRM": 'Oui, envoyer la course au chauffeur',

        "MEMO_REFUND_RIDER": "Remboursement Passager",
        "MEMO_TRANSACTION_ERROR": "Erreur de transaction",
        "MEMO_PROMO_WITHDRAWAL": "Retrait de promo",
        "MEMO_DRIVER_REQUEST": "Demande du conducteur",
        "MEMO_OTHER": "Other",
        "MEMO_WAVE": "Rechargement WAVE",
        "MEMO_DETAILS": "Detailles",
        "TYPE": "Type",
        "REASON": "Raison",
        "CLOSE": "Fermer",

        "DRIVER_PHONE": "Numéro de téléphone du conducteur",
        "RIDER_PHONE": "Numéro de téléphone du passager",
        "NONE": "Aucun",
        "COMPLETED_DATE": "Date d'achèvement",
        "PROOF_OF_CUSTOMER": "Vérification du client",
        "GO_TO_DESTINATION": "Allez vers la destination",
        "CASH_PAYMENT_COLLECTION": "Collecte d'argent en espèce",
        "TIME_TAKEN":"Temps pris",
        "PROOF_OF_ADDRESS": "Vérification de l'adresse",
        "GO_BACK_TO_STORE": "Retour en magasin",
        "DESTINATION": "Destination",
        "PICKUP": "Ramassage",
        "RIDER": "Membre Passager",

        "PROMOTION": "Promotion",
        "SENT_IN_PROMOTION": "Envoyé en promotion",
        "REMEDIAL": "Recticatif",

        "PERMISSIONS": "Permissions",
        "INFORMATION": "Information",
        "RIDE_SHARING_SERVICE": "Covoiturage",
        "DELIVERY_SERVICE": "Livraison",
        "BULK": "Livraison par fourgonnette",
        "DELIVERY_SERVICE_BIKE": "Bike delivery",
        "DELIVERY_SERVICE_MOTORCYCLE": "Moto delivery",
        "DELIVERY_SERVICE_AMBULANCE": "Ambulance",
        "RIDE_SHARING_MOTO": "Covoiturage Moto",
        "DELIVERY_TRICYCLE": "Livraisons par tricycle",
        "RIDE_SHARING_TRICYCLE": "Covoiturage en tricycle",
        "RIDE_SHARING_SERVICE_LUX": "Co-voiturage luxe",
        "SHUTTLE": "Navette",

        "RIDE_SERVICE_NAME":"Type de trajet",

        "TABLE_API_ERROR_1": "Un problème est survenu lors de l'obtention des données.",
        "TABLE_API_ERROR_2": "Veuillez réessayer plus tard.",

        // Report section
        "REPORTS": "Rapports",
        "GENERATE_REPORTS_TITLE": "Générer un rapport",
        "REPORT_TYPE_CHOOSE": "Sélectionner le type de rapport",
        "GET_RIDER_RIDES_FREQUENCY": "Nombre de passager total par nombre de course",
        "GENERATE_BUTTON_TITLE": "Générer",


        //JTF section
        "DRIVERS_MENU_JTF": "Salariés",
        "ADD_NEW_JTF_DRIVER": "Ajouter conducteur JTF",
        "ADD SHIFT": "Ajouter shift",
        "SHOW_SHIFT": "Montrer shifts",
        "REMOVE_FROM_JTF": "Retirer du JTF",
        "JTF_SHIFT": "JTF Shift",
        "JTF_SHIFT_SCHEDULED": "Shift cédulés",
        "JTF_SAVE": "Sauvegarder",
        "JTF_TO": "A",
        "JTF_DELETE_SHIFT": "Enlever shift",
        "JTF_CONFIRM": "Confirmer",
        "JTF_DESACTIVATE_DRIVER": "Désactivation du conducteur JTF",
        "JTF_ADDING_DRIVER": "Ajout du conducteur JTF",
        "JTF_ADDING_SHIFT": "Ajout du shift",
        "JTF_SALARY_UPDATE": "Le salaire du conducteur a bien été modifier.",

        //Driver communication section
        "COMMUNICATION_TITLE": "Opérations de communications",
        "ACTION_SEND_SMS": "Envoyer des textos",
        "ACTION_SEND_NOTIFICATIONS": "Envoyer des notifications",
        "ACTION_SEND_EMAIL": "Envoyer des courriels",
        "ACTION_SEND_TEMPLATED_EMAIL": "Envoyer des modèles de courriels",
        "COVID_CERT": "Certificat Covid",
        "DRIVER_SMS_MESSAGE": "Message Texte",
        "SELECT_ALL": "Sélectionner tout",
        "SEND_SMS": "Envoyer",
        "JTF_SHIFT_REMOVED": "Le shift a bien été retirer.",
        "JTF_SHIFT_ADDED_SUCCESS": "Le shift a bien été ajouter",
        "JTF_DRIVER_ADDED_SUCCESS": "Le conducteur a bien été ajouter au JTF.",
        "JTF_DRIVER_REMOVE_SUCCESS": "Le conducteur a bien été retirer du JTF",
        "JTF_HOURLY_RATE": "Salaire de l'heure",
        "JTF_START_TIME": "Heure début",
        "JTF_END_TIME": "Heure fin",
        "JTF_DELETE_CONFIRM": "Voulez-vous vraiment retirer le chauffeur du JTF?",
        "NEW_SHIFT": "Nouveau shift",
        "SEND": "Envoyer",

        //Online Drivers
        "DRIVERS_MENU_ONLINE": "Carte en ligne",
        "IN_RIDE": "Course en cours",
        "FIND_DRIVER": "Trouver un conducteur",
        "NO_PERMISSIONS": "Aucune autorisation définie",
        "TIP": "Pourboire",

        "IN_RIDE_STATUS": "En route vers ramassage",
        "SCHEDULED_STATUS": "Cédulé",
        "DISPATCHING_STATUS": "En envoi",
        "TRANSIT_PICKUP": "En route ramassage",
        "ARRIVED_PICKUP": "Arrivé ramassage",
        "TRANSIT_DESTINATION": "En route destination",
        "ARRIVED_DESTINATION": "Arrivé destination",

        "SCHEDULED_RIDE_HEALTHCHECK": "Status Monitoré",
        "HEALTHCHECK": "Status Monitoré",
        "SCHEDULED_RIDE_PROBLEM": "Problème",
        "SCHEDULED_RIDE_CLEAR": "Ok",
        "SCHEDULED_DATE": "Date cédulée",
        "TRACKING_ID": "Suivi id",
        "ARCHIVED_DATE": "Date archivée",
        "REDISPATCH_RIDE": "Envoyer à nouveau",
        "REDISPATCH_RIDE_ALERT_TITLE": "Renvoier la livraison au conducteur",
        "REDISPATCH_RIDE_ALERT_YES": "Oui!",
        "REDISPATCH_RIDE_ALERT_BODY": "Vous êtes en train de renvoyer une livraison en envoie. Vérifier si cette course n'est pas déja en route.",

        //Units
        "KILOMETER": "km",
        "MINUTE": "min",
        "SECOND": "sec",

        //404
        "404_TITLE": "404 - Page Non Trouvée",
        "404_CLICK": "Cliquez ",
        "404_HERE": "ici",
        "404_RETURN": " pour revenir à la page d'accueil.",

        "REFILL_AMOUNT_CORPORATE_BUSINESS": "Recharge de la balance corporatif",

        "ADDITIONAL_INFORMATION": "Informations complémentaires",
        "AUTHORIZATION_CODE": "Code d'autorisation Revenu QC",
        "BILLING_NUMBER": "Numéro de facturation",
        "IDENTIFICATION_CODE": "Code d'identification Revenu QC",
        "RIDES_MENU_CANCELLED": "Annulées",
        "ACCEPTED_BLOCK_TIME": "Temps Acceptation",
        "CANCELED_BLOCK_TIME": "Temps Annulation",
        "DELTA_TIME_CANCELLED": "Temps avec course",
        "ESTIMATED_PICKUP_TIME": "Temps pour pickup",
        "ENABLED": "Activé",
        "DISABLED": "Désactivé",
        "PIN_CODE_TITLE": "Débogage : Code PIN",
        "PIN_CODE_SUBTITLE": "Activez l'affichage du code PIN sur l'application Eva. Veuillez ne pas le modifier sans autorisation.",
        "ARCHIVE_SUBMISSION" : "Archiver la soumission",
        "RESTORE_SUBMISSION" : "Restorer la soumission",
        "ATTENTION" : "Attention !",
        "ARE_YOU_SURE_ARCHIVE" : "Êtes-vous sûr de vouloir archiver cette soumission ?",
        "ARE_YOU_SURE_RESTORE" : "Êtes-vous sûr de vouloir restorer cette soumission ?",
        "CONFIRM" : "Confirmer",
        "SUCCESS" : "Succès",
        "RESTORE_SUCCESS" : "Restoration réussi de la soumission",
        "RESTORE_ERROR" : "Il y a eu un problème lors de la restoration de la soumission.",
        "SUBMISSION_IS_ARCHIVED": "La soumission est archivée",
        "DRIVER_SUBMISSIONS_ARCHIVE": "Soumissions Archivées",
        "CANNABIS": "Cannabis",
        "FLAG_DRIVER": "Signaler conducteur",
        "UNFLAG_DRIVER": "Ne pas signaler conducteur",
        "FLAG_CONFIRMATION": "Voulez-vous signaler comme risque élevé le conducteur ",
        "UNFLAG_CONFIRMATION": "Voulez-vous supprimer l'indicateur de risque pour le conducteur ",
        "FLAG_TITLE": "Conducteur signalé",
        "UNFLAG_TITLE": "Conducteur non signalé",
        "FLAG_SUCCESSFUL": "a été signalé avec succès comme étant à haut risque",
        "UNFLAG_SUCCESSFUL": "a été signalé avec succès comme étant à bas risque",
        "SENDING_FLAG_DRIVER": "Signalisation du conducteur...",
        "SENDING_UNFLAG_DRIVER": "Signalisation du conducteur...",
        "DRIVER_FLAGGED": "Conducteur signalé comme risque élevé",
        "FLAGGED_DRIVER_DESC": "Ce conducteur est signalé comme étant à haut risque. Surveillez la course de près! Cela peut être modifié sur la page de modification du conducteur.",
        "FLAGGED_RIDER_DESC": "Ce passager est signalé comme étant à haut risque car son compte a été créé il y a moins de 24 heures. Surveillez la course de près!",
        "NOTES_DRIVER": "Notes",
        "EDIT": "Editer",
        "EDIT_COMMENT": "Editer le commentaire",
        "DELETE": "Supprimer",
        "SUBMIT": "Soumettre",
        "NO_NOTES_YET": "Aucune note à afficher.",
        "BUSINESS_NAME": "NOM DE COMPAGNIE",
        "BUSINESS": "COMPAGNIE",
        "IS_FLAGGED": "Conducteurs signalés",
        "EXTRA_FILTERS": "Filtres supplémentaires",
        "LATE": "Le conducteur est arrivé en retard à la destination.",
        "ORDER_DAMAGED": "Le conducteur a endommagé une commande.",
        "WRONG_DESTINATION": "Le conducteur a livré la commande à la mauvaise adresse.",
        "ORDER_NOT_DELIVERED": "Le conducteur n'a pas livré une commande.",
        "LATE_PICKUP": "Le conducteur a été en retard pour le ramassage.",
        "DANGEROUS_DRIVING": "Le conducteur a conduit de manière dangereuse.",
        "LOW_SUCCESS_RATE": "Le conducteur a un taux de réussite faible.",
        "LOW_CANCELLATION_RATE": "Le conducteur a un faible taux d'annulation.",
        "MISBEHAVIOUR": "Le conducteur a été signalé pour mauvais comportement.",
        "LATE_SELECT": "Commande tardive",
        "ORDER_DAMAGED_SELECT": "Commande endommagée",
        "WRONG_DESTINATION_SELECT": "Mauvaise destination",
        "ORDER_NOT_DELIVERED_SELECT": "Commande non livrée",
        "LATE_PICKUP_SELECT": "Retard pour le ramassage",
        "DANGEROUS_DRIVING_SELECT": "Conduite dangereuse",
        "LOW_ACCEPTATION_RATE" : "Le conducteur a un taux faible d'acceptation. ",
        "LOW_ACCEPTATION_RATE_SELECT": "Faible taux d'acceptation" ,
        "HIGH_ACCEPTATION_RATE_SELECT": "Haut taux d'acceptation",
        "HIGH_ACCEPTATION_RATE" : "Le conducteur a un haut taux d'acceptation. " ,
        "LOW_CANCELLATION_RATE_SELECT": "Faible taux d'annulation", 
        "HIGH_CANCELLATION_RATE_SELECT": "Haut taux d'annulation",
        "HIGH_CANCELLATION_RATE": "Le conducteur a un haut taux d'annulation", 
        "LOW_SUCCESS_RATE_SELECT": "Faible taux de réussite",
        "MISBEHAVIOUR_SELECT": "Mauvaise conduite",
        "CUSTOM": "Autre...",
        "RIDE_REQUESTS_RIDER_GRAPH_TITLE": "Courses demandées par semaine",
        "RIDER_VERIFIED": "Vérifié",
        "RIDER_VERIFIED_TEXT": "Ce membre passager a été vérifié comme étant une personne réelle !",
        "RIDER_NOT_VERIFIED": "Pas encore vérifié",
        "RIDER_NOT_VERIFIED_TEXT": "Ce passager n'a pas encore été vérifié comme étant une personne réelle. Vous pouvez le vérifier manuellement dans la liste d'actions ci-dessous, ou attendre qu'il ait effectué 5 trajets, à ce moment-là le système le vérifiera automatiquement.",
        "RIDER_ACTION_VERIFY": "Vérifier le passager",
        "SEND_VERIFY_CONFIRM": "Le passager sera automatiquement vérifié après 5 trajets réussis. Voulez-vous vérifier que ce passager n'est pas frauduleux",
        "VERIFY_RIDER_SUCCESSFULL": "Passager vérifié avec succès !",
        "NOT_VERIFIED_RIDER_DESC": "Ce passager n'a pas encore été vérifié.",
        "RESET_DRIVER_STATS": "Réinitialiser statistique conducteur",
        "RESET_DRIVER_STATS_CONFIRMATION": "Souhaitez-vous réinitialiser les statistiques du conducteur?",
        "RESET_DRIVER_STATS_SENDING": "Envoi d'action pour réinitialiser les statistiques du conducteur",
        "RESET_DRIVER_STATS_SUCCESSFUL": "Statistique réinitialiser avec succès",
        "RESET_DRIVER_STATS_TITLE": "Réinitialiser statistiques",

        //Helpers
        "never": "Jamais",
        "DEVELOPERS": "Développeurs",
        "STANDARD_CAR": "Voiture Standard",
        "LUXURY_CAR": "Voiture de Luxe",
        "BICYCLE": "Vélo",
        "VAN": "Fourgonette",
        "Moto": "Moto",
        "AMBULANCE": "Ambulance",
        "VEHICLE_RIDE_SERVICES_TITLE": "Service pour véhicule",
        "VEHICLE_RIDE_SERVICES_SUBTITLE": "Sélectionnez tous les services de transport que vous voulez autoriser pour chaque véhicule",
        "RIDE_SERVICES_VISIBILITY_TITLE": "Visibilité des services de transport",
        "RIDE_SERVICES_VISIBILITY_SUBTITLE": "Sélectionnez l'endroit où chaque service doit être accessible",
        "MIA_EXTERNAL": "Eva Business (Mia)",
        "ANA_EXTERNAL": "Eva Application",
        "COMMUNITY_SETTINGS" : "Paramètres de la communauté",
        "PROMO_TITLE": "Promotions",
        "PROMO_SUBTITLE": "Sélectionner toutes les options que vous voulez activer",
        "AUTOMATIC_SMS": "Envoi automatique de SMS lors de la création d'une promotion",
        "TIP_AMOUNT": "Pourboire",
        
        "NOTE": "Note",
        "TOTAL_ORDER_AMOUNT": "Montant total de la commande",

        "ERROR_EDIT_SCHEDULED_RIDE": "Entrées non valides. Veuillez vérifier tous les champs et réessayer.",
        "PRICE": "Prix",
        "INVALID_INPUT": "Entrée invalide.",
        "CUSTOMER_EMAIL": "Courriel",
        "CUSTOMER_NOTE": "Note",
        "ASSIGNED_TO": "Assigné à",
        "MANUAL_DISPATCH_RESET_CONFIRM": "Voulez-vous retirer le conducteur assigné pour ce trajet ?",
        "MANUAL_DISPATCH_RESET_OK": "Suppression du conducteur désigné.",
        "RESET_MANUAL_DISPATCH": "Désassignez",
        "FRENCH": "Français",
        "ENGLISH": "Anglais",
        "REDISPATCH_ACTIVE_RIDE_ALERT_TITLE": "Ré-expédition de la course",
        "REDISPATCH_ACTIVE_RIDE_ALERT_BODY": "Cette opération annulera la course en cours pour le conducteur et l'enverra à quelqu'un d'autre.",
        "DISPATCH_TO_ANOTHER_DRIVER": "Envoyer à un autre conducteur",
        "TECHNOLOGY_FEE": "Frais de technologie:",
        "DRIVER_CASHOUT_TOKEN_CONFIRM": "Êtes-vous sûr de vouloir encaisser les jetons de ce conducteur ?",
        "DRIVER_ACTION_CASHOUT": "Encaisser jetons Eva",
        "RESET": "Réinitialiser",
        "MERGE": "Regrouper",
        "NO_ROUTES_IN_SYSTEM": "Aucune route dans le système.",
        "NO_ORDERS_IN_SYSTEM": "Aucune commande dans le système.",
        "INVALID_ROUTE_IDS": "Les ID de route donnés ne sont pas valides",
        "FULLY_BOOKED": "Cette route est complète.",
        "INCOMPATIBLE_SERVICE": "Impossible de regrouper les courses de covoiturage.",
        "INCOMPATIBLE_TIME": "Les courses sont programmés à des heures incompatibles.",
        "NO_AUTH": "Le client n'autorise pas les regroupements.",
        "ROUTE_ALREADY_STARTED": "Impossible de regrouper les courses qui ont déjà commencé.",
        "TOO_MANY_RIDES": "La limite de courses a été atteinte.",
        "MANUAL_MERGE_RESET_TITLE": "Regrouper des trajets",
        "MANUAL_MERGE_RESET_BODY": "Êtes-vous sûr de vouloir regrouper les courses ? \n\nCette action ne peut pas être annulée !",
        "MANUAL_MERGE_CONFIRMATION": "Les courses ont été regroupés avec succès.",
        "LIVE_ORDERS": "Commandes",
        "LIVE_ROUTES": "Routes",
        "MANUAL_MERGE_LIVE_ROUTES_DESCRIPTION": "Faites glisser les commandes disponibles vers la gauche pour les supprimer de la route.",
        "MANUAL_MERGE_LIVE_ORDERS_DESCRIPTION": "Faites glisser une commande vers la droite pour créer une route ou pour la regrouper avec une route existant.",
        "RIDE_MERGER": "Regroupement de courses",
        "DASHBOARD_RIDE_MERGER_MINI": "RM",
        "Generate_Sequence_Confirmation_Warning": "Vous allez générer la séquence <b>#{{sequence_id}}</b> avec les encaissements suivants :",
        "Updated now": "Mis à jour maintenant",
        "STARTED_DATETIME": "Temps de démarrage",
        "DONE_DATETIME": "Temps de finalisation",
        "NEW_SUBMISSION_ERROR_VALIDATE_STATUS_PROMPT": "Impossible de mettre à jour le statut. Contactez l'équipe de développement si le problème persiste.",
        "Registration_plate": "Plaque d'immatriculation",
        "ANALYTICS_SALES": "Ventes",
        "ANALYTICS_REPORTS": "Rapports",
        "ANALYTICS_EXECUTIVE": "Exécutif",
        "ANALYTICS_REPORT_TYPE": "Groupes de rapport",
        "ANALYTICS_NO_GROUP_SELECTED": "Veuillez sélectionner au moins un groupe",
        "ANALYTICS_OPERATIONS": "Operations",
        "CUSTOMER_FIST_NAME": "Prenom",
        "OWT_RIDER": "En route vers le client",
        "ARCHIVE_SUCCESS": "Archivage réussi de la soumission",
        "ARCHIVE_ERROR": "Il y a eu un problème d'archivage de la soumission.",
        "TRACKING_LINK_MESSAGE_SETTING_TITLE": "Message du lien de suivi",
        "TRACKING_LINK_MESSAGE_SETTING_SUBTITLE": "Il s'agit d'un message personnalisé facultatif à afficher sur tous les liens de suivi dans la communauté.",
        "MESSAGE": "Message",
        "SEVERITY": "Gravité",
        "INFO": "Information",
        "WARN": "Avertissement",
        "DANGER": "Danger",

        "RELEASE_CASH_PAYMENT_ENTRY_CONFIRM": "Confirmer l'argent reçu",
        "RELEASE_CASH_PAYMENT_ENTRY_BODY": "Vous êtes sur le point de déclarer que la commission suivante due en espèces a été reçue : ",
        "RELEASE_CASH_COLLECTION_ENTRY_TITLE": "Déclarer l'argent comme collecté",
        "RELEASE_CASH_PAYMENT_ENTRY_CONFIRM": "Oui, nous avons l'argent",
        "RELEASE_DRIVER_CASH_PAYMENT": "Déclarer les espèces reçues",
        "VIEW_REQUESTS": "Voir les requêtes",
        "PROCESSED_DATE": "Date de traitement",
        "NOT_STARTED": "Pas commencé",
        "ASSIGN_TO": "Attribuer au conducteur",
        "INVALID_DRIVER": "Le conducteur sélectionné n'existe pas.",
        "DISPATCHER_SETTING_TITLE": "Paramètres du répartiteur",
        "DISPATCHER_SETTING_SUBTITLE": "Modifiez le nombre de commandes par cycle de répartition, et le rayon de répartition des différents services.",
        "RADIUSES": "Rayons",
        "GENERAL": "Général",
        "CASH_PAYMENT_SETTING_TITLE": "Paiements en espèces",
        "CASH_PAYMENT_SETTING_SUBTITLE": "Décidez si les paiements en espèces sont autorisés dans la communauté.",
        "ALLOW_CASH_PAYMENT": "Autoriser les paiements en espèces",
        "ERROR_FETCHING_SERVICES": "Il y a eu une erreur lors du chargement des données",
        "DEFAULT_PRICING": "Prix par défaut",

        //Rates mapping
        "minute_rate": "Tarif par minute",
        "km_rate": "Tarif par kilomètre",
        "base_charge": "Frais de base",
        "supplement_comm_charge": "Frais supplémentaires de franchise",
        "supplement_comm_charge_desc": "Description des frais supplémentaires de franchise",
        "supplement_world_charge": "Frais supplémentaires globales",
        "supplement_world_charge_desc": "Description des frais supplémentaires globales",
        "min_charge": "Frais minimaux",
        "cancel_fee": "Frais d'annulation",
        "SQDC_REPORT_TITLE": "SQDC",
        "SQDC_REPORT_SUBTITLE_1": "Cette semaine",
        "SQDC_REPORT_SUBTITLE_2": "Toutes les heures",
        "SQDC_REPORT_SUBTITLE_3": "Tendance des commandes en retard",
        "SQDC_REPORT_SUBTITLE_4": "Tendance du statut",
        "SQDC_REPORT_CHART_TITLE_1": "Statut",
        "SQDC_REPORT_CHART_TITLE_2": "Commandes en retard",
        "SQDC_REPORT_CHART_TITLE_3": "Répartition des trajets",
        "SQDC_REPORT_CHART_TITLE_4": "Retours",
        "SQDC_LEGEND_SUCCESS": "Succès",
        "SQDC_LEGEND_CANCELED": "Annulé",
        "SQDC_LEGEND_RETURN": "Retour",
        "SQDC_LEGEND_ON_TIME": "À l'heure",
        "SQDC_LEGEND_LATE_1": "En retard (0-15 min)",
        "SQDC_LEGEND_LATE_2": "En retard (15-30 min)",
        "SQDC_LEGEND_LATE_3": "En retard (30-45 min)",
        "SQDC_LEGEND_LATE_4": "En retard (45+ min)",
        "SQDC_LEGEND_BREAKDOWN_1": "Minutes pour trouver un chauffeur",
        "SQDC_LEGEND_BREAKDOWN_2": "Minutes pour se rendre au point de ramassage",
        "SQDC_LEGEND_BREAKDOWN_3": "Minutes d'attente au point de ramassage",
        "SQDC_LEGEND_BREAKDOWN_4": "Minutes pour conduire à destination",
        "SQDC_LEGEND_RETURN_1": "Preuve d'adresse",
        "SQDC_LEGEND_RETURN_2": "Preuve du client"
    },
},
es: {
  translation: {
        // Business schedule
        "SUNDAY": "Domingo",
        "MONDAY": "Lunes",
        "TUESDAY": "Martes",
        "WEDNESDAY": "Miércoles",
        "THURSDAY": "Jueves",
        "FRIDAY": "Viernes",
        "SATURDAY": "Sábado",
        "CLOSED": "Cerrado",
        "OPEN_24_HOUR": "Abierto 24 horas",
        "MODIFY": "Modificar",
        "SCHEDULE_EDITOR": "Editor de horarios",
        "SAVE_CHANGES": "Guardar los cambios",
        "WORKING_HOURS": "Horario de trabajo (CLT)",

        // Restaurant
        "RESTAURANT_MENU": "Restaurantes",
        "RESTAURANT_NEW": "Añadir",
        "RESTAURANT_LIST": "Lista",
        "RESTAURANT_NAME": "Nombre",
        "RESTAURANT_DESCRIPTION": "Descripción",
        "ENTER_RESTAURANT_DESCRIPTION": "Introducir la descripción del restaurante",
        "RESTAURANT_LINK": "Enlace web",
        "RESTAURANT_NAME": "Nombre del restaurante",
        "ADDRESS": "Dirección",
        "INVALID_ADDRESS": "Email inválido",
        "ENTER_RESTAURANT_NAME": "Introducir el nombre del restaurante",
        "TRANSACTIONAL_LINK": "Enlace transaccional",
        "ENTER_TRANSACTIONAL_LINK": "Introducir el enlace transaccional",
        "RESTAURANT_NEW_SUBTITLE": "Nuevo restaurante",
        "RESTAURANT_EDIT_SUBTITLE": "Editar restaurante",
        "CHANGE_RESTAURANT_IMAGE": "Cambiar la imagen del restaurante",
        "RESTAURANT_IMAGE": "Imagen del restaurante",
        "ENTER_RESTAURANT_ADDRESS": "Introducir la dirección del restaurante",
        "RESTAURANT_CATEGORIES": "Tipos de comida",
        "ADD_NEW_FOOD_TYPE": "Añadir un nuevo tipo de comida: ",
        "ENTER_FOOD_TYPES": "Introducir los tipos de comida del restaurante",
        "SENDING_DATA": "Envío de datos",
        "ERROR_OCCURED": "Se ha producido un ERROR : {{0}}",
        "UPDATED": "actualizado",
        "CREATED": "creado",
        "SUCCESS_MESSAGE": "El restaurante ha sido {{0}} con éxito.",
        "ENTER_ADDRESS": "Introducir una dirección",
        "PERMANENT_RESTAURANT_DELETION": "Este restaurante será eliminado permanentemente",
        "YES_DELETE": "Sí, bórralo.",
        "DELETED": "borrado",


        //Business
        "BUSINESS_MENU": "Empresas",
        "BUSINESS_NEW": "Añadir",
        "BUSINESS_NEW_SUBTITLE": "Nueva empresa",
        "BUSINESS_EDIT_SUBTITLE": "Editar empresa",
        "COMPANY_NAME": "Nombre de la empresa",
        "COMPANY_ADDRESS": "Dirección de la empresa",
        "COMPANY_APARTMENT_NUMBER": "Número de apartamento de la empresa",
        "COMPANY_PHONE": "Teléfono de la empresa",
        "REGISTERED_ON": "Fecha de inscripción",
        "BUSINESS_LIST": "Lista",
        "CONTACT_FIRST_NAME": "Nombre de contacto",
        "CONTACT_LAST_NAME": "Apellido de contacto",
        "CONTACT_PHONE": "Teléfono de contacto",
        "CONTACT_EMAIL": "Email de contacto",
        "BILLING_EMAIL": "Email de facturas (separar con comas si es múltiple)",
        "CONTACT_NAME": "Nombre de contacto",
        "PREFERRED_LANGUAGE": "Idioma preferido",
        "ADD_QUOTE_STRATEGY_TITLE": "Cotización dinámica",
        "EDIT_QUOTE_STRATEGY_TITLE": "Editar cotización dinámica",
        "QUOTE_TYPE": "Tipo de cotización",
        "TOTAL_REDUCTION": "Reducción del precio",
        "FIXED_PRICE": "Precio fijo",
        "AMOUNT_CENT": "Cantidad en CLP",
        "SAVE": "Guardar",
        "DELETE_QUOTE_STRATEGY": "Borrar cotización dinámica",
        "CUSTOM_PRICING": "Editar cotización dinámica por:",
        "USING_NORMAL_PRICING": "En la actualidad, la empresa utiliza los precios normales para este tipo de servicio.",
        "COMMUNITY_RATE_X100": "Tarifa (%) para la franquicia",
        "GLOBAL_RATE_X100": "Tarifa (%) para Eva Global",
        "MINUTE_RATE_CENT": "Tarifa por minuto (CLP)",
        "KM_RATE_CENT": "Tarifa por kilómetro (CLP)",
        "MIN_CHARGE": "Tarifa mínima (CLP)",
        "CANCEL_FEE_CENT": "Tarifa de anulación (CLP)",
        "BASE_CHARGE": "Tarifa de base (CLP)",
        "SUPPLEMENT_COMM_CHARGE": "Tarifa adicional para la franquicia (CLP)",
        "SUPPLEMENT_COMM_CHARGE_DESCRIPTION": "Descripción tarifa adicional para la franquicia",
        "SUPPLEMENT_WORLD_CHARGE": "Tarifa adicional para Eva Global",
        "SUPPLEMENT_WORLD_CHARGE_DESCRIPTION": "Descripción tarifa adicional para Eva Global",
        "RIDER_ACCOUNT":"Cuenta del pasajero",
        "SEND_SMS_TRACKING":"Enviar enlace de seguimiento por SMS",
        "SEND_EMAIL_TRACKING": "Enviar enlace de seguimiento por Email",
        "PARENT_RIDER_ACCOUNT":"Cuenta de la empresa matriz",
        "PARENT_CORPORATE_DONT_EXIST":"La cuenta de la empresa matriz no existe",
        //LoginPage
        "LOGIN_EMAIL_LABEL": "Email",
        "Password": "Contraseña",
        "LOGIN_BUTTON_LABEL": "Iniciar sesión",
        "LOGIN_ERROR": "Error al iniciar sesión",

        //Footer
        "Home": "Inicio",
        
        //SideBar
        "Dashboard": "Panel de control",
        "Analytics": "Analítica",
        "Analytics Reports": "Informes",
        "Analytics Management": "Gestión",
        "Rides": "Viajes",
        "Drivers": "Conductores",
        "Payments": "Pagos",
        "Active rides": "En directo",
        "Pending rides": "Solicitud de viajes actuales",    
        "Pending payments": "Pagos pendientes",
        "Driver Cancellation": "Cancelación del conductor",
        "Rider Cancellation": "Cancelación del pasajero",
        "Historical payments": "Historial de pagos",
        "Invoices": "Facturas",
        "Config": "Configuración",
        "Community config": "Información financiera",
        "DRIVERS_MENU_COMMUNICATION": "Comunicación",

        //ViewTitles
        "Active Rides in": "Viajes activos en ",
        "Pending Rides Requests in": "Solicitudes de viajes pendientes en ",  
        "Drivers in": "Conductores en ",
        

        //ActiveRidesList
        "route ID": "ID de ruta",
        "Rider ACCOUNT": "Cuenta del pasajero",
        "status": "Estado",
        "Driver account": "Cuenta del conductor",
        "Driver full Name": "Nombre completo del conductor",
        "Created date": "Fecha de creación",
        "Modified date": "Fecha modificada",
        "is_driver_onsite": "¿En el sitio?",
        "DRIVER_ONSITE_ARRIVED": "En la recogida",
        "DRIVER_NOT_ONSITE": "Aún no ha llegado",
        "estimated_pickup_time": "Tiempo estimado de recogida",
        "elapsed_pickup_time": "Tiempo real de recogida",
        "STARTED":"¿Comenzó?",
        "DRIVER_FOUND": "Conductor",
        "FLAG_STARTED":"Comenzó",
        "FLAG_NOT_STARTED":"No iniciado",
        "MERGED_EST_MIN": "Tiempo estimado (minutos)",
        "MERGED_EST_DIST_KM": "Distancia estimada (km)",
        "NUMBER_OF_ORDERS":"Número de pedidos",

        //RequestedRides
        "~Minutes": "~Minutos",
        "~KM": "~KM",
        "Amount": "Precio",
        "actions": "Acciones",
        "action": "Acción",

        //Buttons
        "Default": "Por defecto",
        "Tweet": "Tweet",
        "Share": "Compartir",
        "Share on Google+": "Compartir en Google+",
        "Connect with Linkedin": "Conectar con LinkedIn",
        "Pint it": "Anexar",
        "View on Youtube": "Ver en Youtube",
        "Repost": "Volver a publicar",
        "Connect with Github": "Conectar con Github",
        "Follow us": "Síganos",
        "Find us on Dribbble": "Encuéntranos en Dribbble",
        "View on StumbleUpon": "Ver en StumbleUpon",

        //historicalRides
        "Filter on": "Filtrar en",
        "Download CSV": "Descargar tabla en CSV",
        "Date Range": "Rango de fechas",
        "End date": "Fecha de finalización",
        "End Time": "Tiempo de finalización",
        "rideID": "ID de viaje",
        "Driver Name": "Nombre del conductor",
        "Driver profit": "Conductor",
        "Community profit": "Comunidad",
        "Global profit": "Global",
        "Ride cost": "Coste del viaje",
        "Commity Sup Fee": "Tasa de apoyo a la comunidad",
        "Global Sup Fee": "Tasa de apoyo global",
        "Total Cost": "Coste total",
        "TPS": "Tasa TPS",
        "TVQ": "Tasa TVQ",
        "Total Taxes": "Impuestos totales",
        "Total with Taxes": "Total con impuestos",
        "Credit Card Fee": "Tasa por tarjeta de crédito",
        "Total with Credit": "Total con crédito",
        "Tip Amount": "Monto de la propina",
        "Tip Fee": "Tasa de propina",
        "RIDER_NAME": "Nombre del pasajero",
        "RIDER_CONNECTION_NUMBER": "Número de acceso a la aplicación",

        //RideOpportunitiesTable
        "RIDE_OPPORTUNITIES": "Oportunidades",
        "SUCCESSFUL": "Con éxito",
        "FAILED": "Fallido",

        // DriverCanceledRides
        "Number of cancelled ride by driver in": "Número de viajes cancelados por el conductor en ",
        "Number of cancellation": "Número de cancelación",
        "Number of completed rides": "Número de viajes completados",
        "Score": "Puntuación",

        //ExtendedTables
        "View Profile": "Ver perfil",
        "Edit Profile": "Editor perfil",
        "Remove": "Eliminar",
        "View Post": "Ver el post",
        "Edit Post": "Editar el post",
        "Remove Post": "Eliminar el post",
        "Name": "Nombre",
        "Job Position": "Puesto de trabajo",
        "Salary": "Salario",

        //ReactTables
        "Position": "Posición",
        "Office": "Oficina",
        "Age": "Edad",

        //DriverInfoForm
        "Community (disabled)": "Comunidad (discapacitada)",
        "Phone Number": "Número de teléfono",
        "Eos Account": "Cuenta EOS",
        "Email address": "Dirección de correo electrónico",
        "First Name": "Nombre",
        "Last Name": "Apellido",
        "tps": "TPS",
        "tvq": "TVQ",
        "Google Form":"Campo de Google Sheet",
        "Vehicle model": "Modelo de vehículo",
        "Vehicle year": "Año del vehículo",
        "Vehicle color": "Color del vehículo",
                        ////Color
                        "Silver": "Plata",
                        "White": "Blanco",
                        "Blue": "Azul",
                        "Gray": "Gris",
                        "Red": "Rojo", 
                        "Green": "Verde",
                        "Brown": "Marrón",
                        "Yellow": "Amarillo",
                        "Orange": "Naranja",
                        "Pink": "Rosa",
                        "Purple": "Morado",
                        "Dark Blue": "Azul oscuro",
                        "Dark Gray": "Gris oscuro",
                        "Dark Red": "Rojo oscuro",
                        "Dark Green": "Verde oscuro",
                        "Dark Brown": "Marrón oscuro",
                        "Bright Red": "Rojo claro",
                        "Bright Blue": "Azul claro",
                        "Bright Green": "Verde claro",
                        "Bright Gray": "Gris claro",
                        "Bright Brown": "Marrón claro",
       "Vehicle mileage": "Kilometraje del vehículo",
       "Allowed multi ride": "Permiso para varios viajes",
       "Allowed Cash Payment": "Permisos de pago en efectivo",
       "Vehicle identification number": "Número de identificación del vehículo",
       "Vehicle registration certificate file number": "Número de registro del permiso de circulación del vehículo",
       "VEHICLES": "Vehículos",
       "VEHICLE_TYPE": "Tipo de vehículo",
       "VEHICLE": "Vehículo",
       "Registration plate number": "Número de matrícula",
       "NEW_VEHICLE": "Añadir otro vehículo",
       "Driver licence number": "Número de permiso de conducir",
       "Financial institute name": "Nombre del instituto financiero",
       "Financial institute number": "Número del instituto financiero",
       "Transit number": "Número de tránsito",
       "Account number": "Número de cuenta",
       "Go Back": "Volver",


       //DriverHistoricalCashout
       "Community": "Comunidad",
        "Payment Date": "Fecha de pago",
       "EVA Tokens": "Tokens Eva",
       "cad": "CAD",
       "From": "Desde ",
       "To": " a ",

       //PendingCashout
       "Full Name": "Nombre completo",
       "Requested Date": "Fecha solicitada",
       "All rides": "Todos los viajes",
       "Completed rides": "Viajes realizados",
       "Canceled Rides": "Viajes cancelados",
       "EOS Account": "Cuenta EOS",
       "Institution": "Institución",
       "Transit": "Número de tránsito",
        "Select All": "Seleccionar todo", 
        "Issue All Selected": "Emitir todos los seleccionados", 
        "Requested Time": "Tiempo solicitado",

        "Sequence ID": "ID de secuencia",
        "Force_Sequence": "Forzar la secuencia:",
        "select": "Seleccionar",
        "Error_Force_Sequence": "El número de secuencia debe estar entre 0 y 999.",
        "Download": "Descargar",
        "Error_More_Than_One_Payment": "Por favor, seleccionar al menos un pago.",
        "Error_No_Sequence_Payment": "Uno o más pagos no tienen un número de secuencia. Por favor, generar uno antes de continuar.",
        "Error_Different_Sequence_Ids": "Algunos de los pagos seleccionados tienen números de secuencia conflictivos. Por favor, seleccionar sólo los pagos con un mismo número de secuencia.",
        "Issuing_Payments_Confirmation": "Emisión de la confirmación de pagos",
        "Issuing_Payments_Confirmation_Yes": "Sí, emitir los pagos!",
        "Issuing_Payments_Confirmation_Warning": "Emitirá pagos para los siguientes cobros:",
        "Generate_Deposit_Confirmation": "Confirmar la generación del depósito",
        "Generate_Deposit_Confirmation_Yes": "Generar archivo de depósito",
        "Generate_Deposit_Confirmation_Warning": "Generar un archivo de depósito para la secuencia <b>#{{sequence_number}}</b> con los siguientes cobros?",
        "Generate_Deposit_Error": "Algo ha ido mal al generar el archivo de depósito.",
        "Generate_Deposit_Success": "Archivo generado con éxito.",
        "Generate_Deposit": "Generar archivo de depósito",
        "Payments_Have_Sequence_Warning": "Algunos de los pagos seleccionados ya tienen un número de secuencia. ¿Aún desea continuar?",
        "Generate_Sequence": "Generar secuencia",
        "Generate_Sequence_Confirmation_Warning": "Se generará la secuencia <b>#{{sequence_id}}</b> con los siguientes cobros:",
        "Generate_Sequence_Success": "Se ha generado con éxito una secuencia.",
        "Generate_Sequence_Error": "Se ha producido un error al generar una secuencia.",
        "Payment_Request_Success": "La solicitud de pago ha sido procesada y validada con éxito.",
        "Payment_Confirmation": "Emitir una confirmación de pago",
        "Payment_Confirmation_Yes": "Sí, confirmar el pago",
        "Payment_Confirmation_Warning": "Está a punto de emitir el pago de <b>{{amount}}</b> solicitado por el siguiente conductor: <b>{{driver}}</b>. ¿Desea proceder?",

        //Invoices
        "Invoice Number": "Número de factura",
        "Paid": "Pagada",
        "Payment Type": "Forma de pago",
        "Mark paid": "Marcar pagado",
        "Mark unpaid": "Marcar no pagado",
        "Total": "Total",
        "Files": "Archivos",
        "From_invoice": "Inicio del período",
        "To_invoice": "Fin del período",
        "CREDIT_CARD": "Tarjeta de crédito",
        "MANUAL": "Manual",
        "PAID_DATETIME": "Fecha de pago",

        //DriverList
        "Add driver": "Añadir conductor",
        "Edit driver": "Editar conductor",
        "ACTIONS_DRIVER": "Acciones",
        "DEACTIVATE_TITLE": "Desactivar",
        "DEACTIVATION_CONFIRMATION": "¿Desea desactivar el controlador?",
        "DEACTIVATION_SUCCESSFUL": "ha sido desactivado con éxito de la comunidad",
        "Add new driver": "Añadir nuevo conductor",
        "phone": "Teléfono",
        "DRIVERS_MENU_ACTIVE" :"Activo",
        "DRIVERS_MENU_INACTIVE" :"Inactivo",
        "Updated now" : "Actualizado ahora",
        "Online": "En línea",
        "Activate driver": "Activar conductor",
        "Allowed":"Permitido",
        "Denied":"Denegado",
        "City": "Ciudad",

        //RideSubTable
        "STARTED_DATETIME":"Hora de inicio",
        "DONE_DATETIME": "Hora de finalización",

        // New Submission (add driver)
        "DRIVERS_MENU_NEW_SUBMISSION": "Nueva Presentación",
        "NEW_SUBMISSION_PHONE_LABEL_TEXT": "Por favor, introduzca el número de teléfono del pasajero que desea convertirse en conductor.",
        "NEW_SUBMISSION_PHONE_FORMAT_ERROR": "El número introducido no es válido.",
        "NEW_SUBMISSION_CREATE_BUTTON": "Abrir",
        "NEW_SUBMISSION_CREATE_NEW_PROMPT": "Está a punto de abrir una solicitud para {{fullName}}. ¿Desea continuar?",
        "NEW_SUBMISSION_NO_RIDER_FOUND_PROMPT": "No hay ninguna cuenta de pasajero asociada a este número de teléfono. La cuenta puede estar sincronizándose o puede ser ya un conductor.",
        "NEW_SUBMISSION_PHONE_REJECTED_BY_SERVER_PROMPT": "Número de teléfono rechazado por el servidor.",
        "NEW_SUBMISSION_HEADER_TITLE": "Nuevo solicitante",
        "NEW_SUBMISSION_DRIVER_TAB": "Conductor",
        "NEW_SUBMISSION_VEHICLE_TAB": "Vehículo",
        "NEW_SUBMISSION_SEND_REMINDER_BUTTON": "Enviar recordatorio",
        "NEW_SUBMISSION_SEND_REMINDER_CONFIRMATION": "Está a punto de enviar un recordatorio por correo electrónico. ¿Desea continuar?",
        "NEW_SUBMISSION_ARCHIVE_BUTTON": "Archivar",
        "NEW_SUBMISSION_OPEN_BUTTON": "Abrir",
        "NEW_SUBMISSION_TITLE_BASIC_INFO": "Información básica",
        "NEW_SUBMISSION_TITLE_CUSTOM_INFO": "Información adicional",
        "NEW_SUBMISSION_SELECT_OPTION": "Seleccionar una opción",
        "NEW_SUBMISSION_REMOVE_VEHICLE": "Eliminar",
        "NEW_SUBMISSION_CHOOSE_FILE": "No se ha seleccionado ningún archivo",
        "NEW_SUBMISSION_UPLOADED_FILE_ERROR": "❌ Error al cargar. Por favor, inténtar de nuevo.",
        "NEW_SUBMISSION_CANNOT_OPEN_IMAGE": "No se puede abrir el archivo solicitado en este momento. Contacte con el equipo técnico si el problema persiste.",
        "NEW_SUBMISSION_MAX_FILE_SIZE": "El tamaño del archivo no puede superar los 25 MB.",
        "NEW_SUBMISSION_ACCEPTED_FILE_FORMAT": "Los tipos de archivo aceptados son jpeg, gif, png y pdf.",
        "NEW_SUBMISSION_VALIDATION_ERROR": "Error de validación",
        "NEW_SUBMISSION_SAVE_BUTTON": "Guardar la solicitud",
        "NEW_SUBMISSION_VALIDATE_ALL_BUTTON": "Validar todo",
        "NEW_SUBMISSION_EDIT_BUTTON": "Editar la solicitud",
        "NEW_SUBMISSION_COMPLETE_BUTTON": "Completar la solicitud del conductor",
        "NEW_SUBMISSION_CONFIRMATION_ALERT_TEXT": "La solicitud será enviada al servidor y ya no será posible editar este envío.",
        "NEW_SUBMISSION_ERROR_VERIFY_FIELDS": "Verificar los siguientes campos: ",
        "NEW_SUBMISSION_ERROR_CONTACT_DEV_TEAM": "Contacte con el equipo técnico si el problema persiste.",
        "NEW_SUBMISSION_ERROR_UPDATE_PROMPT": "No se puede actualizar la solicitud.",
        "NEW_SUBMISSION_ERROR_MISSING_FIELDS_PROMPT": "No se puede pasar a la validación porque algunos campos no son válidos.",
        "NEW_SUBMISSION_ERROR_VALIDATE_ALL_PROMPT": "No se ha podido validar la solicitud.",
        "NEW_SUBMISSION_ERROR_STATUS_PROMPT": "No se puede actualizar el estado de la solicitud. Contacte con el equipo técnico si el problema persiste.",
        "NEW_SUBMISSION_ERROR_VALIDATE_STATUS_PROMPT": "No se puede actualizar el estado. Contacte con el equipo técnico si el problema persiste.",
        "NEW_SUBMISSION_SUCCESS_ALERT_TEXT": "La solicitud ha sido procesada con éxito. ¡Un bienvenido a nuestro miembro conductor!",
        "NEW_SUBMISSION_ERROR_ALERT_TEXT": "No se puede crear una cuenta de conductor en este momento. Por favor, inténtelo más tarde o póngase en contacto con el equipo técnico.",
        // Driver Fields
        "NEW_SUBMISSION_FIRST_NAME": "Nombre",
        "NEW_SUBMISSION_LAST_NAME": "Apellido",
        "NEW_SUBMISSION_CHAIN_ACCOUNT_NUMBER": "Número de la cadena de bloques",
        "NEW_SUBMISSION_PREFERRED_LANGUAGE": "Idioma preferido",
        "NEW_SUBMISSION_PREFERRED_LANGUAGE_OPTIONS": {
                "FR": "Francés",
                "EN": "Inglés",
                "ES": "Español"
        },
        "NEW_SUBMISSION_PHONE": "Número de teléfono",
        "NEW_SUBMISSION_EMAIL": "Dirección de correo electrónico",
        "NEW_SUBMISSION_CITY": "Ciudad",
        "NEW_SUBMISSION_DATE_OF_BIRTH": "Fecha de nacimiento",
        "NEW_SUBMISSION_FINANCIAL_INSTITUTION_NUMBER": "Número de la institución financiera",
        "NEW_SUBMISSION_FINANCIAL_INSTITUTION_ACCOUNT_NUMBER": "Número de cuenta bancaria",
        "NEW_SUBMISSION_FINANCIAL_INSTITUTION_TRANSIT_NUMBER": "Número de tránsito de la cuenta bancaria",
        "NEW_SUBMISSION_CRIMINAL_BACKGROUND_CHECK": "Comprobación de antecedentes penales",
        "NEW_SUBMISSION_CRIMINAL_BACKGROUND_CHECK_DATE": "Fecha de emisión de la verificación de antecedentes penales",
        "NEW_SUBMISSION_DRIVER_TRAINING_PHOTO": "Certificado de formación de conductores",
        "NEW_SUBMISSION_DRIVER_TRAINING_DATE": "Fecha de finalización de la formación del conductor",
        "NEW_SUBMISSION_DRIVER_LICENSE_NUMBER": "Número de permiso de conducir",
        "NEW_SUBMISSION_DRIVER_LICENSE_EXPIRATION_DATE": "Fecha de caducidad del permiso de conducir",
        "NEW_SUBMISSION_DRIVER_LICENSE_PHOTO": "Foto del permiso de conducir",
        "NEW_SUBMISSION_TPS_NUMBER": "Número TPS", 
        "NEW_SUBMISSION_TVQ_NUMBER": "Número TVQ",
        "NEW_SUBMISSION_REVENU_QUEBEC_AUTHENTIFICATION_NUMBER": "Código de autorización de Revenu Québec",
        "NEW_SUBMISSION_REVENU_QUEBEC_IDENTIFICATION_NUMBER": "Número de identificación de Revenu Québec",
        "NEW_SUBMISSION_REVENU_QUEBEC_BILLING_NUMBER": "Número de facturación de Revenu Québec",
        
  // Vehicle Fields
        "NEW_SUBMISSION_VEHICLE_TYPE_ID": "Categoría",
        "NEW_SUBMISSION_ALLOWED_RIDE_SERVICES": "Tipos de servicio autorizados",
        "NEW_SUBMISSION_COLOR": "Color",
        "NEW_SUBMISSION_COLOR_OPTIONS": {
                "GREEN" : "Verde",
                "RED" : "Rojo",
                "BLUE" : "Azul",
                "GREY" : "Gris",
                "BLACK" : "Negro",
                "INDIGO" : "Indigo",
                "ORANGE" : "Naranja",
                "YELLOW" : "Amarillo",
                "PURPLE" : "Violeta",
                "WHITE" : "Blanco", 
                "BROWN" : "Marrón",
                "PINK" : "Rosa",
        },
        "NEW_SUBMISSION_BRAND": "Marca",
        "NEW_SUBMISSION_MODEL": "Modelo",
        "NEW_SUBMISSION_YEAR": "Año",
        "NEW_SUBMISSION_MILEAGE": "Kilometraje",
        "NEW_SUBMISSION_VEHICLE_IDENTIFICATION_NUMBER": "Número de identificación del vehículo (VIN)",
        "NEW_SUBMISSION_VEHICLE_REGISTRATION_CERTIFICATE_FILE_NUMBER": "Número de expediente emitido por la SAAQ",
        "NEW_SUBMISSION_VEHICLE_REGISTRATION_PLATE_NUMBER": "Número de matrícula",
        "NEW_SUBMISSION_VEHICLE_MECHANICAL_INSPECTION": "Inspección mecánica",
        "NEW_SUBMISSION_VEHICLE_MECHANICAL_INSPECTION_DATE": "Fecha de la inspección mecánica",
        "NEW_SUBMISSION_INSURANCE_INSTITUTION_NAME": "Nombre del asegurador",
        "NEW_SUBMISSION_INSURANCE_INSTITUTION_NAME_OPTIONS": {
                "ALLSTATE_ASSURANCE": "Allstate Insurance",
                "AVIVA_CANADA": "Aviva Canada",
                "BANQUE_NATIONALE_ASSURANCES": "National Bank Insurance",
                "BC_ASSUR": "BC Assur",
                "BELAIRDIRECT": "belairdirect",
                "CO_OPERATORS": "Co-operators",
                "COSECO_COMPAGNIE_DASSURANCE": "COSECO Insurance Company",
                "DESJARDINS_ASSURANCES": "Desjardins Insurance",
                "ECONOMICAL_ASSURANCE": "Economical Insurance",
                "INDUSTRIELLE_ALLIANCE": "Industrial Alliance",
                "INTACT_ASSURANCES": "Intact Insurance",
                "LA_CAPITALE_ASSURANCE": "La Capitale Insurance / Beneva",
                "ROYAL_SUN_ALLIANCE_DU_CANADA": "RSA Assurance",
                "SSQ_ASSURANCE": "SSQ Insurance",
                "WAWANESA_ASSURANCE": "Wawanesa Insurance",
                "AUTRE": "Otros"
        },
        "NEW_SUBMISSION_INSURANCE_POLICY_NUMBER": "Número de póliza de seguro",
        "NEW_SUBMISSION_INSURANCE_POLICY_PHOTO": "Foto de la póliza de seguro",

        "NEW_SUBMISSION_VEHICLE_GRAY_CARD_NUMBER": "Número de tarjeta gris",
        "NEW_SUBMISSION_VEHICLE_GRAY_CARD_EXPIRY": "Fecha de caducidad de la tarjeta gris",
        "NEW_SUBMISSION_VEHICLE_GRAY_CARD_PHOTO": "Foto de la tarjeta gris",
        "NEW_SUBMISSION_TECHNICAL_VISIT_CERTIFICATE_PHOTO": "Foto del certificado de visita técnica",
        "NEW_SUBMISSION_TECHNICAL_VISIT_CERTIFICATE_EXPIRY": "Fecha de caducidad del certificado de visita técnica",
        "NEW_SUBMISSION_INSURANCE_EXPIRATION_DATE": "Fecha de vencimiento del seguro",
        "NEW_SUBMISSION_FINANCIAL_INSTITUTION_RIB_KEY": "Clave RIB",
        "NEW_SUBMISSION_FINANCIAL_INSTITUTION_NAME": "Nombre del banco",
        "NEW_SUBMISSION_VEHICLE_OWNER": "Propietario del vehículo",
        "NEW_SUBMISSION_VEHICLE_OWNER_RUT": "Número RUT del propietario del vehículo",

        // Driver Submissions List
        "DRIVERS_MENU_SUBMISSIONS_LIST": "Lista de envíos",
        "DRIVERS_MENU_OLD_SUBMISSIONS_LIST": "Lista de envíos",
        "DRIVER_SUBMISSION_DETAIL": "Detalle del envío",
        "DRIVER_FINANCIAL_INFO": "Información financiera",
        "DRIVER_VEHICLE_INFO": "Información del vehículo",
        "DRIVER_LICENSE_INFO": "Información sobre el permiso de conducir",
        "DRIVER_OTHER_INFO": "Otras informaciones",
        "Open File": "Abrir archivo",
        "SEND_REMINDER_ALERT_TEXT": "Está a punto de enviar un recordatorio por correo electrónico a {{name}} ({{email}}). ¿Desea continuar?",
        "SEND_REMINDER_ALERT_SUCCESS_TEXT": "El recordatorio se ha enviado con éxito.",
        "SEND_REMINDER_ALERT_ERROR": "Se ha producido un error al enviar el correo electrónico.",

        // Driver submission field translations
        "License_expiration": "Fecha de caducidad",
        "License_number": "Número de licencia",
        "Registration_plate": "Placa de matrícula",
        "language": "Idioma",
        "insurance_number": "Número de seguro",
        "insurance_institution": "Institución aseguradora",
        "insurance_photo": "Foto del seguro",
        "driver_licence_photo": "Foto del permiso de conducir",
        "criminal_background_check": "Verificación de antecedentes penales",
        "mechanical_inspection": "Inspección mecánica",
        "address": "Dirección",
        "tlc_driver_license_number": "Número de licencia de conducir de TLC",
        "tlc_driver_license_expiration": "Expiración de la licencia de conducir de TLC",
        "fh1_insurance_policy_number": "Número de póliza de seguro FH1",
        "fh1_insurance_policy_effective_date": "Fecha de entrada en vigor de la póliza de seguro FH1",
        "fh1_insurance_policy_expiration_date": "Fecha de vencimiento de la póliza de seguro FH1",
        "vehicle_identification_number": "Número de identificación del vehículo",
        "vehicle_identification_expiration": "Caducidad de la identificación del vehículo",
        "tlc_inspection_number": "Número de inspección de TLC",
        "tlc_inspection_expiration": "Expiración de la inspección de TLC",
        "certificate_of_liability": "Certificado de responsabilidad",
        "vehicle_inspection": "Inspección del vehículo",
        "commercial_insurance": "Seguro comercial",
        "vehicle_registration": "Matriculación del vehículo",
        "class_155_registration": "Registro de la clase 1-55",
        "three_year_driver_abstract": "Resumen de tres años del conductor",
        "elvis_inspection": "Inspección E.L.V.I.S.",
        "tvdl": "Permiso de conducir de la red de transporte",
        "file_upload": "Archivos",
        "creation_date": "Fecha de creación",
        "progress": "Progreso",
        "status": "Estado",
        "REGISTERED": "Inscrit",
        "ACTIVE": "Activo",
        "CANCELLED": "Cancelado",
        "SUSPENDED": "Suspendido",
        "APPLIED": "Aplicado",
        "COMPLETED": "Completado",
        "VALIDATED": "Validado",
        "ALL": "Todo",
        "OPEN": "Abierto",
        "ARCHIVED": "Archivado",
        "reminder": "Recordatorio",
        "send": "Enviar",
        "last_reminder_sent": "Último recordatorio",
        "LAST_ACTIVITY": "Última actividad",

        // Driver submissions category names
        "personal_data": "Datos personales",
        "tlc": "TLC",
        "fh1": "FH1",
        "vehicle": "Vehículo",

        "RIDERS_MENU": "Pasajeros",
        "ACTIVE_RIDERS": "Pasajeros activos",
        "BLACKLISTED_RIDERS": "Pasajeros en la lista negra",
        "RIDER_DETAIL": "Detailles del pasajero",
        "CREATED_AT": "Tiempo de creación",
        "CHAIN_ACCOUNT": "Cuenta Blockchain",
        "HOURLY_RATE": "Salario por hora",
        "RIDER_NUMBER_OF_RIDES": "Número de viajes",
        "RIDER_TRIES_LENGTH": "Número de viajes solicitados",
        "RIDER_SUCCESS_RATE": "Tasa de éxito",
        "RIDERSLIST_TITLE": "Viajes en ",
        "FROM_ADDRESS": "De la dirección",
        "TO_ADDRESS": "A la dirección",
        "LOGS": "Registros",
        "TOKEN_TRANSFER": "Transferencia de Tokens",
        "FROM_ACCOUNT": "De",
        "TO_ACCOUNT": "A",
        "RIDE_REQUEST": "Solicitudes de viaje",
        "AMOUNT": "Cantidad transferida",
        "TIME_SENT": "Tiempo",
        "MEMO": "Memo",
        "ALL_REQUESTS": "Todas las solicitudes",
        "RIDES_COMPLETED": "Facturado",
        "SCHEDULED_RIDES": "Programado",
        "CUSTOMER_ORDER": "Pedidos de clientes",
        "DRIVER_ROUTES":"Rutas de los conductores",
        "ARCHIVED_CUSTOMER_ORDERS":"Pedidos de clientes archivados",
        "SCHEDULED_RIDES_ARCHIVED": "Archivado Programado",
        "RIDES_COMPLETED_TITLE": "Viajes facturados",
        "REQUEST_STATUS": "Estado",
        "SUCCESS": "Éxito",
        "NO_DRIVER": "Sin conductor",
        "CANCELLED_BY_COMMUNITY": "Cancelado por la cooperativa",
        "CANCELLED_BY_RIDER": "Cancelado por el pasajero",
        "CANCELLED_BY_DRIVER": "Cancelado por el conductor",
        "NOT_YET_ACCEPTED": "Buscando conductor",
        "LIVE": "En proceso",
        "MORE_DETAILS": "Detalles",
        "RIDER_BALANCE": "Balanza",
        "RIDER_ACTION_SEND_SHRDISCOUNT": "Enviar un descuento compartido",
        "RIDER_ACTION_SEND_MONEY": "Enviar Eva Tokens", 
        "RIDER_ACTION_CREATE_DEBT": "Retirar Eva Tokens",
        "RIDER_ACTION_BLACKLIST": "Añadir pasajero a la lista negra",
        "RIDER_ACTION_WHITELIST": "Añadir pasajero a la lista blanca",
        "RIDER_ACTION_DELETE": "Borrar pasajero",
        "RIDER_ACTION_SEND_SHRDISCOUNT_BUTTON": "Enviar",
        "RIDE_AMOUNT_LABEL": "Cantidad de Eva Token",
        "RIDE_AMOUNT_FIAT_LABEL": "Cantidad de moneda",
        "CURRENCY_ESTIMATE_DISCLAIMER":"Esta es una estimación y puede variar en función de los tipos de cambio",
        "RIDER_ACTION_TITLE": "Lista de acciones",
        "RIDE_ACTION_TITLE": "Acciones en el viaje",
        "RIDE_ACTION_SEND_INVOICE": "Enviar la factura",
        "RIDE_INVOICE_SEND_TO_EMAIL": "Email",
        "RIDER_EMAIL": "Email",
        "RIDER_LAST_CONNECTION": 'Última conexión',
        "RIDER_NUMBER_CONNECTION": "Conexiones",
        "DEACTIVATE_DRIVER": "Desactivar conductor",
        "SEND_SHARED_DISCOUNT_CONFIRM": "¿Enviar un descuento compartido a este pasajero?",
        "SEND_BLACKLIST_CONFIRM": "Bloquear el pasajero",
        "SEND_WHITELIST_CONFIRM": "Permitir el pasajero",
        "DELETE_RIDER_CONFIRM": "Eliminar la cuenta del pasajero",
        "BLACKLIST_RIDER_SUCCESSFULL": "Pasajero bloqueado con éxito",
        "WHITELIST_RIDER_SUCCESSFULL": "Pasajero desbloqueado con éxito",
        "DELETE_RIDER_SUCCESSFULL": "Pasajero eliminado con éxito",
        "SEND_TOKEN_CONFIRM": "¿Enviar esta cantidad simbólica al usuario?",
        "NEW_DEBT_TOKEN_CONFIRM": "¿Retirar este número de fichas al usuario?",
        "SHARED_DISCOUNT_SUCCESSFULLY_SENT": "Descuento compartido enviado con éxito.",
        "ARE_YOU_SURE": "¿Está seguro?",
        "YES_DO_IT": "Sí, hazlo.",
        "YES_ACTIVATE_DRIVER": "Sí, ¡activa el conductor!",
        "CANCEL": "Cancelar",
        "CANNOT_SEND": "No se pueden enviar Eva Tokens",
        "OK": "Ok",
        "MISSING_INFO_TOKEN": "Le falta información para enviar Eva Tokens",
        "SENDING": "Enviando",
        "SENDING_REQUEST": "Enviando solicitud...",
        "SENDING_TOKEN_USER": "Envío de esta cantidad de tokens al usuario",
        "SENDING_DEACTIVATING_DRIVER": "Envío de la acción de desactivación del conductor",
        "ERROR_ALERT": "Se ha producido un error",
        "ERROR": "Error",
        "DRIVER_DISPATCHED_NUMBER": "Número de envío del conductor",
        "DRIVER_RATING": "Clasificación",
        "DRIVER_SUCCESS_RATIO": "Éxito",
        "DRIVER_ACCEPTANCE_RATIO": "Aceptación",
        "NO_RIDES": "N/A",
        "RIDE_DETAILS": "Detalles del viaje",
        "REQUEST_SUMMARY": "Resumen de la solicitud",
        "TOKENS_SUCCESSFULLY_SENT": "Eva Tokens enviados con éxito.",
        "DRIVER_SUCCESSFULLY_DEACTIVATED": "Conductor desactivado con éxito",
        "DEBT_SUCCESFULLY_SENT": "Eva Tokens retiradas con éxito",
        "DRIVER_NAME": "Nombre del conductor",
        "TRANSACTION_HISTORY": "Historial de transacciones",
        "RIDER_BLACKLISTED": "¡Este pasajero está en la lista negra!!",
        "DELETE_DRIVER_CONFIRMATION": "¿Desea desactivar esta cuenta de conductor?: ",
        "ACTIVATE_DRIVER_CONFIRMATION": "¿Desea activar esta cuenta de conductor?: ",
        "ACTIVATE_DRIVER_SUCCESS": "Esta cuenta de conductor ha sido activada con éxito:",
        "ACTIVATED": "Activado",
        "ACTIVATE_PERMISSIONS_CONFIRM": "Recuerda volver a habilitar los permisos del conductor.",
        "RIDER_NOT_FOUND": "Pasajero no encontrado",
        "RIDER_NOT_FOUND_ERROR_MESSAGE": "El pasajero con el siguiente nombre de cuenta no existe:",
        "WARNING": "Aviso",
        "CONTINUE": "Continuar",

        //DASHBOARD MINIS
        //Rides
        "DASHBOARD_ACTIVE_RIDE_MINI":"ED",
        "DASHBOARD_CUSTOMER_ORDERS_MINI":"PC",
        "DASHBOARD_DRIVER_ROUTES_MINI":"RC",
        "DASHBOARD_BILLED_MINI":"F",
        "DASHBOARD_ARCHIVED_CUSTOMER_ORDERS_MINI":"APC",
        "DASHBOARD_ALL_REQUESTS_MINI":"TS",
        "DASHBOARD_RIDE_OPPORTUNITY_MINI":"OV",
        "DASHBOARD_CANCELLED_MINI":"C",
        //Riders
        "DASHBOARD_ACTIVE_RIDERS_MINI":"PA",
        "DASHBOARD_BLACKLISTED_RIDERS_MINI":"PLN",
        //DRIVERS
        "DASHBOARD_ACTIVE_DRIVER_MINI":"CA",
        "DASHBOARD_INACTIVE_DRIVER_MINI":"CI",
        "DASHBOARD_JTF_DRIVER_MINI":"JTF",
        "DASHBOARD_ONLINE_DRIVER_MAP_MINI":"CEL",
        "DASHBOARD_NEW_DRIVER_SUBMISSIONS_MINI":"NSC",
        "DASHBOARD_DRIVER_SUBMISSIONS_LIST_MINI":"LSC",
        "DASHBOARD_DRIVER_COMMUNICATION_MINI":"COM",
        //BUSINESS
        "DASHBOARD_BUSINESS_LIST_MINI":"L",
        "DASHBOARD_NEW_BUSINESS_MINI":"N",
        //RESTAURANTS
        "DASHBOARD_RESTAURANTS_LIST_MINI":"L",
        "DASHBOARD_NEW_RESTAURANTS_MINI":"N",
        //PAYMENTS
        "DASHBOARD_PENDING_PAYMENTS_MINI":"PP",
        "DASHBOARD_PAYMENT_HISTORY_MINI":"HP",
        "DASHBOARD_PAYMENT_CASH_COLLECTION_MINI": "CD",
        //ANALYTICS
        "DASHBOARD_ANALYTICS_REPORT_MINI":"R",
        "DASHBOARD_ANALYTICS_MANAGEMENT_MINI":"M",
        //LOGS
        "DASHBOARD_LOGS_TOKEN_TRANSFER_MINI":"TT",

        //Dashboard Graphs
        "DASHBOARD_SUCC_RIDES_TITLE": "Número acumulado de viajes con éxito",
  "DASHBOARD_CURRENT_MONTH": "Mes en curso",
        "DASHBOARD_PREV_MONTH": "Mes anterior",
  "DASHBOARD_NEW_USERS_TITLE": "Número acumulado de nuevos usuarios",
        "DASHBOARD_TWO_MONTHS_AGO": "Hace dos meses",
        "DASHBOARD_THREE_MONTHS_AGO": "Hace tres meses",
        "DASHBOARD_FOUR_MONTHS_AGO": "Hace cuatro meses",
        "DASHBOARD_CURRENT_RIDES": "Viajes actuales",
        "NEW_DRIVERS": "Nuevos conductores",
        "MONTHLY_GROWTH": "Crecimiento mensual",
        "DASHBOARD_RIGHT_NOW": "Ahora mismo",
        "DASHBOARD_NUM_RIDES": "Número de viajes",
        "DASHBOARD_SINCE_BEG": "Desde el principio",
        "DASHBOARD_LAST_WEEK": "La semana pasada",
        "DASHBOARD_RIDES_TODAY": "Viajes de hoy",
        "DASHBOARD_RIDES_TODAY_SUCCESSFUL": "Viajes exitosos del día de hoy",
        "DASHBOARD_RIDES_TODAY_FAILED": "Viajes fallidos del día de hoy",
        "DASHBOARD_UPDATED_NOW": "Actualizados ahora",
        "DASHBOARD_TODAY": "Hoy",
        "DASHBOARD_REVENUE_TODAY": "Ingresos de hoy",
        "DASHBOARD_CURRENT_STATUS": "Situación actual",
        "DASHBOARD_SUCCESS": "Con éxito",
        "DASHBOARD_CANCELLED_COMM": "Cancelados por la comunidad",
        "DASHBOARD_CANCELLED_DRIVER": "Cancelados por el conductor",
        "DASHBOARD_CANCELLED_RIDER": "Cancelados por el pasajero",
        "UNKNOWN_CANCELLATION": "Cancelados",
        "DASHBOARD_NEVER_ACC": "Nunca aceptados",

        //Analytics
        "ANALYTICS_SALES": "Ventas",
        "ANALYTICS_REPORTS": "Informes",
        "ANALYTICS_EXECUTIVE": "Ejecutivo",
        "ANALYTICS_REPORT_TYPE": "Grupos de informes",
        "ANALYTICS_NO_GROUP_SELECTED": "Seleccione al menos un grupo",
        "ANALYTICS_OPERATIONS": "Operaciones",
        
        //Driver Edit
  "DRIVER_IS_RIDER_LINK": "Haga clic aquí para acceder a la página de pasajeros del conductor",
        "DRIVER_EDIT_MORE_INFO": "Más información",
        "DRIVER_EDIT_CLOSE": "Cerrar",
        "DRIVER_EDIT_ONLINE_SESSIONS": "Sesiones en línea",
        "DRIVER_EDIT_START": "Inicio de la sesión",
        "DRIVER_EDIT_END": "Fin de la sesión",
        "DRIVER_EDIT_DURATION": "Duración",
        "DRIVER_EDIT_DATE": "Fecha",

        //Ride and Ride Req Details 
        "MAP_STAT_CARD_TITLE": "MAPA",
        "INFO_STAT_CARD_TITLE": "INFORMACIÓN",
        "ACTIONS_STAT_CARD_TITLE": "ACCIONES",
        "MAP_STAT_CARD_HINT": "Mostrar mapa",
        "INFO_STAT_CARD_HINT": "Mostrar información",
        "ACTIONS_STAT_CARD_HINT": "Mostrar acciones",
        "HIDE_STAT_CARD_HINT": "Ocultar",
        "MAP_LABEL_PICKUP": "Ruta de recogida del conductor",
        "MAP_LABEL_DEST": "Ruta del conductor hacia el destino",
        "MAP_LABEL_OPTIMAL": "Ruta óptima",
        "MAP_MARKER_RIDE_ACC": "Viaje aceptado",
        "MAP_MARKER_PICKUP": "Recogida",
        "MAP_MARKER_DEST": "Destino",
        "MAP_MARKER_ADDRESS": "Dirección: ",
        "ALL_REQUESTS_DETAILS_TAB": "Detalles",
        "INFO_TABLE_TITLE": "General",
        "INFO_TABLE_START": "Inicio",
        "INFO_TABLE_END": "Fin",
        "INFO_TABLE_DISTANCE": "Distancia estimada entre la recogida y el destino",
        "INFO_TABLE_FROM": "De",
        "INFO_TABLE_TO": "A",
        "INFO_TABLE_SUCC": "Con éxito",
        "INFO_TABLE_DRIVER": "Conductor",
        "INFO_TABLE_RIDER": "Pasajero",
        "INFO_TABLE_RIDE_REQUEST": "Solicitud de viaje asociado",
        "INFO_TABLE_PROOF": "Comprobante de entrega",
        "INFO_TABLE_ONSITE_DELTA": "Minutos de espera en la recogida",
        "REQUEST_DETAILS_GENERAL_TITLE": "General",
        "REQUEST_DETAILS_GENERAL_ID": "ID de ruta",
        "REQUEST_DETAILS_GENERAL_RIDER": "Miembro pasajero",
        "REQUEST_DETAILS_GENERAL_DRIVER": "Miembro conductor",
        "REQUEST_DETAILS_GENERAL_SERVICE_TYPE": "Tipo de servicio",
        "REQUEST_DETAILS_GENERAL_FROM": "De",
        "REQUEST_DETAILS_GENERAL_TO": "A",
        "REQUEST_DETAILS_GENERAL_DISTANCE": "Distancia estimada entre la recogida y el destino",
        "REQUEST_DETAILS_GENERAL_TIME": "Tiempo estimado desde la recogida hasta el destino",
        "TIMELINE_LIST_TITLE": "Línea de tiempo",
        "TIMELINE_LIST_REQUEST_START": "Solicitud iniciada",
        "TIMELINE_LIST_FIRST_ACCEPTED": "Primera aceptación",
        "TIMELINE_LIST_LAST_ACCEPTED": "Última aceptación",
        "TIMELINE_LIST_CANCELLED": "Última cancelación",
        "TIMELINE_LIST_REQUEST_END": "Solicitud finalizada",
        "TIMELINE_LIST_DELTA": "Retraso medio de respuesta de los conductores",
        "ALL_REQUESTS_HISTORY_TAB": "Historia",
        "HISTORY_TABLE_LEGEND_PASSENGER": "Pasajero",
        "HISTORY_TABLE_LEGEND_COMMUNITY": "Comunidad",
        "HISTORY_TABLE_LEGEND_SYSTEM": "Sistema",
        "HISTORY_TABLE_TITLE": "Solicitudes enviadas",
        "HISTORY_TABLE_DRIVER": "Conductor",
        "HISTORY_TABLE_REQUEST_DATETIME": "Solicitud de fecha",
        "HISTORY_TABLE_ACCEPTANCE_DATETIME": "Aceptación",
        "HISTORY_TABLE_CANCELLATION_DATETIME": "Anulación",
        "HISTORY_TABLE_REFUSAL_DATETIME": "Rechazo",
        "HISTORY_TABLE_ONSITE_DATETIME": "Llegada al lugar",
        "HISTORY_TABLE_START_DATETIME": "Inicio",
        "HISTORY_TABLE_FINISH_DATETIME": "Fin",
        "HISTORY_LOG_TITLE": "Registro",
        "HISTORY_LOG_REQUEST_SENT": "Se ha enviado una solicitud a",
        "HISTORY_LOG_REQUEST_ACCEPTED": "aceptó el viaje y está aproximadamente a {{estimatedPickupDistance}} km del lugar de recogida. El tiempo estimado para llegar es {{estimatedPickupTime}}.",
        "HISTORY_LOG_REQUEST_DECLINED": "rechazó la solicitud.",
        "HISTORY_LOG_REQUEST_DECLINED_BY_SYSTEM": "Límite de tiempo excedido. El sistema ha rechazado la solicitud de",
        "HISTORY_LOG_REQUEST_ONSITE": "llegó al lugar de recogida.",
        "HISTORY_LOG_REQUEST_CANCELLED": "ha cancelado el viaje.",
        "HISTORY_LOG_REQUEST_CANCELLED_BY_COMMUNITY": "La comunidad ha cancelado el viaje actual.",
        "HISTORY_LOG_REQUEST_CANCELLED_BY_RIDER": "El pasajero ha cancelado el viaje actual.",
        "HISTORY_LOG_REQUEST_CANCELLED_BY_SYSTEM": "El sistema ha cancelado el viaje actual.",
        "HISTORY_LOG_REQUEST_STARTED": "comenzó el viaje.",
        "HISTORY_LOG_REQUEST_STARTED_BY_COMMUNITY": "La comunidad inició manualmente el recorrido por ",
        "HISTORY_LOG_REQUEST_FINISHED": "terminó el viaje.",
        "HISTORY_LOG_REQUEST_FINISHED_BY_COMMUNITY": "La comunidad terminó manualmente el viaje por ",
        "HISTORY_LOG_REQUEST_FINISHED_BY_SYSTEM": "El sistema terminó manualmente el viaje ",
        "REQ_STATUS": "Estado de la solicitud",
        "CHARGE_TABLE_TITLE": "CARGOS",
        "CHARGE_TABLE_TIP": "Propina:",
        "CHARGE_TABLE_MIN": "Tarifa mínima:",
        "CHARGE_TABLE_PROMO": "Código de promoción:",
        "CHARGE_TABLE_COMM": "Com.:",
        "CHARGE_TABLE_COMM_SUPPL": "Com. suplementario:",
        "CHARGE_TABLE_BASE": "Tarifa de base:",
        "CHARGE_TABLE_CREDIT": "Tarifa de la tarjeta de crédito:",
        "CHARGE_TABLE_DIST": "Tarifa por distancia:",
        "CHARGE_TABLE_TIME": "Tarifa de tiempo:",
        "CHARGE_TABLE_DRIVER": "Tarifa de conductor:",
        "CHARGE_TABLE_RIDER_ALGO": "Tarifa estimada del viaje:",
        "CHARGE_TABLE_RIDE": "Tarifa real del viaje:",
        "CHARGE_TABLE_FROM_ZONE": "Tarifa desde la zona:",
        "CHARGE_TABLE_TO_ZONE": "Tarifa hasta la zona:",
        "CHARGE_TABLE_WORLD": "Tarifa mundial:",
        "CHARGE_TABLE_WORLD_SUPPL": "Tarifa mundial suplementaria:",
        "CHARGE_TABLE_TOTAL": "Total:",
        "CHARGE_TABLE_TAX": "Impuestos:",
        "CHARGE_TABLE_TOTAL_TAX": "Total (con impuestos):",
        "NO_MAP_INFO": "No hay información disponible",
        "MAP_LOADING": "Cargando...",
        "DEBT_MEMO_LABEL": "Nota de la deuda",
        "SEND_TRANSFER_MEMO_LABEL": "Nota de transferencia",
        "RIDE_REQ_STAT_CARD_TITLE": "ÚLTIMAS 20 SOLICITUDES DE VIAJE",
        "RIDE_REQ_STAT_CARD_HINT": "Mostrar",
        "STATUS": "ESTADO",
        "DATE": "Fecha",
        "SCHEDULED_DATE": "Fecha prevista",
        "TRACKING_ID": "ID de seguimiento",
        "ONSITE_DATE": "Fecha en el sitio",
        "ARCHIVED_DATE": "Fecha de archivo",
        "CUSTOMER_PHONE": "Teléfono del cliente",
        "CUSTOMER_PHONE_EXTENSION": "Extensión",
        "CUSTOMER_FIST_NAME": "Nombre y apellido del cliente",
        "ORDER_NUMBER": "Número de pedido",
        "DRIVER": "Conductor",
        "LOAD_MORE": "CARGAR MÁS",
        "NO_DATA": "NO HAY DATOS DISPONIBLES",
        "ADD_MONTH": "Añadir mes",
        "REMOVE_MONTH": "Eliminar el mes",

        //ACTIVE RIDES PAGE
        "START_RIDE": "Iniciar el viaje",
        "END_RIDE": "Terminar el viaje",
        "TRACK_RIDE": "Sigue el viaje",
        "TRACKING_LINK": "Enlace de seguimiento",
        "CANCEL_RIDE": "Cancelar el viaje",
        "ACTION_SUCCESS": "La acción fue un éxito.!",
        "ACTION_ERROR": "Se ha producido un error:",
        "CANCEL_ACTIVE_RIDE_ALERT_TITLE": "Cancelar el viaje",
        "CANCEL_ACTIVE_RIDE_ALERT_CONFIRM": "Sí, ¡cancela el viaje!!",
        "CANCEL_ACTIVE_RIDE_ALERT_BODY": "Está a punto de cancelar un viaje activo, ID de ruta: ",
        "CANCEL_ACTIVE_CORP_RIDE_ALERT_BODY": "Estás a punto de cancelar este viaje.",
        "START_ACTIVE_RIDE_ALERT_TITLE": "Comenzar el viaje activo",
        "INCREMENT_RIDE_ALERT_TITLE": "Siguiente paso",
        "START_ACTIVE_RIDE_ALERT_CONFIRM": "Sí, ¡empieza el viaje!!",
        "INCREMENT_RIDE_ALERT_CONFIRM": "Sí, ¡siguiente paso!!",
        "START_ACTIVE_RIDE_ALERT_BODY": "Está a punto de comenzar un viaje activo, ID de ruta: ",
        "INCREMENT_STEP": "Saltar el paso",
        "INCREMENT_RIDE_ALERT_BODY": "Está a punto de hacer que el viaje vaya al siguiente paso. Todas las pruebas o documentos necesarios para ir a los siguientes pasos no se juntan ya que no son proporcionados por el conductor.",
        "END_ACTIVE_RIDE_ALERT_TITLE": "Finalización del viaje activo",
        "END_ACTIVE_RIDE_ALERT_CONFIRM": "Sí, ¡finaliza el viaje!!",
        "END_ACTIVE_RIDE_ALERT_BODY": "Está a punto de terminar un viaje activo, ruta Id: ",
        "WANT_TO_PROCEED": "¿Desea continuar??",
        "RIDER_IS_DRIVER_MAIN": "ESTE PASAJERO TAMBIÉN ES CONDUCTOR",
        "RIDER_IS_DRIVER_LINK": " Haga clic aquí para ver la página del conductor",
        "RIDER_IS_CORPORATE_USER": "Esta es una cuenta <b>corporativa</b>",
        "SEND_INVOICE_ALERT_TITLE": "Enviar factura",
      "SEND_INVOICE_ALERT_CONFIRM": "Sí, ¡envíe la factura!!",
      "SEND_INVOICE_ALERT_BODY": "Está a punto de enviar una factura a ",
      "INVALID_EMAIL_OR_ROUTE": "Correo electrónico o ruta no válida",
      "ERROR_SENDING_INVOICE": "Hubo un error al generar la factura y enviarla.",
      "ALERT_LOADING": "Cargando...",
        "WELCOME_SIDEBAR": "Bienvenido, ",
        "YES": "Si",
        "NO": "No",
        "NEXT": "SIGUIENTE",
        "PREVIOUS": "ANTERIOR",
        "LOADING": "CARGANDO",
        "PAGE": "PÁGINA",
        "ROW_PER_PAGE":"FILAS POR PÁGINA",
        "JOURNEY": "VIAJE",
        "MAP": "MAPA",
        "IS_DRIVER_ONLINE": "En línea",
        "MEMO_REFUND_RIDER": "Reembolso del pasajero",
        "MEMO_TRANSACTION_ERROR": "Error de transacción",
        "MEMO_PROMO_WITHDRAWAL": "Retirada de la promoción",
        "MEMO_DRIVER_REQUEST": "Solicitud del conductor",
        "MEMO_OTHER": "Otros",
        "MEMO_DETAILS": "Detalles",
        "TYPE": "Tipo",
        "REASON": "Razón",
        "CLOSE": "Cerrar",

        //Edit rides

        "EDIT_RIDE": "Editar",
        "EDIT_RIDE_ALERT_TITLE":"Editar viaje",
        "EDIT_RIDE_ALERT_BODY": "Está a punto de editar este viaje.",
        "EDIT_RIDE_ALERT_CONFIRM":"Sí, edita este viaje",
        "GET_PRICE_ESTIMATE": "Obtenga una estimación del precio",
        "RIDESHARING": "Ridesharing",
        "DELIVERY": "Entrega",
        "ELECTRIC_PLACEHOLDER" : "Eléctrico",
        "CANNABIS": "Cannabis",
        "RIDE_SERVICE_TYPE":"Tipo de servicio",
        "TIP_AMOUNT": "Actualizar la propina en Eva Token", 
        "NEW_PRICE": "Precio nuevo",
        "EDIT_RIDE_ERROR": "Esta solicitud de edición de viaje no es válida. Por favor, valide las direcciones antes de enviarla.",
        "RIDE_NOT_FOUND" : "No se ha encontrado el viaje.",
        "EDIT_CONFIRMATION" : "Confirmación",
        "EDIT_ERROR" : "Error",
        "MANUAL_DISPATCH": "Despacho manual",
        "MANUAL_DISPATCH_CONFIRM": "Sí, envíe el viaje al conductor",
        "DRIVER_PHONE": "Teléfono del conductor",
        "RIDER_PHONE": "Teléfono del pasajero",
        "NONE": "Ninguno",
        "COMPLETED_DATE": "Fecha de finalización",
        "PROOF_OF_CUSTOMER": "Prueba de cliente",
        "GO_TO_DESTINATION": "Ir al destino",
        "CASH_PAYMENT_COLLECTION": "Colección de dinero",
        "TIME_TAKEN":"Tiempo empleado",
        "PROOF_OF_ADDRESS": "Prueba de dirección",
        "GO_BACK_TO_STORE": "Volviendo a la tienda",
        "OWT_RIDER": "En el camino hacia el pasajero",
        "DESTINATION": "Hacia el destino",
        "PICKUP": "Recogida",
        "RIDER": "Pasajero",

        "PROMOTION": "Promoción",
        "SENT_IN_PROMOTION": "Enviado en promoción",
        "REMEDIAL": "Remedio",

        "PERMISSIONS": "Permisos",
        "INFORMATION": "Información",
        "RIDE_SHARING_SERVICE": "Compartir el viaje",
        "DELIVERY_SERVICE": "Entrega",
        "BULK": "Entrega en masa",
        "DELIVERY_SERVICE_BIKE": "Entrega de bicicletas",
        "DELIVERY_SERVICE_MOTORCYCLE": "Entrega de motos",
        "DELIVERY_SERVICE_AMBULANCE": "Ambulancia",
        "RIDE_SHARING_SERVICE_LUX": "Viaje compartido de lujo",
        "RIDE_SHARING_MOTO": "Viaje compartido en moto",
        "DELIVERY_TRICYCLE": "Entrega en tricimoto",
        "RIDE_SHARING_TRICYCLE": "Viaje compartido en tricimoto",
        "SHUTTLE": "Desplazarse",

        "RIDE_SERVICE_NAME":"Tipo de servicio de viaje",

        "TABLE_API_ERROR_1": "Hubo un problema para obtener los datos.",
        "TABLE_API_ERROR_2": "Por favor, inténtelo más tarde.",

        // Reports section
        "REPORTS": "Informes",
        "GENERATE_REPORTS_TITLE": "Generar un informe",
        "REPORT_TYPE_CHOOSE": "Seleccione el tipo de informe",
        "GET_RIDER_RIDES_FREQUENCY": "Número de pasajeros por viaje completado",
        "GENERATE_BUTTON_TITLE": "Generar",

        //JTF section
        "DRIVERS_MENU_JTF": "Pagado por hora",
        "ADD_NEW_JTF_DRIVER": "Añadir nuevo conductor JTF",
        "ADD SHIFT": "Añadir turno",
        "JTF_DELETE_SHIFT": "Borrar el turno",
        "SHOW_SHIFT": "Mostrar turno",
        "REMOVE_FROM_JTF": "Eliminar del JTF",
        "JTF_SHIFT": "Turno JTF",
        "JTF_SHIFT_SCHEDULED": "Turno programado del JTF",
        "JTF_SAVE": "Guardar cambios",
        "JTF_TO": "A",
        "JTF_CONFIRM": "Confirmar",
        "JTF_DESACTIVATE_DRIVER": "Desactivación del controlador JTF",
        "JTF_ADDING_DRIVER": "Añadiendo conductor al JTF",
        "JTF_ADDING_SHIFT": "Añadiendo turno",
        "JTF_SALARY_UPDATE": "El salario del conductor se ha actualizado",
        "JTF_SHIFT_REMOVED": "El turno ha sido eliminado con éxito",
        "JTF_SHIFT_ADDED_SUCCESS": "Turno añadido con éxito",
        "JTF_DRIVER_ADDED_SUCCESS": "Conductor añadido con éxito",
        "JTF_DRIVER_REMOVE_SUCCESS": "La cuenta ha sido añadida y eliminada con éxito del JTF",
        "JTF_HOURLY_RATE": "Tarifa por hora",
        "JTF_START_TIME": "Hora de inicio",
        "JTF_END_TIME": "Hora de finalización",
        "JTF_DELETE_CONFIRM": "¿Está seguro de que quiere eliminar el conductor de la JTF?",
        "NEW_SHIFT": "Nuevo turno",

        //Driver communication section
        "COMMUNICATION_TITLE": "Operación de comunicación",
        "ACTION_SEND_SMS": "Enviar un SMS",
        "ACTION_SEND_NOTIFICATIONS": "Enviar una notificación",
        "ACTION_SEND_EMAIL": "Enviar un correo electrónico",
        "ACTION_SEND_TEMPLATED_EMAIL": "Enviar una plantilla de correo electrónico",
        "COVID_CERT": "Certificado Covid",
        "DRIVER_SMS_MESSAGE": "Mensaje de texto",
        "SELECT_ALL": "Seleccionar todo",
        "SEND_SMS": "Enviar",
        "JTF_SHIFT_REMOVED": "Turno eliminado.",
        "JTF_SHIFT_ADDED_SUCCESS": "¡Turno añadido!",
        "JTF_DRIVER_ADDED_SUCCESS": "Conductor añadido al JTF.",
        "JTF_DRIVER_REMOVE_SUCCESS": "Conductor retirado del JTF",
        "JTF_HOURLY_RATE": "Salario por hora",
        "JTF_START_TIME": "Hora de inicio",
        "JTF_END_TIME": "Hora de finalización",
        "JTF_DELETE_CONFIRM": "¿Está seguro de que quieres eliminar el conductor de la JTF?",
        "NEW_SHIFT": "Nuevo turno",
        "SEND": "Enviar",

        //Online Drivers
        "DRIVERS_MENU_ONLINE": "Mapa en línea",
        "IN_RIDE": "Viaje en vivo",
        "FIND_DRIVER": "Encontrar conductor",
        "NO_PERMISSIONS": "No se han establecido permisos",
        "TIP": "Propina",

        "IN_RIDE_STATUS": "En viaje",
        "SCHEDULED_STATUS": "Programado",
        "DISPATCHING_STATUS": "Despacho",
        "TRANSIT_PICKUP": "Recogida en tránsito",
        "ARRIVED_PICKUP": "Recogida llegada",
        "TRANSIT_DESTINATION": "En destino en tránsito",
        "ARRIVED_DESTINATION": "Destino llegado",

        "SCHEDULED_RIDE_HEALTHCHECK": "Estado de control",
        "HEALTHCHECK": "Estado de control",
        "SCHEDULED_RIDE_PROBLEM": "Problema",
        "SCHEDULED_RIDE_CLEAR": "Borrar",
        "ONSITE_DATE": "Fecha en el sitio",
        "REDISPATCH_RIDE": "Despacho de nuevo",
        "REDISPATCH_RIDE_ALERT_TITLE": "Reenviar el viaje a los conductores.",
        "REDISPATCH_RIDE_ALERT_YES": "Si!",
        "REDISPATCH_RIDE_ALERT_BODY": "Está enviando esta entrega en el despacho. Por favor, verifique que no existe ya en los viajes activos.",

        //Units
        "KILOMETER": "km",
        "MINUTE": "min",
        "SECOND": "seg",

        //404
        "404_TITLE": "404 - Página no encontrada",
        "404_CLICK": "Haga clic ",
        "404_HERE": "aquí",
        "404_RETURN": " para volver a la página de inicio.",

        "REFILL_AMOUNT_CORPORATE_BUSINESS": "Recargar el saldo de la empresa",

        "ADDITIONAL_INFORMATION": "Información adicional",
        "AUTHORIZATION_CODE": "Código de autorización Revenu Québec",
        "BILLING_NUMBER": "Número de facturación",
        "IDENTIFICATION_CODE": "Código de identificación Revenu Québec",
        "RIDES_MENU_CANCELLED": "Cancelado",
        "ACCEPTED_BLOCK_TIME": "Tiempo de aceptación",
        "CANCELED_BLOCK_TIME": "Tiempo de anulación",
        "DELTA_TIME_CANCELLED": "Minutos con viaje",
        "ESTIMATED_PICKUP_TIME": "Tiempo de recogida",
        "ENABLED": "Activado",
        "DISABLED": "Desactivado",
        "PIN_CODE_TITLE": "Depurar: Código PIN",
        "PIN_CODE_SUBTITLE": "Habilitar para mostrar el código PIN en la aplicación Eva. Por favor, no lo cambie sin permiso.",
        "ARCHIVE_SUBMISSION": "Archivar la solicitud",
        "ATTENTION": "Atención!",
        "ARE_YOU_SURE_ARCHIVE": "¿Está seguro de que quiere archivar esta solicitud?",
        "CONFIRM": "Confirmar",
        "SUCCESS": "Éxito",
        "ARCHIVE_SUCCESS": "Archivado con éxito la solicitud.",
        "RESTORE_SUCCESS": "Se ha restaurado con éxito la solicitud.",
        "ARCHIVE_ERROR": "Hubo un problema al archivar la solicitud.",
        "RESTORE_ERROR": "Hubo un problema al restaurar la solicitud.",
        "SUBMISSION_IS_ARCHIVED": "la solicitud está archivada",
        "RESTORE_SUBMISSION": "Restablecer la solicitud",
        "ARE_YOU_SURE_RESTORE": "¿Está seguro de que quiere restaurar la solicitud actual?",
        "DRIVER_SUBMISSIONS_ARCHIVE": "Archivo de solicitudes",
        "CANNABIS": "Cannabis",
        "FLAG_DRIVER": "Señalar al conductor",
        "UNFLAG_DRIVER": "Desenmascarar al conductor",
        "FLAG_CONFIRMATION": "¿Quiere marcar como de alto riesgo al conductor? ",
        "UNFLAG_CONFIRMATION": "¿Desea eliminar la bandera de riesgo para el conductor? ",
        "FLAG_TITLE": "Conductor señalado",
        "UNFLAG_TITLE": "Conductor desmarcado",
        "FLAG_SUCCESSFUL": "fue marcado con éxito como de alto riesgo",
        "UNFLAG_SUCCESSFUL": "fue desmarcado con éxito como de alto riesgo",
        "SENDING_FLAG_DRIVER": "Señalando al conductor...",
        "SENDING_UNFLAG_DRIVER": "Desmarcando conductor...",
        "DRIVER_FLAGGED": "El conductor está marcado como de alto riesgo",
        "FLAGGED_DRIVER_DESC": "Este conductor está marcado como de alto riesgo. Vigila este viaje de cerca. Esto se puede cambiar en la página de edición del conductor.",
        "FLAGGED_RIDER_DESC": "Este pasajero está marcado como de alto riesgo porque la cuenta tiene menos de 24 horas. ¡Vigila este viaje de cerca!",
        "NOTES_DRIVER": "Notas",
        "EDIT": "Editar",
        "EDIT_COMMENT": "Editar comentario",
        "DELETE": "Borrar",
        "SUBMIT": "Enviar",
        "NO_NOTES_YET": "No hay notas que mostrar.",
        "BUSINESS_NAME": "NOMBRE DE LA EMPRESA",
        "BUSINESS": "EMPRESA",
        "IS_FLAGGED": "Conductores señalados",
        "EXTRA_FILTERS": "Filtros adicionales",
        "LATE": "El conductor llegó tarde al destino.",
        "ORDER_DAMAGED": "El conductor dañó un pedido.",
        "WRONG_DESTINATION": "El conductor entregó el pedido en la dirección equivocada.",
        "ORDER_NOT_DELIVERED": "El conductor no entregó un pedido.",
        "LATE_PICKUP": "El conductor llegó tarde a la recogida.",
        "DANGEROUS_DRIVING": "El conductor conducía peligrosamente.",
        "LOW_SUCCESS_RATE": "El conductor tiene una baja tasa de éxito.",
        "LOW_SUCCESS_RATE_SELECT" : "Baja tasa de éxito",
        "LOW_CANCELLATION_RATE": "El conductor tiene un bajo índice de cancelación.",
        "MISBEHAVIOUR": "El conductor fue denunciado por mal comportamiento.",
        "LATE_SELECT": "Pedido tardío",
        "LOW_ACCEPTATION_RATE" : "El conductor tiene un bajo índice de aceptación",
        "LOW_ACCEPTATION_RATE_SELECT": "Baja tasa de aceptación" ,
        "LOW_CANCELLATION_RATE_SELECT": "Baja tasa de anulación.", 
        "HIGH_ACCEPTATION_RATE_SELECT": "Alto tasa de aceptación.",
        "HIGH_ACCEPTATION_RATE" : "El conductor tiene un alto índice de aceptación. " , 
        "ORDER_DAMAGED_SELECT": "Pedido dañado",
        "HIGH_CANCELLATION_RATE_SELECT": "Alto índice de anulación",
        "HIGH_CANCELLATION_RATE": "El conductor tiene un alto índice de cancelación. ", 
        "WRONG_DESTINATION_SELECT": "Destino erróneo",
        "ORDER_NOT_DELIVERED_SELECT": "Pedido no entregado",
        "LATE_PICKUP_SELECT": "Tarde en la recogida",
        "DANGEROUS_DRIVING_SELECT": "Conducción peligrosa",

        
        "MISBEHAVIOUR_SELECT": "Comportamiento incorrecto",
        "CUSTOM": "Personalizado...",
        "RIDE_REQUESTS_RIDER_GRAPH_TITLE": "Viajes solicitados por semana",
        "RIDER_VERIFIED": "Verificado",
        "RIDER_VERIFIED_TEXT": "¡Se ha verificado que este pasajero es una persona real!",
        "RIDER_NOT_VERIFIED": "Todavía no se ha verificado",
        "RIDER_NOT_VERIFIED_TEXT": "Todavía no se ha verificado que este pasajero sea una persona real. Puede verificar manualmente al pasajero en la lista de acciones que aparece a continuación, o esperar hasta que este pasajero haya completado 5 viajes, momento en el que el sistema lo verificará automáticamente.",
        "RIDER_ACTION_VERIFY": "Verificar el pasajero",
        "SEND_VERIFY_CONFIRM": "El pasajero será verificado automáticamente después de 5 viajes exitosos. ¿Desea verificar que este pasajero no es fraudulento?",
        "VERIFY_RIDER_SUCCESSFULL": "Pasajero verificado con éxito!",
        "NOT_VERIFIED_RIDER_DESC": "Este pasajero aún no ha sido verificado.",

        //Helpers
        "never": "Nunca",
        "DEVELOPERS": "Programadores",
        "STANDARD_CAR": "Coche estándar",
        "LUXURY_CAR": "Carro de lujo",
        "VAN": "Camioneta",
        "MOTO": "Motocicleta",
        "AMBULANCE": "Ambulancia",
        "BICYCLE": "Bicicleta",
        "VEHICLE_RIDE_SERVICES_TITLE": "Servicio para el vehículo",
        "VEHICLE_RIDE_SERVICES_SUBTITLE": "Seleccione todos los servicios de transporte que desea permitir para cada vehículo.",
        "RIDE_SERVICES_VISIBILITY_TITLE": "Visibilidad del servicio de transporte",
        "RIDE_SERVICES_VISIBILITY_SUBTITLE": "Seleccione dónde debe ser accesible cada servicio.",
        "MIA_EXTERNAL": "Eva Business (Mia)",
        "ANA_EXTERNAL": "Eva Aplicación",
        "COMMUNITY_SETTINGS": "Configuraciones comunitarias",
        "PROMO_TITLE": "Promociones",
        "PROMO_SUBTITLE": "Seleccione todas las opciones que desee activar",
        "AUTOMATIC_SMS": "Envío automático de SMS cuando se crea una promoción",
        "TIP_AMOUNT": "Propina",

        "NOTE": "Nota",
        "TOTAL_ORDER_AMOUNT": "Importe total del pedido",

        "ERROR_EDIT_SCHEDULED_RIDE": "Entradas no válidas. Por favor, compruebe todos los campos e inténtelo de nuevo.",
        "PRICE": "Precio",
        "INVALID_INPUT": "Entrada no válida.",
        "CUSTOMER_EMAIL": "Email",
        "CUSTOMER_NAME": "Nombre del cliente",
        "CUSTOMER_NOTE": "Nota",
        "ASSIGNED_TO": "Asignado a",
        "MANUAL_DISPATCH_RESET_CONFIRM": "¿Desea eliminar el conductor asignado para este viaje?",
        "MANUAL_DISPATCH_RESET_OK": "Eliminado el conductor asignado.",
        "RESET_MANUAL_DISPATCH": "Desasignar",
        "FRENCH": "Francés",
        "ENGLISH": "Inglés",
        "SPANISH" : "Español",
        "REDISPATCH_ACTIVE_RIDE_ALERT_TITLE": "Reenviar el viaje",
        "REDISPATCH_ACTIVE_RIDE_ALERT_BODY": "Esto cancelará el viaje en vivo actual para el conductor y lo reenviará a otra persona.",
        "DISPATCH_TO_ANOTHER_DRIVER": "Envío a otro conductor",
        "TECHNOLOGY_FEE": "Tarifa tecnológica:",
        "is_driver_late": "¿es puntual?",
        "CURRENT_STEP_EST_TIME": "Tiempo estimado de la etapa",
        "CURRENT_STEP_REAL_TIME": "Tiempo transcurrido de la etapa",
        "MEMO_WAVE": "Depósito WAVE",
        "RESET_DRIVER_STATS": "Reiniciar las estadísticas del conductor",
        "RESET_DRIVER_STATS_CONFIRMATION": "¿Desea reiniciar las estadísticas del conductor?",
        "RESET_DRIVER_STATS_SENDING": "Envío de la acción para reiniciar las estadísticas del conductor",
        "RESET_DRIVER_STATS_SUCCESSFUL": "Las estadísticas del conductor se han restablecido con éxito",
        "RESET_DRIVER_STATS_TITLE": "Restablecer las estadísticas del conductor",
        "DRIVER_CASHOUT_TOKEN_CONFIRM": "¿Estás seguro de que quieres retirar los tokens de este conductor?",
        "DRIVER_ACTION_CASHOUT": "Retirada de tokens Eva",
        "RESET": "Reiniciar",
        "MERGE": "Combinar",
        "NO_ROUTES_IN_SYSTEM": "No hay rutas en el sistema.",
        "NO_ORDERS_IN_SYSTEM": "No hay pedidos en el sistema.",
        "INVALID_ROUTE_IDS": "Los identificadores de ruta dados no son válidos.",
        "FULLY_BOOKED": "Esta ruta está completamente reservada.",
        "INCOMPATIBLE_SERVICE": "No se pueden combinar los viajes compartidos.",
        "INCOMPATIBLE_TIME": "Los viajes están programados en horarios incompatibles.",
        "NO_AUTH": "El conductor no permite la combinación de viajes.",
        "ROUTE_ALREADY_STARTED": "No se pueden combinar trayectos ya iniciados.",
        "TOO_MANY_RIDES": "Se ha alcanzado el límite de viajes.",
        "MANUAL_MERGE_RESET_TITLE": "Combinar trayectos",
        "MANUAL_MERGE_RESET_BODY": "¿Está seguro de que quiere combinar los pedidos? \Esta acción no se puede deshacer.",
        "MANUAL_MERGE_CONFIRMATION": "Los pedidos se han fusionado con éxito.",
        "LIVE_ORDERS": "Pedidos",
        "LIVE_ROUTES": "Rutas",
        "MANUAL_MERGE_LIVE_ROUTES_DESCRIPTION": "Arrastre los pedidos hacia la izquierda para eliminarlas de la ruta.",
        "MANUAL_MERGE_LIVE_ORDERS_DESCRIPTION": "Arrastre cualquier pedido a la derecha para crear una ruta, o para combinar en una ruta existente.",
        "RIDE_MERGER": "Combinar pedidos",
        "DASHBOARD_RIDE_MERGER_MINI": "RM",
        "TRACKING_LINK_MESSAGE_SETTING_TITLE": "Mensaje del enlace de seguimiento",
        "TRACKING_LINK_MESSAGE_SETTING_SUBTITLE": "Se trata de un mensaje personalizado opcional que se mostrará en todos los enlaces de seguimiento de la comunidad.",
        "MESSAGE": "Mensaje",
        "SEVERITY": "Gravedad",
        "INFO": "Información",
        "WARN": "Advertencia",
        "DANGER": "Peligro",
        "RELEASE_CASH_PAYMENT_ENTRY_CONFIRM": "Confirmar Efectivo Recibido",
        "RELEASE_CASH_PAYMENT_ENTRY_BODY": "Está a punto de declarar que la siguiente comisión pagadera en efectivo ha sido recibida en efectivo del conductor: ",
        "RELEASE_CASH_COLLECTION_ENTRY_TITLE": "Declarar efectivo como cobrado",
        "RELEASE_CASH_PAYMENT_ENTRY_CONFIRM": "Sí, tenemos el dinero",
        "RELEASE_DRIVER_CASH_PAYMENT": "Declarar efectivo recibido",
        "VIEW_REQUESTS": "Ver pedidos",
        "PROCESSED_DATE": "Fecha de procesamiento",
        "NOT_STARTED": "No iniciado",
        "ASSIGN_TO": "Asignar a un conductor",
        "INVALID_DRIVER": "El conductor seleccionado no existe.",
        "DISPATCHER_SETTING_TITLE": "Ajustes del despachador",
        "DISPATCHER_SETTING_SUBTITLE": "Modifique el número de pedidos por ciclo de envío y el radio de envío de los distintos servicios.",
        "RADIUSES": "Radios",
        "GENERAL": "General",
        "CASH_PAYMENT_SETTING_TITLE": "Pago en efectivo",
        "CASH_PAYMENT_SETTING_SUBTITLE": "Decida si se permite el pago en efectivo en la comunidad.",
        "ALLOW_CASH_PAYMENT": "Permitir pago en efectivo",
        "ERROR_FETCHING_SERVICES": "Hubo un error al cargar los datos",
        "DEFAULT_PRICING": "Precio predeterminado",
        //Rates mapping
        "minute_rate": "Tarifa por minuto",
        "km_rate": "Tarifa por kilómetro",
        "base_charge": "Cargo base",
        "supplement_comm_charge": "Cargo adicional de franquicia",
        "supplement_comm_charge_desc": "Descripción del cargo adicional de franquicia",
        "supplement_world_charge": "Cargo adicional global",
        "supplement_world_charge_desc": "Descripción del cargo adicional global",
        "min_charge": "Cargo mínimo",
        "cancel_fee": "Cargo de cancelación",

        "SQDC_REPORT_TITLE": "SQDC",
        "SQDC_REPORT_SUBTITLE_1": "Esta semana",
        "SQDC_REPORT_SUBTITLE_2": "Todo el tiempo",
        "SQDC_REPORT_SUBTITLE_3": "Tendencia de pedidos tardíos",
        "SQDC_REPORT_SUBTITLE_4": "Tendencia de estado",
        "SQDC_REPORT_CHART_TITLE_1": "Estado",
        "SQDC_REPORT_CHART_TITLE_2": "Pedidos tardíos",
        "SQDC_REPORT_CHART_TITLE_3": "Desglose de viajes",
        "SQDC_REPORT_CHART_TITLE_4": "Devoluciones",
        "SQDC_LEGEND_SUCCESS": "Éxito",
        "SQDC_LEGEND_CANCELED": "Cancelado",
        "SQDC_LEGEND_RETURN": "Devolución",
        "SQDC_LEGEND_ON_TIME": "A tiempo",
        "SQDC_LEGEND_LATE_1": "Tarde (0-15 mins)",
        "SQDC_LEGEND_LATE_2": "Tarde (15-30 mins)",
        "SQDC_LEGEND_LATE_3": "Tarde (30-45 mins)",
        "SQDC_LEGEND_LATE_4": "Tarde (45+ mins)",
        "SQDC_LEGEND_BREAKDOWN_1": "Minutos para encontrar un conductor",
        "SQDC_LEGEND_BREAKDOWN_2": "Minutos para conducir hasta la recogida",
        "SQDC_LEGEND_BREAKDOWN_3": "Minutos de espera en la recogida",
        "SQDC_LEGEND_BREAKDOWN_4": "Minutos para conducir hasta el destino",
        "SQDC_LEGEND_RETURN_1": "Prueba de dirección",
        "SQDC_LEGEND_RETURN_2": "Prueba de cliente"
 },
    },
  };
