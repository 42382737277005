import React, { useState, useEffect } from 'react';
import "./InfoIcon.css";

// Improvment idea: use animation for fade in & fade out.

function InfoIcon(props) {

  return (
    <div className="info-icon">
      <span>?</span>
      <p>{props.description}</p>
    </div>
  );
}

export default InfoIcon;

