import React, { Component } from "react";

export class StatsCardXS extends Component {
  render() {
    return (
      <div className="card card-stats">
        <div className="content">
          <div className="row">
            <div style={{float: 'left', paddingLeft: '10px'}}>
              <div className="icon-big text-center icon-warning">
                {this.props.bigIcon}
              </div>
            </div>
            <div style={{float: 'right', paddingRight: '10px'}}>
              <div className="numbers">
                <p style={{fontSize: '0.5em'}}>{this.props.statsText}</p>
                {this.props.statsValue}
                <div className="stats" style={{fontSize: '0.5em'}}>
                   {this.props.statsIconText}
                  </div>
                <p style={{fontSize: '0.5em'}}>{this.props.evaTokenValue}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
    );
  }
}

export default StatsCardXS;
