import React from "react";
import { Trans } from "react-i18next";

// 1	Scheduled	
// 2	Dispatching	
// 3	In Transit Pickup
// 4	Arrived Pickup
// 5	In Transit Destination
// 6	Arrived Destination
// 7	Returning
// 8	Completed	
// 9	Canceled by customer	
// 10	Canceled by admin	

export const SCHEDULED_RIDE_STATUS = {
    1: <Trans>SCHEDULED_STATUS</Trans>,
    2: <Trans>DISPATCHING_STATUS</Trans>,
    3: <Trans>TRANSIT_PICKUP</Trans>,
    4: <Trans>ARRIVED_PICKUP</Trans>,
    5: <Trans>TRANSIT_DESTINATION</Trans>,
    6: <Trans>ARRIVED_DESTINATION</Trans>,
};
