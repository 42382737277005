import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer 
        className={
          "footer" +
          (this.props.transparent !== undefined ? " footer-transparent" : "")
        }
      >
          <p>
            &copy; {1900 + new Date().getYear()}{" "}
            <a className="link" href="https://eva.coop/" target="_blank" rel="noopener noreferrer">Eva Coop</a>, made with{" "}
            <i className="fa fa-heart heart" /> love.
          </p>

        
      </footer>
    );
  }
}
export default Footer;
