import { Button, Col, Grid, Row } from "react-bootstrap";
import React, { Component } from "react";
import {
    archiveDriverSubmission,
    getDriverFormTemplate,
    getDriverSubmission,
    getDriverSubmissionFile,
    restoreDriverSubmission,
} from "../../../helpers/apiHelper";
import {
    getConfirmationAlert,
    getErrorAlert,
    getLoadingAlert,
    getSuccessAlert,
} from "../../../helpers/alertHelper";

import Card from "../../../components/Card/Card";
import LoadingIcon from "../../Components/LoadingIcon";
import StatsCardXS from "../../../components/Card/StatsCardXS";
import { Trans } from "react-i18next";
import moment from "moment";
import queryString from "query-string";

class DriverSubmissionDetails extends Component {
    state = {
        alert: null,
        formTemplate: null,
        submission: null,
        isLoadingDone: false,
        customCategories: [],
        extraFields: [],
        extraVehicleFields: [],
        extraPersonalDataFields: [],
        extraFilesFields: [],
        isArchived: false
    }

    componentDidMount = async () => {
        const { phone } = queryString.parse(this.props.location.search);
        const submission = await getDriverSubmission(phone);
        const isArchived = submission.is_archived;
        let custom_data = null;
        const formTemplate = JSON.parse(await getDriverFormTemplate("website"));
        if (submission.custom_data !== undefined && submission.custom_data !== null) {
            if (typeof submission.custom_data === 'string') {
                custom_data = JSON.parse(submission.custom_data);
            } else {
                custom_data = submission.custom_data;
            }
            await this.parseFormTemplate(formTemplate, custom_data, submission.id, phone);
        }

        this.setState({ submission, custom_data, isLoadingDone: true, isArchived });
    }

    parseFormTemplate = async (formTemplate, customData, submissionID, phone) => {
        const extraFields = [];
        const categories = [];
        for (const field of formTemplate) {
            let fieldValue = customData[field.name];
            switch (field.type) {
                case "file":
                    if (typeof fieldValue === 'undefined' || fieldValue === null || fieldValue === "") continue;
                    const data = { filename: fieldValue, identifier: submissionID, phone: phone}
                    fieldValue = await getDriverSubmissionFile(data).then(result => {
                        return <a href={result} target="_blank" rel="noreferrer noopener"><Trans>Open File</Trans></a>;
                    }).catch(error => {
                        return <Trans>NONE</Trans>
                    });
                    break;
                case "date":
                    fieldValue = moment(fieldValue).format('YYYY/MM/DD');
                    break;
                default:
                    break;
            }

            if (field.category) {
                if (field.category === "personal_data") {
                    this.state.extraPersonalDataFields.push({ field, value: fieldValue });
                    continue;
                } else if (field.category === "vehicle") {
                    this.state.extraVehicleFields.push({ field, value: fieldValue });
                    continue;
                }

                const categoryIndex = categories.findIndex(value => value.name === field.category);
                const fieldObj = { name: field.name, translation: field.translation, value: fieldValue };
                if (categoryIndex === -1) {
                    categories.push({ name: field.category, fields: [fieldObj] })
                } else {
                    categories[categoryIndex]['fields'].push(fieldObj)
                }
                continue;
            }

            extraFields.push({ field, value: fieldValue });
        }

        this.createCategoryCard(categories);
        this.setState({ extraFields });
    }

    createCategoryCard = (categories) => {
        const cards = []
        for (const category of categories) {
            cards.push(
                <Col md={4}>
                    <Card
                        title={<Trans>{category.name}</Trans>}
                        content={
                            <table className="table">
                                <tbody>
                                    {category.fields.map((field, index) => {
                                        return (<tr>
                                            <td><Trans>{field.translation}</Trans>:</td>
                                            <td>{field.value}</td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        }
                    />
                </Col>
            )
        }

        this.setState({ customCategories: cards });
    }

    archiveSubmission = async (id) => {
        this.loadingHandlerAlert();
        console.log(id)
        const response = await archiveDriverSubmission(id);
        if (response.success) {
            this.successHandlerAlert(<Trans>ARCHIVE_SUCCESS</Trans>);
        } else {
            this.errorHandlerAlert(<Trans>ERROR</Trans>, <Trans>ARCHIVE_ERROR</Trans>);
        }
    }

    restoreSubmission = async (id) => {
        this.loadingHandlerAlert();
        const response = await restoreDriverSubmission(id);
        if (response.success) {
            this.successHandlerAlert(<Trans>RESTORE_SUCCESS</Trans>);
        } else {
            this.errorHandlerAlert(<Trans>ERROR</Trans>, <Trans>RESTORE_ERROR</Trans>);
        }
    }

    successHandlerAlert = (message) => {
        this.setState({
            alert: getSuccessAlert(
                () => {
                    this.props.history.push({
                        pathname: '/drivers/submissions',
                    })
                },
                <Trans>SUCCESS</Trans>,
                message
            ),
        });
    };

    errorHandlerAlert = (error, msg) => {
        this.setState({
            alert: getErrorAlert(() => this.hideAlert(), error, msg),
        });
    };

    loadingHandlerAlert = () => {
        this.setState({
            alert: getLoadingAlert(),
        });
    };

    confirmationAlertHandler = (action) => {
        switch (action) {
            case "archive_submission":
                this.setState({
                    alert: getConfirmationAlert(
                        () => this.archiveSubmission(this.state.submission.id),
                        () => this.hideAlert(),
                        <Trans>ATTENTION</Trans>,
                        <Trans>ARE_YOU_SURE_ARCHIVE</Trans>
                    ),
                });
                break;
            case "restore_submission":
                this.setState({
                    alert: getConfirmationAlert(
                        () => this.restoreSubmission(this.state.submission.id),
                        () => this.hideAlert(),
                        <Trans>ATTENTION</Trans>,
                        <Trans>ARE_YOU_SURE_RESTORE</Trans>
                    ),
                });
                break;
            default:
                return
        }
    };

    hideAlert = () => {
        this.setState({
            alert: null,
        });
    };

    render() {
        const isArchivedBanner = (this.state.isArchived) ?
            <div
                style={{
                    margin: "10px",
                    padding: "10px",
                    backgroundColor: "#FF7070",
                    color: "white",
                    textAlign: "center",
                    borderRadius: "10px"
                }}>
                <b><Trans>SUBMISSION_IS_ARCHIVED</Trans></b>
            </div> : <div></div>;

        return (
            <div>
                {this.state.alert}
                {this.state.isLoadingDone ?
                    <Grid fluid>
                        <Row>
                            {isArchivedBanner}
                        </Row>
                        <Row>
                            <Col md={4}>
                                <StatsCardXS
                                    bigIcon={<i className="fa fa-user text-success" />}
                                    statsText={<Trans>Full Name</Trans>}
                                    statsValue=
                                    {<span style={{ fontSize: 24 }}>
                                        {this.state.submission.first_name + " " + this.state.submission.last_name}
                                    </span>}
                                />
                            </Col>
                            <Col md={4}>
                                <StatsCardXS
                                    bigIcon={<i className="fa fa-phone text-primary" />}
                                    statsText={<Trans>Phone Number</Trans>}
                                    statsValue=
                                    {<span style={{ fontSize: 24 }}>
                                        {this.state.submission.phone}
                                    </span>}
                                />
                            </Col>
                            <Col md={4}>
                                <StatsCardXS
                                    bigIcon={<i class="fa fa-envelope" />}
                                    statsText={<Trans>Email</Trans>}
                                    statsValue=
                                    {<span style={{ fontSize: 18 }}>
                                        {this.state.submission.email}
                                    </span>}
                                />
                            </Col>
                            <Col md={4}>
                                <StatsCardXS
                                    bigIcon={<i class="fa fa-building" />}
                                    statsText={<Trans>City</Trans>}
                                    statsValue=
                                    {<span style={{ fontSize: 24 }}>
                                        {this.state.submission.city}
                                    </span>}
                                />
                            </Col>
                            <Col md={4}>
                                <StatsCardXS
                                    bigIcon={<i class="fa fa-birthday-cake" />}
                                    statsText={<Trans>Date of birth</Trans>}
                                    statsValue=
                                    {<span style={{ fontSize: 24 }}>
                                        {moment(this.state.submission.date_of_birth).format('YYYY/MM/DD')}
                                    </span>}
                                />
                            </Col>
                            {
                                this.state.extraPersonalDataFields.map(value => {
                                    return <Col md={4}>
                                        <StatsCardXS
                                            statsText={<Trans>{value.field.translation}</Trans>}
                                            statsValue=
                                            {<span style={{ fontSize: 24 }}>
                                                {value.value}
                                            </span>}
                                        />
                                    </Col>
                                })
                            }
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Card
                                    title={<Trans>DRIVER_FINANCIAL_INFO</Trans>}
                                    content={
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td><Trans>Financial institute name</Trans>:</td>
                                                    <td>{this.state.submission.financial_institution_name}</td>
                                                </tr>
                                                <tr>
                                                    <td><Trans>Financial institute number</Trans>:</td>
                                                    <td>{this.state.submission.financial_institution_number}</td>
                                                </tr>
                                                <tr>
                                                    <td><Trans>Transit number</Trans>:</td>
                                                    <td>{this.state.submission.financial_institution_transit_number}</td>
                                                </tr>
                                                <tr>
                                                    <td><Trans>Account number</Trans>:</td>
                                                    <td>{this.state.submission.financial_institution_account_number}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }
                                />
                            </Col>
                            <Col md={4}>
                                <Card
                                    title={<Trans>DRIVER_LICENSE_INFO</Trans>}
                                    content={
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td><Trans>License_expiration</Trans>:</td>
                                                    <td>{moment(this.state.submission.driver_licence_expiration).format('YYYY/MM/DD')}</td>
                                                </tr>
                                                <tr>
                                                    <td><Trans>License_number</Trans>:</td>
                                                    <td>{this.state.submission.driver_licence_number}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }
                                />
                            </Col>
                            <Col md={4}>
                                <Card
                                    title={<Trans>DRIVER_VEHICLE_INFO</Trans>}
                                    content={
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td><Trans>Vehicle identification number</Trans>:</td>
                                                    <td>{this.state.submission.vehicle_identification_number}</td>
                                                </tr>
                                                <tr>
                                                    <td><Trans>Registration plate number</Trans>:</td>
                                                    <td>{this.state.submission.vehicle_registration_plate}</td>
                                                </tr>
                                                <tr>
                                                    <td><Trans>Vehicle brand</Trans>:</td>
                                                    <td>{this.state.submission.vehicle_brand}</td>
                                                </tr>
                                                <tr>
                                                    <td><Trans>Vehicle color</Trans>:</td>
                                                    <td>{this.state.submission.vehicle_color}</td>
                                                </tr>
                                                <tr>
                                                    <td><Trans>Vehicle model</Trans>:</td>
                                                    <td>{this.state.submission.vehicle_model}</td>
                                                </tr>
                                                <tr>
                                                    <td><Trans>Vehicle year</Trans>:</td>
                                                    <td>{this.state.submission.vehicle_year}</td>
                                                </tr>
                                                <tr>
                                                    <td><Trans>Vehicle mileage</Trans>:</td>
                                                    <td>{this.state.submission.vehicle_kilometers}</td>
                                                </tr>
                                                {console.log('SUBMISSION', this.state.submission)}
                                                {console.log('EXTRA', this.state.extraVehicleFields)}
                                                {this.state.extraVehicleFields.map(value => {
                                                    return (
                                                        <tr>
                                                            <td><Trans>{value.field.translation}</Trans>:</td>
                                                            <td>{value.value}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            {this.state.customCategories}
                        </Row>
                        {this.state.extraFields.length > 0 ?
                            <Row>
                                <Col md={12}>
                                    <Card
                                        title={<Trans>DRIVER_OTHER_INFO</Trans>}
                                        content={
                                            <table className="table">
                                                <tbody>
                                                    {this.state.extraFields.map((value, index) => {
                                                        return (<tr>
                                                            <td><Trans>{value.field.translation}</Trans>:</td>
                                                            <td>{value.value}</td>
                                                        </tr>)
                                                    })}
                                                </tbody>
                                            </table>
                                        }
                                    />
                                </Col>
                            </Row> : ""
                        }
                        <Row style={{ marginTop: "5%" }}>
                            <Col md={4} />
                            <Col md={4} className="text-center">
                                {this.state.isArchived ?
                                    <Button style={{ width: "70%", display: "inline-block" }} onClick={() => this.confirmationAlertHandler("restore_submission")}>
                                        <Trans>RESTORE_SUBMISSION</Trans>
                                    </Button> :
                                    <Button style={{ width: "70%", display: "inline-block" }} onClick={() => this.confirmationAlertHandler("archive_submission")}>
                                        <Trans>ARCHIVE_SUBMISSION</Trans>
                                    </Button>}
                            </Col>
                            <Col md={4} />
                        </Row>
                    </Grid>
                    : <LoadingIcon />}
            </div>
        )
    }
}

export default DriverSubmissionDetails;