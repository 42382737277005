import React from "react";
import { Trans } from "react-i18next";
import { communityAccount } from "../variables/secretEnvVariables";

export const DEBT_MEMO = [
    { value: 'REFUND_RIDER', label: <Trans>MEMO_REFUND_RIDER</Trans> },
    { value: 'TRANSACTION_ERROR', label: <Trans>MEMO_TRANSACTION_ERROR</Trans> },
    { value: 'PROMO_WITHDRAWAL', label: <Trans>MEMO_PROMO_WITHDRAWAL</Trans> },
    { value: 'DRIVER_REQUEST', label: <Trans>MEMO_DRIVER_REQUEST</Trans> },
    { value: 'OTHER', label: <Trans>MEMO_OTHER</Trans> }
]

export const TOKEN_TRANSFER_MEMO = [
    ... communityAccount === "evaicab1" ? [{ value: 'WAVE', label: <Trans>MEMO_WAVE</Trans> }] : [],
    { value: 'PROMOTION', label: <Trans>PROMOTION</Trans> },
    { value: 'REMEDIAL', label: <Trans>REMEDIAL</Trans> },
    { value: 'OTHER', label: <Trans>MEMO_OTHER</Trans> }
]
