import React from 'react';
import { useState } from 'react';
import { forceManualMerge } from '../../helpers/apiHelper';

//TODO: Remove

function ForceManualMerge() {
    const [originTrackingId, setOriginTrackingId] = useState(null);
    const [destinationTrackingId, setDestinationTrackingId] = useState(null);

    const merge = async () => {
        try {
            const response = await forceManualMerge(originTrackingId, destinationTrackingId);
            if (response.success) {
                alert("Request submitted. Check routes in 1 minute to make sure it worked.");
            } else {
                alert(response.message)
            }
        } catch (err) {
            alert(err)
        }
    }

    return <div style={{display: 'grid', height: '25%'}}>
    <div style={{margin: 'auto'}}>
    <div>Origin Tracking ID:<input style={{width: "150px", borderRadius: "10px", border:"none", height:"40px", padding: "10px"}} type="text" onChange={(e) =>setOriginTrackingId(e.target.value)} value={originTrackingId} /></div>
    <br />
    <div>Destination Tracking ID:<input style={{width: "150px", borderRadius: "10px", border:"none", height:"40px", padding: "10px"}} type="text" onChange={(e) => setDestinationTrackingId(e.target.value)} value={destinationTrackingId} /></div>
   <div> <button onClick={() => merge()}>Merge</button></div>
    </div>
  </div>
}

export {ForceManualMerge}