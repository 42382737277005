import {
    Button,
    Col,
    Grid,
    Row
} from "react-bootstrap";
import React, { Component } from "react";
import { activateDriver, getDeactivatedDrivers } from "../../helpers/apiHelper";

import Card from "../../components/Card/Card.jsx";
import StatsCard2 from "../../components/Card/StatsCard2";
import SweetAlert from "react-bootstrap-sweetalert";
import TableAPI from "../Tables/TableAPI";
import { Trans } from "react-i18next";
import { connect } from 'react-redux';

class DeactivatedDrivers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            driversList: {},
            error: '',
            showDefaultView: true,
            alert: null,
        };
    }

    async componentDidMount() {
        let deactivatedDriversCount = await getDeactivatedDrivers({get_count: true});
        this.setState({ deactivatedDriversCount: deactivatedDriversCount.count });
    }

    onClickActivate = (driverAccount) => {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title={<Trans>ARE_YOU_SURE</Trans>}
                    onConfirm={() => this.activateDriver(driverAccount)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={<Trans>YES_ACTIVATE_DRIVER</Trans>}
                    cancelBtnText={<Trans>CANCEL</Trans>}
                    showCancel
                >
                    <p> <Trans>ACTIVATE_DRIVER_CONFIRMATION</Trans> {driverAccount}? </p>
                </SweetAlert>
            )
        });
    }

    activateDriver = (driverAccount) => {
        var that = this;
        activateDriver(driverAccount).then(response => {
            that.showActivateConfirm(driverAccount);
        }).catch(function (error) {
            that.errorHandlerAlert(error);
        })
    }

    showActivateConfirm = (driverAccount) => {
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={<Trans>ACTIVATED</Trans>}
                    onConfirm={() => this.hideAfterconfirmAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    <p><Trans>ACTIVATE_DRIVER_SUCCESS</Trans> {driverAccount}. <Trans>ACTIVATE_PERMISSIONS_CONFIRM</Trans></p>
                </SweetAlert>
            )
        });
    }

    hideAfterconfirmAlert() {
        this.setState({
            alert: null
        });
        window.location.reload();
    };

    hideAlert() {
        this.setState({
            alert: null
        });
    };

    errorHandlerAlert = (error) => {
        console.log("error", error.message);
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error"
                    onConfirm={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    <p>An Error has occurred : {error.message}</p>
                </SweetAlert>
            )
        });
    };

    onDriverEosClick = (accountName) => {
        window.open(`#/rider/?name=${accountName}`);
    }

    dynamicShowDefaultView = () => {
        this.setState({ showDefaultView: true });
    };
    getStyleFromRow = (row,permissionType) => {
        if (row){
            if (row.original.delivery_permission === true && permissionType === 1){
                return {
                    style: {color: "green"}
                }
            }
            else if (row.original.ride_sharing_permission === true && permissionType === 0) {
                return {
                    style: {color: "green"}
                }
            }else {
                return{
                    style: { className: "-highlight", color: 'red'}
                }
            }
        } else {
            return {
                style: { className: "-highlight", color: 'red'}
            }
        }
    }
    render() {
        let showDefaultView = null;
        if (this.state.showDefaultView) {
            showDefaultView = (
                <div className="container-fluid">
                    <br />
                    <Grid fluid>
                        <Row>
                            <Col lg={40} sm={11} xs={6}>
                                    <h4><Trans>Drivers in</Trans> {this.props.cityName}</h4>
                                    <StatsCard2
                                        bigIcon={<i className="pe-7s-users text-success" />}
                                        statsText={<Trans>Inactive drivers</Trans>}
                                        statsValue={this.state.deactivatedDriversCount}
                                        statsIcon={<i className="fa fa-calendar-o" />}
                                        statsIconText={<Trans>DASHBOARD_UPDATED_NOW</Trans>}
                                    />
                            </Col>
                            <Col md={12}>

                                <Card
                                    plain
                                    ctTableFullWidth
                                    ctTableResponsive
                                    content={
                                        <TableAPI
                                            fetchData={getDeactivatedDrivers}
                                            columns={[
                                                {
                                                    Header: <Trans>DRIVER_RATING</Trans>,
                                                    canFilter: true,
                                                    canSort: true,
                                                    id: 'driver_rating',
                                                    width: 120,
                                                    accessor: (el) => {
                                                        if (el.rating_count > 0) {
                                                            return el.rating_avgx100 / 100 + " (" + el.rating_count + ")"
                                                        } else {
                                                            return <Trans>NO_RIDES</Trans>
                                                        }
                                                    },
                                                },
                                                {
                                                    Header: <Trans>DELIVERY_SERVICE</Trans>,
                                                    canFilter: true,
                                                    filterMode: 'checkbox',
                                                    width: 120,
                                                    accessor: el => el.delivery_permission,
                                                    id: 'delivery_permission',
                                                    Cell: row => (
                                                        <span>
                                                           {
                                                                row.value === true ? <Trans>Allowed</Trans>
                                                                    : <Trans>Denied</Trans>
                                                            }
                                                        </span>
                                                    ),
                                                    getProps: (state,row,column)=>{
                                                    return this.getStyleFromRow(row,1); },   
                                                },
                                                {
                                                    Header: <Trans>RIDE_SHARING_SERVICE</Trans>,
                                                    canFilter: true,
                                                    filterMode: 'checkbox',
                                                    width: 120,
                                                    accessor: el => el.ride_sharing_permission,
                                                    id: 'ride_sharing_permission',
                                                    Cell: row => (
                                                        <span>
                                                           {
                                                                row.value === true ? <Trans>Allowed</Trans>
                                                                    : <Trans>Denied</Trans>
                                                            }
                                                        </span>
                                                    ),
                                                    getProps: (state,row,column)=>{
                                                    return this.getStyleFromRow(row,0); },  
                                                },
                                                {
                                                    Header: <Trans>DRIVER_ACCEPTANCE_RATIO</Trans>,
                                                    canFilter: true,
                                                    canSort: true,
                                                    width: 120,
                                                    id: 'driver_acceptance_ratio',
                                                    accessor: (el) => {
                                                        const total_request_count = el.total_request_count;
                                                        if (total_request_count > 0) {
                                                            let ratio = el.total_request_accepted_count / total_request_count;
                                                            return ((ratio) * 100).toFixed(0) + "% (" + total_request_count + ")";
                                                        } else {
                                                            return 0;
                                                        }
                                                    },
                                                },
                                                {
                                                    Header: <Trans>DRIVER_SUCCESS_RATIO</Trans>,
                                                    canFilter: true,
                                                    canSort: true,
                                                    width: 120,
                                                    id: 'success_ratio',
                                                    accessor: (el) => {
                                                        if (el.completed_ride_count > 0) {
                                                            return ((el.completed_ride_count / (el.total_request_accepted_count)) * 100).toFixed(0) + "% (" + el.total_request_accepted_count + ")";
                                                        } else {
                                                            return <Trans>NO_RIDES</Trans>;
                                                        }
                                                    },
                                                },
                                                {
                                                    Header: <Trans>phone</Trans>,
                                                    canFilter: true,
                                                    id: 'phone',
                                                    width: 120,
                                                    accessor: (el) => { return el.profile.phone },
                                                },
                                                {
                                                    Header: <Trans>CUSTOMER_EMAIL</Trans>,
                                                    canFilter: true,
                                                    id: 'email',
                                                    width: 250,
                                                    accessor: (el) => { return el.profile.email },
                                                },
                                                {
                                                    Header: <Trans>First Name</Trans>,
                                                    canFilter: true,
                                                    canSort: true,
                                                    id: 'first_name',
                                                    width: 150,
                                                    accessor: (el) => { return el.profile.first_name },
                                                    Cell: e => <span style={{ color: '#70CEEC', cursor: 'pointer' }} onClick={() => { this.onDriverEosClick(e.original.account); }}> {e.value} </span>
                                                },
                                                {
                                                    Header: <Trans>Last Name</Trans>,
                                                    canFilter: true,
                                                    canSort: true,
                                                    id: 'last_name',
                                                    width: 150,
                                                    accessor: (el) => { return el.profile.last_name },
                                                    Cell: e => <span style={{ color: '#70CEEC', cursor: 'pointer' }} onClick={() => { this.onDriverEosClick(e.original.account); }}> {e.value} </span>
                                                },
                                                {
                                                    id: 'activate_driver',
                                                    width: 150,
                                                    accessor: (el) => {
                                                        const _key = el.account;
                                                        return <Button
                                                            style={{ margin: "5px 0 5px 0", }}
                                                            onClick={() => this.onClickActivate(_key)} bsSize="xsmall" ><Trans>Activate driver</Trans></Button>
                                                    },
                                                }
                                            ]}
                                            className="-highlight"
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                    </Grid >
                    <p></p>
                    <br></br>
                    <br></br>
                </div >
            )
        }

        return (
            <div className="content">
                {this.state.alert}
                {showDefaultView}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.userData.isAuthenticated,
        community: state.userData.communityAccount,
        cityName: state.community.comm_name
    };
};
export default connect(mapStateToProps)(DeactivatedDrivers);
