import ActiveRidersList from "../views/Riders/ActiveRidersList";
import ActiveRidesList from "../views/Rides/ActiveRidesList";
import ArchivedScheduledRidesList from "../views/Rides/ScheduledRides/ArchivedScheduledRidesList";
import BlacklistedRidersList from "../views/Riders/BlacklistedRidersList";
import BusinessList from "../views/Business/BusinessList";
import ChainTransferTokens from "../views/Logs/ChainTransferTokens";
import CommunitySettings from "../views/Community/CommunitySettings";
import CompletedRides from "../views/Rides/CompletedRides";
import Dashboard from "../views/Dashboard/Dashboard.jsx";
import Analytics from "../views/Analytics/Analytics.jsx";
import DeactivatedDrivers from "../views/Drivers/DeactivatedDrivers";
import DebugSettings from "../views/Developers/DebugSettings";
import DriverCancellationList from "../views/Rides/DriverCancellationList";
import DriverEdit from "../views/Drivers/DriverEdit";
import DriverMessageList from "../views/Drivers/DriverMessageList";
import DriverSubmissionDetails from "../views/Drivers/OldSubmissionsList/DriverSubmissionDetails";
import OldDriverSubmissionsList from "../views/Drivers/OldSubmissionsList/OldSubmissionsList";
import DriverSubmissionsArchive from "../views/Drivers/OldSubmissionsList/DriverSubmissionsArchive";
import DriversList from "../views/Drivers/DriversList";
import DriversOnline from "../views/Drivers/DriversOnline";
import Error404 from "../views/Errors/Error404"
import CashCollection from "../views/CashCollection/CashCollection";
import HistoricalCashout from "../views/Payments/DriversCashout/driverHistoricalCashout";
import JTFDrivers from "../views/JTFDrivers/JTFDrivers";
import NewDriverSubmission from "../views/Drivers/NewSubmission/NewSubmission";
import EditDriverSubmission from "../views/Drivers/EditSubmission/EditSubmission";
import DriverSubmissionsList from "../views/Drivers/SubmissionsList/SubmissionsList";
import NewEditBusiness from "../views/Business/NewEditBusiness";
import NewEditRestaurant from "../views/Restaurant/NewEditRestaurant";
import PendingCashout from "../views/Payments/DriversCashout/PendingCashout";
import React from "react";
import Reports from "../views/Reports/Reports";
import RestaurantList from "../views/Restaurant/RestaurantList";
import RideDetails from "../views/Rides/RideDetails";
import RideOpportunitiesList from "../views/Rides/RideOpportunitiesList";
import AllRequests from "../views/Rides/AllRequests/AllRequests"
import RideRequestSummary from "../views/Rides/AllRequests/RideRequestSummary/RideRequestSummary";
import RiderDetails from "../views/Riders/RiderDetails";
import ScheduledRidesList from "../views/Rides/ScheduledRides/ScheduledRidesList";
import DriverRoutes from "../views/Rides/DriverRoute/DriverRoute";
import { Trans } from "react-i18next";
import indexRoutes from ".";
import dashboardReport from "../views/Analytics/dashboardReport.jsx"
import Management from "../views/Analytics/Management.jsx"
import ManagementUser from "../views/Analytics/ManagementUser";
import RideMerger from "../views/Rides/RideMerger";
import ForceManualMerge from "../views/ForceManualMerge";
import Pricing from "../views/Pricing";
import Invoices from "../views/Payments/Invoices/Invoices";
import FixStuckMerge from "../views/Rides/FixStuckMerge";

var dashboardRoutes = [
  /*{
    path: "/dashboard",
    name: <Trans>Dashboard</Trans>,
    icon: "pe-7s-graph",
    component: Dashboard
  },*/
  {
    collapse: true,
    path: "/rides",
    name: <Trans>Rides</Trans>,
    state: "openRides",
    icon: "pe-7s-map-marker",
    views: [
      {
        path: "/rides/active-rides",
        name: <Trans>Active rides</Trans>,
        mini: <Trans>DASHBOARD_ACTIVE_RIDE_MINI</Trans>,
        component: ActiveRidesList
      },
      {
        path: "/rides/customer-orders",
        name: <Trans>CUSTOMER_ORDER</Trans>,
        mini: <Trans>DASHBOARD_CUSTOMER_ORDERS_MINI</Trans>,
        component: ScheduledRidesList
      },
      {
        path: "/rides/driver-routes",
        name: <Trans>DRIVER_ROUTES</Trans>,
        mini: <Trans>DASHBOARD_DRIVER_ROUTES_MINI</Trans>,
        component: DriverRoutes
      },
      //{
      //  path: "/rides/ride-merger",
      //  name: <Trans>RIDE_MERGER</Trans>,
      //  mini: <Trans>DASHBOARD_RIDE_MERGER_MINI</Trans>,
      //  component: RideMerger
      //},
      //{
      //  path: "/fixes",
      //  name: <Trans>RIDE_MERGER</Trans>,
      //  mini: <Trans>DASHBOARD_RIDE_MERGER_MINI</Trans>,
      //  hideOnSidebar: true,
      //  component: FixStuckMerge
      //},
      {
        path: "/rides/billed-rides",
        name: <Trans>RIDES_COMPLETED</Trans>,
        mini: <Trans>DASHBOARD_BILLED_MINI</Trans>,
        component: CompletedRides
      },
      //{
      //  path: "/rides/archived-customer-orders",
      //  name: <Trans>ARCHIVED_CUSTOMER_ORDERS</Trans>,
      //  mini: <Trans>DASHBOARD_ARCHIVED_CUSTOMER_ORDERS_MINI</Trans>,
      //  component: ArchivedScheduledRidesList
      //},
      /*{
        path: "/rides/all-requests",
        name: <Trans>ALL_REQUESTS</Trans>,
        mini: <Trans>DASHBOARD_ALL_REQUESTS_MINI</Trans>,
        component: AllRequests
      },
      {
        path: "/rides/ride-opportunities",
        name: <Trans>RIDE_OPPORTUNITIES</Trans>,
        mini: <Trans>DASHBOARD_RIDE_OPPORTUNITY_MINI</Trans>,
        component: RideOpportunitiesList
      },
      {
        path: "/rides/cancellations",
        name: <Trans>RIDES_MENU_CANCELLED</Trans>,
        mini: <Trans>DASHBOARD_CANCELLED_MINI</Trans>,
        component: DriverCancellationList
      },*/
      //TODO: Remove
      {
        path: "/rides/force_merge",
        name: <Trans>Force Manual Merge</Trans>,
        mini: "FM",
        component: ForceManualMerge
      },
    ]
  },
  /*{
    collapse: true,
    path: "/riders",
    name: <Trans>RIDERS_MENU</Trans>,
    state: "openRiders",
    icon: "pe-7s-user-female",
    component: ActiveRidersList,
    views: [
      {
        path: "/riders/active-riders",
        name: <Trans>ACTIVE_RIDERS</Trans>,
        mini: "AR",
        component: ActiveRidersList
      },
      {
        path: "/riders/blacklisted-riders",
        name: <Trans>BLACKLISTED_RIDERS</Trans>,
        mini: "BR",
        component: BlacklistedRidersList
      }]
  },*/
  {
    path: "/rider",
    collapse: false,
    hideOnSidebar: true,
    name: <Trans>RIDER_DETAIL</Trans>,
    icon: "pe-7s-user-female",
    component: RiderDetails
  },
  {
    path: "/user",
    collapse: false,
    hideOnSidebar: true,
    name: <Trans>Analytics Management</Trans>,
    icon: "pe-7s-user-female",
    component: ManagementUser
  },
  {
    path: "/ride",
    collapse: false,
    hideOnSidebar: true,
    name: <Trans>RIDE_DETAILS</Trans>,
    component: RideDetails
  },
  {
    path: "/ride-request-summary",
    hideOnSidebar: true,
    name: <Trans>REQUEST_SUMMARY</Trans>,
    component: RideRequestSummary
  },
  {
    collapse: true,
    path: "/drivers",
    name: <Trans>Drivers</Trans>,
    state: "openDrivers",
    icon: "pe-7s-car",
    component: DriversList,
    views: [
      {
        path: "/drivers/active-drivers",
        name: <Trans>DRIVERS_MENU_ACTIVE</Trans>,
        mini: <Trans>DASHBOARD_INACTIVE_DRIVER_MINI</Trans>,
        component: DriversList
      },
      /*{
        path: "/drivers/deactivated_drivers",
        name: <Trans>DRIVERS_MENU_INACTIVE</Trans>,
        mini: <Trans>DASHBOARD_INACTIVE_DRIVER_MINI</Trans>,
        component: DeactivatedDrivers
      },
      {
        path: "/jtf_drivers",
        name: <Trans>DRIVERS_MENU_JTF</Trans>,
        mini: <Trans>DASHBOARD_JTF_DRIVER_MINI</Trans>,
        component: JTFDrivers
      },*/
      {
        path: "/drivers/online-drivers",
        name: <Trans>DRIVERS_MENU_ONLINE</Trans>,
        mini: <Trans>DASHBOARD_ONLINE_DRIVER_MAP_MINI</Trans>,
        component: DriversOnline
      },
      {
        path: "/drivers/submissions/new",
        name: <Trans>DRIVERS_MENU_NEW_SUBMISSION</Trans>,
        mini: <Trans>DASHBOARD_NEW_DRIVER_SUBMISSIONS_MINI</Trans>,
        component: NewDriverSubmission,
      },
      {
        path: "/drivers/submissions-list",
        name: <Trans>DRIVERS_MENU_SUBMISSIONS_LIST</Trans>,
        mini: <Trans>DASHBOARD_DRIVER_SUBMISSIONS_LIST_MINI</Trans>,
        component: DriverSubmissionsList
      },
      {
        path: "/drivers/notify",
        name: <Trans>DRIVERS_MENU_COMMUNICATION</Trans>,
        mini: <Trans>DASHBOARD_DRIVER_COMMUNICATION_MINI</Trans>,
        component: DriverMessageList
      },
    ]
  },
  {
    path: "/drivers/submissions/:phone",
    hideOnSidebar: true,
    name: <Trans>DRIVERS_MENU_NEW_SUBMISSION</Trans>,
    component: EditDriverSubmission,
  },
  {
    path: "/driver_edit",
    collapse: false,
    hideOnSidebar: true,
    name: <Trans>EDIT DRIVER</Trans>,
    icon: "pe-7s-user-female",
    component: DriverEdit
  },
  {
    path: "/driver_submission",
    collapse: false,
    hideOnSidebar: true,
    name: <Trans>DRIVER_SUBMISSION_DETAIL</Trans>,
    component: DriverSubmissionDetails
  },
  /*{
    collapse: true,
    path: "/business",
    name: <Trans>BUSINESS_MENU</Trans>,
    state: "openBusiness",
    icon: "pe-7s-rocket",
    views: [
      {
        path: "/business/list",
        name: <Trans>BUSINESS_LIST</Trans>,
        mini: <Trans>DASHBOARD_BUSINESS_LIST_MINI</Trans>,
        component: BusinessList
      },
      {
        path: "/business/form",
        name: <Trans>BUSINESS_NEW</Trans>,
        mini: <Trans>DASHBOARD_NEW_BUSINESS_MINI</Trans>,
        component: NewEditBusiness
      },
    ]
  },*/


  /*{
    collapse: true,
    path: "/restaurant",
    name: <Trans>RESTAURANT_MENU</Trans>,
    icon: "pe-7s-wine",
    views: [
      {
        path: "/restaurant/list",
        name: <Trans>RESTAURANT_LIST</Trans>,
        mini: <Trans>DASHBOARD_RESTAURANTS_LIST_MINI</Trans>,
        component: RestaurantList
      },
      {
        path: "/restaurant/form",
        name: <Trans>RESTAURANT_NEW</Trans>,
        mini: "N",
        component: NewEditRestaurant
      },
    ]
  },*/


  {
    collapse: true,
    path: "/payments",
    name: <Trans>Payments</Trans>,
    state: "openPayments",
    icon: "pe-7s-credit",
    views: [
      {
        path: "/payments/pending-payments",
        name: <Trans>Pending payments</Trans>,
        mini: <Trans>DASHBOARD_PENDING_PAYMENTS_MINI</Trans>,
        component: PendingCashout
      },
      {
        path: "/payments/payment-history",
        name: <Trans>Historical payments</Trans>,
        mini: <Trans>DASHBOARD_PAYMENT_HISTORY_MINI</Trans>,
        component: HistoricalCashout
      },
      {
        path: "/payments/cash-collection",
        name: <Trans>Cash Collection</Trans>,
        mini: <Trans>DASHBOARD_PAYMENT_CASH_COLLECTION_MINI</Trans>,
        component: CashCollection
      },
      {
        path: "/payments/invoices",
        name: <Trans>Invoices</Trans>,
        mini: <Trans>DASHBOARD_INVOICES_MINI</Trans>,
        component: Invoices
      }
    ]
  },
  /*{
    path: "/reports",
    collapse: false,
    hideOnSidebar: false,
    name: <Trans>REPORTS</Trans>,
    icon: "pe-7s-display2",
    component: Reports
  },*/
  /*{
    collapse: true,
    path: "/analytics",
    name: <Trans>Analytics</Trans>,
    icon: "pe-7s-note",
    state: "openAnalytics",
    views: [
      {
        path: "/analytics/report",
        name: <Trans>Analytics Reports</Trans>,
        mini: <Trans>DASHBOARD_ANALYTICS_REPORT_MINI</Trans>,
        component: Analytics
      },

      {
        path: "/analytics/management",
        name: <Trans>Analytics Management</Trans>,
        mini: <Trans>DASHBOARD_ANALYTICS_MANAGEMENT_MINI</Trans>,
        component: Management
      }
      

    ]
  },*/
  /*{
    path: "/analytics/dashboard-report",
    hideOnSidebar: true,
    name: <Trans>Analytics Management</Trans>,    
    component: dashboardReport
  },*/
  {
    path: "/not_found",
    hideOnSidebar: true,
    name: <Trans>404</Trans>,
    component: Error404
  },
  /*{
    collapse: true,
    path: "/logs",
    name: <Trans>LOGS</Trans>,
    state: "openLogs",
    icon: "pe-7s-note2",
    views: [
      {
        path: "/logs/token-transfers",
        name: <Trans>TOKEN_TRANSFER</Trans>,
        mini: <Trans>DASHBOARD_LOGS_TOKEN_TRANSFER_MINI</Trans>,
        component: ChainTransferTokens
      },
    ]
  },*/
  /*{
    path: "/pricing",
    collapse: false,
    hideOnSidebar: false,
    name: <Trans>PRICING</Trans>,
    icon: "pe-7s-cash",
    component: Pricing
  },*/
  /*{
    path: "/community_settings",
    collapse: false,
    hideOnSidebar: false,
    name: <Trans>COMMUNITY_SETTINGS</Trans>,
    icon: "pe-7s-settings",
    component: CommunitySettings
  },*/
  /*{
    path: "/developers",
    collapse: false,
    hideOnSidebar: false,
    name: <Trans>DEVELOPERS</Trans>,
    icon: "pe-7s-tools",
    component: DebugSettings
  },*/
];
export default dashboardRoutes;
