import * as actionType from '../actions/action_types'

const initialState = {
    tokenTransfersList: [],
}

export default function (state = initialState, action) {
    switch(action.type){
        case actionType.UPDATE_TOKEN_TRANSFER_LIST:
            return Object.assign({}, state, {
                tokenTransfersList: action.payload.tokenTransfersList,
            })
        case actionType.LOGOUT:
            return Object.assign({}, initialState);
        default:
            return state; 
    }
}