import React, { useEffect } from 'react';
import "./ProgressRing.css";

/**

 */

/**
 * ProgressRing
 * 
 * This code was base on this article: https://css-tricks.com/building-progress-ring-quickly/
 *
 * Prop list:
 * - progress: Pass the percentage of the actual progress. This one is mandatory.
 * - width: Default width is 120px by 120px. Another value will change the dimension of the ProgressRing.
 * - strokeWidth: It's the width of the circle. Default is 5.
 * - className: any class name you wanna apply to the parent wrapper div of the component.
 *
 */

function ProgressRing(props) {

    const progress = props.progress || 0;
    const width = props.width || 120;
    const strokeWidth = props.strokeWidth || 5;
    const radiusWithStroke = (width / 2);
    const radius = (width / 2) - (strokeWidth * 2);
    const circumference = radius * 2 * Math.PI;
    const offset = circumference - progress / 100 * circumference;
    const strokeColor = `hsl(${props.progress * 1.1}, 100%, 45%)`; //HSL from red to green
    const className = props.className? `progress-ring-wrapper ${props.className}` : 'progress-ring-wrapper';

    return (
        <div className={className} style={{ width: width, height: width }}>
            <svg className="progress-ring" width={width} height={width}>
                <circle
                    stroke="lightgrey"
                    strokeWidth={strokeWidth - 1}
                    fill="transparent"
                    r={radius}
                    cx={radiusWithStroke}
                    cy={radiusWithStroke}
                />
                <circle
                    className="progress-ring-circle"
                    stroke={strokeColor}
                    strokeWidth={strokeWidth}
                    fill="transparent"
                    r={radius}
                    cx={radiusWithStroke}
                    cy={radiusWithStroke}
                    strokeDasharray={`${circumference} ${circumference}`}
                    strokeDashoffset={offset}
                />
            </svg>
            <span style={{ lineHeight: `${width}px`, fontSize: `${width/5}px` }}>{progress}%</span>
        </div>
    );
}

export default ProgressRing;