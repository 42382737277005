import * as actionType from "../actions/action_types";

const initialState = []

const ExchangeRatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.UPDATE_EXCHANGE_RATES:
      return action.payload
    default:
      return state;
  }
};

export default ExchangeRatesReducer;
