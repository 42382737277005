import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Grid, Row, Col } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { getReportsList, generateReport } from "../../helpers/apiHelper";
import CustomButton from "../../components/CustomButton/CustomButton";
import Select from 'react-select';
import { DateRangePicker } from "react-dates";

class Reports extends Component {

    constructor(props) {
        super(props);
        this.state = {
          'selectedReportTypeKey': null,
          'showDates': false,
          'startDate': null,
          'endDate': null,
        }
        this.fetchReportOptions();
    }

    GET_DRIVER_NUMBER_OF_RIDES = "GET_DRIVER_NUMBER_OF_RIDES";
    GET_REVENUE_QUEBEC_MONTHLY_HUMAN_TRIPS = "GET_REVENUE_QUEBEC_MONTHLY_HUMAN_TRIPS";
    GET_PROMOTION_TOKEN_TRANSFERS = "GET_PROMOTION_TOKEN_TRANSFERS";
    GET_RIDERS_BALANCE = "GET_RIDERS_BALANCE";

    fetchReportOptions = async () => {
      const reportsType = await getReportsList();
      let reportOptions = [];
      for (var i = 0; i< reportsType.length; i++){
        reportOptions.push({value: reportsType[i], label: <Trans>{reportsType[i]}</Trans>});
      }
      this.setState({reportOptions});
    }

    onChooseReportType = (event) => {
      const selectedReportTypeKey = event ? event.value : null;
      console.log('selectedReportTypeKey', selectedReportTypeKey);
      if (selectedReportTypeKey == this.GET_DRIVER_NUMBER_OF_RIDES ||
          selectedReportTypeKey == this.GET_REVENUE_QUEBEC_MONTHLY_HUMAN_TRIPS ||
          selectedReportTypeKey == this.GET_PROMOTION_TOKEN_TRANSFERS ||
          selectedReportTypeKey == this.GET_RIDERS_BALANCE){
        this.showDates();
      }

      this.setState({selectedReportTypeKey});
    }

    showDates = () => {
      this.setState({
        showDates: true
      });
    }

    sendGenerateReport = async () => {
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;

      if (startDate) startDate = startDate.startOf('day').toISOString();
      // Add one day to the selected end date to include that day in the report
      if (endDate) endDate = endDate.clone().add(1, 'd').startOf('day').toISOString();
      
      await generateReport(this.state.selectedReportTypeKey, startDate, endDate);
    }

    setDatesInFilter = () => {
      console.log('setDatesInFilter!');
    }

    onDateFocusChange = (focusedInput) => {
      this.setState({ focusedInput });
    };

    render() {
      let generateReportButton = null;

      let datePickerComponents = null;
      if (this.state.showDates){
        datePickerComponents = (<Card
          plain
          ctTableFullWidth
          ctTableResponsive
          content={
            <DateRangePicker
              endDate={this.state.endDate}
              startDateId="startdate_id"
              endDateId="enddate_id"
              startDate={this.state.startDate}
              onDatesChange={({ startDate, endDate }) => {
                this.setState({ startDate, endDate });
              }}
              focusedInput={this.state.focusedInput}
              onFocusChange={this.onDateFocusChange}
              isOutsideRange={() => false}
              onClose={() => {
                this.setDatesInFilter();
              }}
            />
          }
        />);
      }

      if (this.state.selectedReportTypeKey != null){
        generateReportButton = <td>
          <CustomButton
            bsStyle="success"
            pullRight
            type="submit"
            onClick={() => {
              this.sendGenerateReport();
            }}
          >
            <Trans>GENERATE_BUTTON_TITLE</Trans>
          </CustomButton>
        </td>;
      }
      let pageContent = <div id="deactivateDiv">
        <table className="table">
          <tbody>
            <tr>
              <td>
                {datePickerComponents}
              </td>
            </tr>
            <tr>
              <td>
                <Select
                  id="select_report_type"
                  options={this.state.reportOptions}
                  onChange={event => this.onChooseReportType(event)}
                  value={this.state.selectedReportTypeKey}
                />
              </td>
              {generateReportButton}
            </tr>
          </tbody>
        </table>
      </div>


        return (
          <div className="content">
            <div className="main-content" >
              <Grid fluid>
                <Row>

                  <Col lg={12} sm={12}>
                    <Card
                        title={<Trans>REPORT_TYPE_CHOOSE</Trans>}
                        content= {pageContent}
                      />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>
        );
    }

}

export default Reports;