import { Col, Grid, Row } from "react-bootstrap";
import React, { Component } from "react";
import Card from "../../../components/Card/Card.jsx";
import RideRequestStatus from "../../../const/RideRequestStatus";
import RideServiceNames from "../../../const/RideServiceNames";
import TableAPI from "../../../views/Tables/TableAPI";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { getRideRequestHistorical } from "../../../helpers/apiHelper";
import { utcStringToLocalString } from "../../../helpers/dateHelper";
import { Link } from "react-router-dom";


class AllRequests extends Component {
  onDetailsClick = (rowObj) => {
    this.props.history.push({
      pathname: "/ride-request-summary",
      search: "?reqId=" + rowObj.original.ride_request_id,
    });
  };

  parseTableFromApi = async () => {
    this.setState({ customRideRequestHistoricalRequest: true });
    this.parseTable();
  };

  getDataFromStoreOrRedux = async () => {
    let rideRequestsHistorical = null;
    if (this.state.customRideRequestHistoricalRequest) {
      let start_datetime = this.state.startDate.format("YYYY-MM-DDThh:mm:ss");
      let end_datetime = this.state.endDate.format("YYYY-MM-DDThh:mm:ss");
      rideRequestsHistorical = await getRideRequestHistorical({
        start_datetime,
        end_datetime,
      });
    } else {
      rideRequestsHistorical = this.props.currentRideRequestHistorical;
    }
    return rideRequestsHistorical;
  };

  getStyleFromRow = (row) => {
    if (row) {
      const status = row.original.status;
      if (status === RideRequestStatus["SUCCESS"]) {
        return {
          style: { color: "green" },
        };
      } else if (status === RideRequestStatus["LIVE"]) {
        return {
          style: { color: "orange" },
        };
      } else if (status === RideRequestStatus["NOT_YET_ACCEPTED"]) {
        return {
          style: { color: "blue" },
        };
      } else {
        return {
          style: { className: "-highlight", color: "red" },
        };
      }
    } else {
      return {
        style: { className: "-highlight", color: "red" },
      };
    }
  };

  render() {
    let defaultView = (
      <div>
        <br />

        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                plain
                ctTableFullWidth
                ctTableResponsive
                content={
                  <TableAPI
                    fetchData={getRideRequestHistorical}
                    getTrProps={(state, rowInfo) => ({
                      onClick: () => this.onDetailsClick(rowInfo),
                    })}
                    columns={[
                      {
                        Header: <Trans>DATE</Trans>,
                        canFilter: true,
                        canSort: true,
                        defaultSort: true,
                        filterMode: "date",
                        id: "block_time",
                        width: 140,
                        accessor: (el) => {
                          return utcStringToLocalString(el.block_time);
                        },
                      },
                      {
                        Header: <Trans>REQUEST_STATUS</Trans>,
                        id: "status",
                        canFilter: true,
                        filterMode: "select",
                        options: [
                          { value: "", label: <Trans>NONE</Trans> },
                          { value: "SUCCESS", label: <Trans>SUCCESS</Trans> },
                          { value: "LIVE", label: <Trans>LIVE</Trans> },
                          { value: "NOT_YET_ACCEPTED", label: <Trans>NOT_YET_ACCEPTED</Trans> },
                          {
                            value: "NO_DRIVER",
                            label: <Trans>NO_DRIVER</Trans>,
                          },
                          {
                            value: "CANCELLED_BY_RIDER",
                            label: <Trans>CANCELLED_BY_RIDER</Trans>,
                          },
                          {
                            value: "CANCELLED_BY_DRIVER",
                            label: <Trans>CANCELLED_BY_DRIVER</Trans>,
                          },
                          {
                            value: "CANCELLED_BY_COMMUNITY",
                            label: <Trans>CANCELLED_BY_COMMUNITY</Trans>,
                          }
                        ],
                        accessor: (el) => {
                          return el.status;
                        },
                        width: 100,
                        Cell: (row) => <Trans>{row.value}</Trans>,
                        getProps: (state, row, column) => {
                          return this.getStyleFromRow(row);
                        },
                      },
                      {
                        Header: <Trans>RIDE_SERVICE_NAME</Trans>,
                        id: "ride_service_name",
                        canFilter: true,
                        filterMode: "select",
                        options: [{ value: "", label: <Trans>NONE</Trans> }].concat(
                          [0, 1, 3].map(rideServiceType => {
                            return {
                              value: rideServiceType,
                              label: <Trans>{RideServiceNames[rideServiceType]}</Trans>
                            }
                        })),
                        accessor: (el) => {
                          return el.ride_service_type_id;
                        },
                        Cell: (row) => (
                          <Trans>{RideServiceNames[row.value]}</Trans>
                        ),
                        width: 100,
                      },
                      {
                        Header: <Trans>DRIVER</Trans>,
                        canFilter: true,
                        canSort: true,
                        id: "driver_name",
                        accessor: (el) =>
                          el.driver.first_name + " " + el.driver.last_name,
                        width: 100,
                        Cell: (e) => (
                          <Link
                            onClick={(e) => e.stopPropagation()}
                            to={`/driver_edit?name=${e.original.driver_account}`}
                            target="_blank"
                            style={{ color: "#70CEEC", cursor: "pointer" }}
                            rel="noopener noreferrer"
                          >
                            {e.value}
                          </Link>
                        ),
                      },
                      {
                        Header: <Trans>DRIVER_PHONE</Trans>,
                        canFilter: true,
                        id: "driver_phone",
                        width: 100,
                        accessor: (el) => el.driver.phone,
                      },
                      {
                        Header: <Trans>RIDER</Trans>,
                        canFilter: true,
                        canSort: true,
                        id: "rider_name",
                        width: 120,
                        accessor: (el) =>
                          el.rider.first_name + " " + el.rider.last_name,
                        Cell: (e) => (
                          <Link
                            to={`/rider?name=${e.original.rider_account}`}
                            target="_blank"
                            style={{ color: "#70CEEC", cursor: "pointer" }}
                            rel="noopener noreferrer"
                          >
                            {e.value}{" "}
                          </Link>
                        ),
                      },
                      {
                        Header: <Trans>RIDER_PHONE</Trans>,
                        canFilter: true,
                        id: "rider_phone",
                        width: 100,
                        accessor: (el) => {
                          return el.rider.phone;
                        },
                      },
                      {
                        Header: <Trans>FROM_ADDRESS</Trans>,
                        canFilter: true,
                        id: "from_address",
                        width: 180,
                        accessor: (el) => {
                          if (el.path) {
                            return el.path.from_address;
                          } else {
                            return "";
                          }
                        },
                      },
                      {
                        Header: <Trans>TO_ADDRESS</Trans>,
                        canFilter: true,
                        id: "to_address",
                        width: 180,
                        accessor: (el) => {
                          if (el.path) {
                            return el.path.to_address;
                          } else {
                            return "";
                          }
                        },
                      }
                    ]}
                    className="-highlight"
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
        <p></p>
        <br></br>
        <br></br>
      </div>
    );

    return <div className="content">{defaultView}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    cityName: state.userData.cityName,
  };
};
export default connect(mapStateToProps)(AllRequests);

