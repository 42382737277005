import RideRequestStatus from "../const/RideRequestStatus";

export function getStatusFromRideRequest(rideRequest) {
    if (rideRequest.driver_accepted){
        if (rideRequest.ride_success){
            return RideRequestStatus['RIDE_REQUEST_SUCCESS'];
        } else {
            if (rideRequest.canceled_by_driver){
                return RideRequestStatus['RIDE_REQUEST_CANCELLED_BY_DRIVER'];
            } else if(rideRequest.canceled_by_rider){
                return RideRequestStatus['RIDE_REQUEST_CANCELLED_BY_RIDER'];
            } else {
                return RideRequestStatus['RIDE_REQUEST_CANCELLED'];
            }
        }
    } else {
        return RideRequestStatus['RIDE_REQUEST_NEVER_ACCEPTED'];
    }
}



export function getRiderName(rider) {
    if (typeof rider !== 'undefined' && rider != null){
        return rider.first_name + ' ' + rider.last_name;
    } else {
        return rider;
    }
}

export function getDriverName(driver) {
    if (typeof driver !== 'undefined' && driver != null){
        return driver.first_name + ' ' + driver.last_name;
    } else {
        return driver;
    }
}

export function getProfilePhone(rider) {
    let riderPhone;
    if (typeof rider !== 'undefined' && rider != null){
        riderPhone = rider.phone;
    } else {
        riderPhone = '---';
    }
    return riderPhone;
}

export function isUndefined(v) {
    return typeof v === 'undefined';
}

export function isDay() {
    const date = new Date();
    return date.getHours() > 6 && date.getHours() < 19;
}