import { Component } from 'react';
import React from 'react';
import { Trans } from "react-i18next";
import { convertToToken, convertToFiat } from '../../helpers/paymentHelper';
import { utcStringToLocalString } from "../../helpers/dateHelper";
import Popup from '../../components/Popup/Popup';
import {
    Button
} from "react-bootstrap";
import { getDeliveryProof, getRideRefunds } from '../../helpers/apiHelper';

export default class RideDetailsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showProofModal: false,
            proofPhotoBlob: null,
            refunds: []
        }
    }

    componentDidMount() {
        getDeliveryProof(this.props.rideObj.tracking_id)
            .then(response => new Blob([response.data], { type: response.headers["Content-Type"] }))
            .then(blob => this.setState({ proofPhotoBlob: blob }))
            .catch(error => {}); //silence if photo proof not found, not all rides do.
        getRideRefunds(this.props.rideObj.ride_request_id)
            .then(data => this.setState({refunds: data}));
        
    }

    showRider = (riderAccount) => {
        this.props.history.push({
            pathname: '/rider',
            search: '?name=' + riderAccount,
            state: { name: riderAccount }
        })
    }

    showDriver = (driverAccount) => {
        this.props.history.push({
            pathname: '/driver_edit',
            search: '?name=' + driverAccount,
            state: { name: driverAccount }
        })
    }

    showRideRequest = (rideRequestId) => {
        this.props.history.push({
            pathname: '/ride-request-summary',
            search: '?reqId=' + rideRequestId,
            state: { reqId: rideRequestId }
        })
    }

    formatTokens = (tokenAmount) => {
        return tokenAmount + " EVA"
    }

    render() {
        const rideObj = this.props.rideObj;
        const styleButton = {
            marginBottom: '5px',
            marginTop: '5px'
        };
        return (
            <div className="card">
                <div className="card-body">
                    <table className="table">
                        <thead>
                            <tr>
                                <th><h4><Trans>INFO_TABLE_TITLE</Trans></h4></th>
                                <th><h4><Trans>CHARGE_TABLE_TITLE</Trans></h4></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ verticalAlign: "top" }}>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <th><Trans>INFO_TABLE_START</Trans></th>
                                                <td>{(rideObj.start_time) ? utcStringToLocalString(rideObj.start_time) : "---"}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>INFO_TABLE_END</Trans></th>
                                                <td>{utcStringToLocalString(rideObj.end_time)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>INFO_TABLE_DESIRED_ONSITE</Trans></th>
                                                <td>{(rideObj.hasOwnProperty('archived_data')) ? utcStringToLocalString(rideObj.archived_data.desired_onsite_datetime) : "---"}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>INFO_TABLE_REAL_ONSITE</Trans></th>
                                                <td>{(rideObj.start_time) ? utcStringToLocalString(rideObj.driver_onsite_time) : "---"}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>INFO_TABLE_ONSITE_DELTA</Trans></th>
                                                <td>{Math.floor(((new Date(rideObj.start_time).getTime() - new Date(rideObj.driver_onsite_time).getTime()) / 1000) / 60)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>INFO_TABLE_DISTANCE</Trans></th>
                                                <td>{rideObj.distance_km}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>INFO_TABLE_FROM</Trans></th>
                                                <td>{rideObj.path.from_address}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>INFO_TABLE_TO</Trans></th>
                                                <td>{rideObj.path.to_address}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>INFO_TABLE_SUCC</Trans></th>
                                                <td>{(rideObj.canceled_flag === true) ? <Trans>NO</Trans> : <Trans>YES</Trans>}</td>
                                            </tr>
                                            <tr onClick={() => this.showDriver(rideObj.driver)} style={{ cursor: "pointer" }}>
                                                <th><Trans>INFO_TABLE_DRIVER</Trans></th>
                                                <td style={{ color: '#70CEEC' }}>{rideObj.driver_name}</td>
                                            </tr>
                                            <tr onClick={() => this.showRider(rideObj.rider)} style={{ cursor: "pointer" }}>
                                                <th><Trans>INFO_TABLE_RIDER</Trans></th>
                                                <td style={{ color: '#70CEEC' }}>{rideObj.rider_name}</td>
                                            </tr>
                                            <tr onClick={() => this.showRideRequest(rideObj.json_data)} style={{ cursor: "pointer" }}>
                                                <th><Trans>INFO_TABLE_RIDE_REQUEST</Trans></th>
                                                <td style={{ color: '#70CEEC' }}>{rideObj.json_data}</td>
                                            </tr>
                                            {this.state.proofPhotoBlob && <tr>
                                                <th><Trans>INFO_TABLE_PROOF</Trans></th>
                                                <td>
                                                    <Button color="primary" className="px-3" style={styleButton}
                                                        onClick={() => this.setState({ showProofModal: !this.state.showProofModal })}
                                                    ><Trans>
                                                            RIDE_REQ_STAT_CARD_HINT
                                                        </Trans></Button>
                                                    <Popup show={this.state.showProofModal} closeBackgroundClick={() => this.setState({ showProofModal: !this.state.showProofModal })} modalRef={this.state.modalRef}>
                                                        <div className="content">
                                                            <img style={{ height: "100%" }} src={this.state.proofPhotoBlob && URL.createObjectURL(this.state.proofPhotoBlob)} alt='delivery proof photo' />
                                                        </div>
                                                    </Popup>
                                                </td>
                                            </tr>}
                                            {(rideObj.ride_service_type_id == 1 || rideObj.ride_service_type_id == 2) && <tr>
                                                <th><Trans>Refunds</Trans></th>
                                                <td>
                                                {this.state.refunds.map(refund => {
                                                    return (
                                                    <p><b><Trans>CHARGE_TABLE_TOTAL</Trans></b>{convertToFiat(refund.amount_refund_token)} | {refund.processed ? "✔️" : "⌛"} <br/>{refund.note}</p> 
                                                )
                                                })}
                                                </td>
                                                
                                            </tr>}
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_TIP</Trans></th>
                                                <td>{convertToFiat(rideObj.tip_charge, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.tip_charge)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>TECHNOLOGY_FEE</Trans></th>
                                                <td>{convertToFiat(rideObj.technology_charge || 0, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.technology_charge || 0)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_MIN</Trans></th>
                                                <td>{convertToFiat(rideObj.min_charge, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.min_charge)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_PROMO</Trans></th>
                                                <td>{convertToFiat(rideObj.promotion_code_charge, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.promotion_code_charge)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_COMM</Trans></th>
                                                <td>{convertToFiat(rideObj.comm_charge, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.comm_charge)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_COMM_SUPPL</Trans></th>
                                                <td>{convertToFiat(rideObj.supplement_comm_charge, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.supplement_comm_charge)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_BASE</Trans></th>
                                                <td>{convertToFiat(rideObj.base_charge, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.base_charge)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_CREDIT</Trans></th>
                                                <td>{convertToFiat(rideObj.credit_card_charge, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.credit_card_charge)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_DIST</Trans></th>
                                                <td>{convertToFiat(rideObj.dist_charge, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.dist_charge)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_TIME</Trans></th>
                                                <td>{convertToFiat(rideObj.time_charge, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.time_charge)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_DRIVER</Trans></th>
                                                <td>{convertToFiat(rideObj.driver_charge, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.driver_charge)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_RIDER_ALGO</Trans></th>
                                                <td>{convertToFiat(rideObj.ride_algo_charge, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.ride_algo_charge)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_RIDE</Trans></th>
                                                <td>{convertToFiat(rideObj.ride_charge, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.ride_charge)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_FROM_ZONE</Trans></th>
                                                <td>{convertToFiat(rideObj.from_zone_charge, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.from_zone_charge)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_TO_ZONE</Trans></th>
                                                <td>{convertToFiat(rideObj.to_zone_charge, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.to_zone_charge)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_WORLD</Trans></th>
                                                <td>{convertToFiat(rideObj.world_charge, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.world_charge)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_WORLD_SUPPL</Trans></th>
                                                <td>{convertToFiat(rideObj.supplement_world_charge, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.supplement_world_charge)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_TOTAL</Trans></th>
                                                <td>{convertToFiat(rideObj.total_charge, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.total_charge)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_TAX</Trans></th>
                                                <td>{convertToFiat(rideObj.tax_charge, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.tax_charge)}</td>
                                            </tr>
                                            <tr>
                                                <th><Trans>CHARGE_TABLE_TOTAL_TAX</Trans></th>
                                                <td>{convertToFiat(rideObj.total_charge_with_tax, rideObj.end_time)}</td>
                                                <td>{this.formatTokens(rideObj.total_charge_with_tax)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}