import * as actionType from "../actions/action_types";
import { CommunitySettingCodes, CommunitySettingDefaults } from "../const/CommunitySettings";

const initialState = {};

const communityReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.UPDATE_COMMUNITY:
      if (typeof action.payload !== 'undefined' && action.payload !== null){
        let geoBoundsObj = "";
        try{
        geoBoundsObj = JSON.parse(action.payload.geo_bounds)
        }
        catch(error){
          console.log("Could not parse empty community geobounds in community reducer.");
          
          return initialState;
        }
        const coords = geoBoundsObj.features[0].geometry.coordinates[0]
        const communitySettings = JSON.parse(action.payload.settings);
      const currencySetting = communitySettings.find(e => e.code === CommunitySettingCodes.CURRENCY);
      const comm_currency_code = currencySetting && currencySetting.value || CommunitySettingDefaults.CURRENCY;
        return Object.assign({}, state, {
          comm_name: action.payload.comm_name,
          comm_currency_code: comm_currency_code,
          coords: coords
        })
      }
      else {
        return initialState;
      }
      case actionType.LOGOUT:
        return Object.assign({}, initialState);
      default:
        return state;
    }
  };
  
  export default communityReducer;