import React, { Component } from "react";
import TableAPI from "../../Tables/TableAPI";
import { Trans } from "react-i18next";
import { getNewDriverSubmissions, sendSubmissionEmailReminder } from "../../../helpers/apiHelper";
import { Button } from "react-bootstrap";
import { utcStringToLocalDateString, calculateElapsedTimeFromNow } from "../../../helpers/dateHelper";
import SubmissionStatusNames from "../../../const/SubmissionStatusNames";
import {
    getConfirmationAlert,
    getLoadingAlert,
    getSuccessAlert,
    getErrorAlert
} from "../../../helpers/alertHelper";
import { Redirect } from "react-router-dom"

class SubmissionsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            alert: null,
        };
    }

    getStatusOptions = () => {
        const options = [
            { value: '', label: <Trans>ALL</Trans> }
        ];

        for (const status_id in SubmissionStatusNames) {
            const option_object = { value: status_id, label: <Trans>{SubmissionStatusNames[status_id]}</Trans> }
            options.push(option_object);
        }
        return options
    }

    generateSendButton = (cellItem) => {
        const currentSubmissionStatus = SubmissionStatusNames[cellItem.original.status_id];
        const isDisabled = currentSubmissionStatus !== "OPEN" && currentSubmissionStatus !== "VALIDATED";
        return (
            <span onClick={(event) => { event.stopPropagation(); }}>
                <Button
                    style={{ margin: 0, height: 30 }}
                    onClick={() => {
                        this.handleSendBtnOnclick(cellItem)
                    }}
                    bsSize="small"
                    bsStyle="info"
                    disabled={isDisabled}
                >
                    <Trans>send</Trans>
                </Button>
            </span>
        )
    }

    handleSendBtnOnclick = (cellItem) => {
        const phone = cellItem.value;
        const { email } = cellItem.original;
        const full_name = cellItem.original.first_name + " " + cellItem.original.last_name
        const onConfirm = () => this.sendReminderRequest(phone);
        const onCancel = () => this.hideAlert();
        const confirmationBody = (
            <p>
                <Trans
                    i18nKey="SEND_REMINDER_ALERT_TEXT"
                    values={{ name: full_name, email: email }}
                />
            </p>
        );

        this.setAlert(getConfirmationAlert(onConfirm, onCancel, "Confirmation", confirmationBody));
    }

    sendReminderRequest = async (phone) => {
        // Little reminder here that we use the phone number as the ID to find the account in the database
        this.setAlert(getLoadingAlert());
        const onConfirm = () => this.hideAlert();
        const successTitle = <Trans>SUCCESS</Trans>;
        const successBody = <p><Trans>SEND_REMINDER_ALERT_SUCCESS_TEXT</Trans></p>;
        const errorTitle = <Trans>ERROR_ALERT</Trans>;
        const errorBody = <p><Trans>SEND_REMINDER_ALERT_ERROR</Trans></p>;
        
        await sendSubmissionEmailReminder(phone).then(() => {
            this.setAlert(getSuccessAlert(onConfirm, successTitle, successBody));
        }).catch(() => {
            this.setAlert(getErrorAlert(onConfirm, errorTitle, errorBody));
        });
    }

    setAlert = (alertToSet) => {
        this.setState({
            alert: alertToSet
        });
    }

    hideAlert = () => {
        this.setState({ alert: null });
    };

    onClickDetails = (phone) => {
        window.open(`#/drivers/submissions/${phone}`);
    }

    render() {
        return (
            <div>
                {this.state.alert}
                <TableAPI
                    fetchData={getNewDriverSubmissions}
                    topDistance="25px"
                    className="-highlight"
                    extraFilters={{
                        order_type: 'asc',
                        order_by: 'create_timestamp',
                        status_id: 5,
                    }}
                    pageSize={25}
                    columns={[
                        {
                            Header: <Trans>creation_date</Trans>,
                            id: "create_timestamp",
                            accessor: (el) => { return el.create_timestamp },
                            canFilter: true,
                            canSort: true,
                            // defaultSort: true,
                            filterMode: "date",
                            width: 160,
                        },
                        {
                            Header: <Trans>First Name</Trans>,
                            id: "first_name",
                            accessor: (el) => { return el.first_name },
                            canSort: true,
                            canFilter: true,
                            width: 125,
                        },
                        {
                            Header: <Trans>Last Name</Trans>,
                            id: "last_name",
                            accessor: (el) => { return el.last_name },
                            canSort: true,
                            canFilter: true,
                            width: 125,
                        },
                        {
                            Header: <Trans>Phone Number</Trans>,
                            id: "phone",
                            accessor: (el) => { return el.phone },
                            canFilter: true,
                            width: 150,
                        },
                        {
                            Header: <Trans>Email</Trans>,
                            id: "email",
                            accessor: (el) => { return el.email },
                            canFilter: true,
                            width: 150,
                        },
                        {
                            Header: <Trans>progress</Trans>,
                            id: "progress",
                            accessor: (el) => { return el.progress },
                            canSort: true,
                            width: 125,
                            Cell: row => (
                                <span>
                                    {
                                        row.value + "%"
                                    }
                                </span>
                            ),
                        },
                        {
                            Header: <Trans>status</Trans>,
                            id: "status_id",
                            accessor: (el) => { return el.status_id },
                            canFilter: true,
                            filterMode: "select",
                            width: 150,
                            options: this.getStatusOptions(),
                            Cell: row => (
                                <span>
                                    {
                                        <Trans>{SubmissionStatusNames[row.value]}</Trans>
                                    }
                                </span>
                            ),
                        },
                    ]}
                    getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: (e) => {
                                    this.onClickDetails(rowInfo.original.phone)
                                },
                            }
                        } else {
                            return {}
                        }
                    }}
                />
            </div>
        )
    }
}

export default SubmissionsList;