import "./RideRequestSummary.css"

import React, { Component } from "react";
import {
  getRideRequestHistoricalById,
  getRider,
} from "../../../../helpers/apiHelper";

import AnaTabs from '../../../../components/AnaTabs/AnaTabs';
import LoadingIcon from "../../../../views/Components/LoadingIcon";
import RequestDetails from "./RequestDetails/RequestDetails";
import RequestHistory from "./RequestsHistory/RequestHistory";
import { Trans } from "react-i18next";
import queryString from "query-string";

export default class RideRequestSummary extends Component {
  state = {
    rideReqDidLoad: false,
  };

  async componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    const reqId = params.reqId;
    const request = await this.getRideRequest(reqId);
    const ridepositions = [];
    const rider = await getRider(request.rider);
    this.setState({ reqId, request, rider, ridepositions });
  }

  componentDidUpdate() {
    if (!this.state.rideReqDidLoad && this.state.rider && this.state.request) {
      this.setState({ rideReqDidLoad: true });
    }
  }

  getRideRequest = async (rideRequestId) => {
    return await getRideRequestHistoricalById(rideRequestId);
  };

  render() {
    if (!this.state.rideReqDidLoad) {
      return <LoadingIcon />;
    }

    const details = (
      <RequestDetails
        rideRequest={this.state.request}
        rider={this.state.rider}
      />
    );

    const history = (
      <RequestHistory
        allRequest={this.state.request}
        history={this.state.request.drivers}
      />
    )

    let tabs = [
      {
        id: 1,
        name: <Trans>ALL_REQUESTS_DETAILS_TAB</Trans>,
        body: details
      },
      {
        id: 2,
        name: <Trans>ALL_REQUESTS_HISTORY_TAB</Trans>,
        body: history
      },
    ];

    const { request } = this.state;

    return (
      <div className="ride-request-summary main-content">
          <section className="header">
            <h1>
              <strong>
                <Trans>REQ_STATUS</Trans>: <Trans>{request.status}</Trans>
              </strong>
            </h1>
          </section>
          <section>
            {<AnaTabs content={tabs} />}
          </section>
      </div>
    );
  }
}

