import { store } from "../store/index";
import { localTimeZone } from "../variables/secretEnvVariables";
var moment = require('moment-timezone');

let storeState = store.getState();
store.subscribe(() => {
  storeState = store.getState();
});

interface CurrencyEntry {
  currency_code: string;
  currency_value: number;
  effective_from: string;
}

function convertToFiat(
  tokenAmount: number,
  utcDatetimeString: string = new Date().toISOString(),
  getAsString: boolean = true
): string | number {
  const currencyCode: string = storeState.community.comm_currency_code;
  /*const exchangeRate = storeState.exchangeRates
    .filter(
      (e: CurrencyEntry) => moment.utc(e.effective_from).tz(localTimeZone).valueOf() < moment.utc(utcDatetimeString).tz(localTimeZone).valueOf()
    )
    .sort(
      (a: CurrencyEntry, b: CurrencyEntry) =>
        new Date(Date.parse(b.effective_from)).getTime() -
        new Date(Date.parse(a.effective_from)).getTime()
    )[0]*/
  //const fiatAmount: number = (tokenAmount * exchangeRate.currency_value / 100);
  const fiatAmount: number = (tokenAmount * 1 / 100);

  if (getAsString) {
    return new Intl.NumberFormat("en-CA", {
      style: "currency",
      currency: currencyCode,
    }).format(fiatAmount);
  } else {
    return fiatAmount;
  }
}

function convertToToken(
    fiatAmount: number,
    utcDatetimeString: string = new Date().toISOString(),
    getAsString: boolean = true
  ): string | number {
      // const exchangeRate = storeState.exchangeRates
      //.filter(
      //  (e: CurrencyEntry) => moment.utc(e.effective_from).tz(localTimeZone).valueOf() < moment.utc(utcDatetimeString).tz(localTimeZone).valueOf()
      //)
      //.sort(
      //  (a: CurrencyEntry, b: CurrencyEntry) =>
      //    new Date(Date.parse(b.effective_from)).getTime() -
      //    new Date(Date.parse(a.effective_from)).getTime()
      //)[0]
    //const tokenAmount: string = Math.round((fiatAmount * (1/exchangeRate.currency_value) * 100)).toFixed(4);
    const tokenAmount: string = Math.round((fiatAmount * (1/1) * 100)).toFixed(4);
  
    if (getAsString) {
      return tokenAmount + " EVA";
    } else {
      return parseFloat(tokenAmount)
    }
  }

  function formatQuote(
    totalCharge: number,
    currencyCode: string = storeState.community.comm_currency_code
  ): string {
    return new Intl.NumberFormat("en-CA", {
        style: "currency",
        currency: currencyCode,
      }).format(totalCharge / 100);
  }

  function getSymbol(): string {
    return formatQuote(0).replace(/[\d\., ]/g, '');
  }

export { convertToFiat, convertToToken, formatQuote, getSymbol };

