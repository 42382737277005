import React, { Component } from "react";

import TableAPI from "../Tables/TableAPI";
import { Trans } from "react-i18next";
import { getUsers,  IsFranchiseAdmin } from "../../helpers/apiHelper";


export default class Management extends Component {
    constructor(props) {
        super(props);
       
     this.checkFranchise();
       
    }

   async  checkFranchise()
    {
        let response = false;
        response = await IsFranchiseAdmin();
        
        if (response==false) {
            this.props.history.push("/analytics/report");
        }
    }
    onClickDetail = (email) => {
        window.open(`#/user/?email=${email}`);
    }
    


    render() {

        return (
          
            
        <div>
          <div>
            
          </div>
          <div id="container">
          </div>
         
                                <TableAPI
                                    fetchData={getUsers}
                                    columns={[
                                        
                                        
                                        {
                                            Header: <Trans>email</Trans>,
                                            canFilter: true,
                                            id: 'email',
                                            width: 220,
                                            accessor: (el) => {return el.email}
                                        },
                                        {
                                            Header: <Trans>First Name</Trans>,
                                            canFilter: true,
                                            canSort: true,
                                            id: 'first_name',
                                            width: 220,
                                            accessor: (el) => {return el.first_name}
                                        },
                                        {
                                            Header: <Trans>Last Name</Trans>,
                                            canFilter: true,
                                            canSort: true,
                                            id: 'last_name',
                                            width: 220,
                                            accessor: (el) => {return el.last_name}
                                        }

                ]}
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                        return {
                            onClick: (e) => {
                                this.onClickDetail(rowInfo.original.email)
                            },
                            style: {
                                cursor: "pointer"
                            }
                        }
                    } else {
                        return {}
                    }
                }}
              
                className="-highlight"

            /> </div>
        )
    }
}
