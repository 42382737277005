import React, { Component } from "react";

import DriverOnlineSessions from "./DriverOnlineSessions";

class DriverStatsFull extends Component {

    render() {
        return (
            <div>
                <DriverOnlineSessions accountName={this.props.accountName} />
            </div>
        );
    }

}

export default DriverStatsFull;