import { Button, Col, Grid, Row } from "react-bootstrap";
import React, { Component } from "react";
import { getDrivers, sendCommunicationTask } from "../../helpers/apiHelper";

import Card from "../../components/Card/Card.jsx";
import CommunicationTaskType from "../../const/CommunicationTaskType";
import CustomButton from "../../components/CustomButton/CustomButton";
import ReactTable from "react-table";
import Select from 'react-select';
import SweetAlert from "react-bootstrap-sweetalert";
import { Trans } from "react-i18next";
import { connect } from 'react-redux';
import i18n from 'i18next';

class DriverMessageList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            driversList: [],
            selectedDrivers: [],
            currentSmsMessage: '',
            currentNotificationMessage: '',
        };
        this.fetchDrivers();
    } 
        
    handleEntryCheck(event, driver){
        let array = [...this.state.selectedDrivers];
        if(event.target.checked && !array.includes(driver)){
            array.push(driver);
        } else if (!event.target.checked && array.includes(driver)){
            array.splice(array.indexOf(driver), 1);
        }
        this.setState({
            selectedDrivers: array,
        })
    }

    hideAlert() {
        this.setState({
          alert: null
        });
    }

    onSendCommClick = async (taskType) => {
      let message = "";
      switch (taskType) {
        case CommunicationTaskType.TYPE_NOTIFICATION: 
            message = this.state.currentNotificationMessage;
            break;
        case CommunicationTaskType.TYPE_SMS:
            message = this.state.currentSmsMessage;
            break;
        case CommunicationTaskType.TYPE_EMAIL_CUSTOM:
            message = this.state.currentEmailMessage;
            break;
        case CommunicationTaskType.TYPE_EMAIL_COVID_CERT:
            break;
        default:
          throw Error("Invalid Communication Task Type!");
      }

      const response = await sendCommunicationTask(this.state.selectedDrivers, message, taskType);

        let alert = null;
        if (response.success){
            alert = <SweetAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Bravo!"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="info"
            >
              <p>
                <Trans>SUCCESSFUL</Trans>
              </p>
            </SweetAlert>
        } else {
          alert = <SweetAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Error"
            onConfirm={() => this.hideAlert()}
            confirmBtnBsStyle="info"
          >
            <p>
              <Trans>ERROR_ALERT</Trans>
            </p>
          </SweetAlert>
      }
        this.setState({alert});
    }


    fetchDrivers = async () => {
        const driversList = Object.values(await getDrivers(100,100000, null));
        this.setState({driversList})
    }
    
    selectAll(){
        let array = [];
        const {driversList} = this.state;
        for (var i = 0;i < driversList.length;i++){
            array.push(driversList[i].account);
        }
        this.setState({
            selectedDrivers: array,
        })
    }

    render() {
        return (
            <div className="content">
                {this.state.alert}
                <Grid fluid>
                    <br />
                    <Row>
                        <Col lg={12} sm={12}>
                            <Card
                              title={<Trans>COMMUNICATION_TITLE</Trans>}
                              content={
                                <table className="table">
                                  <tbody>
                                    <tr>
                                      <td lg={3} sm={3}>
                                        <Trans>ACTION_SEND_SMS</Trans>
                                      </td>
                                      <td td lg={6} sm={6}>
                                        <div>
                                          <textarea
                                            class="form-control"
                                            rows="6"
                                            onChange={(event) => {
                                              const currentSmsMessage = event.target.value;
                                              this.setState({currentSmsMessage});
                                            }}
                                        ></textarea>
                                        </div>
                                      </td>
                                      <td>
                                        <CustomButton
                                          bsStyle="success"
                                          pullRight
                                          type="submit"
                                          onClick={() => {
                                            this.onSendCommClick(CommunicationTaskType.TYPE_SMS);
                                          }}
                                        >
                                          <Trans>SEND</Trans>
                                        </CustomButton>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td lg={3} sm={3}>
                                        <Trans>ACTION_SEND_NOTIFICATIONS</Trans>
                                      </td>
                                      <td td lg={6} sm={6}>
                                        <div>
                                          <textarea
                                            class="form-control"
                                            rows="6"
                                            maxlength="135"
                                            onChange={(event) => {
                                              const currentNotificationMessage = event.target.value;
                                              this.setState({currentNotificationMessage});
                                            }}
                                        ></textarea>
                                        </div>
                                      </td>
                                      <td>
                                        <CustomButton
                                          bsStyle="success"
                                          pullRight
                                          type="submit"
                                          onClick={() => {
                                            this.onSendCommClick(CommunicationTaskType.TYPE_NOTIFICATION);
                                          }}
                                        >
                                          <Trans>SEND</Trans>
                                        </CustomButton>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td lg={3} sm={3}>
                                        <Trans>ACTION_SEND_EMAIL</Trans>
                                      </td>
                                      <td td lg={6} sm={6}>
                                        <div>
                                          <textarea
                                            class="form-control"
                                            rows="6"
                                            onChange={(event) => {
                                              const currentEmailMessage = event.target.value;
                                              this.setState({currentEmailMessage});
                                            }}
                                        ></textarea>
                                        </div>
                                      </td>
                                      <td>
                                        <CustomButton
                                          bsStyle="success"
                                          pullRight
                                          type="submit"
                                          onClick={() => {
                                            this.onSendCommClick(CommunicationTaskType.TYPE_EMAIL_CUSTOM);
                                          }}
                                        >
                                          <Trans>SEND</Trans>
                                        </CustomButton>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td lg={3} sm={3}>
                                        <Trans>ACTION_SEND_TEMPLATED_EMAIL</Trans>
                                      </td>
                                      <td td lg={6} sm={6}>
                                        <div>
                                          <Select value={this.state.templatedEmailChoice} onChange={(row) => { this.setState({templatedEmailChoice: row.value}) }} style={{marginTop: 45, marginBottom: 45}} options={[{value: CommunicationTaskType.TYPE_EMAIL_COVID_CERT, label: i18n.t('COVID_CERT')}]} />
                                        </div>
                                      </td>
                                      <td>
                                        <CustomButton
                                          bsStyle="success"
                                          pullRight
                                          type="submit"
                                          onClick={() => {
                                            this.onSendCommClick(this.state.templatedEmailChoice);
                                          }}
                                        >
                                          <Trans>SEND</Trans>
                                        </CustomButton>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              }
                            />
                        </Col>
                    </Row>
                    <br />
                    <Col lg={12} sm={12}>
                        <Button onClick={() => {
                            this.selectAll();
                        }}><Trans>SELECT_ALL</Trans></Button>
                    </Col>
                    <br />
                    <Row>
                        <Col md={12}>

                            <Card
                                plain
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <ReactTable
                                    previousText={i18n.t('PREVIOUS')} nextText={i18n.t('NEXT')} loadingText={i18n.t('LOADING')}
                                        data={this.state.driversList}
                                        filterable
                                        columns={[
                                            {
                                                Header: <Trans>phone</Trans>,
                                                id:"phone",
                                                accessor: (driver) => {
                                                    return driver.profile.phone;
                                                }
                                            },
                                            {
                                                Header: <Trans>Email address</Trans>,
                                                id:"email",
                                                accessor: (driver) => {
                                                    return driver.profile.email;
                                                }
                                            },
                                            {
                                                Header: <Trans>First Name</Trans>,
                                                id: "first_name",
                                                accessor: (driver) => {
                                                    return driver.profile.first_name;
                                                }
                                            },
                                            {
                                                Header:<Trans>Last Name</Trans>,
                                                id:"last_name",
                                                accessor: (driver) => {
                                                    return driver.profile.last_name;
                                                },
                                                filterable: false
                                            },
                                            {
                                                Header:<Trans>select</Trans>,
                                                id:"select",
                                                accessor: (driver) => {
                                                    let checkbox = <input type="checkbox"
                                                    
                                                    checked={this.state.selectedDrivers.includes(driver.account)}
                                                    onChange={(event) => this.handleEntryCheck(event, driver.account)}>
                                                    </input>
                                                    return checkbox;
                                                },
                                                filterable: false
                                            },
                                        ]}
                                        defaultPageSize={15}
                                        showPaginationTop
                                        showPaginationBottom={false}
                                        className="-highlight"

                                        
                                    />
                                    
                                }
                            />
                            
                        </Col>
                        
                    </Row>
                </Grid>
                <p></p> 
                <br></br>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
      auth: state.userData.isAuthenticated,
      pubKey : state.userData.publicKey,
      privKey: state.userData.privateKey,
      community: state.userData.communityAccount,
      cityName: state.userData.cityName,
    };
  };

export default connect(mapStateToProps)(DriverMessageList);
