import './LoginPage.css';

import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Row
} from "react-bootstrap";
import React, { Component } from "react";

import Card from "../../components/Card/Card.jsx";
import CustomButton from "../../components/CustomButton/CustomButton.jsx";
import NotificationSystem from 'react-notification-system';
import { Trans } from "react-i18next";
import { loginSuccess } from '../../actions/index';
import { signin, authenticateUser } from "../../helpers/apiHelper";
import { store } from "../../store/index";
import { style } from "../../variables/Variables";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      _notificationSystem: null,
      phone: "",
      verificationCode: "",
      verificationStep: false,
      sessionInfo: null,
    };
    this.handleSubmit.bind(this);
    this.handleChangeEmail.bind(this);
    this.handleChangePassword.bind(this);
  }
  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
    this.setState({ _notificationSystem: this.refs.notificationSystem })
  }

  handleChangeVerificationCode = (e) => {
    this.setState({ verificationCode: e.target.value });
  };

  handleChangePhone = (phone) => {
    this.setState({ phone });
  };

  handleVerify = async () => {
    // Placeholder for sending the verification code to the backend
    /*const { verificationCode, sessionInfo } = this.state;
    //const response = await verifyCode(verificationCode, sessionInfo); // Assuming verifyCode is a function that communicates with the backend
    
    if (response.success) {
      // Handle successful verification, e.g., navigate to the dashboard or display a success message.
      store.dispatch(loginSuccess({
        authToken: response.token,
        // ... other necessary details
      }));
    } else {
      this.handleError();
    }*/
    try {
      const token = parseInt(this.state.verificationCode, 10);
      let phone = "+"+this.state.phone;
      const response = await authenticateUser(phone, token);
      // Handle the response. Maybe update the state or redirect the user.
      if (response.status == "success"){
        store.dispatch(loginSuccess({
          authToken: response.bearer_token,
          phone: phone
          // ... other necessary details
          //communityAccount: communityAccount,
          //firstName: firstName,
          //lastName: lastName,
          //userId: userId
        }));
      } else {
        this.handleError();
      }
      
    } catch (error) {
      // Handle the error. Maybe show an error message to the user.
      console.error("Error authenticating user: ", error);
      this.handleError();
    }
  };
  handleChangeEmail(e) {
    let email = e.target.value;
    this.setState({ email });
  };

  handleChangePassword(e) {
    let password = e.target.value;
    this.setState({ password });
  };

  handleError = () => {
    this.state._notificationSystem.addNotification({
      message: (
        <div><Trans>LOGIN_ERROR</Trans></div>
      ),
      level: 'error',
      position: 'tc',
      autoDismiss: 10,
    });
  }

  handleSignin = async () => {
    const { email, phone } = this.state;
    let phoneGood = "+"+ phone;
    const { success, sessionInfo } = await signin(email, phoneGood);

    if (success) {
      this.setState({ verificationStep: true, sessionInfo });
    } else {
      this.handleError();
    }
  };

  handleSubmit = () => {
    if (this.state.verificationStep) {
      this.handleVerify();
    } else {
      this.handleSignin();
    }
  };

  render() {

    const verificationStepContent = (
      <div>
        <FormGroup>
          <ControlLabel><Trans>Verification Code</Trans></ControlLabel>
          <FormControl
            placeholder="Verification Code"
            type="text"
            value={this.state.verificationCode}
            onChange={this.handleChangeVerificationCode}
          />
        </FormGroup>
        <FormGroup>
          <CustomButton
            onClick={this.handleSubmit}
            bsStyle="warning"
            fill
            wd
          >
            <Trans>Verify</Trans>
          </CustomButton>
        </FormGroup>
      </div>
    );

    const signinStepContent = (
      <div>
        {/* ... (previous signin form fields) */}
        <FormGroup>
          <ControlLabel><Trans>Phone</Trans></ControlLabel>
          <PhoneInput
            country={'ca'}
            masks={{"ci": '.. .. .. .. ..'}}
            value={this.state.phone}
            onChange={this.handleChangePhone}
            placeholder="Enter phone number"
          />
        </FormGroup>
        <FormGroup>
          <CustomButton
            onClick={this.handleSubmit}
            bsStyle="warning"
            fill
            wd
          >
            <Trans>Sign In</Trans>
          </CustomButton>
        </FormGroup>
      </div>
    );

    return (
      <Grid>
        <NotificationSystem ref="notificationSystem" style={style} />
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form id="loginForm" onKeyPress={(e) => { if (e.key === 'Enter') { this.handleSubmit() } }}>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Login"
                content={this.state.verificationStep
                  ? verificationStepContent
                  : signinStepContent}
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default LoginPage;
