import "./RideServiceSelection.css"

import { Button, Col, Grid, Row } from "react-bootstrap";
import React, {Component} from 'react';
import {
  getQuote,
  getRideServicesByType,
} from "../../helpers/apiHelper"
import { formatQuote } from "../../helpers/paymentHelper";

import { RideTypeIds } from "../../const/RideServiceNames"
import { Trans } from "react-i18next";

export default class RideServiceSelection extends Component {

    state = {
        buttons: [],
        selectedRideServiceId: null,
    }

    async componentDidMount() {
        const rideServiceIdsMap = await getRideServicesByType();

        const buttons = [];

        for (const [rideServiceTypeId, rideServiceIds] of Object.entries(rideServiceIdsMap)) {
            for (let rideServiceId of rideServiceIds) {
                buttons.push({
                    service_id: parseInt(rideServiceId),
                    type_id: parseInt(rideServiceTypeId)
                });
            }
        }
        this.setState({buttons: buttons});
        this.setNewPrice(this.props.rideServiceId, this.props.rideServiceTypeId);
    }

    async componentDidUpdate(prevProps) {
      const {props} = this;
        if (props.toLat !== prevProps.toLat 
                || props.toLon !== prevProps.toLon
                || props.fromLat !== prevProps.fromLat
                || props.fromLon !== prevProps.fromLon) {
          this.setNewPrice(this.props.rideServiceId, false);
        }
    }

    getRideServiceBtn = (id, type) => {
      let translation = null;
      switch (type) {
        case RideTypeIds.RIDE_SHARING:
          translation = <Trans>RIDESHARING</Trans>;
          break;
        case RideTypeIds.DELIVERY:
          translation = <Trans>DELIVERY</Trans>;
          break;
        case RideTypeIds.CANNABIS:
          translation = <Trans>CANNABIS</Trans>;
          break;
        case RideTypeIds.BULK:
          translation = <Trans>BULK</Trans>;
          break;
        case RideTypeIds.RIDE_SHARING_MOTO:
          translation = <Trans>RIDE_SHARING_MOTO</Trans>;
          break;
        case RideTypeIds.DELIVERY_TRICYCLE:
          translation = <Trans>DELIVERY_TRICYCLE</Trans>;
          break;
        case RideTypeIds.RIDE_SHARING_TRICYCLE:
          translation = <Trans>RIDE_SHARING_TRICYCLE</Trans>;
          break;
        case RideTypeIds.SHUTTLE:
          translation = <Trans>SHUTTLE</Trans>;
          break;
        default:
          break; //Unreachable
      }
      return (
        <span style={{ marginTop: "0px", marginLeft: "5px" }}>
          <Button style={{ marginTop: "0px", backgroundColor: id === this.state.selectedRideServiceId && "rgb(235, 205, 34)"}}
            id={"serviceBtn_" + id}
            onClick={() => this.setNewPrice(id)}
          >
            <p style={{ marginTop: 0, marginBottom: 0 }}>{translation}</p>
          </Button>
        </span>
      );
    };

    setNewPrice = async (rideServiceId, rideServiceTypeId, antiSpam=true) => {
        if (this.state.selectedRideServiceId === rideServiceId && antiSpam) {return; }
        await getQuote(
          this.props.fromLat,
          this.props.fromLon,
          this.props.toLat,
          this.props.toLon,
          rideServiceId
        ).then(result => {
          if(isNaN(result.total_charges_plus_tax)) {
              this.props.onError(new Error("NAN"));
              return;
          }
          const price = formatQuote(result.total_charges_plus_tax);
          this.props.onSuccess(rideServiceId, rideServiceTypeId, price);
        })
        .catch((error) => this.props.onError(error));    
        this.setState({ selectedRideServiceId: rideServiceId });
      }

    render() {
        return (
            <div>
              <Grid style={{ textAlign: "center", width: "auto" }}>
                <Row className="align-items-center">
                  <div>
                    <Col md={12} xs={12} style={{ textAlign: "center" }}>
                      <div className={"ride-choice"} style={{ margin: "auto" }}>
                        {this.state.buttons.map((button) => {
                          return this.getRideServiceBtn(
                            button.service_id,
                            button.type_id,
                          );
                        })}
                      </div>
                    </Col>
                  </div>
                </Row>
              </Grid>
            </div>
          );
        };
    }