import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Trans } from "react-i18next";
import i18n from "i18next";

export function getLoadingAlert() {
  return (
    <SweetAlert
      style={{ display: "block" }}
      title={<Trans>SENDING_REQUEST</Trans>}
      closeOnClickOutside={false}
      allowEscape={false}
      showCancel={false}
      showConfirm={false}
      onConfirm={() => {}}
    >
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </SweetAlert>
  );
}

export function getConfirmationAlert(onConfirm, onCancel, title, body) {
  return (
    <SweetAlert
      warning
      style={{
        display: "block",
      }}
      title={title}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="danger"
      confirmBtnText={i18n.t("CONFIRM")}
      cancelBtnText={i18n.t("CANCEL")}
      showCancel
      showLoaderOnConfirm
    >
      {body}
    </SweetAlert>
  );
}

export function getSuccessAlert(onConfirm, title, body) {
  return (
    <SweetAlert
      success
      style={{ display: "block" }}
      title={title}
      onConfirm={onConfirm}
      confirmBtnBsStyle="success"
      showCancel={false}
    >
      <p>{body}</p>
    </SweetAlert>
  );
}

export function getErrorAlert(onConfirm, error, message) {
  return (
    <SweetAlert
      style={{ display: "block" }}
      danger
      title={error || i18n.t("ERROR")}
      onConfirm={onConfirm}
      confirmBtnBsStyle="success"
    >
      <p>{message || <Trans>ERROR</Trans>}</p>
    </SweetAlert>
  );
}

