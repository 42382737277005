import "react-select/dist/react-select.css";

import {
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Grid,
    Row,
} from "react-bootstrap";
import React, { Component } from "react";
import { deleteDriver, deleteDriverNote, editDriver, getCommunity, getCommunitySettings, getDriver, getDriverFormTemplate, getDriverNotes, getRideServiceInfo, newDriver, saveDriverNote, switchDriverFlag, resetDriverStats } from '../../helpers/apiHelper';

import COMM_CONSTANTS from '../../views/Community/CommunityConstants';
import Card from "../Card/Card.jsx";
import CommentBox from "../../components/CommentBox/CommentBox";
import CustomButton from "../CustomButton/CustomButton";
import DriverPresetNotes from "../../const/DriverPresetNotes";
import DriverStatsFull from "../../views/DriverStatistics/DriverStatsFull";
import { FormInputs } from "../FormInputs/FormInputs";
import RideServiceNames from '../../const/RideServiceNames';
import SweetAlert from "react-bootstrap-sweetalert";
import { Trans } from "react-i18next";
import VehicleTypes from "../../const/VehicleTypes";
import { connect } from 'react-redux';
import i18n from 'i18next';
import { utcStringToLocalString } from "../../helpers/dateHelper";
import { withRouter } from "react-router";

class DriverInfoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            error: '',
            driverInfo: {
                community_id: this.props.community,
                phone: "",
                eosAccount: "",
                email: "",
                driver_licence_number: "",
                financial_institution_name: "",
                financial_institution_number: "",
                transit_number: "",
                account_number: "",
                first_name: "",
                last_name: "",
                taxes_number: {},
                showAutoFillField: false,
                autoFillValue: "",
                additional: []
            },
            availableServices: [],
            availbleVehicles: [],
            customButtonDisabled: false,
            actionLoading: false,
            additionalFields: [],
            isDriverFlagged: false,
            notes: [],
            newNote: "",
            driverVehicles: [{
                vehicle_brand: "",
                vehicle_model: "",
                vehicle_year: "",
                vehicle_color: "",
                vehicle_registration_plate: "",
                vehicle_mileage: "",
                vehicle_identification_number: "",
                vehicle_registration_certificate_file_number: "",
                vehicle_allowed_multi: true,
                allowed_ride_services: [],
            }]
        }
    }

    componentWillMount = async () => {
        if (this.props.auth) {
            await this.loadRideServiceInfo();    
            if (this.props.titleCard === 'Edit') {
                await this.loadDriverInfo(this.props.selectedDriver);
                await this.loadAdditionalFields();
            } else {
                this.getCommunityTaxInfo();
                this.addAutoFillField();
                this.loadAdditionalFields();
            }
            // const notes = await getDriverNotes(this.props.selectedDriver);
            // this.setState({notes});
        } else {
            this.props.history.push('/');
        };
    };

    async loadAdditionalFields() {
        let additionalFields = await getDriverFormTemplate("community-manager");
        if (Object.keys(additionalFields).length === 0) {return;}
        additionalFields = additionalFields.replace(/'/g, "\"");
        additionalFields = JSON.parse(additionalFields);
        if (this.state.driverInfo.additional != null) {
            for (let field of additionalFields) {
                const a = this.state.driverInfo.additional.find(field2 => field.name == field2.name);
                if (a) field.value = a.value;
            }
        }
        this.setState({additionalFields});
    }

    loadRideServiceInfo = async () => {
        const RideServiceInfo = await getRideServiceInfo();
        let communitySettings = await getCommunitySettings(COMM_CONSTANTS.VEHICLE_RIDE_SERVICES);
        if (typeof communitySettings !== "undefined" && communitySettings !== null && typeof communitySettings.value !== 'undefined') {
            communitySettings = communitySettings.value;
        }
        this.setState({ communitySettings, availableServices: RideServiceInfo.available_services, availableVehicles: RideServiceInfo.available_vehicles });
    }

    hideAfterconfirmAlert() {
        this.setState({
            alert: null
        });
        window.location.reload();
    };

    hideAlert() {
        this.setState({
            alert: null
        });
    };

    goActiveDriverPage() {
        //redirect user to active drivers page
        this.setState({
            alert: null
        });
        this.props.history.push('/drivers/active-drivers');
        window.location.reload();
    }

    errorHandlerAlert = (error) => {
        console.log("error", error);
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error"
                    onConfirm={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    <p>An Error has occurred : {error.code + " " + error.message}</p>
                </SweetAlert>
            )
        });
    };

    editDriverHandlerAlert = () => {
        this.setState({
            alert: (
                <SweetAlert
                    default
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Sending data"
                    onConfirm={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    <div class="spinner">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                    <p>
                        <Trans>Add new driver</Trans>
                    </p>                </SweetAlert>
            )
        })
    }

    confimRegistredAccountAlert = (accounData) => {
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="New Driver"
                    onConfirm={() => this.hideAfterconfirmAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    <p>The account <b>{accounData}</b> has been added successfully as driver to <b>{this.props.cityName}</b> community.</p>
                </SweetAlert>
            )
        });
    }

    confimEditAccountAlert = (eosAccount) => {
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Edit Driver"
                    onConfirm={() => this.hideAfterconfirmAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    <p>The account <b>{eosAccount}</b> has been successfully modified.</p>
                </SweetAlert>
            )
        });
    }

    confimDeleteAccountAlert = (accounData) => {
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Edit Driver"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    <p>The old EosAccount <b>{accounData}</b> of the driver has been successfully deleted from the drivers list of <b>{this.props.cityName}</b> community.</p>
                </SweetAlert>
            )
        });
    }

    handlePhoneNumber(e) {
        let value = e.target.value;
        let currentStateDriverInfo = { ...this.state.driverInfo };
        currentStateDriverInfo.phone = value;
        this.setState({ driverInfo: currentStateDriverInfo });
    };

    handleEosAccount(e) {
        let value = e.target.value;
        let currentStateDriverInfo = { ...this.state.driverInfo };
        currentStateDriverInfo.eosAccount = value;
        this.setState({ driverInfo: currentStateDriverInfo });
    };

    handleEmail(e) {
        let value = e.target.value;
        let currentStateDriverInfo = { ...this.state.driverInfo };
        currentStateDriverInfo.email = value;
        this.setState({ driverInfo: currentStateDriverInfo });
    };

    handleDriverLicenceNumber(e) {
        let value = e.target.value;
        let currentStateDriverInfo = { ...this.state.driverInfo };
        currentStateDriverInfo.driver_licence_number = value;
        this.setState({ driverInfo: currentStateDriverInfo });
    };

    handleFinancialInstituteName(e) {
        let value = e.target.value;
        let currentStateDriverInfo = { ...this.state.driverInfo };
        currentStateDriverInfo.financial_institution_name = value;
        this.setState({ driverInfo: currentStateDriverInfo });
    };

    handleFinancialInstituteNumber(e) {
        let value = e.target.value;
        let currentStateDriverInfo = { ...this.state.driverInfo };
        currentStateDriverInfo.financial_institution_number = value;
        this.setState({ driverInfo: currentStateDriverInfo });
    };

    handleTransitNumber(e) {
        let value = e.target.value;
        let currentStateDriverInfo = { ...this.state.driverInfo };
        currentStateDriverInfo.transit_number = value;
        this.setState({ driverInfo: currentStateDriverInfo });
    };

    handleAccountNumber(e) {
        let value = e.target.value;
        let currentStateDriverInfo = { ...this.state.driverInfo };
        currentStateDriverInfo.account_number = value;
        this.setState({ driverInfo: currentStateDriverInfo });
    };

    handleFirstName(e) {
        let value = e.target.value;
        let currentStateDriverInfo = { ...this.state.driverInfo };
        currentStateDriverInfo.first_name = value;
        this.setState({ driverInfo: currentStateDriverInfo });
    };

    handleLastName(e) {
        let value = e.target.value;
        let currentStateDriverInfo = { ...this.state.driverInfo };
        currentStateDriverInfo.last_name = value;
        this.setState({ driverInfo: currentStateDriverInfo });
    };

    handleAdditionalInformation(e, fieldName) {
        let value = e.target.value;
        this.state.additionalFields.find(field => field.name == fieldName).value = value;
        //Refresh
        this.setState({});
    }

    handleTaxesInputForm = idx => evt => {
        Object.keys(this.state.driverInfo.taxes_number).map(key => {
            if (idx === key) {
                let currentStateDriverInfo = { ...this.state.driverInfo };
                currentStateDriverInfo.taxes_number[key] = evt.target.value;
                this.setState({ driverInfo: currentStateDriverInfo });
            };
            //No return wanted outside of if function
            //eslint-disable-next-line
            return;
        });
    };

    handleRegsiterDriver = () => {
        let accountName = this.state.driverInfo.eosAccount;
        let driverProfile = this.state.driverInfo;
        let driverVehicles = this.state.driverVehicles;
        driverProfile.additional = this.state.additionalFields;
        this.editDriverHandlerAlert();
        newDriver(accountName, driverProfile, driverVehicles).then(_ => {
            this.confimRegistredAccountAlert(accountName);
        }).catch(error => {
            this.errorHandlerAlert(error);
        });
    };

    handleAutoFill = (e) => {
        //FIXME : THIS IS DIRTY FOR EVACAQC1
        // Reset the fields before autofilling
        const driverInfo = {
            ...this.state.driverInfo,
            phone: "",
            eosAccount: "",
            email: "",
            vehicle_brand: "",
            vehicle_model: "",
            vehicle_year: "",
            color: "",
            vehicle_registration_plate: "",
            vehicle_mileage: 0,
            vehicle_identification_number: "",
            vehicle_registration_certificate_file_number: "",
            driver_licence_number: "",
            financial_institution_name: "",
            financial_institution_number: "",
            transit_number: "",
            account_number: "",
            first_name: "",
            last_name: ""
        };
        
        const value = e.target.value;
        const values = value.split('\t');
        
        // Row in Driver Members has 41 columns
        // Dirty to check if 23 > but will do the job until we remove that feature
        if (values.length > 23) {
            driverInfo['phone'] = values[7];
            driverInfo['email'] = values[8];
            driverInfo['first_name'] = values[1];
            driverInfo['last_name'] = values[2];
            driverInfo['vehicle_brand'] = values[19];
            driverInfo['vehicle_model'] = values[20];
            driverInfo['vehicle_year'] = values[21];
            if (values[27]) {
                driverInfo['color'] = this.getVehicleColor(values[27].toLowerCase());
            }

            driverInfo['vehicle_registration_plate'] = values[16];
            driverInfo['vehicle_identification_number'] = values[25];
            driverInfo['vehicle_registration_certificate_file_number'] = values[17];
            driverInfo['driver_licence_number'] = values[13];
            driverInfo['vehicle_mileage'] = values[26];
            driverInfo['financial_institution_number'] = values[34];
            driverInfo['transit_number'] = values[35];
            driverInfo['account_number'] = values[36];
            driverInfo['taxes_number'] = {
                TPS: values[32],
                TVQ: values[33]
            }

            // Check all driver permissions for driver members
            this.setState({ driverPermissions: this.state.availableServices });
        }
        // Row in Delivery Members has 23 columns
        // Dirty to check if 23 > but will do the job until we remove that feature
        else if (values.length >= 20 && values.length <= 23) {
            driverInfo['last_name'] = values[2];
            driverInfo['first_name'] = values[1];
            driverInfo['email'] = values[6];
            driverInfo['phone'] = values[7];
            driverInfo['driver_licence_number'] = values[10];
            driverInfo['vehicle_registration_plate'] = values[11];
            driverInfo['vehicle_identification_number'] = values[12];
            driverInfo['vehicle_brand'] = values[13];
            driverInfo['vehicle_model'] = values[14];
            driverInfo['vehicle_year'] = values[15];

            if (values[16]) {
                driverInfo['color'] = this.getVehicleColor(values[16].toLowerCase());
            }
            driverInfo['financial_institution_number'] = values[17];
            driverInfo['transit_number'] = values[18];
            driverInfo['account_number'] = values[19];

            driverInfo['taxes_number'] = {
                TPS: "",
                TVQ: ""
            }

            // Disable ridesharing and other services for delivery members
            const deliveryServiceId = parseInt(Object.keys(RideServiceNames).find(key => RideServiceNames[key] === "DELIVERY_SERVICE"));
            this.setState({ driverPermissions: [deliveryServiceId] });
        }

        driverInfo['autoFillValue'] = "";

        this.setState({ driverInfo });
    }

    getVehicleColor = (vehicleColor) => {
        const colors = {
            'blanc': 'white',
            'noir': 'black',
            'bleu': 'blue',
            'gris': 'gray',
            'rouge': 'red',
            'vert': 'green',
            'marron': 'brown',
            'brun': 'brown',
            'yellow': 'jaune',
            'orange': 'orange',
            'rose': 'pink'
        }
        if (vehicleColor in colors) {
            return colors[vehicleColor];
        }
    }

    addAutoFillField = () => {
        this.setState({
            'showAutoFillField': true
        })
    }


    getCommunityTaxInfo = () => {
        getCommunity(this.props.community).then(result => {
            let rows = result;
            let taxInfo = JSON.parse(rows.tax_description);
            let taxesJson = {};
            for (let i = 0; i < taxInfo['taxes'].length; i++) {
                let row = taxInfo['taxes'][i];
                taxesJson[row.name] = "";
            };
            let currentStateDriverInfo = { ...this.state.driverInfo };
            currentStateDriverInfo.taxes_number = taxesJson;
            this.setState({ driverInfo: currentStateDriverInfo });
        });
    };

    loadDriverInfo = async (driverAccount) => {
        let driverInfoObject = await getDriver(driverAccount);
        let driverInfo = driverInfoObject.profile;
        let driverVehicles = driverInfoObject.driver_vehicles;

        driverInfo['community_id'] = this.props.community;
        if (typeof driverVehicles === 'undefined' || driverVehicles === null || driverVehicles.length === 0) {
            driverVehicles = this.state.driverVehicles;
        }
        this.setState({
            driverInfo, isDriverFlagged: driverInfoObject.is_flagged, driverVehicles
        });
    };

    onRiderClick = () => {
        this.props.history.push({
            pathname: '/rider',
            search: '?name=' + this.props.selectedDriver,
            state: { name: this.props.selectedDriver }
        })
    }

    handleEditDriver = () => {
        this.setState({ customButtonDisabled: true });
        let accountName = this.props.selectedDriver;
        let driverProfile = this.state.driverInfo;
        let driverVehicles = this.state.driverVehicles;
        driverProfile.additional = this.state.additionalFields;
        editDriver(accountName, driverProfile, driverVehicles).then(_ => {
            this.confimEditAccountAlert(accountName);
            this.setState({ customButtonDisabled: false });
        }).catch(error => {
            this.errorHandlerAlert(error);
        });
    };

    deactivateDriver = async (driverAccount) => {
        await this.setState({ actionLoading: true });
        this.hideAlert();
        this.showAlert("DEACTIVATE");
        deleteDriver(driverAccount).then(response => {
            this.setState({ actionLoading: false });
            this.showConfirm(driverAccount, "DEACTIVATE");
        }).catch(function (error) {
            this.setState({ actionLoading: false });
            this.errorHandlerAlert(error);
        })
    }

    flagDriver = async (driverAccount) => {
        await this.setState({ actionLoading: true });
        this.hideAlert();
        this.showAlert("FLAG");
        switchDriverFlag(driverAccount).then(response => {
            this.setState({ actionLoading: false });
            this.showConfirm(driverAccount, "FLAG");
        }).catch(function (error) {
            this.setState({ actionLoading: false });
            this.errorHandlerAlert(error);
        })
    }

    resetStats = async (driverAccount) => {
        await this.setState({actionLoading: true});
        this.hideAlert();
        this.showAlert("RESET_STATS")
        resetDriverStats(driverAccount).then(response => {
            this.setState({ actionLoading: false });
            this.showConfirm(driverAccount, "RESET_STATS")
        }).catch(function (error) {
            this.setState({actionLoading: false});
            this.errorHandlerAlert(error)
        })
    }

    refreshPage = () => {
        this.setState({
            alert: null
        });
        window.location.reload();
    }

    showConfirm = (accountData, action) => {
        let title = null;
        let message = null;
        let onConfirm = null;

        switch (action) {
            case "DEACTIVATE": 
            message = <Trans>DEACTIVATION_SUCCESSFUL</Trans>;
            title = <Trans>DEACTIVATION_TITLE</Trans>;
            onConfirm = () => this.goActiveDriverPage();
            break;
            case "FLAG": 
            message = (this.state.isDriverFlagged) ? <Trans>UNFLAG_SUCCESSFUL</Trans> : <Trans>FLAG_SUCCESSFUL</Trans>;
            title = (this.state.isDriverFlagged) ? <Trans>UNFLAG_TITLE</Trans> : <Trans>FLAG_TITLE</Trans>;
            onConfirm = () => {
                this.hideAlert();
                window.location.reload();
            }
            break;
            case "RESET_STATS": 
            message = <Trans>RESET_DRIVER_STATS_SUCCESSFUL</Trans>
            title = <Trans>RESET_DRIVER_STATS_TITLE</Trans>
            onConfirm = () => {
                this.hideAlert();
                window.location.reload();
            }
            break;

        }
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={title}
                    onConfirm={() => onConfirm()}
                    confirmBtnBsStyle="info"
                    closeOnClickOutside={false}
                    allowEscape={false}
                    showCancel={false}
                    showConfirm={true}
                >
                    <p>{accountData} {message}.</p>
                </SweetAlert>
            )
        });
    }

    showAlert = (action) => {
        let onConfirm = null;
        let confirmMessage = null;
        let sendingMessage = null;

        switch (action) {
            case "DEACTIVATE": 
                onConfirm = this.deactivateDriver;
                confirmMessage = <Trans>DEACTIVATION_CONFIRMATION</Trans>;
                sendingMessage = <Trans>SENDING_DEACTIVATING_DRIVER</Trans>;
                break;
            case "FLAG":
                onConfirm = this.flagDriver;
                confirmMessage = (this.state.isDriverFlagged) ? <Trans>UNFLAG_CONFIRMATION</Trans> : <Trans>FLAG_CONFIRMATION</Trans>
                sendingMessage = (this.state.isDriverFlagged) ? <Trans>SENDING_UNFLAG_DRIVER</Trans>: <Trans>SENDING_FLAG_DRIVER</Trans>
                break;
            case "RESET_STATS":
                onConfirm = this.resetStats;
                confirmMessage = <Trans>RESET_DRIVER_STATS_CONFIRMATION</Trans>;
                sendingMessage = <Trans>RESET_DRIVER_STATS_SENDING</Trans>;
                break;
        }


        //Will confirm with user one last time to make sure that he really wants
        //to deactivate this account
        if (!this.state.actionLoading) {
            //not in a loading state
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={<Trans>ARE_YOU_SURE</Trans>}
                        onConfirm={() => onConfirm(this.props.selectedDriver)}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="info"
                        cancelBtnBsStyle="danger"
                        confirmBtnText={<Trans>YES_DO_IT</Trans>}
                        cancelBtnText={<Trans>CANCEL</Trans>}
                        showCancel
                    >
                        <p>
                            {confirmMessage} {this.state.driverInfo.first_name} {this.state.driverInfo.last_name}?
                        </p>
                    </SweetAlert>
                )
            });
        } else {
            //in a loading state
            this.setState({
                alert: (
                    <SweetAlert
                        style={{ display: "block", marginTop: "-100px" }}
                        title={<Trans>SENDING</Trans>}
                        closeOnClickOutside={false}
                        allowEscape={false}
                        showCancel={false}
                        showConfirm={false}
                    >
                        <div class="spinner">
                            <div class="bounce1"></div>
                            <div class="bounce2"></div>
                            <div class="bounce3"></div>
                        </div>
                        <p>
                            {sendingMessage} {this.state.driverInfo.first_name} {this.state.driverInfo.last_name}
                        </p>
                    </SweetAlert>
                )
            });
        }
    };

    newNote = async () => {
        if (this.state.isSendingNote === true) {
            return;
        }
        this.setState({isSendingNote: true});
        await saveDriverNote({driver: this.props.selectedDriver, note_id: -1, note: encodeURI(this.state.newNote).replace(/'/g, "%27")}); //%27 is apostrophes/single quotes
        this.setState({newNote: "", driverNotePreset: "", isSendingNote: false});
    }

    editNote = (note_id, note) => {
        saveDriverNote({note_id, note: encodeURI(note), driver: this.props.selectedDriver});
    }

    deleteNote = async (note_id) => {
        deleteDriverNote({note_id});
        let {notes} = this.state;
        notes = notes.filter(e => e.id!==note_id);
        this.setState({notes});
    }

    render() {

        const styleButtonBack = {
            marginBottom: '15px',
        };
        let showCustomButton = null;
        if (this.props.buttonAction === 'Register') {
            showCustomButton = (
                <CustomButton onClick={() => this.handleRegsiterDriver()}
                    bsStyle="warning"
                    pullRight
                    fill
                    disabled={this.state.customButtonDisabled}>
                    {this.state.customButtonDisabled ?
                        <i style={{
                            lineHeight: "0px",
                            fontSize: "14px"
                        }} className="fa fa-refresh fa-spin fa-3x"></i>
                        :
                        this.props.children.props.children}
                </CustomButton>
            )
        } else {
            showCustomButton = (
                <CustomButton onClick={this.handleEditDriver.bind(this)}
                    bsStyle="warning"
                    pullRight
                    fill
                    disabled={this.state.customButtonDisabled}>
                    {this.state.customButtonDisabled ?
                        <i style={{
                            lineHeight: "0px",
                            fontSize: "14px"
                        }} className="fa fa-refresh fa-spin fa-3x"></i>
                        :
                        this.props.children.props.children}
                </CustomButton>
            )
        };

        let autoFillForm = null;
        if (this.state.showAutoFillField) {

            autoFillForm = <Row>
                <Col md={10}>
                    <FormInputs
                        ncols={["col-md-12"]}
                        proprieties={[
                            {
                                label: <Trans>Auto fill</Trans>,
                                type: "text",
                                bsClass: "form-control",
                                placeholder: i18n.t('Google Form'),
                                onChange: this.handleAutoFill.bind(this),
                                value: this.state.autoFillValue,
                            }
                        ]}
                    />
                </Col>
            </Row>
        }

        let driverStats = (this.props.titleCard === 'Edit') ? <DriverStatsFull accountName={this.props.selectedDriver} /> : <div></div>;
        const driverNotes = (this.props.titleCard === 'Edit') ? (<Row>
                <Col md={10}>
                    <Card
                        title={<Trans>NOTES_DRIVER</Trans>}
                        content= {<div style={{width: "100%", padding: "0 20px"}}>
                        {this.state.notes.length === 0 ? <div style={{textAlign: "center", width: "100%"}}><h5><Trans>NO_NOTES_YET</Trans></h5></div>: this.state.notes.map(note => {
                            return <CommentBox key={note.id} canEdit={note.user.id==this.props.userId} comment={decodeURI(note.note)} id={note.id} author={note.user.first_name + " " + note.user.last_name} timestamp={utcStringToLocalString(note.created_at)} editFunction={this.editNote} deleteFunction={this.deleteNote} />
                        })}
                        <div>
                        <FormGroup controlId="formControlsSelect">
                                    <ControlLabel><Trans>Select Note</Trans></ControlLabel>
                                    <FormControl
                                        onChange={(e) => {
                                            const note = e.target.value;
                                            this.setState({newNote: note === DriverPresetNotes.CUSTOM ? "" : note, customNote: e.target.value === DriverPresetNotes.CUSTOM})
                                            }}
                                        inputRef={el => this.inputEl = el}
                                        value={this.state.driverNotePreset}
                                        componentClass="select" placeholder="Color">
                                        <option value=""></option>
                                        <option value={i18n.t(DriverPresetNotes.LATE)}>{i18n.t(DriverPresetNotes.LATE + '_SELECT')}</option>
                                        <option value={i18n.t(DriverPresetNotes.ORDER_DAMAGED)}>{i18n.t(DriverPresetNotes.ORDER_DAMAGED + '_SELECT')}</option>
                                        <option value={i18n.t(DriverPresetNotes.WRONG_DESTINATION)}>{i18n.t(DriverPresetNotes.WRONG_DESTINATION + '_SELECT')}</option>
                                        <option value={i18n.t(DriverPresetNotes.ORDER_NOT_DELIVERED)}>{i18n.t(DriverPresetNotes.ORDER_NOT_DELIVERED + '_SELECT')}</option>
                                        <option value={i18n.t(DriverPresetNotes.LATE_PICKUP)}>{i18n.t(DriverPresetNotes.LATE_PICKUP + '_SELECT')}</option>
                                        <option value={i18n.t(DriverPresetNotes.DANGEROUS_DRIVING)}>{i18n.t(DriverPresetNotes.DANGEROUS_DRIVING + '_SELECT')}</option>
                                        <option value={i18n.t(DriverPresetNotes.LOW_ACCEPTATION_RATE)}>{i18n.t(DriverPresetNotes.LOW_ACCEPTATION_RATE + '_SELECT')}</option>
                                        <option value={i18n.t(DriverPresetNotes.LOW_SUCCESS_RATE)}>{i18n.t(DriverPresetNotes.LOW_SUCCESS_RATE + '_SELECT')}</option>
                                        <option value={i18n.t(DriverPresetNotes.HIGH_CANCELLATION_RATE)}>{i18n.t(DriverPresetNotes.HIGH_CANCELLATION_RATE + '_SELECT')}</option>
                                        <option value={i18n.t(DriverPresetNotes.MISBEHAVIOUR)}>{i18n.t(DriverPresetNotes.MISBEHAVIOUR+ '_SELECT')}</option>
                                        <option value={DriverPresetNotes.CUSTOM}>{i18n.t(DriverPresetNotes.CUSTOM)}</option>
                                    </FormControl>
                                </FormGroup>
                        {this.state.customNote ? <textarea style={{width: "100%", borderRadius: "5px", padding: "1%"}} value={this.state.newNote} onChange={e => this.setState({newNote: e.target.value})} /> : null }
                        <CustomButton
                        onClick={() => this.newNote()}
                    bsStyle="warning"
                    fill
                    disabled={this.state.newNote === ""}>
                {this.state.isSendingNote ? "..." : <Trans>SUBMIT</Trans>}
                        </CustomButton>
                        </div>
                            </div>}
                        />
                </Col>
            </Row>) : null;
        
        let actionPanel;
        if (this.props.titleCard === 'Edit') {
            //If title card is edit, then action panel is showed in grid
            actionPanel = <Row>
                <Col md={10}>
                    <Card
                        title={i18n.t("ACTIONS_DRIVER")}
                        content= {
                            <div style={{padding: "1%"}}>
                    <span id="deactivateDiv" style={{margin: "1%"}}>
                        <customButton class="deactivateBtn"
                            type="submit"
                            onClick={() => {
                                this.showAlert("DEACTIVATE");
                            }}>
                            <Trans>DEACTIVATE_DRIVER</Trans>
                        </customButton>
                    </span>
                    <span id="flagDiv" style={{margin: "1%"}}>
                        <customButton class={this.state.isDriverFlagged ? "activateBtn" : "deactivateBtn"}
                            type="submit"
                            onClick={() => {
                                this.showAlert("FLAG");
                            }}>
                            {this.state.isDriverFlagged ? <Trans>UNFLAG_DRIVER</Trans> : <Trans>FLAG_DRIVER</Trans>}
                        </customButton>
                    </span>
                    <span id="flagDiv" style={{margin: "1%"}}>
                        <customButton class="activateBtn"
                            type="submit"
                            onClick={() => {
                                this.showAlert("RESET_STATS");
                            }}>
                           <Trans>RESET_DRIVER_STATS</Trans>
                        </customButton>
                    </span></div>}/>
                </Col>
            </Row>
        } else {
            //driver is being added
            actionPanel = <div></div>
        }

        let riderBanner = (this.props.titleCard === 'Edit') ? <div
            style={{
                margin: "10px",
                padding: "10px",
                backgroundColor: "#4a80f0",
                color: "white",
                textAlign: "center",
                borderRadius: "10px"
            }}>
            <i style={{ cursor: "pointer" }} onClick={() => this.onRiderClick()}><Trans>DRIVER_IS_RIDER_LINK</Trans></i></div> : <div></div>;
        
        const flaggedBanner = this.state.isDriverFlagged ? <div style={{ textAlign: "center" }} className="alert alert-danger" role="alert">
        <h3><i style={{ margin: "10px" }} className="fa fa-exclamation-triangle"></i> <Trans>DRIVER_FLAGGED</Trans></h3>
      </div> : null;

        return (
            <div className="main-content">
                {riderBanner}
                {flaggedBanner}
                {this.state.alert}
                <br />
                <Grid fluid>
                    <CustomButton color="primary" className="px-3" style={styleButtonBack}
                        onClick={this.props.dynamicShowDefaultView}
                    >
                        <Trans>Go Back</Trans>
                    </CustomButton>
                    {driverStats}
                    <h3>{this.props.titleCard}</h3>
                    <Row>
                        <Col md={10}>
                            <Card
                                title={i18n.t("VEHICLES")}
                                content={
                                    <div>
                                    {this.state.driverVehicles.map(vehicle => {
                                        return <form>
                                        <span onClick={() => {
                                            let {driverVehicles} = this.state;
                                            if (driverVehicles.length <= 1) {
                                                return;
                                            }
                                            driverVehicles.splice(this.state.driverVehicles.indexOf(vehicle), 1);
                                            this.setState(driverVehicles);
                                        }} style={{float: "right"}}>
                                        <i style={{cursor: (this.state.driverVehicles.length > 1) ? "pointer": "", color: (this.state.driverVehicles.length > 1) ? "rgb(255,155,46)" : "", fontSize: "1.5em"}} class="fa fa-times-circle"></i></span>
                                        <h5><Trans>VEHICLE</Trans> {this.state.driverVehicles.indexOf(vehicle) + 1}</h5>
                                        <hr />
                                        <FormGroup controlId="formControlsSelect">
                                    <ControlLabel><Trans>VEHICLE_TYPE</Trans></ControlLabel>
                                    <FormControl
                                        inputRef={el => this.inputEl = el}
                                        value={vehicle.vehicle_type_id}
                                        onChange={(e => {
                                            vehicle.vehicle_type_id = parseInt(e.target.value);
                                            this.setState({vehicle});
                                        })}
                                        componentClass="select" placeholder="Type">
                                        <option value=""></option>
                                        <option value={0}>{i18n.t("STANDARD_CAR")}</option>
                                        <option value={4}>{i18n.t("LUXURY_CAR")}</option>
                                        <option value={1}>{i18n.t("BICYCLE")}</option>
                                        <option value={2}>{i18n.t("VAN")}</option>
                                        <option value={5}>{i18n.t("MOTO")}</option>
                                    </FormControl>
                                </FormGroup>
                                {VehicleTypes.hasOwnProperty(vehicle.vehicle_type_id) ?
                                        <div>
                                        <FormInputs
                                ncols={["col-md-5", "col-md-5", "col-md-2"]}
                                proprieties={[
                                    {
                                        label: <Trans>Vehicle brand</Trans>,
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('Vehicle brand'),
                                        onChange: (e => {
                                            vehicle.vehicle_brand = e.target.value;
                                            this.setState({vehicle});
                                        }),
                                        value: vehicle.vehicle_brand,
                                    },
                                    {
                                        label: <Trans>Vehicle model</Trans>,
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('Vehicle model'),
                                        onChange: (e => {
                                            vehicle.vehicle_model = e.target.value;
                                            this.setState({vehicle});
                                        }),
                                        value: vehicle.vehicle_model,
                                    },
                                    {
                                        label: <Trans>Vehicle year</Trans>,
                                        type: "number",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('Vehicle year'),
                                        onChange: (e => {
                                            vehicle.vehicle_year = e.target.value;
                                            this.setState({vehicle});
                                        }),
                                        value: vehicle.vehicle_year
                                    }
                                ]}
                            />
                            <Col md={3}>
                                <FormGroup controlId="formControlsSelect">
                                    <ControlLabel><Trans>Vehicle color</Trans></ControlLabel>
                                    <FormControl
                                        inputRef={el => this.inputEl = el}
                                        value={vehicle.vehicle_color}
                                        onChange={(e => {
                                            vehicle.vehicle_color = e.target.value;
                                            this.setState({vehicle});
                                        })}
                                        componentClass="select" placeholder="Color">
                                        <option value=""></option>
                                        <option value="white">{i18n.t('White')}</option>
                                        <option value="black">{i18n.t('Black')}</option>
                                        <option value="blue">{i18n.t('Blue')}</option>
                                        <option value="gray">{i18n.t('Gray')}</option>
                                        <option value="red">{i18n.t('Red')}</option>
                                        <option value="green">{i18n.t('Green')}</option>
                                        <option value="brown">{i18n.t('Brown')}</option>
                                        <option value="yellow">{i18n.t('Yellow')}</option>
                                        <option value="orange">{i18n.t('Orange')}</option>
                                        <option value="Pink">{i18n.t('Pink')}</option>
                                        <option value="purple">{i18n.t('Purple')}</option>
                                    </FormControl>
                                </FormGroup>
                            </Col>
                            <FormInputs
                                ncols={["col-md-4"]}
                                proprieties={[
                                    {
                                        label: <Trans>Registration plate number</Trans>,
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('Registration plate number'),
                                        onChange: (e => {
                                            vehicle.vehicle_registration_plate = e.target.value;
                                            this.setState({vehicle});
                                        }),
                                        value: vehicle.vehicle_registration_plate,
                                    }
                                ]}
                            />
                            <FormInputs
                                ncols={["col-md-4", "col-md-4", "col-md-4"]}
                                proprieties={[
                                    {
                                        label: <Trans>Vehicle mileage</Trans>,
                                        type: "number",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('Vehicle mileage'),
                                        onChange: (e => {
                                            vehicle.vehicle_mileage = e.target.value;
                                            this.setState({vehicle});
                                        }),
                                        value: vehicle.vehicle_mileage,
                                    },
                                    {
                                        label: <Trans>Vehicle identification number</Trans>,
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('Vehicle identification number'),
                                        onChange: (e => {
                                            vehicle.vehicle_identification_number = e.target.value;
                                            this.setState({vehicle});
                                        }),
                                        value: vehicle.vehicle_identification_number,
                                    },
                                    {
                                        label: <Trans>Vehicle registration certificate file number</Trans>,
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('Vehicle registration certificate file number'),
                                        onChange: (e => {
                                            vehicle.vehicle_registration_certificate_file_number = e.target.value;
                                            this.setState({vehicle});
                                        }),
                                        value: vehicle.vehicle_registration_certificate_file_number,
                                    }
                                ]}
                            /></div> : null}
                            <div>
                                {/* Driver Permissions */}
                                {this.state.availableServices.map(key => (
                                    <div key={key}>
                                        <input type='checkbox'
                                            style={{ margin: '20px' }}
                                            id={key}
                                            disabled={(this.state.communitySettings && this.state.communitySettings[vehicle.vehicle_type_id]) ? !this.state.communitySettings[vehicle.vehicle_type_id][key] : false}
                                            onChange={(e => {
                                                if (vehicle.allowed_ride_services.includes(key)) {
                                                    vehicle.allowed_ride_services.splice(vehicle.allowed_ride_services.indexOf(key), 1);
                                                } else {
                                                    vehicle.allowed_ride_services.push(key);
                                                }
                                            this.setState({vehicle});
                                        })}
                                            checked={vehicle.allowed_ride_services.includes(key)} /><Trans>{RideServiceNames[key]}</Trans>
                                    </div>
                                ))
                                }
                            </div>
                            <hr/>
                            <div>
                                <FormInputs 
                                    ncols={["col-lg-4"]}
                                    proprieties={[
                                    {
                                        label: <Trans>Allowed multi ride</Trans>,
                                        type: "checkbox",
                                        bsClass: "form-control",
                                        style:{ 'margin-left': '20px', width: '14px'},
                                        onChange: (e => {
                                            vehicle.vehicle_allowed_multi = !vehicle.vehicle_allowed_multi;
                                            this.setState({vehicle});
                                        }),
                                        checked: vehicle.vehicle_allowed_multi,
                                    }]}
                                />
                            </div>
                            <div>
                                <FormInputs 
                                    ncols={["col-lg-4"]}
                                    proprieties={[
                                    {
                                        label: <Trans>Allowed Cash Payment</Trans>,
                                        type: "checkbox",
                                        bsClass: "form-control",
                                        style:{ 'margin-left': '20px', width: '14px'},
                                        onChange: (e => {
                                            vehicle.vehicle_allowed_cash_payment = !vehicle.vehicle_allowed_cash_payment;
                                            this.setState({vehicle});
                                        }),
                                        checked: vehicle.vehicle_allowed_cash_payment,
                                    }]}
                                />
                            </div>
                            </form>
                                    })}
                             <CustomButton 
                    bsStyle="warning"
                    onClick={() => {
                        const {driverVehicles} = this.state;
                        driverVehicles.push({
                vehicle_brand: "",
                vehicle_model: "",
                vehicle_year: "",
                vehicle_color: "",
                vehicle_registration_plate: "",
                vehicle_mileage: "",
                vehicle_identification_number: "",
                vehicle_registration_certificate_file_number: "",
                allowed_ride_services: [],
                vehicle_allowed_multi: true
            });
            this.setState({driverVehicles});
                    }}
                    fill><Trans>NEW_VEHICLE</Trans></CustomButton></div>} />

                            </Col>
                            </Row>
                <Row>
                    <Col md={10}>
                        <Card
                            title={i18n.t("INFORMATION")}
                                content={
                        <form>
                            {autoFillForm}
                            <FormInputs
                                ncols={["col-md-3", "col-md-2", "col-md-4"]}
                                proprieties={[
                                    {
                                        label: <Trans>Phone Number</Trans>,
                                        type: "number",
                                        bsClass: "form-control",
                                        placeholder: "1(514) 111-1111",
                                        onChange: this.handlePhoneNumber.bind(this),
                                        value: this.state.driverInfo.phone,
                                    },
                                    {
                                        label: <Trans>Eos Account</Trans>,
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('Eos Account'),
                                        onChange: this.handleEosAccount.bind(this),
                                        value: this.props.selectedDriver,
                                    },
                                    {
                                        label: <Trans>Email address</Trans>,
                                        type: "email",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('Email address'),
                                        onChange: this.handleEmail.bind(this),
                                        value: this.state.driverInfo.email,
                                    }
                                ]}
                            />
                            <FormInputs
                                ncols={["col-md-3", "col-md-3"]}
                                proprieties={[
                                    {
                                        label: <Trans>First Name</Trans>,
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('First Name'),
                                        onChange: this.handleFirstName.bind(this),
                                        value: this.state.driverInfo.first_name,
                                    },
                                    {
                                        label: <Trans>Last Name</Trans>,
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('Last Name'),
                                        onChange: this.handleLastName.bind(this),
                                        value: this.state.driverInfo.last_name,
                                    },

                                ]}
                            />

                            <FormInputs
                                ncols={["col-md-4"]}
                                proprieties={[
                                    {
                                        label: <Trans>Driver licence number</Trans>,
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('Driver licence number'),
                                        onChange: this.handleDriverLicenceNumber.bind(this),
                                        value: this.state.driverInfo.driver_licence_number,
                                    }
                                ]}
                            />
                        
                            <FormInputs
                                ncols={["col-md-10"]}
                                proprieties={[
                                    {
                                        label: <Trans>Financial institute name</Trans>,
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('Financial institute name'),
                                        onChange: this.handleFinancialInstituteName.bind(this),
                                        value: this.state.driverInfo.financial_institution_name,
                                    }
                                ]}
                            />

                            <FormInputs
                                ncols={["col-md-3", "col-md-3", "col-md-4"]}
                                proprieties={[
                                    {
                                        label: <Trans>Financial institute number</Trans>,
                                        type: "number",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('Financial institute number'),
                                        onChange: this.handleFinancialInstituteNumber.bind(this),
                                        value: this.state.driverInfo.financial_institution_number,
                                    },
                                    {
                                        label: <Trans>Transit number</Trans>,
                                        type: "number",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('Financial institute number'),
                                        onChange: this.handleTransitNumber.bind(this),
                                        value: this.state.driverInfo.transit_number,
                                    },
                                    {
                                        label: <Trans>Account number</Trans>,
                                        type: "number",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('Financial institute number'),
                                        onChange: this.handleAccountNumber.bind(this),
                                        value: this.state.driverInfo.account_number,
                                    }
                                ]}
                            />
                            {Object.keys(this.state.driverInfo.taxes_number).map(key => (
                                <div key={key}>
                                    <FormInputs
                                        ncols={["col-md-4"]}
                                        proprieties={[
                                            {
                                                label: key,
                                                type: "text",
                                                bsClass: "form-control",
                                                placeholder: key + " Number",
                                                onChange: this.handleTaxesInputForm(key),
                                                value: this.state.driverInfo.taxes_number[key]
                                            }
                                        ]}
                                    />
                                </div>
                            ))
                            }
                            <div style={{display: (this.state.additionalFields.length === 0) ? 'none' : ''}}>
                            <Card
                            title={i18n.t("ADDITIONAL_INFORMATION")}
                                content=
                                    {this.state.additionalFields.map(field => {
                                return <div key={field.name}>
                                    <FormInputs
                                        ncols={["col-md-4"]}
                                        proprieties={[
                                            {
                                                label: i18n.t(field.name.toUpperCase()),
                                                type: field.type,
                                                bsClass: "form-control",
                                                onChange: (e) => this.handleAdditionalInformation(e, field.name),
                                                value: field.value
                                            }
                                        ]}
                                    />
                                </div>
                                    })
                            }
                            />
                            </div>
                            {showCustomButton}
                            <div className="clearfix" />
                        </form>
                    }
                            />
                        </Col>
                    </Row>
                    {driverNotes}
                    { actionPanel }
                </Grid>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth: state.userData.isAuthenticated,
        community: state.userData.communityAccount,
        cityName: state.userData.cityName,
        userId: state.userData.userId
    };
};
export default withRouter(connect(mapStateToProps)(DriverInfoForm));
