import React from "react"
import { Trans } from "react-i18next"
import {Table} from 'react-bootstrap'
import moment from "moment"
import { utcStringToLocalString, getDifferenceInSeconds,isoToDateTime, convertSecondsToHoursString} from "../../helpers/dateHelper"
import './StepperSubTable.css'



const StepperSubTable=(props) =>{
    const rideData = props.data.original.ride;

    const getClassname = (row) => {
        let className = "";
        if(row.is_step_done) className += " step-done";
        if(row.order_index == rideData.current_stop_index) className += " current-step";
        return className;
    }

   

    function getRows() {
        const originalData = props.data.original
        const steps = originalData.ride.driver_route_ordered_steps;
        
        return (
            <>
                {steps.map(row => {
                    return (
                        <tr className={getClassname(row)}>
                            <td style={{width:"10%"}} className="step-type">{<Trans>{row.step_type}</Trans>}</td>
                            <td style={{width:"10%"}}>{utcStringToLocalString(row.started_datetime)}</td>
                            <td style={{width:"10%"}}>{utcStringToLocalString(row.done_datetime)}</td>
                            <td style={{width:"5%"}}>{convertSecondsToHoursString(row.estimated_time_taken_seconds)}</td>
                            <td style={{width:"5%"}}>{convertSecondsToHoursString(row.real_time_taken_seconds)}</td>
                            <td>{row.from_address}</td>
                            <td>{row.to_address}</td>
                        </tr>
                    );
                })}
            </>
        );
    }



    return (
        <div>
            <Table striped size="sm">
            <thead>
                <tr>
                    <th><Trans>STEP</Trans></th>
                    <th><Trans>STARTED_DATETIME</Trans></th>
                    <th><Trans>DONE_DATETIME</Trans></th>
                    <th><Trans>MERGED_EST_MIN</Trans></th>
                    <th><Trans>TIME_TAKEN</Trans></th>
                    <th><Trans>FROM_ACCOUNT</Trans></th>
                    <th><Trans>TO_ACCOUNT</Trans></th>
                </tr>
            </thead>
            <tbody className="table-api-subtable-container">
            {getRows()}
            </tbody>
            </Table>
        </div>
    )
}

export default StepperSubTable