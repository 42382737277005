import "./TableStyle.css";

import { Button, Col, Grid, Row } from "react-bootstrap";
import React, { Component } from "react";

import Card from "../../components/Card/Card.jsx";
import { DateRangePicker } from "react-dates";
import LoadingIcon from "../Components/LoadingIcon";
import ReactTable from "react-table";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { Trans } from "react-i18next";
import { localTimeZone } from "../../variables/secretEnvVariables";

var moment = require("moment-timezone");

const DEFAULT_TOP_DISTANCE = "25px";

/**
 * Custom Table for APIs
 *
 * Needs as props:
 *
 *  -> ReactTable props
 *      -> columns (need an id)
 *          -> Possible Attributes: canFilter, filterMode (text, number, select (needs options), checkbox, date (only 1 supported for now))
 *      -> getTrProps
 *      -> defaultPageSize
 *      -> sorted
 *      -> className
 *
 *  -> API methods
 *      -> fetchData with a get_count option to retrieve { count: x }
 *
 * The column ids need to match the parameters of the API call.
 * 
 * It is possible to add extra static filters in the extraFilters props: { column id : value }
 *
 */
export default class TableAPI extends Component {

  state = {
    alert: null,
    data: [],
    loading: true,
    currentPage: 1,
    filters: {},
    pageSize: 100,
    datePickerNeeded: false,
    orderType: "desc",
    orderBy: "",
    headerWidths: {},
    headerWidthTotal: 0,
  };

  componentDidMount = async () => {
    const startDate = moment().tz(localTimeZone).subtract(1, "month");
    const endDate = moment().tz(localTimeZone);
    const filters = {};
    const extraFilters = this.props.extraFilters || {};

    this.setState({pageSize: parseInt(this.props.pageSize) > 0 ? this.props.pageSize : 100 })

    const headerWidths = {};
    let headerWidthTotal = 0;
    for (let col of this.props.columns) {
      if (col.canFilter) {
        switch (col.filterMode) {
          case "checkbox":
            filters[col.id] = false;
            break;
          case "date":
            await this.setState({ datePickerNeeded: true });
            break;
          default:
            filters[col.id] = "";
            break;
        }
      }
      if (col.defaultSort) {
        this.setState({ orderBy: col.id });
        if (col.orderType){
          this.setState({orderType: col.orderType});
        }
      }
      headerWidths[col.id] = col.width;
      headerWidthTotal += col.width;
    }
    await this.setState({ filters: {...filters, ...extraFilters}, startDate, endDate, headerWidths, headerWidthTotal });
    this.setDataAndPaginate();
  };

  async componentDidUpdate(prevProps) {
    const extraFilters = this.props.extraFilters || {};
    const prevExtraFilters = prevProps.extraFilters || {};
    if (JSON.stringify(extraFilters) === JSON.stringify(prevExtraFilters)) {
      return;
    }

    await this.setState({ filters: {...this.state.filters, ...extraFilters} });
    this.setDataAndPaginate();
  };

  fetchDataCount = async (filter) => {
    const dataCount = await this.props.fetchData({...filter, get_count: true});
    if (dataCount instanceof Error) {
      this.endpointErrorHandlerAlert(dataCount);
      return 0;
    } else {
      return dataCount;
    }
  };

  fetchData = async (offset, limit, filter) => {
    const data = await this.props.fetchData({offset, limit, ...filter});
    if (data instanceof Error) {
      this.endpointErrorHandlerAlert(data);
      return [];
    } else {
      return data;
    }
  };

  setDataAndPaginate = async () => {
    const dataCount = await this.fetchDataCount(this.state.filters);
    const maxPage = Math.ceil(dataCount.count / this.state.pageSize);
    const currentPage =
      maxPage === 0
        ? 0
        : this.state.currentPage > maxPage
        ? 1
        : this.state.currentPage;

    await this.setState({ maxPage, currentPage });

    if (maxPage === 0) {
      this.setState({ data: [], loading: false });
      return;
    } else {
      const data = await this.fetchData(
        this.getOffset(),
        this.state.pageSize,
        this.state.filters
      );
      this.setState({ data, loading: false });
    }
  };

  changePage = async (action, currentPage = this.state.currentPage) => {
    if (
      currentPage > this.state.maxPage ||
      currentPage <= 0 ||
      isNaN(currentPage)
    )
      return;

    this.setState({ loading: true });
    this.resetHeaderWidths();
    switch (action) {
      case "previous":
        currentPage--;
        break;
      case "next":
        currentPage++;
        break;
      case "select":
        //Intentionl Skip
        break;
      default:
        break;
    }
    await this.setState({ currentPage, focusedFilter: -1 });
    this.setDataAndPaginate();
  };

  applyFilter = async (key, value, i) => {
    console.log(key, value, i)
    if (value === null || value === "undefined") value = "";
    const { filters } = this.state;
    filters[key] = value;
    await this.setState({ filters, focusedFilter: i });
    this.setDataAndPaginate();
  };

  getOffset = () => {
    const page = this.state.currentPage === 0 ? 0 : this.state.currentPage - 1;
    return page * this.state.pageSize;
  };

  changePageSize = async (val) => {
    await this.setState({ loading: true, pageSize: val });
    this.resetHeaderWidths();
    this.setDataAndPaginate();
  };

  onDateFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  };

  setDatesInFilter = async () => {
    const { state } = this;
    await this.setState({ loading: true });
    this.resetHeaderWidths();
    state.filters["start_datetime"] = state.startDate;
    state.filters["end_datetime"] = state.endDate;
    this.setDataAndPaginate();
  };

  sortByColumn = async (colName) => {
    await this.setState({ loading: true, orderBy: colName });
    if (this.state.filters["order_by"] === colName) {
      await this.setState({
        orderType: this.state.orderType === "asc" ? "desc" : "asc",
      });
    } else {
      await this.setState({ orderType: "asc" });
    }
    this.state.filters["order_by"] = colName;
    this.state.filters["order_type"] = this.state.orderType;
    this.setDataAndPaginate();
  };

  endpointErrorHandlerAlert = (error) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={<Trans>ERROR_ALERT</Trans>}
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          <p>
            <Trans>TABLE_API_ERROR_1</Trans>
            <br />
            <Trans>TABLE_API_ERROR_2</Trans>
          </p>
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleColResize = async (newResized) => {
    const headerWidthsCopy = this.state.headerWidths;
    for (const res of newResized) {
      headerWidthsCopy[res.id] = res.value;
    }
    await this.setState({headerWidths: headerWidthsCopy});

    //Re-calculate total width
    let headerWidthTotal = 0;
    for (const width in this.state.headerWidths) {
      headerWidthTotal += this.state.headerWidths[width];
    }
    this.setState({headerWidthTotal});
  }

  resetHeaderWidths = async () => {
    const headerWidths = {};
    let headerWidthTotal = 0;
    for (let col of this.props.columns) {
      headerWidths[col.id] = col.width;
      headerWidthTotal += col.width;
    }
    await this.setState({ headerWidths, headerWidthTotal });
  }

  render() {
    const header = (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-4 center-text">
            <Button
              onClick={() => this.changePage("previous")}
              style={{ width: "100%", height: "45px" }}
              disabled={this.state.currentPage === 1}
            >
              <Trans>PREVIOUS</Trans>
            </Button>
          </div>
          <div
            className="col-xs-4 center-text page-section"
          >
            <Trans>PAGE</Trans>
            <input
              type="number"
              className="page-section-input"
              onChange={(e) =>
                this.changePage("select", parseInt(e.target.value))
              }
              value={this.state.currentPage}
              max={ parseInt(this.state.maxPage) >= 0 ? this.state.maxPage : 1 }
              min={1}
              autoFocus={this.state.focusedFilter === -1}
            />{" "}
            / { parseInt(this.state.maxPage) >= 0 ? this.state.maxPage : 1 }
            <br />
            <div
              className="rows-per-page-section"
            >
            <span style={{marginTop: "10px"}}>
              <Trans>ROW_PER_PAGE</Trans>
            </span>

              <Select
                style={{
                  width: "auto",
                  marginTop: "1vh",
                  marginLeft: "1vw",
                  display: "inline-block",
                  textAlign: "left",
                }}
                options={[
                  { value: 10, label: "10" },
                  { value: 25, label: "25" },
                  { value: 50, label: "50" },
                  { value: 100, label: "100" },
                  { value: 200, label: "200" },
                ]}
                value={this.state.pageSize}
                onChange={(e) => this.changePageSize(e.value)}
                menuContainerStyle={{
                  zIndex: 3,
                  width: "20vw",
                  marginLeft: "-7vw",
                }}
              />
            </div>
          </div>
          <div className="col-xs-4 center-text">
            <Button
              onClick={() => this.changePage("next")}
              style={{ width: "100%", height: "45px" }}
              disabled={this.state.currentPage === this.state.maxPage}
            >
              <Trans>NEXT</Trans>
            </Button>
          </div>
        </div>
      </div>
    );

    const colHeight = "80px";
    const theads = (
      <thead
      className="table-head"
      style={{ top: this.props.topDistance || DEFAULT_TOP_DISTANCE, width: "97%", margin: "0px" }}
      >
        <tr style={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
          <div style={{ width: "100%", minHeight: "85px", verticalAlign: "bottom", display: "flex", justifyContent:"space-around"}}>
          {[].concat(this.props.columns).map((col, i) => {
            let type = col.filterMode ? col.filterMode : "text";
            let filterInput = col.canFilter ? (
              type === "checkbox" ? (
                <input
                  type={type}
                  className="checkbox-filter"
                  defaultChecked={this.state.filters[col.id]}
                  checked={this.state.filters[col.id]}
                  onChange={(e) =>
                    this.applyFilter(col.id, e.target.checked, i)
                  }
                  autoFocus={this.state.focusedFilter === i}
                />
              ) : type === "select" ? (
                <Select
                  style={{ width: "100%" }}
                  options={col.options}
                  value={this.state.filters[col.id]}
                  onChange={(e) => {
                    if (e === null) {
                      e = { value: "" };
                    }
                    this.applyFilter(col.id, e.value, i);
                  }}
                />
              ) : type === "date" ? (
                (
                  <input
                    type={type}
                    className="default-filter"
                    defaultValue={this.state.filters[col.id]}
                    onChange={(e) => this.applyFilter(col.id, e.target.value, i)}
                    autoFocus={this.state.focusedFilter === i}
                  />
                )
              ) : (
                <input
                  type="text"
                  className="default-filter"
                  defaultValue={this.state.filters[col.id]}
                  onChange={(e) => this.applyFilter(col.id, e.target.value, i)}
                  autoFocus={this.state.focusedFilter === i}
                />
              )
            ) : (
              <div></div>
            );

            const triangle = col.canSort ? (
              <div className="small-triangle" />
            ) : (
              <div></div>
            );

            return (
              <th
                key={i}
                style={{
                  width: this.state.headerWidths[col.id],
                  maxHeight: colHeight,
                  overflow: "visible",
                  display: "inline-block",
                  cursor: col.canSort ? "pointer" : "auto"
                }}
              >
                <div style={{display:"table", height: "100%", width: "100%"}}>
                  <div style={{display:"table-row", width: "100%"}}>
                    <h6
                      onClick={() => {
                        if (col.canSort) this.sortByColumn(col.id);
                      }}
                    >
                      {col.Header}
                      {triangle}
                    </h6>
                  </div>
                  <div style={{display:"table-cell",verticalAlign:"bottom", width: "100%"}}>
                    {filterInput}
                  </div>
                </div>
              </th>
            );
          })}
          </div>
        </tr>
      </thead>
    );

    const datePicker = this.state.datePickerNeeded ? (
      <Card
        plain
        ctTableFullWidth
        ctTableResponsive
        content={
          <DateRangePicker
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onDatesChange={async ({ startDate, endDate }) => {
              startDate = startDate.hour(0).minute(0).second(0);
              endDate = endDate.hour(23).minute(59).second(59);
              await this.setState({ startDate, endDate });
              this.setDatesInFilter();
            }}
            focusedInput={this.state.focusedInput}
            onFocusChange={this.onDateFocusChange}
            isOutsideRange={() => false}
          />
        }
      />
    ) : (
      <div></div>
    );
    
    const defaultView = !this.state.loading ? (
      <div className="card">
        <br />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                plain
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div style={{paddingBottom: '75px'}}>
                    {theads}
                    <ReactTable
                      data={Object.values(this.state.data)}
                      style={{top: '2vh'}}
                      onResizedChange={(newResized) => this.handleColResize(newResized)}
                      showPagination={false}
                      columns={this.props.columns}
                      getTrProps={this.props.getTrProps}
                      SubComponent= {true && this.props.onSubClick}
                      defaultPageSize={this.state.pageSize}
                      // sorted={[
                      //   {
                      //     id: this.state.orderBy,
                      //     desc: this.state.orderType === "desc",
                      //   },
                      // ]}
                      className={this.props.className}
                      resizable={true}
                    />
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    ) : (
      <LoadingIcon />
    );
    
    return (
      <div className="content">
        <div style={{minWidth: "1000px"}}>
        {this.state.alert}
        {datePicker}
        {header}
        {defaultView}
      </div>
      </div>
    );
  }
}

