import React, { Component } from "react";
import close from "../../../../assets/img/close.png";
import Button from "../../../../components/CustomButton/CustomButton";
import Popup from "../../../../components/Popup/Popup";
import './DocumentPopUp.css';

export default class DocumentPopUp extends Component {

    modalRef = React.createRef(null);

    closeBackgroundClick = e => {
        if(this.modalRef.current === e.target)  {
            this.props.close("", "");
        }
    }
    

    render() {
        console.log("imgURL:")
        console.log(this.props.imgURL);
        return (
            <div className="document">
                <Popup show={this.props.showModal} closeBackgroundClick={this.closeBackgroundClick} modalRef={this.modalRef}>
                    <div className="header">
                        <p>{this.props.imgName}</p>
                        <Button
                            className="exit-button"
                            icon 
                            onClick={() => this.props.close("", "")}
                            >
                                <img src={close} alt='close modal'/>
                        </Button>
                    </div>
                    <div className="content">
                        <img src={this.props.imgURL} alt='driver document'/>
                    </div>
                    
                    
                </Popup>
            </div>
            
        )
    }
}