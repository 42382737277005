import "./Popup.css"

import React, {Component} from 'react';


/* Pop up modal
~~~to enable closing by clicking background of pop up, add this code to the parent:
    modalRef = React.createRef(null);

    closeBackgroundClick = e => {
        if(this.modalRef.current === e.target)  {
            this.props.close("");
        }
    }

then pass both as props
*/
export default class Popup extends Component {

    render() {
        if(this.props.show) {
            return (
                <div className="pop-up-container" onClick={this.props.closeBackgroundClick ? this.props.closeBackgroundClick: null} ref={this.props.modalRef ? this.props.modalRef: null}>
                    <div className="modal">
                        {this.props.children}
                    </div>
                </div>
                );
        } else {
            return null;
        }
    }

}