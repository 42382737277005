import React, { Component } from "react";

export class StatsCard extends Component {
  render() {
    let footer = null;
    if (this.props.statsIcon){
      footer = <div className="footer">
        <hr />
        <div className="stats">
          {this.props.statsIcon} {this.props.statsIconText}
        </div>
      </div>
    }
    return (
      <div className="card card-stats">
        <div className="content">
          <div className="row">
            <div className="col-xs-5">
              <div className="icon-big text-center icon-warning">
                {this.props.bigIcon}
              </div>
            </div>
            <div className="col-xs-7">
              <div className="numbers">
                <p>{this.props.statsText}</p>
                {this.props.statsValue}
                <p>{this.props.evaTokenValue}</p>
              </div>
            </div>
          </div>
        </div>
        {footer}
      </div>
      
      
    );
  }
}

export default StatsCard;
