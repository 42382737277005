import {
    Button,
    MenuItem,
    DropdownButton
  } from "react-bootstrap";
import React, { Component } from "react";
import CancelIcon from "../../../assets/img/cancel.svg";
import OkIcon from "../../../assets/img/ok.svg";
import { Link } from "react-router-dom";
import TableAPI from "../../Tables/TableAPI";
import { Trans } from "react-i18next";
import i18n from "i18next";
import { miaBaseLink } from '../../../variables/secretEnvVariables';
import { utcStringToLocalString } from "../../../helpers/dateHelper";
import { getDriverScheduledRoutes } from "../../../helpers/apiHelper";
import "./DriverRouteSubTable.css";
import "./DriverRoutes.css";
import DriverRouteSubTable from "./DriverRouteSubTable";
import DispatchRidePopUp from "../DispatchRideToDriver/DispatchRidePopUp"

export default class DriverRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAlertLoading: false,
      edit: false,
      manualDispatch: false,
      selectedRoute: '',
    };
  }

  constructFormFromApi = async (data) => {
    const driverRoutes = await getDriverScheduledRoutes();
    if (data.get_count) {
      return driverRoutes;
    }
    let formRows = {};
    let index = 0
    for (let route of Object.values(driverRoutes)) {
      const row = await this.getRowsFromData(route);
      row['actions'] =  <DropdownButton
      title={i18n.t("actions")}
      style={{ padding: "4%", margin: 0 }}
    >
    <MenuItem>
      <Button
        onClick={() => this.toggleManualDispatchPopUp(route)}
        bsSize="xsmall"
        bsStyle="warning"
      >
        <Trans>ASSIGN_TO</Trans>
      </Button>
    </MenuItem>
    </DropdownButton>
      formRows[index] = row;
      index++;
    }
    return formRows;
  };

  boolCheckIcon(bool, transTrue, transFalse){
    if (bool) return <img src={OkIcon} title={i18n.t(transTrue)} />;
    else return <img src={CancelIcon} title={i18n.t(transFalse)} />;
  }

  getRowsFromData = async (driverRoute) => {
    let row = {
        scheduled_datetime: utcStringToLocalString(driverRoute.scheduled_datetime),
        onsite_datetime: utcStringToLocalString(driverRoute.onsite_datetime),
        created_datetime: utcStringToLocalString(driverRoute.created_datetime),
        manual_dispatch_driver: driverRoute.manual_dispatch_driver,
        driver_request_uuid: driverRoute.driver_request_uuid,
        merged_est_minutes: driverRoute.merged_est_minutes,
        merged_est_dist_km : driverRoute.merged_est_dist_km,
        started: this.boolCheckIcon(driverRoute.started, "FLAG_STARTED", "FLAG_NOT_STARTED"),
        driver_found : driverRoute.driver_found,
        driver: driverRoute.driver,
        driver_name: driverRoute.driver_name,
        dispatch_protocol_method  : driverRoute.dispatch_protocol_method,
        problem_flag_datetime  : driverRoute.problem_flag_datetime,
        problem_flag_initial_datetime  : driverRoute.problem_flag_initial_datetime,
        last_telegram_msg_sent_at  : driverRoute.last_telegram_msg_sent_at,
        is_manual_dispatch  : driverRoute.is_manual_dispatch,
        first_step_lat : driverRoute.first_step_lat,
        first_step_lon : driverRoute.first_step_lon,
        success : driverRoute.success,
        canceled_block_time : driverRoute.canceled_block_time,
        subRows: driverRoute.rides,
        num_of_orders: driverRoute.rides.length,
    };
    row["problem_flag"] = this.getProblemCheckComponentFormDriverRoutes(driverRoute);
    return row;
  };

  getProblemCheckComponentFormDriverRoutes = (driverRoutes) => {
    if (driverRoutes.problem_flag) {
      return <span style={{ color: 'red' }}><Trans>SCHEDULED_RIDE_PROBLEM</Trans></span>
    } else {
      return <span style={{ color: 'green' }}><Trans>SCHEDULED_RIDE_CLEAR</Trans></span>
    }
  }

  getTrackingLinkComponent = (tracking_id) => {
    return (
      <Button
        style={{ padding: "1%", margin: 0, height: 20 }}
        onClick={() => {
          var link = miaBaseLink + "?tracking_id=" + tracking_id;
          window.open(link, "_blank")
        }}
        bsSize="xsmall"
        bsStyle="success"
      >
        <Trans>TRACK_RIDE</Trans>
      </Button>
    );
  };

  getStepperSubRows = (row) => {
      return <DriverRouteSubTable data={row}/>;
  };

  toggleManualDispatchPopUp = async (selectedRoute) => {
    await this.setState({ manualDispatch: !this.state.manualDispatch, selectedRoute })
  }

  render() {
    return (
      <div className="content">
      <DispatchRidePopUp show={this.state.manualDispatch} cancel={() => this.toggleManualDispatchPopUp(null)} route={this.state.selectedRoute} />
        <TableAPI 
          fetchData={this.constructFormFromApi}
          onSubClick={row => <DriverRouteSubTable data={row}/>}
          hasSubComponents={true}
          columns={[
            {
              Header: <Trans>ONSITE_DATE</Trans>,
              canSort: false,
              defaultSort: false,
              id: "onsite_datetime",
              accessor: "onsite_datetime",
              width: 140,
              Cell: (e) => {
                return <span>{e.value}</span>;
              },
            },
            {
              Header: <Trans>NUMBER_OF_ORDERS</Trans>,
              id: "num_of_orders",
              accessor: "num_of_orders",
              width: 80,
            },
            {
              Header: <Trans>MERGED_EST_MIN</Trans>,
              id: "merged_est_minutes",
              accessor: "merged_est_minutes",
              width: 80,
            },
            {
              Header: <Trans>MERGED_EST_DIST_KM</Trans>,
              id: "merged_est_dist_km",
              accessor: "merged_est_dist_km",
              width: 80,
            },
            {
              Header: <Trans>STARTED</Trans>,
              id: "started",
              accessor: "started",
              width: 40,
            },
            {
              Header: <Trans>DRIVER_FOUND</Trans>,
              id: "driver_found",
              accessor: "driver_found",
              width: 120,
              Cell: (e) => {
                return (e.original.driver_found ? <Link
                  to={`/rider?name=${e.original.driver}`}
                  style={{ color: "#70CEEC", cursor: "pointer" }}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {e.original.driver_name}
                </Link> : <img src={CancelIcon} title={i18n.t("FLAG_NOT_STARTED")} />)
              },
            },
            {
              Header: <Trans>SCHEDULED_RIDE_HEALTHCHECK</Trans>,
              id: "problem_flag",
              width: 80,
              canFilter: false,
              accessor: "problem_flag",
            },      
            {
              Header: <Trans>actions</Trans>,
              accessor: "actions",
              filterable: false,
              width: 75,
              Cell: (e) => (
                <span
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  {e.value}
                </span>
              ),
              getProps: (state, rowInfo, column) => {
                return {
                  style: {
                    overflow: "visible",
                  },
                };
              },
            },     
          ]}
          className="-highlight"
        />
      </div>
    );
  }
}