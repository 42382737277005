import React, { Component, useState, useEffect } from "react";

import { Form, Grid, Col, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import { connect } from 'react-redux';
import LoadingIcon from "../Components/LoadingIcon";
import SweetAlert from "react-bootstrap-sweetalert";
import queryString from "query-string";
import Button from "../../components/CustomButton/CustomButton.jsx";
import i18n from 'i18next';
import Card from "../../components/Card/Card.jsx";

import {
  addUser, updateUser, getAssignedUserGroups, getUsersGroups,
  IsFranchiseAdmin
} from '../../helpers/apiHelper';


function ManagementUser(props) {
  const params = queryString.parse(props.location.search)
  const [groups, setGroups] = useState([]);
  const [user, setUser] = useState({ username: params.email, userGroups: [] });
  const [loading, setLoading] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const res1 = await IsFranchiseAdmin();
      if (!res1) {
        props.history.push("/analytics/report");
      } else {
        const res2 = await getUsersGroups();
        setGroups(res2);
        const res3 = await getAssignedUserGroups(params.email);
        console.log(res3)
        if (res3) {
          setUser({ ...res3 });
        }
      }
      setLoading(false);
    }
    fetchData();

  }, [params.email]);

  const displayLoader = () => {
    if (showLoader) {
      return (
        <SweetAlert
          default
          style={{ display: "block", marginTop: "-100px" }}
          title={i18n.t("SENDING_DATA")}
          onConfirm={() => setShowLoader(false)}
          confirmBtnBsStyle="info"
        >
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>
            {i18n.t("MAP_LOADING")}
          </p>
        </SweetAlert>
      )
    }
  }

  const displaySuccess = (action) => {
    if (showSuccess) {
      return (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          onConfirm={() => {
            setShowSuccess(false);
            props.history.push("/analytics/management");
          }}
          onCancel={() => {
            setShowSuccess(false);
            props.history.push("/analytics/management");
          }}
          confirmBtnBsStyle="info"
        >
        </SweetAlert>
      )
    }
  }

  const isGroupChecked = (id) => {
    const index = user.userGroups.findIndex(a => a.groupId === id);
    if (index >= 0) {
      return true;
    }
    return false;
  }
  const onChangeGroup = (event) => {
    let updatedUserGroups = [...user.userGroups];
    if (event.target.checked) {
      updatedUserGroups.push({
        groupId: event.target.value
      });
    } else {
      const index = updatedUserGroups.findIndex(a => a.groupId === event.target.value)
      updatedUserGroups.splice(index, 1)
    }
    setUser({ ...user, userGroups: updatedUserGroups })
  }

  const displayError = (errorMessage) => {
    if (showError) {
      return (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Error"
          onConfirm={() => setShowError(false)}
          confirmBtnBsStyle="info"
        >
          <p>{i18n.t("ERROR_OCCURED", [errorMessage])}</p>
        </SweetAlert>
      )
    }
  }

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true)
    let response = null
    if (!user.userId) {
      response = await addUser({
        username: user.username,
        userGroups: user.userGroups
      });
    }
    else {
      response = await updateUser({
        userId: user.userId,
        username: user.username,
        userGroups: user.userGroups
      });
    }
    setShowLoader(false)
    if (response && response.status === 200) {
      setShowSuccess(true);
    } else {
      setShowError(true);
    }
  }

  return (
    <div className="main-content">
      {loading ? <LoadingIcon /> :
        < Grid fluid>
          {displayLoader()}
          {displayError()}
          {displaySuccess()}
          <Row>
            <Col md={7}>
              <Card
                content={
                  <Form onSubmit={onFormSubmit}>
                    <div className="card card-stats">{params.email}</div>
                    <div className="card card-stats">

                      <div> <Trans>ANALYTICS_REPORT_TYPE</Trans></div>

                      <div style={{ margin: "10px" }} > </div>
                      {
                        groups.map((item) => (
                          <ol key={item.groupId} >
                            <input
                              type="checkbox"
                              onChange={onChangeGroup}
                              name={item.groupId}
                              value={item.groupId}
                              checked={isGroupChecked(item.groupId)}
                            />
                            {item.name}
                          </ol>
                        ))
                      }
                    </div>
                    <div>
                      <Button fill type="submit" >
                        {i18n.t("SUBMIT")}
                      </Button>
                    </div>
                  </Form>
                }
              />
            </Col>
          </Row>
        </Grid >
      }
    </div >

  )
}

export default ManagementUser;
