import { Col, Grid, Row } from "react-bootstrap";
import React, { Component } from "react";
import { connect } from "react-redux";
import { generateInvoice, getCompletedRide, getArchivedScheduledRide, getRideRefunds, sendNewRefund, sendModifyRefund, sendDeleteRefund } from "../../helpers/apiHelper";

//import CustomButton from "../Components/CustomButton/CustomButton";
import Card from "../../components/Card/Card.jsx";
import CustomButton from "../../components/CustomButton/CustomButton";
import { FormInputs } from "../../components/FormInputs/FormInputs";
import LoadingIcon from "../Components/LoadingIcon";
import RideDetailsTable from '../Tables/RideDetailsTable';
import StatsCard from "../../components/Card/StatsCard";
import SweetAlert from "react-bootstrap-sweetalert";
import { Trans } from "react-i18next";
import queryString from 'query-string';
import { convertToFiat, convertToToken } from "../../helpers/paymentHelper";

class RideDetails extends Component {

    constructor(props) {
        super(props);
        this.showHideView = this
            .showHideView
            .bind(this);
    }

    state = {
        routeId: null,
        to_email: null,
        dataDidLoad: false,
        viewChoice: "info",
        showView: true, //Show view by default
        alert: null,
        refunds: [],
    }

    async componentDidMount() {
        const params = queryString.parse(this.props.location.search);
        const routeId = params.routeId;
        const rideObj = await getCompletedRide(routeId);
        getRideRefunds(rideObj.ride_request_id)
            .then(data => this.setState({ refunds: data }));
        if (rideObj) {
            const archivedData = await getArchivedScheduledRide(rideObj.ride_request_id, true);
            if (archivedData !== null) rideObj['archived_data'] = archivedData;
        }
        this.setState({ routeId, rideObj, dataDidLoad: true });
    };

    hideAlert() {
        this.setState({ alert: null });
    };

    showSuccessAlert = () => {
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Confirmation"
                    onConfirm={() => window.location.reload()}
                    confirmBtnBsStyle="success"
                >
                    <p><Trans>ACTION_SUCCESS</Trans></p>
                </SweetAlert>
            )
        });
    }

    showErrorAlert = (error) => {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    style={{
                        display: "block",
                        marginTop: "-100px"
                    }}
                    title="Error"
                    onConfirm={() => this.hideAlert()}
                    confirmBtnBsStyle="info">
                    <p>An Error has occurred : <br></br>
                        {error.message} </p>
                </SweetAlert>
            )
        });
    };

    confirmAlert = (action) => {

        let title,
            onConfirm,
            onCancel,
            confirmBtnText,
            cancelBtnText,
            paragraph;

        switch (action) {
            case "send_invoice":
                title = <Trans>SEND_INVOICE_ALERT_TITLE</Trans>;
                onConfirm = () => this.generateInvoice();
                onCancel = () => this.hideAlert();
                confirmBtnText = (this.state.alert === null) ? <Trans>SEND_INVOICE_ALERT_CONFIRM</Trans> : <Trans>ALERT_LOADING</Trans>;
                cancelBtnText = <Trans>CANCEL</Trans>;
                paragraph = (
                    <p><Trans>SEND_INVOICE_ALERT_BODY</Trans>
                        <b>{this.state.to_email}</b>
                        <br></br>
                        <Trans>WANT_TO_PROCEED</Trans></p>
                );
                break;
            case "new_refund":
                title = <Trans>SEND_NEW_REFUND</Trans>;
                onConfirm = () => this.sendNewRefund();
                onCancel = () => this.hideAlert();
                confirmBtnText = (this.state.alert === null) ? <Trans>SEND</Trans> : <Trans>ALERT_LOADING</Trans>;
                cancelBtnText = <Trans>CANCEL</Trans>;
                paragraph = (
                    <p><Trans>ARE_YOU_SURE</Trans><br/>
                        <b>You're about to create this refund:</b><br/>
                        <b>Amount: {this.state.amountNewRefund}</b>
                        <br />
                        {this.state.noteNewRefund && ("Note:" + this.state.noteNewRefund)}</p>
                );
                break;
            case "modify_refund":
                title = <Trans>SEND_NEW_REFUND</Trans>;
                onConfirm = () => this.sendModifyRefund();
                onCancel = () => this.hideAlert();
                confirmBtnText = (this.state.alert === null) ? <Trans>SEND</Trans> : <Trans>ALERT_LOADING</Trans>;
                cancelBtnText = <Trans>CANCEL</Trans>;
                paragraph = (
                    <p><Trans>ARE_YOU_SURE</Trans><br/>
                    <b>You're about to modify this refund:</b><br/>
                        <b>Amount: {convertToFiat(this.state.selectedRefund.amount_refund_token)}</b>
                        <br />
                        {this.state.selectedRefund && ("Note:" + this.state.selectedRefund.note)}</p>
                );
                break;
            case "delete_refund":
                title = <Trans>SEND_NEW_REFUND</Trans>;
                onConfirm = () => this.sendDeleteRefund();
                onCancel = () => this.hideAlert();
                confirmBtnText = (this.state.alert === null) ? <Trans>SEND</Trans> : <Trans>ALERT_LOADING</Trans>;
                cancelBtnText = <Trans>CANCEL</Trans>;
                paragraph = (
                    <p><Trans>ARE_YOU_SURE</Trans><br/>
                        <b>You're about to delete this refund:</b><br/>
                        <b>Amount: {this.state.amountNewRefund}</b>
                        <br />
                        {this.state.noteNewRefund && ("Note:" + this.state.noteNewRefund)}</p>
                );
                break;
            default: return;
        }

        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{
                        display: "block",
                        marginTop: "-100px"
                    }}
                    title={title}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    confirmBtnBsStyle="warning"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={confirmBtnText}
                    cancelBtnText={cancelBtnText}
                    showCancel
                    showLoaderOnConfirm>
                    {paragraph}
                </SweetAlert>
            )
        });
    }


    async generateInvoice() {
        if (this.validateEmail(this.state.to_email) && this.state.routeId != null) {
            try {
                const response = await generateInvoice(this.state.to_email, this.state.routeId);
                if (response.data.success) {
                    this.showSuccessAlert();
                }
            } catch (e) {
                this.setErrorAlert(<Trans>ERROR_SENDING_INVOICE</Trans>);
            }
        } else {
            this.setErrorAlert(<Trans>INVALID_EMAIL_OR_ROUTE</Trans>);
        }
    }

    async sendNewRefund() {
        try {
            const refund = {
                ride_request_id: this.state.rideObj.ride_request_id,
                amount_refund_token: convertToToken(this.state.amountNewRefund, new Date(), false),
                note: this.state.noteNewRefund || "",
                rider_account: this.state.rideObj.rider
            }
            await sendNewRefund(refund);

            this.showSuccessAlert();
        } catch (e) {
            this.setErrorAlert(<Trans>ERROR_SENDING_INVOICE</Trans>);
        }
    }

    async sendModifyRefund() {
        try {
            await sendModifyRefund(this.state.selectedRefund);

            this.showSuccessAlert();
        } catch (e) {
            this.setErrorAlert(<Trans>ERROR_SENDING_INVOICE</Trans>);
        }
    }

    async sendDeleteRefund() {
        try {
            await sendDeleteRefund(this.state.selectedRefund.id);

            this.showSuccessAlert();
        } catch (e) {
            this.setErrorAlert(<Trans>ERROR_SENDING_INVOICE</Trans>);
        }
    }

    setErrorAlert = (message) => {
        let alertComponent = (
            <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title={<Trans>ERROR_ALERT</Trans>}
                onConfirm={() => this.hideAlert()}
                confirmBtnBsStyle="info"
            >
                <p><Trans>ERROR_ALERT</Trans> : {message}</p>
            </SweetAlert>
        );
        this.setState({ alert: alertComponent });
    }

    validateEmail = (email) => {
        //Correct use of regex
        //eslint-disable-next-line
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    onEmailChange = async (e) => {
        const to_email = e.target.value;
        this.setState({ to_email });
    }

    onNoteNewRefund = async (e) => {
        const noteNewRefund = e.target.value;
        this.setState({ noteNewRefund });
    }

    onAmountNewRefund = async (e) => {
        const amountNewRefund = e.target.value;
        this.setState({ amountNewRefund });
    }

    onNoteModifyRefund = async (e) => {
        const noteModifyRefund = e.target.value;
        this.setState(prevState => ({
            selectedRefund: {
                ...prevState.selectedRefund,
                note: noteModifyRefund,
            },
        }));
    }

    onAmountModifyRefund = async (e) => {
        const amountModifyRefund = e.target.value;
        this.setState(prevState => ({
            selectedRefund: {
                ...prevState.selectedRefund,
                amount_refund_token: convertToToken(amountModifyRefund, new Date(), false),
            },
        }));
    }

    showHideView = (view) => {
        if (!this.state.dataDidLoad)
            return;

        if (this.state.showView) {
            if (this.state.viewChoice === view) {
                this.setState({ showView: false });
            } else {
                this.setState({ viewChoice: view });
            }
        } else {
            this.setState({ viewChoice: view, showView: true });
        }
    }

    render() {
        //SHOW INFO TABLE
        let bigIconInfo,
            iconTextInfo,
            iconInfo;
        if (this.state.showView && this.state.viewChoice === "info") {
            bigIconInfo = "fa fa-info text-success";
            iconTextInfo = (
                <Trans>HIDE_STAT_CARD_HINT</Trans>
            );
            iconInfo = "fa fa-close";
        } else {
            bigIconInfo = "fa fa-info text-gray";
            iconTextInfo = (
                <Trans>INFO_STAT_CARD_HINT</Trans>
            );
            iconInfo = "fa fa-info";
        }
        let cardInfo = (
            <div
                style={{
                    cursor: "pointer"
                }}
                onClick={(e) => this.showHideView("info")}>
                <StatsCard
                    bigIcon={<i className={bigIconInfo} />}
                    statsText={<Trans>INFO_STAT_CARD_TITLE</Trans>}
                    statsIcon={<i className={iconInfo} />}
                    statsIconText={iconTextInfo}
                />
            </div>
        )
        let viewInfo = (this.state.rideObj)
            ? (<RideDetailsTable rideObj={this.state.rideObj} history={this.props.history} />)
            : <div></div>;

        //SHOW ACTIONS
        let bigIconAction,
            iconTextAction,
            iconAction;
        if (this.state.showView && this.state.viewChoice === "action") {
            bigIconAction = "fa fa-car text-success";
            iconTextAction = (
                <Trans>HIDE_STAT_CARD_HINT</Trans>
            );
            iconAction = "fa fa-close";
        } else {
            bigIconAction = "fa fa-car text-gray";
            iconTextAction = <Trans>ACTIONS_STAT_CARD_HINT</Trans>;
            iconAction = "fa fa-info";
        }
        let cardAction = (
            <div
                style={{
                    cursor: "pointer"
                }}
                onClick={(e) => this.showHideView("action")}>
                <StatsCard
                    bigIcon={< i className={
                        bigIconAction
                    } />}
                    statsText={<Trans>ACTIONS_STAT_CARD_TITLE</Trans>}
                    statsIcon={<i className={iconAction} />}
                    statsIconText={iconTextAction}
                />
            </div>
        )

        //invoice
        let buttonSendInvoice = null;
        if (this.state.to_email != null) {
            buttonSendInvoice = <CustomButton
                bsStyle="success"
                pullRight
                type="submit"
                onClick={() => {
                    this.confirmAlert("send_invoice");
                }}>
                <Trans>RIDE_ACTION_SEND_INVOICE</Trans>
            </CustomButton>
        }

        let buttonSendNewRefund = null;
        if (this.state.noteNewRefund != null && this.state.amountNewRefund != null) {
            buttonSendNewRefund = <CustomButton
                bsStyle="success"
                pullRight
                type="submit"
                onClick={() => {
                    this.confirmAlert("new_refund");
                }}>
                <Trans>SEND_NEW_REFUND</Trans>
            </CustomButton>
        }

        let buttonSendModifyRefund = null;
        if (this.state.selectedRefund != null) {
            buttonSendModifyRefund = <CustomButton
                bsStyle="success"
                pullRight
                type="submit"
                onClick={() => {
                    this.confirmAlert("modify_refund");
                }}>
                <Trans>SEND_NEW_REFUND</Trans>
            </CustomButton>
        }

        let viewAction = (
            <Row>
                <Col lg={12} sm={6}>
                    <Card title={<Trans>RIDE_ACTION_TITLE</Trans>}
                        content={
                            <table className="table" >
                                <tbody>
                                    <tr>
                                        <td>
                                            <Trans>RIDE_ACTION_SEND_INVOICE</Trans>
                                        </td>
                                        <td>
                                            <FormInputs
                                                ncols={["col-md-12"]}
                                                proprieties={[{
                                                    label: <Trans>RIDE_INVOICE_SEND_TO_EMAIL</Trans>,
                                                    type: "text",
                                                    bsClass: "form-control",
                                                    onChange: this
                                                        .onEmailChange
                                                        .bind(this),
                                                    value: this.state.email
                                                }
                                                ]} />
                                        </td>
                                        <td className="td-actions text-right">
                                            {buttonSendInvoice}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>}
                    />
                    <Card title={<Trans>Refunds</Trans>}
                        content={
                            <>
                                {this.state.refunds.map(refund => {
                                    const color = (this.state.selectedRefund && this.state.selectedRefund.id == refund.id) ? "#D3D3D3" : "white";
                                    return (
                                        <span style={{ display: "flex", alignItems: "center", backgroundColor: color }}>
                                            <button style={{ margin: 0, marginRight: "5px" }} onClick={() => this.setState({ selectedRefund: JSON.parse(JSON.stringify(refund)) })}>Select for modification</button>
                                            <b>  <Trans>CHARGE_TABLE_TOTAL</Trans></b> {convertToFiat(refund.amount_refund_token)} | {refund.processed ? "✔️" : "⌛"} | {refund.note}
                                        </span>
                                    )
                                })}
                                {this.state.selectedRefund && <button onClick={() => this.confirmAlert("delete_refund")}>Delete selected refund</button>}
                                <table className="table" >
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Trans>CREATE_A_REFUND</Trans>
                                            </td>
                                            <td>
                                                <FormInputs
                                                    ncols={["col-md-12"]}
                                                    proprieties={[
                                                        {
                                                            label: <span><Trans>REFUND_AMOUNT</Trans> ({this.props.currencyCode})</span>,
                                                            type: "number",
                                                            bsClass: "form-control",
                                                            onChange: this
                                                                .onAmountNewRefund
                                                                .bind(this),
                                                            value: this.state.amountNewRefund
                                                        }
                                                    ]} />
                                                <FormInputs
                                                    ncols={["col-md-12"]}
                                                    proprieties={[
                                                        {
                                                            label: <Trans>REFUND_NOTE</Trans>,
                                                            type: "text",
                                                            bsClass: "form-control",
                                                            onChange: this
                                                                .onNoteNewRefund
                                                                .bind(this),
                                                            value: this.state.noteNewRefund
                                                        }
                                                    ]} />
                                            </td>
                                            <td className="td-actions text-right">
                                                {buttonSendNewRefund}
                                            </td>
                                        </tr>
                                        <tr>
                                            {this.state.selectedRefund && <>
                                                <td>
                                                    <Trans>MODIFY_A_REFUND</Trans>
                                                </td>
                                                <td>
                                                    <FormInputs
                                                        ncols={["col-md-12"]}
                                                        proprieties={[
                                                            {
                                                                label: <span><Trans>REFUND_AMOUNT</Trans> ({this.props.currencyCode})</span>,
                                                                type: "number",
                                                                bsClass: "form-control",
                                                                onChange: this
                                                                    .onAmountModifyRefund
                                                                    .bind(this),
                                                                value: convertToFiat(this.state.selectedRefund.amount_refund_token, new Date(), false)
                                                            }
                                                        ]} />
                                                    <FormInputs
                                                        ncols={["col-md-12"]}
                                                        proprieties={[
                                                            {
                                                                label: <Trans>REFUND_NOTE</Trans>,
                                                                type: "text",
                                                                bsClass: "form-control",
                                                                onChange: this
                                                                    .onNoteModifyRefund
                                                                    .bind(this),
                                                                value: this.state.selectedRefund.note || ""
                                                            }
                                                        ]} />

                                                </td>
                                                <td className="td-actions text-right">
                                                    {buttonSendModifyRefund}
                                                </td></>}


                                        </tr>
                                    </tbody>
                                </table></>}
                    />
                </Col>
            </Row >
        );

        let view;
        if (this.state.showView) {
            switch (this.state.viewChoice) {
                case "info":
                    view = viewInfo;
                    break;
                case "action":
                    view = viewAction;
                    break;
                default: break;
            }
        } else {
            view = <div></div>;
        }

        //LOADING DATA SCREEN
        let loading = (this.state.dataDidLoad)
            ? <div></div>
            : <LoadingIcon />;
        return (
            <div className="main-content">
                {this.state.alert}
                <Grid fluid>
                    <Row>
                        <Col md={6}>
                            {cardInfo}
                        </Col>
                        <Col md={6}>
                            {cardAction}
                        </Col>
                    </Row>
                    {loading}
                    {view}
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currencyCode: state.community.comm_currency_code,
    };
};

export default connect(mapStateToProps)(RideDetails);