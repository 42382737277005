import React, { Component } from "react";
import TableAPI from "../..//Tables/TableAPI";
import { Trans } from "react-i18next";
import { getDriverSubmissionsArchive } from "../../../helpers/apiHelper";

/* DEAD. DO NOT USE */

class DriverSubmissionsArchive extends Component {

    /*onClickDetails = (phone) => {
        this.props.history.push({
            pathname: '/driver_submission',
            search: '?phone=' + phone,
            state: { phone }
        })
    }

    render() {
        return (
            <TableAPI
                fetchData={getDriverSubmissionsArchive}
                topDistance="25px"
                className="-highlight"
                columns={[
                    {
                        Header: <Trans>Full Name</Trans>,
                        id: "full_name",
                        accessor: (el) => { return el.full_name },
                        width: 500,
                        canSort: true,
                        canFilter: true,
                    },
                    {
                        Header: <Trans>Phone Number</Trans>,
                        id: "phone",
                        accessor: (el) => { return el.phone },
                        width: 500,
                        canFilter: true,
                    },
                    {
                        Header: <Trans>City</Trans>,
                        id: "city",
                        accessor: (el) => { return el.city },
                        width: 500,
                        canSort: true,
                        canFilter: true,
                    },
                ]}
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                        return {
                            onClick: (e) => {
                                this.onClickDetails(rowInfo.original.phone)
                            },
                        }
                    } else {
                        return {}
                    }
                }} />
        )
    }*/
}

export default DriverSubmissionsArchive;