import 'react-datetime/css/react-datetime.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

import { Col, Grid, Row } from "react-bootstrap";
import React, { Component } from "react";
import { getCompletedPayments, getCompletedPaymentsTotal, getDriverCashCollections } from "../../helpers/apiHelper";

import { CSVLink } from 'react-csv';
import CurrencyFormat from 'react-currency-format';
import Radio from '../../components/CustomRadio/CustomRadio';
import StatsCard from "../../components/Card/StatsCard";
import TableAPI from "../Tables/TableAPI";
import { Trans } from "react-i18next";
import { connect } from 'react-redux';
import { convertToFiat, getSymbol } from '../../helpers/paymentHelper';
import i18next from 'i18next';
import moment from 'moment';
import i18n from '../../i18n';
import SweetAlert from "react-bootstrap-sweetalert";
import {issueDriverCashPaymentCollection}  from "../../helpers/apiHelper";
import {
  Button,
  DropdownButton,
  MenuItem,
} from "react-bootstrap";

class CashCollection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            isAlertLoading: false,
        };
    }

    componentDidMount = () => {
        if (!this.props.auth) {
            this.props.history.push('/');
        }
    }

    hideAlert() {
        this.setState({ alert: null });
    }

    hideAfterconfirmAlert() {
        this.setState({ alert: null });
    }

    showErrorAlert = (error) => {
        this.setState({
          alert: (
            <SweetAlert
              danger
              style={{
                display: "block",
                marginTop: "-100px",
              }}
              title="Error"
              onConfirm={() => this.hideAlert()}
              confirmBtnBsStyle="info"
            >
              <p>
                An Error has occurred : <br></br>
                {error.message}{" "}
              </p>
            </SweetAlert>
          ),
        });
    };

    showSuccessAlert = () => {
        this.setState({
          alert: (
            <SweetAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Confirmation"
              onConfirm={() => this.hideAfterconfirmAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="success"
            >
              <p>
                <Trans>ACTION_SUCCESS</Trans>
              </p>
            </SweetAlert>
          ),
        });
    };

    getCashCollections= async (data) => {
        const cashCollections = await getDriverCashCollections(data);
        console.log('cashCollections', cashCollections);
        for (var i = 0; i < cashCollections.length;i++){
            cashCollections[i]['actions'] = this.getRowButtons(cashCollections[i]);
        }

        
        return cashCollections;

    }

    issueCashCollectionEntry = (cashCollectionEntry) => {
        const self = this;
        issueDriverCashPaymentCollection(cashCollectionEntry).then(function (response) {
          if (response.status === 200) {
            self.showSuccessAlert();
          } else {
            self.showErrorAlert(response);
          }
        });
    }

    confirmAlert = (cashCollectionEntry, action) => {
        let title, onConfirm, onCancel, confirmBtnText, cancelBtnText, paragraph;

        switch (action) {
          case "issue_cash_collection_entry":
            title = <Trans>RELEASE_CASH_COLLECTION_ENTRY_TITLE</Trans>;
            onConfirm = () => this.issueCashCollectionEntry(cashCollectionEntry);
            onCancel = () => this.hideAlert();
            confirmBtnText =
              this.state.alert === null ? (
                <Trans>RELEASE_CASH_PAYMENT_ENTRY_CONFIRM</Trans>
              ) : (
                <Trans>ALERT_LOADING</Trans>
              );
            cancelBtnText = <Trans>CANCEL</Trans>;
            paragraph = (
              <p>
                <Trans>RELEASE_CASH_PAYMENT_ENTRY_BODY</Trans>
                <h2>{ cashCollectionEntry.first_name + " " + cashCollectionEntry.last_name }.</h2>
                <h2>{convertToFiat(cashCollectionEntry.amount_token)}.</h2>
                <br></br>
                <Trans>WANT_TO_PROCEED</Trans>
              </p>
            );
            break;
        }
        this.setState({
          alert: (
            <SweetAlert
              warning
              style={{
                display: "block",
                marginTop: "-100px",
              }}
              title={title}
              onConfirm={onConfirm}
              onCancel={onCancel}
              confirmBtnBsStyle="warning"
              cancelBtnBsStyle="danger"
              confirmBtnText={confirmBtnText}
              cancelBtnText={cancelBtnText}
              showCancel="true"
              showLoaderOnConfirm="true"
            >
              {paragraph}
            </SweetAlert>
          ),
        });
  };

    getRowButtons = (cashCollectionEntry) => {
        const releaseBttn = (
            <MenuItem>
              <Button
                onClick={() => this.confirmAlert(cashCollectionEntry, "issue_cash_collection_entry")}
                bsSize="xsmall"
                bsStyle="warning"
                style={{margin:"0px"}}
              >
                <Trans>RELEASE_DRIVER_CASH_PAYMENT</Trans>
              </Button>
            </MenuItem>
        );

        

        return (
                <DropdownButton
                    title={i18n.t("actions")}
                    style={{ padding: "4%", margin: 0 }}
                    pullRight
                >
                    {releaseBttn}
                </DropdownButton>
            );
    };

    onDriverEosClick = (accountName) => {
        window.open(`#/driver_edit/?name=${accountName}`);
    }

    render() {
        let iconContext = "";
        if (this.state.startDate && this.state.endDate) {
            iconContext = i18next.t("From") + this.state.startDate.format('YYYY-MM-DD') + i18next.t("To") + this.state.endDate.format('YYYY-MM-DD');
        }

        return (
            <div className="content">
                {this.state.alert}
                <Grid fluid>
                    <br />

                    <Row>
                        <Col md={12}>
                            <TableAPI
                                fetchData={this.getCashCollections}
                                columns={[
                                    {
                                        Header: <Trans>Driver</Trans>,
                                        canSort: false,
                                        canFilter: false,
                                        id: "driver",
                                        width: 130,
                                        accessor: (payment) => {
                                            return payment.driver;
                                        }
                                    },
                                    {
                                        Header: <Trans>Full Name</Trans>,
                                        canSort: false,
                                        canFilter: false,
                                        id: "full_name",
                                        width: 180,
                                        accessor: (payment) => {
                                            return payment.first_name + " " + payment.last_name;
                                        },
                                        Cell: e => <span style={{ color: '#70CEEC', cursor: 'pointer' }} onClick={() => { this.onDriverEosClick(e.original.driver); }}> {e.value} </span>

                                    },
                                    {
                                        Header: <Trans>Modified date</Trans>,
                                        canFilter: false,
                                        canSort: false,
                                        filterMode: "date",
                                        id: "last_updated_time",
                                        width: 180,
                                        accessor: (payment) => {
                                            return moment.utc(payment.last_updated_time).local().format('YYYY-MM-DD HH:mm:ss');
                                        },
                                    },
                                    {
                                        Header: <Trans>Created date</Trans>,
                                        canFilter: false,
                                        canSort: false,
                                        defaultSort: true,
                                        filterMode: "date",
                                        id: "entry_created_time",
                                        width: 180,
                                        accessor: (payment) => {
                                            return moment.utc(payment.entry_created_time).local().format('YYYY-MM-DD HH:mm:ss');
                                        },
                                    },
                                    {
                                        Header: <Trans>Amount</Trans>,
                                        canSort: false,
                                        id: "amount_token",
                                        width: 180,
                                        accessor: (payment) => {
                                            return convertToFiat(payment.amount_token.toFixed(2));
                                        },
                                    },
                                    {
                                        Header: <Trans>actions</Trans>,
                                        accessor: "actions",
                                        filterable: false,
                                        canFilter: false,
                                        resizable: false,
                                        width: 75,
                                        Cell: (e) => (
                                            <span
                                              onClick={(event) => {
                                                event.stopPropagation();
                                              }}
                                            >
                                              {" "}
                                              {e.value}{" "}
                                            </span>
                                        ),
                                        getProps: (state, rowInfo, column) => {
                                            return {
                                              style: {
                                                overflow: "visible",
                                              },
                                            };
                                        },
                                    }
                                    
                                ]}/>
                        </Col>
                    </Row>
                </Grid>
                <p></p>
                <br></br>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.userData.isAuthenticated,
        pubKey: state.userData.publicKey,
        privKey: state.userData.privateKey,
        community: state.userData.communityAccount,
        cityName: state.userData.cityName,
    };
};

export default connect(mapStateToProps)(CashCollection);
