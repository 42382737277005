import {
    Button,
    Col,
    DropdownButton,
    Grid,
    MenuItem,
    Row
} from "react-bootstrap";
import React, { Component } from "react";
import { deleteDriver, getDrivers } from "../../helpers/apiHelper";

import Card from "../../components/Card/Card.jsx";
import StatsCard2 from "../../components/Card/StatsCard2";
import SweetAlert from "react-bootstrap-sweetalert";
import TableAPI from "../Tables/TableAPI";
import { Trans } from "react-i18next";
import { connect } from 'react-redux';

class DriversList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            driversList: {},
            error: '',
            showAddDriverView: false,
            showDefaultView: true,
            alert: null,
            actionLoadingDeleteDriver: false
        };
    }

    async componentDidMount() {
    }

    onClickEdit = (phone) => {
        //console.log('eosAccount', driverAccount);
        //window.open(`#/driver_edit/?name=${driverAccount}`);
        window.open(`#/drivers/submissions/${phone}`);
    }

    hideAfterconfirmAlert() {
        this.setState({
            alert: null
        });
        window.location.reload();
    };

    hideAlert() {
        this.setState({
            alert: null
        });
    };

    errorHandlerAlert = (error) => {
        console.log("error", error.message);
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error"
                    onConfirm={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    <p>An Error has occurred : {error.message}</p>
                </SweetAlert>
            )
        });
    };

    showDeactivateConfirm = (accounData) => {
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={<Trans>DEACTIVATE_TITLE</Trans>}
                    onConfirm={() => this.hideAfterconfirmAlert()}
                    confirmBtnBsStyle="info"
                    closeOnClickOutside={false}
                    allowEscape={false}
                    showCancel={false}
                    showConfirm={true}
                >
                    <p>{accounData} <Trans>DEACTIVATION_SUCCESSFUL</Trans>.</p>
                </SweetAlert>
            )
        });
    }

    deactivateDriverAlert = (accountName) => {
        if (!this.state.actionLoadingDeleteDriver) {
            //about to deactivate driver
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Are you sure?"
                        onConfirm={() => this.deactivateDriver(accountName)}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="info"
                        cancelBtnBsStyle="danger"
                        confirmBtnText={<Trans>YES_DO_IT</Trans>}
                        cancelBtnText={<Trans>CANCEL</Trans>}
                        showCancel
                    >
                        <p> <Trans>DEACTIVATION_CONFIRMATION</Trans>? </p>
                    </SweetAlert>
                )
            });
        } else {
            //in loading state of deactivating driver
            this.setState({
                alert: (
                    <SweetAlert
                        style={{ display: "block", marginTop: "-100px" }}
                        title={<Trans>SENDING</Trans>}
                        closeOnClickOutside={false}
                        allowEscape={false}
                        showCancel={false}
                        showConfirm={false}
                    >
                        <div class="spinner">
                            <div class="bounce1"></div>
                            <div class="bounce2"></div>
                            <div class="bounce3"></div>
                        </div>
                        <p>
                            <Trans>SENDING_DEACTIVATING_DRIVER</Trans>
            </p>
                    </SweetAlert>
                )
            });
        }
    }

    deactivateDriver = async (accountName) => {
        await this.setState({ actionLoadingDeleteDriver: true });
        this.hideAlert();
        this.deactivateDriverAlert(accountName);
        var that = this;
        deleteDriver(accountName).then(response => {
            this.setState({ actionLoadingDeleteDriver: false });
            that.showDeactivateConfirm(accountName);
        }).catch(function (error) {
            that.errorHandlerAlert(error);
        })
    };

    dynamicShowAddDriverView = () => {
        let doesShowAddDriverView = this.state.showAddDriverView;
        let doesShowDefaultView = this.state.showDefaultView;
        this.setState({ showAddDriverView: !doesShowAddDriverView });
        this.setState({ showDefaultView: !doesShowDefaultView });
    };

    onDriverClick = (phone) => {
        window.open(`#/drivers/submissions/${phone}`);
    }

    dynamicShowDefaultView = () => {
        this.setState({ showDefaultView: true });
        this.setState({ showAddDriverView: false })
    };

    getStyleFromRow = (row,permissionType) => {
        if (row){
            if (row.original.delivery_permission === true && permissionType === 1){
                return {
                    style: {color: "green"}
                }
            }
            else if (row.original.ride_sharing_permission === true && permissionType === 0) {
                return {
                    style: {color: "green"}
                }
            }else {
                return{
                    style: { className: "-highlight", color: 'red'}
                }
            }
        } else {
            return {
                style: { className: "-highlight", color: 'red'}
            }
        }
    }

    getTrProps = (_, rowInfo, __) => {
        if (rowInfo) {
          return {
            style: {
              backgroundColor: rowInfo.original.is_flagged ? 'rgba(255, 0, 0, 0.1)' : 'white'
            }
          }
        }
        return {};
      }

    render() {
        const styleButtonBack = {
            marginBottom: '15px',
            marginTop: '15px', //////////// Alignement du bouton avec le DashBoard
            marginLeft: '17px',  ////////// Alignement avec le texte

        };

        let showAddDriverView = null;

        let showDefaultView = null;
        if (this.state.showDefaultView) {
            showDefaultView = (
                <div className="container-fluid">
                    <br />
                    <Grid fluid>
                            <Row>
                            <Col md={12} xs={12} style={{padding: "0 2.5vw"}}>

                            </Col>
                            <Col md={12}>

                                <Card
                                    plain
                                    ctTableFullWidth
                                    ctTableResponsive
                                    content={
                                        <TableAPI
                                            fetchData={getDrivers}
                                            getTrProps={this.getTrProps}
                                            extraFilters={{
                                                order_type: 'asc',
                                                order_by: 'activity_timestamp',
                                            }}
                                            pageSize={25}
                                            columns={[
                                                {
                                                    Header: <Trans>IS_DRIVER_ONLINE</Trans>,
                                                    canFilter: true,
                                                    filterMode: 'checkbox',
                                                    accessor: d => d.is_online,
                                                    id: 'is_online',
                                                    width: 80,
                                                    Cell: row => (
                                                        <span>
                                                            <span style={{
                                                                color: row.value === true ? '#57d500'
                                                                    : '#D4D4D4',
                                                                transition: 'all .3s ease'
                                                            }}>
                                                                &#x25cf;
                                                          </span> {
                                                                row.value === true ? 'Online'
                                                                    : 'Offline'
                                                            }
                                                        </span>
                                                    )
                                                },
                                                {
                                                    Header: <Trans>phone</Trans>,
                                                    canFilter: true,
                                                    id: 'phone',
                                                    width: 150,
                                                    accessor: (el) => { return el.profile.phone },
                                                },
                                                {
                                                    Header: <Trans>CUSTOMER_EMAIL</Trans>,
                                                    canFilter: true,
                                                    id: 'email',
                                                    width: 250,
                                                    accessor: (el) => { return el.profile.email },
                                                },
                                                {
                                                    Header: <Trans>First Name</Trans>,
                                                    canFilter: true,
                                                    canSort: true,
                                                    id: 'first_name',
                                                    width: 150,
                                                    accessor: (el) => { return el.profile.first_name },
                                                    Cell: e => <span style={{ color: '#70CEEC', cursor: 'pointer' }} onClick={() => { this.onDriverClick(e.original.profile.phone); }}> {e.value} </span>
                                                },
                                                {
                                                    Header: <Trans>Last Name</Trans>,
                                                    canFilter: true,
                                                    canSort: true,
                                                    id: 'last_name',
                                                    width: 150,
                                                    accessor: (el) => { return el.profile.last_name },
                                                    Cell: e => <span style={{ color: '#70CEEC', cursor: 'pointer' }} onClick={() => { this.onDriverClick(e.original.profile.phone); }}> {e.value} </span>
                                                },
                                                {
                                                    Header: <Trans>App</Trans>,
                                                    id: "app_version",
                                                    accessor: (el) => { return el.app_version },
                                                    width: 50,
                                                    Cell: row => (
                                                        <span>{row.value}</span>
                                                    ),
                                                },
                                                {
                                                    Header: <Trans>LAST_ACTIVITY</Trans>,
                                                    id: "activity_timestamp",
                                                    accessor: (el) => { return el.activity_timestamp },
                                                    canSort: true,
                                                    filterMode: "date",
                                                    width: 150,
                                                    Cell: row => (
                                                        <span>{row.value}</span>
                                                    ),
                                                },
                                                {
                                                    Header: <Trans>actions</Trans>,
                                                    width: 100,
                                                    accessor: (el) => {
                                                        const _key = el.profile.phone;
                                                        let that = this;
                                                        return <div className="actions-left">
                                                            <DropdownButton title={<Trans>actions</Trans>} style={{ padding: "4%", margin: 0 }} pullRight>
                                                                <MenuItem>
                                                                    <Button
                                                                        style={{ marginRight: '5px' }}
                                                                        onClick={() => this.onClickEdit(_key)} bsSize="xsmall" ><Trans>Edit driver</Trans></Button>
                                                                </MenuItem>
                                                                <MenuItem>
                                                                    <Button onClick={() => that.deactivateDriverAlert(_key)} bsSize="xsmall" bsStyle="danger" > <Trans>DEACTIVATE_DRIVER</Trans> </Button>
                                                                </MenuItem>
                                                            </DropdownButton>
                                                        </div>
                                                    },
                                                    getProps: (state, rowInfo, column) => {
                                                        return {
                                                            style: {
                                                                overflow: 'visible'
                                                            }
                                                        }
                                                    },
                                                    filterable: false,
                                                    id: 'actions'
                                                }
                                            ]}
                                            className="-highlight"
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                    </Grid >
                    <p></p>
                    <br></br>
                    <br></br>
                </div >
            )
        }

        return (
            <div className="content">
                {this.state.alert}
                {showDefaultView}
                {showAddDriverView}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.userData.isAuthenticated,
        community: state.userData.communityAccount,
        cityName: state.community.comm_name
    };
};
export default connect(mapStateToProps)(DriversList);
