import {
  Button,
  Col,
  DropdownButton,
  Grid,
  MenuItem,
  Row,
} from "react-bootstrap";
import React, { Component } from "react";
import {
  cancelCommunityCustomerRide,
  dispatchScheduledRide,
  getArchivedScheduledRides,
} from "../../../helpers/apiHelper";
import { convertToFiat } from "../../../helpers/paymentHelper";

import Card from "../../../components/Card/Card.jsx";
import RideServiceNames from "../../../const/RideServiceNames";
import SweetAlert from "react-bootstrap-sweetalert";
import TableAPI from "../../Tables/TableAPI";
import { Trans } from "react-i18next";
import i18n from "i18next";
import { utcStringToLocalString } from "../../../helpers/dateHelper";
import { Link } from "react-router-dom";

export default class ArchivedScheduledRidesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      isAlertLoading: false,
    };
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  hideAfterconfirmAlert() {
    this.setState({ alert: null });
  }

  loadingHandlerAlert = () => {
    this.setState({
      alert: <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={<Trans>SENDING</Trans>}
        closeOnClickOutside={false}
        allowEscape={false}
        showCancel={false}
        showConfirm={false}
      >
        <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
        <p>
          <Trans>SENDING_REQUEST</Trans>
        </p>
      </SweetAlert>
    });
  };

  showSuccessAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Confirmation"
          onConfirm={() => window.location.reload()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
        >
          <p>
            <Trans>ACTION_SUCCESS</Trans>
          </p>
        </SweetAlert>
      ),
    });
  };

  showRideInfoAlert = (row) => {
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          onConfirm={() => this.hideAfterconfirmAlert()}
          confirmBtnBsStyle="success"
        >
          <h5>#{row.chain_route_id}</h5>
          <div style={{ textAlign: "center" }}>
            <div style={{ width: "60%", display: "inline-block" }}>
              <p style={{ textAlign: "left" }}>
                <b>
                  <Trans>DATE</Trans>
                </b>
                : {row.scheduled_datetime} <br />
                <b>
                  <Trans>FROM_ACCOUNT</Trans>
                </b>
                : {row.from_address} <br />
                <b>
                  <Trans>TO_ACCOUNT</Trans>
                </b>{" "}
                : {row.to_address} <br />
                <b>
                  <Trans>RIDE_REQUEST</Trans>         
                </b>
                : 
                <Link to={`/ride-request-summary?reqId=${row.ride_request_id}`} target="_blank" rel="noopener noreferrer">
                  {row.ride_request_id}
                </Link> <br />
                <b>
                  <Trans>DRIVER</Trans>
                </b>
                : {row.driver_name} <br />
                <b>
                  <Trans>RIDE_SERVICE_NAME</Trans>
                </b>
                : {row.ride_service_name} <br />
                <b>
                  <Trans>RIDER</Trans>
                </b>
                : {row.rider_name || row.rider} <br />
                <b>
                  <Trans>TIP_AMOUNT</Trans>
                </b>
                : {convertToFiat(row.tip_token_amount, row.scheduled_datetime)} <br />
                {row.note && 
                <div>
                <b>
                  <Trans>NOTE</Trans>
                </b>
                : {row.note} <br />
                </div>}
                {row.total_order_amount >= 0 && 
                <div>
                <b>
                  <Trans>TOTAL_ORDER_AMOUNT</Trans>
                </b>
                : {convertToFiat(row.total_order_amount, row.scheduled_datetime)} <br />
                </div>}
              </p>
            </div>
          </div>
        </SweetAlert>
      ),
    });
  };

  showErrorAlert = (error) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{
            display: "block",
            marginTop: "-100px",
          }}
          title="Error"
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          <p>
            An Error has occurred : <br></br>
            {error.message}{" "}
          </p>
        </SweetAlert>
      ),
    });
  };

  confirmAlert = (scheduledRide, action) => {
    let title, onConfirm, onCancel, confirmBtnText, cancelBtnText, paragraph;

    switch (action) {
      case "ride_dispatch":
        title = <Trans>REDISPATCH_RIDE_ALERT_TITLE</Trans>;
        onConfirm = () => this.dispatchRide(scheduledRide.tracking_id);
        onCancel = () => this.hideAlert();
        confirmBtnText =
          this.state.alert === null ? (
            <Trans>REDISPATCH_RIDE_ALERT_YES</Trans>
          ) : (
            <Trans>ALERT_LOADING</Trans>
          );
        cancelBtnText = <Trans>CANCEL</Trans>;
        paragraph = (
          <p>
            <Trans>REDISPATCH_RIDE_ALERT_BODY</Trans>
            <br></br>
            <Trans>WANT_TO_PROCEED</Trans>
          </p>
        );
        break;
      case "cancel_ride":
        title = <Trans>CANCEL_ACTIVE_RIDE_ALERT_TITLE</Trans>;
        onConfirm = async () => {
          this.loadingHandlerAlert();
          const res = await cancelCommunityCustomerRide(scheduledRide.ride_request_id);
          if (res.data.success) {
            this.showSuccessAlert();
          } else {
            this.showErrorAlert(res);
          }
        }
        onCancel = () => this.hideAlert();
        confirmBtnText =
          this.state.alert === null ? (
            <Trans>CANCEL_ACTIVE_RIDE_ALERT_CONFIRM</Trans>
          ) : (
            <Trans>ALERT_LOADING</Trans>
          );
        cancelBtnText = <Trans>CANCEL</Trans>;
        paragraph = (
          <p>
            <Trans>CANCEL_ACTIVE_CORP_RIDE_ALERT_BODY</Trans>
            <br></br>
            <Trans>WANT_TO_PROCEED</Trans>
          </p>
        );
        break;
      default: break;
    }
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{
            display: "block",
            marginTop: "-100px",
          }}
          title={title}
          onConfirm={onConfirm}
          onCancel={onCancel}
          confirmBtnBsStyle="warning"
          cancelBtnBsStyle="danger"
          confirmBtnText={confirmBtnText}
          cancelBtnText={cancelBtnText}
          showCancel="true"
          showLoaderOnConfirm="true"
        >
          {paragraph}
        </SweetAlert>
      ),
    });
  };

  dispatchRide = async (tracking_id) => {
    const self = this;
    const response = await dispatchScheduledRide(tracking_id);
    if (response.status === 200) {
      self.showSuccessAlert();
    } else {
      self.showErrorAlert(response);
    }
    console.log('response', response);
  }

  constructFormFromApi = async (data) => {
    const scheduledRides = await getArchivedScheduledRides(data);
    if (data.get_count) {
      return scheduledRides;
    }
    let formRows = {};
    for (var index in scheduledRides) {
      const row = await this.getFormRowFromScheduledRide(scheduledRides[index]);
      formRows[index] = row;
    }
    return formRows;
  };

  getFormRowFromScheduledRide = async (scheduledRide) => {
    let customerFirstName = scheduledRide.customer_first_name === "---" ? "" : scheduledRide.customer_first_name;
    let customerLastName = scheduledRide.customer_last_name === "---" ? "" : scheduledRide.customer_last_name;
    // The following "row" object is used by TableAPI
    let row = {
      scheduled_datetime: utcStringToLocalString(scheduledRide.scheduled_datetime),
      rider: scheduledRide.rider,
      ride_service_name: <Trans>{RideServiceNames[scheduledRide.ride_service_type_id]}</Trans>,
      from_address: scheduledRide.from_address,
      to_address: scheduledRide.to_address,
      order_number: scheduledRide.order_number,
      customer_name: customerFirstName + " " + customerLastName,
      customer_phone: scheduledRide.customer_phone,
      rider_name: scheduledRide.rider_name,
      pick_up_company_name: scheduledRide.pick_up_company_name,
      tip_token_amount: scheduledRide.tip_token_amount,
      note: scheduledRide.note,
      total_order_amount: scheduledRide.total_order_amount,
      ride_request_id: scheduledRide.ride_request_id, 
    };
    return row;
  };

  getRowButtonsFromScheduledRide = (scheduledRide) => {
    let menuCancelButton = <MenuItem>
      <Button
        onClick={() => this.confirmAlert(scheduledRide, "cancel_ride")}
        bsSize="xsmall"
        bsStyle="danger"
      >
        <Trans>CANCEL</Trans>
      </Button>
    </MenuItem>

    return (
      <DropdownButton
        title={i18n.t("actions")}
        style={{ padding: "4%", margin: 0 }}
      >
        {menuCancelButton}
      </DropdownButton>
    );
  };

  onRiderNameClick = (accountName) => {
    this.props.history.push({
      pathname: "/rider",
      search: "?name=" + accountName,
      state: { name: accountName },
    });
  };

  render() {
    return (
      <div className="content">
        {this.state.alert}
        <Grid fluid>
          <Row>
            <br />
            <Col md={12}>
              <Card
                plain
                ctTableFullWidth
                ctTableResponsive
                content={
                  <TableAPI
                    fetchData={this.constructFormFromApi}
                    getTrProps={(state, rowInfo) => {
                      return {
                        onClick: () => {
                          this.showRideInfoAlert(rowInfo.original);
                        },
                      };
                    }}
                    columns={[
                      {
                        Header: <Trans>DATE</Trans>,
                        canSort: true,
                        defaultSort: true,
                        id: "scheduled_datetime",
                        orderType: "desc",
                        accessor: "scheduled_datetime",
                        width: 150,
                        Cell: (e) => {
                          return <span>{e.value}</span>;
                        },
                      },
                      {
                        Header: <Trans>RIDE_SERVICE_NAME</Trans>,
                        id: "ride_service_name",
                        canFilter: true,
                        filterMode: "select",
                        width: 90,
                        options: [{ value: "", label: <Trans>NONE</Trans> }].concat(
                          [0, 1, 3].map(rideServiceType => {
                            return {
                              value: rideServiceType,
                              label: <Trans>{RideServiceNames[rideServiceType]}</Trans>
                            }
                        })),
                        accessor: "ride_service_name",
                      },
                      {
                        Header: <Trans>RIDER</Trans>,
                        id: "rider_name",
                        canFilter: true,
                        canSort: true,
                        accessor: "rider_name",
                        width: 140,
                        Cell: (e) => (
                          <Link
                            onClick={(e) => e.stopPropagation()}
                            to={`/rider?name=${e.original.rider}`}
                            target="_blank"
                            style={{ color: "#70CEEC", cursor: "pointer" }}
                            rel="noopener noreferrer"
                          >
                            {" "}
                            {e.value || e.original.rider}{" "}
                          </Link>
                        ),
                      },
                      {
                        Header: <Trans>FROM_ADDRESS</Trans>,
                        id: "from_address",
                        width: 160,
                        canFilter: true,
                        accessor: "from_address",
                      },
                      {
                        Header: <Trans>TO_ADDRESS</Trans>,
                        id: "to_address",
                        width: 160,
                        canFilter: true,
                        accessor: "to_address",
                      },
                      {
                        Header: <Trans>BUSINESS_NAME</Trans>,
                        id: "pick_up_company_name",
                        width: 100,
                        canFilter: true,
                        accessor: "pick_up_company_name",
                        Cell: (e) => (
                          <span>{e.row.pick_up_company_name}</span>
                        ),
                      },
                      {
                        Header: <Trans>ORDER_NUMBER</Trans>,
                        id: "order_number",
                        width: 80,
                        canFilter: true,
                        accessor: "order_number",
                      },
                      {
                        Header: <Trans>CUSTOMER_NAME</Trans>,
                        id: "customer_name",
                        width: 100,
                        canFilter: true,
                        accessor: "customer_name",
                      },
                      {
                        Header: <Trans>CUSTOMER_PHONE</Trans>,
                        id: "customer_phone",
                        width: 100,
                        canFilter: true,
                        accessor: "customer_phone",
                      },
                    ]}
                    className="-highlight"
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
        <p></p>
        <br></br>
      </div>
    );
  }
}

