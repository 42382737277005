
import React  from "react";
import { connect } from 'react-redux';
import { logout } from "../actions";
import { auth } from "../helpers/apiHelper";
import {store} from "../store/index";

class FetchAuthStrategy extends React.Component{   

    componentDidMount(){
        this.start();
    }

    execute = async () => {
        this.task_started = true;
        this.execute();
    };
 
    fetchAuthStrategy = () => {
        auth().then(data => {
            return data;
        }).catch(error => {
            store.dispatch(logout());
        });
    };

    start = () => {
        this.fetchAuthStrategy();
    }

    render () {
        return null;
    }
}
export default connect()(FetchAuthStrategy);