import React, { Component } from "react";
import DriverInfoForm from "../../components/DriverInfoForm/DriverInfoForm";
import { Trans } from "react-i18next";
import queryString from 'query-string';

class DriverEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
          selectedDriver : ""
        }
    }

    async componentWillMount(){
        const params = queryString.parse(this.props.location.search);
        const accountName = params.name;
        this.state = {selectedDriver: accountName};
      };

      redirectToTarget = () => {
        this.props.history.goBack();
      }

    render() {
      let defaultView = (
        //Driver Statistics are loaded inside the form so that the "Back" button
        //stays on top of the page.
        <DriverInfoForm dynamicShowDefaultView={this.redirectToTarget} 
                        titleCard={"Edit"}
                        buttonAction={'Edit'}
                        selectedDriver={this.state.selectedDriver}
        >
          <Trans>Edit driver</Trans>
        </DriverInfoForm>
      );
        return (
          <div className="content">
                {defaultView}
          </div>
         );
    }

}

export default DriverEdit;