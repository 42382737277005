import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import PaymentReducer from "./PaymentReducer";
import TokenTransferReducer from "./TokenTransferReducer";
import communityReducer from "./communityReducer";
import dashboardReducer from "./DashboardReducer";
import NavbarReducer from "./NavbarReducer";
import ExchangeRatesReducer from "./ExchangeRatesReducer";
import zoneReducer from "./ZoneReducer";

export default combineReducers({
  userData: UserReducer,
  payments: PaymentReducer,
  tokenTransfer: TokenTransferReducer,
  community: communityReducer,
  dashboard: dashboardReducer,
  navbar: NavbarReducer,
  exchangeRates: ExchangeRatesReducer,
  zones: zoneReducer
});
