import './CommunitySettingsStyles.css';
import { FormInputs } from "../../components/FormInputs/FormInputs";
import i18n from 'i18next';
import {
    Button,
    Col,
    FormControl,
    Grid,
    Row,
} from 'react-bootstrap';
import React, { Component } from 'react';
import {
    getCommunitySettings,
    getExchangeRates,
    getRideServiceInfo,
    setCommunitySettings
} from '../../helpers/apiHelper';
import {
    getErrorAlert,
    getLoadingAlert,
    getSuccessAlert,
} from '../../helpers/alertHelper';

import COMM_CONSTANTS from './CommunityConstants';
import Card from '../../components/Card/Card';
import RideServiceNames from '../../const/RideServiceNames';
import { Trans } from 'react-i18next';
import VehicleTypeNames from '../../const/VehicleTypeNames';

class CommunitySettings extends Component {
    state = {
        alert: null,
        originalSettings: null,
        availableServices: [],
        availableVehicles: [],
        vehicleRideServices: {},
        rideServicesVisibility: {},
        promotionOptions: {},
        allowCashPayment: false,
        dispatcher: {
            number_of_orders: 5,
            radius: {}
        },
        trackingLinkMessage: {
            severity: null,
            message: i18n.languages.map(lang => ({lang: lang, text: ""}))
        }
    };

    async componentDidMount() {
        // const exchangeRates = await getExchangeRates();
        const communitySettings = await getCommunitySettings();
        const serviceInfo = await getRideServiceInfo();

        const vehicleRideServices = communitySettings.find(
            (e) => e.code === COMM_CONSTANTS.VEHICLE_RIDE_SERVICES
        );
        const rideServicesVisibility = communitySettings.find(
            (e) => e.code === COMM_CONSTANTS.RIDE_SERVICE_VISIBILITY
        );
        const promotionOptions = communitySettings.find(
            (e) => e.code === COMM_CONSTANTS.PROMOTION_OPTIONS
        );

        const currency = communitySettings.find(
            (e) => e.code === COMM_CONSTANTS.CURRENCY
        );

        const trackingLinkMessage = communitySettings.find(
            (e) => e.code === COMM_CONSTANTS.TRACKING_LINK_MESSAGE
        )

        const maps = communitySettings.find(
            (e) => e.code === COMM_CONSTANTS.MAPS
        )

        const dispatcher = communitySettings.find(
            (e) => e.code === COMM_CONSTANTS.DISPATCHER
        )

        const allowCashPayment = communitySettings.find(
            (e) => e.code === COMM_CONSTANTS.IS_CASH_PAYMENT_ALLOWED
        )

        const vehicleRideServicesValue = vehicleRideServices
            ? vehicleRideServices.value
            : {};

        const rideServicesVisibilityValue = rideServicesVisibility
            ? rideServicesVisibility.value
            : {};

        // Big smell on the following as the structure is hardcoded. The logic for this setting is different from the others so 
        // we can't generate it dynamically because there is no reference.
        const promotionOptionsValue = promotionOptions
            ? promotionOptions.value
            : {[COMM_CONSTANTS.AUTOMATIC_SMS]: false}; 

        this.setState({
            vehicleRideServices: vehicleRideServicesValue,
            rideServicesVisibility: rideServicesVisibilityValue,
            availableServices: serviceInfo.available_services,
            availableVehicles: serviceInfo.available_vehicles,
            promotionOptions: promotionOptionsValue,
            currency: currency ? currency.value : "",
            maps: maps ? maps.value : {},
            trackingLinkMessage: (trackingLinkMessage && trackingLinkMessage.value) || this.state.trackingLinkMessage,
            // availableCurrencies: Array.isArray(exchangeRates) ? exchangeRates.map(e => e.currency_code).filter((v, i, a) => a.indexOf(v) === i) : [],
            availableCurrencies: [],
            dispatcher: dispatcher ? dispatcher.value : this.state.dispatcher,
            allowCashPayment: typeof allowCashPayment !== 'undefined' ? allowCashPayment.value : false
        });
    }

    onVehicleRideServiceChange = (vehicleKey, serviceKey, value) => {
        const vehicleRideServices = this.state.vehicleRideServices;
        if (vehicleRideServices[vehicleKey]) {
            vehicleRideServices[vehicleKey][serviceKey] = value;
        } else {
            vehicleRideServices[vehicleKey] = {};
            vehicleRideServices[vehicleKey][serviceKey] = value;
        }
        this.setState({ vehicleRideServices });
    };

    onRideServicesVisibilityChange = (serviceKey, visibilityKey, value) => {
        const rideServicesVisibility = this.state.rideServicesVisibility;
        if (rideServicesVisibility[serviceKey]) {
            rideServicesVisibility[serviceKey][visibilityKey] = value;
        } else {
            rideServicesVisibility[serviceKey] = {};
            rideServicesVisibility[serviceKey][visibilityKey] = value;
        }
        this.setState({ rideServicesVisibility });
    };

    onPromotionOptionsChange = (optionKey, newValue) => {
        const promotionOptions = this.state.promotionOptions;
        promotionOptions[optionKey] = newValue;
        this.setState({ promotionOptions });
    };

    saveSettings = async () => {
        this.loadingHandlerAlert();
        const { vehicleRideServices, rideServicesVisibility, promotionOptions, currency, trackingLinkMessage, maps, dispatcher, allowCashPayment } = this.state;
        const { success } = await setCommunitySettings([
            {
                code: COMM_CONSTANTS.VEHICLE_RIDE_SERVICES,
                value: vehicleRideServices,
            },
            {
                code: COMM_CONSTANTS.RIDE_SERVICE_VISIBILITY,
                value: rideServicesVisibility,
            },
            {
                code: COMM_CONSTANTS.PROMOTION_OPTIONS,
                value: promotionOptions,
            },
            {
                code: COMM_CONSTANTS.CURRENCY,
                value: currency,
            },
            {
                code: COMM_CONSTANTS.TRACKING_LINK_MESSAGE,
                value: trackingLinkMessage
            },
            {
                code: COMM_CONSTANTS.MAPS,
                value: maps
            },
            {
                code: COMM_CONSTANTS.DISPATCHER,
                value: dispatcher
            },
            {
                code: COMM_CONSTANTS.IS_CASH_PAYMENT_ALLOWED,
                value: allowCashPayment
            }
        ]);
        if (success) {
            this.setState({
                originalSettings: await getCommunitySettings(),
            });
            this.successHandlerAlert();
        } else {
            this.errorHandlerAlert();
        }
    };

    errorHandlerAlert = (error, msg) => {
        this.setState({
            alert: getErrorAlert(() => this.hideAlert(), error, msg),
        });
    };

    loadingHandlerAlert = () => {
        this.setState({
            alert: getLoadingAlert(),
        });
    };

    successHandlerAlert = () => {
        this.setState({
            alert: getSuccessAlert(() => this.hideAlert()),
        });
    };

    hideAlert() {
        this.setState({ alert: null });
    }

    render() {
        return (
            <div
                style={{
                    minHeight: '85vh',
                    padding: '6%',
                    textAlign: 'center',
                    backgroundColor: 'white',
                }}
            >
                {this.state.alert}
                <Grid>
                <Row>
                <Col style={{ textAlign: 'left' }}>
                <p className="setting-title">
                                <Trans>TRACKING_LINK_MESSAGE_SETTING_TITLE</Trans>
                            </p>
                            <p className="setting-subtitle">
                                <i>
                                    <Trans>TRACKING_LINK_MESSAGE_SETTING_SUBTITLE</Trans>
                                </i>
                            </p>
                </Col>
                </Row>
                <Row style={{marginBottom: "50px"}}>
                <Col style={{ textAlign: 'left' }}>
                <Card
                                title=""
                                content={
                                    <div>
                                    {i18n.languages.map(lang => {
                                        return (
                                            <FormInputs
                                ncols={["col-md-4"]}
                                proprieties={[
                                    {
                                        label: lang,
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('MESSAGE'),
                                        maxLength: 150,
                                        onChange: (e => {
                                            if (e.target.value.length > 60) {
                                                return
                                            }
                                            const {trackingLinkMessage} = this.state;
                                            const msgObj = trackingLinkMessage.message.find(e => e.lang === lang);
                                            msgObj.text = e.target.value;
                                            this.setState({trackingLinkMessage});
                                        }),
                                        value: this.state.trackingLinkMessage.message.find(e => e.lang === lang).text
                                    }
                                ]}
                            />
                                        )
                                    })}
                                    <FormControl
                                        inputRef={el => this.inputEl2 = el}
                                        value={this.state.trackingLinkMessage.severity}
                                        onChange={(e => {
                                            const { trackingLinkMessage } = this.state;
                                            trackingLinkMessage.severity = e.target.value
                                            this.setState({trackingLinkMessage});
                                        })}
                                        componentClass="select" placeholder={i18n.t('SEVERITY')}>
                                        <option value={'INFO'}>{i18n.t('INFO')}</option>
                                        <option value={'WARN'}>{i18n.t('WARN')}</option>
                                        <option value={'DANGER'}>{i18n.t('DANGER')}</option>
                                    </FormControl></div>
                                   } />
                </Col>
                </Row>
                <Row>
                <Col style={{ textAlign: 'left' }}>
                <p className="setting-title">
                                <Trans>DISPATCHER_SETTING_TITLE</Trans>
                            </p>
                            <p className="setting-subtitle">
                                <i>
                                    <Trans>DISPATCHER_SETTING_SUBTITLE</Trans>
                                </i>
                            </p>
                </Col>
                </Row>
                <Row style={{marginBottom: "50px"}}>
                <Col style={{ textAlign: 'left' }}>
                <Card
                                title=""
                                content={
                                <div>
                                <p>{i18n.t('GENERAL')}</p>
                                 <FormInputs
                                ncols={["col-md-4"]}
                                proprieties={[
                                    {
                                        label: i18n.t("NUMBER_OF_ORDERS"),
                                        type: "number",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('RADIUS'),
                                        maxLength: 150,
                                        onChange: (e) => {
                                            const parsedValue = parseInt(e.target.value);
                                            const value = isNaN(parsedValue) ? null : parsedValue;
                                            let {dispatcher} = this.state;
                                            dispatcher['number_of_orders'] = value;
                                            this.setState({dispatcher});
                                        },
                                        value: this.state.dispatcher.number_of_orders
                                    }]}
                            />
                           <p>{i18n.t('RADIUSES')}</p>
                                    {this.state.availableServices.map(service => {
                                        return (
                                            <FormInputs
                                ncols={["col-md-4"]}
                                proprieties={[
                                    {
                                        label: i18n.t(RideServiceNames[service]),
                                        type: "number",
                                        bsClass: "form-control",
                                        placeholder: i18n.t('RADIUS'),
                                        maxLength: 150,
                                        onChange: (e) => {
                                            const parsedValue = parseInt(e.target.value);
                                            const value = isNaN(parsedValue) ? null : parsedValue;
                                            let {dispatcher} = this.state;
                                            dispatcher.radius[service] = value
                                            this.setState({dispatcher});
                                        },
                                        value: this.state.dispatcher.radius[service] || null
                                    }]}
                            />
                                        )
                                    })}
                                    </div>}
                                    />
                </Col>
                </Row>
                <Row>
                <Col style={{ textAlign: 'left' }}>
                <p className="setting-title">
                                <Trans>CASH_PAYMENT_SETTING_TITLE</Trans>
                            </p>
                            <p className="setting-subtitle">
                                <i>
                                    <Trans>CASH_PAYMENT_SETTING_SUBTITLE</Trans>
                                </i>
                            </p>
                </Col>
                </Row>
                <Row style={{marginBottom: "50px"}}>
                <Col style={{ textAlign: 'left' }}>
                <Card
                                title=""
                                content={
                                    <div style={{ border: '1px solid transparent' }}>
                                        <input
                                            type="checkbox"
                                            style={{
                                                margin: '20px',
                                                float: 'left',
                                            }}
                                            id="allowCashPayment"
                                            onChange={(e) => this.setState({allowCashPayment: e.target.checked})}
                                            checked={this.state.allowCashPayment}
                                        />
                                        <p style={{ margin: '15px' }}>
                                            <Trans>ALLOW_CASH_PAYMENT</Trans>
                                        </p>
                                    </div>
                                }
                                    />
                </Col>
                </Row>
                    <Row>
                        <Col style={{ textAlign: 'left' }}>
                            <p className="setting-title">
                                <Trans>VEHICLE_RIDE_SERVICES_TITLE</Trans>
                            </p>
                            <p className="setting-subtitle">
                                <i>
                                    <Trans>VEHICLE_RIDE_SERVICES_SUBTITLE</Trans>
                                </i>
                            </p>
                            <Card
                                title=""
                                content={
                                    <>
                                        {this.state.availableVehicles.map(
                                            (vehicleKey, index) => (
                                                <div key={index} style={{ textAlign: 'left' }} >
                                                    <p>
                                                        <Trans>{VehicleTypeNames[vehicleKey]}</Trans>
                                                    </p>
                                                    {this.state.availableServices.map(
                                                        (serviceKey, index) => (
                                                            <div key={index} style={{ border: '1px solid transparent' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    style={{
                                                                        margin: '20px',
                                                                        float: 'left',
                                                                    }}
                                                                    id={serviceKey}
                                                                    onChange={(e) => this.onVehicleRideServiceChange(vehicleKey, serviceKey, e.target.checked)}
                                                                    checked={
                                                                        (this.state.vehicleRideServices[vehicleKey] && this.state.vehicleRideServices[vehicleKey][serviceKey]) ?
                                                                            this.state.vehicleRideServices[vehicleKey][serviceKey] :
                                                                            false
                                                                    }
                                                                />
                                                                <p style={{ margin: '15px' }}>
                                                                    <Trans>{RideServiceNames[serviceKey]}</Trans>
                                                                </p>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )
                                        )}
                                    </>
                                }
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '5%' }}>
                        <Col style={{ textAlign: 'left' }}>
                            <p className="setting-title">
                                <Trans>RIDE_SERVICES_VISIBILITY_TITLE</Trans>
                            </p>
                            <p className="setting-subtitle">
                                <i>
                                    <Trans>RIDE_SERVICES_VISIBILITY_SUBTITLE</Trans>
                                </i>
                            </p>
                            <Card
                                title=""
                                content={
                                    <>
                                        {this.state.availableServices.map(
                                            (serviceKey, index) => (
                                                <div key={index} style={{ textAlign: 'left' }}>
                                                    <p>
                                                        <Trans>{RideServiceNames[serviceKey]}</Trans>
                                                    </p>
                                                    <div style={{ border: '1px solid transparent' }}>
                                                        <input
                                                            type="checkbox"
                                                            style={{
                                                                margin: '20px',
                                                                float: 'left',
                                                            }}
                                                            onChange={(e) => this.onRideServicesVisibilityChange(serviceKey, COMM_CONSTANTS.EVA_BUSINESS, e.target.checked)}
                                                            checked={(this.state.rideServicesVisibility[serviceKey] && this.state.rideServicesVisibility[serviceKey][COMM_CONSTANTS.EVA_BUSINESS]) ?
                                                                this.state.rideServicesVisibility[serviceKey][COMM_CONSTANTS.EVA_BUSINESS] :
                                                                false}
                                                        />
                                                        <p style={{ margin: '15px' }}>
                                                            <Trans>MIA_EXTERNAL</Trans>
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            border: '1px solid transparent',
                                                        }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            style={{
                                                                margin: '20px',
                                                                float: 'left',
                                                            }}
                                                            onChange={(e) => this.onRideServicesVisibilityChange(serviceKey, COMM_CONSTANTS.EVA_CLIENT, e.target.checked)}
                                                            checked={(this.state.rideServicesVisibility[serviceKey] && this.state.rideServicesVisibility[serviceKey][COMM_CONSTANTS.EVA_CLIENT]) ?
                                                                this.state.rideServicesVisibility[serviceKey][COMM_CONSTANTS.EVA_CLIENT] :
                                                                false}
                                                        />
                                                        <p style={{ margin: '15px' }}>
                                                            <Trans>ANA_EXTERNAL</Trans>
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </>
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: 'left', marginTop: '5%' }}>
                            <p className="setting-title">
                                <Trans>PROMO_TITLE</Trans>
                            </p>
                            <p className="setting-subtitle">
                                <i>
                                    <Trans>PROMO_SUBTITLE</Trans>
                                </i>
                            </p>
                            <Card
                                title=""
                                content={

                                    <div style={{ textAlign: 'left' }} >
                                        {Object.keys(this.state.promotionOptions).map(
                                            (optionKey, index) => (
                                                <div key={index} style={{ border: '1px solid transparent' }}>
                                                    <input
                                                        type="checkbox"
                                                        style={{
                                                            margin: '20px',
                                                            float: 'left',
                                                        }}
                                                        id={optionKey}
                                                        onChange={(e) => this.onPromotionOptionsChange(optionKey, e.target.checked)}
                                                        checked={
                                                            (this.state.promotionOptions[optionKey]) ? this.state.promotionOptions[optionKey] : false
                                                        }
                                                    />
                                                    <p style={{ margin: '15px' }}>
                                                        <Trans>{optionKey}</Trans>
                                                    </p>
                                                </div>
                                            )
                                        )}
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                    <Row  style={{ marginTop: '5%' }}>
                        <Col style={{ textAlign: 'left' }}>
                            <p className="setting-title">
                                <Trans>CURRENCY</Trans>
                            </p>
                            <p className="setting-subtitle">
                                <i>
                                    <Trans>CURRENCY_SETTING_BODY</Trans>
                                </i>
                            </p>
                            <Card
                                title=""
                                content={
                                    <FormControl
                                        inputRef={el => this.inputEl = el}
                                        value={this.state.currency}

                                        onChange={(e => {
                                            this.setState({currency: e.target.value});
                                        })}
                                        disabled={this.state.currency !== ""}
                                        componentClass="select" placeholder="CAD">
                                        {this.state.availableCurrencies ? this.state.availableCurrencies.map(e => {
                                            return <option value={e}>{e}</option>
                                        }) : null}
                                    </FormControl>
                                }
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20%' }}>
                        <Col md={10}>
                            <Button
                                style={{ width: '40%' }}
                                onClick={() => this.saveSettings()}
                            >
                                <Trans>SAVE</Trans>
                            </Button>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default CommunitySettings;

