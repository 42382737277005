import React, { Component } from "react";

import TableAPI from "../Tables/TableAPI";
import { Trans } from "react-i18next";
import { getRiders } from "../../helpers/apiHelper";

export default class ActiveRidersList extends Component {

    onClickDetail = (accountName) => {
        console.log('accountName', accountName);
        window.open(`#/rider/?name=${accountName}`);
    }

    render() {
        return (
                                <TableAPI
                                    fetchData={getRiders}
                                    columns={[
                                        {
                                            Header: <Trans>CHAIN_ACCOUNT</Trans>,
                                            canFilter: true,
                                            canSort: true,
                                            id: 'account',
                                            width: 220,
                                            accessor: (el) => {return el.account}
                                        },
                                        {
                                            Header: <Trans>RIDER_NUMBER_OF_RIDES</Trans>,
                                            canFilter: true,
                                            canSort: true,
                                            defaultSort: true,
                                            id: 'number_rides',
                                            width: 220,
                                            accessor: (el) => {return el.number_rides}
                                        },
                                        {
                                            Header: <Trans>phone</Trans>,
                                            canFilter: true,
                                            id: 'phone',
                                            width: 220,
                                            accessor: (el) => {return el.profile.phone}
                                        },
                                        {
                                            Header: <Trans>First Name</Trans>,
                                            canFilter: true,
                                            canSort: true,
                                            id: 'first_name',
                                            width: 220,
                                            accessor: (el) => {return el.profile.first_name}
                                        },
                                        {
                                            Header: <Trans>Last Name</Trans>,
                                            canFilter: true,
                                            canSort: true,
                                            id: 'last_name',
                                            width: 220,
                                            accessor: (el) => {return el.profile.last_name}
                                        },
                                        {
                                            Header: <Trans>CUSTOMER_EMAIL</Trans>,
                                            canFilter: true,
                                            canSort: false,
                                            id: 'email',
                                            width: 220,
                                            accessor: (el) => {return el.profile.email}
                                        }

                ]}
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                        return {
                            onClick: (e) => {
                                this.onClickDetail(rowInfo.original.account)
                            },
                            style: {
                                cursor: "pointer"
                            }
                        }
                    } else {
                        return {}
                    }
                }}
                sorted={[
                    {
                        id: 'num_rides',
                        desc: true
                    }
                ]}
                className="-highlight"

            />
        )
    }
}
