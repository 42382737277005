import React from "react";
import "./LoadingSpinner.css"
/* Did this component to avoid copy-pasting the same html block everywhere. I can't find the original CSS though. 
It seems to be compiled somewhere in a library. */

const LoadingSpinner = (props) => {

    const { width } = props;
    const style = {
        width: width && `${width}px`,
        height: width && `${width}px`,
        margin: "0 1px",
    }

    return (
        <div className="spinner">
            <div className="bounce1" style={style}></div>
            <div className="bounce2" style={style}></div>
            <div className="bounce3" style={style}></div>
        </div>
    );
};

export default LoadingSpinner;