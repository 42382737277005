import React, { Component } from "react";

import HealthBarGreen from "../../assets/img/health-bar-green.png";
import HealthBarOrange from "../../assets/img/health-bar-orange.png";
import HealthBarRed from "../../assets/img/health-bar-red.png";
import HealthBarYellow from "../../assets/img/health-bar-yellow.png";
import TableAPI from "../Tables/TableAPI";
import { Trans } from "react-i18next";
import {
  getBusiness
} from "../../helpers/apiHelper";
import { utcStringToLocalString } from "../../helpers/dateHelper";

export default class BusinessList extends Component {
  constructor(props) {
    super(props);
  }

  onClickDetail = (accountName) => {
    window.open(`#/business/form/?name=${accountName}`, "_blank");
  }

  constructFormFromApi = async (data) => {
    const businessList = await getBusiness(data);
    if (data.get_count) {
      return businessList;
    }
    let formRows = {};
    for (var index in businessList) {
      const row = await this.getFormRowFromBusinessEntry(businessList[index]);
      formRows[index] = row;
    }
    return formRows;
  };

  getFormRowFromBusinessEntry = async (entry) => {
    let row = {
      'company_name': entry.company_name,
      'number_rides': entry.number_rides,
      'registered_on': utcStringToLocalString(entry.registered_on),
      'rider_account': entry.rider_account,
      'send_sms_tracking': entry.send_sms_tracking,
      'send_email_tracking': entry.send_email_tracking,
      'contact_name': (entry.contact_first_name || "") + " " + (entry.contact_last_name || ""),
      'contact_phone': entry.contact_phone,
      'contact_email': entry.contact_email,
      'status_rating': entry.status_rating,
      'last_ride_datetime': entry.last_ride_datetime
    }
    return row;
  };

  render() {
    return (
      <div className="content">
        <TableAPI
          fetchData={this.constructFormFromApi}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                  onClick: (e) => {
                    this.onClickDetail(rowInfo.original.rider_account)
                  },
                  style: { 
                      cursor: "pointer"
                  }
              }
            } else {
              return {}
            }
          }}
          columns={[
            {
              Header: <Trans>STATUS</Trans>,
              canSort: false,
              id: "status_rating",
              orderType: "",
              accessor: "status_rating",
              width: 140,
              Cell: (e) => {
                const rating = e.value;
                let img = null;
                if (rating >= 0.8) {
                  img = HealthBarGreen;
                } else if (rating >= 0.6) {
                  img = HealthBarYellow;
                } else if (rating >= 0.4) {
                  img = HealthBarOrange;
                } else {
                  img = HealthBarRed;
                }
                return <span><img src={img} height="15px" width="35px" /></span>;
              }
            },
            {
              Header: <Trans>COMPANY_NAME</Trans>,
              canSort: true,
              canFilter: true,
              id: "company_name",
              orderType: "",
              accessor: "company_name",
              width: 140,
              Cell: (e) => {
                return <span>{e.value}</span>;
              },
            },
            {
              Header: <Trans>CONTACT_NAME</Trans>,
              canSort: true,
              id: "contact_name",
              canFilter: true,
              orderType: "",
              accessor: "contact_name",
              width: 140,
              Cell: (e) => {
                return <span>{e.value}</span>;
              },
            },
            {
              Header: <Trans>CONTACT_PHONE</Trans>,
              canSort: true,
              id: "contact_phone",
              canFilter: true,
              orderType: "",
              accessor: "contact_phone",
              width: 140,
              Cell: (e) => {
                return <span>{e.value}</span>;
              },
            },
            {
              Header: <Trans>CONTACT_EMAIL</Trans>,
              canSort: true,
              id: "contact_email",
              canFilter: true,
              orderType: "",
              accessor: "contact_email",
              width: 140,
              Cell: (e) => {
                return <span>{e.value}</span>;
              },
            },
            {
              Header: <Trans>RIDER_NUMBER_OF_RIDES</Trans>,
              canSort: true,
              defaultSort: true,
              id: 'number_rides',
              width: 220,
              accessor: (el) => {return el.number_rides}
            },
            {
              Header: <Trans>LAST_RIDE_DATETIME</Trans>,
              id: 'last_ride_datetime',
              width: 220,
              accessor: (el) => {return utcStringToLocalString(el.last_ride_datetime)}
            },
            {
              Header: <Trans>REGISTERED_ON</Trans>,
              canSort: true,
              defaultSort: true,
              id: "registered_on",
              orderType: "desc",
              accessor: "registered_on",
              width: 140,
              Cell: (e) => {
                return <span>{e.value}</span>;
              },
            },
            {
              Header: <Trans>Rider ACCOUNT</Trans>,
              canSort: true,
              id: "rider_account",
              canFilter: true,
              orderType: "desc",
              accessor: "rider_account",
              width: 140,
              Cell: (e) => {
                return <span>{e.value}</span>;
              },
            }
          ]}
          className="-highlight"
        />
      </div>
    );
  }
}

