import { Component } from "react";
import React from "react";
import "./RequestDetails.css";
import { getDriverName, getRiderName } from "../../../../../helpers/viewsHelper";
import {
  utcStringToLocalString,
  convertSecondsToHoursString,
  getDifferenceInSeconds
} from "../../../../../helpers/dateHelper";
import RideServiceNames from "../../../../../const/RideServiceNames";
import { Trans } from "react-i18next";
import { Link } from 'react-router-dom';
const Moment = require('moment')

const NO_VALUE = "---";

export default class RequestDetails extends Component {

  render() {
    const { rideRequest } = this.props;

    /* ORDERING DATA */
    const driversData = rideRequest.drivers;
    // Double checking fetched data -> important to avoid some sorting mistakes that happenned while developping
    driversData.sort((a, b) => new Moment(a.obj.block_time) - new Moment(b.obj.block_time));

    /* INITIALIZING VARIABLES */
    let firstAcceptedTime = NO_VALUE;
    let lastAcceptedTime = NO_VALUE;
    let cancelledTime = NO_VALUE;
    let averageResponseDelay = 0;
    let endTime = NO_VALUE;
    const requestedTime = utcStringToLocalString(rideRequest.block_time);

    let acceptedDriverFound = false;
    let acceptedDriver = null;
    let acceptedDriverProfile;

    let responseCounter = 0;
    let averageResponseArray = [];
    for (let d of driversData) {
      if (d.obj.accepted) {
        if (!acceptedDriverFound) {
          acceptedDriver = d.obj;
          acceptedDriverProfile = d.profile;
          acceptedDriverFound = true;
        }
        responseCounter++;
        const acceptance_delta = getDifferenceInSeconds(d.obj.accepted_block_time, d.obj.block_time);
        averageResponseArray.push(acceptance_delta);
        lastAcceptedTime = utcStringToLocalString(d.obj.accepted_block_time);
      }
      if (d.obj.declined) {
        responseCounter++;
        const refusal_delta = getDifferenceInSeconds(d.obj.declined_block_time, d.obj.block_time);
        averageResponseArray.push(refusal_delta);
      }
    }

    if (averageResponseArray.length > 0) {
      averageResponseDelay = averageResponseArray.reduce((partial_sum, item) => partial_sum + item, 0) / responseCounter;
    }

    if (acceptedDriver !== null && acceptedDriver.accepted_block_time !== null) {
      firstAcceptedTime = utcStringToLocalString(
        acceptedDriver.accepted_block_time
      );
    }

    if (rideRequest.canceled_by_rider === true) {
      cancelledTime = utcStringToLocalString(
        rideRequest.canceled_by_rider_block_time
      );
    } else if (rideRequest.canceled_by_driver === true) {
      cancelledTime = utcStringToLocalString(
        rideRequest.canceled_by_driver_block_time
      );
    }

    if (rideRequest.end_time) {
      endTime = utcStringToLocalString(
        rideRequest.end_time
      );
    }

    /* RENDERING */
    return (
      <div className="ride-request-details">
        <section className="general-info">
          <h4>
            <Trans>REQUEST_DETAILS_GENERAL_TITLE</Trans>
          </h4>
          <ul>
            <li>
              <dl>
                <dt>
                  <Trans>REQUEST_DETAILS_GENERAL_ID</Trans>
                </dt>
                <dd>
                  {rideRequest.route_id ?
                    <Link target="_blank" to={{
                      pathname: "/ride",
                      search: "?routeId=" + rideRequest.route_id,
                    }}>{rideRequest.route_id}</Link>
                    : <span>---</span>}
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  <Trans>REQUEST_DETAILS_GENERAL_DRIVER</Trans>
                </dt>
                <dd>
                  {(acceptedDriver) ?
                    <Link  target="_blank" to={"/driver_edit?name=" + acceptedDriver.driver}>{getDriverName(acceptedDriverProfile)}</Link>
                    : <span>---</span>}
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  <Trans>REQUEST_DETAILS_GENERAL_RIDER</Trans>
                </dt>
                <dd>
                  <Link target="_blank" to={{
                    pathname: "/rider",
                    search: "?name=" + rideRequest.rider,
                  }}>{getRiderName(this.props.rider.profile)}</Link>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  <Trans>REQUEST_DETAILS_GENERAL_SERVICE_TYPE</Trans>
                </dt>
                <dd>
                  <Trans>
                    {RideServiceNames[rideRequest.ride_service_type_id]}
                  </Trans>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  <Trans>REQUEST_DETAILS_GENERAL_FROM</Trans>
                </dt>
                <dd>
                  {rideRequest.path.from_address}
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  <Trans>REQUEST_DETAILS_GENERAL_TO</Trans>
                </dt>
                <dd>
                  {rideRequest.path.to_address}
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  <Trans>REQUEST_DETAILS_GENERAL_DISTANCE</Trans>
                </dt>
                <dd>
                  {rideRequest.est_dist_km} <Trans>KILOMETER</Trans>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  <Trans>REQUEST_DETAILS_GENERAL_TIME</Trans>
                </dt>
                <dd>
                  {convertSecondsToHoursString(rideRequest.est_minutes * 60)}
                </dd>
              </dl>
            </li>
          </ul>
        </section>
        <section className="events-info">
          <h4>
            <Trans>TIMELINE_LIST_TITLE</Trans>
          </h4>
          <ul>
            <li>
              <dl>
                <dt>
                  <Trans>TIMELINE_LIST_REQUEST_START</Trans>
                </dt>
                <dd>
                  {requestedTime.toLocaleString()}
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  <Trans>TIMELINE_LIST_FIRST_ACCEPTED</Trans>
                </dt>
                <dd>
                  {firstAcceptedTime.toLocaleString()}
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  <Trans>TIMELINE_LIST_LAST_ACCEPTED</Trans>
                </dt>
                <dd>
                  {lastAcceptedTime.toLocaleString()}
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  <Trans>TIMELINE_LIST_CANCELLED</Trans>
                </dt>
                <dd>
                  {cancelledTime.toLocaleString()}
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  <Trans>TIMELINE_LIST_REQUEST_END</Trans>
                </dt>
                <dd>
                  {endTime.toLocaleString()}
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  <Trans>TIMELINE_LIST_DELTA</Trans>
                </dt>
                <dd>
                  {convertSecondsToHoursString(averageResponseDelay)}
                </dd>
              </dl>
            </li>
          </ul>
        </section>
      </div>
    );

  }
}