import React, { useState, useEffect, useCallback } from "react";
import MorphingField from "./MorphingField/MorphingField";
import { Trans } from "react-i18next";
import './SubForm.css';

const SubForm = (props) => {
    /* useStates */
    const [serviceTypeActiveScopes, setServiceTypeActiveScopes] = useState();
    const [vehicleTypeActiveScopes, setVehicleTypeActiveScopes] = useState();

    /* useEffects */
    useEffect(() => {
        if (activeScopes) {
            setServiceTypeActiveScopes(activeScopes["allowedRideServices"]);
            setVehicleTypeActiveScopes(activeScopes["selectedVehicleType"]);
        }
    })

    /* Variables */
    const { formId, type, lockAllFields, onChange, onBlur, onScopeChange, activeScopes, onClick } = props;
    const formFields = props.fieldsArray;
    let formToRender;

    const handleInputChange = (fieldId, newValue) => {
        const updatedformFieldsArray = [...formFields];
        updatedformFieldsArray.forEach(field => {
            if (field.id === fieldId) {
                field.value = newValue;
                if (field.value == ''){
                    field.show_error = false; 
                }
            };
        });

        onChange(formId, updatedformFieldsArray);
    }

    const handleInputBlur = useCallback((fieldId, value, validationRegex = "") => { 
        const updatedformFieldsArray = [...formFields];
        updatedformFieldsArray.forEach(field => {
            if (field.id === fieldId) {
                const fieldRegex = new RegExp(validationRegex);
                const isValueInvalid = !fieldRegex.test(value);
                if (value !== "")
                    field.show_error = isValueInvalid;
                field.value = value;
                field.is_validated = !isValueInvalid;
                if (type === 'vehicle') {
                    if (field.name === "allowed_ride_services") {
                        onScopeChange(formId, { allowedRideServices: value });
                    } else if (field.name === "vehicle_type_id") {
                        onScopeChange(formId, { selectedVehicleType: value });
                    }
                }
            };
        });

        onChange(formId, updatedformFieldsArray);
        onBlur();

    }, [formFields]);

    const generateFields = useCallback((infoType) => {
        return formFields.map(field => {
            if (field.info_type === infoType) {
                let fragmentToReturn = <React.Fragment key={field.id} />;   
                if (field.is_scoped) {
                    fragmentToReturn = (
                        <React.Fragment key={field.id} >
                            <MorphingField
                                fieldTemplate={field}
                                selectedVehicleType={type === "vehicle" && vehicleTypeActiveScopes}
                                onChange={handleInputChange}
                                toggleShowDocument={onClick}
                                onBlur={handleInputBlur}
                                disabled={lockAllFields}
                            />
                        </React.Fragment>
                    )
                }
                return fragmentToReturn;
            }
        });
    }, [formFields, lockAllFields, vehicleTypeActiveScopes]);

    if (Object.keys(formFields).length > 0) {
        formToRender = (
            <>
                <div className="subform-subsection">
                    <h3><Trans>NEW_SUBMISSION_TITLE_BASIC_INFO</Trans></h3>
                    {generateFields("basic")}
                </div>
                <div className="subform-subsection">
                    <h3 ><Trans>NEW_SUBMISSION_TITLE_CUSTOM_INFO</Trans></h3>
                    {generateFields("custom")}
                </div>
            </>
        );
    }

    return (
        <>
            {formToRender}
        </>
    );
};

export default SubForm;