import Button from "../../components/CustomButton/CustomButton.jsx";
import React, { Component } from "react";
import { Col, Grid, Modal, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import i18n from "../../i18n";
import Card from "../Card/Card";
import './BusinessSchedule.css';
import "rc-time-picker/assets/index.css"
import moment from "moment";
import TimePicker from "rc-time-picker";

/**
 * Component used to defined the working hours of a business (restaurant or retailer for now)
 */
class BusinessSchedule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            openTime: moment(`0:0`, "HH:mm"),
            closeTime: moment(`0:0`, "HH:mm"),
            hasPresetOption: false,
            editingDays: [],
            workingHours: [
                {
                    day: 0,
                    open_time: null,
                    close_time: null
                },
                {
                    day: 1,
                    open_time: null,
                    close_time: null
                },
                {
                    day: 2,
                    open_time: null,
                    close_time: null
                },
                {
                    day: 3,
                    open_time: null,
                    close_time: null
                },
                {
                    day: 4,
                    open_time: null,
                    close_time: null
                },
                {
                    day: 5,
                    open_time: null,
                    close_time: null
                },
                {
                    day: 6,
                    open_time: null,
                    close_time: null
                },
            ],
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleUpdateHours = this.handleUpdateHours.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.onChangeOpenTime = this.onChangeOpenTime.bind(this);
        this.onChangeCloseTime = this.onChangeCloseTime.bind(this);
        this.onChangeEditingDays = this.onChangeEditingDays.bind(this);
        this.onChangeHoursOption = this.onChangeHoursOption.bind(this);
    }

    /**
     * @param {String} openTime 
     * @param {String} closeTime 
     * @returns a String representing the open and close time
     */
    getTime(openTime, closeTime) {
        // closed if the open time or close time is not defined
        if (openTime == null || closeTime == null) {
            return i18n.t("CLOSED");
        }

        // Adding 1 minute to the close time to see if it's equal to the open time
        // if it is, that means that it is open 24 hours 
        let openTimeObj = new Date("1970-01-01 " + openTime);
        let closeTimeObj = new Date("1970-01-01 " + closeTime);
        closeTimeObj.setMinutes(closeTimeObj.getMinutes() + 1);
        if (openTimeObj.getHours() == closeTimeObj.getHours() && openTimeObj.getMinutes() == closeTimeObj.getMinutes()) {
            return i18n.t("OPEN_24_HOUR");
        }

        // returning normal open and close time if it's not closed or open 24 hours
        return moment(openTime, 'HH:mm:s').format('h:mm a') + " - " + moment(closeTime, 'HH:mm:s').format('h:mm a');
    }

    /**
     * Called whenever the user closes the modal.  Resets some of the state and hides the modal.
     */
    handleClose() {
        this.setState({
            show: false,
            hasPresetOption: false,
            openTime: moment(`0:0`, "HH:mm"),
            closeTime: moment(`0:0`, "HH:mm"),
        });
    }

    /**
     * Called whever the user clicks on "save changes".  Edits the working hours with the new changes
     */
    handleUpdateHours() {
        // Going over the selected days
        for (const day of this.state.editingDays) {
            // Adding the close and open time to the specific day.
            // NOTE: 0 = Sunday, 6 = Saturday
            const newWorkingHour = this.state.workingHours;
            // if open time is the same as close time, I consider it closed so I change the values to null
            let openTime = this.state.openTime == null ? null : this.state.openTime.format("HH:mm:ss")
            let closeTime = this.state.closeTime == null ? null : this.state.closeTime.format("HH:mm:ss");
            if (openTime == closeTime) {
                openTime = null;
                closeTime = null;
            }
            newWorkingHour[day].open_time = this.state.openTime == null ? null : openTime;
            newWorkingHour[day].close_time = this.state.closeTime == null ? null : closeTime;
            this.setState({
                workingHours: newWorkingHour,
            });
        }

        // calling onChange method of the parent
        this.props.onChange(this.state.workingHours);
        // closing modal
        this.handleClose();
    }

    /**
     * Shows the modal
     */
    handleShow() {
        this.setState({ show: true });
    }

    /**
     * Updates the state to change the open time of the modal
     * 
     * @param {Object} value A moment Object
     */
    onChangeOpenTime(value) {
        this.setState({ openTime: value });
    }

    /**
     * Updates the state to change the close time of the modal
     * 
     * @param {Object} value A moment Object
     */
    onChangeCloseTime(value) {
        this.setState({ closeTime: value });
    }

    /**
     * Updates the state to change the days that are being edited
     * 
     * @param {Array} values The days that are being edited in the modal
     */
    onChangeEditingDays(values) {
        this.setState({ editingDays: values });
    }

    /**
     * Updates the state to change the currently active preset for the working hours.  Right now, the two 
     * presets are closed and open 24 hours
     * 
     * @param {Number} values The preset options selected
     */
    onChangeHoursOption(values) {
        // removing previously pressed option if two options are selected.  
        // Mean to act as a radio button/checkbox
        if (values.length > 1) {
            values.shift();
        }
        // checking if any preset is selected
        if (values.length == 1) {
            this.setState({ hasPresetOption: true });
            const value = values[0];
            // now checking which preset has been selected
            if (value == 0) {
                // closed option
                this.setState({
                    openTime: null,
                    closeTime: null
                });

            } else if (value == 1) {
                // open 24 hours option
                this.setState({
                    openTime: moment(`00:00`, "HH:mm:ss"),
                    closeTime: moment(`23:59:59`, "HH:mm:ss")
                });
            }
        } else if (values.length == 0) {
            this.setState({ hasPresetOption: false });
        }
    }

    render() {
        const workingHours = this.props.workingHours;
        // taking into account arrays with missing days
        // just putting closed as default for those cases
        for (const workingDay of workingHours) {
            this.state.workingHours[workingDay.day] = workingDay;
        }
        return (
            <div className="schedule-container">
                <Col md={5}>
                    <Card
                        title={i18n.t("WORKING_HOURS")}
                        content={
                            <Grid>
                                <Row className="working-hours-row">
                                    <Col md={5}>{i18n.t("SUNDAY")}</Col>
                                    <Col md={7} className="open-close-time">{this.getTime(this.state.workingHours[0].open_time, this.state.workingHours[0].close_time)}</Col>
                                </Row>
                                <Row className="working-hours-row">
                                    <Col md={5}>{i18n.t("MONDAY")}</Col>
                                    <Col md={7} className="open-close-time">{this.getTime(this.state.workingHours[1].open_time, this.state.workingHours[1].close_time)}</Col>
                                </Row>
                                <Row className="working-hours-row">
                                    <Col md={5}>{i18n.t("TUESDAY")}</Col>
                                    <Col md={7} className="open-close-time">{this.getTime(this.state.workingHours[2].open_time, this.state.workingHours[2].close_time)}</Col>
                                </Row>
                                <Row className="working-hours-row">
                                    <Col md={5}>{i18n.t("WEDNESDAY")}</Col>
                                    <Col md={7} className="open-close-time">{this.getTime(this.state.workingHours[3].open_time, this.state.workingHours[3].close_time)}</Col>
                                </Row>
                                <Row className="working-hours-row">
                                    <Col md={5}>{i18n.t("THURSDAY")}</Col>
                                    <Col md={7} className="open-close-time">{this.getTime(this.state.workingHours[4].open_time, this.state.workingHours[4].close_time)}</Col>
                                </Row>
                                <Row className="working-hours-row">
                                    <Col md={5}>{i18n.t("FRIDAY")}</Col>
                                    <Col md={7} className="open-close-time">{this.getTime(this.state.workingHours[5].open_time, this.state.workingHours[5].close_time)}</Col>
                                </Row>
                                <Row className="working-hours-row">
                                    <Col md={5}>{i18n.t("SATURDAY")}</Col>
                                    <Col md={7} className="open-close-time">{this.getTime(this.state.workingHours[6].open_time, this.state.workingHours[6].close_time)}</Col>
                                </Row>
                                <Row className="working-hours-row">
                                    <Button fill onClick={this.handleShow}>
                                        {i18n.t("MODIFY")}
                                    </Button>
                                </Row>
                            </Grid>
                        }
                    />
                </Col>

                <Modal enforceFocus={false} show={this.state.show} onHide={this.handleClose} className="schedule-editor" >
                    <Modal.Header closeButton>
                        <Modal.Title>{i18n.t("SCHEDULE_EDITOR")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="days-container">
                            <ToggleButtonGroup type="checkbox" className="days-toggle-group" onChange={this.onChangeEditingDays}>
                                <ToggleButton id="tbg-btn-1" value={0}>
                                    {i18n.t("SUNDAY")}
                                </ToggleButton>
                                <ToggleButton id="tbg-btn-2" value={1}>
                                    {i18n.t("MONDAY")}
                                </ToggleButton>
                                <ToggleButton id="tbg-btn-3" value={2}>
                                    {i18n.t("TUESDAY")}
                                </ToggleButton>
                                <ToggleButton id="tbg-btn-4" value={3}>
                                    {i18n.t("WEDNESDAY")}
                                </ToggleButton>
                                <ToggleButton id="tbg-btn-5" value={4}>
                                    {i18n.t("THURSDAY")}
                                </ToggleButton>
                                <ToggleButton id="tbg-btn-6" value={5}>
                                    {i18n.t("FRIDAY")}
                                </ToggleButton>
                                <ToggleButton id="tbg-btn-7" value={6}>
                                    {i18n.t("SATURDAY")}
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>

                        <div className="hours-container">
                            <TimePicker
                                disabled={this.state.hasPresetOption}
                                value={this.state.openTime}
                                onChange={this.onChangeOpenTime}
                                showSecond={false}
                            />

                            <p>{i18n.t("To")}</p>

                            <TimePicker
                                disabled={this.state.hasPresetOption}
                                value={this.state.closeTime}
                                onChange={this.onChangeCloseTime}
                                showSecond={false}
                            />

                            <ToggleButtonGroup type="checkbox" className="hours-option-toggle-group" onChange={this.onChangeHoursOption}>
                                <ToggleButton id="tbg-btn-closed" value={0}>
                                    {i18n.t("CLOSED")}
                                </ToggleButton>
                                <ToggleButton id="tbg-btn-open-24" value={1}>
                                    {i18n.t("OPEN_24_HOUR")}
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            {i18n.t("CLOSE")}
                        </Button>
                        <Button variant="primary" onClick={this.handleUpdateHours}>
                            {i18n.t("SAVE_CHANGES")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}


export default (BusinessSchedule);
