import React, { Component } from "react";

import { Trans } from "react-i18next";
import main_img from "../../assets/img/eva_car_people.png";

export default class Error404 extends Component {
  render() {
    return <div style={{textAlign: "center", padding: '10%', backgroundColor: "white", border: "1px solid white"}}>
    <h1><Trans>404_TITLE</Trans></h1>
    <h3><Trans>404_CLICK</Trans><a style={{color: "rgb(255, 193, 22)"}} href="/dashboard"><Trans>404_HERE</Trans></a><Trans>404_RETURN</Trans></h3>
    <img src={main_img} alt="CAR_IMG" style={{display: "block", margin: "auto", height: "100%", width: "100%", marginTop: "10%"}} />
    </div>
  }
}
  