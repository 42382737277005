import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Form,
  FormControlFeedback,
} from "react-bootstrap";

import { apiAddress } from "../../variables/secretEnvVariables.js";

import Card from "../../components/Card/Card.jsx";

import Button from "../../components/CustomButton/CustomButton.jsx";
import i18n from 'i18next';
import {
  uploadImage,
  addRestaurant,
  getRestaurant,
  updateRestaurant,
  getRestaurantsCategories,
  deleteRestaurant
} from '../../helpers/apiHelper';
import SweetAlert from "react-bootstrap-sweetalert";
import queryString from "query-string";
import { Typeahead } from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './NewEditRestaurant.css'
import BusinessSchedule from "../../components/Schedule/BusinessSchedule.jsx";
import LocationInput from "../../components/LocationInput/LocationInput.jsx";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

/**
 * View used to either create, update, or delete a restaurant
 */
class NewEditRestaurant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      restaurantId: -1,
      restaurantName: "",
      description: "",
      transactionalLink: "https://",
      address: "",
      lat: 0,
      lng: 0,
      imageFile: null,
      image: null,
      imageFileName: "default.png",
      categories: [],
      workingHours: [],

      existingCategories: [],

      alert: null,
      isEdit: false,
    };

  }

  /**
   * Updates the state according to wether or not we are creating or updating a restaurant
   */
  async componentDidMount() {
    // retrieving the existing categories.  This will be used for the autocompletion of the food types inpput
    const existingCategories = await getRestaurantsCategories();

    // retrieving the id param if it exists in the url
    const restaurantId = queryString.parse(this.props.location.search).id;
    // checking if we are going to be creating or updating a restaurant
    if (restaurantId != null) {
      // retrieving the restaurant that we are editing
      const restaurantData = await getRestaurant(restaurantId);
      if (restaurantData != null && restaurantData !== undefined) {
        // updating the state with the data of the restaurant that we are editing
        const stateUpdated = {
          isEdit: true,
          restaurantId: restaurantId,
          title: "RESTAURANT_EDIT_SUBTITLE",
          restaurantName: restaurantData.name,
          description: restaurantData.description,
          transactionalLink: restaurantData.transactional_link,
          address: restaurantData.address,
          lat: restaurantData.lat,
          lng: restaurantData.lng,
          imageFileName: restaurantData.image_file_name == "" ? "default.png" : restaurantData.image_file_name,
          categories: this.getRestaurantCategories(restaurantData),
          workingHours: this.getRestaurantWorkingHours(restaurantData),
          existingCategories: existingCategories,
        }
        this.setState(stateUpdated);
      } else {
        this.setState({
          title: "RESTAURANT_NEW_SUBTITLE",
          existingCategories: existingCategories
        })
      }
    } else {
      this.setState({
        title: "RESTAURANT_NEW_SUBTITLE",
        existingCategories: existingCategories
      })
    }
  }

  /**
   * @param {Object} restaurant 
   * @returns the categories of a specific restaurant
   */
  getRestaurantCategories(restaurant) {
    const restaurantCategories = [];
    for (const category of restaurant.categories) {
      if (category.category == null) continue;
      restaurantCategories.push(category.category);
    }
    return restaurantCategories;
  }

  /**
   * @param {Object} restaurant 
   * @returns the working hours of a specific restaurant
   */
  getRestaurantWorkingHours(restaurant) {
    const restaurantWorkingHours = restaurant.working_hours;
    for (const workingHours of restaurantWorkingHours) {
      // replacing None with null 
      if (workingHours.open_time == "None" || workingHours.open_time == "None") {
        workingHours.open_time = null;
        workingHours.close_time = null;
      }
    }
    return restaurantWorkingHours;
  }

  /**
   * Updates the state to change the restaurant name
   * 
   * @param {Event} e 
   */
  onChangeRestaurantName = (e) => {
    let value = e.target.value;
    this.setState({
      restaurantName: value
    });
  };

  /**
   * Updates the state to change the restaurant description
   * 
   * @param {Event} e 
   */
  onChangeRestaurantDescription = (e) => {
    let value = e.target.value;
    this.setState({
      description: value
    });
  };

  /**
   * Updates the state to change the restaurant transactional link
   * 
   * @param {Event} e 
   */
  onChangeTransactionalLink = (e) => {
    let value = e.target.value;
    this.setState({
      transactionalLink: value
    });
  };

  /**
   * Updates the state to change the restaurant image
   * 
   * @param {Event} e 
   */
  onChangeImage = (e) => {
    const file = e.target.files[0];
    this.setState({
      imageFile: file,
      image: URL.createObjectURL(file),
      imageFileName: file.name,
    });
  };

  /**
   * Updates the state to change the restaurant categories
   * 
   * @param {Array} categories 
   */
  onChangeCategories = (categories) => {
    const formattedCategories = [];
    for (const category of categories) {
      // if category has been created, it's going to be an object.  That's why I do this if else statement
      // I'm also not adding the category if it's already there
      if (typeof category == "object" && !formattedCategories.includes(category.label)) {
        formattedCategories.push(category.label.trim());
      } else if (typeof category == "string" && !formattedCategories.includes(category.trim())) {
        formattedCategories.push(category);
      }
    }
    this.setState({ categories: formattedCategories });
  };

  /**
   * Updates the state to change the restaurant address
   * 
   * @param {String} address 
   */
  onChangeAddress = (address) => {
    this.setState({
      address: address,
    });
  }

  /**
   * Updates the state to change the restaurant working hours
   * 
   * @param {Array} newSchedule 
   */
  handleChangeSchedule(newSchedule) {
    this.setState({ workingHours: newSchedule })
  }

  /**
   * Updates the state to change the restaurant latitude and longitude according to the address
   */
  async setLatLng() {
    await geocodeByAddress(this.state.address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState({
          lat: latLng.lat,
          lng: latLng.lng,
        });
      })
      .catch(error => console.error('Error', error));
  }

  /**
   * @returns a new file name with a uuidv4 prefix
   */
  generateNewImageFileName() {
    let newFileName = this.state.imageFileName;
    if (newFileName != "default.png" && this.state.imageFile != null) {
      const uuidv4 = require('uuid/v4');
      const splitFileName = this.state.imageFile.name.split(".");
      newFileName = uuidv4() + "." + splitFileName[splitFileName.length - 1]
    }
    return newFileName;
  }

  /**
   * Displays the loader modal
   */
  displayLoader() {
    this.setState({
      alert: (
        <SweetAlert
          default
          style={{ display: "block", marginTop: "-100px" }}
          title={i18n.t("SENDING_DATA")}
          confirmBtnBsStyle="info"
        >
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>
            {i18n.t("MAP_LOADING")}
          </p>
        </SweetAlert>
      )
    });
  }

  /**
   * Displays the success modal
   * 
   * @param {String} action 
   */
  displaySuccess(action) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          onConfirm={() => {
            this.setState({ alert: null });
            this.props.history.push("/restaurant/list");
          }}
          onCancel={() => {
            this.setState({ alert: null });
            this.props.history.push("/restaurant/list");
          }}
          confirmBtnBsStyle="info"
        >
          <p>{i18n.t("SUCCESS_MESSAGE", [action])}</p>
        </SweetAlert>
      )
    });
  }

  /**
   * Displays the error modal
   * 
   * @param {String} errorMessage 
   */
  displayError(errorMessage) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Error"
          onConfirm={() => { this.setState({ "alert": null }) }}
          confirmBtnBsStyle="info"
        >
          <p>{i18n.t("ERROR_OCCURED", [errorMessage])}</p>
        </SweetAlert>
      )
    });
  }

  async displayConfirmation() {
    this.setState({
      alert: (
        <SweetAlert
          danger
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          confirmBtnText={i18n.t("YES_DELETE")}
          confirmBtnBsStyle="danger"
          title={i18n.t("ARE_YOU_SURE")}
          onConfirm={async () => {
            const response = await deleteRestaurant(this.state.restaurantId);
            if (response.success) {
              this.displaySuccess(i18n.t("DELETED"));
            } else {
              this.displayError(response.message);
            }
          }}
          onCancel={() => { this.setState({ "alert": null }) }}
          focusCancelBtn
        >
          <p>{i18n.t("PERMANENT_RESTAURANT_DELETION")}</p>
        </SweetAlert>)
    });
  }

  /**
   * Creates or updates a restaurant.  Called when the user clicks on the submit button
   * 
   * @param {Event} e 
   */
  onFormSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    // validating form first
    if (e.currentTarget.checkValidity()) {
      this.displayLoader();

      // updating lat and lng of the state according to the address 
      await this.setLatLng();

      // generating new unique file name
      const newFileName = this.generateNewImageFileName();

      // creating or updating a restaurant
      let response = null;
      if (!this.state.isEdit) {
        // creating
        response = await addRestaurant({
          name: this.state.restaurantName,
          description: this.state.description,
          categories: this.state.categories,
          transactional_link: this.state.transactionalLink,
          image_file_name: newFileName,
          address: this.state.address,
          lat: this.state.lat,
          lng: this.state.lng,
          working_hours: this.state.workingHours
        });
      } else {
        // updating
        response = await updateRestaurant({
          id: this.state.restaurantId,
          name: this.state.restaurantName,
          description: this.state.description,
          categories: this.state.categories,
          transactional_link: this.state.transactionalLink,
          image_file_name: newFileName,
          address: this.state.address,
          lat: this.state.lat,
          lng: this.state.lng,
          working_hours: this.state.workingHours
        });
      }
      // check if creation or update was successful
      if (response.success) {
        // getting the id of the restaurant
        let restaurantId = this.state.restaurantId;
        if (!this.state.isEdit) {
          restaurantId = response.id;
        }

        // uploading the image to the backend if it's been changed
        if (newFileName != "default.png" && this.state.imageFile != null) {
          let formData = new FormData();
          const file = new File([this.state.imageFile], newFileName, { type: this.state.imageFile.type });
          formData.append("file", file);
          formData.append("type", "restaurant-image");
          uploadImage(formData);
        }

        this.displaySuccess(this.state.isEdit ? i18n.t("UPDATED") : i18n.t("CREATED"));
      } else {
        this.displayError(response.message);
      }
    }
  }

  render() {

    return (
      <div className="main-content new-edit-restaurant">
        {this.state.alert}
        <Grid fluid>
          <Row>
            <Col md={7}>
              <Card
                title={i18n.t(this.state.title)}
                content={
                  <Form onSubmit={this.onFormSubmit.bind(this)}>
                    <FormGroup>
                      <ControlLabel>{i18n.t("RESTAURANT_NAME")}</ControlLabel>
                      <FormControl required value={this.state.restaurantName} placeholder={i18n.t("ENTER_RESTAURANT_NAME")} type="text" onChange={this.onChangeRestaurantName.bind(this)} />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>{i18n.t("RESTAURANT_DESCRIPTION")}</ControlLabel>
                      <FormControl style={{maxWidth: '100%'}} required value={this.state.description} placeholder={i18n.t("ENTER_RESTAURANT_DESCRIPTION")} onChange={this.onChangeRestaurantDescription.bind(this)} componentClass="textarea" />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>{i18n.t("TRANSACTIONAL_LINK")}</ControlLabel>
                      <FormControl required value={this.state.transactionalLink} placeholder={i18n.t("ENTER_TRANSACTIONAL_LINK")} type="url" onChange={this.onChangeTransactionalLink.bind(this)} />
                    </FormGroup>
                    <FormGroup className="location-input-restaurants">
                      <ControlLabel>{i18n.t("ENTER_RESTAURANT_ADDRESS")}</ControlLabel>
                      <LocationInput onChange={this.onChangeAddress.bind(this)} value={this.state.address}></LocationInput>
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>{i18n.t("CHANGE_RESTAURANT_IMAGE")}</ControlLabel>
                      <FormControl type="file" accept="image/png, image/gif, image/jpeg" onChange={this.onChangeImage.bind(this)} />
                      <img className="restaurant-image-preview" height="100" src={this.state.image == null ? apiAddress + "get_restaurant_image?image_name=" + this.state.imageFileName : this.state.image} alt={i18n.t("RESTAURANT_IMAGE")}></img>
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>{i18n.t("RESTAURANT_CATEGORIES")}</ControlLabel>
                      <Typeahead
                        selected={this.state.categories}
                        onChange={this.onChangeCategories.bind(this)}
                        id="custom-selections-example"
                        className="food-types"
                        allowNew
                        multiple
                        newSelectionPrefix={i18n.t("ADD_NEW_FOOD_TYPE")}
                        options={this.state.existingCategories}
                        placeholder={i18n.t("ENTER_FOOD_TYPES")}
                        minLength={2}
                      />
                    </FormGroup>
                    <div className="restaurant-cta-buttons">
                      <Button fill type="submit" >
                        {i18n.t("SUBMIT")}
                      </Button>
                      {this.state.isEdit &&
                        <Button fill bsStyle="danger"
                          onClick={this.displayConfirmation.bind(this)}>
                          {i18n.t("DELETE")}
                        </Button>

                      }
                    </div>
                  </Form>
                }
              />
            </Col>
            <BusinessSchedule workingHours={this.state.workingHours} onChange={this.handleChangeSchedule.bind(this)}></BusinessSchedule>

          </Row>
          <Row>
          </Row>
        </Grid>
      </div>
    );
  }
}


export default NewEditRestaurant;
