import React, { Component } from "react";
import { Trans } from "react-i18next";
import { getDriverOnlineSessions } from "../../helpers/apiHelper";
import {utcStringToLocalString} from '../../helpers/dateHelper';
import { Table} from "react-bootstrap";
import StatsCard from "../../components/Card/StatsCard";

class DriverOnlineSessions extends Component {

    constructor(props) {
        super(props);
        this.getData(this.props.accountName);
        this.state = {
            moreInfoString: <Trans>DRIVER_EDIT_MORE_INFO</Trans>,
            moreInfoIcon: "fa fa-info",
            showMoreInfo: false, //Hide list by default
            searchString: ""
        }
    }

    getData = async (accountName) => {
        await getDriverOnlineSessions(accountName).then((response) => {
            this.setState({sessionList: response}); 
        });
    }

    showHideMoreInfo = () => {
        this.setState({
            showMoreInfo: !this.state.showMoreInfo,
            moreInfoString: (this.state.moreInfoString===<Trans>DRIVER_EDIT_MORE_INFO</Trans>) ? <Trans>DRIVER_EDIT_CLOSE</Trans> : <Trans>DRIVER_EDIT_MORE_INFO</Trans>,
            moreInfoIcon: (this.state.moreInfoIcon==="fa fa-info") ? "fa fa-close" : "fa fa-info"
        });
        window.scrollTo(0, 0);
    }

    convertSessionsList(sessionsList) {
        const sessions = sessionsList;
        let convertedList = []

        let i;
        for(i=0;i<sessions.length;i++) {
            const correctOnline = utcStringToLocalString(sessions[i].online_block_time);
            const correctedOffline = utcStringToLocalString(sessions[i].offline_block_time);
            convertedList[i] = {
                date: correctOnline.substr(0, 10), //First 10 characters are the date (same for online and offline)
                online_time: correctOnline.substr(10, 11), //11-19 are the time
                offline_time: correctedOffline.substr(10, 11)
            }
        }
        return convertedList;
    }

    handleSearchChange = () => {
        this.setState({
          searchString: this.refs.search.value
        });
      }

    subtractTimeStrings(first, second) {
        first = first.split(':');
        second = second.split(':');

        let result = [
            first[0]-second[0], first[1]-second[1], first[2]-second[2]
        ];

        //Correct negatives
        if (result[0]<0) {
            result[0]+=24;
        }
        if (result[1]<0) {
            result[0]--;
            result[1]+=60;
        }
        if (result[2]<0) {
            result[1]--;
            result[2]+=60;
        }

        //Correct the 0s
        let zero0 = "", zero1 = "", zero2 = "";
        if (result[0]<10) {
            zero0 = "0";
        }
        if (result[1]<10) {
            zero1 = "0";
        }
        if (result[2]<10) {
            zero2 = "0";
        }

        return zero0 + result[0].toString() + ":" + zero1 + result[1].toString() + ":" + zero2 + result[2].toString();
    }

    render() {
            if (this.state.sessionList) { 
                let sessions = this.convertSessionsList(this.state.sessionList);
                let search = this.state.searchString.trim().toLowerCase();

                if (search.length > 0) {
                    sessions = sessions.filter(function(session) {
                      return session.date.toLowerCase().match(search);
                    });
                  }
                
                const blockTimeTableData = []

                let i;
                for (i=0;i<sessions.length;i++) {
                    blockTimeTableData.push(
                    <tr>
                        <td>{sessions[i].date}</td>
                        <td>{sessions[i].online_time}</td>
                        <td>{sessions[i].offline_time}</td>
                        <td>{this.subtractTimeStrings(sessions[i].offline_time, sessions[i].online_time)}</td>
                    </tr>
                    )
                }

              let card = (
                <div style={{cursor:"pointer", width:"25%"}} onClick={this.showHideMoreInfo}>
                    <StatsCard
                        bigIcon={<i className="fa fa-car text-success" />}
                        statsText={<Trans>DRIVER_EDIT_ONLINE_SESSIONS</Trans>}
                        statsValue={sessions.length}
                        statsIcon={<i className={this.state.moreInfoIcon} />}
                        statsIconText={this.state.moreInfoString}
                    />
                 </div>
              )

              let onlineSessionList = (
                <div className="card card-stats">
                    <div id="Filters">
                        <strong style={{margin:"1%"}}>Filters:</strong>
                        <input style={{padding: "1%", margin:"1%"}}
                            type="text"
                            value={this.state.searchString}
                            ref="search"
                            onChange={this.handleSearchChange}
                            placeholder="YYYY-MM-DD"
                        />
                    </div>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th><Trans>DRIVER_EDIT_DATE</Trans></th>
                            <th><Trans>DRIVER_EDIT_START</Trans></th>
                            <th><Trans>DRIVER_EDIT_END</Trans></th>
                            <th><Trans>DRIVER_EDIT_DURATION</Trans></th>
                        </tr>
                        </thead>
                        <tbody>
                            {blockTimeTableData}
                        </tbody>
                    </Table>
                </div>
              )

              if (this.state.showMoreInfo) {
                    return (
                        <div>
                            {card}
                            {onlineSessionList}
                        </div>
                    )
                    } else {
                        return (
                            <div>
                            {card}
                        </div>
                        )
                    }
            } else {
                return (<div></div>) 
            }
        }
    }
        

export default DriverOnlineSessions;