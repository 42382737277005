import "./DebugSettingsStyles.css";

import { Button, Col, FormControl, Grid, Row } from "react-bootstrap";
import React, { Component } from "react";
import {
  getDebugOptions,
  setDebugOptions
} from "../../helpers/apiHelper";
import {
  getErrorAlert,
  getLoadingAlert,
  getSuccessAlert,
} from "../../helpers/alertHelper";

import DEBUG_CONSTANTS from "./DebugConstants";
import { Trans } from "react-i18next";
import i18n from "i18next";

class DebugSettings extends Component {

  state = {
    alert: null,
    originalSettings: null,
    pinCodeOption: DEBUG_CONSTANTS.DISABLED,
  };

  async componentDidMount() {
    const debugOptions = await getDebugOptions();
    const pinCodeOption = debugOptions.find(e => e.option === DEBUG_CONSTANTS.PIN_CODE);
    const pinCodeOptionValue = pinCodeOption ? pinCodeOption.value : DEBUG_CONSTANTS.DISABLED;
    this.setState({
      pinCodeOption: pinCodeOptionValue
    });
  }

  changePinCodeOption = (value) => {
    this.setState({ pinCodeOption: value });
  };

  saveSettings = async () => {
    this.loadingHandlerAlert();
    const { pinCodeOption } = this.state;
    const { success } = await setDebugOptions([{
      "option": DEBUG_CONSTANTS.PIN_CODE,
      "value": pinCodeOption
    }]);
    if (success) {
      this.setState({
        originalSettings: await getDebugOptions(),
      });
      this.successHandlerAlert();
    } else {
      this.errorHandlerAlert();
    }
  };

  errorHandlerAlert = (error, msg) => {
    this.setState({
      alert: getErrorAlert(() => this.hideAlert(), error, msg),
    });
  };

  loadingHandlerAlert = () => {
    this.setState({
      alert: getLoadingAlert(),
    });
  };

  successHandlerAlert = () => {
    this.setState({
      alert: getSuccessAlert(() => this.hideAlert()),
    });
  };

  hideAlert() {
    this.setState({ alert: null });
  }


  render() {
    return (
      <div
        style={{
          minHeight: "85vh",
          padding: "6%",
          textAlign: "center",
          backgroundColor: "white",
        }}
      >
        {this.state.alert}
        <Grid>
          <Row>
            <Col className="col-d">
              <p className="setting-title"><Trans>PIN_CODE_TITLE</Trans></p>
              <p className="setting-subtitle">
                <i><Trans>PIN_CODE_SUBTITLE</Trans></i>
              </p>
                                    <FormControl
                                        onChange={(e) => this.changePinCodeOption(e.target.value)}
                                        style={{width: '25%', marginLeft: "37%"}}
                                        value={this.state.pinCodeOption}
                                        componentClass="select" placeholder="---">
                                        <option value={DEBUG_CONSTANTS.ENABLED}>{i18n.t('ENABLED')}</option>
                                        <option value={DEBUG_CONSTANTS.DISABLED}>{i18n.t('DISABLED')}</option>
                                    </FormControl>
            </Col>
            </Row>
          <Row style={{ marginTop: "20%" }}>
            <Col>
              <Button
                style={{ width: "40%" }}
                onClick={() => this.saveSettings()}
              >
                <Trans>SAVE</Trans>
              </Button>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default DebugSettings;

