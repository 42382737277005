import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Row,
  HelpBlock,
  Form,
  InputGroup,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import React, { Component } from "react";
import {
  createEditRiderRideService,
  createQuoteStrategy,
  deleteBusiness,
  editBusiness,
  getBusinessByAccount,
  registerBusiness,
  removeQuoteStrategy,
} from '../../helpers/apiHelper';

import Button from "../../components/CustomButton/CustomButton.jsx";
import Card from "../../components/Card/Card.jsx";
import ChartistGraph from "react-chartist";
import RideServiceNames from "../../const/RideServiceNames";
import SweetAlert from "react-bootstrap-sweetalert";
import { Trans } from "react-i18next";
import queryString from "query-string";
import { responsiveSales } from "../../variables/Variables.jsx";

var moment = require('moment-timezone');

const NUMBER_OF_WEEKS_IN_GRAPH = 16

class NewEditBusiness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isEditQuoteStrategy: false,
      title: "",
      email: "",
      password: "",
      company_name: "",
      company_phone: "",
      contact_first_name: "",
      contact_last_name: "",
      contact_phone: "",
      contact_email: "",
      billing_email: "",
      preferred_language: "",
      quoteStrategyType: "TOTAL_REDUCTION",
      quoteStrategyAmount: "",
      customQuoteTitle: "ADD_QUOTE_STRATEGY_TITLE",
      alert: null,
      accountName: null,
      custom_prices_per_ride_service_type_id: {},
      graphData: [],
      graphLabels: [],
      default_address: "",
      send_sms_tracking: true,
      send_email_tracking: true,
      apt_number: "",
      parent_rider_account: "",
    };
  }

  async componentDidMount() {
    let custom_prices_per_ride_service_type_id = {}
    for (const ride_service_type_id in RideServiceNames) {
      custom_prices_per_ride_service_type_id[ride_service_type_id] = {}
    }
    this.setState({ custom_prices_per_ride_service_type_id });

    const params = queryString.parse(this.props.location.search);
    const accountName = params.name;
    if (accountName != null) {
      const businessData = await getBusinessByAccount(accountName);
      let stateUpdated = {
        isEdit: true,
        title: "BUSINESS_EDIT_SUBTITLE",
        email: businessData.email,
        company_name: businessData.company_name,
        company_phone: businessData.company_phone,
        contact_first_name: businessData.contact_first_name,
        contact_last_name: businessData.contact_last_name,
        contact_phone: businessData.contact_phone,
        contact_email: businessData.contact_email,
        billing_email: businessData.billing_email,
        preferred_language: businessData.preferred_language,
        accountName: accountName,
        statistics: businessData.statistics,
        send_email_tracking: businessData.send_email_tracking,
        send_sms_tracking: businessData.send_sms_tracking,
        default_address: businessData.default_address,
        apt_number: businessData.apt_number,
        parent_rider_account: businessData.parent_corporate_user != null ? businessData.parent_corporate_user[0] : "",
      };

      if (!this.isEmpty(businessData.rider_ride_services)) {
        stateUpdated["custom_prices_per_ride_service_type_id"] = businessData.rider_ride_services;
      }

      if ('custom_quote_strategy' in businessData) {
        stateUpdated['isEditQuoteStrategy'] = true;
        stateUpdated["quoteStrategyType"] = businessData.custom_quote_strategy.strategy_type
        stateUpdated["quoteStrategyAmount"] = businessData.custom_quote_strategy.amount_cent
        stateUpdated["customQuoteTitle"] = "EDIT_QUOTE_STRATEGY_TITLE"
      }

      await this.setState(stateUpdated);


      this.prepareGraphData();
    } else {
      this.setState({ title: "BUSINESS_NEW_SUBTITLE", preferred_language: "fr", send_email_tracking: true, send_sms_tracking: true })
    }

  }

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  prepareGraphData = () => {
    let today = moment();
    let year = parseInt(moment().format('YYYY'));
    let week = parseInt(moment().format('W'));

    const graphData = [];
    const graphLabels = [];
    const { statistics } = this.state;

    let counter = 0;
    for (let i = 0; i < NUMBER_OF_WEEKS_IN_GRAPH; i++) {
      today = today.subtract(7, 'd')
      graphLabels.push(today.format("MM/DD"))

      if (counter == statistics.length) {
        graphData.push(0);
        continue;
      }

      const entry = statistics[counter];
      if (entry.year === year && entry.week === week) {
        graphData.push(entry.count);
        counter++;
      } else {
        graphData.push(0);
      }

      if (week === 0) {
        year--;
      }
      week--;
    }
    this.setState({ graphData: graphData.reverse(), graphLabels: graphLabels.reverse() });
  }

  sanitizeInputString = (string) => {
    //Do not accept quotes since this is causing issues in the demux
    return string ? string.replace('\'', '').replace('\"', '') : string;
  }

  onChangeEmail = (e) => {
    let value = e.target.value;
    value = this.sanitizeInputString(value);
    this.setState({
      "email": value
    });
  };


  onChangeParentRiderAccount = (e) => {
    let value = e.target.value;
    value = this.sanitizeInputString(value);
    this.setState({
      parent_rider_account: value,
    })
  }

  onChangePassword = (e) => {
    let value = e.target.value;
    value = this.sanitizeInputString(value);
    this.setState({
      "password": value
    });
  };

  onChangeCompanyName = (e) => {
    let value = e.target.value;
    value = this.sanitizeInputString(value);
    this.setState({
      "company_name": value
    });
  };

  onChangeCompanyAddress = (e) => {
    let value = e.target.value;
    value = this.sanitizeInputString(value);
    this.setState({
      "default_address": value
    });
  };

  onChangeApartmentNumber = (e) => {
    let value = e.target.value;
    value = this.sanitizeInputString(value);
    this.setState({
      "apt_number": value
    });
  };

  onChangeContactFirstName = (e) => {
    let value = e.target.value;
    value = this.sanitizeInputString(value);
    this.setState({
      "contact_first_name": value
    });
  };

  onChangeContactLastName = (e) => {
    let value = e.target.value;
    value = this.sanitizeInputString(value);
    this.setState({
      "contact_last_name": value
    });
  };

  onChangeContactPhone = (e) => {
    let value = e.target.value;
    value = this.sanitizeInputString(value);
    this.setState({
      "contact_phone": value
    });
  };

  onChangeContactEmail = (e) => {
    let value = e.target.value;
    value = this.sanitizeInputString(value);
    this.setState({
      "contact_email": value
    });
  };

  onChangeBillingEmail = (e) => {
    let value = e.target.value;
    value = this.sanitizeInputString(value);
    console.log(value)
    this.setState({
      "billing_email": value
    });
  };


  onChangeCompanyPhone = (e) => {
    let value = e.target.value;
    value = this.sanitizeInputString(value);
    this.setState({
      "company_phone": value
    });
  };

  onChangePreferredLanguage = (e) => {
    let value = e.target.value;
    this.setState({
      "preferred_language": value
    });
    e.checked = true;
  };

  onChangeSendSmsTracking = (e) => {
    let value = e.target.value === 'false' ? false : true;
    this.setState({
      "send_sms_tracking": value
    });
    e.checked = true;
  };

  onChangeSendEmailTracking = (e) => {
    let value = e.target.value === 'false' ? false : true;
    this.setState({
      "send_email_tracking": value
    });
    e.checked = true;
  };

  onChangeAmountQuoteStrategy = (e) => {
    let value = e.target.value;
    this.setState({
      "quoteStrategyAmount": value
    });
  }

  onQuoteStrategyTypeChange = (e) => {
    let value = e.target.value;
    this.setState({
      "quoteStrategyType": value
    });
  }

  onDeleteQuoteStrategy = async (e) => {
    this.setState({
      alert: (
        <SweetAlert
          default
          style={{ display: "block", marginTop: "-100px" }}
          title="Sending data"
          confirmBtnBsStyle="info"
        >
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
          <p>
            <Trans>MAP_LOADING</Trans>
          </p>
        </SweetAlert>
      )
    });
    const response = await removeQuoteStrategy({
      'rider_account': this.state.accountName,
    });

    if (response.success) {
      this.setState({
        alert: (
          <SweetAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Success"
            onConfirm={() => { this.setState({ alert: null }) }}
            onCancel={() => { this.setState({ alert: null }) }}
            confirmBtnBsStyle="info"
          >
            <p></p>

          </SweetAlert>
        )
      });
    } else {
      this.setState({
        alert: (
          <SweetAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Error"
            onConfirm={() => { this.setState({ "alert": null }) }}
            confirmBtnBsStyle="info"
          >
            <p>An Error has occurred : {response.message}</p>
          </SweetAlert>
        )
      });
    }
  }

  onChangePricingParam = (ride_service_type_id, e, pricing_key) => {
    let value = e.target.value;
    let custom_prices_per_ride_service_type_id = this.state.custom_prices_per_ride_service_type_id;
    console.log("custom_prices_per_ride_service_type_id", custom_prices_per_ride_service_type_id);
    custom_prices_per_ride_service_type_id[ride_service_type_id][pricing_key] = value;
    this.setState({ custom_prices_per_ride_service_type_id });
  }

  onFormSubmitQuoteStrategy = async (e) => {
    this.setState({
      alert: (
        <SweetAlert
          default
          style={{ display: "block", marginTop: "-100px" }}
          title="Sending data"
          confirmBtnBsStyle="info"
        >
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
          <p>
            <Trans>MAP_LOADING</Trans>
          </p>
        </SweetAlert>
      )
    })

    let response = null;
    if (this.state.isEditQuoteStrategy) {
      // First delete current strategy
      response = await removeQuoteStrategy({
        'rider_account': this.state.accountName,
      });

      if (response.success) {
        response = await createQuoteStrategy({
          'rider_account': this.state.accountName,
          'strategy_type': this.state.quoteStrategyType,
          'amount_cent': parseInt(this.state.quoteStrategyAmount),
        });

      }
    } else {
      response = await createQuoteStrategy({
        'rider_account': this.state.accountName,
        'strategy_type': this.state.quoteStrategyType,
        'amount_cent': parseInt(this.state.quoteStrategyAmount),
      });
    }

    if (response.success) {
      this.setState({
        alert: (
          <SweetAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Success"
            onConfirm={() => { this.setState({ alert: null }) }}
            onCancel={() => { this.setState({ alert: null }) }}
            confirmBtnBsStyle="info"
          >
            <p></p>

          </SweetAlert>
        )
      });
    } else {
      this.setState({
        alert: (
          <SweetAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Error"
            onConfirm={() => { this.setState({ "alert": null }) }}
            confirmBtnBsStyle="info"
          >
            <p>An Error has occurred : {response.message}</p>
          </SweetAlert>
        )
      });
    }
  }

  onFormSubmit = async (e) => {
    this.setState({
      alert: (
        <SweetAlert
          default
          style={{ display: "block", marginTop: "-100px" }}
          title="Sending data"
          confirmBtnBsStyle="info"
        >
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
          <p>
            <Trans>MAP_LOADING</Trans>
          </p>
        </SweetAlert>
      )
    })
    let response = null;
    if (!this.state.isEdit) {
      response = await registerBusiness(
        this.state.email,
        this.state.password,
        this.state.company_name,
        this.state.company_phone,
        this.state.contact_first_name,
        this.state.contact_last_name,
        this.state.contact_phone,
        this.state.contact_email,
        this.state.preferred_language,
        this.state.send_email_tracking,
        this.state.send_sms_tracking,
        this.state.default_address,
        this.state.apt_number,
        this.state.parent_rider_account.length > 0 ? this.state.parent_rider_account : null,
        this.state.billing_email
      );
    } else {
      response = await editBusiness({
        email: this.state.email,
        rider_account: this.state.accountName,
        company_name: this.state.company_name,
        company_phone: this.state.company_phone,
        contact_first_name: this.state.contact_first_name,
        contact_last_name: this.state.contact_last_name,
        contact_phone: this.state.contact_phone,
        contact_email: this.state.contact_email,
        preferred_language: this.state.preferred_language,
        send_sms_tracking: this.state.send_sms_tracking,
        send_email_tracking: this.state.send_email_tracking,
        default_address: this.state.default_address,
        apt_number: this.state.apt_number,
        parent_rider_account: this.state.parent_rider_account.length > 0 ? this.state.parent_rider_account : null,
        billing_email: this.state.billing_email
      });
    }
    if (response.success) {
      let accesTokenSection = null;
      if (!this.state.isEdit) {
        accesTokenSection = response.access_token + "     Please note the access token somewhere safe. This information is needed for external business software integrations"
      }
      this.setState({
        alert: (
          <SweetAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Success"
            onConfirm={() => {
              this.setState({ alert: null });
              this.props.history.push("/business/list");
            }}
            onCancel={() => {
              this.setState({ alert: null });
              this.props.history.push("/business/list");
            }}
            confirmBtnBsStyle="info"
          >
            {accesTokenSection}
            <p>The account corporate account has been successfully created.</p>

          </SweetAlert>
        )
      });
    } else {
      this.setState({
        alert: (
          <SweetAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Error"
            onConfirm={() => { this.setState({ "alert": null }) }}
            confirmBtnBsStyle="info"
          >
            <p>An Error has occurred : <Trans>{response.message}</Trans></p>
          </SweetAlert>
        )
      });
    }
  }

  onSubmitCustomPricing = async (ride_service_type_id) => {
    this.setState({
      alert: (
        <SweetAlert
          default
          style={{ display: "block", marginTop: "-100px" }}
          title="Sending data"
          confirmBtnBsStyle="info"
        >
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
          <p>
            <Trans>MAP_LOADING</Trans>
          </p>
        </SweetAlert>
      )
    })
    let values = this.state.custom_prices_per_ride_service_type_id[ride_service_type_id];
    values['rider_account'] = this.state.accountName;
    values['ride_service_type_id'] = ride_service_type_id;
    const response = await createEditRiderRideService(values);

    if (response.success) {
      this.setState({
        alert: (
          <SweetAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Success"
            onConfirm={() => { this.setState({ alert: null }) }}
            onCancel={() => { this.setState({ alert: null }) }}
            confirmBtnBsStyle="info"
          >
            <p>The account corporate pricing parameters has been sent successfully.</p>
          </SweetAlert>
        )
      });
    } else {
      this.setState({
        alert: (
          <SweetAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Error"
            onConfirm={() => { this.setState({ "alert": null }) }}
            confirmBtnBsStyle="info"
          >
            <p>An Error has occurred : {response.message}</p>
          </SweetAlert>
        )
      });
    }
  }

  onDelete = async () => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={async () => {
            // remove corporate user / business
            const response = await deleteBusiness({ "rider_account": this.state.accountName });
            if (response.success) {
              this.setState({
                alert: (
                  <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Success"
                    onConfirm={() => {
                      this.setState({ alert: null });
                      this.props.history.push("/business/list");
                    }}
                    onCancel={() => {
                      this.setState({ alert: null });
                      this.props.history.push("/business/list");
                    }}
                    confirmBtnBsStyle="info"
                  >
                    <p>The business has been succesfully deleted.</p>
                  </SweetAlert>
                )
              });
            } else {
              this.setState({
                alert: (
                  <SweetAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error"
                    onConfirm={() => {
                      this.setState({ "alert": null })
                    }}
                    confirmBtnBsStyle="info"
                  >
                    <p>An Error has occurred : {response.message}</p>
                  </SweetAlert>
                )
              });
            }
          }}
          onCancel={() => { this.setState({ "alert": null }) }}
          focusCancelBtn
        >
          <p>This corporate user will be deleted and archived.  The token balance will also be erased</p>
        </SweetAlert>)
    });
  }

  render() {
    let passwordField = null;
    let quoteStrategyComponent = null;
    let addEditRiderRideServiceComponent = [];
    if (!this.state.isEdit) {
      passwordField = <FormGroup>
        <ControlLabel><Trans>Password</Trans></ControlLabel>
        <FormControl placeholder="Password" type="password" onChange={this.onChangePassword.bind(this)} />
      </FormGroup>
    } else {
      let removeButton = null;
      if (this.state.isEditQuoteStrategy) {
        removeButton = <Button bsStyle="error" fill onClick={this.onDeleteQuoteStrategy}>
          <Trans>DELETE_QUOTE_STRATEGY</Trans>
        </Button>
      }
      quoteStrategyComponent = <Row>
        <Col md={10}>
          <Card
            title={<Trans>{this.state.customQuoteTitle}</Trans>}
            content={
              <form>
                <FormGroup controlId="formControlsSelect">
                  <ControlLabel><Trans>QUOTE_TYPE</Trans></ControlLabel>
                  <FormControl
                    onChange={this.onQuoteStrategyTypeChange}
                    value={this.state.quoteStrategyType}
                    componentClass="select" placeholder="Type">
                    <option value="TOTAL_REDUCTION">TOTAL_REDUCTION</option>
                    <option value="FIXED_PRICE">FIXED_PRICE</option>
                  </FormControl>
                </FormGroup>
                <FormGroup>
                  <ControlLabel><Trans>AMOUNT_CENT</Trans></ControlLabel>
                  <FormControl value={this.state.quoteStrategyAmount} placeholder="Amount" type="number" onChange={this.onChangeAmountQuoteStrategy.bind(this)} />
                </FormGroup>
                <Button bsStyle="info" fill onClick={this.onFormSubmitQuoteStrategy.bind(this)}>
                  <Trans>SAVE</Trans>
                </Button>
                {removeButton}
              </form>
            }
          />
        </Col>
      </Row>

      for (const ride_service_type_id in RideServiceNames) {
        let values = {}
        let message = <Trans>USING_NORMAL_PRICING</Trans>
        if (ride_service_type_id in this.state.custom_prices_per_ride_service_type_id) {
          values = this.state.custom_prices_per_ride_service_type_id[ride_service_type_id];
          message = null;
        }
        let title = (<span><Trans>CUSTOM_PRICING</Trans>  <Trans>{RideServiceNames[ride_service_type_id]}</Trans></span>)
        let addEditRiderRideServiceComponentToAdd = <Row>
          <Col md={10}>
            <Card
              title={<Trans>{title}</Trans>}
              content={
                <form>
                  {message}<br /><br />
                  <FormGroup>
                    <ControlLabel><Trans>COMMUNITY_RATE_X100</Trans></ControlLabel>
                    <FormControl value={values['community_rate_x100']} placeholder="Amount" type="number" onChange={(e) => { this.onChangePricingParam(ride_service_type_id, e, 'community_rate_x100') }} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel><Trans>GLOBAL_RATE_X100</Trans></ControlLabel>
                    <FormControl value={values['global_rate_x100']} placeholder="Amount" type="number" onChange={(e) => { this.onChangePricingParam(ride_service_type_id, e, 'global_rate_x100') }} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel><Trans>MINUTE_RATE_CENT</Trans></ControlLabel>
                    <FormControl value={values['minute_rate']} placeholder="Amount" type="number" onChange={(e) => { this.onChangePricingParam(ride_service_type_id, e, 'minute_rate') }} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel><Trans>KM_RATE_CENT</Trans></ControlLabel>
                    <FormControl value={values['km_rate']} placeholder="Amount" type="number" onChange={(e) => { this.onChangePricingParam(ride_service_type_id, e, 'km_rate') }} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel><Trans>MIN_CHARGE</Trans></ControlLabel>
                    <FormControl value={values['min_charge']} placeholder="Amount" type="number" onChange={(e) => { this.onChangePricingParam(ride_service_type_id, e, 'min_charge') }} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel><Trans>CANCEL_FEE_CENT</Trans></ControlLabel>
                    <FormControl value={values['cancel_fee']} placeholder="Amount" type="number" onChange={(e) => { this.onChangePricingParam(ride_service_type_id, e, 'cancel_fee') }} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel><Trans>BASE_CHARGE</Trans></ControlLabel>
                    <FormControl value={values['base_charge']} placeholder="Amount" type="number" onChange={(e) => { this.onChangePricingParam(ride_service_type_id, e, 'base_charge') }} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel><Trans>SUPPLEMENT_COMM_CHARGE</Trans></ControlLabel>
                    <FormControl value={values['supplement_comm_charge']} placeholder="Amount" type="number" onChange={(e) => { this.onChangePricingParam(ride_service_type_id, e, 'supplement_comm_charge') }} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel><Trans>SUPPLEMENT_COMM_CHARGE_DESCRIPTION</Trans></ControlLabel>
                    <FormControl
                      value={values['supplement_comm_charge_desc']}
                      onChange={(e) => { this.onChangePricingParam(ride_service_type_id, e, 'supplement_comm_charge_desc') }}
                      componentClass="select" placeholder="Type">
                      <option value=""></option>
                      <option value="EVA_FEE">EVA_FEE</option>
                      <option value="QUEBEC_GOUV_REDEEM">QUEBEC_GOUV_REDEEM</option>
                    </FormControl>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel><Trans>SUPPLEMENT_WORLD_CHARGE</Trans></ControlLabel>
                    <FormControl value={values['supplement_world_charge']} placeholder="Amount" type="number" onChange={(e) => { this.onChangePricingParam(ride_service_type_id, e, 'supplement_world_charge') }} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel><Trans>SUPPLEMENT_WORLD_CHARGE_DESCRIPTION</Trans></ControlLabel>
                    <FormControl
                      value={values['supplement_world_charge_desc']}
                      onChange={(e) => { this.onChangePricingParam(ride_service_type_id, e, 'supplement_world_charge_desc') }}
                      componentClass="select" placeholder="Type">
                      <option value=""></option>
                      <option value="EVA_FEE">EVA_FEE</option>
                      <option value="QUEBEC_GOUV_REDEEM">QUEBEC_GOUV_REDEEM</option>
                    </FormControl>
                  </FormGroup>
                  <Button bsStyle="info" fill onClick={() => { this.onSubmitCustomPricing(ride_service_type_id) }}>
                    <Trans>SAVE</Trans>
                  </Button>
                  {removeButton}
                </form>
              }
            />
          </Col>
        </Row>
        addEditRiderRideServiceComponent.push(addEditRiderRideServiceComponentToAdd);
      }
    }
    return (
      <div className="main-content">
        {this.state.alert}
        <Grid fluid>
          {this.state.isEdit &&
            <Row>
              <Col md={10}>
                <Card
                  title={<Trans>RIDE_REQUESTS_RIDER_GRAPH_TITLE</Trans>}
                  content={<div>
                    <ChartistGraph
                      data={{
                        labels: this.state.graphLabels,
                        series: [this.state.graphData]
                      }}
                      type="Bar"
                      options={{
                        showArea: false,
                        height: "290px",
                        axisX: {
                          showGrid: false
                        },
                        lineSmooth: true,
                        showLine: true,
                        showPoint: true,
                        fullWidth: true,
                        chartPadding: {
                          right: 50
                        }
                      }}
                      responsiveOptions={responsiveSales}
                    />
                  </div>}

                />
              </Col>
            </Row>}
          <Row>
            <Col md={10}>
              <Card
                title={<Trans>{this.state.title}</Trans>}
                content={
                  <form>
                    <FormGroup>
                      <ControlLabel><Trans>RIDER_ACCOUNT</Trans></ControlLabel>
                      <FormControl value={this.state.accountName} type="text" readOnly={"true"} />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel><Trans>Email address</Trans></ControlLabel>
                      <FormControl value={this.state.email} placeholder="Enter email" type="email" onChange={this.onChangeEmail.bind(this)} />
                    </FormGroup>
                    {passwordField}
                    <FormGroup>
                      <ControlLabel><Trans>COMPANY_NAME</Trans></ControlLabel>
                      <FormControl value={this.state.company_name} placeholder="Enter corp name" type="text" onChange={this.onChangeCompanyName.bind(this)} />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel><Trans>COMPANY_ADDRESS</Trans></ControlLabel>
                      <FormControl value={this.state.default_address} placeholder="Enter corp address" type="text" onChange={this.onChangeCompanyAddress.bind(this)} />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel><Trans>COMPANY_APARTMENT_NUMBER</Trans></ControlLabel>
                      <FormControl value={this.state.apt_number} placeholder="Enter Apartment Number" type="text" onChange={this.onChangeApartmentNumber.bind(this)} />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel><Trans>COMPANY_PHONE</Trans></ControlLabel>
                      <FormControl value={this.state.company_phone} placeholder="Enter phone #" type="text" onChange={this.onChangeCompanyPhone.bind(this)} />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel><Trans>CONTACT_FIRST_NAME</Trans></ControlLabel>
                      <FormControl value={this.state.contact_first_name} placeholder="Name" type="text" onChange={this.onChangeContactFirstName.bind(this)} />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel><Trans>CONTACT_LAST_NAME</Trans></ControlLabel>
                      <FormControl value={this.state.contact_last_name} placeholder="Name" type="text" onChange={this.onChangeContactLastName.bind(this)} />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel><Trans>CONTACT_PHONE</Trans></ControlLabel>
                      <FormControl value={this.state.contact_phone} placeholder="Phone" type="text" onChange={this.onChangeContactPhone.bind(this)} />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel><Trans>CONTACT_EMAIL</Trans></ControlLabel>
                      <FormControl value={this.state.contact_email} placeholder="Email" type="email" onChange={this.onChangeContactEmail.bind(this)} />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel><Trans>BILLING_EMAIL</Trans></ControlLabel>
                      <FormControl value={this.state.billing_email} placeholder="Email" type="email" onChange={this.onChangeBillingEmail.bind(this)} />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>
                        <Trans>PARENT_RIDER_ACCOUNT</Trans>
                      </ControlLabel>
                      <FormControl
                        value={this.state.parent_rider_account}
                        placeholder="Parent Rider Account"
                        type="text"
                        onChange={this.onChangeParentRiderAccount.bind(this)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>
                        <Trans>PREFERRED_LANGUAGE</Trans>
                      </ControlLabel>
                    </FormGroup>
                    <FormGroup>
                      <ButtonGroup toggle className="mb-2" onChange={this.onChangePreferredLanguage.bind(this)}>
                        <ToggleButton
                          type="radio"
                          name="pref-lang-radio"
                          value="fr"
                          checked={this.state.preferred_language === "fr"}
                        >
                          &ensp; <Trans>FRENCH</Trans>
                        </ToggleButton>
                        <ToggleButton
                          type="radio"
                          name="pref-lang-radio"
                          value="en"
                          checked={this.state.preferred_language === "en"}
                        >
                          &ensp;<Trans>ENGLISH</Trans>
                        </ToggleButton>
                        <ToggleButton
                          type="radio"
                          name="pref-lang-radio"
                          value="es"
                          checked={this.state.preferred_language === "es"}
                        >
                          &ensp;<Trans>SPANISH</Trans>
                        </ToggleButton>
                      </ButtonGroup>
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>&ensp;<Trans>SEND_SMS_TRACKING</Trans></ControlLabel>
                    </FormGroup>
                    <FormGroup>
                      <ButtonGroup toggle className="mb-2" onChange={this.onChangeSendSmsTracking.bind(this)}>
                        <ToggleButton
                          type="radio"
                          name="send-sms-tracking-radio"
                          value="true"
                          checked={this.state.send_sms_tracking === true}
                        >
                          &ensp;<Trans>YES</Trans>
                        </ToggleButton>
                        <ToggleButton
                          type="radio"
                          name="send-sms-tracking-radio"
                          value="false"
                          checked={this.state.send_sms_tracking === false}
                        >
                          &ensp;<Trans>NO</Trans>
                        </ToggleButton>
                      </ButtonGroup>
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>&ensp;<Trans>SEND_EMAIL_TRACKING</Trans></ControlLabel>
                    </FormGroup>
                    <FormGroup>
                      <ButtonGroup toggle className="mb-2" onChange={this.onChangeSendEmailTracking.bind(this)}>
                        <ToggleButton
                          type="radio"
                          name="send-email-tracking-radio"
                          value="true"
                          checked={this.state.send_email_tracking === true}
                        >
                          &ensp; <Trans>YES</Trans>
                        </ToggleButton>
                        <ToggleButton
                          type="radio"
                          name="send-email-tracking-radio"
                          value="false"
                          checked={this.state.send_email_tracking === false}
                        >
                          &ensp;<Trans>NO</Trans>
                        </ToggleButton>
                      </ButtonGroup>
                    </FormGroup>
                    <Button bsStyle="info" fill onClick={this.onFormSubmit.bind(this)}>
                      Submit
                    </Button>
                    {this.state.isEdit &&
                      <Button bsStyle="danger" fill style={{ float: "right" }} onClick={this.onDelete.bind(this)} >
                        Delete
                      </Button>
                    }
                  </form>
                }
              />
            </Col>
          </Row>
          {quoteStrategyComponent}
          {addEditRiderRideServiceComponent}
        </Grid>
      </div >
    );
  }
}

export default NewEditBusiness;
