import 'react-datetime/css/react-datetime.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

import { Col, Grid, Row } from "react-bootstrap";
import React, { Component } from "react";
import { getCompletedPayments, getCompletedPaymentsTotal } from "../../../helpers/apiHelper";

import { CSVLink } from 'react-csv';
import CurrencyFormat from 'react-currency-format';
import Radio from '../../../components/CustomRadio/CustomRadio';
import StatsCard from "../../../components/Card/StatsCard";
import TableAPI from "../../Tables/TableAPI";
import { Trans } from "react-i18next";
import { connect } from 'react-redux';
import { convertToFiat, getSymbol } from '../../../helpers/paymentHelper';
import i18next from 'i18next';
import moment from 'moment';
import i18n from '../../../i18n';

class HistoricalCashout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: ["EOS Account", "Full Name", "Requested time", "Paid time", "EVA Tokens", getSymbol()],
            totalFiat: 0,
            totalToken: 0,
            error: '',
            radio: 'paid_time',
            startDate: moment('2019-01-01', 'YYYY-MM-DD'),
            endDate: moment(),
            alert: null,
            completedPayments: [],
            filteredCompletedPayments: [],
        };
    }

    componentDidMount = () => {
        if (!this.props.auth) {
            this.props.history.push('/');
        }
    }

    getCompletedPayments = async (data) => {
        data['filter_date_type'] = this.state.radio;
        const completedPayments = await getCompletedPayments(data);

        if (data.count) {
            return completedPayments;
        }

        const { completed_payments, total_cashout_amount } = await getCompletedPaymentsTotal(data);
        this.calculateTotals(total_cashout_amount);

        const { start_datetime, end_datetime } = data;
        if (start_datetime && end_datetime) {
            this.setState({
                startDate: start_datetime,
                endDate: end_datetime
            })
        }

        this.generateCsvData(completed_payments);
        return completedPayments;
    }

    onChangeRadio = (event) => {
        const target = event.target;
        this.setState({
            radio: target.value
        })
    };

    calculateTotals = (totalCashoutAmount) => {
        this.setState({
            //Wont be 100% accurate because of exchange rate fluctuations.
            //Disclaimer added to inform the user this is now an estimate
            totalFiat: convertToFiat(totalCashoutAmount),
            totalToken: totalCashoutAmount,
        });
    };

    generateCsvData = (completedPayments) => {
        const dataSet = [this.state.headers];
        for (const completePayment of completedPayments) {
            const { driver, full_name, cashout_amount, requested_time, paid_time } = completePayment;
            const fiatConverstionAmount = convertToFiat(cashout_amount, requested_time);
            const requested_time_local = moment.utc(requested_time).local().format('YYYY-MM-DD HH:mm:ss');
            const paid_time_local = moment.utc(paid_time).local().format('YYYY-MM-DD HH:mm:ss');

            dataSet.push([driver, full_name, requested_time_local, paid_time_local, cashout_amount + ' EVA', fiatConverstionAmount]);
        }
        dataSet.push(['Total', null, '', '', this.state.totalToken + ' EVA', this.state.totalFiat]);
        this.setState({ csvData: dataSet });
        return dataSet;
    };

    onDriverEosClick = (accountName) => {
        window.open(`#/driver_edit/?name=${accountName}`);
    }

    render() {
        let iconContext = "";
        if (this.state.startDate && this.state.endDate) {
            iconContext = i18next.t("From") + this.state.startDate.format('YYYY-MM-DD') + i18next.t("To") + this.state.endDate.format('YYYY-MM-DD');
        }

        return (
            <div className="content">
                {this.state.alert}
                <Grid fluid>
                    <br />
                    <Col lg={4} sm={6}>
                        <StatsCard
                            bigIcon={<i className="pe-7s-wallet text-success" />}
                            statsText={i18next.t("Total")}
                            statsValue={this.state.totalFiat}
                            evaTokenValue={<div><CurrencyFormat value={this.state.totalToken} displayType={'text'} thousandSeparator={true} prefix={'EVA '} /><br /><p style={{fontSize: "12px", color: "grey"}}>{i18n.t('CURRENCY_ESTIMATE_DISCLAIMER')}</p></div>}
                            statsIcon={<i className="fa fa-calendar-o" />}
                            statsIconText={iconContext}
                        />
                    </Col>
                    <Col md={2}>
                        <h6 className="title"><Trans>Filter on</Trans></h6>
                        <Radio
                            number="2dr"
                            option="requested_time"
                            name="radio"
                            onChange={this.onChangeRadio}
                            checked={this.state.radio === 'requested_time'}
                            label={<Trans>Requested Date</Trans>}

                        />
                        <Radio
                            number="1dr"
                            option="paid_time"
                            name="radio"
                            onChange={this.onChangeRadio}
                            checked={this.state.radio === 'paid_time'}
                            label={<Trans>Payment Date</Trans>}
                        />
                    </Col>
                    <Col md={2}>
                        <CSVLink data={this.state.csvData || ""}><Trans>Download CSV</Trans></CSVLink>
                    </Col>
                    <Row>
                        <Col md={12}>
                            <TableAPI
                                fetchData={this.getCompletedPayments}
                                columns={[
                                    {
                                        Header: <Trans>Eos Account</Trans>,
                                        canSort: true,
                                        canFilter: true,
                                        id: "driver",
                                        width: 130,
                                        accessor: (payment) => {
                                            return payment.driver;
                                        }
                                    },
                                    {
                                        Header: <Trans>Full Name</Trans>,
                                        canFilter: true,
                                        id: "full_name",
                                        width: 180,
                                        accessor: (payment) => {
                                            return payment.full_name;
                                        },
                                        Cell: e => <span style={{ color: '#70CEEC', cursor: 'pointer' }} onClick={() => { this.onDriverEosClick(e.original.driver); }}> {e.value} </span>

                                    },
                                    {
                                        Header: <Trans>Requested Date</Trans>,
                                        canFilter: true,
                                        canSort: true,
                                        filterMode: "date",
                                        id: "requested_time",
                                        width: 180,
                                        accessor: (payment) => {
                                            return moment.utc(payment.requested_time).local().format('YYYY-MM-DD HH:mm:ss');
                                        },
                                    },
                                    {
                                        Header: <Trans>Payment Date</Trans>,
                                        canFilter: true,
                                        canSort: true,
                                        defaultSort: true,
                                        filterMode: "date",
                                        id: "paid_time",
                                        width: 180,
                                        accessor: (payment) => {
                                            return moment.utc(payment.paid_time).local().format('YYYY-MM-DD HH:mm:ss');
                                        },
                                    },
                                    {
                                        Header: <Trans>Eva Tokens</Trans>,
                                        canSort: true,
                                        id: "cashout_amount",
                                        width: 180,
                                        accessor: (payment) => {
                                            return payment.cashout_amount.toFixed(2).concat(' EVA')
                                        },
                                    },
                                    {
                                        Header: getSymbol(),
                                        canSort: true,
                                        id: getSymbol(),
                                        width: 180,
                                        accessor: (payment) => {
                                            return convertToFiat(payment.cashout_amount, payment.requested_time);
                                        },
                                    },
                                    {
                                        Header: <Trans>Sequence ID</Trans>,
                                        canSort: true,
                                        id: "seq_id",
                                        width: 180,
                                        accessor: (payment) => {
                                            return payment.seq_id;
                                        },
                                    },
                                ]}/>
                        </Col>
                    </Row>
                </Grid>
                <p></p>
                <br></br>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.userData.isAuthenticated,
        pubKey: state.userData.publicKey,
        privKey: state.userData.privateKey,
        community: state.userData.communityAccount,
        cityName: state.userData.cityName,
    };
};

export default connect(mapStateToProps)(HistoricalCashout);
