import React, { Component } from "react";
import { Grid, Row, Col, Button } from "react-bootstrap";
import Card from "../../../components/Card/Card.jsx";
import { getInvoices, sendLoggedGETResponse, sendLoggedPUTAsync, API_VERSION_1 } from "../../../helpers/apiHelper";
import ReactTable from "react-table";
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import { Trans } from "react-i18next";
import i18n from '../../../i18n';
import { Link } from "react-router-dom";
import "../DriversCashout/PendingCashout.scss"
import { INVOICE_TYPES } from "../../../const/Invoices.js";
import { utcStringToLocalDateString } from "../../../helpers/dateHelper.js";
import { CurrencyFormatter } from "../../../helpers/CurrencyFormatter";
import moment from "moment";

class Invoices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoices: [],
            selectedInvoices: [],
            csvData: [],
            error: '',
            alert: null,
            focusedInput: null,
        };
    }

    componentDidMount = () => {
        this.fetchPendingInvoices();
    }

    fetchPendingInvoices = async () => {
        await getInvoices().then(invoices => {
            invoices.sort((a, b) => {
                if ((a.paid === false && b.paid === false) || (a.paid === true && b.paid === true)) return new Date(a.from_date_inclusive) - new Date(b.from_date_inclusive)
                else if (a.paid == true && b.paid === false) return 1
                else return -1
            })
            // const XOF_invoice = {
            //     "invoice_id": 666,
            //     "invoice_number": "FAKE-XOF",
            //     "invoice_type": 2,
            //     "invoice_date": "2024-06-21T00:00:00",
            //     "partner_id": 8,
            //     "tax_0_charge": 2558,
            //     "tax0_description": "TPS",
            //     "tax_1_charge": 5104,
            //     "tax1_description": "TVQ",
            //     "tax_2_charge": 0,
            //     "tax2_description": "",
            //     "total_charge": 51170,
            //     "refund_amount": 0,
            //     "tip_charge": 0,
            //     "total_tax": 7662,
            //     "total_tax_description": "",
            //     "total_charge_with_tax_with_tip_with_refund": 100000,
            //     "total_charge_with_tax_with_tip_with_refund_description": "total_charge_with_tax_description",
            //     "paid": true,
            //     "paid_date": "2024-06-26T13:36:08.320334",
            //     "pdf_blob_url": "invoices/8/2024-06-21/invoice.pdf",
            //     "csv_blob_url": "invoices/8/2024-06-21/invoice.csv",
            //     "excel_blob_url": "invoices/8/2024-06-21/invoice.xlsx",
            //     "from_date_inclusive": "2024-04-01T00:00:00",
            //     "to_date_inclusive": "2024-06-21T00:00:00",
            //     "payment_terms": 1,
            //     "payment_tax_zone": 0,
            //     "create_timestamp": "2024-06-21T16:36:27.029865",
            //     "communication_sent": false,
            //     "communication_sent_date": null,
            //     "recipient": "Fake XOF invoice",
            //     "currency": "XOF",
            //     "currency_exchange_rate": 445.9
            // }


            // const EUR_invoice = {
            //     "invoice_id": 667,
            //     "invoice_number": "FAKE-EUR",
            //     "invoice_type": 2,
            //     "invoice_date": "2024-06-21T00:00:00",
            //     "partner_id": 8,
            //     "tax_0_charge": 2558,
            //     "tax0_description": "TPS",
            //     "tax_1_charge": 5104,
            //     "tax1_description": "TVQ",
            //     "tax_2_charge": 0,
            //     "tax2_description": "",
            //     "total_charge": 51170,
            //     "refund_amount": 0,
            //     "tip_charge": 0,
            //     "total_tax": 7662,
            //     "total_tax_description": "",
            //     "total_charge_with_tax_with_tip_with_refund": 100000,
            //     "total_charge_with_tax_with_tip_with_refund_description": "total_charge_with_tax_description",
            //     "paid": true,
            //     "paid_date": "2024-06-26T13:36:08.320334",
            //     "pdf_blob_url": "invoices/8/2024-06-21/invoice.pdf",
            //     "csv_blob_url": "invoices/8/2024-06-21/invoice.csv",
            //     "excel_blob_url": "invoices/8/2024-06-21/invoice.xlsx",
            //     "from_date_inclusive": "2024-04-01T00:00:00",
            //     "to_date_inclusive": "2024-06-21T00:00:00",
            //     "payment_terms": 1,
            //     "payment_tax_zone": 0,
            //     "create_timestamp": "2024-06-21T16:36:27.029865",
            //     "communication_sent": false,
            //     "communication_sent_date": null,
            //     "recipient": "Fake EUR invoice",
            //     "currency": "EUR",
            //     "currency_exchange_rate": 0.68
            // }


            // const USD_invoice = {
            //     "invoice_id": 668,
            //     "invoice_number": "FAKE-USD",
            //     "invoice_type": 2,
            //     "invoice_date": "2024-06-21T00:00:00",
            //     "partner_id": 8,
            //     "tax_0_charge": 2558,
            //     "tax0_description": "TPS",
            //     "tax_1_charge": 5104,
            //     "tax1_description": "TVQ",
            //     "tax_2_charge": 0,
            //     "tax2_description": "",
            //     "total_charge": 51170,
            //     "refund_amount": 0,
            //     "tip_charge": 0,
            //     "total_tax": 7662,
            //     "total_tax_description": "",
            //     "total_charge_with_tax_with_tip_with_refund": 100000,
            //     "total_charge_with_tax_with_tip_with_refund_description": "total_charge_with_tax_description",
            //     "paid": true,
            //     "paid_date": "2024-06-26T13:36:08.320334",
            //     "pdf_blob_url": "invoices/8/2024-06-21/invoice.pdf",
            //     "csv_blob_url": "invoices/8/2024-06-21/invoice.csv",
            //     "excel_blob_url": "invoices/8/2024-06-21/invoice.xlsx",
            //     "from_date_inclusive": "2024-04-01T00:00:00",
            //     "to_date_inclusive": "2024-06-21T00:00:00",
            //     "payment_terms": 1,
            //     "payment_tax_zone": 0,
            //     "create_timestamp": "2024-06-21T16:36:27.029865",
            //     "communication_sent": false,
            //     "communication_sent_date": null,
            //     "recipient": "Fake USD invoice",
            //     "currency": "USD",
            //     "currency_exchange_rate": 0.733
            // }

            // invoices.push(XOF_invoice)
            // invoices.push(EUR_invoice)
            // invoices.push(USD_invoice)

            this.setState({ invoices });
        }).catch(error => {
            console.error(error)
            this.errorAlert({ message: "ERROR_ALERT" });
        });
    }

    errorAlert = (error) => {
        const { title, message } = error;
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title={i18n.t(title || "ERROR")}
                    onConfirm={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    <p><Trans>{i18n.t(message)}</Trans></p>
                </SweetAlert>
            )
        })
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    };

    togglePaid = async (invoice) => {
        await sendLoggedPUTAsync(`invoices/${invoice.invoice_id}`, { zone_id: this.props.zone_id, paid: !invoice.paid }, API_VERSION_1)
    }


    togglePaidConfirmationAlert = (invoice) => {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title={invoice.paid ? <Trans>Mark unpaid</Trans> : <Trans>Mark paid</Trans>}
                    onConfirm={async () => {
                        await this.togglePaid(invoice);
                        window.location.reload();
                    }}
                    confirmBtnBsStyle="success"
                    cancelBtnText={i18n.t("CANCEL")}
                    cancelBtnBsStyle="danger"
                    onCancel={() => this.hideAlert()}
                    showCancel
                >
                    <p>Invoice id: {invoice.invoice_id}</p>
                    <p><Trans>ARE_YOU_SURE</Trans></p>
                </SweetAlert>
            )
        });
    }

    download = (endpoint, filename) => {
        sendLoggedGETResponse("api/v1/ana/" + endpoint, "", false, { params: { zone_id: this.props.zone_id }, responseType: "blob" })
            .then(response => new Blob([response.data], { type: response.headers["Content-Type"] }))
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    }

    render() {
        const invoices = this.state.invoices;

        return (
            <div className="content">
                {this.state.alert}
                <Grid fluid>
                    <br />
                    <Row>
                        <Col md={12}>

                            <Card
                                plain
                                ctTableFullWidth
                                ctTableResponsive
                                defaultPageSize={15}
                                showPaginationTop
                                showPaginationBottom={false}
                                className="-highlight"
                                content={
                                    <ReactTable
                                        className="-payment-table"
                                        data={invoices}
                                        filterable
                                        columns={[
                                            {
                                                Header: <Trans>Invoice Number</Trans>,
                                                id: "invoice_number",
                                                accessor: (invoice) => {
                                                    return invoice.invoice_number;
                                                },
                                                filterable: true
                                            },
                                            {
                                                Header: <Trans>BUSINESS_NAME</Trans>,
                                                id: "rider",
                                                accessor: (invoice) => {
                                                    return <Link
                                                        // to={`/business/form/?name=${invoice.rider.account}`}
                                                        style={{ color: "#70CEEC", cursor: "pointer" }}
                                                    >
                                                        {invoice.recipient}
                                                    </Link>
                                                },
                                                filterable: true
                                            },
                                            {
                                                Header: <Trans>Paid</Trans>,
                                                id: "paid",
                                                accessor: (invoice) => {
                                                    let checkbox = <input type="checkbox"
                                                        checked={invoice.paid}
                                                        disabled={true}>
                                                    </input>

                                                    return checkbox;
                                                },
                                                sortable: true,
                                                filterable: false
                                            },
                                            {
                                                Header: <Trans>PAID_DATETIME</Trans>,
                                                id: "paid_date",
                                                accessor: (invoice) => {
                                                    return invoice.paid_date !== null ? utcStringToLocalDateString(invoice.paid_date) : null;
                                                },
                                                filterable: true
                                            },
                                            {
                                                Header: <Trans>Payment Type</Trans>,
                                                id: "invoice_type",
                                                accessor: (invoice) => {
                                                    return INVOICE_TYPES[invoice.invoice_type];
                                                },
                                                filterable: true
                                            },
                                            {
                                                Header: <Trans>Total</Trans>,
                                                id: "total_charge",
                                                accessor: (invoice) => {
                                                    return CurrencyFormatter.formatAmount(invoice.total_charge_with_tax_with_tip_with_refund, invoice.currency);
                                                },
                                                filterable: false
                                            },
                                            {
                                                Header: <Trans>Total CAD</Trans>,
                                                id: "total_charge_cad",
                                                accessor: (invoice) => {
                                                    return CurrencyFormatter.convertToCAD(invoice.total_charge_with_tax_with_tip_with_refund, invoice.currency, invoice.currency_exchange_rate);
                                                },
                                                filterable: false
                                            },
                                            {
                                                Header: <Trans>From_invoice</Trans>,
                                                id: "from_date_inclusive",
                                                accessor: (invoice) => {
                                                    return moment.utc(invoice.from_date_inclusive).format('YYYY-MM-DD');
                                                },
                                                filterable: true
                                            },
                                            {
                                                Header: <Trans>To_invoice</Trans>,
                                                id: "to_date_inclusive",
                                                accessor: (invoice) => {
                                                    return moment.utc(invoice.to_date_inclusive).format('YYYY-MM-DD');
                                                },
                                                filterable: true
                                            },
                                            {
                                                //TODO: When pdf upload is landed
                                                Header: <Trans>Files</Trans>,
                                                id: "select",
                                                accessor: (invoice) => {
                                                    //will download file in browser
                                                    return <span>
                                                        <a style={{ cursor: "pointer" }} onClick={() => { this.download(`invoices/${invoice.invoice_id}/csv`, `invoice-${invoice.invoice_number}.csv`) }}>csv </a>
                                                        <a style={{ cursor: "pointer" }} onClick={() => { this.download(`invoices/${invoice.invoice_id}/pdf`, `invoice-${invoice.invoice_number}.pdf`) }}>pdf </a>
                                                        <a style={{ cursor: "pointer" }} onClick={() => { this.download(`invoices/${invoice.invoice_id}/excel`, `invoice-${invoice.invoice_number}.xlsx`) }}>excel</a>
                                                    </span>;
                                                },
                                                filterable: false
                                            },
                                            {
                                                //Here, if AUTOMATIC && UNPAID -> Maybe add SendReminder option (to support to deal with it)
                                                Header: <Trans>actions</Trans>,
                                                id: "action",
                                                accessor: (invoice) => {
                                                    return (<div className="actions-right">
                                                        <Button
                                                            onClick={() => this.togglePaidConfirmationAlert(invoice)}
                                                            bsStyle={invoice.paid ? "danger" : "success"}
                                                            bsSize="xsmall">
                                                            {invoice.paid ? <Trans>Mark unpaid</Trans> : <Trans>Mark paid</Trans>}
                                                        </Button>
                                                    </div>)
                                                },
                                                sortable: false,
                                                filterable: false
                                            }
                                        ]}
                                    />

                                }
                            />

                        </Col>

                    </Row>
                </Grid>
                <p></p>
                <br></br>
            </div>
        );
    }
}



const mapStateToProps = state => {
    return {
        auth: state.userData.isAuthenticated,
        pubKey: state.userData.publicKey,
        privKey: state.userData.privateKey,
        community: state.userData.communityAccount,
        cityName: state.userData.cityName,
        zone_id: state.zones.selected.zone_id
    };
};

export default connect(mapStateToProps)(Invoices);