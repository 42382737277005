export default {
    VEHICLE_RIDE_SERVICES: "VEHICLE_RIDE_SERVICES",
    RIDE_SERVICE_VISIBILITY: "RIDE_SERVICE_VISIBILITY",
    EVA_BUSINESS: "EVA_BUSINESS",
    EVA_CLIENT: "EVA_CLIENT",
    PROMOTION_OPTIONS: "PROMOTION_OPTIONS",
    AUTOMATIC_SMS: "AUTOMATIC_SMS",
    CURRENCY: "CURRENCY",
    TRACKING_LINK_MESSAGE: "TRACKING_LINK_MESSAGE",
    MAPS: "MAPS",
    DISPATCHER: "DISPATCHER",
    IS_CASH_PAYMENT_ALLOWED: "IS_CASH_PAYMENT_ALLOWED"
}