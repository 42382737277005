import React, { useState, useEffect } from 'react';
import { getDriverName } from "../../../../../helpers/viewsHelper";
import {
    utcStringToLocalString,
    utcStringToLocalTimeString,
    convertMinutesToHoursString,
} from "../../../../../helpers/dateHelper";
import { Trans, useTranslation} from "react-i18next";
import "./RequestHistory.css"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { worldAccount } from '../../../../../variables/secretEnvVariables';

const Moment = require('moment')
const NO_VALUE = "---";
const AUTHORITY_TO_LETTER = {
    WORLD: "S",
    COMMUNITY: "C",
    RIDER: "R",
}
const EVENTS = {
    block_time: "request",
    accepted_block_time: "accepted",
    declined_block_time: "declined",
    onsite_block_time: "onsite",
    canceled_block_time: "canceled",
    started_block_time: "started",
    finished_block_time: "finished",
};

function RequestHistory(props) {
    const [ t, i18n ] = useTranslation(); // This hook make sure to re-render on translation changes
    const [driversData, setDriversData] = useState([]);
    const [logEvents, setLogEvents] = useState([]);

    useEffect(() => {
        // Double checking fetched data -> important to avoid some sorting mistakes that happenned while developping
        const sortedDriverData = props.history;
        sortedDriverData.sort((a, b) => new Moment(a.obj.block_time) - new Moment(b.obj.block_time));
        setDriversData(sortedDriverData);

        // Build log events
        props.history.forEach(driver => {
            for (const prop in driver.obj) {
                if (prop.includes("block_time") && driver.obj[prop] !== null) {
                    let estimatedPickupDistance, estimatedPickupTime;
                    if (prop === 'accepted_block_time') {
                        // Because right now those info are only available for when a driver accept a ride.
                        estimatedPickupDistance = driver.obj['estimated_pickup_distance'];
                        const pickupTimeInMinutes = driver.obj['estimated_pickup_time'];
                        estimatedPickupTime = pickupTimeInMinutes ? convertMinutesToHoursString(pickupTimeInMinutes) : NO_VALUE;
                    }

                    let eventName = EVENTS[prop];
                    const authority = driver.obj[`${eventName}_by`] || null //For example started_by
                    if (authority !== null) {
                        // Append a suffix to the name of the event if an external autority is involved
                        if (authority === worldAccount) { // Is this account the same for staging and prod?
                            eventName += "_by_system";
                        } else if (authority === props.communityAccount) {
                            eventName += "_by_community";
                        } else if (authority === props.allRequest.rider) {
                            eventName += "_by_rider";
                        }
                    }
                    const driverInfo = {
                        chainAccount: driver.obj.driver,
                        fullName: getDriverName(driver.profile),
                    };
                    logEvents.push({
                        name: eventName,
                        type: EVENTS[prop],
                        parentDatetime: driver.obj['block_time'],
                        datetime: driver.obj[prop],
                        driverInfo,
                        estimatedPickupDistance: estimatedPickupDistance || "",
                        estimatedPickupTime: estimatedPickupTime || ""
                    });
                }
            }
        });
        logEvents.sort((a, b) => new Moment(a.datetime) - new Moment(b.datetime));
        setLogEvents(logEvents);
    }, []);

    const formatTimeTableEntry = (timeToFormat, chainAccount = null) => {
        const timeString = timeToFormat ? utcStringToLocalTimeString(timeToFormat) : NO_VALUE;
        let contentToReturn = timeString;
        if (chainAccount !== null) {
            let autorityLetter = null;
            if (chainAccount === worldAccount) {
                autorityLetter = AUTHORITY_TO_LETTER.WORLD;
            } else if (chainAccount === props.communityAccount) {
                autorityLetter = AUTHORITY_TO_LETTER.COMMUNITY;
            } else if (chainAccount === props.allRequest.rider) {
                autorityLetter = AUTHORITY_TO_LETTER.RIDER;
            }

            if (autorityLetter !== null) {
                contentToReturn = (
                    <>
                        {timeString}&ensp;<AutorityIcon autorityLetter={autorityLetter} />
                    </>
                )
            }
        }
        return contentToReturn;
    };

    const formatDatetimeDisplay = (timeToFormat) => {
        return timeToFormat ? utcStringToLocalString(timeToFormat) : NO_VALUE;
    };

    const getLogText = (event) => {
        let { name, type, driverInfo, estimatedPickupDistance, estimatedPickupTime } = event;
        const driverLink = <Link onClick={(e) => e.stopPropagation() } to={"/driver_edit?name=" + driverInfo.chainAccount}>{driverInfo.fullName}</Link>;
        const LOG_LINE = {
            request: <>{i18n.t("HISTORY_LOG_REQUEST_SENT")} {driverLink}.</>,
            accepted: <>{driverLink} {i18n.t("HISTORY_LOG_REQUEST_ACCEPTED", { estimatedPickupDistance, estimatedPickupTime })}</>,
            declined: <>{driverLink} {i18n.t("HISTORY_LOG_REQUEST_DECLINED")}</>,
            declined_by_system: <>{i18n.t("HISTORY_LOG_REQUEST_DECLINED_BY_SYSTEM")} {driverLink}.</>,
            canceled: <>{driverLink} {i18n.t("HISTORY_LOG_REQUEST_CANCELLED")}</>,
            canceled_by_community: <>{i18n.t("HISTORY_LOG_REQUEST_CANCELLED_BY_COMMUNITY")}</>,
            canceled_by_rider: <>{i18n.t("HISTORY_LOG_REQUEST_CANCELLED_BY_RIDER")}</>,
            canceled_by_system: <>{i18n.t("HISTORY_LOG_REQUEST_CANCELLED_BY_SYSTEM")}</>,
            onsite: <>{driverLink} {i18n.t("HISTORY_LOG_REQUEST_ONSITE")} </>,
            started: <>{driverLink} {i18n.t("HISTORY_LOG_REQUEST_STARTED")} </>,
            started_by_community: <>{i18n.t("HISTORY_LOG_REQUEST_STARTED_BY_COMMUNITY")} {driverLink}.</>,
            finished: <>{driverLink} {i18n.t("HISTORY_LOG_REQUEST_FINISHED")} </>,
            finished_by_community: <>{i18n.t("HISTORY_LOG_REQUEST_FINISHED_BY_COMMUNITY")} {driverLink}.</>,
            // Note: finished_by_system is called everytime (even when the driver or the community end the ride) -> comes from the blockchain
            finished_by_system: <>{i18n.t("HISTORY_LOG_REQUEST_FINISHED_BY_SYSTEM")} {driverLink}.</>,
        }
        return LOG_LINE[name];
    };

    return (
        <div className="request-history">
            <div id="table-title">
                <h4><Trans>HISTORY_TABLE_TITLE</Trans></h4>
                <div className="legend-item">
                    <AutorityIcon autorityLetter={AUTHORITY_TO_LETTER.RIDER} />
                    <p><Trans>HISTORY_TABLE_LEGEND_PASSENGER</Trans></p>
                </div>
                <div className="legend-item">
                    <AutorityIcon autorityLetter={AUTHORITY_TO_LETTER.COMMUNITY} />
                    <p><Trans>HISTORY_TABLE_LEGEND_COMMUNITY</Trans></p>
                </div>
                <div className="legend-item">
                    <AutorityIcon autorityLetter={AUTHORITY_TO_LETTER.WORLD} />
                    <p><Trans>HISTORY_TABLE_LEGEND_SYSTEM</Trans></p>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th><Trans>HISTORY_TABLE_DRIVER</Trans></th>
                        <th className="right-separator"><Trans>HISTORY_TABLE_REQUEST_DATETIME</Trans></th>
                        <th><Trans>HISTORY_TABLE_ACCEPTANCE_DATETIME</Trans></th>
                        <th className="right-separator"><Trans>HISTORY_TABLE_REFUSAL_DATETIME</Trans></th>
                        <th><Trans>HISTORY_TABLE_ONSITE_DATETIME</Trans></th>
                        <th><Trans>HISTORY_TABLE_START_DATETIME</Trans></th>
                        <th><Trans>HISTORY_TABLE_CANCELLATION_DATETIME</Trans></th>
                        <th><Trans>HISTORY_TABLE_FINISH_DATETIME</Trans></th>
                    </tr>
                </thead>
                <tbody>
                    {driversData.map((driver, index) => {
                        const profile = driver.profile;
                        const request = driver.obj;

                        return (
                            <tr key={index}>
                                <td><Link onClick={(e) => e.stopPropagation()} target="_blank" to={"/driver_edit?name=" + request.driver}>{getDriverName(profile)}</Link></td>
                                <td className="right-separator">{formatTimeTableEntry(request.block_time)}</td>
                                <td>{formatTimeTableEntry(request.accepted_block_time)}</td>
                                <td className="right-separator">{formatTimeTableEntry(request.declined_block_time, request.declined_by)}</td>
                                <td>{formatTimeTableEntry(request.onsite_block_time)}</td>
                                <td>{formatTimeTableEntry(request.started_block_time, request.started_by)}</td>
                                <td>{formatTimeTableEntry(request.canceled_block_time, request.canceled_by)}</td>
                                <td>{formatTimeTableEntry(request.finished_block_time, request.finished_by)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <h4><Trans>HISTORY_LOG_TITLE</Trans></h4>
            <div id="log">
                {logEvents.map((event, index) => {
                    const className = `log-line-${event.type}`;
                    return (
                        <p className={className} key={index}>
                            &#40;{formatDatetimeDisplay(event.datetime)}&#41;&emsp;
                            &nbsp;{getLogText(event)}
                        </p>
                    )
                })}
            </div>
        </div>
    );
}

function AutorityIcon(props) {
    const { autorityLetter } = props;

    return (
        <span className="authority-icon">{autorityLetter}</span>
    )
}

function mapStateToProps(state) {
    return {
        communityAccount: state.userData.communityAccount
    }
}

export default connect(mapStateToProps)(RequestHistory);