import React, { Component } from "react";
import {
  getRestaurants,
} from "../../helpers/apiHelper";

import TableAPI from "../Tables/TableAPI";
import { Trans } from "react-i18next";

/**
 * View meant to display the list of restaurants s
 */
export default class RestaurantList extends Component {
  constructor(props) {
    super(props);
  }

  onClickDetail = (restaurantId) => {
    window.open(`#/restaurant/form/?id=${restaurantId}`);
  }

  constructFormFromApi = async (data) => {
    const restaurantList = await getRestaurants(data);
    if (data.get_count) {
      return restaurantList;
    }
    let formRows = {};
    for (var index in restaurantList) {
      const row = await this.getFormRowFromRestaurantEntry(restaurantList[index]);
      formRows[index] = row;
    }
    return formRows;
  };

  getFormRowFromRestaurantEntry = async (entry) => {
    let row = {
      'id': entry.id,
      'restaurant_name': entry.name,
      'address': entry.address,
      'transactional_link': entry.transactional_link,
    }
    return row;
  };

  render() {
    return (
      <div className="content">
        <TableAPI
          fetchData={this.constructFormFromApi}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: (e) => {
                  // when clicking on a row, we get redirected to the form page to update the restaurant
                  // we don't redirect when the user clicks on the link though because this should redirect to link itself
                  if (e.target.tagName != 'A') {
                    this.onClickDetail(rowInfo.original.id)
                  }
                },
                style: {
                  cursor: "pointer"
                }
              }
            } else {
              return {}
            }
          }}
          columns={[
            {
              Header: <Trans>RESTAURANT_NAME</Trans>,
              canFilter: true,
              canSort: true,
              id: "restaurant_name",
              orderType: "",
              accessor: "restaurant_name",
              width: 140,
              Cell: (e) => {
                return <span>{e.value}</span>;
              },
            },
            {
              Header: "address",
              canFilter: true,
              canSort: true,
              id: "address",
              orderType: "",
              accessor: "address",
              width: 500,
              Cell: (e) => {
                return <span>{e.value}</span>;
              },
            },
            {
              Header: <Trans>RESTAURANT_LINK</Trans>,
              canSort: true,
              id: "transactional_link",
              orderType: "",
              accessor: "transactional_link",
              width: 140,
              Cell: (e) => {
                return <span><a target="_blank" href={e.value}>{e.value}</a></span>;
              },
            },
          ]}
          className="-highlight"
        />
      </div>
    );
  }
}

