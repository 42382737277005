import * as actionType from "../actions/action_types";

const initialState = {
    isOpen: true
};

const NavbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.UPDATE_SIDEBAR:
      return Object.assign({}, state, {
        isOpen: action.payload
      });
    default:
      return state;
  }
};

export default NavbarReducer;
