export default {
    "LATE": "LATE",
    "ORDER_DAMAGED": "ORDER_DAMAGED",
    "WRONG_DESTINATION": "WRONG_DESTINATION",
    "ORDER_NOT_DELIVERED": "ORDER_NOT_DELIVERED",
    "LATE_PICKUP": "LATE_PICKUP",
    "DANGEROUS_DRIVING": "DANGEROUS_DRIVING",
    "LOW_ACCEPTATION_RATE": "LOW_ACCEPTATION_RATE",
    "LOW_SUCCESS_RATE": "LOW_SUCCESS_RATE",
    "HIGH_CANCELLATION_RATE": "HIGH_CANCELLATION_RATE",
    "MISBEHAVIOUR": "MISBEHAVIOUR",
    "CUSTOM": "CUSTOM"
}