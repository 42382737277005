import "./EditRidePopUp.css"

import { ControlLabel, FormControl, FormGroup, HelpBlock } from "react-bootstrap";
import React, { Component } from "react";
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { isoToDateTime, utcStringToLocalString } from "../../../../helpers/dateHelper";

import CustomButton from "../../../../components/CustomButton/CustomButton";
import Datetime from "react-datetime";
import LocationInput from "../../../../components/LocationInput/LocationInput";
import Popup from "../../../../components/Popup/Popup";
import RideServiceSelection from "../../../../components/ButtonGroups/RideServiceSelection";
import SweetAlert from "react-bootstrap-sweetalert";
import { Trans } from "react-i18next";
import { editScheduledRide } from "../../../../helpers/apiHelper";
import { stripNonDigitCharacters } from "../../../../helpers/sanitizeHelper";
import moment from "moment";

export default class EditRidePopUp extends Component {

  state = {
    sweetAlert: null,
      show: this.props.show,
      trackingId: this.props.ride.tracking_id,
      fromAddress: this.props.ride.from_address,
      fromLat: this.props.ride.from_latitude,
      fromLon: this.props.ride.from_longitude,
      toAddress: this.props.ride.to_address,
      toLat: this.props.ride.to_latitude,
      toLon: this.props.ride.to_longitude,
      scheduledTime: isoToDateTime(utcStringToLocalString(this.props.ride.scheduled_datetime)),
      onSiteTime: this.props.ride.onsite_datetime,
      customerFirstName: this.props.ride.customer_first_name,
      customerLastName: this.props.ride.customer_last_name,
      customerPhone: this.props.ride.customer_phone,
      customerEmail: this.props.ride.customer_email,
      customerPhoneExt: "",
      price: this.props.ride.total_charges_plus_tax,
      tipAmount: this.props.ride.tip_token_amount || "0",
      note: this.props.ride.note,
      orderNumber: this.props.ride.order_number,
      rideServiceId: this.props.ride.ride_service_id,
      rideServiceTypeId: this.props.ride.ride_service_type_id,
      isError: false
    }

  async componentDidMount() {
    if (this.props.ride.customer_phone && this.props.ride.customer_phone.includes("ext.")) {
      const phoneArray = this.props.ride.customer_phone.split("ext. ");
      this.setState({ customerPhone: phoneArray[0], customerPhoneExt: phoneArray[1] });
    }
  }

  validateScheduledDatetime(value) {
    return value.isAfter(moment().subtract({ days: 1 }));
  }

  validateTipTokenAmount = (value) => {    
    this.setState({ tipAmount: stripNonDigitCharacters(value) });
  };

  locationChange = async (address) => {
    this.loadingHandlerAlert();
    await this.setLatLng(address)
      .then(() => this.setState({ alert: null }))
      .catch(e => console.log(e))
  }

  async setLatLng(address) {
    await geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        if (address == this.state.fromAddress)
          this.setState({
            fromLat: lat,
            fromLon: lng,
          });
        else if (address == this.state.toAddress)
          this.setState({
            toLat: lat,
            toLon: lng,
          });
      })
      .catch((error) => console.error('Error', error));
  }

  reloadPage() {
    window.location.reload(false);
  }

  loadingHandlerAlert = () => {
    this.setState({
      alert: <SweetAlert
        style={{ display: "block", zIndex: 10, border: "1px grey solid", left: "52.5%" }}
        title={<Trans>SENDING</Trans>}
        closeOnClickOutside={false}
        allowEscape={false}
        showCancel={false}
        showConfirm={false}
        hideOverlay={true}
      >
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
        <p>
          <Trans>SENDING_REQUEST</Trans>
        </p>
      </SweetAlert>
    });
  };

  showSuccessAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          hideOverlay={true}
          style={{ display: "block", zIndex: 10, border: "1px grey solid", left: "52.5%" }}
          title={<Trans>EDIT_CONFIRMATION</Trans>}
          onConfirm={() => this.reloadPage()}
          onCancel={() => this.reloadPage()}
          confirmBtnBsStyle="success"
        >
          <p>
            <Trans>ACTION_SUCCESS</Trans>
          </p>
        </SweetAlert>
      ),
    });
  };

  showErrorAlert = (error1,error2) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          hideOverlay="true"
          style={{ display: "block", zIndex: 10, border: "1px grey solid", left: "52.5%" }}
          title={<Trans>EDIT_ERROR</Trans>}
          onConfirm={() => this.setState({ alert: null })}
          confirmBtnBsStyle="info"
        >
          <p>
            <Trans>ERROR_ALERT</Trans> : <br></br>
            {error1}<br></br><br></br>{error2}
          </p>
        </SweetAlert>
      ),
    });
  };
  //End

  async editScheduledRide() {
    if (this.state.isError) return;

    const { customerPhone, customerPhoneExt } = this.state;
    const customerPhoneFull = customerPhoneExt ? `${customerPhone} ext. ${customerPhoneExt}` : customerPhone;
    this.loadingHandlerAlert();

    let body = {
      "from_address": this.state.fromAddress,
      "from_latitude": this.state.fromLat,
      "from_longitude": this.state.fromLon,
      "to_address": this.state.toAddress,
      "to_latitude": this.state.toLat,
      "to_longitude": this.state.toLon,
      "scheduled_datetime": this.state.scheduledTime,
      "customer_first_name": this.state.customerFirstName,
      "customer_last_name": this.state.customerLastName,
      "customer_phone": customerPhoneFull,
      "customer_email": this.state.customerEmail,
      "ride_service_type_id": parseInt(this.state.rideServiceTypeId),
      "tip_token_amount": parseInt(this.state.tipAmount),
      "note": this.state.note || "",
      "order_number": this.state.orderNumber || "",
    }

    if (!customerPhoneFull)
      delete body['customer_phone']

    const response = await editScheduledRide(this.state.trackingId, body);

    if (response.data && response.data.success) {
      this.showSuccessAlert();
    } else {
      let errors = '';
      if (response.response.data && response.response.data.errors) {
        Object.keys(response.response.data.errors).forEach(function(key) {
          //TODO - Replace key with translation version of field labels.
          if (errors.length > 0)
            //commented out full error due to REGEX response being too verbose for end users.
            errors += '  |  ' + key // + response.response.data.errors[key]
          else
            errors = key
        })
      }
      
      this.showErrorAlert(<Trans>ERROR_EDIT_SCHEDULED_RIDE</Trans>,errors);
    }
  }

  getStringValidationState(field) {
    if (!field) {
      return 'error';
    }
    const length = field.length;
    if (length === 0) {
      return 'error';
    }
    return null;
  }

  render() {
    return (
      <Popup show={this.state.show}>
        {this.state.alert}
        <div className="modal_content">
          <div style={{ width: "100%", display: "inline-block" }}>
            <div className="flexContainer" style={{ textAlign: "left" }}>

              <b>
                <Trans>SCHEDULED_DATE</Trans>
              </b>
              <div style={{ marginBottom: "25px", marginTop: "10px" }}>
                <Datetime
                  onChange={(date) => {
                    this.setState({ scheduledTime: date })
                  }}
                  isValidDate={(currentDate) =>
                    currentDate.isAfter(moment().subtract(1, "days"))
                  }
                  value={this.state.scheduledTime}
                  dateFormat="DD-MM-YYYY"
                  timeFormat="HH:mm:ss"
                  inputProps={{
                    style: { cursor: "pointer", padding: "20px", paddingLeft: "10px" }
                  }}
                />
              </div>
              <b><Trans>RIDE_SERVICE_TYPE</Trans></b>
              <RideServiceSelection
                onError={() => {
                  this.setState({ isError: true })
                }
                }
                onSuccess={(rideServiceId, rideServiceTypeId, price) => {
                  this.setState({ isError: false, rideServiceTypeId, price, rideServiceId })
                }}
                fromLat={this.state.fromLat}
                toLat={this.state.toLat}
                fromLon={this.state.fromLon}
                toLon={this.state.toLon}
                rideServiceId={this.state.rideServiceId}
                rideServiceTypeId={this.state.rideServiceTypeId} />

              <div style={{ textAlign: 'center' }}>
                <div style={{ marginTop: "30px", }}><h5 style={{ verticalAlign: "middle" }}><Trans>PRICE</Trans>: {this.state.price}</h5>
                </div>

                {this.state.isError ? <span style={{ color: "darkred", fontStyle: "bold" }}><Trans>EDIT_RIDE_ERROR</Trans></span> : null}
                <br />
              </div>

              <LocationInput className="formInput"
                label={<Trans>FROM_ACCOUNT</Trans>}
                onError={() => this.setState({ isError: true })}
                onSuccess={() => this.setState({ isError: false })}
                onChange={address => {
                  this.setState({ fromAddress: address });
                }}
                onSelect={address => this.locationChange(address)}
                value={this.state.fromAddress}></LocationInput>

              <LocationInput style={{ width: "100%" }}
                label={<Trans>TO_ACCOUNT</Trans>}
                onError={() => this.setState({ isError: true })}
                onSuccess={() => this.setState({ isError: false })}
                onChange={address => {
                  this.setState({ toAddress: address, isError: false });
                }}
                onSelect={(address) => this.locationChange(address)}
                value={this.state.toAddress}></LocationInput>
              <br />

              <form>
                <FormGroup
                  controlId="formBasicText"
                >
                  <ControlLabel><Trans>ORDER_NUMBER</Trans></ControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.orderNumber}
                    placeholder=""
                    onChange={e => {
                      this.setState({ orderNumber: e.target.value });
                    }}
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </form>

              <form>
                <FormGroup
                  controlId="formBasicText"
                  validationState={this.getStringValidationState(this.state.customerFirstName)}
                >
                  <ControlLabel><Trans>First Name</Trans></ControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.customerFirstName}
                    placeholder=""
                    onChange={e => {
                      this.setState({ customerFirstName: e.target.value });
                    }}
                  />
                  <FormControl.Feedback />
                  {this.state.customerFirstName ? null : <HelpBlock><Trans>INVALID_INPUT</Trans></HelpBlock>}
                </FormGroup>
              </form>

              <form>
                <FormGroup
                  controlId="formBasicText"
                  validationState={this.getStringValidationState(this.state.customerLastName)}
                >
                  <ControlLabel><Trans>Last Name</Trans></ControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.customerLastName}
                    placeholder=""
                    onChange={e => {
                      this.setState({ customerLastName: e.target.value });
                    }}
                  />
                  <FormControl.Feedback />
                  {this.state.customerLastName ? null : <HelpBlock><Trans>INVALID_INPUT</Trans></HelpBlock>}
                </FormGroup>
              </form>

              <form>
                <FormGroup
                  controlId="formBasicText"
                >
                  <ControlLabel><Trans>CUSTOMER_PHONE</Trans></ControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.customerPhone}
                    placeholder=""
                    onChange={e => {
                      this.setState({ customerPhone: e.target.value });
                    }}
                  />
                </FormGroup>
              </form>

              <form>
                <FormGroup
                  controlId="formBasicText"
                >
                  <ControlLabel><Trans>CUSTOMER_PHONE_EXTENSION</Trans></ControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.customerPhoneExt}
                    placeholder=""
                    onChange={e => {
                      this.setState({ customerPhoneExt: e.target.value });
                    }}
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </form>

              <form>
                <FormGroup
                  controlId="formBasicText"
                  validationState={this.getStringValidationState(this.state.customerEmail)}
                >
                  <ControlLabel><Trans>CUSTOMER_EMAIL</Trans></ControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.customerEmail}
                    placeholder="example@mail.com"
                    onChange={e => {
                      this.setState({ customerEmail: e.target.value });
                    }}
                  />
                  <FormControl.Feedback />
                  {this.state.customerEmail ? null : <HelpBlock><Trans>INVALID_INPUT</Trans></HelpBlock>}
                </FormGroup>
              </form>

              <form>
                <FormGroup
                  controlId="formBasicText"
                >
                  <ControlLabel><Trans>CUSTOMER_NOTE</Trans></ControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.note}
                    placeholder=""
                    onChange={e => {
                      this.setState({ note: e.target.value });
                    }}
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </form>

              <form>
                <FormGroup
                  controlId="formBasicText"
                  validationState={this.getStringValidationState(this.state.tipAmount)}
                >
                  <ControlLabel><Trans>TIP_AMOUNT</Trans></ControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.tipAmount}
                    placeholder=""
                    onChange={e => {
                      this.validateTipTokenAmount(e.target.value);
                    }}
                  />
                  <FormControl.Feedback />
                  {this.state.tipAmount ? null : <HelpBlock><Trans>INVALID_INPUT</Trans></HelpBlock>}
                </FormGroup>
              </form>

                    <div className='button-container'>
                  <CustomButton id="confirmButton"
                    disabled={this.state.isError || 
                                !this.state.customerFirstName ||
                                !this.state.customerLastName ||
                                !this.state.customerEmail ||
                                !this.state.tipAmount}
                    onClick={
                      () => this.editScheduledRide()
                    }> <Trans>EDIT_RIDE</Trans>
                  </CustomButton>

                <CustomButton id="cancelButtton"
                  onClick={() => this.props.cancel()}>
                  <Trans>CANCEL</Trans>
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    )
  }
}