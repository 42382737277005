import React, { Component } from "react";
import { Grid, Row, Col, Button } from "react-bootstrap";
import StatsCard from "../../../components/Card/StatsCard";
import Card from "../../../components/Card/Card.jsx";
import { convertToFiat, getSymbol, formatQuote } from '../../../helpers/paymentHelper';
import { addSequence, generateDeposit, issuePayment, getPendingPayments } from "../../../helpers/apiHelper";
import CurrencyFormat from 'react-currency-format';
import { CSVLink } from 'react-csv';
import ReactTable from "react-table";
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import { DateRangePicker } from 'react-dates';
import { Trans } from "react-i18next";
import i18n from '../../../i18n';
import moment from "moment";
import "./PendingCashout.css"

class PendingCashout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payments: [],
            selectedPayments: [],
            csvData: [],
            totalFiat: 0,
            totalToken: 0,
            error: '',
            alert: null,
            focusedInput: null,
            startDate: moment().subtract(1, 'month').startOf('day'),
            endDate: moment().startOf('day'),
            forceSequenceNumber: 0,
            forceSquenceMessage: null,
        };
        this.updateForceSequenceNumber = this.updateForceSequenceNumber.bind(this);
    }
    
    componentDidMount = () =>  {
        this.fetchPendingPayments();
    }

    fetchPendingPayments = async () => {
        //fetch data from api which returns an array of objects
        await getPendingPayments().then(payments => {
            payments.forEach(e => {
                const d = new Date(0);
                d.setUTCSeconds(e.requested_time);
                e['requested_time_utc_string'] = d.toISOString()
            });
            const totalFiat = payments.reduce((previousValue, currentValue) => previousValue + convertToFiat(currentValue.cashout_amount.replace(".0000 EVA", ""), currentValue.requested_time_utc_string, false), 0)
            const totalToken = payments.reduce((previousValue, currentValue) => previousValue + parseFloat(currentValue.cashout_amount.replace(".0000 EVA", "")), 0)
            this.setState({ payments, totalFiat, totalToken });
        }).catch(error => {
            this.errorAlert({ message: "ERROR_ALERT" });
        });
    }

    errorAlert = (error) => {
        const { title, message } = error;
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title={i18n.t(title || "ERROR")}
                    onConfirm={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    <p><Trans>{i18n.t(message)}</Trans></p>
                </SweetAlert>
            )
        })
    }

    successMessageAlert = async (success) => {
        const { title, message, data, href, reload } = success;
        const { sequence_number } = data;
        const strippedSequenceNumber = parseInt(sequence_number);

        const csvData = await this.generateCsvData();

        this.setState({
            alert: (<SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title={(i18n.t(title || "SUCCESS"))}
                onConfirm={() => {
                    this.hideAlert();
                    reload && window.location.reload();
                }}
                confirmBtnBsStyle="info"
            >
                <p>{(i18n.t(message || "SUCCESS"))}</p>
                <br></br>
                <Button download={`FCaisse_${strippedSequenceNumber}.txt`} href={(href || "")}>
                    <Trans>Generate_Deposit</Trans>
                </Button>
                <CSVLink
                    data={csvData}
                    filename={`Tableau_${strippedSequenceNumber}.csv`}>
                    <Button>
                        <Trans>Download CSV</Trans>
                    </Button>
                </CSVLink>
            </SweetAlert>)
        });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    };

    updateForceSequenceNumber(event) {
        if (!event.target.value.match("^[0-9]{1,3}$") && event.target.value !== "") {
            this.setState({
                forceSquenceMessage: i18n.t("Error_Force_Sequence"),
            });
            return;
        }
        this.setState({
            forceSquenceMessage: null,
            forceSequenceNumber: event.target.value,
        });
    }

    confirmIssuePayments = async () => {
        //No return wanted outside of if fuction.
        // eslint-disable-next-line
        const paymentList = this.getSelectedPayments();

        if (paymentList.length === 0) {
            this.errorAlert({ message: i18n.t("Error_More_Than_One_Payment") });
            return;
        }

        for (var i = 0; i < paymentList.length; i++) {
            if (paymentList[i].seq_id === 0) {
                this.errorAlert({ message: i18n.t("Error_No_Sequence_Payment") })
                return;
            }
        }

        this.setState({
            alert:
                <SweetAlert
                    warning
                    style={{
                        display: "block",
                        alignSelf: "center",
                        width: "60%",
                        marginTop: "-15%",
                        marginLeft: "-30%"
                    }}
                    title={i18n.t("Issuing_Payments_Confirmation")}
                    onConfirm={() => {
                        paymentList.forEach((el) => {
                            this.issuePayment(el.driver, el.seq_id);
                        });
                    }}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText={i18n.t("Issuing_Payments_Confirmation_Yes")}
                    cancelBtnText={i18n.t("CANCEL")}
                    cancelBtnBsStyle="danger"
                    showCancel
                >
                    <p style={{ fontSize: 30, color: "orange" }}><Trans>Issuing_Payments_Confirmation_Warning</Trans></p>

                    <div>{this.generateConfirmationTable(paymentList, false)}</div>
                </SweetAlert>
        })
    }

    confirmGenerateDeposit = async () => {
        // Has to have at least one selected payment
        if (this.state.selectedPayments.length === 0) {
            this.errorAlert({ message: i18n.t("Error_More_Than_One_Payment") });
            return;
        }
        // Get the payment info for the select drivers
        const selectedPayments = this.getSelectedPayments();
        // Each selected payment has to have a sequence ID
        if (selectedPayments.findIndex(payment => payment.seq_id === 0) > -1) {
            this.errorAlert({ message: i18n.t("Error_No_Sequence_Payment") });
            return;
        }
        // Every selected payment must have the same sequence id
        const sequence_number = selectedPayments[0].seq_id;
        for (const payment of selectedPayments) {
            if (payment.seq_id !== sequence_number) {
                this.errorAlert({ message: i18n.t("Error_Different_Sequence_Ids") });
                return;
            }
        }

        this.setState({
            alert:
                <SweetAlert
                    warning
                    style={{
                        display: "block",
                        alignSelf: "center",
                        width: "60%",
                        marginTop: "-15%",
                        marginLeft: "-30%"
                    }}
                    title={i18n.t("Generate_Deposit_Confirmation")}
                    onConfirm={() => this.onGenerateDeposit(selectedPayments, sequence_number)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={i18n.t("Generate_Deposit_Confirmation_Yes")}
                    cancelBtnText={i18n.t("CANCEL")}
                    showCancel
                >
                    <p style={{ fontSize: 30, color: "orange" }}>
                        <Trans values={{ sequence_number }}>
                            Generate_Deposit_Confirmation_Warning
                        </Trans>
                    </p>
                    <div>{this.generateConfirmationTable(selectedPayments, true)}</div>
                </SweetAlert>
        });
    }

    generateConfirmationTable = (payments, isGeneratePayment) => {
        return (<ReactTable
        previousText={i18n.t('PREVIOUS')} nextText={i18n.t('NEXT')} loadingText={i18n.t('LOADING')}
            data={payments}
            className="-highlight"
            style={{
                width: "100%",
                paddingLeft: "50px",
                paddingRight: "50px",
                height: "300px",
                fontSize: 14,
            }}
            filterable
            columns={[
                {
                    Header: <Trans>Eos Account</Trans>,
                    id: "account",
                    accessor: (payment) => {
                        return payment.driver;
                    }
                },
                {
                    Header: <Trans>Full Name</Trans>,
                    id: "fullName",
                    accessor: (payment) => {
                        return payment.full_name;
                    }
                },
                {
                    Header: <Trans>Requested Date</Trans>,
                    id: "requestedTime",
                    accessor: (payment) => {
                        return moment.utc(payment.requested_time * 1000).local().format('YYYY-MM-DD HH:mm:ss');
                    },
                    filterable: false
                },
                {
                    Header: getSymbol(),
                    id: getSymbol(),
                    accessor: (payment) => {
                        return convertToFiat(payment.cashout_amount.replace(".0000 EVA", ""), payment.requested_time_utc_string);
                    },
                    filterable: false
                },
                {
                    Header: <Trans>Sequence ID</Trans>,
                    id: "sequence",
                    accessor: (payment) => {
                        return (
                            <p style={{
                                color: (isGeneratePayment === true ?
                                    (payment.seq_id <= 0 ? "green" : "orange") :
                                    (payment.seq_id <= 0 ? "red" : "green"))
                            }}>
                                {payment.seq_id}
                            </p>)
                    },
                    filterable: false
                },
            ]}
            defaultPageSize={15}
        />)

    }

    issuePaymentConfirmationAlert = () => {
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={i18n.t("Payment_Confirmation")}
                    onConfirm={() => {
                        this.successMessageAlert();
                        window.location.reload();
                    }}
                    confirmBtnBsStyle="success"
                >
                    <p><Trans>Payment_Request_Success</Trans></p>
                </SweetAlert>
            )
        });
    }

    confirmPaymentAlert = (accountData, amount, sequence_id) => {
        if (sequence_id <= 0) {
            this.errorAlert({ message: i18n.t("Error_More_Than_One_Payment") });
        } else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={i18n.t("Payment_Confirmation")}
                        onConfirm={() => this.issuePayment(accountData, sequence_id)}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        confirmBtnText={i18n.t("Payment_Confirmation_Yes")}
                        cancelBtnText={i18n.t("CANCEL")}
                        showCancel
                    >
                        <p>
                            <Trans
                                i18nKey={"Payment_Confirmation_Warning"}
                                values={{ amount, driver: accountData }} />
                        </p>
                    </SweetAlert>
                )
            });
        }
    }

    onDateChange = ({ startDate, endDate }) => {
        this.setState({
            startDate: startDate, endDate: endDate,
            selectedPayments: [],
        });
    };

    filterDataSet = (paymentList) => {
        return paymentList.filter((el) => {
            const time = moment.utc(el['requested_time'] * 1000);
            return (time.isBetween(this.state.startDate, this.state.endDate) ||
                time.isSame(this.state.startDate, 'day') ||
                time.isSame(this.state.endDate, 'day'))
        });
    };

    onGenerateDeposit = async (paymentList, sequence_number) => {
        const depositObj = await generateDeposit(paymentList, sequence_number);
        if (!depositObj.result) {
            this.errorAlert({
                message: "Generate_Deposit_Error"
            })
            return;
        }
        const blob = new Blob([depositObj.result], { type: 'text/plain;charset=ANSI' });
        const ref = window.URL.createObjectURL(blob);

        this.successMessageAlert({
            message: "Generate_Deposit_Success",
            data: { drivers: depositObj.drivers, sequence_number: depositObj.sequence_number, payments: paymentList },
            href: ref,
            reload: true
        });
    }

    confirmGenerateSequence = async (payments) => {
        const drivers = [...this.state.selectedPayments];
        const selectedPayments = payments.filter((payment) => drivers.find(driver => driver === payment.driver));

        if (drivers.length === 0) {
            this.errorAlert({
                message: i18n.t("Error_More_Than_One_Payment")
            });
            return;
        }

        // Show an alert if a sequence is already generated for a selected payment
        const hasPaymentWithSequence = selectedPayments.findIndex(payment => payment.seq_id > 0);
        if (hasPaymentWithSequence > -1) {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Warning"
                        onConfirm={() => this.onGenerateSequence(payments, drivers)}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        confirmBtnText="Continue"
                        cancelBtnText="Cancel"
                        showCancel
                    >
                        <p><Trans>Payments_Have_Sequence_Warning</Trans></p>
                    </SweetAlert>
                )
            });
            return;
        }

        this.onGenerateSequence(payments, drivers);
    }

    onGenerateSequence = async (payments, drivers) => {
        const sequence_id = parseInt(this.state.forceSequenceNumber);

        const data = { payments, drivers, sequence_id };
        this.setState({
            alert:
                <SweetAlert
                    warning
                    style={{
                        display: "block",
                        alignSelf: "center",
                        width: "60%",
                        marginTop: "-15%",
                        marginLeft: "-30%"
                    }}
                    title={i18n.t("Generate_Deposit_Confirmation")}
                    onConfirm={() => this.generateSequence(data)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={i18n.t("Generate_Sequence")}
                    cancelBtnText={i18n.t("CANCEL")}
                    showCancel
                >
                    <p style={{ fontSize: 30, color: "orange" }}>
                        <Trans
                            i18nKey={"Generate_Sequence_Confirmation_Warning"}
                            values={{ sequence_id }} />
                    </p>
                    <div>{this.generateConfirmationTable(payments, true)}</div>
                </SweetAlert>
        })
    }

    generateSequence = async (data) => {
        addSequence(data).then(result => {
            this.onGenerateDeposit(data.payments, data.sequence_id).catch(error => {
                this.errorAlert({ message: "Generate_Deposit_Error" });
            })
        }).catch(error => {
            this.errorAlert({ message: "Generate_Sequence_Error" });
        })
    }

    issuePayment = async (driver, seq_id) => {
        issuePayment(driver, seq_id).then(result => {
            this.issuePaymentConfirmationAlert();
        }).catch(error => {
            this.errorAlert({ message: error });
        });
    }

    generateCsvData = async () => {
        const selectedPayments = this.getSelectedPayments();
        let driverBankInfo = {};

        for (const payment of selectedPayments) {
            driverBankInfo[payment.driver] = {
                financial_institution_number: "" + payment.financial_institution_number,
                account_number: "" + payment.driver,
                transit_number: "" + payment.transit_number,
            };
        }

        let dataSet = [];

        const csvHeaders = ["EOS Account", "Full Name", "Community", "Requested Time", "EVA Tokens", getSymbol(), "Institution", "Transit number", "Account number"];
        const translatedHeaders = csvHeaders.map(value => i18n.t(value));
        dataSet.push(translatedHeaders);
        let totalToken = 0;
        let totalFiat = 0;
        for (let i = 0; i < selectedPayments.length; i++) {
            const row = selectedPayments[i];
            dataSet.push([
                row.driver,
                row.full_name,
                this.props.community,
                row.requested_time,
                row.cashout_amount.replace('.0000 EVA', '.00 EVA'),
                convertToFiat(row.cashout_amount.replace('.0000 EVA', ''), row.requested_time_utc_string),
                row.financial_institution_number,
                row.transit_number,
                row.account_number
            ]);
            totalToken += parseFloat(row.cashout_amount);
            totalFiat += convertToFiat(row.cashout_amount.replace('.0000 EVA', ''), row.requested_time_utc_string, false);
            
        }
        dataSet.push(['Total', '', '', '', totalToken + " EVA", formatQuote(totalFiat*100), "", "", "", ""]);
        this.setState({
            csvData: dataSet
        })

        return dataSet;
    };

    handlePaymentCheck(event, driver) {
        let array = [...this.state.selectedPayments];
        if (event.target.checked && !array.includes(driver)) {
            array.push(driver);
        } else if (!event.target.checked && array.includes(driver)) {
            array.splice(array.indexOf(driver), 1);
        }
        this.setState({
            selectedPayments: array,
        })
    }

    selectAll(payments) {
        let array = [...this.state.selectedPayments];
        if (array.length !== payments.length) {
            array = payments.map((el) => {
                return el.driver;
            })
        } else {
            array = [];
        }
        this.setState({
            selectedPayments: array,
        })
    }

    getSelectedPayments() {
        const payments = this.state.payments;
        return this.state.selectedPayments.map(driver => {
            for (let i = 0; i < payments.length; i++) {
                if (driver === payments[i].driver) {
                    return payments[i];
                }
            }
        });
    }

    render() {
        const payments = this.state.payments;

        return (
            <div className="content">
                {this.state.alert}
                <Grid fluid>
                    <br />
                    <Col lg={4} sm={6}>
                        <StatsCard
                            bigIcon={<i className="pe-7s-wallet text-success" />}
                            statsText={"Total"}
                            statsValue={formatQuote(this.state.totalFiat * 100)}
                            evaTokenValue={<div><CurrencyFormat value={this.state.totalToken} displayType={'text'} thousandSeparator={true} prefix={'EVA '} /><br /><p style={{fontSize: "12px", color: "grey"}}>{i18n.t('CURRENCY_ESTIMATE_DISCLAIMER')}</p></div>}
                            statsIcon={<i className="fa fa-calendar-o" />}
                            statsIconText="Now"
                        />
                    </Col>
                    <Col md={3}>
                        <h6 className="title"><Trans>Date Range</Trans></h6>
                        <DateRangePicker
                            startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                            endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                            onDatesChange={this.onDateChange}//{({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
                            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                            displayFormat={() => "YYYY-MM-DD"}
                            isOutsideRange={() => false}
                        />
                    </Col>
                    <Col md={5}>
                        <p><Trans>Force_Sequence</Trans></p>
                        <input
                            type="text"
                            pattern="^[0-9]{0,3}$"
                            onChange={this.updateForceSequenceNumber}
                            value={this.state.forceSequenceNumber}
                            style={{ height: 30 }}/>
                        <Button 
                            onClick={() => this.confirmGenerateSequence(payments)}
                            style={{ marginTop: 0 }}
                        >
                            <Trans>Generate_Sequence</Trans></Button>
                        <p style={{ color: 'red' }}>{this.state.forceSquenceMessage}</p>
                        <Button onClick={() => {
                            this.confirmGenerateDeposit();
                        }}> <Trans>Generate_Deposit</Trans></Button>
                        <Button onClick={() => {
                            this.confirmIssuePayments(payments);
                        }}> <Trans>Issue All Selected</Trans></Button>
                        <Button onClick={() => {
                            this.selectAll(payments);
                        }}>
                            <Trans>Select All</Trans>
                        </Button>
                        <br />
                        <br />
                        <CSVLink
                            onClick={() => { this.generateCsvData() }}
                            data={this.state.csvData}
                            filename={`Tableau_${this.state.forceSequenceNumber}.csv`}>
                            <Trans>Download CSV</Trans>
                        </CSVLink>
                    </Col>
                    <Row>
                        <Col md={12}>

                            <Card
                                plain
                                ctTableFullWidth
                                ctTableResponsive
                                defaultPageSize={15}
                                showPaginationTop
                                showPaginationBottom={false}
                                className="-highlight"
                                content={
                                    <ReactTable
                                    previousText={i18n.t('PREVIOUS')} nextText={i18n.t('NEXT')} loadingText={i18n.t('LOADING')}
                                        className="-payment-table"
                                        data={payments}
                                        filterable
                                        columns={[
                                            {
                                                Header: <Trans>Eos Account</Trans>,
                                                id: "account",
                                                accessor: (payment) => {
                                                    return payment.driver;
                                                }
                                            },
                                            {
                                                Header: <Trans>Full Name</Trans>,
                                                id: "fullName",
                                                accessor: (payment) => {

                                                    if (payment) {
                                                        return payment.full_name;
                                                    }
                                                    return null;
                                                }
                                            },
                                            {
                                                Header: <Trans>Requested Date</Trans>,
                                                id: "requestedTime",
                                                accessor: (payment) => {
                                                    return moment.utc(payment.requested_time * 1000).local().format('YYYY-MM-DD HH:mm:ss');
                                                },
                                                filterable: false
                                            },
                                            {
                                                Header: <Trans>EVA Tokens</Trans>,
                                                id: "evaTokens",
                                                accessor: (payment) => {
                                                    return payment.cashout_amount.replace('.0000', '')
                                                },
                                                filterable: false
                                            },
                                            {
                                                Header: getSymbol(),
                                                id: getSymbol(),
                                                accessor: (payment) => {
                                                    return convertToFiat(payment.cashout_amount.replace('.0000 EVA', ''), payment.requested_time_utc_string);
                                                },
                                                filterable: false
                                            },
                                            {
                                                Header: <Trans>Sequence ID</Trans>,
                                                id: "sequence",
                                                accessor: (payment) => {
                                                    return payment.seq_id;
                                                },
                                                filterable: false
                                            },
                                            {
                                                Header: <Trans>select</Trans>,
                                                id: "select",
                                                accessor: (payment) => {
                                                    let checkbox = <input type="checkbox"
                                                        checked={this.state.selectedPayments.includes(payment.driver)}
                                                        onChange={(event) => this.handlePaymentCheck(event, payment.driver)}>
                                                    </input>
                                                    return checkbox;
                                                },
                                                filterable: false
                                            },
                                            {
                                                Header: <Trans>actions</Trans>,
                                                id: "action",
                                                accessor: (payment) => {
                                                    const driver = payment.driver;
                                                    let fiatConverstionAmount = convertToFiat(payment.cashout_amount.replace('.0000 EVA', ''), payment.requested_time_utc_string);
                                                    return <div className="actions-right">
                                                        <Button
                                                            onClick={() => this.confirmPaymentAlert(driver, fiatConverstionAmount, payment.seq_id)}
                                                            bsStyle="success"
                                                            bsSize="xsmall"
                                                            disabled={payment.seq_id === 0}>
                                                            Issue payment
                                                        </Button>
                                                    </div>
                                                },
                                                sortable: false,
                                                filterable: false
                                            }
                                        ]}
                                    />

                                }
                            />

                        </Col>

                    </Row>
                </Grid>
                <p></p>
                <br></br>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.userData.isAuthenticated,
        pubKey: state.userData.publicKey,
        privKey: state.userData.privateKey,
        community: state.userData.communityAccount,
        cityName: state.userData.cityName
    };
};

export default connect(mapStateToProps)(PendingCashout);