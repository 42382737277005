import { Col, FormGroup, Grid, Row } from "react-bootstrap";
import { DEBT_MEMO, TOKEN_TRANSFER_MEMO } from '../../const/MemoReasons';
import React, { Component } from "react";
import {
  blacklistRider,
  deleteRider,
  getDriver,
  getRider,
  newDebt,
  sendDiscount,
  sendTokens,
  verifyRider,
  whitelistRider,
  driverCashout,
  getBalance
} from "../../helpers/apiHelper";

import Card from "../../components/Card/Card.jsx";
import ChartistGraph from "react-chartist";
import { ControlLabel } from "react-bootstrap";
import CustomButton from "../../components/CustomButton/CustomButton";
import { FormInputs } from "../../components/FormInputs/FormInputs";
import I18n from "i18next";
import LoadingIcon from "../Components/LoadingIcon";
import RiderNotFoundError from './RiderNotFoundError'
import RiderRideRequestsTable from '../Tables/RiderRideRequestsTable';
import RiderTransactionsTable from "./RiderTransactionsTable";
import Select from 'react-select';
import StatsCard from "../../components/Card/StatsCard";
import StatsCardXS from "../../components/Card/StatsCardXS";
import SweetAlert from "react-bootstrap-sweetalert";
import { Trans } from "react-i18next";
import cancelIcon from "../../assets/img/cancel.svg";
import { communityAccount } from '../../variables/secretEnvVariables';
import { convertToFiat, convertToToken, formatQuote, getSymbol } from "../../helpers/paymentHelper";
import { getLoadingAlert } from "../../helpers/alertHelper";
import okIcon from "../../assets/img/ok.svg";
import queryString from "query-string";
import { responsiveSales } from "../../variables/Variables.jsx";
import { utcStringToLocalString } from "../../helpers/dateHelper";

var moment = require('moment-timezone');

const NUMBER_OF_WEEKS_IN_GRAPH = 20

class RiderDetails extends Component {
  state = {
    riderData: null,
    tokenPromotionSentAmount: "",
    tokenPromotionSentAmountFiat: "",
    tokenBalance: "",
    tokenBalanceFiat: "",
    riderValid: false,
    emailValid: false,
    accountName: null,
    amountTokenToSend: null,
    debtAmountToSend: null,
    cashoutAmountToSend: null,
    rideSuccessRate: null,
    amountToSendInFiat: null,
    amountDebtInFiat: null,
    amountToCashoutInFiat: parseFloat(0).toFixed(2),
    debtMemo: '',
    debtMemoLabel: '',
    debtMemoCustom: '',
    debtMemoIsOther: false,
    alert: null,
    viewChoice: "action",
    showView: false, //Hide view by default
    dataDidLoad: false,
    blacklisted: false,
    lastConnectionTime: "",
    actionLoading: false,
    transferMemoLabel : '',
    transferMemo : '',
    denySendTokens: true, //the default state is that the button unclickable
    riderNotFound: false,
    isCorporateUser: false,
    graphData: [],
    graphLabels: []
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.getRiderDetails();
      this.setState({ dataDidLoad: true });
    }
  }

  async componentDidMount() {
    await this.getRiderDetails();
    await this.getDriverInfo(); 
    this.prepareGraphData(); 
  }

  prepareGraphData = () => {
    let today = moment();
    let year = parseInt(moment().format('YYYY'));
    let week = parseInt(moment().format('W'));

    const graphData = [];
    const graphLabels = [];
    const {statistics} = this.state;

    let counter = 0;
    for (let i=0; i < NUMBER_OF_WEEKS_IN_GRAPH; i++) {
      today = today.subtract(7, 'd')
      graphLabels.push(today.format("MM/DD"))

      if (counter == statistics.length) {
        graphData.push(0);
        continue;
      }

      const entry = statistics[counter];
      if (entry.year === year && entry.week === week) {
        graphData.push(entry.count);
        counter++;
      } else {
        graphData.push(0);
      }
      
      if (week === 0) {
        year--;
      }
      week--;
    }
    this.setState({graphData: graphData.reverse(), graphLabels: graphLabels.reverse()});
  }

  getDriverInfo = async () => {
    const driverObj = await getDriver(this.state.accountName, true);
    this.setState({
      isDriver: (Object.values(driverObj).length !== 0 && driverObj.is_active)
    });
  }

  onDriverClick = () => {
    this.props.history.push({
      pathname: '/driver_edit',
      search: '?name=' + this.state.accountName,
      state: { name: this.state.accountName }
    })
  }

  async getRiderDetails() {
    const params = queryString.parse(this.props.location.search);
    const accountName = params.name;
    const riderData = await getRider(accountName);
    if (riderData.length === 0) {
      this.setState({ riderNotFound: true, accountName });
      return;
    }

    await this.setState({ riderData });
    this.updateBalance(accountName);
    let ride_requests_length =
      riderData.ride_requests_count > 0 ? riderData.ride_requests_count : 1;
    let lastConnectionTime =
      riderData.last_connection != null
        ? riderData.last_connection.block_time
        : "";
    let rideSuccessRate =
      parseInt((riderData.number_rides / ride_requests_length) * 100) + " %";
    const email = this.state.riderData.profile.email;
    let emailValid = email !== null && email !== "";
    let blacklisted = riderData.is_blacklisted ? true : false;
    const tokenPromotionSentAmount = riderData.amount_sent_promotion;
    const tokenPromotionSentAmountFiat = convertToFiat(tokenPromotionSentAmount);
    const isCorporateUser = riderData.corporate;

    this.setState({
      riderValid: true,
      emailValid,
      accountName,
      rideSuccessRate,
      blacklisted,
      lastConnectionTime,
      tokenPromotionSentAmount,
      tokenPromotionSentAmountFiat,
      isCorporateUser,
      statistics: riderData.statistics
    });
  }

  updateBalance = async accountName => {
    const tokenBalance = await getBalance(accountName);
    let tokenBalanceFiat = convertToFiat(tokenBalance.toString().split(' ')[0]);
    this.setState({ tokenBalance, tokenBalanceFiat });
  };

  sendDiscountAlert = accountName => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={<Trans>ARE_YOU_SURE</Trans>}
          onConfirm={() => this.onConfirmSendDiscount()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={(!this.state.actionLoading) ? <Trans>YES_DO_IT</Trans> : <Trans>ALERT_LOADING</Trans>}
          cancelBtnText={<Trans>CANCEL</Trans>}
          showCancel
        >
          <p>
            <Trans>SEND_SHARED_DISCOUNT_CONFIRM</Trans> {accountName} ?
          </p>
        </SweetAlert>
      )
    });
  };

  blacklistAlert = accountName => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={<Trans>ARE_YOU_SURE</Trans>}
          onConfirm={() => this.onConfirmBlacklist()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={(!this.state.actionLoading) ? <Trans>YES_DO_IT</Trans> : <Trans>ALERT_LOADING</Trans>}
          cancelBtnText={<Trans>CANCEL</Trans>}
          showCancel
        >
          <p>
            <Trans>SEND_BLACKLIST_CONFIRM</Trans> {accountName} ?
          </p>
        </SweetAlert>
      )
    });
  };

  whitelistAlert = accountName => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={<Trans>ARE_YOU_SURE</Trans>}
          onConfirm={() => this.onConfirmWhitelist()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={(!this.state.actionLoading) ? <Trans>YES_DO_IT</Trans> : <Trans>ALERT_LOADING</Trans>}
          cancelBtnText={<Trans>CANCEL</Trans>}
          showCancel
        >
          <p>
            <Trans>SEND_WHITELIST_CONFIRM</Trans> {accountName} ?
          </p>
        </SweetAlert>
      )
    });
  };

  verifyAlert = accountName => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={<Trans>ARE_YOU_SURE</Trans>}
          onConfirm={() => this.onConfirmVerify()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={(!this.state.actionLoading) ? <Trans>YES_DO_IT</Trans> : <Trans>ALERT_LOADING</Trans>}
          cancelBtnText={<Trans>CANCEL</Trans>}
          showCancel
        >
          <p>
            <Trans>SEND_VERIFY_CONFIRM</Trans> {accountName} ?
          </p>
        </SweetAlert>
      )
    });
  };

  deleteAlert = accountName => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={<Trans>ARE_YOU_SURE</Trans>}
          onConfirm={() => this.onConfirmDelete()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={(!this.state.actionLoading) ? <Trans>YES_DO_IT</Trans> : <Trans>ALERT_LOADING</Trans>}
          cancelBtnText={<Trans>CANCEL</Trans>}
          showCancel
        >
          <p>
            <Trans>DELETE_RIDER_CONFIRM</Trans> {accountName} ?
          </p>
        </SweetAlert>
      )
    });
  };

  sendTransferAlert = () => {
    if ((this.state.amountTokenToSend !== null) && (this.state.transferMemoLabel !== undefined)) {
      if (!this.state.actionLoading) {
        //confirm one last tim with user that he is about to send tokens
        this.setState({
          alert: (
            <SweetAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title={<Trans>ARE_YOU_SURE</Trans>}
              onConfirm={() => this.onConfirmTransfer()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="info"
              cancelBtnBsStyle="danger"
              confirmBtnText={<Trans>YES_DO_IT</Trans>}
              cancelBtnText={<Trans>CANCEL</Trans>}
              showCancel
            >
              <p>
                <Trans>SEND_TOKEN_CONFIRM</Trans> {this.state.amountTokenToSend} EVA
            </p>
            </SweetAlert>
          )
        });
      } else {
        //process the token transfering
        this.setState({
          alert: (
            <SweetAlert
              style={{ display: "block", marginTop: "-100px" }}
              title={<Trans>SENDING</Trans>}
              closeOnClickOutside={false}
              allowEscape={false}
              showCancel={false}
              showConfirm={false}
            >
              <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div>
              <p>
                <Trans>SENDING_TOKEN_USER</Trans> {this.state.amountTokenToSend} EVA
          </p>
            </SweetAlert>
          )
        });
      }

    } else {
      //missing info such as debt memo or amount of token to transfer  
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title={<Trans>CANNOT_SEND</Trans>}
            onCancel={() => this.hideAlert()}
            cancelBtnBsStyle="danger"
            cancelBtnText={<Trans>OK</Trans>}
            showCancel={true}
            showConfirm={false}
          >
            <p>
              <Trans>MISSING_INFO_TOKEN</Trans>
            </p>
          </SweetAlert>
        )
      });
    }
  };


  sendDebtAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={<Trans>ARE_YOU_SURE</Trans>}
          onConfirm={() => this.onConfirmNewDebt()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={(!this.state.actionLoading) ? <Trans>YES_DO_IT</Trans> : <Trans>ALERT_LOADING</Trans>}
          cancelBtnText={<Trans>CANCEL</Trans>}
          showCancel
        >
          <p>
            <Trans>NEW_DEBT_TOKEN_CONFIRM</Trans> {this.state.amountDebtToSend}{" "}
            EVA
          </p>
        </SweetAlert>
      )
    });
  };

  sendDriverCashoutAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={<Trans>ARE_YOU_SURE</Trans>}
          onConfirm={() => this.onConfirmDriverCashout()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={(!this.state.actionLoading) ? <Trans>YES_DO_IT</Trans> : <Trans>ALERT_LOADING</Trans>}
          cancelBtnText={<Trans>CANCEL</Trans>}
          showCancel
        >
          <p>
            <Trans>DRIVER_CASHOUT_TOKEN_CONFIRM</Trans> {this.state.amountToCashoutInFiat}
            EVA
          </p>
        </SweetAlert>
      )
    });
  };

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  onConfirmSendDiscount = async () => {
    await this.setState({ actionLoading: true });
    this.hideAlert();
    const response = await sendDiscount(this.state.accountName);
    if (response.success) {
      this.updateBalance(this.state.accountName);
      this.hideAlert();
      this.confirmActionSuccess("SHARED_DISCOUNT_SUCCESSFULLY_SENT");
    } else {
      this.errorHandlerAlert(response.message);
    }
    this.setState({ actionLoading: false });
  };

  onConfirmBlacklist = async () => {
    await this.setState({ actionLoading: true });
    this.hideAlert();
    const response = await blacklistRider(this.state.accountName);
    if (response.success) {
      this.hideAlert();
      this.confirmActionSuccess("BLACKLIST_RIDER_SUCCESSFULL");
    } else {
      this.errorHandlerAlert(response.message);
    }
    this.setState({ actionLoading: false });
  }

  onConfirmWhitelist = async () => {
    await this.setState({ actionLoading: true });
    this.hideAlert();
    const response = await whitelistRider(this.state.accountName);
    if (response.success) {
      this.hideAlert();
      this.confirmActionSuccess("WHITELIST_RIDER_SUCCESSFULL");
    } else {
      this.errorHandlerAlert(response.message);
    }
    this.setState({ actionLoading: false });
  }

  onConfirmVerify = async () => {
    await this.setState({ actionLoading: true });
    this.hideAlert();
    const response = await verifyRider(this.state.accountName);
    if (response.success) {
      this.hideAlert();
      this.confirmActionSuccess("VERIFY_RIDER_SUCCESSFULL");
    } else {
      this.errorHandlerAlert(response.message);
    }
    this.setState({ actionLoading: false });
  }

  onConfirmDelete = async () => {
    await this.setState({ actionLoading: true });
    this.hideAlert();
    const response = await deleteRider(this.state.accountName);
    if (response.success) {
      this.hideAlert();
      this.confirmActionSuccess("DELETE_RIDER_SUCCESSFULL");
    } else {
      this.errorHandlerAlert(response.message);
    }
    this.setState({ actionLoading: false });
  }

  onConfirmTransfer = async () => {
    if( this.state.transferMemo === "" || this.state.amountTokenToSend === 0){
      this.setState({denySendTokens : true})
    }else{
      this.setState({denySendTokens : false})
    }
    await this.setState({actionLoading: true});

    this.hideAlert();
    this.sendTransferAlert();
    let amountToken = parseInt(this.state.amountTokenToSend);
    let amountInString = amountToken.toFixed(4) + " EVA";
    const response = await sendTokens(this.state.accountName, amountInString, this.state.transferMemo);
    if (response.success) {
      this.updateBalance(this.state.accountName);
      this.hideAlert();
      this.confirmActionSuccess("TOKENS_SUCCESSFULLY_SENT");
    } else {
      this.errorHandlerAlert(response.message);
    }
    this.setState({ actionLoading: false });
  };

  onConfirmNewDebt = async () => {
    await this.setState({ actionLoading: true });
    this.hideAlert();
    this.sendDebtAlert();
    let amountTokenDebt = parseInt(this.state.debtAmountToSend);
    const response = await newDebt(
      this.state.accountName,
      amountTokenDebt,
      this.state.debtMemo
    );
    if (response.success) {
      this.updateBalance(this.state.accountName);
      this.hideAlert();
      this.confirmActionSuccess("DEBT_SUCCESFULLY_SENT");
    } else {
      this.errorHandlerAlert(response.message);
    }
    this.setState({ actionLoading: false });
  };

  onConfirmDriverCashout = async () => {
    await this.setState({ actionLoading: true });
    this.hideAlert();
    this.sendDriverCashoutAlert();
    let cashoutAmountToSend = parseInt(this.state.cashoutAmountToSend);
    const response = await driverCashout(
      this.state.accountName,
      cashoutAmountToSend
    );
    if (response.success) {
      this.updateBalance(this.state.accountName);
      this.hideAlert();
      this.confirmActionSuccess("DEBT_SUCCESFULLY_SENT");
    } else {
      this.errorHandlerAlert(response.message);
    }
    this.setState({ actionLoading: false });
  };

  errorHandlerAlert = message => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Error"
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          <p>
            <Trans>ERROR_ALERT</Trans> : {message}
          </p>
        </SweetAlert>
      )
    });
  };

  confirmActionSuccess = message => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Bravo!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          <p>
            <Trans>{message}</Trans>
          </p>
        </SweetAlert>
      )
    });
  };

  onTransferMemoChange = (option) => {
    if (typeof option === 'undefined' || option===null) return;
     
    if((option !=='') && (this.state.amountTokenToSend > 0)){
      this.setState({denySendTokens : false});
    }else{
      this.setState({denySendTokens : true});
    }

    if (typeof option === 'undefined' || option === null) return;
    const transferMemoLabel = option.value;
    const transferMemoIsOther = transferMemoLabel === 'OTHER';
    const transferMemo = `{"type": "T", "action": "community_transfer", "community": "${communityAccount}", "data": {"reason": "${option.value}"}}`;
    this.setState({ transferMemo, transferMemoLabel, transferMemoIsOther,});
  };

  onCustomTransferMemoChange = event => {
    if (event === null) return;
    const transferMemo = `{"type": "T", "action": "community_transfer", "community": "${communityAccount}", "data": {"reason": "${event.target.value}"}}`;
    this.setState({ transferMemo });
  }

  onFiatAmountToSendChange = event => {
    if( this.state.transferMemo === "" || this.state.amountTokenToSend === 0){
      this.setState({denySendTokens : true})
    }else{
      this.setState({denySendTokens : false})
    }

    const amountToSendInFiat = event.target.value;
    if (amountToSendInFiat != null) {
      if (amountToSendInFiat >= 0) {
        this.setState({ amountTokenToSend: convertToToken(amountToSendInFiat, new Date(), false), amountToSendInFiat });
      }
    }
  };

  onFiatAmountToSendBlur = () => {
    this.setState({amountToSendInFiat: parseFloat(this.state.amountToSendInFiat).toFixed(2)})
  }

  onDebtMemoChange = (option) => {
    if (typeof option === 'undefined' || option === null) return;
    const debtMemoLabel = option.value;
    const debtMemoIsOther = debtMemoLabel === 'OTHER';
    const debtMemo = `{"type": "T", "action": "community_withdraw", "community": "${communityAccount}", "data": {"reason": "${option.value}"}}`;
    this.setState({ debtMemo, debtMemoLabel, debtMemoIsOther });
  };

  onCustomDebtMemoChange = event => {
    if (event === null) return;
    const debtMemo = `{"type": "T", "action": "community_withdraw", "community": "${communityAccount}", "data": {"reason": "${event.target.value}"}}`;
    this.setState({ debtMemo });
  }


  onDebtAmountChange = event => {
    const amountDebtInFiat = event.target.value;
    if (amountDebtInFiat != null) {
      if (amountDebtInFiat >= 0) {
        this.setState({ debtAmountToSend: convertToToken(amountDebtInFiat, new Date(), false), amountDebtInFiat });
      }
    }
  };

  onCashoutAmountChange = event => {
    const amountToCashoutInFiat = event.target.value;
    if (amountToCashoutInFiat != null) {
      if (amountToCashoutInFiat >= 0) {
        this.setState({ cashoutAmountToSend: convertToToken(amountToCashoutInFiat, new Date(), false), amountToCashoutInFiat });
      }
    }
  };

  onFiatDebtAmountBlur = () => {
    this.setState({amountDebtInFiat: parseFloat(this.state.amountDebtInFiat).toFixed(2)})
  }

  onFiatCashoutAmountBlur = () => {
    this.setState({amountToCashoutInFiat: parseFloat(this.state.amountToCashoutInFiat).toFixed(2)})
  }

  showHideView = (view) => {
    if (!this.state.riderValid) return;

    if (view === "requestTable" && this.state.riderData.ride_requests_count === 0) return;

    if (this.state.showView) {
      if (this.state.viewChoice === view) {
        this.setState({ showView: false });
      } else {
        this.setState({ viewChoice: view });
      }
    } else {
      this.setState({
        viewChoice: view,
        showView: true
      });
    }
  }

  showBlacklistedText = () => {
    if (this.state.blacklisted) {
      return (<div style={{ textAlign: "center" }} className="alert alert-danger" role="alert">
        <h3><i style={{ margin: "10px" }} className="fa fa-exclamation-triangle"></i> <Trans>RIDER_BLACKLISTED</Trans></h3>
      </div>)
    }
  }

  render() {
    if (this.state.riderValid) {
      let pictureColumn = (
	<div style={{ position: "relative", textAlign: "center", margin: "0 auto", height: "22vh", width: "22vh", marginBottom: "4vh" }}>
      <div style={{position: "absolute", height: "22vh", width: "2.5vw", cursor: "pointer"}} 
      onClick={() => {
        this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={(this.state.riderData.is_verified) ? I18n.t('RIDER_VERIFIED') : I18n.t('RIDER_NOT_VERIFIED')}
          onConfirm={() => this.setState({alert: null})}
          confirmBtnBsStyle="info"
          confirmBtnText={<Trans>OK</Trans>}
        >
          <p>
          {(this.state.riderData.is_verified) ? I18n.t('RIDER_VERIFIED_TEXT') : I18n.t('RIDER_NOT_VERIFIED_TEXT')}
          </p>
        </SweetAlert>
      )});
      }
      }>
        <img src={(this.state.riderData.is_verified) ? okIcon : cancelIcon} style={{position: "absolute", bottom: "1.5vh", right: 0}} height="40px" width="40px" />
      </div>
          <img src={ `data:image/png;base64, ${this.state.riderData.image_data}` } alt="profile_avatar" style={{ height: "100%", width: "100%", border: "1px solid black", borderRadius: "50%", objectFit: "cover" }}/>
        </div>
      );

      let emailColumn = null;
      if (this.state.emailValid) {
        emailColumn = (
          <Col lg={3} sm={6}>
            <StatsCard
              bigIcon={<i className="fa fa-info text-primary" />}
              statsText={<Trans>RIDER_EMAIL</Trans>}
              statsValue={
                <span style={{ fontSize: 17 }}>
                  {this.state.riderData.profile.email}
                </span>
              }
            />
          </Col>
        );
      }
      let alert = this.state.alert;

      if (this.state.actionLoading) {
        alert = getLoadingAlert();
      }

      //Request Table
      let bigIconReqTable, iconTextReqTable, iconReqTable;
      if (this.state.showView && this.state.viewChoice === "requestTable") {
        bigIconReqTable = "fa fa-tachometer text-success";
        iconTextReqTable = (<Trans>HIDE_STAT_CARD_HINT</Trans>);
        iconReqTable = "fa fa-close";
      } else {
        bigIconReqTable = "fa fa-tachometer text-gray";
        iconTextReqTable = <Trans>RIDE_REQ_STAT_CARD_HINT</Trans>;
        iconReqTable = "fa fa-info";
      }

      let cardReqTable = (
        <div style={{ cursor: "pointer" }} onClick={(e) => this.showHideView("requestTable")}>
          <StatsCardXS
            bigIcon={<i className={bigIconReqTable} />}
            statsText={<Trans>RIDE_REQ_STAT_CARD_TITLE</Trans>}
            statsIcon={<i className={iconReqTable} />}
            statsIconText={iconTextReqTable}
          />
        </div>
      )

      //let viewRequests = (this.state.riderData !== null) ? (
      //  <RiderRideRequestsTable rideRequests={this.state.riderData.ride_requests} history={this.props.history} />
      //) : <div></div>;
      let viewRequests = null;

      let view;
      if (this.state.showView) {
        switch (this.state.viewChoice) {
          case "requestTable": view = viewRequests; break;
          default: return;
        }
      } else {
        view = <div></div>;
      }

      const divider = (
        <div
          style={{
            color: "# ",
            backgroundColor: "#999999",
            height: "2px",
            borderRadius: "100px",
            opacity: "0.5",
            marginLeft: "150px",
            marginRight: "150px",
            marginTop: "10px",
            marginBottom: "10px"
          }}
        />
      );

      const isDriverBanner = (this.state.isDriver) ?
        <div
          style={{
            margin: "10px",
            padding: "10px",
            backgroundColor: "#4a80f0",
            color: "white",
            textAlign: "center",
            borderRadius: "10px"
          }}>
          <b><Trans>RIDER_IS_DRIVER_MAIN</Trans></b>
          <i style={{ cursor: "pointer" }} onClick={() => this.onDriverClick()}><Trans>RIDER_IS_DRIVER_LINK</Trans></i>
        </div> : <div></div>;

      const isCorporateUserText = (this.state.isCorporateUser) ?
        <div 
          style={{ 
            fontSize: "large", 
            marginTop: "-2vh",
            marginBottom: "10px",
            textAlign: "center", 
          }}>
          <Trans>RIDER_IS_CORPORATE_USER</Trans>
        </div> : <div></div>;

      let blacklistWhiteListSection = null;

      if (this.state.blacklisted){
        blacklistWhiteListSection = <tr>
          <td>
            <Trans>RIDER_ACTION_WHITELIST</Trans>
          </td>
          <td></td><td></td><td></td><td></td>
          <td className="td-actions text-right">
            <CustomButton
              bsStyle="warning"
              pullRight
              type="submit"
              onClick={() => {
                this.whitelistAlert();
              }}
            >
              <Trans>
                RIDER_ACTION_SEND_SHRDISCOUNT_BUTTON
              </Trans>
            </CustomButton>
          </td>
        </tr>
      } else {
        blacklistWhiteListSection = <tr>
          <td>
            <Trans>RIDER_ACTION_BLACKLIST</Trans>
          </td>
          <td></td><td></td><td></td><td></td>
          <td className="td-actions text-right">
            <CustomButton
              bsStyle="warning"
              pullRight
              type="submit"
              onClick={() => {
                this.blacklistAlert();
              }}
            >
              <Trans>
                RIDER_ACTION_SEND_SHRDISCOUNT_BUTTON
              </Trans>
            </CustomButton>
          </td>
        </tr>
      }

      let verifyDriverSection = null; 
      
      if (!this.state.riderData.is_verified){
        verifyDriverSection = <tr>
          <td>
            <Trans>RIDER_ACTION_VERIFY</Trans>
          </td>
          <td></td><td></td><td></td><td></td>
          <td className="td-actions text-right">
            <CustomButton
              bsStyle="warning"
              pullRight
              type="submit"
              onClick={() => {
                this.verifyAlert();
              }}
            >
              <Trans>
                RIDER_ACTION_SEND_SHRDISCOUNT_BUTTON
              </Trans>
            </CustomButton>
          </td>
        </tr>
      }

      return (
        <div className="main-content" >
           {alert}
          <Grid fluid>


            <Row>
              <Col lg={3} sm={6}>
                <StatsCardXS
                  bigIcon={<i className="fa fa-user text-success" />}
                  statsText={<Trans>RIDER_NAME</Trans>}
                  statsValue=
                  {<span style={{ fontSize: 17 }}>
                    {this.state.riderData.profile.first_name + " " + this.state.riderData.profile.last_name}
                  </span>}
                />
              </Col>
              <Col lg={3} sm={6}>
                <StatsCardXS
                  bigIcon={<i className="fa fa-phone text-primary" />}
                  statsText={<Trans>RIDER_PHONE</Trans>}
                  statsValue={this.state.riderData.profile.phone}
                />
              </Col>
              <Col lg={3} sm={6}>
                <StatsCardXS
                  bigIcon={<i className="fa fa-folder" />}
                  statsText={<Trans>Rider ACCOUNT</Trans>}
                  statsValue=
                  {<span style={{ fontSize: 17 }}>
                    {this.state.accountName}
                  </span>}
                />
              </Col>
            <Col lg={3} sm={6}>
                <StatsCardXS
                  bigIcon={<i className="fa fa-calendar-check-o" />}
                  statsText={<Trans>CREATED_AT</Trans>}
                  statsValue=
                  {<span style={{ fontSize: 17 }}>
                    {utcStringToLocalString(this.state.riderData.created_block_time)}
                  </span>}
                />
              </Col>
              {emailColumn}
              <Col lg={3} sm={6}>
                <StatsCardXS
                  bigIcon={<i className="fa fa-money text-info" />}
                  statsText={<Trans>RIDER_BALANCE</Trans>}
                  statsValue={<span style={{ fontSize: 17 }}>
                    {this.state.tokenBalance + " ( " + this.state.tokenBalanceFiat + ")"}
                  </span>}
                />
              </Col>
            </Row>
            {divider}
            <Row>
              <Col lg={12} sm={12}>
                {view}
              </Col>
            </Row>

            <Row>
              <Col lg={12} sm={12}>
                <Card
                  title={<Trans>RIDER_ACTION_TITLE</Trans>}
                  content={
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>
                            <Trans>RIDER_ACTION_SEND_MONEY</Trans>
                          </td>
                          <td>
                            <FormInputs
                              ncols={["col-md-12"]}
                              proprieties={[
                                {
                                  label: I18n.t('RIDE_AMOUNT_FIAT_LABEL') + " (" + getSymbol() + ")",
                                  type: "number",
                                  bsClass: "form-control",
                                  onChange: this.onFiatAmountToSendChange.bind(
                                    this
                                  ),
                                  onBlur: () => this.onFiatAmountToSendBlur(),
                                  value: (this.state.amountToSendInFiat !== null) ? this.state.amountToSendInFiat : "0.00"
                                }
                              ]}
                            />
                          </td>
                          <td>
                            <div style={{ visibility: (this.state.amountTokenToSend) ? 'visible' : 'hidden' }}>
                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    label: <Trans>RIDE_AMOUNT_LABEL</Trans>,
                                    type: "text",
                                    bsClass: "form-control",
                                    value: parseInt(this.state.amountTokenToSend) + " EVA",
                                    disabled: true
                                  }
                                ]}
                              />
                            </div>
                          </td>
                          <td>
                            <FormGroup>
                              <ControlLabel><Trans>SEND_TRANSFER_MEMO_LABEL</Trans></ControlLabel>
                              <Select
                                id="select_transfer_memo_reason"
                                options={TOKEN_TRANSFER_MEMO}
                                onChange={event => this.onTransferMemoChange(event)}
                                value={this.state.transferMemoLabel}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <div style={{ visibility: (this.state.transferMemoIsOther) ? 'visible' : 'hidden' }}>
                              <FormInputs
                                ncols={["col-md-10"]}
                                proprieties={[
                                  {
                                    label: <Trans>MEMO_OTHER</Trans>,
                                    type: "text",
                                    bsClass: "form-control",
                                    onChange: this.onCustomTransferMemoChange.bind(this)
                                  }
                                ]}
                              />
                            </div>
                          </td>
                          
                          <td className="td-actions text-right">
                            <CustomButton
                              bsStyle="success"
                              pullRight
                              type="submit"
                              disabled={this.state.denySendTokens}
                              onClick={() => {
                                this.sendTransferAlert();
                              }}
                            >
                              <Trans>
                                RIDER_ACTION_SEND_SHRDISCOUNT_BUTTON
                              </Trans>
                            </CustomButton>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Trans>RIDER_ACTION_CREATE_DEBT</Trans>
                          </td>
                          <td>
                            <FormInputs
                              ncols={["col-md-12"]}
                              proprieties={[
                                {
                                  label:  I18n.t('RIDE_AMOUNT_FIAT_LABEL') + " (" + getSymbol() + ")",
                                  type: "number",
                                  bsClass: "form-control",
                                  onChange: this.onDebtAmountChange.bind(this),
                                  onBlur: () => this.onFiatDebtAmountBlur(),
                                  value: (this.state.amountDebtInFiat !== null) ? this.state.amountDebtInFiat : "0.00"
                                }
                              ]}
                            />
                          </td>
                          <td>
                            <div style={{ visibility: (this.state.debtAmountToSend) ? 'visible' : 'hidden' }}>
                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    label: <Trans>RIDE_AMOUNT_LABEL</Trans>,
                                    type: "text",
                                    bsClass: "form-control",
                                    value: this.state.debtAmountToSend + " EVA",
                                    disabled: true
                                  }
                                ]}
                              />
                            </div>
                          </td>
                          <td>
                            <FormGroup>
                              <ControlLabel><Trans>DEBT_MEMO_LABEL</Trans></ControlLabel>
                              <Select
                                id="select_memo_reason"
                                options={DEBT_MEMO}
                                onChange={(event) => this.onDebtMemoChange(event, 'DEBT')}
                                value={this.state.debtMemoLabel}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <div style={{ visibility: (this.state.debtMemoIsOther) ? 'visible' : 'hidden' }}>
                              <FormInputs
                                ncols={["col-md-10"]}
                                proprieties={[
                                  {
                                    label: <Trans>MEMO_OTHER</Trans>,
                                    type: "text",
                                    bsClass: "form-control",
                                    onChange: this.onCustomDebtMemoChange.bind(this)
                                  }
                                ]}
                              />
                            </div>
                          </td>
                          <td className="td-actions text-right">
                            <CustomButton
                              bsStyle="danger"
                              pullRight
                              type="submit"
                              onClick={() => {
                                this.sendDebtAlert();
                              }}
                            >
                              <Trans>
                                RIDER_ACTION_SEND_SHRDISCOUNT_BUTTON
                              </Trans>
                            </CustomButton>
                          </td>
                          <td className="td-actions text-right">
                          </td>
                        </tr>
                       {(this.state.isDriver) ?  (<tr>
                          <td>
                            <Trans>DRIVER_ACTION_CASHOUT</Trans>
                          </td>
                          <td>
                            <FormInputs
                              ncols={["col-md-12"]}
                              proprieties={[
                                {
                                  label:  I18n.t('RIDE_AMOUNT_FIAT_LABEL') + " (" + getSymbol() + ")",
                                  type: "number",
                                  bsClass: "form-control",
                                  onChange: this.onCashoutAmountChange.bind(this),
                                  onBlur: () => this.onFiatCashoutAmountBlur(),
                                  value: (this.state.amountToCashoutInFiat !== null) ? this.state.amountToCashoutInFiat : "0.00"
                                }
                              ]}
                            />
                          </td>
                          <td>
                            <div style={{ visibility: (this.state.cashoutAmountToSend) ? 'visible' : 'hidden' }}>
                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    label: <Trans>RIDE_AMOUNT_LABEL</Trans>,
                                    type: "text",
                                    bsClass: "form-control",
                                    value: this.state.cashoutAmountToSend + " EVA",
                                    disabled: true
                                  }
                                ]}
                              />
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                          <td className="td-actions text-right">
                            <CustomButton
                              bsStyle="success"
                              pullRight
                              type="submit"
                              onClick={() => {
                                this.sendDriverCashoutAlert();
                              }}
                              disabled={parseFloat(this.state.amountToCashoutInFiat) <= 0}
                            >
                              <Trans>
                                RIDER_ACTION_SEND_SHRDISCOUNT_BUTTON
                              </Trans>
                            </CustomButton>
                          </td>
                        </tr>): null}
                        
                      </tbody>
                    </table>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} sm={12}>
                <Card
                  title={<Trans>TRANSACTION_HISTORY</Trans>}
                  content={
                    <RiderTransactionsTable
                      account_name={this.state.accountName}
                      history={this.props.history}
                    ></RiderTransactionsTable>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      )
    } else {
      if (this.state.riderNotFound) {
        return <RiderNotFoundError
          accountName={this.state.accountName} />;
      }
      return <LoadingIcon />;
    }
  }
}

export default RiderDetails;
