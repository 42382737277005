import React from "react";
import {
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
} from "react-bootstrap";
import { connect } from 'react-redux';
import * as actionTypes from '../../actions/action_types';
import {store} from "../../store/index";
import {logout} from '../../actions';
import ZoneDropdown from "../ZoneDropdown/ZoneDropdown";



class HeaderLinks extends React.Component {


  logout = () => {
    this.props.onInputIsAuth();
    window.location.reload();
    store.dispatch(logout({}));
  };

  render() {

    const lang = this.props.currentLanguage;
    let displayLang;
    if (this.props.currentLanguage){
      displayLang = this.props.currentLanguage.Label;
    }

    return (   
      <div>
       {/*<Navbar.Form pullLeft className="navbar-search-form">
          <FormGroup>
            <InputGroup>
              <InputGroup.Addon>
                <i className="fa fa-search" />
              </InputGroup.Addon>
              <FormControl type="text" placeholder="Search..." />
            </InputGroup>
          </FormGroup>
    </Navbar.Form> */}

        <Nav pullRight>
        <ZoneDropdown/>
        <NavDropdown
          eventKey={2}
          title={
            <div>
              {displayLang}
              <p className="hidden-md hidden-lg">
                Language
                <b className="caret" />
              </p>
            </div>
          }
          noCaret
          id="basic-nav-dropdown-1"
        >
          <MenuItem onClick={()=> this.props.changeLanguage('fr')}>Français</MenuItem>
          <MenuItem onClick={()=> this.props.changeLanguage('en')}>English</MenuItem>
          <MenuItem onClick={()=> this.props.changeLanguage('es')}>Español</MenuItem>
        </NavDropdown>
          {/* <NavDropdown
            eventKey={3}
            title={
              <div>
                <i className="fa fa-bell-o" />
                <span className="notification">5</span>
                <p className="hidden-md hidden-lg">
                  Notifications
                  <b className="caret" />
                </p>
              </div>
            }
            noCaret
            id="basic-nav-dropdown-2"
          >
            <MenuItem eventKey={3.1}>Notification 1</MenuItem>
            <MenuItem eventKey={3.2}>Notification 2</MenuItem>
            <MenuItem eventKey={3.3}>Notification 3</MenuItem>
            <MenuItem eventKey={3.4}>Notification 4</MenuItem>
            <MenuItem eventKey={3.5}>Another notifications</MenuItem>
          </NavDropdown> */}
          <NavDropdown
            eventKey={1}
            title={
              <div>
                <i className="fa fa-list" />
                <p className="hidden-md hidden-lg">
                  More
                  <b className="caret" />
                </p>
              </div>
            }
            noCaret
            id="basic-nav-dropdown-3"
            bsClass="dropdown-with-icons dropdown"
          >
            <MenuItem eventKey={1.1} onClick={() => this.logout()}>
            {/*   <i className="pe-7s-mail" /> Messages
            {</MenuItem>
            <MenuItem eventKey={4.2}>
              <i className="pe-7s-help1" /> Help Center
            </MenuItem>
            <MenuItem eventKey={4.3}>
              <i className="pe-7s-tools" /> Settings
            </MenuItem>
            <MenuItem divider />
            <MenuItem eventKey={4.4}>
              <i className="pe-7s-lock" /> Lock Screen
            </MenuItem>
            <MenuItem eventKey={4.5}>} */}
              <div className="text-danger">
                <i className="pe-7s-close-circle" /> Log out
                
              </div>
            </MenuItem>
          </NavDropdown>
        </Nav>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInputIsAuth: () => dispatch({type:actionTypes.UPDATE_ISAUTHENTICATED, value:false})
  };
};

const mapStateToProps = state => {
  return {
    auth: state.userData.isAuthenticated,
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(HeaderLinks);

