// Use as a reference. Gonna append custom templates here too! 
export const formFieldsTemplate = {
    driver: {
        basic: [
            { name: "first_name", type: "text", required: true, placeholder: "123", validation_regex: "^[a-zA-ZÀ-ÿ -]{1,32}$", entity_scope: "driver", service_scope: [], vehicle_type_scope: [] },
            { name: "last_name", type: "text", required: true, placeholder: "Valjean", validation_regex: "^[a-zA-ZÀ-ÿ -]{1,32}$", entity_scope: "driver", service_scope: [], vehicle_type_scope: [] },
            { name: "email", type: "email", required: true, placeholder: "jean.valjean@eva.coop", validation_regex: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$", entity_scope: "driver", service_scope: [], vehicle_type_scope: [] },
            { name: "city", type: "text", required: true, placeholder: "Montréal", validation_regex: "^[a-zA-ZÀ-ÿ -]{1,32}$", entity_scope: "driver", service_scope: [], vehicle_type_scope: [] },
            { name: "date_of_birth", type: "date", required: true, placeholder: "1990-01-01", validation_regex: "^[0-9]{4}-[0-9]{2}-[0-9]{2}$", entity_scope: "driver", service_scope: [], vehicle_type_scope: [] },
        ]
    },
    vehicle: {
        basic: [
            { name: "reference_id", type: "hidden", required: false, placeholder: "", validation_regex: "", entity_scope: "vehicle", service_scope: [], vehicle_type_scope: [] },
            { name: "vehicle_type_id", type: "select", required: true, placeholder: "", options: [], validation_regex: "", entity_scope: "vehicle", service_scope: [], vehicle_type_scope: [] },
            { name: "allowed_ride_services", type: "checkbox", required: false, placeholder: "", options: [], validation_regex: "", entity_scope: "vehicle", service_scope: [], vehicle_type_scope: [] },
            { name: "color", type: "select", required: true, placeholder: "", options: ['green', 'red', 'blue', 'grey', 'black', 'indigo', 'orange', 'yellow', 'purple', 'white', 'brown', 'pink'], validation_regex: "^[a-zA-ZÀ-ÿ -]{1,32}$", entity_scope: "vehicle", service_scope: [], vehicle_type_scope: [] },
            { name: "brand", type: "text", required: true, placeholder: "Nissan", validation_regex: "^[a-zA-ZÀ-ÿ -]{1,32}$", entity_scope: "vehicle", service_scope: [], vehicle_type_scope: [] },
            { name: "model", type: "text", required: true, placeholder: "Sentra", validation_regex: "^(?=.{1,40}$)[a-zA-ZÀ-ÿ0-9]+(?:[-' ][a-zA-ZÀ-ÿ0-9]+)*$", entity_scope: "vehicle", service_scope: [], vehicle_type_scope: [] },
            { name: "year", type: "text", required: true, placeholder: "1990", validation_regex: "^(19[0-9]{2}|2[0-9]{3})$", entity_scope: "vehicle", service_scope: [], vehicle_type_scope: [] }, // Should be of type Number but is Text because of the data type in the DB.
            { name: "mileage", type: "number", required: true, placeholder: "10000", validation_regex: "^[0-9]{1,6}$", entity_scope: "vehicle", service_scope: [], vehicle_type_scope: [] },
            { name: "vehicle_identification_number", type: "text", required: true, placeholder: "XXXXXXXXXXXXXXXXX", validation_regex: "^[A-z0-9^iIoOqQsS]{17}$", entity_scope: "vehicle", service_scope: [], vehicle_type_scope: [] },
        ]
    },
};