import React, { Component } from "react";
import { Grid, Col, Row, Button } from "react-bootstrap";
import ChartistGraph from "react-chartist";
import Card from "../../components/Card/Card";
import StatsCard from "../../components/Card/StatsCard";
import { Trans } from "react-i18next";
import { connect } from 'react-redux';
import "./GraphStyle.css";
import { responsiveSales } from "../../variables/Variables.jsx";
import { localTimeZone } from '../../variables/secretEnvVariables';
import LoadingIcon from "../Components/LoadingIcon";
import SweetAlert from "react-bootstrap-sweetalert";
import { getDashboardData } from '../../helpers/apiHelper'
import { isUndefined } from '../../helpers/viewsHelper'

var moment = require('moment-timezone');

const monthLegend = [
  "fa fa-circle text-info",
  "fa fa-circle text-danger",
  "fa fa-circle text-warning",
  "fa fa-circle text-purple",
  "fa fa-circle text-green",
  "fa fa-circle text-blue",
  "fa fa-circle text-grey",
  "fa fa-circle text-orange", 
  "fa fa-circle text-darkblue",
  "fa fa-circle text-darkred"
];

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      dataDidLoad: false,
      numberOfMonthsMainGraph: 5,
      numberOfMonthsRidersGraph: 5,
    }
  }

  componentDidMount = async () => {
    if (!this.props.auth) {
      this.props.history.push('/');
    }
    const data = await getDashboardData();
    await this.setState({ data });

    this.retrieveLastTwelveMonths();
    this.calculateStatisticsCharts();
    this.calculateNumberOfRideGraph();
    this.calculateNewRidersGraph();
    this.loadSQDCData();
    this.setState({ dataDidLoad: true });
  }

  retrieveLastTwelveMonths = () => {
    const months = [];
    for (let i = 0; i < 12; i++) {
      let month = moment()
        .tz(localTimeZone)
        .subtract(i, 'months')
        .endOf('month');

      months.push(month);
    }
    this.setState({ lastTwelveMonths: months });
    this.setState({ selectedGraphsMonth: 0, selectedRiderGraphsMonth: 0 });
  }

  onAddMonthGraphClick = async (e) => {
    if (this.state.numberOfMonthsMainGraph === 10) return;
    await this.setState({ numberOfMonthsMainGraph: this.state.numberOfMonthsMainGraph + 1 });
    this.calculateNumberOfRideGraph();
  }

  onRemoveMonthGraphClick = async (e) => {
    if (this.state.numberOfMonthsMainGraph === 1) return;
    await this.setState({ numberOfMonthsMainGraph: this.state.numberOfMonthsMainGraph - 1 });
    this.calculateNumberOfRideGraph();
  }

  fillNumberOfRideData(startDate) {
    if (isUndefined(this.state.data)) return;

    const today = moment().tz(localTimeZone).format('YYYY-MM-DD');
    const rideSerie = [];

    const { ride_count_per_day } = this.state.data;

    const daysInMonth = startDate.daysInMonth();

    let total = 0;

    let date = startDate.format('YYYY-MM-DD');

    for (let i = 0; i < daysInMonth; i++) {
      total += (typeof ride_count_per_day[date] !== 'undefined' && ride_count_per_day[date]) ? ride_count_per_day[date] : 0;
      rideSerie.push(total);
      if (date === today) break;
      date = startDate.add(1, 'days').format('YYYY-MM-DD');
    }
    return rideSerie;
  }

  calculateNumberOfRideGraph = () => {
    let today = moment().tz(localTimeZone).subtract(this.state.selectedGraphsMonth, 'months').endOf('month');

    const numOfMonths = this.state.numberOfMonthsMainGraph;
    const series = [];

    for (let i = 0; i < numOfMonths; i++) {
      let startDate = moment(today).subtract(i, 'months').startOf('month');
      let endDate = moment(today).subtract(i, 'months').endOf('month');
      let serie = this.fillNumberOfRideData(startDate, endDate);
      series.push(serie);
    }

    var labels = [];
    for (let i = 1; i <= 31; i++) { //Max of 31 days per month
      labels.push(i);
    }

    var numberOfRidesData = {
      labels: labels,
      series: series
    };

    const numberOfRidesOptions = {
      showArea: false,
      height: "290px",
      axisX: {
        showGrid: false
      },
      lineSmooth: true,
      showLine: true,
      showPoint: true,
      fullWidth: true,
      chartPadding: {
        right: 50
      }
    };

    this.setState({ numberOfRidesData, numberOfRidesOptions });

  }

  calculateStatisticsCharts = () => {
    if (isUndefined(this.state.data)) return;

    const { current_month_status_chart, previous_month_status_chart } = this.state.data;

    const thisMonthPieChart = {
      labels: [
        `${((current_month_status_chart.success / current_month_status_chart.total) * 100).toFixed(0)}%`,
        `${((current_month_status_chart.canceled_by_driver / current_month_status_chart.total) * 100).toFixed(0)}%`,
        `${((current_month_status_chart.canceled_by_rider / current_month_status_chart.total) * 100).toFixed(0)}%`,
        `${((current_month_status_chart.canceled_by_community / current_month_status_chart.total) * 100).toFixed(0)}%`,
        `${((current_month_status_chart.no_driver / current_month_status_chart.total) * 100).toFixed(0)}%`
      ],
      series: [
        current_month_status_chart.success,
        current_month_status_chart.canceled_by_driver,
        current_month_status_chart.canceled_by_rider,
        current_month_status_chart.canceled_by_community,
        current_month_status_chart.no_driver
      ]
    }

    const lastMonthPieChart = {
      labels: [
        `${((previous_month_status_chart.success / previous_month_status_chart.total) * 100).toFixed(0)}%`,
        `${((previous_month_status_chart.canceled_by_driver / previous_month_status_chart.total) * 100).toFixed(0)}%`,
        `${((previous_month_status_chart.canceled_by_rider / previous_month_status_chart.total) * 100).toFixed(0)}%`,
        `${((previous_month_status_chart.canceled_by_community / previous_month_status_chart.total) * 100).toFixed(0)}%`,
        `${((previous_month_status_chart.no_driver / previous_month_status_chart.total) * 100).toFixed(0)}%`
      ],
      series: [
        previous_month_status_chart.success,
        previous_month_status_chart.canceled_by_driver,
        previous_month_status_chart.canceled_by_rider,
        previous_month_status_chart.canceled_by_community,
        previous_month_status_chart.no_driver
      ]
    }

    this.setState({ thisMonthPieChart, lastMonthPieChart })
  }

  onAddMonthRidersGraphClick = async (e) => {

    if (this.state.numberOfMonthsRidersGraph === 10) return;
    await this.setState({ numberOfMonthsRidersGraph: this.state.numberOfMonthsRidersGraph + 1 });
    this.calculateNewRidersGraph();

  }

  onRemoveMonthRidersGraphClick = async (e) => {

    if (this.state.numberOfMonthsRidersGraph === 1) return;
    await this.setState({ numberOfMonthsRidersGraph: this.state.numberOfMonthsRidersGraph - 1 });
    this.calculateNewRidersGraph();

  }

  fillNewRidersData(startDate) {
    if (isUndefined(this.state.data)) return;

    const today = moment().tz(localTimeZone).format('YYYY-MM-DD');
    const daysInMonth = startDate.daysInMonth();

    const { new_user_count_per_day } = this.state.data;

    const newRiders = [];
    let total = 0;
    let date = startDate.format('YYYY-MM-DD');

    for (let i = 0; i < daysInMonth; i++) {
      total += (typeof new_user_count_per_day[date] !== 'undefined' && new_user_count_per_day[date]) ? new_user_count_per_day[date] : 0;
      newRiders.push(total);
      if (date === today)
        break;
      date = startDate.add(1, 'days').format('YYYY-MM-DD');
    }

    return newRiders;

  }

  calculateNewRidersGraph = () => {

    let today = moment().tz(localTimeZone).subtract(this.state.selectedRiderGraphsMonth, 'months').endOf('month');
    const maxDaysPerMonth = 31;
    const numOfMonths = this.state.numberOfMonthsRidersGraph;
    const series = [];

    for (let i = 0; i < numOfMonths; i++) {
      let startDate = moment(today).subtract(i, 'months').startOf('month');
      let endDate = moment(today).subtract(i, 'months').endOf('month');
      let serie = this.fillNewRidersData(startDate, endDate);
      series.push(serie);
    }

    var labels = [];
    for (let i = 1; i <= maxDaysPerMonth; i++) {
      labels.push(i);
    }

    var newRidersData = {
      labels: labels,
      series: series
    }

    const newRidersOptions = {
      showArea: false,
      height: "290px",
      axisX: {
        showGrid: false
      },
      lineSmooth: true,
      showLine: true,
      showPoint: true,
      fullWidth: true,
      chartPadding: {
        right: 50
      }
    };

    this.setState({ newRidersData, newRidersOptions });
  }

  endpointErrorHandlerAlert = (error) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={<Trans>ERROR_ALERT</Trans>}
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          <p><Trans>TABLE_API_ERROR_1</Trans><br /><Trans>TABLE_API_ERROR_2</Trans></p>
        </SweetAlert >
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  loadSQDCData = () => {
    if (!this.state.data.sqdc) return;
    const sqdcData = this.state.data.sqdc;

    const sqdcChartData1 = {
      series: [
       sqdcData.target.success_count,
       sqdcData.target.cancel_count,
       sqdcData.target.return_count
      ],
      labels: [
        `${((sqdcData.target.success_count / sqdcData.target.deliveries_count) * 100).toFixed(0)}%`,
        `${((sqdcData.target.cancel_count/ sqdcData.target.deliveries_count) * 100).toFixed(0)}%`,
        `${((sqdcData.target.return_count/ sqdcData.target.deliveries_count) * 100).toFixed(0)}%`,
      ]
    };

    const sqdcChartData2 = {
      series: [
       sqdcData.target.on_time_deliveries_count,
       sqdcData.target.late_deliveries_count_b1,
       sqdcData.target.late_deliveries_count_b2,
       sqdcData.target.late_deliveries_count_b3,
       sqdcData.target.late_deliveries_count_b4
      ],
      labels: [
        `${((sqdcData.target.on_time_deliveries_count / sqdcData.target.deliveries_count) * 100).toFixed(0)}%`,
        `${((sqdcData.target.late_deliveries_count_b1 / sqdcData.target.deliveries_count) * 100).toFixed(0)}%`,
        `${((sqdcData.target.late_deliveries_count_b2 / sqdcData.target.deliveries_count) * 100).toFixed(0)}%`,
        `${((sqdcData.target.late_deliveries_count_b3 / sqdcData.target.deliveries_count) * 100).toFixed(0)}%`,
        `${((sqdcData.target.late_deliveries_count_b4 / sqdcData.target.deliveries_count) * 100).toFixed(0)}%`,
      ],
    };

    const sqdcChartData3 = {
      labels: [''],
    series: [
      { name: '', value: sqdcData.target.avg_driver_to_store_time },
      { name: '', value: sqdcData.target.avg_driver_at_store_time },
      { name: '', value: sqdcData.target.avg_driver_dispatch_time },
      { name: '', value: sqdcData.target.avg_driver_to_final_destination },
    ],
    };

    const sqdcChartData4 = {
      series: [
       sqdcData.target.invalid_address_count,
       sqdcData.target.invalid_customer_count
      ],
      labels: [
        `${((sqdcData.target.invalid_address_count / sqdcData.target.return_count) * 100).toFixed(0)}%`,
        `${((sqdcData.target.invalid_customer_count / sqdcData.target.return_count) * 100).toFixed(0)}%`,
      ],
    };

    const sqdcChartData5 = {
      series: [
       sqdcData.all_time.success_count,
       sqdcData.all_time.cancel_count,
       sqdcData.all_time.return_count
      ],
      labels: [
        `${((sqdcData.all_time.success_count / sqdcData.all_time.deliveries_count) * 100).toFixed(0)}%`,
        `${((sqdcData.all_time.cancel_count/ sqdcData.all_time.deliveries_count) * 100).toFixed(0)}%`,
        `${((sqdcData.all_time.return_count/ sqdcData.all_time.deliveries_count) * 100).toFixed(0)}%`,
      ]
    };

    const sqdcChartData6 = {
      series: [
       sqdcData.all_time.on_time_deliveries_count,
       sqdcData.all_time.late_deliveries_count_b1,
       sqdcData.all_time.late_deliveries_count_b2,
       sqdcData.all_time.late_deliveries_count_b3,
       sqdcData.all_time.late_deliveries_count_b4
      ],
      labels: [
        `${((sqdcData.all_time.on_time_deliveries_count / sqdcData.all_time.deliveries_count) * 100).toFixed(0)}%`,
        `${((sqdcData.all_time.late_deliveries_count_b1 / sqdcData.all_time.deliveries_count) * 100).toFixed(0)}%`,
        `${((sqdcData.all_time.late_deliveries_count_b2 / sqdcData.all_time.deliveries_count) * 100).toFixed(0)}%`,
        `${((sqdcData.all_time.late_deliveries_count_b3 / sqdcData.all_time.deliveries_count) * 100).toFixed(0)}%`,
        `${((sqdcData.all_time.late_deliveries_count_b4 / sqdcData.all_time.deliveries_count) * 100).toFixed(0)}%`,
      ],
    };

    const sqdcChartData7 = {
      labels: [''],
    series: [
      { name: '', value: sqdcData.all_time.avg_driver_to_store_time },
      { name: '', value: sqdcData.all_time.avg_driver_at_store_time },
      { name: '', value: sqdcData.all_time.avg_driver_dispatch_time },
      { name: '', value: sqdcData.all_time.avg_driver_to_final_destination },
    ],
    };

    const sqdcChartData8 = {
      series: [
       sqdcData.all_time.invalid_address_count,
       sqdcData.all_time.invalid_customer_count
      ],
      labels: [
        `${((sqdcData.all_time.invalid_address_count / sqdcData.all_time.return_count) * 100).toFixed(0)}%`,
        `${((sqdcData.all_time.invalid_customer_count / sqdcData.all_time.return_count) * 100).toFixed(0)}%`,
      ],
    };

    const sqdcChartData9 = {
      series: [
        Object.values(sqdcData.cumulative).map(e => (e.number_of_orders_on_time / e.number_of_orders)),
        Object.values(sqdcData.cumulative).map(e => (e.number_of_orders_late_b1 / e.number_of_orders)),
        Object.values(sqdcData.cumulative).map(e => (e.number_of_orders_late_b2 / e.number_of_orders)),
        Object.values(sqdcData.cumulative).map(e => (e.number_of_orders_late_b3 / e.number_of_orders)),
        Object.values(sqdcData.cumulative).map(e => (e.number_of_orders_late_b4 / e.number_of_orders)),
      ],
      labels: Object.keys(sqdcData.cumulative),
    };

    const sqdcChartData10 = {
      series: [
        Object.values(sqdcData.cumulative).map(e => (e.number_of_orders_success / e.number_of_orders)),
        Object.values(sqdcData.cumulative).map(e => (e.number_of_orders_return / e.number_of_orders)),
        Object.values(sqdcData.cumulative).map(e => (e.number_of_orders_canceled / e.number_of_orders)),
      ],
      labels: Object.keys(sqdcData.cumulative),
    };

    this.setState({sqdcChartData1, sqdcChartData2, sqdcChartData3, sqdcChartData4, sqdcChartData5, sqdcChartData6, sqdcChartData7, sqdcChartData8, sqdcChartData9, sqdcChartData10});
  }

  render() {
    if (this.state.dashboardError) {
      return <div>
        {this.state.alert}
      </div>
    }

    if (!this.state.dataDidLoad) {
      return <LoadingIcon />;
    }

    const thisMonthPieChartSeries = this.state.thisMonthPieChart.series;
    const lastMonthPieChartSeries = this.state.lastMonthPieChart.series;

    return (
      <div className="main-content">
        {this.state.alert}
        <Grid fluid>
          <Row>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-car text-success" />}
                statsText={<Trans>DASHBOARD_CURRENT_RIDES</Trans>}
                statsValue={this.state.data.active_rides_count}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText={<Trans>DASHBOARD_RIGHT_NOW</Trans>}
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-car text-info" />}
                statsText={<Trans>DASHBOARD_RIDES_TODAY_SUCCESSFUL</Trans>}
                statsValue={this.state.data.status_info_for_date.success}
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText={<Trans>DASHBOARD_TODAY</Trans>}
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-car text-danger" />}
                statsText={<Trans>DASHBOARD_RIDES_TODAY_FAILED</Trans>}
                statsValue={this.state.data.status_info_for_date.failed}
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText={<Trans>DASHBOARD_TODAY</Trans>}
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-car text-warning" />}
                statsText={<Trans>DASHBOARD_NUM_RIDES</Trans>}
                statsValue={this.state.data.completed_rides_count}
                statsIcon={< i className="fa fa-clock-o" />}
                statsIconText={<Trans>DASHBOARD_SINCE_BEG</Trans>}
              />
            </Col >
          </Row>
          <Row>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-id-card text-warning" />}
                statsText={<Trans>NEW_DRIVERS</Trans>}
                statsValue={this.state.data.drivers_activated_last_week_count}
                statsIcon={<i className="fa fa-clock-o" />}
                statsIconText={<Trans>DASHBOARD_LAST_WEEK</Trans>}
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-signal text-success" />}
                statsText={<Trans>MONTHLY_GROWTH</Trans>}
                statsValue={parseInt(this.state.data.monthly_growth) + " %"}
                statsIcon={<i className="fa fa-clock-o" />}
                statsIconText={<Trans>MONTHLY_GROWTH</Trans>}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div id="">
                <Card
                  title={<Trans>DASHBOARD_SUCC_RIDES_TITLE</Trans>}
                  content={
                    <ChartistGraph
                      data={this.state.numberOfRidesData}
                      type="Line"
                      options={this.state.numberOfRidesOptions}
                      responsiveOptions={responsiveSales}
                    />
                  }
                  legend={
                    <div>
                      {[].concat(this.state.lastTwelveMonths).map((month, i) => {
                        if (i < this.state.numberOfMonthsMainGraph) {
                          return <span key={i}>
                            <i className={monthLegend[i]} style={{ marginLeft: "30px" }} />
                            {this.state.lastTwelveMonths[i].format('M')}/{this.state.lastTwelveMonths[i].format('Y')}
                          </span>
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  }
                  stats={
                    <div>
                      <Button style={{ margin: "20px" }} onClick={e => this.onAddMonthGraphClick()}><Trans>ADD_MONTH</Trans></Button>
                      <Button style={{ margin: "20px" }} onClick={e => this.onRemoveMonthGraphClick()}><Trans>REMOVE_MONTH</Trans></Button>
                    </div>
                  }
                />
              </div>
            </Col>
          </Row >
          <Row>
            <Col md={6}>
              <Card
                title={<Trans>DASHBOARD_CURRENT_MONTH</Trans>}
                category=""
                content={<ChartistGraph data={this.state.thisMonthPieChart}
                  type="Pie" />}
                legend={
                  <div>
                    <i className="fa fa-circle text-info" /> <Trans>SUCCESS</Trans> ({thisMonthPieChartSeries[0]})<br />
                    <i className="fa fa-circle text-danger" /> <Trans>CANCELLED_BY_DRIVER</Trans> ({thisMonthPieChartSeries[1]})<br />
                    <i className="fa fa-circle text-warning" /> <Trans>CANCELLED_BY_RIDER</Trans> ({thisMonthPieChartSeries[2]})<br />
                    <i className="fa fa-circle text-purple" /> <Trans>CANCELLED_BY_COMMUNITY</Trans> ({thisMonthPieChartSeries[3]})<br />
                    <i className="fa fa-circle text-green" /> <Trans>NO_DRIVER</Trans> ({thisMonthPieChartSeries[4]})<br />
                  </div>
                }
                stats={
                  <div>
                    <i className="fa fa-clock-o" /> <Trans>DASHBOARD_CURRENT_STATUS</Trans>
                  </div>
                }
              />
            </Col>
            <Col md={6}>
              <Card
                title={<Trans>DASHBOARD_PREV_MONTH</Trans>}
                category=""
                content={< ChartistGraph data={this.state.lastMonthPieChart} type="Pie" />}
                legend={
                  < div >
                    <i className="fa fa-circle text-info" /> <Trans>SUCCESS</Trans> ({lastMonthPieChartSeries[0]}) < br />
                    <i className="fa fa-circle text-danger" /> <Trans>CANCELLED_BY_DRIVER</Trans> ({lastMonthPieChartSeries[1]}) < br />
                    <i className="fa fa-circle text-warning" /> <Trans>CANCELLED_BY_RIDER</Trans> ({lastMonthPieChartSeries[2]}) < br />
                    <i className="fa fa-circle text-purple" /> <Trans>CANCELLED_BY_COMMUNITY</Trans> ({lastMonthPieChartSeries[3]}) < br />
                    <i className="fa fa-circle text-green" /> <Trans>NO_DRIVER</Trans> ({lastMonthPieChartSeries[4]}) < br />
                  </div >
                }
                stats={
                  < div >
                    <i className="fa fa-clock-o" /><Trans>DASHBOARD_CURRENT_STATUS</Trans>
                  </div >
                }
              />
            </Col >
          </Row >
          <Row>
            <Col md={12}>
              <div id="">
                <Card
                  title={<Trans>DASHBOARD_NEW_USERS_TITLE</Trans>}
                  content={
                    <ChartistGraph
                      data={this.state.newRidersData}
                      type="Line"
                      options={this.state.newRidersOptions}
                      responsiveOptions={responsiveSales}
                    />
                  }
                  legend={
                    <div>
                      {[].concat(this.state.lastTwelveMonths).map((month, i) => {
                        if (i < this.state.numberOfMonthsRidersGraph) {
                          return <span key={i}>
                            <i className={monthLegend[i]} style={{ marginLeft: "30px" }} />
                            {this.state.lastTwelveMonths[i].format('M')}/{this.state.lastTwelveMonths[i].format('Y')}
                          </span>
                        }
                        else {
                          return null;
                        }
                      })}
                    </div>
                  }
                  stats={
                    <div>
                      <Button style={{ margin: "20px" }} onClick={e => this.onAddMonthRidersGraphClick()}><Trans>ADD_MONTH</Trans></Button>
                      <Button style={{ margin: "20px" }} onClick={e => this.onRemoveMonthRidersGraphClick()}><Trans>REMOVE_MONTH</Trans></Button>
                    </div>
                  }
                />
              </div>
            </Col>
          </Row>
          {(this.state.dataDidLoad && this.state.data.sqdc) && <div>
            <h3><Trans>SQDC_REPORT_TITLE</Trans></h3>
            <Grid fluid>
          <Row>
          <h4><Trans>SQDC_REPORT_SUBTITLE_1</Trans></h4>
            <Col lg={3} sm={6}>
            <Card
                title={<Trans>SQDC_REPORT_CHART_TITLE_1</Trans>}
                category=""
                content={<ChartistGraph data={this.state.sqdcChartData1}
                  type="Pie" />}
                legend={
                  <div>
                    <i className="fa fa-circle text-info" /> <Trans>SQDC_LEGEND_SUCCESS</Trans> ({this.state.sqdcChartData1.series[0]})<br />
                    <i className="fa fa-circle text-danger" /> <Trans>SQDC_LEGEND_CANCELED</Trans> ({this.state.sqdcChartData1.series[1]})<br />
                    <i className="fa fa-circle text-warning" /> <Trans>SQDC_LEGEND_RETURN</Trans> ({this.state.sqdcChartData1.series[2]})<br />
                  </div>
                }
                stats={
                  <div>
                    <i className="fa fa-clock-o" /> <Trans>SQDC_REPORT_SUBTITLE_1</Trans>
                  </div>
                }
              />
            </Col>
            <Col lg={3} sm={6}>
            <Card
                title={<Trans>SQDC_REPORT_CHART_TITLE_2</Trans>}
                category=""
                content={<ChartistGraph data={this.state.sqdcChartData2}
                  type="Pie" />}
                legend={
                  <div>
                    <i className="fa fa-circle text-info" /> <Trans>SQDC_LEGEND_ON_TIME</Trans> ({this.state.sqdcChartData2.series[0]})<br />
                    <i className="fa fa-circle text-danger" /> <Trans>SQDC_LEGEND_LATE_1</Trans> ({this.state.sqdcChartData2.series[1]})<br />
                    <i className="fa fa-circle text-warning" /> <Trans>SQDC_LEGEND_LATE_2</Trans> ({this.state.sqdcChartData2.series[2]})<br />
                    <i className="fa fa-circle text-purple" /> <Trans>SQDC_LEGEND_LATE_3</Trans> ({this.state.sqdcChartData2.series[3]})<br />
                    <i className="fa fa-circle text-green" /> <Trans>SQDC_LEGEND_LATE_4</Trans> ({this.state.sqdcChartData2.series[4]})<br />
                  </div>
                }
                stats={
                  <div>
                    <i className="fa fa-clock-o" /> <Trans>SQDC_REPORT_SUBTITLE_1</Trans>
                  </div>
                }
              />
            </Col>
            <Col lg={3} sm={6}>
            <Card
                title={<Trans>SQDC_REPORT_CHART_TITLE_3</Trans>}
                category=""
                content={<ChartistGraph data={{
                  labels: this.state.sqdcChartData3.labels,
                  series: this.state.sqdcChartData3.series.map((s) => [s.value]),
                }}
                  type="Bar" options={{
                    horizontalBars: true,
                    stackBars: true}} />}
                legend={
                  <div>
                    <i className="fa fa-circle text-info" /> <Trans>SQDC_LEGEND_BREAKDOWN_1</Trans> ({this.state.sqdcChartData3.series[0].value.toFixed(2)})<br />
                    <i className="fa fa-circle text-danger" /> <Trans>SQDC_LEGEND_BREAKDOWN_2</Trans> ({this.state.sqdcChartData3.series[1].value.toFixed(2)})<br />
                    <i className="fa fa-circle text-warning" /> <Trans>SQDC_LEGEND_BREAKDOWN_3</Trans> ({this.state.sqdcChartData3.series[2].value.toFixed(2)})<br />
                    <i className="fa fa-circle text-purple" /> <Trans>SQDC_LEGEND_BREAKDOWN_4</Trans> ({this.state.sqdcChartData3.series[3].value.toFixed(2)})<br />
                  </div>
                }
                stats={
                  <div>
                    <i className="fa fa-clock-o" /> <Trans>SQDC_REPORT_SUBTITLE_1</Trans>
                  </div>
                }
              />
            </Col>
            <Col lg={3} sm={6}>
            <Card
                title={<Trans>SQDC_REPORT_CHART_TITLE_4</Trans>}
                category=""
                content={<ChartistGraph data={this.state.sqdcChartData4}
                  type="Pie" />}
                legend={
                  <div>
                    <i className="fa fa-circle text-info" /> <Trans>SQDC_LEGEND_RETURN_1</Trans> ({this.state.sqdcChartData4.series[0]})<br />
                    <i className="fa fa-circle text-danger" /> <Trans>SQDC_LEGEND_RETURN_2</Trans> ({this.state.sqdcChartData4.series[1]})<br />
                   </div>
                }
                stats={
                  <div>
                    <i className="fa fa-clock-o" /> <Trans>SQDC_REPORT_SUBTITLE_1</Trans>
                  </div>
                }
              />
            </Col>
          </Row>
          <Row>
          <h4><Trans>SQDC_REPORT_SUBTITLE_2</Trans></h4>
            <Col lg={3} sm={6}>
            <Card
                title={<Trans>SQDC_REPORT_CHART_TITLE_1</Trans>}
                category=""
                content={<ChartistGraph data={this.state.sqdcChartData5}
                  type="Pie" />}
                legend={
                  <div>
                    <i className="fa fa-circle text-info" /> <Trans>SQDC_LEGEND_SUCCESS</Trans> ({this.state.sqdcChartData5.series[0]})<br />
                    <i className="fa fa-circle text-danger" /> <Trans>SQDC_LEGEND_CANCELED</Trans> ({this.state.sqdcChartData5.series[1]})<br />
                    <i className="fa fa-circle text-warning" /> <Trans>SQDC_LEGEND_RETURN</Trans> ({this.state.sqdcChartData5.series[2]})<br />
                  </div>
                }
                stats={
                  <div>
                    <i className="fa fa-clock-o" /> <Trans>SQDC_REPORT_SUBTITLE_2</Trans>
                  </div>
                }
              />
            </Col>
            <Col lg={3} sm={6}>
            <Card
                title={<Trans>SQDC_REPORT_CHART_TITLE_2</Trans>}
                category=""
                content={<ChartistGraph data={this.state.sqdcChartData6}
                  type="Pie" />}
                legend={
                  <div>
                    <i className="fa fa-circle text-info" /> <Trans>SQDC_LEGEND_ON_TIME</Trans> ({this.state.sqdcChartData6.series[0]})<br />
                    <i className="fa fa-circle text-danger" /> <Trans>SQDC_LEGEND_LATE_1</Trans> ({this.state.sqdcChartData6.series[1]})<br />
                    <i className="fa fa-circle text-warning" /> <Trans>SQDC_LEGEND_LATE_2</Trans> ({this.state.sqdcChartData6.series[2]})<br />
                    <i className="fa fa-circle text-purple" /> <Trans>SQDC_LEGEND_LATE_3</Trans> ({this.state.sqdcChartData6.series[3]})<br />
                    <i className="fa fa-circle text-green" /> <Trans>SQDC_LEGEND_LATE_4</Trans> ({this.state.sqdcChartData6.series[4]})<br />
                  </div>
                }
                stats={
                  <div>
                    <i className="fa fa-clock-o" /> <Trans>SQDC_REPORT_SUBTITLE_2</Trans>
                  </div>
                }
              />
            </Col>
            <Col lg={3} sm={6}>
            <Card
                title={<Trans>SQDC_REPORT_CHART_TITLE_3</Trans>}
                category=""
                content={<ChartistGraph data={{
                  labels: this.state.sqdcChartData7.labels,
                  series: this.state.sqdcChartData7.series.map((s) => [s.value]),
                }}
                  type="Bar" options={{
                    horizontalBars: true,
                    stackBars: true}} />}
                legend={
                  <div>
                    <i className="fa fa-circle text-info" /> <Trans>SQDC_LEGEND_BREAKDOWN_1</Trans> ({this.state.sqdcChartData7.series[0].value.toFixed(2)})<br />
                    <i className="fa fa-circle text-danger" /> <Trans>SQDC_LEGEND_BREAKDOWN_2</Trans> ({this.state.sqdcChartData7.series[1].value.toFixed(2)})<br />
                    <i className="fa fa-circle text-warning" /> <Trans>SQDC_LEGEND_BREAKDOWN_3</Trans> ({this.state.sqdcChartData7.series[2].value.toFixed(2)})<br />
                    <i className="fa fa-circle text-purple" /> <Trans>SQDC_LEGEND_BREAKDOWN_4</Trans> ({this.state.sqdcChartData7.series[3].value.toFixed(2)})<br />
                  </div>
                }
                stats={
                  <div>
                    <i className="fa fa-clock-o" /> <Trans>SQDC_REPORT_SUBTITLE_2</Trans>
                  </div>
                }
              />
            </Col>
            <Col lg={3} sm={6}>
            <Card
                title={<Trans>SQDC_REPORT_CHART_TITLE_4</Trans>}
                category=""
                content={<ChartistGraph data={this.state.sqdcChartData8}
                  type="Pie" />}
                legend={
                  <div>
                    <i className="fa fa-circle text-info" /> <Trans>SQDC_LEGEND_RETURN_1</Trans> ({this.state.sqdcChartData8.series[0]})<br />
                    <i className="fa fa-circle text-danger" /> <Trans>SQDC_LEGEND_RETURN_2</Trans> ({this.state.sqdcChartData8.series[1]})<br />
                   </div>
                }
                stats={
                  <div>
                    <i className="fa fa-clock-o" /> <Trans>SQDC_REPORT_SUBTITLE_2</Trans>
                  </div>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div id="">
                <Card
                  title={<Trans>SQDC_REPORT_SUBTITLE_3</Trans>}
                  content={
                    <ChartistGraph
                      data={this.state.sqdcChartData9}
                      type="Line"
                    />
                  }
                  legend={
                    <div>
                           <i className="fa fa-circle text-info" /> <Trans>SQDC_LEGEND_ON_TIME</Trans> <br />
                           <i className="fa fa-circle text-danger" /> <Trans>SQDC_LEGEND_LATE_1</Trans> <br />
                           <i className="fa fa-circle text-warning" /> <Trans>SQDC_LEGEND_LATE_2</Trans> <br />
                           <i className="fa fa-circle text-purple" /> <Trans>SQDC_LEGEND_LATE_3</Trans> <br />
                           <i className="fa fa-circle text-green" /> <Trans>SQDC_LEGEND_LATE_4</Trans> <br />
                    </div>
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div id="">
                <Card
                  title={<Trans>SQDC_REPORT_SUBTITLE_4</Trans>}
                  content={
                    <ChartistGraph
                      data={this.state.sqdcChartData10}
                      type="Line"
                    />
                  }
                  legend={
                    <div>
                           <i className="fa fa-circle text-info" /> <Trans>SQDC_LEGEND_SUCCESS</Trans> <br />
                           <i className="fa fa-circle text-danger" /> <Trans>SQDC_LEGEND_CANCELED</Trans> <br />
                           <i className="fa fa-circle text-warning" /> <Trans>SQDC_LEGEND_RETURN</Trans> <br />
  </div>
                  }
                />
              </div>
            </Col>
          </Row>
          </Grid>
          </div>}
        </Grid>
        <p></p>
        <br></br>
      </div>

    );
  }
}
const mapStateToProps = state => {
  return {
    auth: state.userData.isAuthenticated,
    pubKey: state.userData.publicKey,
    privKey: state.userData.privateKey,
    community: state.userData.communityAccount,
    cityName: state.userData.cityName,
    dashboardData: state.dashboard.data,
  };
};

export default connect(mapStateToProps)(Dashboard);
