import * as actionType from '../actions/action_types'

const initialState = {
    pendingPayments: {},
    completedPayments: {},
    paySequence: {},
}

/**
 * Main Navigation Reducer that create the next state bases on the action
 * {LOGGED_IN} - Update the keys, profile info and login status
 * {LOGGED_OUT} - Reset all state to null
 * @param {Object} state Current root state of the store
 * @param {Object} action LOGGED_IN, LOGGED_OUT
 */
export default function (state = initialState, action) {
    switch(action.type){
        case actionType.UPDATE_PENDING_PAYMENT_LIST:
            return Object.assign({}, state, {
                pendingPayments: action.payload.pendingPayments,
            });
        case actionType.UPDATE_COMPLETED_PAYMENT_LIST:
            return Object.assign({}, state, {
                completedPayments: action.payload.completedPayments,
            })
        case actionType.UPDATE_SEQUENCE:
            return Object.assign({},state,  {
                paySequence : action.payload.paySequence,
            })
        case actionType.LOGOUT:
            return Object.assign({}, initialState);
        default:
            return state; 
    }
}