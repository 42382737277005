import React, { useState, useEffect } from 'react';
import "./AnaTabs.css";
import TabContent from "./TabContent/TabContent";
import TabHead from "./TabHead/TabHead";

/**
 * This component will generate a container with tabs. EYou only need to pass one prop to it and it's content. In content, you need to have this
 * structure: 
 * 
 * [
 *     {id: 1, name: "name-of-your-first-tab", body: renderable-content-of-your-first-tab },
 *     {id: 2, name: "name-of-your-second-tab", body: renderable-content-of-your-second-tab },
 *     etc.
 * ]
 * 
 * As you can see, it's an array of objects. If you don't respect this exact structure, your tabs won't render. There is no limit of how many
 * tabs you can pass. The only limit is the width of the screen which is probably a lot more tabs than you need.
 * */

function AnaTabs(props) {
  const defaultValue = [{ id: 1, name: "Default", body: "This is some content" }];
  const removeInvalidEntries = () => {
    // Remove entries that miss a property to avoid a potential crash.
    if (props.content) {
      return props.content.filter(element => element.hasOwnProperty('id') && element.hasOwnProperty('name') && element.hasOwnProperty('body')) || defaultValue;
    }
  }
  let filteredContent = removeInvalidEntries() || defaultValue;
  let minId = Math.min(...filteredContent.map(element => element.id));
  const [currentId, setCurrentId] = useState(minId);

  useEffect(() => {
    filteredContent = removeInvalidEntries();
  }, [props.content]);

  const allTabs = () => {
    const tabsToRender = [];
    filteredContent.forEach((element, index) => {
      tabsToRender.push(<TabHead
        key={"tab" + index}
        id={element.id}
        name={element.name || "Missing name"}
        selected={element.id === currentId}
        onClick={id => setCurrentId(id)} />
      );
    })
    return tabsToRender;
  }

  const currentContent = filteredContent.find((e => e.id === currentId)).body;

  return (
    <div className="ana-tabs">
      <div className="heads-container">
        {allTabs()}
      </div>
      <div className="body-container">
        <TabContent content={currentContent} />
      </div>
    </div>
  );
}

export default AnaTabs;