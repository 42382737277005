export const UPDATE_ISAUTHENTICATED = "UPDATE_ISAUTHENTICATED";
export const UPDATE_TOKEN_TRANSFER_LIST = "UPDATE_TOKEN_TRANSFER_LIST";
export const LOGOUT = "LOGOUT";
export const UPDATE_PENDING_PAYMENT_LIST = "UPDATE_PENDING_PAYMENT_LIST";
export const UPDATE_COMPLETED_PAYMENT_LIST = "UPDATE_COMPLETED_PAYMENT_LIST";
export const ON_LOG_IN_SUCCESS = "ON_LOG_IN_SUCCESS";
export const UPDATE_COMMUNITY = "UPDATE_COMMUNITY";
export const UPDATE_DASHBOARD = "UPDATE_DASHBOARD";
export const UPDATE_SEQUENCE = "UPDATE_SEQUENCE";
export const UPDATE_SIDEBAR = "UPDATE_SIDEBAR";
export const UPDATE_EXCHANGE_RATES = "UPDATE_EXCHANGE_RATES";
export const UPDATE_SELECT_ZONE = "UPDATE_SELECT_ZONE";
export const LOAD_ZONES = "LOAD_ZONES";