import React, { useState } from 'react';
import "./TabContent.css";

function TabContent(props) {  
  return (
    <div className="ana-tab-content">
        {props.content}
    </div>
  );
}

export default TabContent;