import './DispatchRidePopUp.css'

import { ControlLabel, FormControl, FormGroup, HelpBlock } from "react-bootstrap";
import React, { Component } from "react";

import CustomButton from "../../../components/CustomButton/CustomButton";
import Popup from "../../../components/Popup/Popup";
import SweetAlert from "react-bootstrap-sweetalert";
import { Trans } from "react-i18next";
import i18n from 'i18next';
import { manualDispatchRide } from "../../../helpers/apiHelper";
import PhoneInput from 'react-phone-input-2';

/*
  We want the V2 of this to be done with a similar interface that the online rider list have.
  Once we have capacity in development, support would need to have a way to cancel and redispatch a ride
  in the same interface.
*/

export default class DispatchRidePopUp extends Component {

  state = {
    alert: null,
    isError: true,
    driverAccount: ""
  }

  componentDidUpdate(prevProps) {
    if (prevProps.route && this.props.route) {
      if (this.props.route.manual_dispatch_driver !== prevProps.route.manual_dispatch_driver) {
        this.setState({driverAccount: this.props.route.manual_dispatch_driver})
    }
    } else if (this.props.route) {
      if (this.props.route.manual_dispatch_driver) {
        this.setState({driverAccount: this.props.route.manual_dispatch_driver})
    }
    } else if (prevProps.route) {
      if (prevProps.route.manual_dispatch_driver) {
        this.setState({driverAccount: prevProps.route.manual_dispatch_driver})
    }
    } else if (this.state.driverAccount !== '') {
      this.setState({driverAccount: ''})
    }
  }

  reloadPage = () => {
    window.location.reload(false);
  }

  validateAccount = () => {
      this.setState({isError: this.state.driverAccount.length < 11 });
  }

  confirmDispatchAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          showCancel
          style={{ display: "block", marginTop: "-100px", border: "1px solid black"}}
          confirmBtnText={i18n.t("YES")}
          confirmBtnBsStyle="danger"
          title={i18n.t("ARE_YOU_SURE")}
          onConfirm={async () => {
            this.loadingHandlerAlert();
            console.log('this.props.route.driver_request_uuid', this.props.route.driver_request_uuid)
            console.log('this.state.driverAccount', this.state.driverAccount)
            const response = await manualDispatchRide(this.props.route.driver_request_uuid, this.state.driverAccount);
            if (response.status === 200) {
              if (response.data.success === true) {
                this.showSuccessAlert(i18n.t("MANUAL_DISPATCH_OK"));
              } else {
                this.showErrorAlert(i18n.t(response.data.message));
              }
            } else {
              this.showErrorAlert(response.response.data.message);
            }
          }}
          onCancel={() => { this.setState({ "alert": null }) }}
          focusCancelBtn
        >
          <p>{i18n.t("MANUAL_DISPATCH_CONFIRM")} {this.state.driverAccount}</p>
        </SweetAlert>)
    });
  }

  confirmResetDispatchAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          showCancel
          style={{ display: "block", marginTop: "-100px", border: "1px solid black"}}
          confirmBtnText={i18n.t("YES")}
          confirmBtnBsStyle="danger"
          title={i18n.t("ARE_YOU_SURE")}
          onConfirm={async () => {
            this.loadingHandlerAlert();
            const response = await manualDispatchRide(this.props.route.driver_request_uuid, null, true);
            if (response.status === 200) {
              if (response.data.success === true) {
                this.showSuccessAlert(i18n.t("MANUAL_DISPATCH_RESET_OK"));
            } else {
                this.showErrorAlert(i18n.t(response.data.message));
              }
            } else {
              this.showErrorAlert(response.response.data.message);
            }
          }}
          onCancel={() => { this.setState({ "alert": null }) }}
          focusCancelBtn
        >
          <p>{i18n.t("MANUAL_DISPATCH_RESET_CONFIRM")}</p>
        </SweetAlert>)
    });
  }

  loadingHandlerAlert = () => {
    this.setState({
      alert: <SweetAlert
      style={{ display: "block", marginTop: "-100px", border: "1px solid black"}}
        title={<Trans>SENDING</Trans>}
        closeOnClickOutside={false}
        allowEscape={false}
        showCancel={false}
        showConfirm={false}
        hideOverlay={true}
      >
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
        <p>
          <Trans>SENDING_REQUEST</Trans>
        </p>
      </SweetAlert>
    });
  };

  showSuccessAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          hideOverlay={true}
          style={{ display: "block", marginTop: "-100px", border: "1px solid black"}}
          title={<Trans>EDIT_CONFIRMATION</Trans>}
          onConfirm={() => this.reloadPage()}
          onCancel={() => this.props.cancel()}
          confirmBtnBsStyle="success"
        >
          <p>
            <Trans>ACTION_SUCCESS</Trans>
          </p>
        </SweetAlert>
      ),
    });
  };

  showErrorAlert = (error) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          hideOverlay="true"
          style={{ display: "block", marginTop: "-100px", border: "1px solid black"}}
          title={<Trans>EDIT_ERROR</Trans>}
          onConfirm={() => this.reloadPage()}
          onCancel={() => this.props.cancel()}
          confirmBtnBsStyle="info"
        >
          <p>
            <Trans>ERROR_ALERT</Trans> : <br></br>
            {error}{" "}
          </p>
        </SweetAlert>
      ),
    });
  };

  render() {
    return (
      <div className='manualDispatch'>
      {this.state.alert}
      <Popup show={this.props.show}>
        <div className="modal_content">

        <form>
          <FormGroup
            controlId="formBasicText"
          >
            <ControlLabel><Trans>PHONE NUMBER</Trans></ControlLabel>
            {/* <FormControl
              type="text"
              value={this.state.driverAccount}
              placeholder="14185405081"
              onChange={async e => {
                await this.setState({ driverAccount: e.target.value });
                this.validateAccount();
              }}
            /> */}
            <PhoneInput
              country={'ca'}
              masks={{"ci": '.. .. .. .. ..'}}
              value={this.state.driverAccount}
              onChange={async value => {
                console.log(value)
                await this.setState({ driverAccount: value });
                this.validateAccount();
              }}
              placeholder="Enter phone number"
            />
            <FormControl.Feedback />
                  {this.state.driverAccount.length < 11   ? <HelpBlock><Trans>INVALID_INPUT</Trans></HelpBlock> : null}
          </FormGroup>
        </form>

          <div className='button-container' style={{display:'flex', justifyContent:"space-around"}}>
            <CustomButton id="cancelButtton"
              onClick={() => this.props.cancel()}>
              <Trans>CANCEL</Trans>
            </CustomButton>

            <CustomButton id="confirmButton"
              disabled={this.state.isError}
              onClick={
                () => this.confirmDispatchAlert()
              }> <Trans>MANUAL_DISPATCH</Trans>
            </CustomButton>
          </div>
        </div>
      </Popup>
      </div>
    )
  }
}