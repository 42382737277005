import React from "react";
import { connect } from "react-redux";
import { getExchangeRates } from "../helpers/apiHelper";
import { store } from "../store/index";
import { updateExchangeRates } from "../actions/index";

class FetchExchangeRatesStrategy extends React.Component {

  constructor(props) {
    super(props);
    this.taskStarted = false;
  }

  componentDidMount(){
    this.execute();
  }

  componentDidUpdate(){
    this.execute();
  }

  execute = async () => {
    if (!this.taskStarted){
      if (this.props.currencyCode){
        this.taskStarted=true;
        getExchangeRates(this.props.currencyCode)
          .then((data) => {
            store.dispatch(updateExchangeRates(data));
            this.taskStarted = false;
          })
          .catch(() => this.taskStarted = false);
      }
    }
  }

  render () {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    currencyCode: state.community.comm_currency_code,
  };
};

export default connect(mapStateToProps)(FetchExchangeRatesStrategy);
