export default class PrototypeCache {
    static $type
    static add
    static contains
    static insert
    static removeItem
    static removeAt
    static removeAllInList
    static clear
}
