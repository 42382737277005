import React, { useState } from "react";
import { useDrag } from "react-dnd";
import RouteIcon from "../../../../assets/img/route_icon.png";
import CannabisIcon from "../../../../assets/img/cannabis.svg";
import DeliveryIcon from "../../../../assets/img/bag.svg";
import RidesharingIcon from "../../../../assets/img/car.svg";
import BulkIcon from "../../../../assets/img/bag.svg"; //TODO update with Truck icon
import { utcStringToLocalString } from "../../../../helpers/dateHelper";
import { Trans } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import RideServiceNames from "../../../../const/RideServiceNames";
import i18n from "i18next";
import { convertToFiat } from "../../../../helpers/paymentHelper";

const RIDE_SERVICE_TYPE_ICONS = {
  0: RidesharingIcon,
  1: DeliveryIcon,
  2: CannabisIcon,
  3: BulkIcon,
  4: DeliveryIcon,
  5: DeliveryIcon,
  6: RidesharingIcon,
  7: RidesharingIcon,
  8: RidesharingIcon,
  9: DeliveryIcon,
  10: RidesharingIcon,
  11: BulkIcon
};

function OrderTile(props) {
  let { draggable, isOnlyRide, order, route_id, type, setAlert } = props;

  const [{}, dragRef] = useDrag({
    type: type,
    item: { ...order, route_id, isOnlyRide },
  });

  const showRideInfoAlert = (row) => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: -250 }}
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="success"
      >
        <h5>#{row.chain_route_id}</h5>
        <div style={{ textAlign: "center" }}>
          <div style={{ width: "60%", display: "inline-block" }}>
            <p style={{ textAlign: "left" }}>
              <b>
                <Trans>SCHEDULED_DATE</Trans>
              </b>
              : {utcStringToLocalString(row.scheduled_datetime)} <br />
              <b>
                <Trans>ONSITE_DATE</Trans>
              </b>
              : {utcStringToLocalString(row.onsite_datetime)} <br />
              <b>
                <Trans>FROM_ACCOUNT</Trans>
              </b>
              : {row.from_address} <br />
              <b>
                <Trans>TO_ACCOUNT</Trans>
              </b>{" "}
              : {row.to_address} <br />
              {row.ride_request_id && row.status_id !== 0 && (
                <>
                  <b>
                    <Trans>RIDE_REQUEST</Trans>
                  </b>
                  :
                  <Link
                    to={`/ride-request-summary?reqId=${row.ride_request_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {row.ride_request_id}
                  </Link>{" "}
                  <br />
                </>
              )}
              <b>
                <Trans>RIDE_SERVICE_NAME</Trans>
              </b>
              : {i18n.t(RideServiceNames[row.ride_service_type_id])} <br />
              <b>
                <Trans>TIP_AMOUNT</Trans>
              </b>
              : {convertToFiat(row.tip_token_amount, row.scheduled_datetime)}{" "}
              <br />
              {row.note && (
                <div>
                  <b>
                    <Trans>NOTE</Trans>
                  </b>
                  : {row.note} <br />
                </div>
              )}
              {row.total_order_amount >= 0 && (
                <div>
                  <b>
                    <Trans>TOTAL_ORDER_AMOUNT</Trans>
                  </b>
                  :{" "}
                  {convertToFiat(
                    row.total_order_amount,
                    row.scheduled_datetime
                  )}{" "}
                  <br />
                </div>
              )}
              <br />
              <b>
                <Trans>RIDER</Trans>
              </b>
              : {row.rider_name || row.rider} <br />
              <b>
                <Trans>ORDER_NUMBER</Trans>
              </b>
              : {row.order_number} <br />
              <b>
                <Trans>CUSTOMER_NAME</Trans>
              </b>
              : {row.customer_first_name + " " + row.customer_last_name} <br />
              <b>
                <Trans>CUSTOMER_PHONE</Trans>
              </b>
              : {row.customer_phone}
            </p>
          </div>
        </div>
      </SweetAlert>
    );
  };

  return (
    <div
      style={{
        cursor: draggable ? "-webkit-grab" : "",
        backgroundColor: draggable ? "white" : "#eee",
      }}
      className={
        draggable
          ? "ride-merger-order-tile-draggable"
          : "ride-merger-order-tile"
      }
      ref={draggable === true ? dragRef : null}
      onClick={() => showRideInfoAlert(order)}
    >
      <span>
        <img
          src={RIDE_SERVICE_TYPE_ICONS[order.ride_service_type_id]}
          style={{
            margin: "auto",
            height: "35px",
            width: "35px",
          }}
        />
      </span>
      <span style={{ fontSize: "1.5em" }}>
        {order.rider_name}
        {order.pick_up_company_name ? (
          <span
            style={{
              color: "#888",
              paddingTop: "1px",
              display: 'block',
              fontSize: "0.8em"
            }}
          >
            {" (" + order.pick_up_company_name + ")"}
          </span>
        ) : (
          <p> </p>
        )}
        <span style={{ fontSize: "0.7em", fontStyle: "italic" }}>
          {order.customer_first_name + " " + order.customer_last_name}
        </span>
      </span>
      <span style={{ fontSize: "1.2em" }}>
        {order.order_number && "#" + order.order_number}
      </span>
      <span />
      <span style={{ gridColumn: "span 2" }}>
        {utcStringToLocalString(order.onsite_datetime)}
      </span>
      <span style={{ gridRow: "span 2" }}>
        <img
          src={RouteIcon}
          style={{
            margin: "auto",
            height: "40px",
            width: "16px",
            marginTop: "4px",
            marginLeft: "20px"
          }}
        />
      </span>
      <span style={{ gridColumn: "span 2" }}>{order.from_address}</span>
      <span style={{ gridColumn: "span 2" }}>{order.to_address}</span>
    </div>
  );
}

export { OrderTile };
