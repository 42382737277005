import './MorphingField.css';

import { CommunitySettingsContext, PhoneParamContext } from '../../EditSubmission';
import React, { useCallback, useContext, useEffect, useState } from "react";
import { getNewDriverSubmissionFile, uploadDriverSubmissionFiles } from "../../../../../helpers/apiHelper";

import InfoIcon from "../../../../../components/InfoIcon/InfoIcon";
import LoadingSpinner from "../../../../../components/LoadingSpinner/LoadingSpinner";
import RideServiceNames from "../../../../../const/RideServiceNames";
import { Trans } from "react-i18next";
import i18n from 'i18next';
import { nonWordCharactersAndUnderscore } from '../../../../../const/Regex';
import vehicleTypes from '../../../../../const/VehicleTypes';

const MorphingField = (props) => {
    /* Varialbles */
    const { fieldTemplate, selectedVehicleType, onChange, onBlur, toggleShowDocument, disabled } = props;
    const communitySettings = useContext(CommunitySettingsContext);
    const phoneParam = useContext(PhoneParamContext);
    const [isLoading, setIsLoading] = useState(false);
    const [fileInputText, setFileInputText] = useState(i18n.t("NEW_SUBMISSION_CHOOSE_FILE"));
    let inputToReturn;
    const [showModal, setShowModal] = useState(false);
    const [modalURL, setModalURL] = useState("");

    /* UseEffects */
    useEffect(() => {
        if (fieldTemplate.value) {
            setFileInputText(fieldTemplate.value);
        } else {
            setFileInputText(i18n.t("NEW_SUBMISSION_CHOOSE_FILE"));
        }
    }, [fieldTemplate.value]);

    /* Functions */
    const handleKeyPress = (event) => {
        if (fieldTemplate.type === "number") {
            /* For number inputs, we stop propagation of all non digit characters (excluding "." and "-")
            because it invalidates the value otherwise. */
            if (isNaN(parseFloat(event.key)) && !isFinite(event.key) && event.key !== '.' && event.key !== '-') {
                event.preventDefault();
            }
        }
    }

    const handleChange = (id, newValue) => {
        if (fieldTemplate.type === "number") newValue = parseFloat(newValue);
        onChange(id, newValue);
    };

    const handleBlur = useCallback((id, newValue, validationRegex, isNumber = false) => {
        if (isNumber || fieldTemplate.type === "number" && newValue.length > 0) newValue = parseFloat(newValue);
        onBlur(id, newValue, validationRegex);
    }, [props]);

    const handleCheckboxChange = (id, checkboxValue, checked) => {
        const valueArrayCopy = [...fieldTemplate.value];
        if (checked) {
            valueArrayCopy.push(checkboxValue);
        } else {
            const index = valueArrayCopy.indexOf(checkboxValue);
            if (index > -1) {
                valueArrayCopy.splice(index, 1);
            }
        }
        onBlur(id, valueArrayCopy);
    };

    const handleFileInput = async (id, file) => {
        const acceptedFileType = ["image/jpeg", "image/gif", "image/png", "application/pdf"];
        if (file.size > 25000000)
            alert(i18n.t("NEW_SUBMISSION_MAX_FILE_SIZE"));
        if (acceptedFileType.indexOf(file.type) === -1)
            alert(i18n.t("NEW_SUBMISSION_ACCEPTED_FILE_FORMAT"));
        else {
            setIsLoading(true);
            const filesFormData = new FormData();
            const renamedFile = renameFile(file);
            filesFormData.append("file", renamedFile);
            await uploadDriverSubmissionFiles(phoneParam, filesFormData)
                .then(() => onBlur(id, renamedFile.name))
                .catch(() => {
                    onBlur(id, "");
                    setFileInputText(i18n.t("NEW_SUBMISSION_UPLOADED_FILE_ERROR"));
                })
                .finally(() => setIsLoading(false));
        }
    }

    const renameFile = (originalFile) => {
        const newNameWithExtension = fieldTemplate.name + "." + originalFile.name.split(".").pop();
        return new File([originalFile], newNameWithExtension, { type: originalFile.type, lastModified: originalFile.lastModified });
    };

    const handleOpenDocument = useCallback(async () => {
        // File is already uploaded. Create a link to it.
        await getNewDriverSubmissionFile(phoneParam, fieldTemplate.value)
            .then(response => {
                const fileURL = URL.createObjectURL(response.data);
                toggleShowDocument(fileURL, fieldTemplate.value);
            }).catch(() => {
                alert(i18n.t("NEW_SUBMISSION_CANNOT_OPEN_IMAGE"));
            });
    }, [fieldTemplate.value])

    /* Switch */
    switch (fieldTemplate.type) {
        case "select":
            const isNumber = (Array.isArray(fieldTemplate.options) && typeof fieldTemplate.options[0] === "number") // Meant to keep number type when options are numeric.
            inputToReturn = (
                <select
                    id={fieldTemplate.id}
                    name={fieldTemplate.name}
                    placeholder={fieldTemplate.placeholder}
                    value={fieldTemplate.value || ""}
                    onChange={e => handleBlur(fieldTemplate.id, e.target.value, fieldTemplate.validation_regex, isNumber)}
                    disabled={disabled}
                    className="form-control"
                >
                    {fieldTemplate.value === "" && <option value="" hidden>-- {i18n.t('NEW_SUBMISSION_SELECT_OPTION')} --</option> /*Default ghost option*/}

                    {fieldTemplate.options.map((option, index) => {
                        const fieldOptionsTranslationKey = fieldTemplate.translation + "_OPTIONS";
                        const option_capitalized = (typeof option === "string") ? option.toUpperCase().replace(nonWordCharactersAndUnderscore, "_") : option;
                        let translatedOption = "";
                        if (fieldTemplate.name === "vehicle_type_id") {
                            translatedOption = i18n.t(vehicleTypes[option]);
                        }
                        else if (i18n.exists(fieldOptionsTranslationKey)) {
                            const fieldOptionsTranslationObject = i18n.t(fieldOptionsTranslationKey, { returnObjects: true });
                            if (fieldOptionsTranslationObject.hasOwnProperty(option_capitalized)) {
                                translatedOption = fieldOptionsTranslationObject[option_capitalized];
                            } else {
                                translatedOption = option // Not translated. Display as is.
                            }
                        } else {
                            translatedOption = option // Not translated. Display as is.
                        }

                        return (
                            <option
                                value={option}
                                key={index}
                            >
                                {translatedOption}
                            </option>
                        );
                    })}
                </select>
            )
            break;
        case "checkbox":
            inputToReturn = (
                <div className="checkboxes-container">
                    {fieldTemplate.options.map((checkboxValue, index) => {
                        const checkboxId = `${fieldTemplate.name}-checkbox-${index}`;
                        let isThisCheckboxDisabled = true; // True by default. Vehicle type will unlock the ones that are available.
                        if (fieldTemplate.name === "allowed_ride_services" && typeof selectedVehicleType === "number") {
                            if (communitySettings && communitySettings.hasOwnProperty(selectedVehicleType)) {
                                isThisCheckboxDisabled = !communitySettings[selectedVehicleType][checkboxValue];
                            }
                        }
                        return (
                            <React.Fragment key={checkboxId}>
                                <input
                                    type="checkbox"
                                    id={checkboxId}
                                    name={checkboxId}
                                    value={checkboxValue}
                                    checked={fieldTemplate.value.indexOf(checkboxValue) > -1}
                                    onChange={e => handleCheckboxChange(fieldTemplate.id, checkboxValue, e.target.checked)}
                                    disabled={disabled || isThisCheckboxDisabled}
                                />
                                <label htmlFor={checkboxId}
                                    className={(disabled || isThisCheckboxDisabled) ? "disabled" : null}
                                >
                                    <Trans>{RideServiceNames[checkboxValue]}</Trans>
                                </label>
                            </React.Fragment>
                        )
                    })}
                </div>
            );
            break;
        case "file":
            inputToReturn = (
                <div className="tweaked-file-input">
                    <p style={{ backgroundColor: disabled && "#e3e3e3" }}>{fileInputText}</p>
                    <input
                        id={fieldTemplate.id}
                        type={fieldTemplate.type}
                        name={fieldTemplate.name}
                        placeholder={fieldTemplate.placeholder}
                        onChange={e => handleFileInput(fieldTemplate.id, e.target.files[0])}
                        accept="image/*, application/pdf,  application/msword"
                        disabled={disabled}
                        className="form-control"
                    />
                    <label htmlFor={fieldTemplate.id} style={{ opacity: disabled && "0.5", cursor: disabled && "not-allowed", backgroundColor: disabled && "transparent" }} />
                    <button onClick={() => handleOpenDocument()} disabled={!fieldTemplate.value} />
                </div>
            )
            break;
        default:
            inputToReturn = (
                <input
                    id={fieldTemplate.id}
                    type={fieldTemplate.type}
                    name={fieldTemplate.name}
                    placeholder={fieldTemplate.placeholder}
                    value={fieldTemplate.value || ''}
                    onKeyPress={e => handleKeyPress(e)}
                    onChange={e => handleChange(fieldTemplate.id, e.target.value)}
                    onBlur={e => handleBlur(fieldTemplate.id, e.target.value, fieldTemplate.validation_regex)}
                    disabled={disabled}
                    className="form-control"
                />
            )
            break;
    }

    return (
        <>
            {
                fieldTemplate.type !== "hidden" &&
                <div
                    className={`morphing-field-group form-group ${fieldTemplate.show_error && "validation-error"}`}
                    // Space out allowed ride services checkboxes
                    style={{ gridColumn: fieldTemplate.name === "allowed_ride_services" && "2 / span 2" }}
                >
                    <div className="label-wrapper">
                        <label className="control-label" htmlFor={fieldTemplate.id}><Trans>{fieldTemplate.translation}</Trans></label>
                        {fieldTemplate.type == "file" &&
                            <InfoIcon
                                description={`${i18n.t("NEW_SUBMISSION_ACCEPTED_FILE_FORMAT")} ${i18n.t("NEW_SUBMISSION_MAX_FILE_SIZE")}`}
                            />
                        }
                    </div>
                    {isLoading ? <LoadingSpinner width="12" /> : inputToReturn}
                    <p className="validation-error">{fieldTemplate.show_error && <Trans>NEW_SUBMISSION_VALIDATION_ERROR</Trans>}</p>

                </div>
            }
        </>
    );
};

export default MorphingField;