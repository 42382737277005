// Import locales
import 'moment/locale/fr'

import i18n from "../i18n";
import { localTimeZone } from "../variables/secretEnvVariables";
import moment from 'moment-timezone'

function setLocaleWithCurrentLanguage() {
  if (i18n.language.includes("fr")) {
    moment.locale('fr');
  } else {
    moment.locale('en');
  }
}

export function isToday(someDate) {
  const today = new Date();
  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
}

export function utcStringToLocalString(utcString) {
  if (!utcString) {
    return "";
  } else {
    return moment.utc(utcString).tz(localTimeZone).format('YYYY-MM-DD HH:mm:ss');
  }
}

export function utcStringToLocalDateString(utcString) {
  if (!utcString) {
    return "";
  } else {
    return moment.utc(utcString).tz(localTimeZone).format('YYYY-MM-DD');
  }
}

export function utcStringToLocalTimeString(utcString) {
  if (!utcString) {
    return "";
  } else {
    return moment.utc(utcString).tz(localTimeZone).format('HH:mm:ss');
  }
}

export function calculateElapsedTimeFromNow(date) {
  setLocaleWithCurrentLanguage();
  if (!date) {
    return i18n.t("never");
  } else {
    return moment.utc(date).tz(localTimeZone).fromNow();
  }
}

export function isoToDateTime(isoString) {
  return new Date(isoString.replace(/-/g, "/"));
}

export function convertLocalDateToBlockTime(date) {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
    date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));
}

export function timestampToHuman(timestamp){
	let date = new Date(timestamp * 1000);
	return utcStringToLocalString(date);
}

export function convertSecondsToHoursString(seconds) {
  /* Idea: Include i18n to translate units */
  const roundedSeconds = Math.round(seconds);
  const hour = Math.floor(roundedSeconds / 3600);
  const min = Math.floor(roundedSeconds / 60) % 60;
  const sec = roundedSeconds % 60;

  const formatTime = (arg) => (arg > 9 ? "" + arg: "0" + arg);
  return formatTime(hour) + ":" + formatTime(min) + ":" + formatTime(sec);
}


export function convertMinutesToHoursString(minutes) {
  /* Idea: Include i18n to translate units */
  const roundedMinutes = Math.round(minutes);
  const hour = Math.floor(roundedMinutes / 60);
  const min = roundedMinutes % 60;

  const formatTime = (arg) => (arg > 9 ? "" + arg: "0" + arg);
  return formatTime(hour) + ":" + formatTime(min) + ":00";
}

export function getDifferenceInSeconds(datetime, datetimeToSubstract) {
  const moment_datetime = moment(datetime);
  const moment_datetimeToSubstract = moment(datetimeToSubstract);
  return moment_datetime.diff(moment_datetimeToSubstract, 'seconds'); 
}