import * as actionType from "../actions/action_types";

const initialState = {
    data: {}
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.UPDATE_DASHBOARD:
      return Object.assign({}, state, {
        data: action.payload
      });
    case actionType.LOGOUT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};

export default dashboardReducer;
