import 'react-datetime/css/react-datetime.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

import {
    Col,
    Grid,
    Row
} from "react-bootstrap";
import React, { Component } from "react";
import { getDriverName, getRiderName, getProfilePhone } from '../../helpers/viewsHelper';

import Card from "../../components/Card/Card.jsx";
import RideServiceNames from '../../const/RideServiceNames';
import SweetAlert from "react-bootstrap-sweetalert";
import TableAPI from "../Tables/TableAPI";
import { Trans } from "react-i18next";
import { connect } from 'react-redux';
import { getCompletedRides } from "../../helpers/apiHelper";
import moment from 'moment';
import { utcStringToLocalString } from '../../helpers/dateHelper';
import { Link } from "react-router-dom";
import { CurrencyFormatter } from '../../helpers/CurrencyFormatter';

class CompletedRides extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().subtract(1, 'days').startOf('day'),
            endDate: moment().startOf('day'),
            focusedInput: null,
            alert: null,
        };
    }

    constructFormFromApi = async (data) => {
        const historicalRides = await getCompletedRides(data);
        if (data.get_count) {
            return historicalRides;
        }
        let formRows = {};
        if (historicalRides instanceof Error) return historicalRides;
        for (var index in historicalRides) {
            const row = await this.getFormRowFromHistoricalRide(historicalRides[index]);
            formRows[index] = row;
        }
        return formRows;
    };


    getFormRowFromHistoricalRide = async (historicalRide) => {
        const rider = historicalRide.rider;
        const riderFullName = getRiderName(rider);
        const riderAccount = historicalRide.rider_account;

        const rideServiceType = historicalRide.ride_service_type_id;

        const driver = historicalRide.driver;
        const driverFullName = getDriverName(driver);
        const driverAccount = historicalRide.driver_account;
        const driverPhone = getProfilePhone(driver);

        let totalChargeWithTax = historicalRide.total_charge_with_tax;
        let creditCardCharge = historicalRide.credit_card_charge;
        console.log("totalChargeWithTax", totalChargeWithTax);
        console.log("creditCardCharge", creditCardCharge);
        console.log("complete_timestamp", historicalRide.complete_timestamp);
        let totalWithTaxAndCredit = totalChargeWithTax + creditCardCharge;

        let row = {
            "canceledFlag": historicalRide.canceled_flag,
            "complete_timestamp": utcStringToLocalString(historicalRide.complete_timestamp),
            "route_id": parseInt(historicalRide.route_id),
            "driver": driverAccount,
            "driver_name": driverFullName,
            "driver_phone": driverPhone,
            "rider": riderAccount,
            "order_number": historicalRide.order_number,
            "rider_name": riderFullName,
            "ride_service_name": <Trans>{RideServiceNames[rideServiceType]}</Trans>,
            "from_address": historicalRide.path.from_address,
            "to_address": historicalRide.path.to_address,
            "customer_phone": historicalRide.customer_phone,
            "pickup_company_name": historicalRide.pickup_company_name,
            "total_with_credit": totalWithTaxAndCredit,
            "currency": historicalRide.currency,
            "currency_exchange_rate": historicalRide.currency_exchange_rate,
            "ride_request_id": historicalRide.ride_request_id,
        }
        return row
    }

    getRowProps = (row) => {
        if (row) {
            return {
                style: { color: row.original.canceledFlag === true ? "red" : "black" }
            }
        }
        else {
            return {
                style: { className: "-highlight" }
            }
        }
    }

    getColumns = () => {
        let headersList = ["complete_timestamp", "ride_service_name", "order_number", "pickup_company_name", "driver_name", "rider_name", "customer_phone", "from_address", "to_address", "total_with_credit", "total_with_credit_cad"]
        let columsTitles = {
            "complete_timestamp": <Trans>DATE</Trans>,
            "driver_name": <Trans>DRIVER</Trans>,
            "rider_name": <Trans>RIDER</Trans>,
            "customer_phone": <Trans>CUSTOMER_PHONE</Trans>,
            "ride_service_name": <Trans>RIDE_SERVICE_NAME</Trans>,
            "order_number": <Trans>ORDER_NUMBER</Trans>,
            "from_address": <Trans>FROM_ADDRESS</Trans>,
            "to_address": <Trans>TO_ADDRESS</Trans>,
            "total_with_credit": <Trans>Total</Trans>,
            "total_with_credit_cad": <Trans>Total CAD</Trans>,
            "pickup_company_name": <Trans>BUSINESS</Trans>,
        }

        let columnsList = [];
        for (let i = 0; i < headersList.length; i++) {
            let element = headersList[i];
            if (element === 'complete_timestamp') {
                let column = {
                    Header: columsTitles[element],
                    id: element,
                    canFilter: false,
                    filterMode: "date",
                    canSort: true,
                    defaultSort: true,
                    accessor: element,
                    width: 130,
                    getProps: (state, row, column) => { return this.getRowProps(row) }
                }
                columnsList.push(column);
            }
            else if (element === 'to_address' || element === 'from_address') {
                let column = {
                    Header: columsTitles[element],
                    id: element,
                    canFilter: true,
                    width: 200,
                    filterMode: 'text',
                    accessor: element,
                    getProps: (state, row, column) => { return this.getRowProps(row) }
                }
                columnsList.push(column);
            } else if (element === 'rider_name') {
                let column = {
                    Header: columsTitles[element],
                    id: element,
                    canFilter: true,
                    canSort: true,
                    accessor: element,
                    width: 130,
                    getProps: (state, row, column) => { return this.getRowProps(row) },
                    Cell: e => (
                        /*<Link
                            onClick={(e) => e.stopPropagation()}
                            to={`/rider?name=${e.original['rider']}`}
                            target="_blank"
                            style={{ color: "#70CEEC", cursor: "pointer" }}
                            rel="noopener noreferrer"
                        >
                            {e.value}
                        </Link>*/
                        <b>{e.value}</b>
                    )
                }
                columnsList.push(column);
            }
            else if (element === 'driver_name') {
                let column = {
                    Header: columsTitles[element],
                    id: element,
                    canFilter: true,
                    canSort: true,
                    accessor: element,
                    width: 130,
                    getProps: (state, row, column) => { return this.getRowProps(row) },
                    Cell: e => (
                        <Link
                            onClick={(e) => e.stopPropagation()}
                            to={`/drivers/submissions/${e.original['driver_phone']}`}
                            target="_blank"
                            style={{ color: "#70CEEC", cursor: "pointer" }}
                            rel="noopener noreferrer"
                        >
                            {e.value}
                        </Link>
                    )
                }
                columnsList.push(column);
            } else if (element === 'ride_service_name') {
                let column = {
                    Header: columsTitles[element],
                    id: element,
                    canFilter: true,
                    filterMode: 'select',
                    width: 100,
                    options: [{ value: '', label: <Trans>NONE</Trans> }].concat(
                        [0, 1, 3, 4, 5, 6, 7].map(rideServiceType => {
                          return {
                            value: rideServiceType,
                            label: <Trans>{RideServiceNames[rideServiceType]}</Trans>
                          }
                      })),
                    accessor: element,
                    getProps: (state, row, column) => { return this.getRowProps(row) }
                }
                columnsList.push(column);
            } else if (element === 'total_with_credit') {
                let column = {
                    Header: columsTitles[element],
                    id: element,
                    accessor: (data) => {
                        return CurrencyFormatter.formatAmount(data.total_with_credit, data.currency);
                    },
                    canFilter: false,
                    canSort: true,
                    filterMode: 'number',
                    width: 80,
                    // Cell: row => <div style={{ textAlign: "center" }}>{row.value/100}</div>,
                    getProps: (state, row, column) => { return this.getRowProps(row) }
                }
                columnsList.push(column);
            } else if (element === 'total_with_credit_cad') {
                let column = {
                    Header: columsTitles[element],
                    id: element,
                    accessor: (data) => {
                        return CurrencyFormatter.convertToCAD(data.total_with_credit, data.currency, data.currency_exchange_rate);
                    },
                    canFilter: false,
                    canSort: true,
                    filterMode: 'number',
                    width: 70,
                    // Cell: row => <div style={{ textAlign: "center" }}>{row.value/100}</div>,
                    getProps: (state, row, column) => { return this.getRowProps(row) }
                }
                columnsList.push(column);
            } else if (element === 'order_number') {
                let column = {
                    Header: columsTitles[element],
                    id: element,
                    accessor: element,
                    canFilter: true,
                    canSort: true,
                    filterMode: 'number',
                    width: 80,
                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
                    getProps: (state, row, column) => { return this.getRowProps(row) }
                }
                columnsList.push(column);
            } else if (element === 'customer_phone') {
                let column = {
                    Header: columsTitles[element],
                    id: element,
                    accessor: element,
                    canFilter: true,
                    canSort: true,
                    filterMode: 'number',
                    width: 80,
                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
                    getProps: (state, row, column) => { return this.getRowProps(row) }
                }
                columnsList.push(column);
            } else if (element === 'pickup_company_name') {
                let column = {
                    Header: columsTitles[element],
                    id: element,
                    accessor: element,
                    canFilter: true,
                    canSort: true,
                    filterMode: 'string',
                    width: 160,
                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
                    getProps: (state, row, column) => { return this.getRowProps(row) }
                }
                columnsList.push(column);
            }
        }
        return columnsList;
    }

    showRideInfoAlert = (row) => {
        console.log(row)
        this.setState({
            alert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" }}
                    onConfirm={() => this.hideAfterconfirmAlert()}
                    confirmBtnBsStyle="success"
                >
                    <div style={{ textAlign: "center" }}>
                        <div style={{ width: "60%", display: 'inline-block' }}>
                            <p style={{ textAlign: 'left' }}>
                                <b><Trans>DRIVER_EDIT_DATE</Trans></b>: {row.complete_timestamp} <br />
                                <b><Trans>BUSINESS</Trans></b>: {row.pickup_company_name} <br />
                                <b><Trans>FROM_ACCOUNT</Trans></b>: {row.from_address} <br />
                                <b><Trans>TO_ACCOUNT</Trans></b>: {row.to_address} <br />
                                <b><Trans>CUSTOMER_PHONE</Trans></b>: {row.customer_phone} <br />
                                <b><Trans>RIDE_REQUEST</Trans></b>
                                : <Link onClick={(e) => e.stopPropagation()} to={`/ride-request-summary?reqId=${row.ride_request_id}`} target="_blank" rel="noopener noreferrer">
                                    {row.ride_request_id}
                                </Link> <br />
                                <b><Trans>DRIVER</Trans></b>: {row.driver_name} <br />
                                <b><Trans>RIDER</Trans></b>: {row.rider_name} <br />
                                <b><Trans>RIDE_SERVICE_NAME</Trans></b>: {row.ride_service_name} <br />
                                <b><Trans>TOTAL</Trans></b>: {row.total_with_credit}<br />
                                <b><Trans>TOTAL CAD</Trans></b>: {row.total_with_credit_cad}</p>
                        </div></div>
                </SweetAlert>
            )
        });
    }

    hideAfterconfirmAlert() {
        this.setState({ alert: null });

    };

    render() {
        const columns = this.getColumns();
        return (
            <div className="content">
                {this.state.alert}
              
                <TableAPI
                    fetchData={this.constructFormFromApi}
                    columns={columns}
                    pageSize={50}
                    sorted={[
                        {
                            id: 'route_id',
                            desc: true
                        }
                    ]}
                    className="-highlight"
                    getTrProps={(state, rowInfo) => {
                        console.log(rowInfo);
                        return {
                            onClick: () => {
                                this.showRideInfoAlert(rowInfo.original);
                            }
                        }
                    }}
                />
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        community: state.userData.communityAccount
    };
};

export default connect(mapStateToProps)(CompletedRides);
