import React, { Component } from 'react';
import { Trans } from 'react-i18next'

class RiderNotFoundError extends Component {

    render() {
        return (
            <div
                style={{
                    minHeight: "85vh",
                    padding: "6%",
                    textAlign: "center"
                }}>
                <h1><Trans>RIDER_NOT_FOUND</Trans></h1>
                <h3><Trans>RIDER_NOT_FOUND_ERROR_MESSAGE</Trans></h3>
                <h3>{this.props.accountName}</h3>
            </div>
        )
    }

}

export default RiderNotFoundError;