import { GeoJsonLayer, IconLayer } from "@deck.gl/layers";
import { dayMapstyle, nightMapstyle } from "../../const/MapBoxStyles";

import { Component } from "react";
import DeckGL from "@deck.gl/react";
import React from "react";
import { StaticMap } from "react-map-gl";
import { Trans } from "react-i18next";
import { apiAddress } from '../../variables/secretEnvVariables';
import { connect } from "react-redux";
import { isDay } from "../../helpers/viewsHelper";
import { mapboxToken } from "../../variables/secretEnvVariables";

class DriversOnlineMap extends Component {
  state = {
    filter: false,
    mapStyle: isDay() ? dayMapstyle : nightMapstyle
  };

  async componentDidMount() {
    await this.setPoints();
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.drivers.length !== this.props.drivers.length ||
      prevProps.commCoords.length !== this.props.commCoords.length ||
      this.isPositionUpdated(prevProps.drivers, this.props.drivers)
    ) {
      this.setPoints(prevProps);
    }
    if (prevProps.driverFilter !== this.props.driverFilter) {
      this.filterDrivers(this.props.driverFilter);
    }

  }

  isPositionUpdated = (prevDrivers, drivers) => {
    for (const driver of drivers) {
      const prevDriver = prevDrivers.find(d => d.account_name === driver.account_name);
      if (prevDriver.lat !== driver.lat || prevDriver.lon !== driver.lon) {
        return true;
      }
    }
    return false;
  }

  _renderTooltip() {
    const { hoveredObject, pointerX, pointerY } = this.state || {};
    return (
      hoveredObject && (
        <div
          style={{
            padding: "15px",
            color: "white",
            backgroundColor: "black",
            position: "absolute",
            zIndex: 1,
            pointerEvents: "none",
            width: "220px",
            left: pointerX,
            top: pointerY,
          }}
        >
          {hoveredObject.name}
          {hoveredObject.activeRide ? <div>
                                  <hr />
                                  -- <Trans>IN_RIDE</Trans> --<br />
                                  <Trans>FROM_ADDRESS</Trans>: {hoveredObject.activeRide.path.from_address}<br />
                                  <Trans>TO_ADDRESS</Trans>: {hoveredObject.activeRide.path.to_address}<br />
                                    </div> : null}
          <hr />
          
        </div>
      )
    );
  }

  setPoints = async (prevProps) => {
    if (typeof this.props.commCoords[0] === 'undefined') return;
    const commPolygonCenter = this.getPolygonCenter(this.props.commCoords);
    await this.setState({
      viewport: {
        latitude: commPolygonCenter[1],
        longitude: commPolygonCenter[0],
        zoom: 10,
      },
    });
    const driverIcons = [];
    for (let driver of this.props.drivers) {
      const prevDriver = prevProps.drivers.find(d => d.account_name === driver.account_name);
      let bearing = 0;
      if (prevDriver) {
        bearing = this.getBearing(prevDriver.lat, prevDriver.lon, driver.lat, driver.lon);
      }
      driverIcons.push({
        coordinates: [parseFloat(driver.lon), parseFloat(driver.lat)],
        name: driver.first_name + " " + driver.last_name,
        permissions: driver.permissions,
        activeRide: driver.active_ride,
        account: driver.account_name,
        angle: bearing
      });
    }

    await this.setState({ driverIcons });
  };

  getPolygonCenter = (arr) => {
    var minX, maxX, minY, maxY;
    for (var i = 0; i < arr.length; i++) {
      minX = arr[i][0] < minX || minX == null ? arr[i][0] : minX;
      maxX = arr[i][0] > maxX || maxX == null ? arr[i][0] : maxX;
      minY = arr[i][1] < minY || minY == null ? arr[i][1] : minY;
      maxY = arr[i][1] > maxY || maxY == null ? arr[i][1] : maxY;
    }
    return [(minX + maxX) / 2, (minY + maxY) / 2];
  };

  onDriverNameClick = (accountName) => {
    this.props.history.push({
      pathname: "/driver_edit",
      search: "?name=" + accountName,
      state: { name: accountName },
    });
  };

  filterDrivers = (driver) => {
    if (driver === "") {
      this.setState({filter: false});
    } else {
      const { driverIcons } = this.state;
    const filteredDriverIcons = driverIcons.filter(d => d.name.toLowerCase().includes(driver.toLowerCase()));
    this.setState({filter: true, filteredDriverIcons});
    }
  }

  radians(n) {
    return n * (Math.PI / 180);
  }
  degrees(n) {
    return n * (180 / Math.PI);
  }
  
  getBearing(startLat,startLong,endLat,endLong){
    startLat = this.radians(startLat);
    startLong = this.radians(startLong);
    endLat = this.radians(endLat);
    endLong = this.radians(endLong);
  
    var dLong = endLong - startLong;
  
    var dPhi = Math.log(Math.tan(endLat/2.0+Math.PI/4.0)/Math.tan(startLat/2.0+Math.PI/4.0));
    if (Math.abs(dLong) > Math.PI){
      if (dLong > 0.0)
         dLong = -(2.0 * Math.PI - dLong);
      else
         dLong = (2.0 * Math.PI + dLong);
    }
  
    return ((this.degrees(Math.atan2(dLong, dPhi)) + 360.0) % 360.0) + 180;
  }

  render() {
    if (
      typeof this.state.viewport === "undefined" ||
      this.state.viewport === null
    )
      return <div></div>;

    const ICON_MAPPING = {
      marker: { x: 0, y: 0, width: 128, height: 128, mask: true },
    };

    const layers = [
      new GeoJsonLayer({
        id: "community_bounds",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: this.props.commCoords,
          },
        },
        opacity: 1,
        stroked: false,
        filled: false,
        lineWidthMinPixels: 2,
        parameters: {
          depthTest: false,
        },
        getLineColor: [251, 64, 75],
        pickable: true,
      }),
      new IconLayer({
        id: "drivers",
        data: (this.state.filter) ? this.state.filteredDriverIcons : this.state.driverIcons,
        pickable: true,
        iconMapping: ICON_MAPPING,
        getAngle: (d) => d.angle,
        getIcon: (d) => ({
          url: apiAddress + "/api/v1/ana/get_driver_icon",
          width: 55,
          height: 128,
        }),
        sizeScale: 15,
        getPosition: (d) => d.coordinates,
        getSize: (d) => 4,
        onClick: (d) => this.onDriverNameClick(d.object.account),
        onHover: (info) =>
          this.setState({
            hoveredObject: info.object,
            pointerX: info.x,
            pointerY: info.y,
          }),
      }),
    ];

    return (
      <div>
        <DeckGL
          layers={layers}
          initialViewState={this.state.viewport}
          controller={true}
          pickingRadius={5}
          width="100%"
          height="100%"
        >
          <StaticMap
            reuseMaps
            preventStyleDiffing={true}
            mapStyle={this.state.mapStyle}
            mapboxApiAccessToken={mapboxToken}
          />
          {this._renderTooltip()}
        </DeckGL>
        <div style={{ height: "680px" }} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commCoords: state.community.coords,
  };
};
export default connect(mapStateToProps)(DriversOnlineMap);

