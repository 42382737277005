import {
  Button,
  DropdownButton,
  MenuItem,
} from "react-bootstrap";
import React, { Component } from "react";
import {
  cancelCommunityCustomerRide,
  dispatchScheduledRide,
  getScheduledRides
} from "../../../helpers/apiHelper";
import { convertToFiat } from "../../../helpers/paymentHelper";

import CannabisIcon from "../../../assets/img/cannabis.svg";
import DeliveryIcon from "../../../assets/img/bag.svg";
import EditRidePopUp from "./EditRide/EditRidePopUp";
import { Link } from "react-router-dom";
import RideServiceNames from "../../../const/RideServiceNames";
import RidesharingIcon from "../../../assets/img/car.svg";
import BulkIcon from "../../../assets/img/bag.svg"; //TODO update with Truck icon
import SweetAlert from "react-bootstrap-sweetalert";
import TableAPI from "../../Tables/TableAPI";
import { Trans } from "react-i18next";
import i18n from "i18next";
import { miaBaseLink } from '../../../variables/secretEnvVariables';
import { utcStringToLocalString } from "../../../helpers/dateHelper";
import { SCHEDULED_RIDE_STATUS } from "../../../const/RideStatus";


const RIDE_SERVICE_TYPE_ICONS = {
  0: RidesharingIcon,
  1: DeliveryIcon,
  2: CannabisIcon,
  3: DeliveryIcon,
  4: DeliveryIcon,
  5: DeliveryIcon,
  6: RidesharingIcon,
  7: RidesharingIcon,
  8: RidesharingIcon,
  9: DeliveryIcon,
  10: RidesharingIcon,
  11: BulkIcon
}

export default class ScheduledRidesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      edit: false,
      selectedRide: '',
    };
  }

  toggleEditPopUp = () => {
    this.setState({ edit: !this.state.edit })
  };

  hideAlert() {
    this.setState({ alert: null });
  }

  hideAfterconfirmAlert() {
    this.setState({ alert: null });
  }

  loadingHandlerAlert = () => {
    this.setState({
      alert: <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={<Trans>SENDING</Trans>}
        closeOnClickOutside={false}
        allowEscape={false}
        showCancel={false}
        showConfirm={false}
      >
        <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
        <p>
          <Trans>SENDING_REQUEST</Trans>
        </p>
      </SweetAlert>
    });
  };

  showSuccessAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Confirmation"
          onConfirm={() => window.location.reload()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
        >
          <p>
            <Trans>ACTION_SUCCESS</Trans>
          </p>
        </SweetAlert>
      ),
    });
  };

  showRideInfoAlert = (row) => {
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", marginTop: -250 }}
          onConfirm={() => this.hideAfterconfirmAlert()}
          confirmBtnBsStyle="success"
        >
          <h5>#{row.chain_route_id}</h5>
          <div style={{ textAlign: "center" }}>
            <div style={{ width: "60%", display: "inline-block" }}>
              <p style={{ textAlign: "left" }}>
              <b>
                  <Trans>ID</Trans>
                </b>
                : {row.tracking_id} <br />
                <b>
                  <Trans>SCHEDULED_DATE</Trans>
                </b>
                : {row.scheduled_datetime} <br />
                <b>
                  <Trans>ONSITE_DATE</Trans>
                </b>
                : {row.onsite_datetime} <br />
                <b>
                  <Trans>FROM_ACCOUNT</Trans>
                </b>
                : {row.from_address} <br />
                <b>
                  <Trans>TO_ACCOUNT</Trans>
                </b>{" "}
                : {row.to_address} <br />
                <b>
                  <Trans>REQUEST_STATUS</Trans>
                </b>
                : {row.status} <br />
                {(row.ride_request_id && row.status_id !== 0) &&
                  <>
                    <b>
                      <Trans>RIDE_REQUEST</Trans>
                    </b>
                    :
                    <Link to={`/ride-request-summary?reqId=${row.ride_request_id}`} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>
                      {row.ride_request_id}
                    </Link> <br />
                  </>
                }
                <b>
                  <Trans>RIDE_SERVICE_NAME</Trans>
                </b>
                : {i18n.t(RideServiceNames[row.ride_service_name])} <br />
                <b>
                  <Trans>TIP_AMOUNT</Trans>
                </b>
                : {convertToFiat(row.tip_token_amount, row.scheduled_datetime)} <br />
                {row.note &&
                  <div>
                    <b>
                      <Trans>NOTE</Trans>
                    </b>
                : {row.note} <br />
                  </div>}
                {row.total_order_amount >= 0 &&
                  <div>
                    <b>
                      <Trans>TOTAL_ORDER_AMOUNT</Trans>
                    </b>
                : {convertToFiat(row.total_order_amount, row.scheduled_datetime)} <br />
                  </div>}
                <br />
                <b>
                  <Trans>RIDER</Trans>
                </b>
                : {row.rider_name || row.rider} <br />
                <b>
                  <Trans>ORDER_NUMBER</Trans>
                </b>
                : {row.order_number} <br />
                <b>
                  <Trans>CUSTOMER_NAME</Trans>
                </b>
                : {row.customer_name} <br />
                <b>
                  <Trans>CUSTOMER_PHONE</Trans>
                </b>
                : {row.customer_phone} <br />
                <b>
                  <Trans>SCHEDULED_RIDE_HEALTHCHECK</Trans>
                </b>
                : {row.problem_flag} <br />
              </p>
            </div>
          </div>
        </SweetAlert>

      ),
    });
  };

  editRideAlert = (scheduledRide) => {
    this.setState({ selectedRide: scheduledRide })
    this.toggleEditPopUp();
  };

  showErrorAlert = (error) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{
            display: "block",
            marginTop: "-100px",
          }}
          title="Error"
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          <p>
            An Error has occurred : <br></br>
            {error.message}{" "}
          </p>
        </SweetAlert>
      ),
    });
  };

  confirmAlert = (scheduledRide, action) => {
    let title, onConfirm, onCancel, confirmBtnText, cancelBtnText, paragraph;

    switch (action) {
      case "ride_dispatch":
        title = <Trans>REDISPATCH_RIDE_ALERT_TITLE</Trans>;
        onConfirm = () => this.dispatchRide(scheduledRide.tracking_id);
        onCancel = () => this.hideAlert();
        confirmBtnText =
          this.state.alert === null ? (
            <Trans>REDISPATCH_RIDE_ALERT_YES</Trans>
          ) : (
            <Trans>ALERT_LOADING</Trans>
          );
        cancelBtnText = <Trans>CANCEL</Trans>;
        paragraph = (
          <p>
            <Trans>REDISPATCH_RIDE_ALERT_BODY</Trans>
            <br></br>
            <Trans>WANT_TO_PROCEED</Trans>
          </p>
        );
        break;
      case "cancel_ride":
        title = <Trans>CANCEL_ACTIVE_RIDE_ALERT_TITLE</Trans>;
        onConfirm = async () => {
          this.loadingHandlerAlert();
          const res = await cancelCommunityCustomerRide(scheduledRide.ride_request_id);
          if (res.data.success) {
            this.showSuccessAlert();
          } else {
            this.showErrorAlert(res);
          }
        }
        onCancel = () => this.hideAlert();
        confirmBtnText =
          this.state.alert === null ? (
            <Trans>CANCEL_ACTIVE_RIDE_ALERT_CONFIRM</Trans>
          ) : (
            <Trans>ALERT_LOADING</Trans>
          );
        cancelBtnText = <Trans>CANCEL</Trans>;
        paragraph = (
          <p>
            <Trans>CANCEL_ACTIVE_CORP_RIDE_ALERT_BODY</Trans>
            <br></br>
            <Trans>WANT_TO_PROCEED</Trans>
          </p>
        );
        break;
      default: break;
    }
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{
            display: "block",
            marginTop: "-100px",
          }}
          title={title}
          onConfirm={onConfirm}
          onCancel={onCancel}
          confirmBtnBsStyle="warning"
          cancelBtnBsStyle="danger"
          confirmBtnText={confirmBtnText}
          cancelBtnText={cancelBtnText}
          showCancel={true}
          showLoaderOnConfirm={true}
        >
          {paragraph}
        </SweetAlert>
      ),
    });
  };

  dispatchRide = async (tracking_id) => {
    const self = this;
    const response = await dispatchScheduledRide(tracking_id);
    if (response.status === 200) {
      self.showSuccessAlert();
    } else {
      self.showErrorAlert(response);
    }
    console.log('response', response);
  }

  constructFormFromApi = async (data) => {
    const scheduledRides = await getScheduledRides(data);
    if (data.get_count) {
      return scheduledRides;
    }
    let formRows = {};
    for (var index in scheduledRides) {
      const row = await this.getFormRowFromScheduledRide(scheduledRides[index]);
      formRows[index] = row;
    }
    return formRows;
  };

  getFormRowFromScheduledRide = async (scheduledRide) => {
    let customerFirstName = scheduledRide.customer_first_name === "---" ? "" : scheduledRide.customer_first_name;
    let customerLastName = scheduledRide.customer_last_name === "---" ? "" : scheduledRide.customer_last_name;
    let row = {
      scheduled_datetime: utcStringToLocalString(scheduledRide.scheduled_datetime),
      onsite_datetime: utcStringToLocalString(scheduledRide.onsite_datetime),
      rider: scheduledRide.rider,
      ride_service_name: scheduledRide.ride_service_type_id,
      from_address: scheduledRide.from_address,
      to_address: scheduledRide.to_address,
      order_number: scheduledRide.order_number,
      customer_name: customerFirstName + " " + customerLastName,
      customer_phone: scheduledRide.customer_phone,
      status: SCHEDULED_RIDE_STATUS[scheduledRide.status_id],
      status_id: scheduledRide.status_id,
      rider_name: scheduledRide.rider_name,
      pick_up_company_name: scheduledRide.pick_up_company_name,
      tip_token_amount: scheduledRide.tip_token_amount,
      note: scheduledRide.note,
      total_order_amount: scheduledRide.total_order_amount,
      ride_request_id: scheduledRide.ride_request_id,
      tracking_id: scheduledRide.tracking_id
    };
    row["actions"] = this.getRowButtonsFromScheduledRide(scheduledRide);
    row["problem_flag"] = this.getProblemCheckComponentFormScheduledRide(scheduledRide);
    return row;
  };

  getProblemCheckComponentFormScheduledRide = (scheduledRide) => {
    if (scheduledRide.problem_flag) {
      return <span style={{ color: 'red' }}><Trans>SCHEDULED_RIDE_PROBLEM</Trans></span>
    } else {
      return <span style={{ color: 'green' }}><Trans>SCHEDULED_RIDE_CLEAR</Trans></span>
    }
  }

  getRowButtonsFromScheduledRide = (scheduledRide) => {
    let menuCancelButton = <MenuItem>
      <Button
        onClick={() => this.confirmAlert(scheduledRide, "cancel_ride")}
        bsSize="xsmall"
        bsStyle="danger"
      >
        <Trans>CANCEL</Trans>
      </Button>
    </MenuItem>


    let menuEditButton = null
    /*if (!scheduledRide.started) {
      menuEditButton = <MenuItem>
        <Button
          onClick={() => this.editRideAlert(scheduledRide)}
          bsSize="xsmall"
          bsStyle="warning"
        >
          <Trans>EDIT_RIDE</Trans>
        </Button>
      </MenuItem>
    }*/

    let menuRedispatchRide = null;
    let status_id = scheduledRide.status_id;
    if (status_id === 1 || status_id === 2) {
      menuRedispatchRide = <MenuItem>
        <Button
          onClick={() => this.confirmAlert(scheduledRide, "ride_dispatch")}
          bsSize="xsmall"
          bsStyle="warning"
        >
          <Trans>REDISPATCH_RIDE</Trans>
        </Button>
      </MenuItem>
    }

    let menuTrackRide = <MenuItem>
        <Button
            onClick={() => {
              var link = miaBaseLink + "?tracking_id=" + scheduledRide.tracking_id;
              window.open(link, "_blank")
            }}
            bsSize="xsmall"
            bsStyle="success"
          >
        <Trans>TRACK_RIDE</Trans>
        </Button>
      </MenuItem>

    return (
      <DropdownButton
        title={i18n.t("actions")}
        style={{ padding: "4%", margin: 0 }}
      >
        {menuCancelButton}
        {menuEditButton}
        {menuTrackRide}
      </DropdownButton>
    );
  };

  render() {
    return (
      <div className="content">
        {this.state.alert}
        <EditRidePopUp show={this.state.edit} cancel={this.toggleEditPopUp} ride={this.state.selectedRide} />
        <TableAPI
          fetchData={this.constructFormFromApi}
          pageSize={100}
          getTrProps={(state, rowInfo) => {
            return {
              onClick: (event) => {
                event.stopPropagation();
                !(event.target.className.includes('rt-expand'))   && this.showRideInfoAlert(rowInfo.original);
              },
            };
          }}
          columns={[
            {
              Header: <Trans>SCHEDULED_DATE</Trans>,
              canSort: true,
              defaultSort: false,
              id: "scheduled_datetime",
              orderType: "asc",
              accessor: "scheduled_datetime",
              width: 130,
              Cell: (e) => {
                return <span>{e.value}</span>;
              },
            },
            {
              Header: <Trans>ONSITE_DATE</Trans>,
              canSort: true,
              defaultSort: true,
              id: "onsite_datetime",
              accessor: "onsite_datetime",
              width: 130,
              Cell: (e) => {
                return <span>{e.value}</span>;
              },
            },
            {
              Header: <Trans>status</Trans>,
              id: "status",
              accessor: "status",
              canFilter: true,
              filterMode: "select",
              width: 150,
              options: [
                { value: 1, label: SCHEDULED_RIDE_STATUS[1] },
                { value: 2, label: SCHEDULED_RIDE_STATUS[2] },
                { value: 3, label: SCHEDULED_RIDE_STATUS[3] },
                { value: 4, label: SCHEDULED_RIDE_STATUS[4] },
                { value: 5, label: SCHEDULED_RIDE_STATUS[5] },
                { value: 6, label: SCHEDULED_RIDE_STATUS[6] },
              ],
            },
            {
              Header: <Trans>RIDE_SERVICE_NAME</Trans>,
              id: "ride_service_name",
              canFilter: true,
              filterMode: "select",
              width: 75,
              options: [{ value: "", label: <Trans>NONE</Trans> }].concat(
                  [0, 1, 2, 3, 4, 5, 6, 7].map(rideServiceType => {
                    return {
                      value: rideServiceType,
                      label: <Trans>{RideServiceNames[rideServiceType]}</Trans>
                    }
                })),
              accessor: "ride_service_name",
              Cell: (e) => (
                <img src={RIDE_SERVICE_TYPE_ICONS[e.value]}
                  style={{
                    margin: 'auto',
                    display: 'block'
                  }} />
              )
            },
            {
              Header: <Trans>RIDER</Trans>,
              id: "rider_name",
              canFilter: false,
              canSort: true,
              accessor: "rider_name",
              width: 125,
              Cell: (e) => (
                <b>{e.value}</b>
              ),
            },
            {
              Header: <Trans>FROM_ADDRESS</Trans>,
              id: "from_address",
              width: 150,
              canFilter: true,
              accessor: "from_address",
            },
            {
              Header: <Trans>TO_ADDRESS</Trans>,
              id: "to_address",
              width: 150,
              canFilter: true,
              accessor: "to_address",
            },
            {
              Header: <Trans>BUSINESS_NAME</Trans>,
              id: "pick_up_company_name",
              width: 100,
              canSort: true,
              canFilter: true,
              accessor: "pick_up_company_name",
              Cell: (e) => (
                <span>{e.row.pick_up_company_name}</span>
              ),
            },
            {
              Header: <Trans>ORDER_NUMBER</Trans>,
              id: "order_number",
              width: 145,
              canFilter: true,
              canSort: true,
              accessor: "order_number",
            },
            {
              Header: <Trans>CUSTOMER_NAME</Trans>,
              id: "customer_name",
              width: 125,
              canFilter: true,
              canSort: true,
              accessor: "customer_name",
            },
            {
              Header: <Trans>CUSTOMER_PHONE</Trans>,
              id: "customer_phone",
              width: 100,
              canFilter: true,
              accessor: "customer_phone",
            },
            {
              Header: <Trans>SCHEDULED_RIDE_HEALTHCHECK</Trans>,
              id: "problem_flag",
              width: 75,
              canFilter: false,
              accessor: "problem_flag",
            },
            {
              Header: <Trans>actions</Trans>,
              accessor: "actions",
              filterable: false,
              width: 75,
              Cell: (e) => (
                <span
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  {" "}
                  {e.value}{" "}
                </span>
              ),
              getProps: (state, rowInfo, column) => {
                return {
                  style: {
                    overflow: "visible",
                  },
                };
              },
            },
          ]}
          className="-highlight"
        />
      </div>
    );
  }
}

