import React from "react";
import { connect } from 'react-redux';
import { getUserZones, getCommunity } from "../helpers/apiHelper";
import { store } from "../store/index";
import { loadZones, updateCommunity } from "../actions/index";

class FetchCommunityStrategy extends React.Component{   

    componentDidMount() {
        this.start();
    }

    start = () => {
        this.fetchZones();
    }

    fetchZones = async () => {
        const zonesData = await getUserZones();
        store.dispatch(loadZones({
            selected: zonesData[0],
            available: zonesData
        }));
        const communityData = await getCommunity();
        store.dispatch(updateCommunity(communityData));
    };

    render () {
        return null;
    }
}
export default connect()(FetchCommunityStrategy);