import * as actionTypes from '../actions/action_types';


const initialState = {
    selected: {},
    available: []
};

const zoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_ZONES:
      /*if (Object.keys(state.selected).length === 0){
        return action.payload;
      } else {
        return state;
      }*/
      return Object.assign({}, state, {
        available: action.payload.available
      })
    case actionTypes.UPDATE_SELECT_ZONE:
      return Object.assign({}, state, {
        selected: state.available.filter(zone => zone.zone_id == action.payload.zoneId)[0]})
    default:
        return state; 
  };
}
  
  export default zoneReducer;