import * as actionTypes from '../actions/action_types';

const initialState = {
    isAuthenticated: false,
    authToken: null,
    communityAccount: null,
    firstName: null,
    lastName: null,
    userId: null,
    phone: null,
}; 

export default (state  = initialState, action) => {
    switch (action.type) {
        case actionTypes.ON_LOG_IN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                authToken: action.payload.authToken,
                phone: action.payload.phone,
                communityAccount: action.payload.communityAccount,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                userId: action.payload.userId
            };
        case actionTypes.LOGOUT:
            return Object.assign({}, initialState);
        default:
            return state; 
    }
};