const blockchainNetwork = process.env.REACT_APP_BASE_NODEOS_URL;
const blockchainDomain = process.env.REACT_APP_BASE_NODEOS_DOMAIN;
const apiPort = process.env.REACT_APP_BASE_NODEOS_PORT;
const contractName = process.env.REACT_APP_CONTRACT_ACCOUNT;
const tokenContractName = process.env.REACT_APP_TOKEN_CONTRACT_ACCOUNT;
const chainId = process.env.REACT_APP_CHAIN_ID;
const protocol = process.env.REACT_APP_PROTOCOL;
const apiAddress = process.env.REACT_APP_API_ADDRESS;
const tokenCommoditySymbol = process.env.REACT_APP_TOKEN_COMMODITY_SYMBOL;
const localTimeZone = process.env.REACT_APP_LOCAL_TIMEZONE;
const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;
const mapboxDirectionsApi = process.env.REACT_APP_MAPBOX_DIRECTIONS_URL;
const communityAccount = process.env.REACT_APP_COMMUNITY_ACCOUNT;
const worldAccount = process.env.REACT_APP_WORLD_ACCOUNT;
const miaBaseLink = process.env.REACT_APP_MIA_LINK;

module.exports = {
    apiAddress,
    blockchainNetwork,
    blockchainDomain,
    contractName,
    tokenContractName,
    apiPort,
    chainId,
    protocol,
    tokenCommoditySymbol,
    localTimeZone,
    mapboxToken,
    mapboxDirectionsApi,
    communityAccount,
    worldAccount,
    miaBaseLink
};