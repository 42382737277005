
import React from "react";

import { connect } from 'react-redux';

class FetchTokenTransferStrategy extends React.Component{   
    task_started = false
    
    componentWillUnmount() {
        clearInterval(this.task);
        this.task = null;
    }
    componentDidMount(){
        this.start();
    }

    start = () => {
        this.task_started = true;
        this.execute();
        this.task = setInterval(() => {
            this.execute();
        }, 60000 * 10);
    }

    execute = async () => {
        /*let token_transfers = [];
        const tokenTransfersList = await getTokenTransfers();
        store.dispatch(updateTokenTransfers({
            tokenTransfersList: tokenTransfersList,
        }));*/
    };
    

    render () {
        return null;
    }
}

const mapStateToProps = state => {
    return {};
  };
export default connect(mapStateToProps)(FetchTokenTransferStrategy);