import LoginPage from "../views/login/LoginPage";
/*
import RegisterPage from "../views/Pages/RegisterPage.jsx";
import LockScreenPage from "../views/Pages/LockScreenPage.jsx";
import RiderDetails from "../views/Riders/RiderDetails";
import RideDetails from "../views/Rides/RideDetails";
*/

var pagesRoutes = [
  {
    path: "/pages/login-page",
    name: "Login Page",
    mini: "LP",
    component: LoginPage
  },
 /*  {
    path: "/pages/register-page",
    name: "Register",
    mini: "RP",
    component: RegisterPage
  },
  {
    path: "/pages/lock-screen-page",
    name: "Lock Screen Page",
    mini: "LSP",
    component: LockScreenPage
  } */
];

export default pagesRoutes;
