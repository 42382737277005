import "./dashboardReport.css";

import { Button, Col, Grid, Row } from "react-bootstrap";
import React, { Component, useState } from "react";
import {
  getReport,
  getGroupDashboards
} from '../../helpers/apiHelper';

import CustomButton from "../../components/CustomButton/CustomButton";
import PrototypeCache from "../../static_cache/PrototypeCache";
import ReactDOM from 'react-dom';
import { apiAddress } from '../../variables/secretEnvVariables';
import queryString from "query-string";
import { store } from "../../store/index"

const $ = window.$
let storeState = null;
const API_VERSION_2 = "api/v2/";


export class dashboardReport extends Component {
  static displayName = dashboardReport.name;

  async componentDidMount() {
    //Force wait until script loaded - only load once
    if (!document.getElementById('revealbi_script')) {
      let promiseResolve, promiseReject;
      const promise = new Promise(function (resolve, reject) {
        promiseResolve = resolve;
        promiseReject = reject;
      });

      this.script = document.createElement('script');
      this.script.src = 'Reveal/infragistics.reveal.js';
      this.script.id = 'revealbi_script';
      document.body.appendChild(this.script);
      this.script.onload = () => {
        promiseResolve();
      };
      await promise

      //Cache added Array prototypes
      PrototypeCache.$type = Array.prototype.$type
      PrototypeCache.add = Array.prototype.add
      PrototypeCache.contains = Array.prototype.contains
      PrototypeCache.insert = Array.prototype.insert
      PrototypeCache.removeItem = Array.prototype.removeItem
      PrototypeCache.removeAt = Array.prototype.removeAt
      PrototypeCache.removeAllInList = Array.prototype.removeAllInList
      PrototypeCache.clear = Array.prototype.clear

    } else {
      //Load Array prototypes from cache
      Array.prototype.$type = PrototypeCache.$type
      Array.prototype.add = PrototypeCache.add
      Array.prototype.contains = PrototypeCache.contains
      Array.prototype.insert = PrototypeCache.insert
      Array.prototype.removeItem = PrototypeCache.removeItem
      Array.prototype.removeAt = PrototypeCache.removeAt
      Array.prototype.removeAllInList = PrototypeCache.removeAllInList
      Array.prototype.clear = PrototypeCache.clear
    }

    const params = queryString.parse(this.props.location.search);
    const group = params.group;
    storeState = store.getState();
    $.ig.RevealSdkSettings.setBaseUrl(apiAddress + API_VERSION_2 + 'analytics/');

    $.ig.RevealSdkSettings.setAdditionalHeadersProvider(function (url) {
      var headers = {};
      headers["Authorization"] = storeState.userData.authToken;
      return headers;
    });

    this.updateAvailableDashboards(group);
  }

  componentWillUnmount() {
    delete Array.prototype.$type
    delete Array.prototype.add
    delete Array.prototype.contains
    delete Array.prototype.insert
    delete Array.prototype.removeItem
    delete Array.prototype.removeAt
    delete Array.prototype.removeAllInList
    delete Array.prototype.clear
  }

  rendederAvailableDashboards(dashboardNames) {
    if (dashboardNames.length > 0) {
      this.loadDashboard(dashboardNames[0].reportId);
    }
    const listItems = dashboardNames.map((d) => {
      return <CustomButton key={d.reportId} onClick={(e) => this.loadDashboard(d.reportId, e)}>{d.name}</CustomButton>;
    });

    const element =
      <div>
        <h5 className="sidenavHeader"></h5>
        {listItems}
      </div>;

    ReactDOM.render(element, document.getElementById('dashboardList'));

  }

  async loadDashboard(dashboardId) {
    var dashboardFile = await getReport(dashboardId);
    $.ig.RVDashboard.loadDashboardFromContainer(dashboardFile, (res) => {
      const view = new $.ig.RevealView("#revealView");
      view.canEdit = false;
      view.canSaveAs = false;
      view.dashboard = res;
    }, (err) => {
      console.log("error", err)
    })

  }


  async updateAvailableDashboards(group) {
    var dashboards = await getGroupDashboards(group);
    this.rendederAvailableDashboards(dashboards);
  }

  render() {
    return (
      <div className="main-content">
        <div className="dashboard-report-container main-content">
          <div id="dashboards">
            <div id="dashboardList">
            </div>
            <div id="revealView" className="revealView" style={{ display: "block", position: "static", left: "0px !important" }}>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default dashboardReport;
