import { Button, Modal } from "react-bootstrap";
import { DEBT_MEMO, TOKEN_TRANSFER_MEMO } from '../../const/MemoReasons';
import React, { Component } from "react";

import ReactTable from "react-table";
import { Trans } from "react-i18next";
import { convertToFiat } from "../../helpers/paymentHelper";
import { getTokenTransfersOfAccount } from "../../helpers/apiHelper";
import {utcStringToLocalString} from '../../helpers/dateHelper';
import i18n from "../../i18n";

const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

class RiderTransactionsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMemoModal: false,
      memo: ""
    };
  }

  componentDidMount() {
    this.getTransactionHistory();
  }

  async getTransactionHistory() {
    getTokenTransfersOfAccount(this.props.account_name).then(data => {
      this.setState({ transactionHistory: data });
    });
  }

  componentDidUpdate = prevProps => {
    if (this.props.account_name !== prevProps.account_name) {
      this.setState(
        { transactionHistory: null, account_name: this.props.account_name },
        () => {
          this.getTransactionHistory();
        }
      );
    }
  };

  onClickAccountName = account_name => {
    this.props.history.push({
      pathname: "/rider",
      search: "?name=" + account_name
    });
  };

  onClickMemo = memo => {
    this.setState({
      memo,
      showMemoModal: true
    });
  };

  handleHideMemoModal = () => {
    this.setState({ memo: "", showMemoModal: false });
  };

  sortDates = (a, b, desc) => {
    if (new Date(a) > new Date(b)) {
      return 1;
    }
    if (new Date(a) < new Date(b)) {
      return -1;
    }
    return 0;
  };

  sortFiat = (a, b, desc) => {
    b = parseFloat(b.substr(0,b.length));
    a = parseFloat(a.substr(0,a.length));
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  }

  sortToken = (a, b, desc) => {
    a = parseFloat(a.substr(0,a.indexOf(' ')));
    b = parseFloat(b.substr(0,b.indexOf(' ')));
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  }

  createModalBody = () => {
    let reason = this.getMemoReasonLabel(this.state.memo);

    let memo;
    let newFormat;

    try {
     memo = JSON.parse(this.state.memo);
     newFormat = true;
    } catch (ex) {
      memo = this.state.memo;
      newFormat = false;
    }

    return (newFormat) ? (
      <div>
        <Trans>TYPE</Trans>: {memo.type} <br />
        <Trans>action</Trans>: {memo.action} <br />
        <Trans>Community</Trans>: {memo.community} <br />
        <Trans>REASON</Trans>: {reason}
      </div>
    ) : <div>{memo}</div>;
  }

  getMemoReasonLabel = (memo) => {
    try {
      const jsonMemo = JSON.parse(memo); //New format
      let reasonLabel = '';
      for (let i in DEBT_MEMO) {
        if (DEBT_MEMO[i].value===jsonMemo.data.reason) reasonLabel = DEBT_MEMO[i].label;
      }
      for (let i in TOKEN_TRANSFER_MEMO) {
        if (TOKEN_TRANSFER_MEMO[i].value===jsonMemo.data.reason) reasonLabel = TOKEN_TRANSFER_MEMO[i].label;
      }
      return (reasonLabel) ? reasonLabel : (jsonMemo.data.reason) ? jsonMemo.data.reason : memo; // NEW : NEW CUSTOM : OLD
    } catch (ex) {
      return memo; //Backward compatibility
    }
  }

  render() {
   
    const modalBody = this.createModalBody();

    return this.state.transactionHistory ? (
      <div>
        <Modal
          show={this.state.showMemoModal}
          onHide={this.handleHideMemoModal}
        >
          <Modal.Header closeButton>
            <Modal.Title><Trans>MEMO_DETAILS</Trans></Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>{modalBody}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleHideMemoModal}>
              <Trans>CLOSE</Trans>
            </Button>
          </Modal.Footer>
        </Modal>
        <ReactTable
         previousText={i18n.t('PREVIOUS')} nextText={i18n.t('NEXT')} loadingText={i18n.t('LOADING')}
          pageSizeOptions= {[5, 10, 20, 25, 50, 100, 1000]}
          data={this.state.transactionHistory}
          columns={[
            {
              Header: <Trans>INFO_TABLE_FROM</Trans>,
              id: "from_account",
              accessor: el => {
                return el.from_account;
              },
              Cell: e => (
                <span style={{ color: "#70CEEC", cursor: 'pointer'}}
                  onClick={() => {
                    this.onClickAccountName(e.original.from_account);
                  }}
                >
                  {e.value}
                </span>
              ),
              minWidth: 170
            },
            {
              Header: <Trans>INFO_TABLE_TO</Trans>,
              id: "to_account",
              accessor: el => {
                return el.to_account;
              },
              Cell: e => (
                <span style={{ color: "#70CEEC", cursor: 'pointer' }}
                  onClick={() => {
                    this.onClickAccountName(e.original.to_account);
                  }}
                >
                  {e.value}
                </span>
              ),
              minWidth: 170
            },
            {
              Header: <Trans>Amount</Trans>,
              id: "fiat_amount",
              accessor: el => {
                return convertToFiat(el.quantity.split(' ')[0], el.block_time);
              },
              sortMethod: this.sortFiat,
              minWidth: 100
            },
            {
              Header: <Trans>Eva Tokens</Trans>,
              id: "eva_tokens",
              accessor: el => {
                return el.quantity;
              },
              sortMethod: this.sortToken,
              minWidth: 200
            },
            {
              Header: <Trans>MEMO</Trans>,
              id: "memo",
              accessor: el => {
                 return this.getMemoReasonLabel(el.memo);
              },
              Cell: e => (
                <span style={{ color: "#70CEEC", cursor: 'pointer' }}
                  onClick={() => {
                    this.onClickMemo(e.original.memo);
                  }}
                >
                  {e.value}
                </span>
              ),
              minWidth: 300
            },
            {
              Header: <Trans>Payment Date</Trans>,
              id: "block_time",
              accessor: el => {
                return utcStringToLocalString(el.block_time);
              },
              sortMethod: this.sortDates,
              minWidth: 130
            }
          ]}
          defaultSorted={[
            {
              id: "block_time",
              desc: !isSafari

            }
          ]}
          defaultPageSize={10}
        />
      </div>
    ) : (
      <div className="text-center">
        <h1 className="text-center">
          <i
            className="fa fa-refresh fa-spin"
            style={{
              lineHeight: "200px",
              width: "200px",
              fontSize: "100px"
            }}
          ></i>
        </h1>
      </div>
    );
  }
}

export default RiderTransactionsTable;
