import { createStore } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from '../reducers/index';

const persistConfig = {
    key: 'comm',
    storage,
    blackList: ['payments', 'tokenTransfer', 'userData', 'dashboard']
  };
  
const persistedReducer = persistReducer(persistConfig,rootReducer);
  
//export default () => {
export const store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
export const persistor = persistStore(store);
//    return { store, persistor };
//  };
