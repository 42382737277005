import React from 'react';
import "./TabHead.css";

function TabHead(props) { 
  const selectedClass = props.selected? "tab-selected" : "";
  return (
    <div className="ana-tab-head" onClick={() => props.onClick(props.id)}>
      <div className={selectedClass}>
        <p>{props.name}</p>
      </div>
      {props.selected && <div className="bottom-line"></div>} 
    </div>
  );
}

export default TabHead;