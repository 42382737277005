import React, { Component } from "react";
import { getConfirmationAlert, getSuccessAlert, getErrorAlert } from "../../helpers/alertHelper";
import { unStickForceMerge } from "../../helpers/apiHelper";

class FixStuckMerge extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      alert: null
    };

    this.fixStuckForceMerge = this.fixStuckForceMerge.bind(this);
    this.successHandlerAlert = this.successHandlerAlert.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.errorHandlerAlert = this.errorHandlerAlert.bind(this);
  }

  successHandlerAlert = () => {
    this.setState({
      alert: getSuccessAlert(() => this.hideAlert()),
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  }

  errorHandlerAlert = (error, msg) => {
    this.setState({
      alert: getErrorAlert(() => this.hideAlert(), error, msg),
    });
  };

  fixStuckForceMerge = () => {
    this.setState({
      alert: getConfirmationAlert(async () => {
        const success = await unStickForceMerge();
        if (success) {
          this.successHandlerAlert();
        } else {
          this.errorHandlerAlert();
        }
      }, this.hideAlert, "Are you sure?", "Please be patient, sometimes a merge can take 2-5 minutes.")
    });
  }

  render() {
    return (
      <>
        {this.state && this.state.alert}
        <div>
          <button onClick={this.fixStuckForceMerge}>FIX FORCE MERGE STUCK</button>
        </div>
      </>);
  }
}

export default FixStuckMerge;