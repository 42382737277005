import React, { Component } from "react";

import TableAPI from "../Tables/TableAPI";
import { Trans } from "react-i18next";
import {
  getCanceledDriverRequest,
} from "../../helpers/apiHelper";
import { utcStringToLocalString } from "../../helpers/dateHelper";

export default class DriverCancellationList extends Component {
  constructor(props) {
    super(props);
  }


  constructFormFromApi = async (data) => {
    const canceledRequests = await getCanceledDriverRequest(data);
    if (data.get_count) {
      return canceledRequests;
    }
    let formRows = {};
    for (var index in canceledRequests) {
      const row = await this.getFormRowFromDriverCancelEntry(canceledRequests[index]);
      formRows[index] = row;
    }
    return formRows;
  };

  showRide = (row) => {
    window.open(`#/ride-request-summary/?reqId=${row.ride_request_id}`);
  }

  getFormRowFromDriverCancelEntry = async (entry) => {
    var moment = require('moment-timezone');
    let accepted = moment(entry.accepted_block_time);
    let canceled = moment(entry.canceled_block_time);
    var duration = moment.duration(canceled.diff(accepted));
    let minutes = duration.asMinutes();
    let row = {
      'driver': entry.driver,
      'accepted_block_time': utcStringToLocalString(entry.accepted_block_time),
      'canceled_block_time': utcStringToLocalString(entry.canceled_block_time),
      'estimated_pickup_distance': entry.estimated_pickup_distance,
      'estimated_pickup_time': entry.estimated_pickup_time,
      'ride_request_id': entry.ride_request_id,
      'delta_time': parseInt(minutes),
      'driver_name': entry.driver_name
    }
    return row;
  };

  render() {
    return (
      <div className="content">
        <TableAPI
          fetchData={this.constructFormFromApi}
          getTrProps={(state, rowInfo) => {
            return {
              onClick: () => {
                this.showRide(rowInfo.original);
              },
            };
          }}
          columns={[
            {
              Header: <Trans>DRIVER</Trans>,
              canSort: true,
              canFilter: true,
              id: "driver",
              orderType: "",
              accessor: "driver",
              width: 140,
              Cell: (e) => {
                return <span>{e.value}</span>;
              },
            },
            {
              Header: <Trans>NAME</Trans>,
              canSort: true,
              canFilter: true,
              id: "driver_name",
              orderType: "",
              accessor: "driver_name",
              width: 140,
              Cell: (e) => {
                return <span>{e.value}</span>;
              },
            },
            {
              Header: <Trans>ACCEPTED_BLOCK_TIME</Trans>,
              canSort: true,
              defaultSort: true,
              id: "accepted_block_time",
              orderType: "desc",
              accessor: "accepted_block_time",
              width: 140,
              Cell: (e) => {
                return <span>{e.value}</span>;
              },
            },
            {
              Header: <Trans>CANCELED_BLOCK_TIME</Trans>,
              canSort: true,
              canFilter: false,
              filterMode: "date",
              id: "canceled_block_time",
              orderType: "desc",
              accessor: "canceled_block_time",
              width: 140,
              Cell: (e) => {
                return <span>{e.value}</span>;
              },
            },
            {
              Header: <Trans>DELTA_TIME_CANCELLED</Trans>,
              canSort: false,
              id: "delta_time",
              orderType: "desc",
              accessor: "delta_time",
              width: 140,
              Cell: (e) => {
                return <span>{e.value}</span>;
              },
            },
            {
              Header: <Trans>ESTIMATED_PICKUP_TIME</Trans>,
              id: "estimated_pickup_time",
              accessor: "estimated_pickup_time",
              width: 140,
              Cell: (e) => {
                return <span>{e.value}</span>;
              },
            },
          ]}
          className="-highlight"
        />
      </div>
    );
  }
}

