import {
  Button,
  DropdownButton,
  MenuItem,
} from "react-bootstrap";
import React, { Component } from "react";
import {
  cancelActiveRide,
  endActiveRide,
  getActiveRides,
  startActiveRide,
  incrementStepActiveRide,
  redispatchActiveRide
} from "../../helpers/apiHelper";

import "./StepsTable.css"

import CancelIcon from "../../assets/img/cancel.svg";
import DeliveryIcon from "../../assets/img/bag.svg";
import OkIcon from "../../assets/img/ok.svg";
import RideServiceNames from "../../const/RideServiceNames";
import RidesharingIcon from "../../assets/img/car.svg";
import CannabisIcon from "../../assets/img/cannabis.svg";
import BulkIcon from "../../assets/img/bag.svg"; //TODO update with Truck icon
import RightArrowIcon from "../../assets/img/arrow-right.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import TableAPI from "../Tables/TableAPI";
import { Trans } from "react-i18next";
import driverFlag from "../../assets/img/driver_flag.png";
import driverFlagYellow from "../../assets/img/driver_flag_yellow.png";
import i18n from "i18next";
import { utcStringToLocalString, getDifferenceInSeconds, isoToDateTime } from "../../helpers/dateHelper";
import { miaBaseLink } from "../../variables/secretEnvVariables";
import { Link } from "react-router-dom";
import StepperSubTable from "../Tables/StepperSubTable";


const RIDE_STATUS_SHORT = {
  "PICKUP": "PICKUP",
  "DESTINATION": "DESTINATION",
  "PROOF_OF_CUSTOMER": "PROOF_OF_CUSTOMER",
  "GO_TO_DESTINATION": "GO_TO_DESTINATION",
  "PROOF_OF_ADDRESS": "PROOF_OF_ADDRESS",
  "GO_BACK_TO_STORE": "GO_BACK_TO_STORE"
}

const RIDE_SERVICE_TYPE_ICONS = {
  1: RidesharingIcon,
  2: DeliveryIcon,
  3: CannabisIcon,
  4: DeliveryIcon,
  5: DeliveryIcon,
  100: BulkIcon,
  100: DeliveryIcon,
  100: DeliveryIcon,
  100: RidesharingIcon,
  100: RidesharingIcon,
  100: RidesharingIcon,
  100: DeliveryIcon,
  100: RidesharingIcon,
  100: BulkIcon
}

export default class ActiveRidesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      isAlertLoading: false,
    };
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  hideAfterconfirmAlert() {
    this.setState({ alert: null });
  }

  showSuccessAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Confirmation"
          onConfirm={() => this.hideAfterconfirmAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
        >
          <p>
            <Trans>ACTION_SUCCESS</Trans>
          </p>
        </SweetAlert>
      ),
    });
  };


  getTrackingLinkComponent = (tracking_ids) => {
    if (!tracking_ids) return;
    return (
        <>
            {tracking_ids.map(id => {
                return (
                      <MenuItem>
                      <Button
                        style={{ padding: "1%", margin: 0, height: 20 }}
                        onClick={() => {
                          var link = miaBaseLink + "?tracking_id=" + id;
                          window.open(link, "_blank")
                        }}
                        bsSize="xsmall"
                        bsStyle="success"
                      >
                        <Trans>TRACK_RIDE</Trans>
                      </Button>
                    </MenuItem>
                );
            })}
        </>
    );
}

  showRideInfoAlert = (row) => {
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          onConfirm={() => this.hideAfterconfirmAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          allowEscape={true}
          title=""
        >
          <h5>#{row.chain_route_id}</h5>
          <div style={{ textAlign: "center" }}>
            <div style={{ width: "60%", display: "inline-block" }}>
              <p style={{ textAlign: "left" }}>
                <b>
                  <Trans>DRIVER_EDIT_DATE</Trans>
                </b>
                : {row.block_time} <br />
                <b>
                  <Trans>FROM_ACCOUNT</Trans>
                </b>
                : {row.from_address} <br />
                <b>
                  <Trans>TO_ACCOUNT</Trans>
                </b>{" "}
                : {row.to_address} <br />
                <b>
                  <Trans>RIDE_REQUEST</Trans>
                </b>
                : <Link onClick={(e) => e.stopPropagation()} to={`/ride-request-summary?reqId=${row.ride_request_id}`} target="_blank" rel="noopener noreferrer">
                  {row.ride_request_id}
                </Link> <br />
                <b>
                  <Trans>DRIVER</Trans>
                </b>
                : {row.driver_name} <br />
                <b>
                  <Trans>RIDE_SERVICE_NAME</Trans>
                </b>
                : {i18n.t(RideServiceNames[row.ride_service_name])} <br />
                <b>
                  <Trans>RIDER</Trans>
                </b>
                : {row.rider_name}  <br />
                <b>
                  <Trans>TRACKING_LINK</Trans>
                </b>
                : {row.tracking_link} <br />
              </p>
            </div>
          </div>
        </SweetAlert>
      ),
    });
  };

  showErrorAlert = (error) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{
            display: "block",
            marginTop: "-100px",
          }}
          title="Error"
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          <p>
            An Error has occurred : <br></br>
            {error.message}{" "}
          </p>
        </SweetAlert>
      ),
    });
  };

  confirmAlert = (activeRide, action) => {
    let title, onConfirm, onCancel, confirmBtnText, cancelBtnText, paragraph;

    switch (action) {
      case "ride_end":
        title = <Trans>END_ACTIVE_RIDE_ALERT_TITLE</Trans>;
        onConfirm = () => this.endRide(activeRide);
        onCancel = () => this.hideAlert();
        confirmBtnText =
          this.state.alert === null ? (
            <Trans>END_ACTIVE_RIDE_ALERT_CONFIRM</Trans>
          ) : (
            <Trans>ALERT_LOADING</Trans>
          );
        cancelBtnText = <Trans>CANCEL</Trans>;
        paragraph = (
          <p>
            <Trans>END_ACTIVE_RIDE_ALERT_BODY</Trans>
            <b>{activeRide.chain_route_id}.</b>
            <br></br>
            <Trans>WANT_TO_PROCEED</Trans>
          </p>
        );
        break;
      case "ride_start":
        title = <Trans>START_ACTIVE_RIDE_ALERT_TITLE</Trans>;
        onConfirm = () =>
          this.startRide(activeRide.rider_ride_uuids, activeRide.dactiveride_and_driver_request_uuid);
        onCancel = () => this.hideAlert();
        confirmBtnText =
          this.state.alert === null ? (
            <Trans>START_ACTIVE_RIDE_ALERT_CONFIRM</Trans>
          ) : (
            <Trans>ALERT_LOADING</Trans>
          );
        cancelBtnText = <Trans>CANCEL</Trans>;
        paragraph = (
          <p>
            <Trans>START_ACTIVE_RIDE_ALERT_BODY</Trans>
            <b>{activeRide.chain_route_id}.</b>
            <br></br>
            <Trans>WANT_TO_PROCEED</Trans>
          </p>
        );
        break;
        case "ride_redispatch":
          title = <Trans>REDISPATCH_ACTIVE_RIDE_ALERT_TITLE</Trans>;
          onConfirm = () =>
            this.redispatchActiveRide(activeRide.dactiveride_and_driver_request_uuid);
          onCancel = () => this.hideAlert();
          confirmBtnText =
            this.state.alert === null ? (
              <Trans>REDISPATCH_ACTIVE_RIDE_ALERT_TITLE</Trans>
            ) : (
              <Trans>ALERT_LOADING</Trans>
            );
          cancelBtnText = <Trans>CANCEL</Trans>;
          paragraph = (
            <p>
              <Trans>REDISPATCH_ACTIVE_RIDE_ALERT_BODY</Trans>
              <b>{activeRide.chain_route_id}.</b>
              <br></br>
              <Trans>WANT_TO_PROCEED</Trans>
            </p>
          );
          break;
      case "increment_step":
        title = <Trans>INCREMENT_RIDE_ALERT_TITLE</Trans>;
        onConfirm = () =>
          this.incrementStep(activeRide.dactiveride_and_driver_request_uuid);
        onCancel = () => this.hideAlert();
        confirmBtnText =
          this.state.alert === null ? (
            <Trans>INCREMENT_RIDE_ALERT_CONFIRM</Trans>
          ) : (
            <Trans>ALERT_LOADING</Trans>
          );
        cancelBtnText = <Trans>CANCEL</Trans>;
        paragraph = (
          <p>
            <Trans>INCREMENT_RIDE_ALERT_BODY</Trans>
            <b>{activeRide.dactiveride_and_driver_request_uuid}.</b>
            <br></br>
            <Trans>WANT_TO_PROCEED</Trans>
          </p>
        );
        break;
      default:
        console.error("action", action);
    }
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{
            display: "block",
            marginTop: "-100px",
          }}
          title={title}
          onConfirm={onConfirm}
          onCancel={onCancel}
          confirmBtnBsStyle="warning"
          cancelBtnBsStyle="danger"
          confirmBtnText={confirmBtnText}
          cancelBtnText={cancelBtnText}
          showCancel="true"
          showLoaderOnConfirm="true"
        >
          {paragraph}
        </SweetAlert>
      ),
    });
  };

  cancelRide = async (routeId) => {
    const self = this;
    cancelActiveRide(routeId).then(function (response) {
      if (response.status === 200) {
        self.showSuccessAlert();
      } else {
        self.showErrorAlert(response);
      }
    });
  };

  redispatchActiveRide = async (dactiveride_and_driver_request_uuid) => {
    const self = this;
    redispatchActiveRide(dactiveride_and_driver_request_uuid).then(function (response) {
      if (response.status === 200) {
        self.showSuccessAlert();
      } else {
        self.showErrorAlert(response);
      }
    });
  };

  startRide = async (rider_ride_uuids, dactiveride_and_driver_request_uuid) => {
    const self = this;
    startActiveRide(rider_ride_uuids, dactiveride_and_driver_request_uuid).then(function (response) {
      if (response.status === 200) {
        self.showSuccessAlert();
      } else {
        self.showErrorAlert(response);
      }
    });
  };

  incrementStep = async (dactiveride_and_driver_request_uuid) => {
    const self = this;
    incrementStepActiveRide(dactiveride_and_driver_request_uuid).then(function (response) {
      if (response.status === 200) {
        self.showSuccessAlert();
      } else {
        self.showErrorAlert(response);
      }
    });
  };

  endRide = (context) => {
    console.log('context', context);
    // Destination step type only has one ride, always.
    const ride_uuid = context.rider_ride_uuids[0];
    const route_uuid = context.dactiveride_and_driver_request_uuid;
    const self = this;
    endActiveRide(route_uuid).then(function (response) {
      if (response.status === 200) {
        self.showSuccessAlert();
      } else {
        self.showErrorAlert(response);
      }
    });
  };

  constructFormFromApi = async (data) => {
    const activeRides = await getActiveRides(data);
    if (data.get_count) {
      return activeRides;
    }
    let formRows = {};
    for (var index in activeRides) {
      const row = await this.getFormRowFromActiveRide(activeRides[index]);
      formRows[index] = row;
    }
    return formRows;
  };

  getFormRowFromActiveRide = async (activeRide) => {
    console.log('activeRide', activeRide);

    let driver_onsite = null;

    if (activeRide.is_multi_driver_route == false){
      driver_onsite = <img src={CancelIcon} title={i18n.t("DRIVER_NOT_ONSITE")} />;
      if (activeRide.driver_onsite_time) {
        driver_onsite = <img src={OkIcon} title={i18n.t("DRIVER_ONSITE_ARRIVED")} />;
      }
    }
    console.log('activeRide', activeRide);
    let current_step_estimated_time = parseInt(activeRide.current_step.estimated_time_taken_seconds/60);
    let current_step_elapsed_time = parseInt(activeRide.current_step.real_time_taken_seconds/60);
    let driver_late_on_step = <img src={OkIcon} title={i18n.t("DRIVER_ONSITE_ARRIVED")} />;
    if (current_step_elapsed_time/current_step_estimated_time > 1.3){
      driver_late_on_step = <img src={CancelIcon} title={i18n.t("DRIVER_NOT_ONSITE")} />;
    }

    let row = {
      'step_type': activeRide.current_step.step_type,
      'block_time': utcStringToLocalString(activeRide.block_time),
      'driver_name': activeRide.driver_name,
      'driver_phone': activeRide.driver.phone,
      'driver_account': activeRide.driver_account,
      'is_driver_flagged': activeRide.is_driver_flagged,
      'driver_onsite_time': driver_onsite,
      'is_driver_late_on_step': driver_late_on_step,
      'ride_service_type_id': activeRide.ride_service_type_id,
      'rider_name': activeRide.rider_name,
      'rider_account': activeRide.rider_account,
      'is_rider_verified': activeRide.is_rider_verified,
      'from_address': activeRide.from_address,
      'to_address': activeRide.to_address,
      'current_step_estimated_time': current_step_estimated_time,
      'current_step_elapsed_time': current_step_elapsed_time,
      'ride': activeRide
    }
    row["actions"] = this.getRowButtonsFromActiveRide(activeRide);
    return row
  };


  getStepperSubRows = (rowInfo) => {
    return <StepperSubTable data={rowInfo}/>;
  };

  getRowButtonsFromActiveRide = (activeRide) => {
    const redispatchBtn = (
      activeRide.current_step.step_type ==  "PICKUP" ? (
        <MenuItem>
          <Button
            onClick={() => this.confirmAlert(activeRide, "ride_redispatch")}
            bsSize="xsmall"
            bsStyle="warning"
            style={{margin:"0px"}}
          >
            <Trans>DISPATCH_TO_ANOTHER_DRIVER</Trans>
          </Button>
        </MenuItem>
      ) : (
        <span></span>
      ));
    
    const startRideBtn =
      activeRide.current_step.step_type ==  "PICKUP" ? (
        <MenuItem>
          <Button
            onClick={() => this.confirmAlert(activeRide, "ride_start")}
            bsSize="xsmall"
            bsStyle="success"
            style={{margin:"0px"}}
          >
            <Trans>START_RIDE</Trans>
          </Button>
        </MenuItem>
      ) : (
        <span></span>
      );

    const getTrackingLinks =  this.getTrackingLinkComponent(activeRide.tracking_ids);

    const endRideBtn =
      ((activeRide.current_step.step_type !=  "DESTINATION") && (activeRide.current_step.step_type !=  "GO_BACK_TO_STORE") && (activeRide.current_step.step_type != "PROOF_OF_CUSTOMER")) ? '' :
        <MenuItem>
          <Button
            onClick={() => this.confirmAlert(activeRide, "ride_end")}
            bsSize="xsmall"
            bsStyle="danger"
            style={{margin:"0px"}}
          >
            <Trans>END_RIDE</Trans>
          </Button>
        </MenuItem>

    const next_step_button =
      ((activeRide.current_step.step_type != "PROOF_OF_ADDRESS") && (activeRide.current_step.step_type != "GO_TO_DESTINATION")) ? '' :
        <MenuItem>
          <Button
            onClick={() => this.confirmAlert(activeRide, "increment_step")}
            bsSize="xsmall"
            bsStyle="danger"
          >
            <Trans>INCREMENT_STEP</Trans>
          </Button>
        </MenuItem>

    return (
      <DropdownButton
        title={i18n.t("actions")}
        style={{ padding: "4%", margin: 0 }}
        pullRight
      >
        {startRideBtn}
        {endRideBtn}
        {next_step_button}
        {getTrackingLinks}
        {redispatchBtn}
      </DropdownButton>
    );
  };

  render() {
    return (
      <div className="content">
        {this.state.alert}
        <TableAPI
          fetchData={this.constructFormFromApi}
          topDistance={20}
          onSubClick={rowInfo => <StepperSubTable data={rowInfo}/>}
          hasSubComponents={true}
          pageSize={100}
          getTrProps={(state, rowInfo) => {
            return {
              onClick: (event) => {
                event.stopPropagation();
                !(event.target.className.includes('rt-expand'))   && this.showRideInfoAlert(rowInfo.original);
              },
            };
          }}
          columns={[
            {
              Header: <Trans>DATE</Trans>,
              canSort: true,
              defaultSort: true,
              id: "block_time",
              accessor: "block_time",
              width: 140,
              Cell: (e) => {
                return <span>{e.value}</span>;
              },
            },
            {
              Header: <span>STEP</span>,
              id: "step_type",
              canFilter: false,
              filterMode: "select",
              width: 110,
              options: [
                { value: "PICKUP", label: RIDE_STATUS_SHORT["PICKUP"] },
                { value: "DESTINATION", label: RIDE_STATUS_SHORT["DESTINATION"] },
                { value: "PROOF_OF_ADDRESS", label: RIDE_STATUS_SHORT["PROOF_OF_ADDRESS"] },
                { value: "GO_TO_DESTINATION", label: RIDE_STATUS_SHORT["GO_TO_DESTINATION"] },
                { value: "PROOF_OF_CUSTOMER", label: RIDE_STATUS_SHORT["PROOF_OF_CUSTOMER"] },
              ],
              accessor: "step_type",
              Cell: (e) => (
                <span style={{ backgroundColor: e.value === "PICKUP" ? "#ffeba1" : "lightgreen", borderRadius: 50, padding: 5, display: "flex" }}>
                  <img src={RightArrowIcon} style={{ width: 18, height: 18, marginTop: "auto", marginBottom: "auto" }} />
                  {i18n.t(RIDE_STATUS_SHORT[e.value])}
                </span>
              )
            },
            {
              Header: <Trans>is_driver_onsite</Trans>,
              id: "driver_onsite_time",
              accessor: "driver_onsite_time",
              width: 40,
              accessor: "driver_onsite_time",
            },
            {
              Header: <Trans>is_driver_late</Trans>,
              id: "is_driver_late",
              accessor: "is_driver_late_on_step",
              width: 40,
              accessor: "is_driver_late_on_step",
            },
            {
              Header: <Trans>RIDE_SERVICE_NAME</Trans>,
              id: "ride_service_type_id",
              filterMode: "select",
              width: 75,
              options: [{ value: "", label: <Trans>MULTI</Trans> }].concat(
                [0, 1, 2, 3].map(rideServiceType => {
                  return {
                    value: rideServiceType,
                    label: <Trans>{RideServiceNames[rideServiceType]}</Trans>
                  }
              })),
              accessor: "ride_service_type_id",
              Cell: (e) => (
                <img src={RIDE_SERVICE_TYPE_ICONS[e.value]}
                  style={{
                    margin: 'auto',
                    display: 'block'
                  }} />
              )
            },
            {
              Header: <Trans>CURRENT_STEP_EST_TIME</Trans>,
              id: "current_step_estimated_time",
              accessor: "current_step_estimated_time",
              width: 40,
              accessor: "current_step_estimated_time",
            },
            {
              Header: <Trans>CURRENT_STEP_REAL_TIME</Trans>,
              id: "current_step_elapsed_time",
              accessor: "current_step_elapsed_time",
              width: 40,
              accessor: "current_step_elapsed_time",
            },
            {
              Header: <Trans>DRIVER</Trans>,
              id: "driver_name",
              accessor: "driver_name",
              canSort: true,
              filter: (filter, row) => {
                return row.driver_name.includes(filter.value);
              },
              sortType:'basic',
              width: 120,
              Cell: (e) => {return (
                <p>
                  {e.original.is_driver_flagged ? <img src={driverFlag} height="25px" width="25px" style={{ cursor: 'pointer' }} onClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                      alert: (
                        <SweetAlert
                          style={{ display: "block", marginTop: "-100px" }}
                          onConfirm={() => this.hideAfterconfirmAlert()}
                          onCancel={() => this.hideAlert()}
                          confirmBtnBsStyle="success"
                          allowEscape={true}
                          title=""
                        >
                          <Trans>FLAGGED_DRIVER_DESC</Trans>
                        </SweetAlert>
                      ),
                    });
                  }} /> : null}
                  <Link
                    onClick={(e) => e.stopPropagation()}
                    to={`/drivers/submissions/${e.original.driver_phone}`}
                    style={{ color: "#70CEEC", cursor: "pointer" }}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {e.value}
                  </Link>
                </p>
              )},
            },
            {
              Header: <Trans>RIDER</Trans>,
              id: "rider_name",
              accessor: "rider_name",
              width: 120,
              canSort: true,
              Cell: (e) => {
                let showFlag = false;
                let flagImg = null;
                let flagDesc = "";

                if (e.original.is_rider_verified === null || e.original.is_rider_verified === false) {
                  showFlag = true;
                  flagDesc = i18n.t('NOT_VERIFIED_RIDER_DESC');
                  flagImg = driverFlagYellow;
                }

                return (<div>
                  {showFlag && <img src={flagImg} height="25px" width="25px" style={{ cursor: 'pointer' }} onClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                      alert: (
                        <SweetAlert
                          style={{ display: "block", marginTop: "-100px" }}
                          onConfirm={() => this.hideAfterconfirmAlert()}
                          onCancel={() => this.hideAlert()}
                          confirmBtnBsStyle="success"
                          allowEscape={true}
                          title=""
                        >
                          {flagDesc}
                        </SweetAlert>
                      ),
                    });
                  }} />}
                  <b>{e.value}</b>
                </div>)
              }
            },
            {
              Header: <Trans>actions</Trans>,
              accessor: "actions",
              filterable: false,
              canFilter: false,
              resizable: false,
              width: 75,
              Cell: (e) => (
                <span
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  {" "}
                  {e.value}{" "}
                </span>
              ),
              getProps: (state, rowInfo, column) => {
                return {
                  style: {
                    overflow: "visible",
                  },
                };
              },
            }
          ]}
          className="-highlight"
        />
      </div>
    );
  }
}

