import { Component } from "react";
import React from "react";
import RideRequestStatus from "../../const/RideRequestStatus";
import { Trans } from "react-i18next";
import { utcStringToLocalString } from "../../helpers/dateHelper";

class RiderRideRequestsTable extends Component {
  constructor(props) {
    super(props);
    this.increaseCounter = this.increaseCounter.bind(this);
  }

  state = {
    counter: 20,
  };

  showDetails = (reqId) => {
    this.props.history.push({
      pathname: "ride-request-details",
      search: "?reqId=" + reqId,
      state: {
        name: reqId,
      },
    });
  };

  increaseCounter = () => {
    this.setState({
      counter: this.state.counter + 20,
    });
  };

  /**
   * This function will return the style for the value of a status
   * in the rider ride request table
   */
  getStyleFromStatus = (status) => {
    if (status) {
      if (status === RideRequestStatus["SUCCESS"]) {
        return {
          padding: "10px",
          cursor: "pointer",
          color: "green",
        };
      } else if (status === RideRequestStatus["LIVE"]) {
        return {
          padding: "10px",
          cursor: "pointer",
          color: "orange",
        };
      } else {
        return {
          padding: "10px",
          cursor: "pointer",
          className: "-highlight",
          color: "red",
        };
      }
    } else {
      return {
        padding: "10px",
        cursor: "pointer",
        className: "-highlight",
        color: "red",
      };
    }
  };

  render() {
    const rideRequests = this.props.rideRequests;

    return (
      <div className="card">
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th>
                  <h4>
                    <Trans>DATE</Trans>
                  </h4>
                </th>
                <th>
                  <h4>
                    <Trans>STATUS</Trans>
                  </h4>
                </th>
              </tr>
            </thead>
            <tbody>
              {[]
                .concat(rideRequests)
                .sort(
                  (a, b) =>
                    new Date(a.block_time).getTime() <
                    new Date(b.block_time).getTime()
                )
                .map((request, i) => {
                  if (i > this.state.counter) {
                    return null;
                  }

                  return i < this.state.counter ? (
                    <tr
                      onClick={() => this.showDetails(request.ride_request_id)}
                    >
                      <td
                        style={{
                          padding: "10px",
                        }}
                      >
                        {utcStringToLocalString(request.block_time)}
                      </td>
                      <td style={this.getStyleFromStatus(request.status)}>
                        <Trans>{request.status}</Trans>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td></td>
                      <td onClick={() => this.increaseCounter()}>
                        <i style={{ cursor: "pointer" }}>
                          <Trans>LOAD_MORE</Trans>
                        </i>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default RiderRideRequestsTable;

