import React, { useState, useCallback, createContext } from 'react';
import { Trans } from "react-i18next";
import { saveDriverSubmission, getRiderNotDriverByPhone, getNewDriverSubmission } from "../../../helpers/apiHelper";
import { getConfirmationAlert, getErrorAlert, getLoadingAlert } from "../../../helpers/alertHelper";
import { validPhone } from "../../../const/Regex";
import { Button } from "react-bootstrap";
import { stripNonDigitCharacters } from "../../../helpers/sanitizeHelper";
import "./NewSubmission.css";

export const CommunitySettingsContext = createContext();
export const PhoneParamContext = createContext();

function NewSubmission(props) {
    /* useStates */
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isPhoneValid, setIsPhoneValid] = useState(false); // Disable the button at first thus avoid displaying the error message. 
    const [isError, setIsError] = useState(false);
    const [confirmationAlert, setConfirmationAlert] = useState();

    /* Functions */
    const handleFocus = () => {
        setIsError(false);
    }

    const handleChange = (newValue) => {
        const isNewValueValid = testRegex(newValue);
        setIsPhoneValid(isNewValueValid);
        setPhoneNumber(newValue);
    }

    const handleBlur = () => {
        if (phoneNumber !== "") {
            const isPhoneValid = testRegex(phoneNumber);
            setIsError(!isPhoneValid);
        }
    }

    const testRegex = (value) => {
        return validPhone.test(value);
    }

    const verifyNumber = async () => {
        setConfirmationAlert(
            getLoadingAlert()
        );

        const sanitizedPhoneNumber = stripNonDigitCharacters(phoneNumber);
        const existingSubmissionData = await getNewDriverSubmission(sanitizedPhoneNumber, true);
        if (existingSubmissionData === null) {
            await getRiderNotDriverByPhone(phoneNumber).then(rider_object => {
                const jsonEndpointObject = Object.assign({},
                    {
                        phone: phoneNumber,
                        chain_account_number: rider_object.account,
                        progress: 0,
                        status_id: 1,
                        is_finished: false,
                        custom_fields: [],
                        driver_vehicles: [],
                    },
                    rider_object.first_name && { first_name: rider_object.first_name },
                    rider_object.last_name && { last_name: rider_object.last_name },
                    rider_object.email && { email: rider_object.email },
                );

                // I am assuming that name will be available because is it a basic requirements for rider.
                const fullName = `${rider_object['first_name']} ${rider_object['last_name']}`;
                setConfirmationAlert(
                    getConfirmationAlert(() => createNewSubmission(jsonEndpointObject), closeConfirmationAlert, <Trans>ARE_YOU_SURE</Trans>,
                        <Trans
                            i18nKey="NEW_SUBMISSION_CREATE_NEW_PROMPT"
                            values={{ fullName }}
                        />)
                );
            }).catch(() => {
                setConfirmationAlert(
                    getErrorAlert(closeConfirmationAlert, <Trans>ERROR</Trans>, <Trans>NEW_SUBMISSION_NO_RIDER_FOUND_PROMPT</Trans>)
                )
            });
        } else {
            // If there is already an existing submission for this phone number, redirect to it.
            props.history.push(`/drivers/submissions/${phoneNumber}`);
        }
    }

    const closeConfirmationAlert = useCallback(() => {
        setConfirmationAlert();
    })

    const createNewSubmission = async (jsonEndpointObject) => {
        await saveDriverSubmission(jsonEndpointObject, false).then(response => {
            props.history.replace(`/drivers/submissions/${phoneNumber}`);
        }).catch(() => {
            // This error is less likely to happen. This would mean that the phone number wasn't valid in the first place.
            setConfirmationAlert(
                getErrorAlert(setConfirmationAlert, <Trans>ERROR</Trans>, <Trans>NEW_SUBMISSION_PHONE_REJECTED_BY_SERVER_PROMPT</Trans>)
            )
        })
    }

    return (
        <div className="new-driver-submission-container main-content" >
            {confirmationAlert}
            <div className={`input-wrapper form-group ${isError && "validation-error"}`}>
                <label className="text-label" htmlFor="phone_number">
                    <Trans>NEW_SUBMISSION_PHONE_LABEL_TEXT</Trans>
                </label>
                <input
                    id="phone_number"
                    type="tel"
                    name="phone_number"
                    placeholder="15141234567"
                    value={phoneNumber}
                    onFocus={handleFocus}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    //disabled={disabled}
                    className="form-control"
                />
                <p className="validation-error">{isError && <Trans>NEW_SUBMISSION_PHONE_FORMAT_ERROR</Trans>}</p>
                <Button
                    onClick={verifyNumber}
                    bsStyle="success"
                    disabled={isPhoneValid === false}
                >
                    <Trans>NEW_SUBMISSION_CREATE_BUTTON</Trans>
                </Button>
            </div>
        </div>
    );
}

export default NewSubmission;