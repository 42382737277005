import * as actionTypes from "./action_types";

import { persistor } from "../store/index";

export function loginSuccess(payload) {
  return { type: actionTypes.ON_LOG_IN_SUCCESS, payload };
}

export function updateTokenTransfers(payload) {
  return { type: actionTypes.UPDATE_TOKEN_TRANSFER_LIST, payload };
}

export function logout(payload) {
  persistor.purge();
  return { type: actionTypes.LOGOUT, payload };
}

export function updatePendingPaymentList(payload) {
  return { type: actionTypes.UPDATE_PENDING_PAYMENT_LIST, payload };
}

export function updateCompletedPaymentList(payload) {
  return { type: actionTypes.UPDATE_COMPLETED_PAYMENT_LIST, payload };
}

export function updateCommunity(payload) {
  return { type: actionTypes.UPDATE_COMMUNITY, payload };
}

export function updateDashboard(payload) {
  return { type: actionTypes.UPDATE_DASHBOARD, payload };
}

export function updateSequence(payload){
  return { type:actionTypes.UPDATE_SEQUENCE,payload};
}

export function updateSidebar(payload) {
  return { type: actionTypes.UPDATE_SIDEBAR, payload };
}

export function updateExchangeRates(payload) {
  return { type: actionTypes.UPDATE_EXCHANGE_RATES, payload};
}

export function loadZones(payload) {
  return { type: actionTypes.LOAD_ZONES, payload};
}

export function updateSelectedZone(payload) {
  return { type: actionTypes.UPDATE_SELECT_ZONE, payload};
}
