import './LocationInput.css';

import { ControlLabel, FormControl, FormGroup, HelpBlock } from "react-bootstrap";

import PlacesAutocomplete from 'react-places-autocomplete';
import React from 'react';
import { Trans } from "react-i18next";
import i18n from '../../i18n';

/**
 * Component used to enter an address with autocompletion
 */
class LocationInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "", isError: false, hasChanged: false };
  }


  getStringValidationState(field) {
    const length = field.length;
    if (length === 0 && this.state.hasChanged) {
      if (this.props.onError && !this.state.isError && this.state.hasChanged) {
        this.props.onError();
        this.setState({ isError: true });
      }
      return 'error';
    }
    if (this.props.onSuccess && this.state.isError) {
      this.props.onSuccess();
      this.setState({ isError: false });
    }
    return null;
  }

  /**
   * Updates the address
   * 
   * @param {String} address 
   */
  handleChange = address => {
    this.setState({ address });
    this.setState({ hasChanged: true })
    this.props.onChange(address);
  };

  /**
   * Updates the address
   * 
   * @param {String} address 
   */
  handleSelect = address => {
    this.setState({ address });
    this.props.onChange(address);
    this.props.onSelect && this.props.onSelect(address);
    this.setState({ hasChanged: false })
  };

  render() {

    return (
      <PlacesAutocomplete
        value={this.props.value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <form>
              <FormGroup
                controlId="formBasicText"
                validationState={this.getStringValidationState(this.props.value)}
                onBlur={selection => {
                  if (this.state.hasChanged) { this.handleSelect(selection.target.value) }
                }}
              >
                {this.props.label && <ControlLabel>{this.props.label}</ControlLabel>}
                <FormControl
                  value={this.props.value}
                  onPaste={event => {
                    event.preventDefault()
                    this.handleSelect(event.clipboardData.getData("Text"))
                  }}
                  required
                  {...getInputProps({
                    placeholder: i18n.t("ENTER_ADDRESS")
                  })}
                />
                <FormControl.Feedback />
                {this.props.value.length === 0 && <HelpBlock><Trans>INVALID_ADDRESS</Trans></HelpBlock>}
              </FormGroup>
            </form>

            <div className="autocomplete-dropdown-container">
              {loading && <div>{i18n.t("MAP_LOADING")}</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                const style = suggestion.active
                  ? { backgroundColor: 'rgba(235, 205, 34, 0.932)', cursor: 'pointer', color: 'white', padding: '10px', borderRadius: "4px" }
                  : { backgroundColor: '#ffffff', padding: '10px', cursor: 'pointer' };
                return (
                  <div

                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={suggestion.placeId}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default (LocationInput);