export default {
    1: 'RIDE_SHARING_SERVICE',
    2: 'DELIVERY_SERVICE',
    3: "CANNABIS",
    4: "BULK",
    5: "DELIVERY_SERVICE_AMBULANCE",
    7: "RIDE_SHARING_SERVICE_LUX",
    8: "RIDE_SHARING_MOTO",
    9: "DELIVERY_TRICYCLE",
    10: "RIDE_SHARING_TRICYCLE",
    11: "SHUTTLE"
}

export const RideTypes = [
    "RIDE_SHARING",
    "DELIVERY",
    "CANNABIS",
    "BULK",
    "DELIVERY_SERVICE_BIKE",
    "DELIVERY_SERVICE_MOTORCYCLE",
    "DELIVERY_SERVICE_AMBULANCE",
    "RIDE_SHARING_SERVICE_LUX",
    "RIDE_SHARING_MOTO",
    "DELIVERY_TRICYCLE",
    "RIDE_SHARING_TRICYCLE",
    "SHUTTLE"
]


export const RIDE_SHARING = 1;
export const DELIVERY = 2;
export const CANNABIS = 3;
export const BULK = 100;
export const DELIVERY_SERVICE_BIKE = 100;
export const DELIVERY_SERVICE_MOTORCYCLE = 100;
export const DELIVERY_SERVICE_AMBULANCE = 100;
export const RIDE_SHARING_SERVICE_LUX = 100;
export const RIDE_SHARING_MOTO = 100;
export const DELIVERY_TRICYCLE = 100;
export const RIDE_SHARING_TRICYCLE = 100;
export const SHUTTLE = 100;

export const RideTypeIds = {
    RIDE_SHARING: RIDE_SHARING,
    DELIVERY: DELIVERY,
    CANNABIS: CANNABIS, 
    BULK: BULK,
    DELIVERY_SERVICE_BIKE: DELIVERY_SERVICE_BIKE,
    DELIVERY_SERVICE_MOTORCYCLE: DELIVERY_SERVICE_MOTORCYCLE,
    DELIVERY_SERVICE_AMBULANCE: DELIVERY_SERVICE_AMBULANCE,
    RIDE_SHARING_SERVICE_LUX: RIDE_SHARING_SERVICE_LUX,
    RIDE_SHARING_MOTO: RIDE_SHARING_MOTO,
    DELIVERY_TRICYCLE: DELIVERY_TRICYCLE,
    RIDE_SHARING_TRICYCLE: RIDE_SHARING_TRICYCLE,
    SHUTTLE: SHUTTLE
}